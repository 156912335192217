import { __, request } from '../functions';
import {
    FETCH_TRACK_TRACE,
    CLEAN_TRACK_TRACE,
    CHANGE_TRACK_TRACE_SETTINGS,
    TRACK_TRACE_UPLOAD_LOGO,
} from './types';

/**
 * Nacitanie track trace.
 *
 * @param {Screen} screen
 */
export const fetchTrackTrace = screen => {
    return dispatch => request('/track-trace').then(response => {
        const { data, sync } = response.data;

        const { user } = sync;
        const { setUser } = screen.props;

        // Nasetujeme usera
        setUser(user);

        dispatch({ type: FETCH_TRACK_TRACE, payload: data });
    });
};

/**
 * Zmenime nastavenia track trace.
 *
 * @param {Screen} screen
 * @param {Object} settings
 */
export const changeTrackTraceSettings = (screen, settings) => {
    return dispatch => request('/track-trace/save', { data: settings }, 'POST').then(response => {
        const { status } = response.data;

        screen.setState({ loading: false });

        if (status === 'error') {
            // Nepodarilo sa zmenit nastavenia
            screen.showSnackbar('error', __('Nepodarilo sa zmeniť nastavenia'));
            return;
        }

        screen.showSnackbar('success', __('Nastavenia boli zmenené'));

        dispatch({ type: CHANGE_TRACK_TRACE_SETTINGS, payload: settings });
    });
};

/**
 * Upload loga.
 *
 * @param {string} url
 */
export const trackTraceUploadLogo = url => ({ type: TRACK_TRACE_UPLOAD_LOGO, payload: url });

/**
 * Vycistenie track trace.
 */
export const cleanTrackTrace = () => ({ type: CLEAN_TRACK_TRACE });
