import _ from 'lodash';
import {
    FETCH_MTOKNA_DOGS,
    CLEAN_MTOKNA_DOGS,
    CHANGE_MTOKNA_DOG,
    DELETE_MTOKNA_DOG,
} from './types';
import { request, __ } from '../functions';

export const fetchMtoknaDogs = (screen, options = {}) => {
    const defaults = {
        page: 1,
        per_page: 0,
        filtered: {},
    };

    options = { ...defaults, ...options };

    let params = { page: options.page, per_page: options.per_page };

    // Nasetujeme filter
    _.forEach(options.filtered, (value, key) => {
        params = { ...params, ...{ [`filter_${key}`]: value } };
    });

    return dispatch => request('/mtokna/dogs', params).then(response => {
        const { data, sync } = response.data;
        const { setUser } = screen.props;

        // Nasetujeme user data
        setUser(sync.user);

        dispatch({ type: FETCH_MTOKNA_DOGS, payload: data });
    });
};

export const changeMtoknaDog = (screen, id, data) => {
    return dispatch => request(`/mtokna/editDog/${id}`, { data }, 'POST').then(response => {
        const { status } = response.data;

        if (status === 'error') {
            screen.showSnackbar('error', __('Nepodarilo sa zmeniť zdroj'));
            return;
        }

        screen.showSnackbar('success', __('Zdroj bol zmenený'));

        dispatch({ type: CHANGE_MTOKNA_DOG, payload: { id, data } });
    });
};

export const deleteMtoknaDog = (screen, id) => {
    return dispatch => request(`/mtokna/removeDog/${id}`).then(response => {
        const { status } = response.data;

        if (status === 'error') {
            screen.showSnackbar('error', __('Nepodarilo sa zmazať zdroj'));
            return;
        }

        screen.showSnackbar('success', __('Zdroj bol zmazaný'));

        dispatch({ type: DELETE_MTOKNA_DOG });
    });
};

export const cleanMtoknaDogs = () => ({ type: CLEAN_MTOKNA_DOGS });
