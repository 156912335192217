import React from 'react';
import { connect } from 'react-redux';
import { withCookies } from 'react-cookie';
import _ from 'lodash';
import {Screen, Input, Button} from '../components';
import { fetchMtoknaWindowSeller, setUser, cleanMtoknaWindowSeller } from '../actions';
import {__, request} from '../functions';
import '../assets/styles/mtokna_window_seller.css';

/**
 * Email.
 */
class MtoknaWindowSellerScreen extends Screen {
    /**
     * Title.
     *
     * @type {string}
     */
    title = __('Žalúzie, sieťky - nastavenia');

    /**
     * Default state.
     *
     * @type {Object}
     */
    state = {
        data: {},
        loading: false,
    };

    /**
     * Komponenta bola pripojena.
     *
     * @return boolean
     */
    componentDidMount() {
        if (super.componentDidMount()) {
            this.load();
        }

        return true;
    }

    /**
     * Komponenta bude odpojena.
     */
    componentWillUnmount() {
        const { cleanMtoknaWindowSeller } = this.props;

        cleanMtoknaWindowSeller();
    }

    async load() {
        const { fetchMtoknaWindowSeller } = this.props;

        // Nacitame smtp
        await fetchMtoknaWindowSeller(this);

        const { settings } = this.props;

        let data = {
            name: '',
            address: '',
            city: '',
            zip: '',
            phone: '',
            email: '',
            ico: '',
            dic: '',
            ic_dph: '',
            point_name: '',
        };

        if (!_.isEmpty(settings.data)) {
            data = { ...data, ...settings.data };
        }

        this.setState({ data, loading: false });
    }

    /**
     * Ulozenie.
     */
    save() {
        let { data } = this.state;

        let error = '';

        if (error !== '') {
            // Mame error
            this.showSnackbar('error', error);
            return;
        }

        this.setState({ loading: true });

        request('/mtokna/editWindowSeller', { data }, 'POST').then(response => {
            const { status, data } = response.data;

            this.setState({ loading: false });

            if (status === 'error') {
                this.showSnackbar('error', _.has(data, 'message') ? data.message : __('Nepodarilo sa uložiť nastavenia'));
                return;
            }

            this.showSnackbar('success', __('Nastavenia boli uložené'));
        })
    }

    /**
     * Event po zmene dat.
     *
     * @param {string} setting
     * @param {string} value
     */
    onChange(setting, value) {
        const { data } = this.state;

        this.setState({ data: { ...data, [setting]: value } });
    }

    /**
     * Rendrovanie.
     *
     * @returns {JSX.Element}
     */
    render() {
        const { data, loading } = this.state;

        if (_.isEmpty(data) || loading) {
            // Data nie su nacitane
            return this.renderLoading();
        }

        return (
            <div className="mtokna_window_seller">
                <div className="mtokna_window_seller__header">
                    <div className="mtokna_window_seller__header__left">
                        <div className="mtokna_window_seller__header__left__title">{__('Žalúzie, sieťky - nastavenia')}</div>
                    </div>
                </div>
                <div className="mtokna_window_seller__content">
                    <div className="mtokna_window_seller__content__panels">
                        <div className="mtokna_window_seller__content__panels__panel">
                            <Input
                                label={__('Meno a priezvisko')}
                                value={data.name}
                                onChange={value => this.onChange('name', value)}
                            />
                            <Input
                                label={__('Tel. číslo')}
                                value={data.phone}
                                onChange={value => this.onChange('phone', value)}
                            />
                            <Input
                                label={__('Email')}
                                value={data.email}
                                onChange={value => this.onChange('email', value)}
                            />
                            <Input
                                label={__('IČO')}
                                value={data.ico}
                                onChange={value => this.onChange('ico', value)}
                            />
                            <Input
                                label={__('DIČ')}
                                value={data.dic}
                                onChange={value => this.onChange('dic', value)}
                            />
                            <Input
                                label={__('IČ DPH')}
                                value={data.ic_dph}
                                onChange={value => this.onChange('ic_dph', value)}
                            />
                        </div>
                        <div className="mtokna_window_seller__content__panels__panel">
                            <Input
                                label={__('Ulica')}
                                value={data.address}
                                onChange={value => this.onChange('address', value)}
                            />
                            <Input
                                label={__('Mesto')}
                                value={data.city}
                                onChange={value => this.onChange('city', value)}
                            />
                            <Input
                                label={__('PSČ')}
                                value={data.zip}
                                onChange={value => this.onChange('zip', value)}
                            />
                            <Input
                                label={__('Názov a adresa prevádzky')}
                                value={data.point_name}
                                onChange={value => this.onChange('point_name', value)}
                            />
                        </div>
                    </div>
                    <Button
                        onClick={() => this.save()}
                        loading={loading}
                        className="mtokna_window_seller__content__button"
                        color="green"
                    >{__('Uložiť')}</Button>
                </div>
                {this.renderSnackbar()}
            </div>
        );
    }
}

const stateToProps = ({ mtokna_window_seller, user }) => ({ settings: mtokna_window_seller, user });

export default withCookies(connect(stateToProps, {
    fetchMtoknaWindowSeller,
    setUser,
    cleanMtoknaWindowSeller,
})(MtoknaWindowSellerScreen));
