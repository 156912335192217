import _ from 'lodash';
import { toNumber } from '../functions';
import {
    FETCH_MTOKNA_ORDERS,
    CLEAN_MTOKNA_ORDERS,
    SYNC_MTOKNA_ORDER,
    EXPORT_MTOKNA_ORDER,
    EXPORT_MTOKNA_ORDERS,
    CHANGE_MTOKNA_ORDER_STATE,
    SELLER_MTOKNA_ORDER,
    SELLER_MTOKNA_ORDERS,
    EDIT_MTOKNA_ORDER,
    READY_MTOKNA_ORDER,
    PICKUP_MTOKNA_ORDER,
    SEND_MTOKNA_ORDERS,
    LOCK_MTOKNA_ORDER,
    UNLOCK_MTOKNA_ORDER,
} from '../actions/types';

const MTOKNA_ORDERS_DEFAULT_STATE = {};

/**
 * Reducer pre objednavky.
 */
const MtoknaOrdersReducer = (state = MTOKNA_ORDERS_DEFAULT_STATE, { type, payload }) => {
    switch (type) {
        case FETCH_MTOKNA_ORDERS:
            return payload;

        case CLEAN_MTOKNA_ORDERS:
            return MTOKNA_ORDERS_DEFAULT_STATE;

        case EDIT_MTOKNA_ORDER:
            // Editacia objednavky
            return { ...state, items: _.map(state.items, item => {
                if (toNumber(item.id) === toNumber(payload.id)) {
                    // Nasli sme objednavku
                    item = { ...item, data: { ...item.data, ...payload.data } };
                }

                return item;
            }) };

        case LOCK_MTOKNA_ORDER:
        case UNLOCK_MTOKNA_ORDER:
            return { ...state, locked: payload };

        case CHANGE_MTOKNA_ORDER_STATE:
        case READY_MTOKNA_ORDER:
            return { ...state, items: _.map(state.items, item => {
                if (toNumber(item.id) === toNumber(payload.id)) {
                    // Nasli sme objednavku
                    item = { ...item, _matchingData: { ...item._matchingData, OrderSellers: { ...item._matchingData.OrderSellers, state: payload.state } } };
                }

                return item;
            }) };

        case PICKUP_MTOKNA_ORDER:
            return { ...state, items: _.map(state.items, item => {
                if (toNumber(item.id) === toNumber(payload.id)) {
                    // Nasli sme objednavku
                    item = {
                        ...item,
                        _matchingData: { ...item._matchingData, OrderSellers: { ...item._matchingData.OrderSellers, state: payload.state } },
                        data: { ...item.data, meta_data: payload.meta_data },
                    };
                }

                return item;
            }) };

        case SEND_MTOKNA_ORDERS:
            const { packages, datas } = payload;

            return { ...state, items: _.map(state.items, item => {
                if (!_.isEmpty(packages[toNumber(item.id)])) {
                    // Nasli sme objednavku nastavime zasielku
                    item = { ...item, package: packages[toNumber(item.id)] };
                }

                if (!_.isEmpty(datas[toNumber(item.id)])) {
                    // Nasli sme objednavku nastavime data
                    item = { ...item, data: datas[toNumber(item.id)] };
                }

                return item;
            }) };

        case SYNC_MTOKNA_ORDER:
        case SELLER_MTOKNA_ORDER:
        case SELLER_MTOKNA_ORDERS:
            return { ...state, items: _.map(state.items, item => {
                if (!_.isEmpty(payload[toNumber(item.id)])) {
                    // Nasli sme objednavku zmenime data
                    item = { ...item, data: payload[toNumber(item.id)] };
                }

                return item;
            }) };

        case EXPORT_MTOKNA_ORDER:
        case EXPORT_MTOKNA_ORDERS:
            // Export objednavky
            // Export objednavok
            return { ...state, items: _.map(state.items, item => {
                if (_.has(payload, item.id)) {
                    // Nasli sme objednavku nastavime priznak ako exportovany
                    item = { ...item, exported: 1 };
                }

                return item;
            }) };

        default:
            return state;
    }
};

export default MtoknaOrdersReducer;
