import React from 'react';
import _ from 'lodash';
import PdfIcon from '@mui/icons-material/PictureAsPdf';
import DeleteIcon from '@mui/icons-material/Delete';
import { __ } from './__';

/**
 * Vratime buttony pre fakturu.
 *
 * @param {Object} item
 * @param {Object} options
 *
 * @return {Object}
 */
export const getInvoiceButtons = (item, options = {}) => {
    options = { ...{
        downloadedPdf: [],
        hasInvoicePdf: false,
        hasPermissionPdf: false,
        hasPermissionDelete: false,
    }, ...options };

    let buttons = {};

    if (options.hasInvoicePdf && options.hasPermissionPdf) {
        // Stahujeme PDF
        buttons = { ...buttons, pdf: {
            name: __('Stiahnuť PDF'),
            icon: <PdfIcon color={item.status_pdf === 'newly' && !_.includes(options.downloadedPdf, item.id)
                 ? 'secondary'
                : 'inherit'} />,
            callback: () => {},
            options: {},
        }};
    }

    buttons = { ...buttons, delete: {
        name: __('Zmazať'),
        icon: <DeleteIcon />,
        callback: () => {},
        options: {},
    }};

    if (!options.hasPermissionDelete) {
        // Nema pravo
        buttons = _.omit(buttons, ['delete']);
    }

    return buttons;
};
