import React from 'react';
import { connect } from 'react-redux';
import { withCookies } from 'react-cookie';
import _ from 'lodash';
import { Tooltip } from '@mui/material';
import {ListScreen, Input, Select} from '../components';
import {
    fetchMtoknaPoints,
    cleanMtoknaPoints,
    setUser,
    changeUserSetting,
    changeMtoknaPoint,
    deleteMtoknaPoint,
} from '../actions';
import { __, formatDate, isEmptyString, request } from '../functions';
import '../assets/styles/mtokna_points.css';
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from '@mui/icons-material/Delete';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

/**
 * Klienti.
 */
class MtoknaPointsScreen extends ListScreen {
    /**
     * Title.
     *
     * @type {string}
     */
    title = __('Odberné miesta');

    /**
     * Default state.
     *
     * @type {Object}
     */
    state = { ...ListScreen.state, ...{
        lightbox: {
            create: false,
            edit: false,
            delete: false,
        },
    }};

    /**
     * Memory cache.
     *
     * @type {Object}
     */
    memoryCache = {};

    /**
     * Vratime nazov tabulky.
     *
     * @return {string}
     */
    getTableName() {
        return 'mtokna_points';
    }

    /**
     * Vratime zoznam stlpcov.
     *
     * @return {Array}
     */
    getColumns() {
        return [
            <div>{`${__('ID')} / ${__('Názov')} / ${__('Email')}`}</div>,
            <div>{`${__('Ulica')} / ${__('Mesto')} / ${__('Krajina')}`}</div>,
            <div>{`${__('Súradnica (Latitude)')} / ${__('Súradnica (Longitude)')} / ${__('Vytvorené')}`}</div>,
            <div></div>,
        ];
    }

    /**
     * Vratime bunky.
     *
     * @param {Object} item
     *
     * @return {Array}
     */
    getCells(item) {
        const { user } = this.props;

        return [
            <div>
                <div>{item.code}</div>
                <div>{_.truncate(item.data.name, { length: 32 })}</div>
                <div>{_.truncate(item.data.email, { length: 32 })}</div>
            </div>,
            <div>
                <div>{_.truncate(item.data.address, { length: 32 })}</div>
                <div>{item.data.city} {item.data.zip}</div>
                <div>{item.data.country === 'cz' ? __('Česká republika') : __('Slovensko')}</div>
            </div>,
            <div>
                <div>{item.data.lat}</div>
                <div>{item.data.lng}</div>
                {formatDate(item.created, 'dd.mm.yyyy')}
            </div>,
            <div>
                {item.status === 'public' || (_.has(item.data, 'status') && item.data.status === 'public') ? this.renderTableButton(
                    item.id,
                    __('Verejné'),
                    <CheckCircleIcon color="secondary" />,
                    (callbackLoading, callback) => {},
                    {}
                ) : null}
                {!user.mtokna_moderator ? this.renderTableButton(
                    item.id,
                    __('Upraviť'),
                    <EditIcon />,
                    (callbackLoading, callback) => this.confirmEdit(item.id, callbackLoading, callback, item.data),
                    {}
                ) : null}
                {!user.mtokna_moderator ? this.renderTableButton(
                    item.id,
                    __('Zmazať'),
                    <DeleteIcon />,
                    (callbackLoading, callback) => this.confirmDelete(item.id, callbackLoading, callback),
                    {}
                ) : null}
            </div>,
        ];
    }

    /**
     * Vratime filter.
     *
     * @return {Object}
     */
    getFilter() {
        const { items } = this.props;

        return _.reduce(items.filter, (result, options, key) => {
            switch (key) {
                case 'code':
                    return { ...result, ...{ [key]: {
                        type: 'input',
                        name: __('ID'),
                        value: '',
                    }}};

                case 'email':
                    return { ...result, ...{ [key]: {
                        type: 'input',
                        name: __('E-mail'),
                        value: '',
                    }}};

                case 'city':
                    return { ...result, ...{ [key]: {
                        type: 'select',
                        name: __('Mesto'),
                        value: '',
                        autocomplete: true,
                        options,
                    }}};

                case 'search':
                    return { ...result, ...{ [key]: {
                        type: 'input',
                        name: __('Hľadať'),
                        value: '',
                    }}};

                default:
                    return result;
            }
        }, {});
    }

    getTags() {
        const { user, items } = this.props;

        if (user.mtokna_moderator) {
            return [];
        }

        return [
            {
                name: __('Nové odberné miesto'),
                onClick: () => this.showLightbox('create', {
                    loading: false,
                    data: {
                        code: `${items.total + 100}`,
                        name: '',
                        email: '',
                        address: '',
                        city: '',
                        zip: '',
                        country: 'sk',
                        lat: '',
                        lng: '',
                        text: '',
                        status: 'draft',
                    },
                }),
            }
        ];
    }

    /**
     * Vratime empty text.
     *
     * @return {string}
     */
    getEmptyText() {
        return __('Zatiaľ nemáte žiadne odberné miesta');
    }

    /**
     * Event po zmene hesla.
     *
     * @param {string} key
     * @param {string} value
     */
    onChangeEdit(key, value) {
        const { lightbox } = this.state;

        this.setState({ lightbox: { ...lightbox, edit: {
            ...lightbox.edit,
            data: { ...lightbox.edit.data, [key]: value }
        } } });
    }

    /**
     * Event po zmene create.
     *
     * @param {string} key
     * @param {string} value
     */
    onChangeCreate(key, value) {
        const { lightbox } = this.state;

        this.setState({ lightbox: { ...lightbox, create: {
            ...lightbox.create,
            data: { ...lightbox.create.data, [key]: value }
        } } });
    }

    /**
     * Event po zmene create.
     *
     * @param {string} value
     */
    onChangeCreateLoading(value) {
        const { lightbox } = this.state;

        this.setState({ lightbox: { ...lightbox, create: { ...lightbox.create, loading: value } } })
    }

    /**
     * Zobrazime lightbox na email.
     *
     * @param {number} id
     * @param {function} callbackLoading
     * @param {function} callback
     * @param {Object} data
     */
    confirmEdit(id, callbackLoading, callback, data) {
        this.showLightbox('edit', { id, callbackLoading, callback, data });
    }

    /**
     * Zobrazime lightbox na email.
     *
     * @param {number} id
     * @param {function} callbackLoading
     * @param {function} callback
     */
    confirmDelete(id, callbackLoading, callback) {
        this.showLightbox('delete', { id, callbackLoading, callback });
    }

    /**
     * Ulozenie emailu.
     */
    async edit() {
        const { changeMtoknaPoint } = this.props;
        const { lightbox } = this.state;

        // Zavolame loading
        lightbox.edit.callbackLoading();

        // Zavrieme lightbox
        this.closeLightbox('edit');

        // Zmenime email
        await changeMtoknaPoint(this, lightbox.edit.id, lightbox.edit.data);

        // Zavolame callback
        lightbox.edit.callback();
    }

    /**
     * Zmazanie miesta.
     */
    async delete() {
        const { deleteMtoknaPoint, items } = this.props;
        const { lightbox } = this.state;

        // Zavolame loading
        lightbox.delete.callbackLoading();

        // Zavrieme lightbox
        this.closeLightbox('delete');

        // Zmazeme klienta
        await deleteMtoknaPoint(this, lightbox.delete.id);

        // Zavolame callback
        lightbox.delete.callback();

        // Znovu nacitame zoznam od prvej stranky
        this.fetchList(1, 0, items.filtered);
    }

    create() {
        const { lightbox } = this.state;

        if (isEmptyString(lightbox.create.data.code)) {
            this.showSnackbar('error', __('Nie je vyplnené ID'));
            return;
        } else if (isEmptyString(lightbox.create.data.name)) {
            this.showSnackbar('error', __('Nie je vyplnený názov'));
            return;
        } else if (isEmptyString(lightbox.create.data.email)) {
            this.showSnackbar('error', __('Nie je vyplnený email'));
            return;
        } else if (isEmptyString(lightbox.create.data.address)) {
            this.showSnackbar('error', __('Nie je vyplnená adresa'));
            return;
        } else if (isEmptyString(lightbox.create.data.city)) {
            this.showSnackbar('error', __('Nie je vyplnené mesto'));
            return;
        } else if (isEmptyString(lightbox.create.data.zip)) {
            this.showSnackbar('error', __('Nie je vyplnené PSČ'));
            return;
        } else if (isEmptyString(lightbox.create.data.lat) || isEmptyString(lightbox.create.data.lng)) {
            this.showSnackbar('error', __('Nie sú vyplnené súradnice'));
            return;
        }

        this.onChangeCreateLoading(true);

        request('/mtokna/createPoint', { data: lightbox.create.data }, 'POST').then(response => {
            const { status } = response.data;

            if (status === 'error') {
                this.onChangeCreateLoading(false);
                this.showSnackbar('error', __('Odberné miesto sa nepodarilo vytvoriť'));
                return;
            }

            // Refreshneme
            window.location = window.location.pathname;
        });
    }

    /**
     * Rendrujeme screen.
     *
     * @return {JSX.Element|null}
     */
    renderScreen() {
        const { lightbox } = this.state;

        return (
            <div>
                {this.renderLightbox(
                    'delete',
                    __('Naozaj chcete zmazať toto odberné miesto?'),
                    null,
                    __('Áno'),
                    __('Nie'),
                    () => this.delete()
                )}
                {this.renderLightbox(
                    'edit',
                    __('Úprava odberného miesta'),
                    !_.isEmpty(lightbox.edit) ? <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                    }}>
                        <div style={{ width: '300px' }}>
                            <div>
                                <Input
                                    label={__('ID')}
                                    value={lightbox.edit.data.code}
                                    onChange={value => this.onChangeEdit('code', value)}
                                />
                            </div>
                            <div style={{ marginTop: '20px' }}>
                                <Input
                                    label={__('Názov')}
                                    value={lightbox.edit.data.name}
                                    onChange={value => this.onChangeEdit('name', value)}
                                />
                            </div>
                            <div style={{ marginTop: '20px' }}>
                                <Select
                                    label={__('Stav')}
                                    value={lightbox.edit.data.status}
                                    options={{ draft: 'Draft', 'public': 'Verejné' }}
                                    onChange={value => this.onChangeEdit('status', value)}
                                    allowEmpty={false}
                                />
                            </div>
                            <div style={{ marginTop: '20px' }}>
                                <Input
                                    label={__('Email')}
                                    value={lightbox.edit.data.email}
                                    onChange={value => this.onChangeEdit('email', value)}
                                />
                            </div>
                            <div style={{ marginTop: '20px' }}>
                                <Input
                                    label={__('Súradnica (Latitude and longitude)')}
                                    value={lightbox.edit.data.lat}
                                    onChange={value => this.onChangeEdit('lat', value)}
                                />
                            </div>
                            <div style={{ marginTop: '20px' }}>
                                <Input
                                    label={__('Súradnica (Longitude)')}
                                    value={lightbox.edit.data.lng}
                                    onChange={value => this.onChangeEdit('lng', value)}
                                />
                            </div>
                        </div>
                        <div style={{ marginLeft: '20px', width: '300px' }}>
                            <div>
                                <Input
                                    label={__('Ulica')}
                                    value={lightbox.edit.data.address}
                                    onChange={value => this.onChangeEdit('address', value)}
                                />
                            </div>
                            <div style={{ marginTop: '20px' }}>
                                <Input
                                    label={__('Mesto')}
                                    value={lightbox.edit.data.city}
                                    onChange={value => this.onChangeEdit('city', value)}
                                />
                            </div>
                            <div style={{ marginTop: '20px' }}>
                                <Input
                                    label={__('PSČ')}
                                    value={lightbox.edit.data.zip}
                                    onChange={value => this.onChangeEdit('zip', value)}
                                />
                            </div>
                            <div style={{ marginTop: '20px' }}>
                                <Select
                                    label={__('Krajina')}
                                    value={lightbox.edit.data.country}
                                    options={{ sk: 'sk', cz: 'cz' }}
                                    onChange={value => this.onChangeEdit('country', value)}
                                    allowEmpty={false}
                                />
                            </div>
                            <div style={{ marginTop: '20px' }}>
                                <Input
                                    label={__('Text')}
                                    value={lightbox.edit.data.text}
                                    onChange={value => this.onChangeEdit('text', value)}
                                    multiline
                                />
                            </div>
                        </div>
                    </div> : null,
                    __('Uložiť'),
                    __('Zrušiť'),
                    () => this.edit()
                )}
                {this.renderLightbox(
                    'create',
                    __('Nové odberné miesto'),
                    !_.isEmpty(lightbox.create) ? (lightbox.create.loading ? this.renderLoading(20) : <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                    }}>
                        <div style={{ width: '300px' }}>
                            <div>
                                <Input
                                    label={__('ID')}
                                    value={lightbox.create.data.code}
                                    onChange={value => this.onChangeCreate('code', value)}
                                />
                            </div>
                            <div style={{ marginTop: '20px' }}>
                                <Input
                                    label={__('Názov')}
                                    value={lightbox.create.data.name}
                                    onChange={value => this.onChangeCreate('name', value)}
                                />
                            </div>
                            <div style={{ marginTop: '20px' }}>
                                <Select
                                    label={__('Stav')}
                                    value={lightbox.create.data.status}
                                    options={{ draft: 'Draft', 'public': 'Verejné' }}
                                    onChange={value => this.onChangeCreate('status', value)}
                                    allowEmpty={false}
                                />
                            </div>
                            <div style={{ marginTop: '20px' }}>
                                <Input
                                    label={__('Email')}
                                    value={lightbox.create.data.email}
                                    onChange={value => this.onChangeCreate('email', value)}
                                />
                            </div>
                            <div style={{ marginTop: '20px' }}>
                                <Input
                                    label={__('Súradnica (Latitude)')}
                                    value={lightbox.create.data.lat}
                                    onChange={value => this.onChangeCreate('lat', value)}
                                />
                            </div>
                            <div style={{ marginTop: '20px' }}>
                                <Input
                                    label={__('Súradnica (Longitude)')}
                                    value={lightbox.create.data.lng}
                                    onChange={value => this.onChangeCreate('lng', value)}
                                />
                            </div>
                        </div>
                        <div style={{ marginLeft: '20px', width: '300px' }}>
                            <div>
                                <Input
                                    label={__('Ulica')}
                                    value={lightbox.create.data.address}
                                    onChange={value => this.onChangeCreate('address', value)}
                                />
                            </div>
                            <div style={{ marginTop: '20px' }}>
                                <Input
                                    label={__('Mesto')}
                                    value={lightbox.create.data.city}
                                    onChange={value => this.onChangeCreate('city', value)}
                                />
                            </div>
                            <div style={{ marginTop: '20px' }}>
                                <Input
                                    label={__('PSČ')}
                                    value={lightbox.create.data.zip}
                                    onChange={value => this.onChangeCreate('zip', value)}
                                />
                            </div>
                            <div style={{ marginTop: '20px' }}>
                                <Select
                                    label={__('Krajina')}
                                    value={lightbox.create.data.country}
                                    options={{ sk: 'sk', cz: 'cz' }}
                                    onChange={value => this.onChangeCreate('country', value)}
                                    allowEmpty={false}
                                />
                            </div>
                            <div style={{ marginTop: '20px' }}>
                                <Input
                                    label={__('Text')}
                                    value={lightbox.create.data.text}
                                    onChange={value => this.onChangeCreate('text', value)}
                                    multiline
                                />
                            </div>
                        </div>
                    </div>) : null,
                    __('Uložiť'),
                    __('Zrušiť'),
                    () => this.create()
                )}
            </div>
        );
    }
}

const stateToProps = ({ mtokna_points, user }) => ({ items: mtokna_points, user });

export default withCookies(connect(stateToProps, {
    fetch: fetchMtoknaPoints,
    clean: cleanMtoknaPoints,
    setUser,
    changeUserSetting,
    changeMtoknaPoint,
    deleteMtoknaPoint,
})(MtoknaPointsScreen));
