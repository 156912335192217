import _ from 'lodash';
import {
    FETCH_MTOKNA_COMPLAINTS,
    CLEAN_MTOKNA_COMPLAINTS,
    EDIT_MTOKNA_COMPLAINT,
    DELETE_MTOKNA_COMPLAINT,
    EXPORT_MTOKNA_COMPLAINT,
    CHANGE_MTOKNA_COMPLAINT_STATE,
    CANCEL_MTOKNA_COMPLAINT,
} from './types';
import {request, __} from '../functions';

export const fetchMtoknaComplaints = (screen, options = {}) => {
    const defaults = {
        page: 1,
        per_page: 0,
        filtered: {},
        callback: null,
    };

    options = { ...defaults, ...options };

    let params = { page: options.page, per_page: options.per_page };

    // Nasetujeme filter
    _.forEach(options.filtered, (value, key) => {
        params = { ...params, ...{ [`filter_${key}`]: value } };
    });

    return dispatch => request('/mtokna/complaints', params).then(response => {
        const { data, sync } = response.data;
        const { setUser } = screen.props;

        if (options.callback !== null) {
            // Zavolame callback
            options.callback();
        }

        // Nasetujeme user data
        setUser(sync.user);

        dispatch({ type: FETCH_MTOKNA_COMPLAINTS, payload: data });
    });
};

export const cleanMtoknaComplaints = () => ({ type: CLEAN_MTOKNA_COMPLAINTS });

export const changeMtoknaComplaintState = (screen, id, stateId) => {
    return dispatch => request(`/mtokna/changeComplaintState/${id}/${stateId}`).then(response => {
        const { status } = response.data;

        if (status === 'error') {
            // Stav sa nepodarilo zmenit
            screen.showSnackbar('error', __('Stav reklamácie sa nepodarilo zmeniť'));
            return;
        }

        screen.showSnackbar('success', __('Stav reklamácie bol zmenený'));

        dispatch({ type: CHANGE_MTOKNA_COMPLAINT_STATE, payload: { id, state: stateId } });
    });
};

export const cancelMtoknaComplaint = (screen, id) => {
    return dispatch => request(`/mtokna/cancelComplaint/${id}`).then(response => {
        const { status, data } = response.data;

        if (status === 'error') {
            // Stav sa nepodarilo zmenit
            screen.showSnackbar('error', __('Stav reklamácie sa nepodarilo zmeniť'));
            return;
        }

        screen.showSnackbar('success', __('Stav reklamácie bol zmenený'));

        dispatch({ type: CANCEL_MTOKNA_COMPLAINT, payload: { id, state: data.state } });
    });
};

export const pdfMtoknaComplaint = (screen, id) => {
    return dispatch => request(`/mtokna/pdfComplaint/${id}`).then(response => {
        const { status, data } = response.data;

        if (status === 'error') {
            screen.showSnackbar('error', __('Nepodarilo sa stiahnuť pdf reklamácie'));
            return;
        }

        screen.showSnackbar('success', __('Reklamácia bola exportovaná'));

        // Stiahneme subor
        window.location = data.link;

        dispatch({ type: EXPORT_MTOKNA_COMPLAINT, payload: { [id]: id } });
    });
};

/**
 * Zmazanie objednavky.
 *
 * @param {Screen} screen
 * @param {number} id
 */
export const deleteMtoknaComplaint = (screen, id) => {
    return dispatch => request(`/mtokna/deleteComplaint/${id}`, { order_delete_in_eshop: false }).then(response => {
        const { status } = response.data;

        if (status === 'error') {
            // Objednavku sa nepodarilo zmazat
            screen.showSnackbar('error', __('Reklamáciu sa nepodarilo zmazať pretože má evidovanú faktúru'));
            return;
        }

        screen.showSnackbar('success', __('Reklamácia bola zmazaná'));

        dispatch({ type: DELETE_MTOKNA_COMPLAINT });
    });
};

export const changeMtoknaComplaintData = (id, data) => ({ type: EDIT_MTOKNA_COMPLAINT, payload: { id, data } });
