import _ from 'lodash';
import { toNumber } from '../functions';
import {
    FETCH_MTOKNA_MANUAL_ORDERS,
    CLEAN_MTOKNA_MANUAL_ORDERS,
    EXPORT_MTOKNA_MANUAL_ORDER,
    CHANGE_MTOKNA_MANUAL_ORDER_STATE,
    EDIT_MTOKNA_MANUAL_ORDER,
    DELETE_MTOKNA_MANUAL_ORDER,
    CREATE_MTOKNA_MANUAL_INVOICE,
    ADDON_MTOKNA_MANUAL_ORDERS,
    SEND_MTOKNA_MANUAL_ORDERS,
    EXPORT_MTOKNA_MANUAL_ORDERS,
} from '../actions/types';

const MTOKNA_MANUAL_ORDERS_DEFAULT_STATE = {};

/**
 * Reducer pre objednavky.
 */
const MtoknaManualOrdersReducer = (state = MTOKNA_MANUAL_ORDERS_DEFAULT_STATE, { type, payload }) => {
    switch (type) {
        case FETCH_MTOKNA_MANUAL_ORDERS:
            return payload;

        case CLEAN_MTOKNA_MANUAL_ORDERS:
        case DELETE_MTOKNA_MANUAL_ORDER:
            return MTOKNA_MANUAL_ORDERS_DEFAULT_STATE;

        case EDIT_MTOKNA_MANUAL_ORDER:
            // Editacia objednavky
            return { ...state, items: _.map(state.items, item => {
                if (toNumber(item.id) === toNumber(payload.id)) {
                    // Nasli sme objednavku
                    item = { ...item, data: { ...item.data, ...payload.data } };
                }

                return item;
            }) };

        case CHANGE_MTOKNA_MANUAL_ORDER_STATE:
            return { ...state, items: _.map(state.items, item => {
                if (!_.isEmpty(payload[toNumber(item.id)])) {
                    // Nasli sme objednavku zmenime data
                    item = { ...item, data: payload[toNumber(item.id)] };
                }

                return item;
            }) };

        case EXPORT_MTOKNA_MANUAL_ORDER:
        case EXPORT_MTOKNA_MANUAL_ORDERS:
            return { ...state, items: _.map(state.items, item => {
                if (_.has(payload, item.id)) {
                    // Nasli sme objednavku nastavime priznak ako exportovany
                    item = { ...item, exported: 1 };
                }

                return item;
            }) };

        case CREATE_MTOKNA_MANUAL_INVOICE:
            return { ...state, items: _.map(state.items, item => {
                if (!_.isEmpty(payload[toNumber(item.id)])) {
                    // Nasli sme objednavku, pridame a nastavime objednavku ako procesovanu
                    const data = payload[toNumber(item.id)];

                    item = _.isString(data)
                        ? { ...item, error_invoice: data }
                        : { ...item,
                            invoice: data,
                            invoice_status_pdf: 'newly',
                            invoice_status: data.paid > 0 ? 'paid' : 'unpaid',
                        };
                }

                return item;
            }) };

        case SEND_MTOKNA_MANUAL_ORDERS:
            const { packages, datas } = payload;

            return { ...state, items: _.map(state.items, item => {
                if (!_.isEmpty(packages[toNumber(item.id)])) {
                    // Nasli sme objednavku nastavime zasielku
                    item = { ...item, package: packages[toNumber(item.id)] };
                }

                if (!_.isEmpty(datas[toNumber(item.id)])) {
                    // Nasli sme objednavku nastavime data
                    item = { ...item, data: datas[toNumber(item.id)] };
                }

                return item;
            }) };

        case ADDON_MTOKNA_MANUAL_ORDERS:
        default:
            return state;
    }
};

export default MtoknaManualOrdersReducer;
