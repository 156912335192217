import _ from 'lodash';
import cz from '../translations/cz.json';
import pl from '../translations/pl.json';
import hu from '../translations/hu.json';

const MARKET = window.location.host.indexOf('.cz') !== -1
    ? 'cz'
    : (window.location.host.indexOf('.pl') !== -1
        ? 'pl'
        : (window.location.host.indexOf('.hu') !== -1 ? 'hu' : 'sk'));

/**
 * Nacitame preklad.
 *
 * @param {string} word
 * @param {string} type
 *
 * @returns {string}
 */
export const __ = (word, type = '') => {
    if (MARKET === 'cz') {
        word = _.has(cz, word) ? cz[word] : word;
    } else if (MARKET === 'pl') {
        word = _.has(pl, word) ? pl[word] : word;
    } else if (MARKET === 'hu') {
        word = _.has(hu, word) ? hu[word] : word;
    }

    switch (type) {
        case 'l':
            return word.toLowerCase();

        case 'u':
            return word.toUpperCase();

        default:
            return word;
    }
};
