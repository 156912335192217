import React from 'react';
import _ from 'lodash';
import { Tooltip, IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { Navigate, Button, Input, Select, ContentPart, Message, Checkbox } from '../components';
import { __, request, toNumber, resetObject } from '../functions';
import '../assets/styles/courier.css';

/**
 * Courier komponenta.
 */
class Courier extends Navigate {
    /**
     * Default props.
     *
     * @type {Object}
     */
    static defaultProps = {
        type: '',
    };

    /**
     * Default state.
     *
     * @type {Object}
     */
    state = {
        showSetup: false,
        setupId: 'all',
        data: {},
        settings: [],
        fields: {},
        loading: false,
        senders: {},
    };

    /**
     * Zoznam labelov.
     *
     * @type {Object}
     */
    labels = {
        'market': __('Lokalizácia'),
        'sender_id': __('Odosielateľ'),
        'sender': __('Odosielateľ'),
        'dpd_portal': __('Portál pre prihlásenie'),
        'company_id': __('Company id'),
        'id': __('ID klienta'),
        'email': __('Prihlasovací email'),
        'username': __('Prihlasovacie meno'),
        'password': __('Prihlasovacie heslo'),
        'iban': __('IBAN / Číslo účtu pre úhrady dobierok'),
        'apikey': __('API klúč'),
        'bic': __('BIC'),
        'client_id': __('Client id'),
        'client_secret': __('Client secret'),
        'agreement': __('Agreement'),
        'accounting_code': __('Accounting code'),
        'account_number': __('Account number'),
    };

    /**
     * Zoznam setvices labelov.
     *
     * @type {Object}
     */
    servicesLabels = {
        UloznaLehota: __('Úložná lehota pri dobierke (počet dní)'),
        SposobUhrady: __('Spôsob úhrady'),
        PodacieCisloPrefix: __('Podacie číslo (prefix)'),
        PodacieCisloOd: __('Podacie číslo od (vrátane)'),
        PodacieCisloDo: __('Podacie číslo do (vrátane)'),
        PodacieCisloSuffix: __('Podacie číslo (suffix)'),
    };

    /**
     * Dpd portaly.
     *
     * @type {Object}
     */
    dpdPortals = {
        'old': __('dpdportal.sk'),
        'new': __('dpdshipper.sk'),
    };

    /**
     * Komponenta bola pripojena.
     */
    componentDidMount() {
        const { title, setTitle, user } = this.props;

        // Nastavime title
        setTitle(title);

        if (toNumber(user.user_eshop_id) === 0) {
            return;
        }

        // Nacitame data
        this.fetch();
    }

    /**
     * Nacitame data.
     */
    fetch() {
        const { type, onClose } = this.props;

        // Nacitame data kuriera
        request('/user-couriers/get', { courier: type }).then(response => {
            const { status, data } = response.data;

            if (status === 'error') {
                // Kurier neexistuje
                onClose();
                return;
            }

            // Vytiahneme specific fields
            const specificFields = this.getSpecific(type);

            // Naformatujeme nastavenia
            const settings = _.reduce(data.settings, (result, settings, id) => {
                let services = settings.services;

                _.each(_.keys(data.services), service => {
                    if (!_.has(services, service)) {
                        services = { ...services, [service]: '' };
                    }
                });

                let specific = {};

                _.each(specificFields, specificField => {
                    let value = specificField.value;

                    if (_.has(settings.specific, specificField.name)) {
                        // Uz mame nastavene
                        value = settings.specific[specificField.name];
                    }

                    specific = { ...specific, [specificField.name]: value };
                });

                return [ ...result,  { ...settings, id, services, specific } ];
            }, []);

            this.setState({
                data,
                settings,
                fields: this.getDefaultFields(data.couriers[data.id].fields),
            });
        });
    }

    /**
     * Vratime zoznam default fieldov.
     *
     * @param {Object} fields
     *
     * @return {Object}
     */
    getDefaultFields(fields) {
        return _.reduce(
            fields,
            (result, field, name) => ({ ...result, [name]: _.isObject(field) ? _.keys(field)[0] : '' }),
            {}
        );
    }

    /**
     * Vratime specifikacie nastavenia.
     *
     * @param {string} name
     *
     * @return {Array}
     */
    getSpecific(name) {
        switch (name) {
            case 'Remax':
                return [
                    {
                        name: 'send',
                        label: __('Stav po importe u prepravcu'),
                        options: {
                            yes: __('Odoslané'),
                            no: __('Neodoslané'),
                        },
                        value: 'yes',
                    },
                ];

            case 'Kurier123':
            case 'Sps':
            case 'Qdl':
                return [
                    {
                        name: 'send',
                        label: __('Tlač štítkov'),
                        options: {
                            yes: __('Áno'),
                            no: __('Nie'),
                        },
                        value: 'yes',
                    },
                ];

            default:
                return [];
        }
    }

    /**
     * Event po zmene settingu.
     *
     * @param {int} key
     * @param {string} type
     * @param {string|Object} value
     */
    onChangeSetting(key, type, value) {
        let { settings } = this.state;

        settings = _.map(settings, (data, k) => {
            if (toNumber(k) === toNumber(key)) {
                // Editujeme
                return { ...data, [type]: value };
            }

            return data;
        });

        this.setState({ settings });
    }

    /**
     * Event po zmene sluzby.
     *
     * @param {int} key
     * @param {string} type
     * @param {string} value
     */
    onChangeService(key, type, value) {
        const { settings } = this.state;

        this.onChangeSetting(key, 'services', { ...settings[key].services, [type]: value });
    }

    /**
     * Event po zmene specifickeho nastavenia.
     *
     * @param {int} key
     * @param {string} type
     * @param {string} value
     */
    onChangeSpecific(key, type, value) {
        const { settings } = this.state;

        this.onChangeSetting(key, 'specific', { ...settings[key].specific, [type]: value });
    }

    /**
     * Pridame dalsie nastavenie.
     */
    addSetting() {
        const { settings } = this.state;

        this.setState({ settings: [ ...settings, resetObject(settings[0]) ] });
    }

    /**
     * Zmazeme nastavenie.
     *
     * @param {int} key
     */
    deleteSetting(key) {
        let { settings } = this.state;

        settings = _.remove(settings, (item, k) => k !== key);

        this.setState({ settings });
    }

    /**
     * Event po zmene fieldu.
     *
     * @param {string} field
     * @param {string} value
     */
    onChangeField(field, value) {
        const { fields } = this.state;

        this.setState({ fields: { ...fields, [field]: value } });
    }

    /**
     * Zobrazime setup.
     *
     * @param {string|number} setupId
     */
    showSetup(setupId = 'all') {
        this.setState({ showSetup: true, setupId });
    }

    /**
     * Schovame setup.
     */
    closeSetup() {
        this.setState({ showSetup: false });
    }

    /**
     * Skontrolujeme credentials.
     *
     * @param {boolean} create
     */
    checkCredentials(create) {
        const { showSnackbar } = this.props;
        const { data, fields, senders } = this.state;

        this.setState({ loading: true });

        if (!_.isEmpty(senders)) {
            // Mame zadany zoznam odosielatelov, pokracujeme dalej
            this.save(fields, create);
            return;
        }

        request('/user-couriers/credentials', fields, 'POST', { courier: data.couriers[data.id].name }).then(response => {
            const { status, data } = response.data;

            if (status === 'error') {
                this.setState({ loading: false });

                if (_.has(data, 'type')) {
                    switch (data.type) {
                        case 'invalid senders':
                            // Dopravca bez odosielatelov
                            showSnackbar('error', __('U dopravcu nemáte zaevidovaného odosielateľa'));
                            return;

                        default:
                            break;
                    }
                }

                showSnackbar('error', __('Nepodarilo sa prihlásiť'));
                return;
            }

            if (_.has(data.credentials, 'auth_url')) {
                // Presmerujeme na auth url
                window.location = data.credentials.auth_url;
                return;
            }

            if (!_.has(data.credentials, 'sender_id')) {
                // Nie je zadane sender id
                this.save(data.credentials, create);
                return;
            }

            const { senders, sender_id } = data.credentials;

            if (sender_id !== '' && sender_id !== '0' && sender_id !== 0) {
                // Mame zadanu polozku, pokracujeme
                this.save(data.credentials, create);
                return;
            }

            // Mame viac odosielatelov musime zobrazit select
            this.setState({
                loading: false,
                fields: { ...data.credentials, sender_id: _.keys(senders)[0] },
                senders,
            });
        });
    }

    /**
     * Ulozenie.
     *
     * @param {Object} credentials
     * @param {boolean} create
     */
    save(credentials, create = false) {
        const { showSnackbar, createCourier, changeUserSetting } = this.props;
        const { data, setupId } = this.state;

        if (create) {
            // Vytvarame
            let params = { courier: data.couriers[data.id].name };

            if (this.getTutorialStep() === 4) {
                // Sme na stvrtom kroku, rusime tutorial
                params = { ...params, tutorial_step: 0 };
            }

            request('/user-couriers/create', { courier_id: data.id, credentials }, 'POST', params).then(response => {
                const { status } = response.data;

                if (status === 'error') {
                    // Nepodarilo sa vytvorit
                    this.setState({ loading: false });
                    showSnackbar('error', __('Nepodarilo sa prihlásiť'));
                    return;
                }

                this.setState({ loading: false, data: {}, senders: {} });

                showSnackbar('success', __('Prihlasovacie údaje boli uložené'));

                // Nacitame data znovu
                this.fetch();

                // Vytvorime
                createCourier(data.id);

                if (_.has(params, 'tutorial_step')) {
                    // Schovame tutorial
                    changeUserSetting('tutorial_step', 0);
                }
            });
            return;
        }

        // Zmenime nastavenia
        request('/user-couriers/changeCredentials', { credentials_id: setupId, credentials }, 'POST', { courier: data.couriers[data.id].name }).then(response => {
            const { status } = response.data;

            this.setState({ loading: false });

            if (status === 'error') {
                // Nepodarilo sa zmenit credentials
                showSnackbar('error', __('Nepodarilo sa zmeniť prihlasovacie údaje'));
                return;
            }

            this.setState({ loading: false, data: {}, senders: {}, showSetup: false });

            showSnackbar('success', __('Prihlasovacie údaje boli zmenené'));

            // Nacitame data znovu
            this.fetch();
        });
    }

    /**
     * Zmazeme prepravcu.
     */
    delete() {
        const { showSnackbar, onClose, deleteCourier } = this.props;
        const { data } = this.state;

        this.setState({ loadingDelete: true });

        request('/user-couriers/delete', { courier: data.couriers[data.id].name }).then(response => {
            const { status } = response.data;

            if (status === 'error') {
                // Nepodarilo sa zmazat
                this.setState({ loadingDelete: false });
                showSnackbar('error', __('Nepodarilo sa deaktivovať prepravcu'));
                return;
            }

            showSnackbar('success', __('Prepravca bol deaktivovaný'));

            // Zavrieme
            onClose();

            // Zmazeme
            deleteCourier(data.id);
        });
    }

    /**
     * Zmena nastaveni.
     */
    changeSettings() {
        const { showSnackbar, onClose } = this.props;
        const { data, settings } = this.state;

        this.setState({ loading: true });

        request('/user-couriers/changeSettings', { settings }, 'POST', { courier: data.couriers[data.id].name }).then(response => {
            const { status } = response.data;

            this.setState({ loading: false });

            if (status === 'error') {
                // Nepodarilo sa zmenit nastavenia
                showSnackbar('error', __('Nepodarilo sa zmeniť nastavenia'));
                return;
            }

            showSnackbar('success', __('Nastavenia boli zmenené'));

            // Zavrieme
            onClose();
        });
    }

    /**
     * Rendrovanie fieldov.
     *
     * @param {Object} items
     *
     * @return {JSX.Element[]}
     */
    renderFields(items) {
        const { fields } = this.state;

        return _.map(items, (field, name) => {
            const label = _.has(this.labels, name) ? this.labels[name] : '';

            switch (name) {
                case 'market':
                    // Lokalizacia
                    return <Select
                        key={name}
                        label={label}
                        options={field}
                        value={fields[name]}
                        onChange={value => this.onChangeField(name, value)}
                        allowEmpty={false}
                    />;

                case 'sender_id':
                    // Firma
                    return <Select
                        key={name}
                        label={label}
                        options={field}
                        value={fields[name]}
                        onChange={value => this.onChangeField(name, value)}
                        allowEmpty={false}
                    />;

                case 'dpd_portal':
                    // DPD portal
                    return <Select
                        key={name}
                        label={label}
                        options={this.dpdPortals}
                        value={fields[name]}
                        onChange={value => this.onChangeField(name, value)}
                        allowEmpty={false}
                    />;

                case 'sender':
                    // Sender
                    return <Input
                        key={name}
                        label={label}
                        value={fields[name]}
                        onChange={value => this.onChangeField(name, value)}
                    />;

                case 'company_id':
                    // Company id
                    return <Input
                        key={name}
                        label={label}
                        value={fields[name]}
                        onChange={value => this.onChangeField(name, value)}
                    />;

                case 'id':
                    // ID
                    return <Input
                        key={name}
                        label={label}
                        value={fields[name]}
                        onChange={value => this.onChangeField(name, value)}
                    />;

                case 'email':
                    // Email
                    return <Input
                        type="email"
                        key={name}
                        label={label}
                        value={fields[name]}
                        onChange={value => this.onChangeField(name, value)}
                    />;

                case 'username':
                    // Prihlasovacie meno
                    return <Input
                        key={name}
                        label={label}
                        value={fields[name]}
                        onChange={value => this.onChangeField(name, value)}
                    />;

                case 'password':
                    // Heslo
                    return <Input
                        type="password"
                        key={name}
                        label={label}
                        value={fields[name]}
                        onChange={value => this.onChangeField(name, value)}
                    />;

                case 'iban':
                case 'apikey':
                case 'bic':
                case 'client_id':
                case 'client_secret':
                case 'agreement':
                case 'accounting_code':
                case 'account_number':
                    // Iban
                    return <Input
                        key={name}
                        label={label}
                        value={fields[name]}
                        onChange={value => this.onChangeField(name, value)}
                    />;

                default:
                    return null;
            }
        });
    }

    /**
     * Rendrovanie.
     *
     * @return {JSX.Element}
     */
    render() {
        const { user } = this.props;
        const { data, loading, loadingDelete, showSetup, senders, settings } = this.state;

        if (toNumber(user.user_eshop_id) === 0) {
            return <div
                className="courier-content"
            ><Message type="warning">{__('Najprv musíte aktivovať eshop')}</Message></div>;
        }

        if (_.isEmpty(data)) {
            // Data nie su nacitane
            return <div
                className="courier-content courier-content-loading"
            >{this.renderLoading()}</div>;
        }

        // Je aktivne?
        const activated = !_.isEmpty(data.credentials);

        // Fieldy na generovanie
        let { name, fields } = data.couriers[data.id];

        if (!_.isEmpty(senders)) {
            // Mame zadany zoznam odosielatelov zobrazime len ten
            fields = { sender_id: senders };
        }

        // Typy dodani
        const delivers = _.reduce(data.eshop_data.delivers, (result, { id, name }) => ({ ...result, [id]: name }), {});

        // Typy stavov
        const states = _.reduce(data.eshop_data.states, (result, { id, name }) => ({ ...result, [id]: name }), {});

        // Typy platieb
        const payments = _.reduce(data.eshop_data.payments, (result, { id, name }) => ({ ...result, [id]: name }), {});

        // Vytiahneme zoznam specifickych nastaveni
        const specificSettings = this.getSpecific(name);

        // Mame viac nastaveni?
        const multipleSettings = settings.length > 1;

        return (
            <div className={`courier-content ${name}`}>
                {!showSetup && activated ? <div className="courier-content__data">
                    <div className="courier-content__data__credentials">
                        <div className="courier-content__data__credentials__title">{__('Prihlasovacie údaje')}</div>
                        {_.map(data.credentials, (value, name) => {
                            if (!_.has(this.labels, name)) {
                                return null;
                            }

                            if (
                                name === 'sender_id'
                                && _.has(data.credentials, 'senders')
                                && _.has(data.credentials.senders, value)
                            ) {
                                // Chceme nazov
                                value = data.credentials.senders[value];
                            } else if (name === 'dpd_portal') {
                                // Chceme nazov
                                value = this.dpdPortals[value];
                            }

                            return (
                                <div className="courier-content__data__credentials__value" key={name}>
                                    <div className="courier-content__data__credentials__value__name">
                                        {this.labels[name]}:
                                    </div>
                                    <div className="courier-content__data__credentials__value__text">
                                        {_.truncate(value, { length: 30 })}
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                    <div className="courier-content__data__buttons">
                        <Button
                            onClick={() => this.showSetup()}
                            color="shadow"
                            className="courier-content__data__buttons__button"
                        >{__('Upraviť')}</Button>
                        <Button
                            onClick={() => this.delete()}
                            color="red"
                            className="courier-content__data__buttons__button"
                            loading={loadingDelete}
                        >{__('Zmazať')}</Button>
                    </div>
                    {!_.isEmpty(settings) ? <div className="courier-content__data__settings">
                        <ContentPart title={__('Nastavenia')} />
                        {_.map(settings, (setting, key) => {
                            const disabledCredentials = toNumber(setting.id) === 0;

                            return (
                                <div className="courier-content__data__settings__setting" key={key}>
                                    {multipleSettings ? <div className="courier-content__data__settings__setting__header">
                                        <Input
                                            label={__('Názov nastavenia')}
                                            value={setting.name}
                                            onChange={value => this.onChangeSetting(key, 'name', value)}
                                            placeholder={`${name} ${key + 1}`}
                                        />
                                        <Tooltip title={!disabledCredentials
                                            ? __('Zmeniť prihlasovacie údaje konkrétnemu nastaveniu')
                                            : __('Najprv musíte uložiť nastavenie')}>
                                        <span><Button
                                            onClick={() => this.showSetup(setting.id)}
                                            disabled={disabledCredentials}
                                        >{__('Zmeniť prihlasovacie údaje')}</Button></span>
                                        </Tooltip>
                                    </div> : null}
                                    <div className="courier-content__data__settings__setting__selects">
                                        {!_.isEmpty(data.types) ? <Select
                                            label={__('Typ zásielky')}
                                            options={data.types}
                                            value={setting.type}
                                            onChange={value => this.onChangeSetting(key, 'type', value)}
                                        /> : null}
                                        <Select
                                            label={__('Typ dodania objednávky')}
                                            options={delivers}
                                            value={setting.delivery_id}
                                            onChange={value => this.onChangeSetting(key, 'delivery_id', value)}
                                            multiple
                                        />
                                        <Select
                                            label={__('Dobierka')}
                                            options={payments}
                                            value={setting.cod_payment}
                                            onChange={value => this.onChangeSetting(key, 'cod_payment', value)}
                                            placeholder={__('Automatické rozpoznanie systémom')}
                                        />
                                        <Select
                                            label={__('Stav objednávky po odoslaní zásielky')}
                                            options={states}
                                            value={setting.state_after_send}
                                            onChange={value => this.onChangeSetting(key, 'state_after_send', value)}
                                        />
                                        <Select
                                            label={__('Stav objednávky po doručení zásielky')}
                                            options={states}
                                            value={setting.state_after_delivery}
                                            onChange={value => this.onChangeSetting(key, 'state_after_delivery', value)}
                                        />
                                        {multipleSettings ? <Tooltip title={__('Zmazať')}>
                                            <IconButton
                                                onClick={() => this.deleteSetting(key)}
                                                className="courier-content__data__settings__setting__selects__button"
                                            >
                                                <DeleteIcon />
                                            </IconButton>
                                        </Tooltip> : null}
                                    </div>
                                    {!_.isEmpty(specificSettings)
                                        ? <div className="courier-content__data__settings__setting__title">{__('Doplnkové nastavenia')}</div>
                                        : null}
                                    {!_.isEmpty(specificSettings)
                                        ? <div className="courier-content__data__settings__setting__selects">
                                            {_.map(specificSettings, item => <Select
                                                key={item.name}
                                                label={item.label}
                                                options={item.options}
                                                value={setting.specific[item.name]}
                                                onChange={value => this.onChangeSpecific(key, item.name, value)}
                                                allowEmpty={false}
                                            />)}
                                        </div> : null}
                                    {!_.isEmpty(data.services)
                                        ? <div className="courier-content__data__settings__setting__title">{__('Doplnkové služby')}</div>
                                        : null}
                                    {!_.isEmpty(data.services)
                                        ? <div className="courier-content__data__settings__setting__services">
                                            {_.map(data.services, ({ type, name, options }, subkey) => {
                                                switch (type) {
                                                    case 'text':
                                                        if (subkey.indexOf('PodacieCislo') !== -1 && !setting.services.PodacieCislo) {
                                                            return null;
                                                        }

                                                        return <Input
                                                            key={subkey}
                                                            label={_.has(this.servicesLabels, subkey) ? this.servicesLabels[subkey] : name}
                                                            value={setting.services[subkey]}
                                                            onChange={value => this.onChangeService(key, subkey, value)}
                                                        />;

                                                    case 'select':
                                                        return <Select
                                                            key={subkey}
                                                            label={_.has(this.servicesLabels, subkey) ? this.servicesLabels[subkey] : name}
                                                            value={setting.services[subkey]}
                                                            options={options}
                                                            onChange={value => this.onChangeService(key, subkey, value)}
                                                        />;

                                                    case 'checkbox':
                                                        return <Checkbox
                                                            key={subkey}
                                                            label={name}
                                                            value={setting.services[subkey]}
                                                            onChange={checked => this.onChangeService(key, subkey, checked)}
                                                        />;

                                                    default:
                                                        return null;
                                                }
                                            })}
                                        </div>
                                        : null}
                                </div>
                            );
                        })}
                    </div> : null}
                </div> : <div className="courier-content__data">
                    {!_.isEmpty(senders) ? <Message type="warning">{__('U prepravcu máte zaevidovaných viacero odosielateľov, prosím vyberte konkrétneho odosielateľa.')}</Message> : null}
                    {this.renderFields(fields)}
                </div>}
                <div className="courier-content__buttons">
                    {showSetup ? <Button
                        onClick={() => this.closeSetup()}
                        color="shadow"
                        className="courier-content__buttons__button"
                    >{__('Naspäť')}</Button> : null}
                    {!showSetup && activated ? <Button
                        onClick={() => this.addSetting()}
                        color="shadow"
                        className="courier-content__buttons__button"
                    >{__('Pridať nastavenie')}</Button> : null}
                    <Button
                        onClick={!showSetup && activated
                            ? () => this.changeSettings()
                            : () => this.checkCredentials(!showSetup)}
                        color="green"
                        className="courier-content__buttons__button"
                        loading={loading}
                    >{__('Uložiť')}</Button>
                </div>
            </div>
        );
    }
}

export { Courier };
