import { CLEAN_MTOKNA_TEMPLATE, FETCH_MTOKNA_TEMPLATE } from '../actions/types';

const MTOKNA_TEMPLATE_DEFAULT_STATE = {};

/**
 * Reducer pre sablonu.
 */
const MtoknaTemplateReducer = (state = MTOKNA_TEMPLATE_DEFAULT_STATE, { type, payload }) => {
    switch (type) {
        case FETCH_MTOKNA_TEMPLATE:
            // Nacitanie sablony
            return payload;

        case CLEAN_MTOKNA_TEMPLATE:
            // Vycistenie sablony
            return MTOKNA_TEMPLATE_DEFAULT_STATE;

        default:
            return state;
    }
};

export default MtoknaTemplateReducer;
