import { FETCH_MTOKNA_DOG_PRODUCTS, CLEAN_MTOKNA_DOG_PRODUCTS, CHANGE_MTOKNA_DOG_PRODUCT, DELETE_MTOKNA_DOG_PRODUCT } from '../actions/types';
import _ from 'lodash';
import { toNumber } from '../functions';

const MTOKNA_DOG_PRODUCTS_DEFAULT_STATE = {};

const MtoknaDogProductsReducer = (state = MTOKNA_DOG_PRODUCTS_DEFAULT_STATE, { type, payload }) => {
    switch (type) {
        case FETCH_MTOKNA_DOG_PRODUCTS:
            return payload;

        case CHANGE_MTOKNA_DOG_PRODUCT:
            return { ...state, items: _.map(state.items, item => {
                if (toNumber(item.id) === toNumber(payload.id)) {
                    item = { ...item, ...payload.data };
                }

                return item;
            }) };

        case CLEAN_MTOKNA_DOG_PRODUCTS:
        case DELETE_MTOKNA_DOG_PRODUCT:
            return MTOKNA_DOG_PRODUCTS_DEFAULT_STATE;

        default:
            return state;
    }
};

export default MtoknaDogProductsReducer;
