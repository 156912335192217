import React from 'react';
import { connect } from 'react-redux';
import { withCookies } from 'react-cookie';
import _ from 'lodash';
import {ListScreen, MtoknaOrder, Select} from '../components';
import {
    fetchMtoknaComplaints,
    cleanMtoknaComplaints,
    setUser,
    changeUserSetting,
    changeMtoknaComplaintData,
    deleteMtoknaComplaint,
    pdfMtoknaComplaint,
    changeMtoknaComplaintState,
    cancelMtoknaComplaint,
} from '../actions';
import {__, formatDate, formatAmount, getOrderButtons} from '../functions';
import '../assets/styles/mtokna_complaints.css';
import CancelIcon from "@mui/icons-material/Cancel";
import EditIcon from "@mui/icons-material/Edit";

/**
 * Objednavky.
 */
class MtoknaComplaintsScreen extends ListScreen {
    /**
     * Title.
     *
     * @type {string}
     */
    title = __('Reklamácie');

    /**
     * Default state.
     *
     * @type {Object}
     */
    state = { ...ListScreen.state, ...{
            loadingSync: false,
            lightbox: {
                delete: false,
                state: false,
                cancel: false,
            },
        }};

    // Memory cache
    memoryCacheStates = null;

    /**
     * Bol volany klik na objednavku.
     *
     * @type {boolean}
     */
    callViewClick = false;

    defaultState = {};

    /**
     * Nacitame zoznam.
     *
     * @param {number} page
     * @param {number} per_page
     * @param {Object} filtered
     * @param {function|null} callback
     */
    async fetchList(page = 1, per_page = 0, filtered = {}, callback = null) {
        this.page = page;

        const { fetch } = this.props;

        // Nacitame zoznam
        await fetch(this, { page, per_page, filtered, callback });

        const { items } = this.props;

        if (!_.isEmpty(items.filtered)) {
            // Nastavime filter
            this.setState({ filtered: items.filtered });
        }

        this.afterFetchList();
    }

    /**
     * Vratime zoznam tagov.
     *
     * @return {Array}
     */
    getTags() {
        const { user } = this.props;

        if (user.mtokna_moderator) {
            return [];
        }

        return [
            {
                name: __('Nová reklamácia'),
                onClick: () => this.redirect('/manual/mo/38/new/complaint'),
            }
        ];
    }

    /**
     * Vratime nazov tabulky.
     *
     * @return {string}
     */
    getTableName() {
        return 'mtokna_complaints';
    }

    getColumns() {
        return [
            <div>{`${__('Číslo')} / ${__('Suma')} / ${__('Dátum')}`}</div>,
            <div>{`${__('Zákaznik')} / ${__('E-mail')} / ${__('Eshop')}`}</div>,
            <div>{`${__('Tel. číslo')} / ${__('Fakturačná adresa')} / ${__('Dodacia adresa')}`}</div>,
            <div>{`${__('Poznámka')} / ${__('Číslo faktúry')} / ${__('Popis reklamácie')}`}</div>,
            <div></div>,
        ];
    }

    /**
     * Vratime bunky.
     *
     * @param {Object} item
     *
     * @return {Array}
     */
    getCells(item) {
        const { user, items } = this.props;

        // Vytiahneme tlacitka pre objednavku
        let buttons = getOrderButtons(item, {
            settings: {
                meta_data_pdf_link: '',
            },
            hasPermissionView: true,
            hasPermissionDelete: true,
            hasPermissionPdfOrder: true,
        });

        // Nastavime callbacky
        if (typeof buttons.view !== 'undefined') {
            buttons.view.callback = (callbackLoading, callback) => this.showDrawer('order', {
                id: item.id,
                buttons,
                callbackLoading,
                callback,
            });
        }

        if (typeof buttons.delete !== 'undefined') {
            buttons.delete.callback = (callbackLoading, callback) => this.confirmDelete(item.id, callbackLoading, callback);
        }

        // Nastavime callbacky
        if (typeof buttons.pdfOrder !== 'undefined') {
            buttons.pdfOrder.callback = (callbackLoading, callback) => this.pdfOrder(item.id, callbackLoading, callback);
        }

        let removeButtons = ['sync', 'pdfLink', 'paid', 'send', 'createInvoice', 'pdf'];
        let states = items.states;

        if (user.mtokna_type === 'mtokna_seller' || user.mtokna_moderator) {
            removeButtons = ['sync', 'pdfLink', 'paid', 'send', 'createInvoice', 'pdf', 'delete'];

            states = { [item._matchingData.MtoknaComplaints.state]: states[item._matchingData.MtoknaComplaints.state] };
        }

        let color = item.data.state.color;
        let state = item._matchingData.MtoknaComplaints.state;

        if (state === 'Prijatá') {
            color = 'orange';
        } else if (state === 'Na ceste k predajcovi') {
            color = 'grey';
        } else if (state === 'Zamietnutá') {
            color = 'red';
        } else if (state === 'Vybavená') {
            color = 'green';
        }

        return [
            <div>
                <div style={{ backgroundColor: color }} />
                <div>
                    <div>{item.data.number}</div>
                </div>
                <div>{formatAmount(item.data.amount, item.data.currency)}</div>
                <div>{formatDate(item.data.date)}</div>
            </div>,
            <div>
                <div>{_.truncate(item.data.client.name, { length: 32 })}</div>
                <div>{_.truncate(item.data.client.email, { length: 32 })}</div>
                <div>{_.has(items.user_eshops, item.user_eshop_id) ? items.user_eshops[item.user_eshop_id] : '-'}</div>
            </div>,
            <div>
                <div>{item.data.client.phone}</div>
                <div>{_.truncate(`${item.data.client.address}, ${item.data.client.city}`, { length: 32 })}</div>
                <div>{_.truncate(`${item.data.client.delivery_address}, ${item.data.client.delivery_city}`, { length: 32 })}</div>
            </div>,
            <div>
                <div>{item.data.client.delivery_note !== '' ? _.truncate(item.data.client.delivery_note, { length: 32 }) : '-'}</div>
                <div>{item._matchingData.MtoknaComplaints.invoice_number !== '' ? _.truncate(item._matchingData.MtoknaComplaints.invoice_number, { length: 32 }) : '-'}</div>
                <div>{item._matchingData.MtoknaComplaints.text !== '' ? _.truncate(item._matchingData.MtoknaComplaints.text, { length: 32 }) : '-'}</div>
            </div>,
            <div>
                {!this.isMobile() ? <div>
                    <Select
                        options={states}
                        value={item._matchingData.MtoknaComplaints.state}
                        onChange={value => this.confirmState(item.id, value, '')}
                        allowEmpty={false}
                        variant="standard"
                    />
                </div> : null}
                <div>
                    {user.mtokna_type === 'mtokna_admin' && !user.mtokna_moderator ? this.renderTableButton(
                        item.id,
                        __('Upraviť'),
                        <EditIcon />,
                        () => this.redirect(`/manual/mo/${item.user_eshop_id}/${item.id}/complaint`),
                        {}
                    ) : null}
                    {user.mtokna_type === 'mtokna_admin' && !user.mtokna_moderator ? this.renderTableButton(
                        item.id,
                        __('Zamietnuť'),
                        <CancelIcon />,
                        (callbackLoading, callback) => this.confirmCancel(item.id, callbackLoading, callback),
                        {}
                    ) : null}
                    {this.renderTableButtons(item.id, buttons, removeButtons, false)}
                </div>
            </div>,
        ];
    }

    /**
     * Vratime options buniek.
     *
     * @return {Object}
     */
    getCellsOptions() {
        return {
            number: {
                label: __('Číslo'),
                get: item => item.data.number,
            },
            amount: {
                label: __('Suma'),
                get: item => formatAmount(item.data.amount, item.data.currency),
            },
            created: {
                label: __('Dátum'),
                get: item => formatDate(item.data.date),
            },
            client_name: {
                label: __('Zákaznik'),
                get: item => _.truncate(item.data.client.name, { length: 32 }),
            },
            client_delivery_name: {
                label: __('Zákaznik (dodanie)'),
                get: item => _.truncate(item.data.client.delivery_name, { length: 32 }),
            },
            client_contact_name: {
                label: __('Kontaktná osoba'),
                get: item => _.truncate(item.data.client.contact_name, { length: 32 }),
            },
            client_delivery_contact_name: {
                label: __('Kontaktná osoba (dodanie)'),
                get: item => _.truncate(item.data.client.delivery_contact_name, { length: 32 }),
            },
            client_company: {
                label: __('Firemné údaje'),
                get: item => !_.isEmpty(item.data.client.ico)
                    ? _.truncate(`${__('IČO')}: ${item.data.client.ico}, ${__('DIČ')}: ${item.data.client.dic}, ${__('IČ DPH')}: ${item.data.client.ic_dph}`, { length: 32 })
                    : '',
            },
            client_address: {
                label: __('Fakturačná adresa'),
                get: item => _.truncate(`${item.data.client.address}, ${item.data.client.city}`, { length: 32 }),
            },
            client_delivery_address: {
                label: __('Dodacia adresa'),
                get: item => _.truncate(`${item.data.client.delivery_address}, ${item.data.client.delivery_city}`, { length: 32 }),
            },
            client_phone: {
                label: __('Tel. číslo'),
                get: item => item.data.client.phone,
            },
            client_delivery_phone: {
                label: __('Tel. číslo (dodanie)'),
                get: item => item.data.client.delivery_phone,
            },
            client_email: {
                label: __('E-mail'),
                get: item => _.truncate(item.data.client.email, { length: 32 }),
            },
            client_note: {
                label: __('Poznámka'),
                get: item => _.truncate(item.data.client.delivery_note, { length: 32 }),
            },
            delivery_name: {
                label: __('Dodanie'),
                get: item => _.truncate(item.data.delivery_type_name, { length: 32 }),
            },
            payment_name: {
                label: __('Platba'),
                get: item => _.truncate(item.data.payment_type_name, { length: 32 }),
            },
            internal_comment: {
                label: __('Interná poznámka'),
                get: item => _.truncate(item.data.internal_comment, { length: 32 }),
            },
            package_number: {
                label: __('Číslo zásielky'),
                get: item => item.package.number.toString().indexOf('-') === -1 ? item.package.number : '',
            },
        };
    }

    /**
     * Vratime nastavenia buniek.
     *
     * @return {Object}
     */
    getCellsSettings() {
        return {};
    }

    /**
     * Vratime filter.
     *
     * @return {Object}
     */
    getFilter() {
        const { items } = this.props;

        return _.reduce(items.filter, (result, options, key) => {
            switch (key) {
                case 'MtoknaComplaints-state':
                    return { ...result, ...{ [key]: {
                                type: 'select',
                                name: __('Stav'),
                                value: '',
                                options,
                                multiple: true,
                            }}};

                default:
                    return result;
            }
        }, {});
    }

    /**
     * Vratime empty text.
     *
     * @return {string}
     */
    getEmptyText() {
        return __('Zatiaľ nemáte žiadne reklamácie');
    }

    /**
     * Zobrazime lightbox na zmazanie.
     *
     * @param {number} id
     * @param {function} callbackLoading
     * @param {function} callback
     */
    confirmDelete(id, callbackLoading, callback) {
        this.showLightbox('delete', { id, callbackLoading, callback });
    }

    /**
     * Zobrazime lightbox na zmazanie.
     *
     * @param {number} id
     * @param {function} callbackLoading
     * @param {function} callback
     */
    confirmCancel(id, callbackLoading, callback) {
        this.showLightbox('cancel', { id, callbackLoading, callback });
    }

    /**
     * Zobrazime lightbox na zmenu stavu.
     *
     * @param {number} orderId
     * @param {string} stateId
     * @param {string} viewText
     */
    confirmState(orderId, stateId, viewText) {
        this.showLightbox('state', { orderId, stateId, viewText });
    }

    /**
     * Stiahnutie order pdf.
     *
     * @param {number} id
     * @param {function} callbackLoading
     * @param {function} callback
     */
    async pdfOrder(id, callbackLoading, callback) {
        const { pdfMtoknaComplaint } = this.props;

        // Zavolame loading
        callbackLoading();

        // Zavolame callback
        await pdfMtoknaComplaint(this, id);

        // Zavolame callback
        callback();
    }

    /**
     * Event po zmene stavu objednavky.
     */
    async changeState() {
        const { lightbox } = this.state;
        const { changeMtoknaComplaintState } = this.props;

        // Zavrieme lightbox
        this.closeLightbox('state');

        // Zobrazime loading
        this.loadingTableButton(this.getLoadingTableButtonId(lightbox.state.orderId, lightbox.state.viewText));

        // Zmenime stav
        await changeMtoknaComplaintState(this, lightbox.state.orderId, lightbox.state.stateId);

        // Zrusime loading table button
        this.clearTableButton();
    }

    /**
     * Zmazanie objednavky.
     */
    async delete() {
        const { deleteMtoknaComplaint, items } = this.props;
        const { lightbox } = this.state;

        // Zavolame loading
        lightbox.delete.callbackLoading();

        // Zavrieme lightbox
        this.closeLightbox('delete');

        // Zmazeme objednavku
        await deleteMtoknaComplaint(this, lightbox.delete.id);

        // Zavolame callback
        lightbox.delete.callback();

        // Znovu nacitame zoznam od prvej stranky
        this.fetchList(1, 0, items.filtered);
    }

    /**
     * Zmazanie objednavky.
     */
    async cancel() {
        const { cancelMtoknaComplaint } = this.props;
        const { lightbox } = this.state;

        // Zavolame loading
        lightbox.cancel.callbackLoading();

        // Zavrieme lightbox
        this.closeLightbox('cancel');

        // Zmazeme objednavku
        await cancelMtoknaComplaint(this, lightbox.cancel.id);

        // Zavolame callback
        lightbox.cancel.callback();
    }

    /**
     * Rendrujeme screen.
     *
     * @return {JSX.Element|null}
     */
    renderScreen() {
        const { items, user, changeMtoknaComplaintData } = this.props;

        return (
            <div>
                {this.renderLightbox(
                    'state',
                    __('Naozaj chcete zmeniť stav tejto reklamácii?'),
                    null,
                    __('Áno'),
                    __('Nie'),
                    () => this.changeState()
                )}
                {this.renderLightbox(
                    'delete',
                    __('Naozaj chcete zmazať túto reklamáciu?'),
                    null,
                    __('Áno'),
                    __('Nie'),
                    () => this.delete()
                )}
                {this.renderLightbox(
                    'cancel',
                    __('Naozaj chcete zamietnuť túto reklamáciu?'),
                    null,
                    __('Áno'),
                    __('Nie'),
                    () => this.cancel()
                )}
                {this.renderDrawer('order', {
                    title: __('Reklamácia'),
                    content: props => <MtoknaOrder
                        user={user}
                        showSnackbar={(type, message) => this.showSnackbar(type, message)}
                        changeMtoknaOrderData={changeMtoknaComplaintData}
                        settings={items.settings}
                        manual={true}
                        complaint={true}
                        {...props}
                    />,
                })}
                {this.renderOffer()}
            </div>
        );
    }
}

const stateToProps = ({ mtokna_complaints, user }) => ({ items: mtokna_complaints, user });

export default withCookies(connect(stateToProps, {
    fetch: fetchMtoknaComplaints,
    clean: cleanMtoknaComplaints,
    setUser,
    changeUserSetting,
    changeMtoknaComplaintData,
    deleteMtoknaComplaint,
    pdfMtoknaComplaint,
    changeMtoknaComplaintState,
    cancelMtoknaComplaint,
})(MtoknaComplaintsScreen));
