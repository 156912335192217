import {
    FETCH_MTOKNA_MODERATORS,
    CLEAN_MTOKNA_MODERATORS,
    CHANGE_MTOKNA_MODERATOR_EMAIL,
    DELETE_MTOKNA_MODERATOR,
} from '../actions/types';
import _ from 'lodash';
import { toNumber } from '../functions';

const MTOKNA_MODERATORS_DEFAULT_STATE = {};

const MtoknaModeratorsReducer = (state = MTOKNA_MODERATORS_DEFAULT_STATE, { type, payload }) => {
    switch (type) {
        case FETCH_MTOKNA_MODERATORS:
            return payload;

        case CHANGE_MTOKNA_MODERATOR_EMAIL:
            return { ...state, items: _.map(state.items, item => {
                if (toNumber(item.user_id) === toNumber(payload.id)) {
                    item = { ...item, _matchingData: {
                        ...item._matchingData,
                        Users: { ...item._matchingData.Users, email: payload.email }
                    } };
                }

                return item;
            }) };

        case CLEAN_MTOKNA_MODERATORS:
        case DELETE_MTOKNA_MODERATOR:
            return MTOKNA_MODERATORS_DEFAULT_STATE;

        default:
            return state;
    }
};

export default MtoknaModeratorsReducer;
