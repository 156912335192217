import { FETCH_ADERYN_PRODUCTS, CLEAN_ADERYN_PRODUCTS, CHANGE_ADERYN_PRODUCT } from '../actions/types';
import _ from 'lodash';
import { toNumber } from '../functions';

const ADERYN_PRODUCTS_DEFAULT_STATE = {};

const AderynProductsReducer = (state = ADERYN_PRODUCTS_DEFAULT_STATE, { type, payload }) => {
    switch (type) {
        case FETCH_ADERYN_PRODUCTS:
            return payload;

        case CHANGE_ADERYN_PRODUCT:
            return { ...state, items: _.map(state.items, item => {
                if (toNumber(item.id) === toNumber(payload.id)) {
                    item = payload;
                }

                return item;
            }) };

        case CLEAN_ADERYN_PRODUCTS:
            return ADERYN_PRODUCTS_DEFAULT_STATE;

        default:
            return state;
    }
};

export default AderynProductsReducer;
