import {
    FETCH_TRACK_TRACE,
    CLEAN_TRACK_TRACE,
    CHANGE_TRACK_TRACE_SETTINGS,
    TRACK_TRACE_UPLOAD_LOGO,
} from '../actions/types';

const TRACK_TRACE_DEFAULT_STATE = [];

/**
 * Reducer pre track trace.
 */
const TrackTraceReducer = (state = TRACK_TRACE_DEFAULT_STATE, { type, payload }) => {
    switch (type) {
        case FETCH_TRACK_TRACE:
            // Nacitanie nastaveni
            return payload;

        case CHANGE_TRACK_TRACE_SETTINGS:
            // Zmena nastaveni
            return { ...state, settings: payload };

        case TRACK_TRACE_UPLOAD_LOGO:
            // Upload loga
            return { ...state, logo: payload };

        case CLEAN_TRACK_TRACE:
            // Vycistenie nastaveni
            return TRACK_TRACE_DEFAULT_STATE;

        default:
            return state;
    }
};

export default TrackTraceReducer;
