import _ from 'lodash';
import { FETCH_COMPLAINTS, CLEAN_COMPLAINTS, DELETE_COMPLAINT, DELETE_COMPLAINTS } from './types';
import { request, __ } from '../functions';

/**
 * Nacitanie reklamacii.
 *
 * @param {Screen} screen
 * @param {object} options
 */
export const fetchComplaints = (screen, options = {}) => {
    const defaults = {
        page: 1,
        per_page: 0,
        filtered: {},
    };

    options = { ...defaults, ...options };

    let params = { page: options.page, per_page: options.per_page };

    // Nasetujeme filter
    _.forEach(options.filtered, (value, key) => {
        params = { ...params, ...{ [`filter_${key}`]: value } };
    });

    return dispatch => request('/complaints/index', params).then(response => {
        const { data, sync } = response.data;
        const { setUser } = screen.props;

        // Nasetujeme user data
        setUser(sync.user);

        dispatch({ type: FETCH_COMPLAINTS, payload: data });
    });
};

/**
 * Zmazanie reklamacie.
 *
 * @param {Screen} screen
 * @param {number} id
 */
export const deleteComplaint = (screen, id) => {
    return dispatch => request(`/complaints/delete/${id}`).then(response => {
        const { status } = response.data;

        if (status === 'error') {
            // Reklamaciu sa nepodarilo zmazat
            screen.showSnackbar('error', __('Nepodarilo sa zmazať reklamáciu'));
            return;
        }

        screen.showSnackbar('success', __('Reklamácia bola zmazaná'));

        dispatch({ type: DELETE_COMPLAINT });
    });
};

/**
 * Zmazanie reklamacii cez multiselect.
 *
 * @param {Screen} screen
 * @param {string} ids
 * @param {Object} filtered
 */
export const deleteComplaints = (screen, ids, filtered = {}) => {
    let params = {};

    // Nasetujeme filter
    _.forEach(filtered, (value, key) => {
        params = { ...params, ...{ [`filter_${key}`]: value } };
    });

    return dispatch => request(`/complaints/multiDelete/${ids}`, params).then(response => {
        const { status } = response.data;

        if (status === 'error') {
            // Reklamacie sa nepodarilo zmazat
            screen.showSnackbar('error', __('Reklamácie sa nepodarilo zmazať'));
            return;
        }

        screen.showSnackbar('success', __('Reklamácie boli zmazané'));

        dispatch({ type: DELETE_COMPLAINTS });
    });
};

/**
 * Vycistenie reklamacii.
 */
export const cleanComplaints = () => ({ type: CLEAN_COMPLAINTS });
