import { __, request } from '../functions';
import { FETCH_ADDONS, CREATE_ADDON, DELETE_ADDON, CLEAN_ADDONS } from './types';

/**
 * Nacitanie addonov.
 *
 * @param {Screen} screen
 */
export const fetchAddons = screen => {
    return dispatch => request('/user-addons/index').then(response => {
        const { data, sync } = response.data;

        const { user } = sync;
        const { setUser } = screen.props;

        // Nasetujeme usera
        setUser(user);

        dispatch({ type: FETCH_ADDONS, payload: data });
    });
};

/**
 * Pridanie addonu.
 *
 * @param {Screen} screen
 * @param {number} addonId
 */
export const createAddon = (screen, addonId) => {
    return dispatch => request('/user-addons/create', { addon_id: addonId }, 'POST').then(response => {
        const { status, data } = response.data;

        screen.setState({ loadingAddon: 0 });

        if (status === 'error') {
            // Nepodarilo sa pridat addon
            screen.showSnackbar('error', __('Nepodarilo sa aktivovať doplnok'));
            return;
        }

        screen.showSnackbar('success', __('Doplnok bol aktivovaný'));

        dispatch({ type: CREATE_ADDON, payload: { id: data.id, addon_id: addonId } });
    });
};

/**
 * Zmazanie addonu.
 *
 * @param {Screen} screen
 * @param {number} id
 */
export const deleteAddon = (screen, id) => {
    return dispatch => request(`/user-addons/delete/${id}`).then(response => {
        const { status } = response.data;

        screen.setState({ loadingAddon: 0 });

        if (status === 'error') {
            // Nepodarilo sa odstranit addon
            screen.showSnackbar('error', __('Nepodarilo sa deaktivovať doplnok'));
            return;
        }

        screen.showSnackbar('success', __('Doplnok bol deaktivovaný'));

        dispatch({ type: DELETE_ADDON, payload: id });
    });
};

/**
 * Vycistenie addonov.
 */
export const cleanAddons = () => ({ type: CLEAN_ADDONS });
