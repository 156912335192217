import React from 'react';
import { connect } from 'react-redux';
import { withCookies } from 'react-cookie';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Create';
import AddIcon from '@mui/icons-material/AddCircle';
import { ListScreen } from '../components';
import { fetchEmployees, cleanEmployees, setUser, deleteEmployee } from '../actions';
import { __, formatDate, toNumber } from '../functions';
import { IMAGES } from '../config';
import '../assets/styles/employees.css';

/**
 * Zamestnanci.
 */
class EmployeesScreen extends ListScreen {
    /**
     * Title.
     *
     * @type {string}
     */
    title = __('Zamestnanci');

    /**
     * Default state.
     *
     * @type {Object}
     */
    state = { ...ListScreen.state, ...{
        lightbox: {
            delete: false,
        },
    }};

    /**
     * Vratime zoznam tagov.
     *
     * @return {Array}
     */
    getTags() {
        const { user } = this.props;

        return [
            {
                name: __('Nový zamestnanec'),
                color: 'secondary',
                icon: <AddIcon />,
                onClick: () => this.redirect('/employee'),
                disabled: toNumber(user.user_eshop_id) === 0,
            },
        ];
    }

    /**
     * Vratime nazov tabulky.
     *
     * @return {string}
     */
    getTableName() {
        return 'employees';
    }

    /**
     * Vratime empty text.
     *
     * @return {string}
     */
    getEmptyText() {
        return __('Zatiaľ nemáte žiadnych zamestnancov');
    }

    /**
     * Zobrazime lightbox na zmazanie.
     *
     * @param {number} id
     * @param {function} callbackLoading
     * @param {function} callback
     */
    confirmDelete(id, callbackLoading, callback) {
        this.showLightbox('delete', { id, callbackLoading, callback });
    }

    /**
     * Zmazanie zamestnanca.
     */
    async delete() {
        const { deleteEmployee, items } = this.props;
        const { lightbox } = this.state;

        // Zavolame loading
        lightbox.delete.callbackLoading();

        // Zavrieme lightbox
        this.closeLightbox('delete');

        // Zmazeme zamestnanca
        await deleteEmployee(this, lightbox.delete.id);

        // Zavolame callback
        lightbox.delete.callback();

        // Znovu nacitame zoznam od prvej stranky
        this.fetchList(1, 0, items.filtered);
    }

    /**
     * Rendrujeme polozku gridu.
     *
     * @param {Object} item
     *
     * @return {JSX.Element|null}
     */
    renderGridItem(item) {
        const buttons = {
            edit: {
                name: __('Upraviť'),
                icon: <EditIcon />,
                callback: () => this.redirect(`/employee/${item.id}`),
                options: {},
            },
            delete: {
                name: __('Zmazať'),
                icon: <DeleteIcon />,
                callback: (callbackLoading, callback) => this.confirmDelete(item.id, callbackLoading, callback),
            },
        };

        return (
            <div className="employees-grid__item">
                <div className="employees-grid__item__header">
                    <div className="employees-grid__item__header__title">{item.name}</div>
                    <div className="employees-grid__item__header__buttons">
                        {this.renderGridButtons(item.id, buttons)}
                    </div>
                </div>
                <div className="employees-grid__item__content">
                    <img src={IMAGES['employee.png']} alt="zamestnanec" />
                    <span>{item._matchingData.Users.email}</span>
                </div>
                <div className="employees-grid__item__footer">
                    {`${__('Vytvorené')} ${formatDate(item.created, 'dd.mm.yyyy hh:ii')}`}
                </div>
            </div>
        );
    }

    /**
     * Rendrujeme screen.
     *
     * @return {JSX.Element|null}
     */
    renderScreen() {
        return (
            <div>
                {this.renderLightbox(
                    'delete',
                    __('Naozaj chcete zmazať tohto zamestnanca?'),
                    null,
                    __('Áno'),
                    __('Nie'),
                    () => this.delete()
                )}
            </div>
        );
    }
}

const stateToProps = ({ employees, user }) => ({ items: employees, user });

export default withCookies(connect(stateToProps, {
    fetch: fetchEmployees,
    clean: cleanEmployees,
    setUser,
    deleteEmployee,
})(EmployeesScreen));
