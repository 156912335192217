import _ from 'lodash';
import { CUSTOMIZATION } from '../config';

/**
 * Vratime customizaciu.
 *
 * @return {Object|null}
 */
export const getCustomization = () => {
    const host = window.location.host;

    if (_.has(CUSTOMIZATION, host)) {
        // Mame customizaciu
        return CUSTOMIZATION[host];
    } else if (_.has(CUSTOMIZATION, `www.${host}`)) {
        // Mame customizaciu
        return CUSTOMIZATION[`www.${host}`];
    }

    return null;
};
