import React from 'react';
import _ from 'lodash';
import { Navigate, Input, Select, Button, Message } from '../components';
import { __, request, toNumber, isEmptyString, getCustomizationKey } from '../functions';
import '../assets/styles/eshop-drawer.css';

/**
 * Eshop komponenta.
 */
class Eshop extends Navigate {
    /**
     * Default props.
     *
     * @type {Object}
     */
    static defaultProps = {
        id: 0,
        user_eshop_id: null,
        mtokna: false,
        create: false,
    };

    /**
     * Default state.
     *
     * @type {Object}
     */
    state = {
        showSetup: false,
        data: {},
        fields: {},
        manualFields: {},
        manualFieldsSave: false,
        name: '',
        loading: false,
    };

    /**
     * Zoznam labelov.
     *
     * @type {Object}
     */
    labels = {
        'url': __('Url adresa eshopu (https://obchod.sk)'),
        'version': __('Verzia API'),
        'consumer_key': __('Kľúč zákazníka (key)'),
        'consumer_secret': __('Tajomstvo zákazníka (secret)'),
        'shopify_host': __('Názov eshopu (https://{nazov}.myshopify.com)'),
        'shoprenter_host': __('Názov eshopu (https://{nazov}.api.myshoprenter.hu)'),
        'upgates_host': __('Názov eshopu (https://{nazov}.admin.upgates.com)'),
        'cscart_company_id': __('Company id'),
        'apikey': __('API klúč'),
        'apitoken': __('API token'),
        'auth_code': __('API token'),
        'apipassword': __('API password'),
        'email': __('Email / meno do administrácie eshopu'),
        'username': __('Prihlasovacie meno do administrácie eshopu'),
        'password': __('Heslo do administrácie eshopu'),
        'api_key': __('API klúč'),
        'api_login': __('API login'),
        'api_url': __('API url'),
        'token': __('API token'),
        'store': __('Obchod'),
    };

    /**
     * Komponenta bola pripojena.
     */
    componentDidMount() {
        const { title, setTitle } = this.props;

        // Nastavime title
        setTitle(title);

        // Nacitame data
        this.fetch();
    }

    /**
     * Nacitame data.
     */
    fetch() {
        const { id, onClose, user_eshop_id, create } = this.props;

        let params = {};

        if (user_eshop_id !== null) {
            params = { ...params, user_eshop_id };
        }

        if (create) {
            params = { ...params, create: 1 };
        }

        // Nacitame data eshop systemu
        request('/user-eshops/eshop', params).then(response => {
            const { status, data } = response.data;

            if (status === 'error') {
                // Brana neexistuje
                onClose();
                return;
            }

            this.setState({
                data,
                name: data.setting_name,
                fields: this.getDefaultFields(data.eshops[id].fields),
            });
        });
    }

    /**
     * Vratime zoznam default fieldov.
     *
     * @param {Object} fields
     *
     * @return {Object}
     */
    getDefaultFields(fields) {
        return _.reduce(
            fields,
            (result, field, name) => ({ ...result, [name]: _.isObject(field) ? _.keys(field)[0] : '' }),
            {}
        );
    }

    /**
     * Event po zmene fieldu.
     *
     * @param {string} field
     * @param {string} value
     */
    onChangeField(field, value) {
        const { fields } = this.state;

        this.setState({ fields: { ...fields, [field]: value } });
    }

    /**
     * Event po zmene nazvu.
     *
     * @param {string} name
     */
    onChangeName(name) {
        this.setState({ name });
    }

    /**
     * Zobrazime setup.
     */
    showSetup() {
        this.setState({ showSetup: true });
    }

    /**
     * Schovame setup.
     */
    closeSetup() {
        this.setState({ showSetup: false, manualFields: {}, manualFieldsSave: false });
    }

    /**
     * Skontrolujeme credentials.
     *
     * @param {boolean} create
     */
    checkCredentials(create) {
        const { id, showSnackbar, user_eshop_id } = this.props;
        const { fields, name } = this.state;

        this.setState({ loading: true });

        if (isEmptyString(name)) {
            // Nazov nie je vyplneny
            this.setState({ loading: false });
            showSnackbar('error', __('Názov musí byť vyplnený'));
            return;
        }

        let params = {};

        if (user_eshop_id !== null) {
            params = { ...params, user_eshop_id };
        }

        request(`/user-eshops/eshopCredentials/${id}`, fields, 'POST', params).then(response => {
            const { status, data } = response.data;

            if (status === 'error') {
                if (!_.isEmpty(data.manual_fields)) {
                    // Mame manual fields, treba rucne zadat
                    this.setState({
                        loading: false,
                        manualFields: data.manual_fields,
                        fields: { ...fields, ...data.manual_fields },
                    });
                    return;
                }

                this.setState({ loading: false, manualFieldsSave: true });

                showSnackbar('error', data.message === 'invalid'
                    ? __('Nepodarilo sa prihlásiť do eshopu')
                    : __('Tieto prihlasovacie údaje už niekto používa'));
                return;
            }

            // Ukladame
            this.save(data.credentials, create);
        });
    }

    /**
     * Ulozenie.
     *
     * @param {Object} credentials
     * @param {boolean} create
     */
    save(credentials, create) {
        const { id, showSnackbar, createEshop, onClose, refreshData, user_eshop_id } = this.props;
        const { name } = this.state;

        let params = {};

        if (user_eshop_id !== null) {
            params = { ...params, user_eshop_id };
        }

        if (create) {
            // Vytvarame
            request('/user-eshops/create', {
                name,
                eshop_id: id,
                eshop_credentials: credentials,
                brand: getCustomizationKey(),
            }, 'POST', params).then(response => {
                const { status } = response.data;

                if (status === 'error') {
                    // Nepodarilo sa zmenit credentials
                    this.setState({ loading: false, manualFieldsSave: true });
                    showSnackbar('error', __('Nepodarilo sa vytvoriť eshop'));
                    return;
                }

                showSnackbar('success', __('Eshop bol aktivovaný'));

                // Vytvorime
                createEshop(id);

                // Zavrieme
                onClose();

                // Refreshneme
                window.location.reload();
            });
            return;
        }

        // Upravime
        request('/user-eshops/changeEshopCredentials', { credentials }, 'POST', params).then(response => {
            const { status } = response.data;

            if (status === 'error') {
                // Nepodarilo sa zmenit credentials
                this.setState({ loading: false, manualFieldsSave: true });
                showSnackbar('error', __('Nepodarilo sa zmeniť prihlasovacie údaje'));
                return;
            }

            showSnackbar('success', __('Prihlasovacie údaje boli zmenené'));

            // Zavrieme
            onClose();

            // Refreshneme data
            refreshData();
        });
    }

    /**
     * Vratime manual fields text.
     *
     * @param {string} name
     *
     * @return {JSX.Element|null}
     */
    getCredentialsText(name) {
        const { mtokna } = this.props;

        switch (name) {
            case 'Shopify':
                return <div>
                    <span>{__('Pripravili sme pre vás návod ako vygenerujete potrebné API údaje')} - </span>
                    {!mtokna ? <a href="/blog/eshop-shopify" target="_blank">{__('Návod')}</a> : null}
                </div>;

            case 'Prestashop':
                return <div>
                    <span>{__('Pripravili sme pre vás návod ako vygenerujete potrebné API údaje')} - </span>
                    {!mtokna ? <a href="/blog/eshop-prestashop" target="_blank">{__('Návod')}</a> : null}
                </div>;

            default:
                return null;
        }
    }

    /**
     * Vratime manual fields text.
     *
     * @param {string} name
     *
     * @return {JSX.Element|string}
     */
    getManualFieldsText(name) {
        const { mtokna } = this.props;

        switch (name) {
            case 'WooCommerce':
                return <div>
                    <span>{__('Nepodarilo sa predvyplniť API údaje, prosím zadajte ich manuálne')} - </span>
                    {!mtokna ? <a href="/blog/eshop-woocommerce" target="_blank">{__('Návod')}</a> : null}
                </div>;

            case 'Upgates':
                return <div>
                    <span>{__('Nepodarilo sa predvyplniť API údaje, prosím zadajte ich manuálne')} - </span>
                    {!mtokna ? <a href="/blog/eshop-upgates" target="_blank">{__('Návod')}</a> : null}
                </div>;

            case 'BiznisWeb':
                return <div>
                    <span>{__('Nepodarilo sa predvyplniť API údaje, prosím zadajte ich manuálne')} - </span>
                    {!mtokna ? <a href="https://www.biznisweb.sk/a/1270/token-api" rel="noopener noreferrer" target="_blank">{__('Návod')}</a> : null}
                </div>;

            default:
                return __('Nepodarilo sa predvyplniť údaje eshopu, prosím zadajte ich manuálne');
        }
    }

    /**
     * Rendrovanie fieldov.
     *
     * @param {Object} items
     *
     * @return {JSX.Element[]}
     */
    renderFields(items) {
        const { fields } = this.state;

        return _.map(items, (field, name) => {
            const label = _.has(this.labels, name) ? this.labels[name] : '';

            switch (name) {
                case 'url':
                    // Url adresa
                    return <Input
                        key={name}
                        label={label}
                        value={fields[name]}
                        onChange={value => this.onChangeField(name, value)}
                    />;

                case 'version':
                    // Verzia
                    return <Select
                        key={name}
                        label={label}
                        options={{ '2': __('Verzia 2'), '31': __('Verzia 3 - OAuth 1.0'), '3': __('Verzia 3') }}
                        value={fields[name]}
                        onChange={value => this.onChangeField(name, value)}
                        allowEmpty={false}
                    />;

                case 'consumer_key':
                    // Consumer key
                    return <Input
                        key={name}
                        label={label}
                        value={fields[name]}
                        onChange={value => this.onChangeField(name, value)}
                    />;

                case 'consumer_secret':
                    // Consumer secret
                    return <Input
                        key={name}
                        label={label}
                        value={fields[name]}
                        onChange={value => this.onChangeField(name, value)}
                    />;

                case 'shopify_host':
                case 'upgates_host':
                case 'shoprenter_host':
                case 'username':
                case 'api_url':
                case 'api_login':
                case 'api_key':
                case 'token':
                case 'store':
                    // Shopify host
                    // Upgates host
                    // API url
                    // API login
                    // API key
                    return <Input
                        key={name}
                        label={label}
                        value={fields[name]}
                        onChange={value => this.onChangeField(name, value)}
                    />;

                case 'cscart_company_id':
                    // CS cart company id
                    return <Input
                        key={name}
                        label={label}
                        value={fields[name]}
                        onChange={value => this.onChangeField(name, value)}
                    />;

                case 'apikey':
                    // Api kluc
                    return <Input
                        key={name}
                        label={label}
                        value={fields[name]}
                        onChange={value => this.onChangeField(name, value)}
                    />;

                case 'apitoken':
                case 'auth_code':
                    // Api token
                    return <Input
                        key={name}
                        label={label}
                        value={fields[name]}
                        onChange={value => this.onChangeField(name, value)}
                    />;

                case 'apipassword':
                    // Api password
                    return <Input
                        key={name}
                        label={label}
                        value={fields[name]}
                        onChange={value => this.onChangeField(name, value)}
                    />;

                case 'email':
                    // Email
                    return <Input
                        key={name}
                        label={label}
                        value={fields[name]}
                        onChange={value => this.onChangeField(name, value)}
                    />;

                case 'password':
                    // Heslo
                    return <Input
                        type="password"
                        key={name}
                        label={label}
                        value={fields[name]}
                        onChange={value => this.onChangeField(name, value)}
                    />;

                default:
                    return null;
            }
        });
    }

    /**
     * Rendrovanie.
     *
     * @return {JSX.Element}
     */
    render() {
        const { id } = this.props;
        const { data, manualFields, loading, showSetup, name, manualFieldsSave } = this.state;

        if (_.isEmpty(data)) {
            // Data nie su nacitane
            return <div
                className="eshop-drawer-content eshop-drawer-content-loading"
            >{this.renderLoading()}</div>;
        }

        // Je aktivne?
        const activated = toNumber(data.id) === toNumber(id);

        // Fieldy na generovanie
        let { fields } = data.eshops[id];

        if (!_.isEmpty(manualFields)) {
            // Mame zadane manual fieldy, zobrazujeme len tie
            fields = manualFields;
        }

        // Vytiahneme credentials
        const credentials = _.reduce(data.credentials, (result, value, name) => {
            if (!_.has(this.labels, name)) {
                return result;
            }

            return { ...result, [name]: value };
        }, {});

        // Vytiahneme manual fields text
        const manualFieldsText = this.getManualFieldsText(data.eshops[id].name);

        // Vytiahneme credentials text
        const credentialsText = this.getCredentialsText(data.eshops[id].name);

        return (
            <div className="eshop-drawer-content">
                {activated && !showSetup
                    ? <div className="eshop-drawer-content__data">
                        {!_.isEmpty(credentials) ? <div className="eshop-drawer-content__data__credentials">
                            <div className="eshop-drawer-content__data__credentials__title">{__('Prihlasovacie údaje')}</div>
                            {_.map(credentials, (value, name) => {
                                return (
                                    <div className="eshop-drawer-content__data__credentials__value" key={name}>
                                        <div className="eshop-drawer-content__data__credentials__value__name">
                                            {this.labels[name]}:
                                        </div>
                                        <div className="eshop-drawer-content__data__credentials__value__text">
                                            {_.truncate(value, { length: 30 })}
                                        </div>
                                    </div>
                                );
                            })}
                        </div> : null}
                    </div>
                    : <div className="eshop-drawer-content__data">
                        {credentialsText !== null
                            ? <Message type="warning">{credentialsText}</Message>
                            : null}
                        {_.isEmpty(manualFields) ? <Input
                            label={__('Interný názov')}
                            value={name}
                            onChange={value => this.onChangeName(value)}
                        /> : null}
                        {!_.isEmpty(manualFields) && !manualFieldsSave
                            ? <Message type="warning">{manualFieldsText}</Message>
                            : null}
                        {!_.isEmpty(manualFields) && _.has(manualFields, 'version') && manualFieldsSave
                            ? <Message type="warning">{__('Skúste zmeniť verziu API')}</Message>
                            : null}
                        {this.renderFields(fields)}
                    </div>}
                <div className="eshop-drawer-content__buttons">
                    {showSetup ? <Button
                        onClick={() => this.closeSetup()}
                        color="shadow"
                        className="eshop-drawer-content__buttons__button"
                    >{__('Naspäť')}</Button> : null}
                    {showSetup || !activated ? <Button
                        onClick={() => this.checkCredentials(!activated)}
                        color="green"
                        className="eshop-drawer-content__buttons__button"
                        loading={loading}
                    >{showSetup ? __('Uložiť') : __('Aktivovať')}</Button> : null}
                    {!_.isEmpty(credentials) && !showSetup ? <Button
                        onClick={() => this.showSetup()}
                        color="shadow"
                        className="eshop-drawer-content__buttons__button"
                    >{__('Upraviť')}</Button> : null}
                </div>
            </div>
        );
    }
}

export { Eshop };
