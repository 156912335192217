import React from 'react';
import { connect } from 'react-redux';
import { withCookies } from 'react-cookie';
import _ from 'lodash';
import { Screen, Button, Select, Input, Checkbox } from '../components';
import { fetchSettings, cleanSettings, setUser, saveSettings } from '../actions';
import { __, isEmptyString, isValidEmail, toNumber } from '../functions';
import { PASSWORD_MIN_LENGTH, COUNTRIES, CURRENCIES } from '../config';
import '../assets/styles/settings.css';

/**
 * Nastavenia.
 */
class SettingsScreen extends Screen {
    /**
     * Title.
     *
     * @type {string}
     */
    title = __('Nastavenia');

    /**
     * Default state.
     *
     * @type {Object}
     */
    state = {
        loading: false,
        data: {},
    };

    /**
     * Komponenta bola pripojena.
     *
     * @return boolean
     */
    async componentDidMount() {
        if (super.componentDidMount()) {
            const { fetchSettings } = this.props;

            // Nacitame nastavenia
            await fetchSettings(this);

            const { settings } = this.props;

            // Nasetujeme nastavenia
            this.setSettings(settings);
        }

        return true;
    }

    /**
     * Komponenta bude odpojena.
     */
    componentWillUnmount() {
        const { cleanSettings } = this.props;

        cleanSettings();
    }

    /**
     * Nasetujeme nastavenia.
     *
     * @param {Object} settings
     */
    setSettings(settings) {
        this.setState({ data: {
            market: settings.market,
            currency: settings.currency,
            inline_pdf: settings.inline_pdf,
            email_old: '',
            email_new: '',
            email_password: '',
            password_old: '',
            password_new: '',
            password_new_repeat: '',
        } });
    }

    /**
     * Event po zmene settingu.
     *
     * @param {string} type
     * @param {string} value
     */
    onChangeSetting(type, value) {
        const { data } = this.state;

        this.setState({ data: { ...data, [type]: value } });
    }

    /**
     * Ulozenie nastaveni.
     */
    save() {
        const { data } = this.state;

        if (!isEmptyString(data.email_old)) {
            // Je vyplneny stary email
            if (!isValidEmail(data.email_old)) {
                // Stary email nie je validny
                this.showSnackbar('error', __('Aktuálny email je neplatný'));
                return;
            }

            if (!isValidEmail(data.email_new)) {
                // Novy email nie je validny
                this.showSnackbar('error', __('Nový email je neplatný'));
                return;
            }

            if (isEmptyString(data.email_password)) {
                // Nie je zadane heslo
                this.showSnackbar('error', __('Heslo nie je zadané'));
                return;
            }
        }

        if (!isEmptyString(data.password_old)) {
            // Je vyplnene stare heslo
            if (data.password_new.length < PASSWORD_MIN_LENGTH) {
                // Dlzka hesla je zla
                this.showSnackbar(
                    'error',
                    __('Minimálna dĺžka hesla je {COUNT} znakov').replace('{COUNT}', PASSWORD_MIN_LENGTH.toString())
                );
                return;
            } else if (data.password_new !== data.password_new_repeat) {
                // Hesla sa nezhoduju
                this.showSnackbar('error', __('Heslá nie sú rovnaké'));
                return;
            }
        }

        this.setState({ loading: true });

        const { saveSettings } = this.props;

        saveSettings(this, data);
    }

    /**
     * Rendrovanie.
     *
     * @returns {JSX.Element}
     */
    render() {
        const { user } = this.props;
        const { data, loading } = this.state;

        if (_.isEmpty(data)) {
            // Data nie su nacitane
            return this.renderLoading();
        }

        return (
            <div className="settings">
                <div className="settings__header">
                    <div className="settings__header__left">
                        <div className="settings__header__left__title">{__('Nastavenia')}</div>
                    </div>
                </div>
                <div className="settings__content">
                    <div className="settings__content__panels">
                        <div className="settings__content__panels__panel">
                            <div className="settings__content__panels__panel__title">{__('Eshop')}</div>
                            <Select
                                label={__('Lokalizácia')}
                                options={COUNTRIES}
                                value={data.market}
                                onChange={value => this.onChangeSetting('market', value)}
                                allowEmpty={false}
                            />
                            <Select
                                label={__('Mena')}
                                options={CURRENCIES}
                                value={data.currency}
                                onChange={value => this.onChangeSetting('currency', value)}
                                allowEmpty={false}
                            />
                            <Checkbox
                                label={__('Zobrazovať PDF v prehliadači')}
                                value={data.inline_pdf}
                                onChange={checked => this.onChangeSetting('inline_pdf', checked)}
                            />
                        </div>
                        <div className="settings__content__panels__panel">
                            <div className="settings__content__panels__panel__title">{__('Zmena emailu')}</div>
                            <Input
                                label={__('Aktuálny email')}
                                value={data.email_old}
                                onChange={value => this.onChangeSetting('email_old', value)}
                                type="email"
                            />
                            <Input
                                label={__('Nový email')}
                                value={data.email_new}
                                onChange={value => this.onChangeSetting('email_new', value)}
                                type="email"
                            />
                            <Input
                                label={__('Heslo')}
                                value={data.email_password}
                                onChange={value => this.onChangeSetting('email_password', value)}
                                type="password"
                            />
                        </div>
                        <div className="settings__content__panels__panel">
                            <div className="settings__content__panels__panel__title">{__('Zmena hesla')}</div>
                            <Input
                                label={__('Aktuálne heslo')}
                                value={data.password_old}
                                onChange={value => this.onChangeSetting('password_old', value)}
                                type="password"
                            />
                            <Input
                                label={__('Nové heslo')}
                                value={data.password_new}
                                onChange={value => this.onChangeSetting('password_new', value)}
                                type="password"
                            />
                            <Input
                                label={__('Nové heslo znovu')}
                                value={data.password_new_repeat}
                                onChange={value => this.onChangeSetting('password_new_repeat', value)}
                                type="password"
                            />
                        </div>
                    </div>
                    <Button
                        onClick={() => this.save()}
                        loading={loading}
                        className="settings__content__button"
                        color="green"
                        disabled={toNumber(user.user_eshop_id) === 0}
                    >{__('Uložiť')}</Button>
                </div>
                {this.renderSnackbar()}
            </div>
        );
    }
}

const stateToProps = ({ settings, user }) => ({ settings, user });

export default withCookies(connect(stateToProps, {
    fetchSettings,
    setUser,
    saveSettings,
    cleanSettings,
})(SettingsScreen));
