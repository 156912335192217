import { FETCH_EMPLOYEE, CLEAN_EMPLOYEE } from './types';
import { __, request, toNumber } from '../functions';

/**
 * Nacitanie zamestnanca.
 *
 * @param {Screen} screen
 * @param {number} id
 */
export const fetchEmployee = (screen, id) => {
    return dispatch => request(`/user-employees/view/${id}`).then(response => {
        const { data, sync } = response.data;
        const { setUser } = screen.props;

        // Nasetujeme user data
        setUser(sync.user);

        dispatch({ type: FETCH_EMPLOYEE, payload: data });
    });
};

/**
 * Vytvorime zamestnanca.
 *
 * @param {Screen} screen
 * @param {Object} data
 */
export const createEmployee = (screen, data) => {
    const edit = toNumber(data.id) > 0;

    return dispatch => request(`/user-employees/${edit ? 'edit' : 'create'}`, data, 'POST').then(response => {
        const { status } = response.data;

        screen.setState({ loading: false });

        if (status === 'error') {
            // Nepodarilo sa ulozit zamestnanca
            screen.showSnackbar('error', __('Nepodarilo sa uložiť zamestnanca'));
            return;
        }

        window.location = '/employees';
    });
};

/**
 * Vycistenie zamestnanca.
 */
export const cleanEmployee = () => ({ type: CLEAN_EMPLOYEE });
