import _ from 'lodash';
import { request } from '../functions';
import { FETCH_DASHBOARD } from './types';
import { COOKIE_SETTINGS, COOKIE_SOURCE_NAME } from '../config';

/**
 * Nacitanie dashboardu.
 *
 * @param {Screen} screen
 * @param {string} date
 */
export const fetchDashboard = (screen, date = '') => {
    const { cookies } = screen.props;

    let params = {};

    // Vytiahneme zdroj
    const source = cookies.get(COOKIE_SOURCE_NAME, COOKIE_SETTINGS);

    if (!_.isEmpty(source)) {
        params = { s: source };

        // Zmazeme
        cookies.remove(COOKIE_SOURCE_NAME, COOKIE_SETTINGS);
    }

    return dispatch => request(`/user-eshops/dashboard/${date}`, params).then(({ data }) => {
        const { user } = data.sync;
        const { setUser } = screen.props;

        // Nasetujeme usera
        setUser(user);

        dispatch({ type: FETCH_DASHBOARD, payload: data.data });
    });
};
