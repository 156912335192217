import React from 'react';
import { connect } from 'react-redux';
import { withCookies } from 'react-cookie';
import _ from 'lodash';
import {ListScreen, Input} from '../components';
import {
    fetchMtoknaFiles,
    cleanMtoknaFiles,
    deleteMtoknaFile,
    setUser,
    changeUserSetting,
} from '../actions';
import { __, formatDate, isEmptyString, request } from '../functions';
import '../assets/styles/mtokna_files.css';
import DeleteIcon from '@mui/icons-material/Delete';
import ExportsIcon from '@mui/icons-material/GetApp';
import {ButtonBase} from "@mui/material";
import EditIcon from "@mui/icons-material/Create";
import {IMAGES, TEMPLATES_EVENTS} from "../config";

/**
 * Klienti.
 */
class MtoknaFilesScreen extends ListScreen {
    /**
     * Title.
     *
     * @type {string}
     */
    title = __('Praktické pomôcky');

    /**
     * Default state.
     *
     * @type {Object}
     */
    state = { ...ListScreen.state, ...{
        lightbox: {
            create: false,
            delete: false,
            detail: false,
        },
    }};

    /**
     * Memory cache.
     *
     * @type {Object}
     */
    memoryCache = {};

    /**
     * Vratime nazov tabulky.
     *
     * @return {string}
     */
    getTableName() {
        return 'mtokna_files';
    }

    /**
     * Rendrujeme polozku gridu.
     *
     * @param {Object} item
     *
     * @return {JSX.Element|null}
     */
    renderGridItem(item) {
        const { user } = this.props;

        let buttons = {
            download: {
                name: __('Stiahnuť'),
                icon: <ExportsIcon />,
                callback: (callbackLoading, callback) => this.download(item.id, callbackLoading, callback),
                options: {},
            },
            delete: {
                name: __('Zmazať'),
                icon: <DeleteIcon />,
                callback: (callbackLoading, callback) => this.confirmDelete(item.id, callbackLoading, callback),
                options: {},
            },
        };

        if (user.mtokna_type !== 'mtokna_admin') {
            // Nema pravo
            buttons = _.omit(buttons, ['delete']);
        }

        let link = '';

        if (!_.isEmpty(item.file_path)) {
            link = `https://api.goodeshop.sk/${item.file_path}`;
        }

        return (
            <div className="mtokna_files-grid__item">
                <div className="mtokna_files-grid__item__header">
                    <div className="mtokna_files-grid__item__header__title">{item.name}</div>
                    <div className="mtokna_files-grid__item__header__buttons">
                        {this.renderGridButtons(item.id, buttons)}
                    </div>
                </div>
                <ButtonBase onClick={link !== '' ? () => this.showLightbox('detail', {
                    link,
                    id: item.id
                }) : () => {}} className="mtokna_files-grid__item__content">
                    <img src={link !== '' ? link : IMAGES['email.png']} alt="email" />
                </ButtonBase>
                <div className="mtokna_files-grid__item__footer">
                    {`${__('Vytvorené')} ${formatDate(item.created, 'dd.mm.yyyy hh:ii')}`}
                </div>
            </div>
        );
    }

    /**
     * Vratime filter.
     *
     * @return {Object}
     */
    getFilter() {
        const { items } = this.props;

        return _.reduce(items.filter, (result, options, key) => {
            switch (key) {
                case 'name':
                    return { ...result, ...{ [key]: {
                        type: 'input',
                        name: __('Názov'),
                        value: '',
                    }}};

                default:
                    return result;
            }
        }, {});
    }

    getTags() {
        const { user } = this.props;

        if (user.mtokna_type !== 'mtokna_admin') {
            return [];
        }

        return [
            {
                name: __('Nový súbor'),
                onClick: () => this.showLightbox('create', {
                    loading: false,
                    data: {
                        name: '',
                        file: null,
                    },
                }),
            }
        ];
    }

    /**
     * Vratime empty text.
     *
     * @return {string}
     */
    getEmptyText() {
        return __('Zatiaľ nemáte žiadne súbory');
    }

    /**
     * Event po zmene create.
     *
     * @param {string} key
     * @param {string} value
     */
    onChangeCreate(key, value) {
        const { lightbox } = this.state;

        this.setState({ lightbox: { ...lightbox, create: {
            ...lightbox.create,
            data: { ...lightbox.create.data, [key]: value }
        } } });
    }

    /**
     * Event po zmene create.
     *
     * @param {string} value
     */
    onChangeCreateLoading(value) {
        const { lightbox } = this.state;

        this.setState({ lightbox: { ...lightbox, create: { ...lightbox.create, loading: value } } })
    }

    /**
     * Zobrazime lightbox na email.
     *
     * @param {number} id
     * @param {function} callbackLoading
     * @param {function} callback
     */
    confirmDelete(id, callbackLoading, callback) {
        this.showLightbox('delete', { id, callbackLoading, callback });
    }

    /**
     * Zmazanie miesta.
     */
    async delete() {
        const { deleteMtoknaFile, items } = this.props;
        const { lightbox } = this.state;

        // Zavolame loading
        lightbox.delete.callbackLoading();

        // Zavrieme lightbox
        this.closeLightbox('delete');

        // Zmazeme klienta
        await deleteMtoknaFile(this, lightbox.delete.id);

        // Zavolame callback
        lightbox.delete.callback();

        // Znovu nacitame zoznam od prvej stranky
        this.fetchList(1, 0, items.filtered);
    }

    create() {
        const { lightbox } = this.state;

        if (isEmptyString(lightbox.create.data.name)) {
            this.showSnackbar('error', __('Nie je vyplnený názov'));
            return;
        } else if (lightbox.create.data.file === null) {
            this.showSnackbar('error', __('Nie je nahratý súbor'));
            return;
        }

        this.onChangeCreateLoading(true);

        request(`/mtokna/uploadFile/${lightbox.create.data.name}`, lightbox.create.data.file, 'FILE').then(response => {
            const { status } = response.data;

            if (status === 'error') {
                this.onChangeCreateLoading(false);
                this.showSnackbar('error', __('Súbor sa nepodarilo nahrať'));
                return;
            }

            // Refreshneme
            window.location = window.location.pathname;
        });
    }

    /**
     * Zmazanie miesta.
     */
    download(id, callbackLoading, callback) {
        // Zavolame loading
        callbackLoading();

        window.location = `https://api.goodeshop.sk/mtokna/downloadFile/${id}?token=${global.token}`

        // Zavolame callback
        callback();
    }

    downloadDetail() {
        const { lightbox } = this.state;

        window.location = `https://api.goodeshop.sk/mtokna/downloadFile/${lightbox.detail.id}?token=${global.token}`;

        this.closeLightbox('detail');
    }

    /**
     * Rendrujeme screen.
     *
     * @return {JSX.Element|null}
     */
    renderScreen() {
        const { lightbox } = this.state;

        return (
            <div>
                {this.renderLightbox(
                    'delete',
                    __('Naozaj chcete zmazať tento súbor?'),
                    null,
                    __('Áno'),
                    __('Nie'),
                    () => this.delete()
                )}
                {this.renderLightbox(
                    'create',
                    __('Nový súbor'),
                    !_.isEmpty(lightbox.create) ? (lightbox.create.loading ? this.renderLoading(20) : <div>
                        <div>
                            <Input
                                label={__('Názov')}
                                value={lightbox.create.data.name}
                                onChange={value => this.onChangeCreate('name', value)}
                            />
                        </div>
                        <div className="mtokna_files__upload-text">{__('Súbor')}</div>
                        <ButtonBase className="mtokna_files__upload">
                            <div className="mtokna_files__upload__text">{lightbox.create.data.file !== null
                                ? lightbox.create.data.file.name
                                : __('Kliknite alebo presuňte súbor (jpg, png, pdf, max. 5MB)')}</div>
                            <input type="file" onChange={event => this.onChangeCreate('file', event.target.files[0])} />
                        </ButtonBase>
                    </div>) : null,
                    __('Uložiť'),
                    __('Zrušiť'),
                    () => this.create()
                )}
                {this.renderLightbox(
                    'detail',
                    __('Súbor'),
                    !_.isEmpty(lightbox.detail) ? <div>
                        <img src={lightbox.detail.link} style={{ width: '1320px' }} />
                    </div> : null,
                    __('Stiahnuť'),
                    __('Zrušiť'),
                    () => this.downloadDetail(),
                    false,
                    null,
                    'mtokna-detail-lightbox'
                )}
            </div>
        );
    }
}

const stateToProps = ({ mtokna_files, user }) => ({ items: mtokna_files, user });

export default withCookies(connect(stateToProps, {
    fetch: fetchMtoknaFiles,
    clean: cleanMtoknaFiles,
    setUser,
    changeUserSetting,
    deleteMtoknaFile,
})(MtoknaFilesScreen));
