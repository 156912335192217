import { FETCH_BMAILS, CLEAN_BMAILS, CREATE_GATEWAY, DELETE_GATEWAY, CREATE_BANK, DELETE_BANK } from '../actions/types';
import _ from 'lodash';
import { toNumber } from '../functions';

const BMAILS_DEFAULT_STATE = {};

/**
 * Reducer pre bmaily.
 */
const BmailsReducer = (state = BMAILS_DEFAULT_STATE, { type, payload }) => {
    switch (type) {
        case FETCH_BMAILS:
            // Nacitanie bmailov
            return payload;

        case CLEAN_BMAILS:
            // Vycistenie bmailov
            return BMAILS_DEFAULT_STATE;

        case CREATE_GATEWAY:
            // Vytvorime platobnu branu
            return { ...state, user_gateways: [ ...state.user_gateways, ...[payload] ] };

        case DELETE_GATEWAY:
            // Zrusime platobnu branu
            return { ...state, user_gateways: _.remove(state.user_gateways, i => toNumber(i) !== toNumber(payload)) };

        case CREATE_BANK:
            // Vytvorime banku
            return { ...state, user_banks: { ...state.user_banks, [toNumber(payload.id)]: payload.hash } };

        case DELETE_BANK:
            // Zmazeme banku
            return { ...state, user_banks: _.omit(state.user_banks, [toNumber(payload)]) };

        default:
            return state;
    }
};

export default BmailsReducer;
