import React from 'react';
import { connect } from 'react-redux';
import { withCookies } from 'react-cookie';
import _ from 'lodash';
import { Tooltip, IconButton, Autocomplete, TextField } from '@mui/material';
import IdeaIcon from '@mui/icons-material/Lightbulb';
import { Button, Checkbox, Input, ListScreen, Select, Switch } from '../components';
import { fetchMarketplaceProducts, cleanMarketplaceProducts, setUser, saveMarketplaceProducts } from '../actions';
import { __, formatAmount, request, toNumber } from '../functions';
import '../assets/styles/marketplace-products.css';

/**
 * Trhovisko produkty.
 */
class MarketplaceProductsScreen extends ListScreen {
    /**
     * Title.
     *
     * @type {string}
     */
    title = __('Trhovisko - synchronizácia produktov');

    /**
     * Statusy.
     *
     * @type {Object}
     */
    statuses = {
        empty: __('Nezadané'),
        waiting: __('Čaká na spracovanie'),
        processing: __('Spracováva sa'),
        processed: __('Spracované'),
        error: __('Chyba'),
    };

    /**
     * Default state.
     *
     * @type {Object}
     */
    state = {
        loadingProducts: false,
        settings: {},
        deleted: {},
        marketplaceSettings: {},
        loadingMarketplaceSettings: false,
    };

    /**
     * Nacitame zoznam.
     *
     * @param {number} page
     * @param {number} per_page
     * @param {Object} filtered
     * @param {function|null} callback
     */
    async fetchList(page = 1, per_page = 0, filtered = {}, callback = null) {
        const { fetch } = this.props;

        // Nacitame zoznam
        await fetch(this, this.getMarketplace(), { page, per_page, filtered, callback });

        const { items } = this.props;

        if (_.isEmpty(items.marketplace_products)) {
            this.showSnackbar('error', __('Nepodarilo sa načítať dáta z trhoviska, skúste znovu načítať stránku'));
        }

        if (!_.isEmpty(items.filtered)) {
            // Nastavime filter
            this.setState({ filtered: items.filtered });
        }
    }

    /**
     * Ulozime produkty.
     */
    saveProducts() {
        const { saveMarketplaceProducts } = this.props;
        const { settings, deleted } = this.state;

        this.setState({ loadingProducts: true });

        saveMarketplaceProducts(this, this.getMarketplace(), settings, deleted);
    }

    /**
     * Vratime id zamestnanca.
     *
     * @return {string}
     */
    getMarketplace() {
        const { location } = this.props;

        return location.pathname.replace('/marketplaces/products/', '');
    }

    /**
     * Vratime nazov tabulky.
     *
     * @return {string}
     */
    getTableName() {
        return 'marketplace-products';
    }

    /**
     * Vratime empty text.
     *
     * @return {string}
     */
    getEmptyText() {
        return __('Zatiaľ nemáte žiadne produkty');
    }

    /**
     * Event po zmene marketplace produktu.
     *
     * @param {number} productId
     * @param {number} marketplaceProductId
     */
    onChangeMarketplaceProduct(productId, marketplaceProductId) {
        const { settings, deleted } = this.state;

        this.setState({
            settings: { ...settings, [productId]: marketplaceProductId },
            deleted: _.omit(deleted, [productId]),
        });
    }

    /**
     * Zmazeme marketplace produkt.
     *
     * @param {number} productId
     */
    deleteMarketplaceProduct(productId) {
        const { settings, deleted } = this.state;

        if (!_.has(settings, productId)) {
            // Mazeme
            this.setState({ deleted: { ...deleted, [productId]: productId } });
            return;
        }

        this.setState({ settings: _.omit(settings, [productId]) });
    }

    /**
     * Navrh produktu.
     *
     * @param {Object} item
     */
    findProduct(item) {
        const { items } = this.props;

        let marketplaceProductId = null;

        _.each(items.marketplace_products, ({ name, number, ean }, id) => {
            if (marketplaceProductId === null) {
                // Este sme nenasli
                if (item.data.number.toString() !== '' && item.data.number.toString() === number.toString()) {
                    // Nasli sme podla cisla
                    marketplaceProductId = id;
                } else if (item.data.ean.toString() !== '' && item.data.ean.toString() === ean.toString()) {
                    // Nasli sme podla EAN
                    marketplaceProductId = id;
                } else if (item.data.name.toString() !== '' && item.data.name.toString() === name.toString()) {
                    // Nasli sme podla EAN
                    marketplaceProductId = id;
                }
            }
        });

        if (marketplaceProductId === null) {
            this.showSnackbar('error', __('Nenašli sme produkt na trhovisku'));
            return;
        }

        this.onChangeMarketplaceProduct(item.id, marketplaceProductId);
    }

    /**
     * Vratime bunky.
     *
     * @param {Object} item
     *
     * @return {Array}
     */
    getCells(item) {
        const { settings, deleted } = this.state;
        const { items } = this.props;

        // Naformatujeme
        const marketplaceProducts = _.reduce(items.marketplace_products, (result, { name }, id) => {
            return [ ...result, { id, name } ];
        }, []);

        const marketplaceProductsNames = _.reduce(items.marketplace_products, (result, { name }, id) => {
            return { ...result, [id]: name };
        }, {});

        // Vytiahneme nastavenia buniek
        const cellsSettings = this.getCellsSettings();

        // Vytiahneme options buniek
        const cellsOptions = this.getCellsOptions();

        // Default status, nezadane
        let status = 'empty';
        let marketplaceProduct = null;

        if (_.has(items.marketplace_products_sync, item.id)) {
            // Nastavena synchronizacia
            status = items.marketplace_products_sync[item.id].status;
            marketplaceProduct = {
                id: items.marketplace_products_sync[item.id].marketplace_product_id,
                name: marketplaceProductsNames[items.marketplace_products_sync[item.id].marketplace_product_id]
            };
        }

        if (_.has(settings, item.id) && _.has(marketplaceProductsNames, settings[item.id])) {
            // Nastavena synchronizacia v state
            marketplaceProduct = { id: settings[item.id], name: marketplaceProductsNames[settings[item.id]] };
        }

        return [
            <div>
                <Checkbox
                    onChange={value => {
                        if (value) {
                            this.onChangeMarketplaceProduct(item.id, null);
                        } else {
                            this.deleteMarketplaceProduct(item.id);
                        }
                    }}
                    value={(status !== 'empty' || _.has(settings, item.id)) && !_.has(deleted, item.id)}
                />
            </div>,
            <div>
                {this.getCellValue(cellsOptions[cellsSettings.settings[1][0]].get(item))}
            </div>,
            <div>
                {this.getCellValue(cellsOptions[cellsSettings.settings[2][0]].get(item))}
            </div>,
            <div>
                {this.getCellValue(cellsOptions[cellsSettings.settings[3][0]].get(item))}
            </div>,
            <div className={status}>{this.statuses[status]}</div>,
            <div className="products-select">
                <Input
                    content={<Autocomplete
                        options={marketplaceProducts}
                        getOptionLabel={option => option.name}
                        onChange={(event, value) => this.onChangeMarketplaceProduct(item.id, value.id)}
                        renderInput={(params) => <TextField
                            { ...params }
                            placeholder={__('Začnite písať názov...')}
                            variant="outlined"
                        />}
                        noOptionsText={__('Nenašiel sa žiadny produkt')}
                        clearText={__('Zrušiť')}
                        value={marketplaceProduct}
                    />}
                />
                <Tooltip title={__('Návrh zhodného produktu')}>
                    <IconButton onClick={() => this.findProduct(item)} disabled={status !== 'empty'}>
                        <IdeaIcon />
                    </IconButton>
                </Tooltip>
            </div>,
        ];
    }

    /**
     * Vratime filter.
     *
     * @return {Object}
     */
    getFilter() {
        const { items } = this.props;

        return _.reduce(items.filter, (result, options, key) => {
            switch (key) {
                case 'marketplace_product_status':
                    return { ...result, ...{ [key]: {
                        type: 'select',
                        name: __('Stav'),
                        value: '',
                        options: this.statuses,
                    }}};

                case 'search':
                    return { ...result, ...{ [key]: {
                        type: 'input',
                        name: __('Hľadať'),
                        value: '',
                    }}};

                default:
                    return result;
            }
        }, {});
    }

    /**
     * Vratime nastavenia buniek.
     *
     * @return {Object}
     */
    getCellsSettings() {
        return {
            'settings': [
                ['checkbox'],
                ['name'],
                ['number_ean'],
                ['amount'],
                ['status'],
            ],
            'disabled': ['checkbox', 'name', 'number_ean', 'amount'],
        };
    }

    /**
     * Vratime text posledneho stlpca.
     *
     * @return {JSX.Element|string}
     */
    getLastColumnText() {
        return <div>{__('Párovať s produktom na trhovisku')}</div>;
    }

    /**
     * Schovame multiselect.
     *
     * @return {boolean}
     */
    showMultiselect() {
        return false;
    }

    /**
     * Vratime options buniek.
     *
     * @return {Object}
     */
    getCellsOptions() {
        const { user } = this.props;

        return {
            checkbox: {
                label: __('Vytvoriť'),
                get: item => '',
            },
            name: {
                label: __('Názov'),
                get: item => {
                    const name = _.truncate(item.data.name, { length: 48 });

                    if (!item.data.available) {
                        return <span className="error">{name}</span>;
                    }

                    return name;
                },
            },
            amount: {
                label: __('Celková cena'),
                get: item => formatAmount(item.data.total_price, user.settings.currency),
            },
            number_ean: {
                label: __('Číslo produktu | EAN'),
                get: item => `${item.data.number !== '' ? item.data.number : '-'} / ${item.data.ean !== '' ? item.data.ean : '-'}`,
            },
            status: {
                label: __('Stav'),
                get: item => '',
            },
        };
    }

    /**
     * Has settings.
     *
     * @return {boolean}
     */
    hasSettings() {
        return false;
    }

    /**
     * Mame vlastne custom settingy.
     *
     * @return {boolean}
     */
    hasCustomSettings() {
        const { deleted, settings } = this.state;

        if (!_.isEmpty(settings) || !_.isEmpty(deleted)) {
            return false;
        }

        return true;
    }

    /**
     * Ulozenie marketplace settings.
     */
    saveMarketplaceSettings() {
        const { items } = this.props;
        const { marketplaceSettings } = this.state;

        this.setState({ loadingMarketplaceSettings: true });

        request(
            '/user-marketplaces/changeSettings',
            { settings: { ...items.marketplace_settings, ...marketplaceSettings } },
            'POST',
            { marketplace: this.getMarketplace() }
        ).then(response => {
            const { status } = response.data;

            this.setState({ loadingMarketplaceSettings: false, settingsCustomOpened: false });

            if (status === 'error') {
                // Nepodarilo sa zmenit nastavenia
                this.showSnackbar('error', __('Nepodarilo sa zmeniť nastavenia'));
                return;
            }

            this.showSnackbar('success', __('Nastavenia boli zmenené'));
        });
    }

    /**
     * Render vlastnych settingov.
     *
     * @return {JSX.Element|null}
     */
    renderCustomSettings() {
        const { items } = this.props;
        const { loadingMarketplaceSettings, marketplaceSettings } = this.state;

        return (
            <div className="marketplace-products-settings">
                <div className="marketplace-products-settings__list">
                    <Switch
                        label={__('Aktualizovať názov')}
                        checked={_.has(marketplaceSettings, 'update_name')
                            ? marketplaceSettings.update_name
                            : items.marketplace_settings.update_name}
                        onChange={value => this.setState({ marketplaceSettings: { ...marketplaceSettings, update_name: value } })}
                    />
                    <Switch
                        label={__('Aktualizovať cenu')}
                        checked={_.has(marketplaceSettings, 'update_price')
                            ? marketplaceSettings.update_price
                            : items.marketplace_settings.update_price}
                        onChange={value => this.setState({ marketplaceSettings: { ...marketplaceSettings, update_price: value } })}
                    />
                    <Switch
                        label={__('Aktualizovať stav na sklade')}
                        checked={_.has(marketplaceSettings, 'update_stock')
                            ? marketplaceSettings.update_stock
                            : items.marketplace_settings.update_stock}
                        onChange={value => this.setState({ marketplaceSettings: { ...marketplaceSettings, update_stock: value } })}
                    />
                </div>
                <Button
                    onClick={() => this.saveMarketplaceSettings()}
                    loading={loadingMarketplaceSettings}
                    disabled={_.isEmpty(marketplaceSettings)}
                    color="green"
                >{__('Uložiť')}</Button>
            </div>
        );
    }

    /**
     * Rendrujeme custom filter.
     *
     * @return {JSX.Element|null}
     */
    renderCustomFilter() {
        const { deleted, settings, loadingProducts } = this.state;

        if (!_.isEmpty(settings) || !_.isEmpty(deleted)) {
            return <Button
                onClick={() => this.saveProducts()}
                className="marketplaces-products-save-button"
                loading={loadingProducts}
            >{__('Uložiť')}</Button>
        }

        return super.renderCustomFilter();
    }
}

const stateToProps = ({ marketplaceProducts, user }) => ({ items: marketplaceProducts, user });

export default withCookies(connect(stateToProps, {
    fetch: fetchMarketplaceProducts,
    clean: cleanMarketplaceProducts,
    setUser,
    saveMarketplaceProducts,
})(MarketplaceProductsScreen));
