import _ from 'lodash';
import { FETCH_BMAILS, CLEAN_BMAILS, CREATE_GATEWAY, DELETE_GATEWAY, CREATE_BANK, DELETE_BANK } from './types';
import { request } from '../functions';

/**
 * Nacitanie bmailov.
 *
 * @param {Screen} screen
 * @param {object} options
 */
export const fetchBmails = (screen, options = {}) => {
    const defaults = {
        page: 1,
        per_page: 0,
        filtered: {},
    };

    options = { ...defaults, ...options };

    let params = { page: options.page, per_page: options.per_page };

    // Nasetujeme filter
    _.forEach(options.filtered, (value, key) => {
        params = { ...params, ...{ [`filter_${key}`]: value } };
    });

    return dispatch => request('/bmails/index', params).then(response => {
        const { data, sync } = response.data;
        const { setUser } = screen.props;

        // Nasetujeme user data
        setUser(sync.user);

        dispatch({ type: FETCH_BMAILS, payload: data });
    });
};

/**
 * Vytvorenie prepojenia na branu.
 *
 * @param {number} id
 */
export const createGateway = id => ({ type: CREATE_GATEWAY, payload: id });

/**
 * Zmazanie prepojenia na branu.
 *
 * @param {number} id
 */
export const deleteGateway = id => ({ type: DELETE_GATEWAY, payload: id });

/**
 * Vytvorenie prepojenia na banku.
 *
 * @param {number} id
 * @param {string} hash
 */
export const createBank = (id, hash) => ({ type: CREATE_BANK, payload: { id, hash } });

/**
 * Zmazanie prepojenia na banku.
 *
 * @param {number} id
 */
export const deleteBank = id => ({ type: DELETE_BANK, payload: id });

/**
 * Vycistenie bmailov.
 */
export const cleanBmails = () => ({ type: CLEAN_BMAILS });
