import React from 'react';
import { connect } from 'react-redux';
import { withCookies } from 'react-cookie';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import DeleteIcon from '@mui/icons-material/Delete';
import WarningIcon from '@mui/icons-material/Error';
import ReadIcon from '@mui/icons-material/AlarmOn';
import NewlyIcon from '@mui/icons-material/Alarm';
import { ListScreen } from '../components';
import {
    fetchNotifications,
    cleanNotifications,
    setUser,
    deleteNotification,
    deleteNotifications,
    readNotification,
    readNotifications,
    fetchUserNotifications,
} from '../actions';
import { __, formatAmount, formatDate } from '../functions';
import { COURIERS } from '../config';
import '../assets/styles/notifications.css';

/**
 * Notifikacie.
 */
class NotificationsScreen extends ListScreen {
    /**
     * Title.
     *
     * @type {string}
     */
    title = __('Upozornenia');

    /**
     * Default state.
     *
     * @type {Object}
     */
    state = { ...ListScreen.state, ...{
        lightbox: {
            delete: false,
            multidelete: false,
        },
    }};

    /**
     * Zoznam kategorii.
     *
     * @type {Object}
     */
    categories = {
        order: __('Objednávka'),
        invoice: __('Faktúra'),
        heureka: __('Heureka'),
        user: __('Eshop'),
        bmail: __('Banka'),
        track_trace: __('Track & Trace'),
    };

    /**
     * Zoznam notifikacii.
     *
     * @type {Object}
     */
    texts = {
        'order.create': __('Objednávka {NUMBER} bola vytvorená'),
        'order.send': __('Objednávka {NUMBER} bola odoslaná do {COURIER} s číslom zásielky {PACKAGE_NUMBER}'),
        'order.not_send': __('Objednávku {NUMBER} sa nepodarilo odoslať do {COURIER} - {ERROR}'),
        'order.send_waiting_payment': __('Objednávka {NUMBER} bola odoslaná do {COURIER} ale čaká na úhradu u prepravcu'),
        'invoice.create': __('Faktúra {NUMBER} bola vystavená na základe objednávky {ORDER_NUMBER}'),
        'invoice.regular_create': __('Faktúra {NUMBER} bola vystavená na základe úhrady zálohovej faktúry {PROFORMA_NUMBER}'),
        'invoice.cancel_create': __('Dobropis {NUMBER} bol vystavený na základe faktúry {REGULAR_NUMBER}'),
        'invoice.paid': __('Faktúre {NUMBER} bola pridaná úhrada vo výške {AMOUNT}'),
        'heureka.create': __('Objednávka {NUMBER} bola vytvorená na heuréke'),
        'heureka.cancel': __('Objednávka {NUMBER} bola stornovaná na heuréke'),
        'heureka.paid': __('Objednávka {NUMBER} bola uhradená na heuréke'),
        'user.no_subscription': __('Objednávka nebola importovaná z dôvodu neaktívneho členstva'),
        'user.info_days_subscription': __('Vaše členstvo končí o {COUNT} dní'),
        'user.info_tomorrow_subscription': __('Vaše členstvo končí zajtra'),
        'bmail.invalid_amount': __('Objednávka číslo {NUMBER} nebola spárovaná z dôvodu prijatia rozdielnej sumy {AMOUNT}'),
        'track_trace.cancel': __('Objednávka {NUMBER} bola stornovaná cez Track & Trace'),
        'track_trace.complaint': __('Objednávka {NUMBER} bola reklamovaná cez Track & Trace'),
        'track_trace.complaint_comment': __('K objednávke {NUMBER} bolo pridané vyjadrenie reklamácie cez Track & Trace'),
    };

    /**
     * Komponenta bola pripojena.
     *
     * @return {boolean}
     */
    componentDidMount() {
        if (this.getPath() === '/notifications/high') {
            this.defaultFiltered = { priority: 'high' };
        }

        return super.componentDidMount();
    }

    /**
     * Naformatujeme notifikaciu.
     *
     * @param {string} text
     * @param {Object} data
     *
     * @return {JSX.Element}
     */
    formatText(text, data) {
        // Vytiahneme zoznam placeholderov
        const placeholders = text.match(/({.*?})/g);

        // Rozdelime text na slova
        const words = text.split(' ');
        let response = [];

        _.each(words, word => {
            response = [ ...response, ...[word, ' '] ];
        });

        if (_.toArray(placeholders).length === 0) {
            // Nemame ziadne placeholdre
            return response;
        }

        const { errorChars } = this.props;

        _.each(placeholders, placeholder => {
            // Vytiahneme nazov fieldu
            const field = placeholder.replace(/[{}]/g, '').toLowerCase();

            if (_.has(data, field)) {
                // Data maju placeholder field
                let value = data[field];

                switch (field) {
                    case 'number':
                    case 'order_number':
                    case 'proforma_number':
                    case 'regular_number':
                        // Number field ma svoj number_type a number_id field
                        let prefix_number = field.match(/(.*?)_/);
                        prefix_number = prefix_number !== null ? prefix_number[0] : '';

                        const number_type_field = `${prefix_number}number_type`;
                        const number_id_field = `${prefix_number}number_id`;

                        if (_.has(data, number_type_field) && _.has(data, number_id_field)) {
                            let url = '';

                            switch (data[number_type_field]) {
                                case 'order':
                                default:
                                    // Objednavka
                                    // TODO::
                                    //url = `/orders/view/${data[number_id_field]}`;
                                    break;

                                case 'invoice':
                                    // Faktura
                                    // TODO::
                                    //url = `/invoices/view/${data[number_id_field]}`;
                                    break;
                            }

                            value = <Link key={url} to={url}>{value}</Link>;
                        }
                        break;

                    case 'amount':
                        // Amount field ma currency field
                        if (_.has(data, 'currency')) {
                            value = formatAmount(value, data.currency);
                        }
                        break;

                    case 'courier':
                        if (_.has(COURIERS, value)) {
                            value = <Link key={value} to={`/courier/${value}`}>{COURIERS[value]}</Link>;
                        }
                        break;

                    case 'error':
                        value = value.substring(0, errorChars);
                        break;

                    default:
                        break;
                }

                // Nahradime placeholder v response
                response = _.map(response, word => {
                    if (word === placeholder) {
                        return value;
                    }

                    return word;
                });
            }
        });

        return response;
    }

    /**
     * Vratime nazov tabulky.
     *
     * @return {string}
     */
    getTableName() {
        return 'notifications';
    }

    /**
     * Vratime stlpce.
     *
     * @return {Array}
     */
    getColumns() {
        return [
            '',
            __('Kategória'),
            __('Text'),
            __('Vytvorené'),
            '',
        ];
    }

    /**
     * Vratime bunky.
     *
     * @param {Object} item
     *
     * @return {Array}
     */
    getCells(item) {
        // Vytiahneme tlacitka pre zakaznika
        const buttons = {
            read: {
                name: item.status === 'newly' ? __('Označiť ako prečítané') : __('Prečítané'),
                icon: item.status === 'newly' ? <NewlyIcon color="orange" /> : <ReadIcon />,
                callback: callback => this.read(item.id, callback),
                options: {
                    disabled: item.status === 'read',
                },
            },
            delete: {
                name: __('Zmazať'),
                icon: <DeleteIcon />,
                callback: (callbackLoading, callback) => this.confirmDelete(item.id, callbackLoading, callback),
            },
        };

        return [
            item.priority === 'high' && item.status === 'newly' ? <WarningIcon color="orange" /> : null,
            _.has(this.categories, item.category) ? this.categories[item.category] : '',
            _.has(this.texts, item.type) ? this.formatText(this.texts[item.type], item.data) : '',
            formatDate(item.created, 'dd.mm.yyyy hh:ii'),
            this.renderTableButtons(item.id, buttons)
        ];
    }

    /**
     * Vratime multiselect.
     *
     * @return {Array}
     */
    getMultiselect() {
        return [
            {
                callback: (ids, callback) => this.multiRead(ids, callback),
                icon: <NewlyIcon />,
                text: __('Označiť ako prečítané'),
            },
            {
                confirm: (ids, callback, callbackLoading) => this.showLightbox('multidelete', {
                    ids,
                    callback,
                    callbackLoading,
                }),
                icon: <DeleteIcon />,
                text: __('Zmazať'),
            }
        ];
    }

    /**
     * Vratime filter.
     *
     * @return {Object}
     */
    getFilter() {
        const { items } = this.props;

        return _.reduce(items.filter, (result, options, key) => {
            switch (key) {
                case 'category':
                    return { ...result, ...{ [key]: {
                        type: 'select',
                        name: __('Kategória'),
                        value: '',
                        options: this.categories,
                    }}};

                case 'priority':
                    return { ...result, ...{ [key]: {
                        type: 'select',
                        name: __('Priorita'),
                        value: '',
                        options: {
                            low: __('Nízka'),
                            high: __('Vysoká'),
                        },
                    }}};

                default:
                    return result;
            }
        }, {});
    }

    /**
     * Vratime empty text.
     *
     * @return {string}
     */
    getEmptyText() {
        return __('Zatiaľ nemáte žiadne upozornenia');
    }

    /**
     * Zobrazime lightbox na zmazanie.
     *
     * @param {number} id
     * @param {function} callbackLoading
     * @param {function} callback
     */
    confirmDelete(id, callbackLoading, callback) {
        this.showLightbox('delete', { id, callbackLoading, callback });
    }

    /**
     * Oznacenie notifikacie ako precitane.
     *
     * @param {number} id
     * @param {function} callback
     */
    async read(id, callback) {
        const { readNotification, fetchUserNotifications } = this.props;

        // Precitame
        await readNotification(this, id);

        // Zavolame callback
        callback();

        // Nacitame novy pocet
        fetchUserNotifications();
    }

    /**
     * Zmazanie notifikacie.
     */
    async delete() {
        const { deleteNotification, items } = this.props;
        const { lightbox } = this.state;

        // Zavolame loading
        lightbox.delete.callbackLoading();

        // Zavrieme lightbox
        this.closeLightbox('delete');

        // Zmazeme notifikaciu
        await deleteNotification(this, lightbox.delete.id);

        // Zavolame callback
        lightbox.delete.callback();

        // Znovu nacitame zoznam od prvej stranky
        this.fetchList(1, 0, items.filtered);
    }

    /**
     * Zmazanie notifikacii cez multiselect.
     */
    async multiDelete() {
        const { deleteNotifications, items } = this.props;
        const { lightbox } = this.state;

        // Zavrieme lightbox
        this.closeLightbox('multidelete');

        // Zobrazime loading
        lightbox.multidelete.callbackLoading();

        // Zmazeme notifikacie
        await deleteNotifications(this, lightbox.multidelete.ids, items.filtered);

        // Zavolame callback
        lightbox.multidelete.callback();

        // Znovu nacitame zoznam od prvej stranky
        this.fetchList(1, 0, items.filtered);
    }

    /**
     * Precitanie notifikacii cez multiselect.
     *
     * @param {string|Array} ids
     * @param {function} callback
     */
    async multiRead(ids, callback) {
        const { readNotifications, items } = this.props;

        // Precitame notifikacie
        await readNotifications(this, ids, items.filtered);

        // Zavolame callback
        callback();

        // Znovu nacitame zoznam od prvej stranky
        this.fetchList(1, 0, items.filtered);
    }

    /**
     * Rendrujeme screen.
     *
     * @return {JSX.Element|null}
     */
    renderScreen() {
        return (
            <div>
                {this.renderLightbox(
                    'delete',
                    __('Naozaj chcete zmazať toto upozornenie?'),
                    null,
                    __('Áno'),
                    __('Nie'),
                    () => this.delete()
                )}
                {this.renderLightbox(
                    'multidelete',
                    __('Naozaj chcete zmazať tieto upozornenia?'),
                    null,
                    __('Áno'),
                    __('Nie'),
                    () => this.multiDelete()
                )}
            </div>
        );
    }
}

const stateToProps = ({ notifications, user }) => ({ items: notifications, user });

export default withCookies(connect(stateToProps, {
    fetch: fetchNotifications,
    clean: cleanNotifications,
    setUser,
    deleteNotification,
    deleteNotifications,
    readNotification,
    readNotifications,
    fetchUserNotifications,
})(NotificationsScreen));
