import { FETCH_TEMPLATES, CLEAN_TEMPLATES, DELETE_TEMPLATE, TEMPLATE_UPLOAD_LOGO } from '../actions/types';

const TEMPLATES_DEFAULT_STATE = {};

/**
 * Reducer pre sablony.
 */
const TemplatesReducer = (state = TEMPLATES_DEFAULT_STATE, { type, payload }) => {
    switch (type) {
        case FETCH_TEMPLATES:
            // Nacitanie sablon
            return payload;

        case TEMPLATE_UPLOAD_LOGO:
            // Upload loga
            return { ...state, logo: payload };

        case CLEAN_TEMPLATES:
        case DELETE_TEMPLATE:
            // Vycistenie sablon
            // Zmazanie sablony
            return TEMPLATES_DEFAULT_STATE;

        default:
            return state;
    }
};

export default TemplatesReducer;
