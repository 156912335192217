import _ from 'lodash';
import { FETCH_INVOICES, CLEAN_INVOICES, DELETE_INVOICE, DELETE_INVOICES } from './types';
import { request, __ } from '../functions';

/**
 * Nacitanie faktur.
 *
 * @param {Screen} screen
 * @param {object} options
 */
export const fetchInvoices = (screen, options = {}) => {
    const defaults = {
        page: 1,
        per_page: 0,
        filtered: {},
    };

    options = { ...defaults, ...options };

    let params = { page: options.page, per_page: options.per_page };

    // Nasetujeme filter
    _.forEach(options.filtered, (value, key) => {
        params = { ...params, ...{ [`filter_${key}`]: value } };
    });

    return dispatch => request('/invoices/index', params).then(response => {
        const { data, sync } = response.data;
        const { setUser } = screen.props;

        // Nasetujeme user data
        setUser(sync.user);

        dispatch({ type: FETCH_INVOICES, payload: data });
    });
};

/**
 * Zmazanie faktury.
 *
 * @param {Screen} screen
 * @param {number} id
 */
export const deleteInvoice = (screen, id) => {
    const { invoice_delete_in_acccounting } = screen.props.user.settings;

    return dispatch => request(`/invoices/delete/${id}`, {
        invoice_delete_in_acccounting,
        invoice_delete_reactivate_order: false,
    }).then(response => {
        const { status } = response.data;

        if (status === 'error') {
            // Fakturu sa nepodarilo zmazat
            screen.showSnackbar('error', __('Faktúru sa nepodarilo zmazať'));
            return;
        }

        screen.showSnackbar('success', __('Faktúra bola zmazaná'));

        dispatch({ type: DELETE_INVOICE });
    });
};

/**
 * Zmazanie faktur cez multiselect.
 *
 * @param {Screen} screen
 * @param {string} ids
 * @param {Object} filtered
 */
export const deleteInvoices = (screen, ids, filtered = {}) => {
    const { invoice_delete_in_acccounting } = screen.props.user.settings;

    let params = {
        invoice_delete_in_acccounting,
        invoice_delete_reactivate_order: false,
    };

    // Nasetujeme filter
    _.forEach(filtered, (value, key) => {
        params = { ...params, ...{ [`filter_${key}`]: value } };
    });

    return dispatch => request(`/invoices/multiDelete/${ids}`, params).then(response => {
        const { status } = response.data;

        if (status === 'error') {
            // Faktury sa nepodarilo zmazat
            screen.showSnackbar('error', __('Faktúry sa nepodarilo zmazať'));
            return;
        }

        screen.showSnackbar('success', __('Faktúry boli zmazané'));

        dispatch({ type: DELETE_INVOICES });
    });
};

/**
 * Vycistenie faktur.
 */
export const cleanInvoices = () => ({ type: CLEAN_INVOICES });
