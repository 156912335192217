import _ from 'lodash';
import {FETCH_MTOKNA_BANNERS, CLEAN_MTOKNA_BANNERS, DELETE_MTOKNA_BANNER, CHANGE_MTOKNA_BANNER} from './types';
import { request, __ } from '../functions';

/**
 * Nacitanie sablon.
 *
 * @param {Screen} screen
 * @param {object} options
 */
export const fetchMtoknaBanners = (screen, options = {}) => {
    const defaults = {
        page: 1,
        per_page: 0,
        filtered: {},
    };

    options = { ...defaults, ...options };

    let params = { page: options.page, per_page: options.per_page };

    // Nasetujeme filter
    _.forEach(options.filtered, (value, key) => {
        params = { ...params, ...{ [`filter_${key}`]: value } };
    });

    return dispatch => request('/mtokna/banners', params).then(response => {
        const { data, sync } = response.data;
        const { setUser } = screen.props;

        // Nasetujeme user data
        setUser(sync.user);

        dispatch({ type: FETCH_MTOKNA_BANNERS, payload: data });
    });
};

export const deleteMtoknaBanner = (screen, id) => {
    return dispatch => request(`/mtokna/removeBanner/${id}`).then(response => {
        const { status } = response.data;

        if (status === 'error') {
            screen.showSnackbar('error', __('Nepodarilo sa zmazať banner'));
            return;
        }

        screen.showSnackbar('success', __('Banner bol zmazaný'));

        dispatch({ type: DELETE_MTOKNA_BANNER });
    });
};

export const changeMtoknaBanner = (screen, id, type) => {
    return dispatch => request(`/mtokna/changeBannerType/${id}/${type}`).then(response => {
        const { status } = response.data;

        if (status === 'error') {
            screen.showSnackbar('error', __('Nepodarilo sa zmeniť banner'));
            return;
        }

        screen.showSnackbar('success', __('Banner bol zmenený'));

        dispatch({ type: CHANGE_MTOKNA_BANNER, payload: { id, type } });
    });
};

/**
 * Vycistenie sablon.
 */
export const cleanMtoknaBanners = () => ({ type: CLEAN_MTOKNA_BANNERS });
