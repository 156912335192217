import { FETCH_COMPLAINTS, CLEAN_COMPLAINTS, DELETE_COMPLAINT, DELETE_COMPLAINTS } from '../actions/types';

const COMPLAINTS_DEFAULT_STATE = {};

/**
 * Reducer pre reklamacie.
 */
const ComplaintsReducer = (state = COMPLAINTS_DEFAULT_STATE, { type, payload }) => {
    switch (type) {
        case FETCH_COMPLAINTS:
            // Nacitanie reklamacii
            return payload;

        case CLEAN_COMPLAINTS:
        case DELETE_COMPLAINT:
        case DELETE_COMPLAINTS:
            // Vycistenie reklamacii
            // Zmazanie reklamacie
            // Zmazanie reklamacii cez multiselect
            return COMPLAINTS_DEFAULT_STATE;

        default:
            return state;
    }
};

export default ComplaintsReducer;
