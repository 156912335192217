import { FETCH_MTOKNA_WINDOW_SELLER, CLEAN_MTOKNA_WINDOW_SELLER } from '../actions/types';

const MTOKNA_WINDOW_SELLER_DEFAULT_STATE = {};

/**
 * Reducer pre sablonu.
 */
const MtoknaWindowSellerReducer = (state = MTOKNA_WINDOW_SELLER_DEFAULT_STATE, { type, payload }) => {
    switch (type) {
        case FETCH_MTOKNA_WINDOW_SELLER:
            return payload;

        case CLEAN_MTOKNA_WINDOW_SELLER:
            return MTOKNA_WINDOW_SELLER_DEFAULT_STATE;

        default:
            return state;
    }
};

export default MtoknaWindowSellerReducer;
