import _ from 'lodash';
import {
    FETCH_MTOKNA_WINDOWS,
    CLEAN_MTOKNA_WINDOWS,
    EDIT_MTOKNA_WINDOW,
    DELETE_MTOKNA_WINDOW,
    CHANGE_MTOKNA_WINDOW_STATE,
    READY_PICKUP_MTOKNA_WINDOW,
    PICKUP_MTOKNA_WINDOW,
    ACTIVATE_MTOKNA_WINDOW,
} from './types';
import {request, __} from '../functions';

export const fetchMtoknaWindows = (screen, options = {}) => {
    const defaults = {
        page: 1,
        per_page: 0,
        filtered: {},
        callback: null,
    };

    options = { ...defaults, ...options };

    let params = { page: options.page, per_page: options.per_page };

    // Nasetujeme filter
    _.forEach(options.filtered, (value, key) => {
        params = { ...params, ...{ [`filter_${key}`]: value } };
    });

    return dispatch => request('/mtokna/windows', params).then(response => {
        const { data, sync } = response.data;
        const { setUser } = screen.props;

        if (options.callback !== null) {
            // Zavolame callback
            options.callback();
        }

        // Nasetujeme user data
        setUser(sync.user);

        dispatch({ type: FETCH_MTOKNA_WINDOWS, payload: data });
    });
};

export const cleanMtoknaWindows = () => ({ type: CLEAN_MTOKNA_WINDOWS });

/**
 * Zmazanie objednavky.
 *
 * @param {Screen} screen
 * @param {number} id
 */
export const deleteMtoknaWindow = (screen, id) => {
    return dispatch => request(`/mtokna/deleteManualSellerOrder/${id}`, { order_delete_in_eshop: false }).then(response => {
        const { status } = response.data;

        if (status === 'error') {
            // Objednavku sa nepodarilo zmazat
            screen.showSnackbar('error', __('Objednávku sa nepodarilo zmazať pretože má evidovanú faktúru'));
            return;
        }

        screen.showSnackbar('success', __('Objednávka bola zmazaná'));

        dispatch({ type: DELETE_MTOKNA_WINDOW });
    });
};

export const changeMtoknaWindowState = (screen, id, stateId) => {
    return dispatch => request(`/mtokna/changeWindowOrderState/${id}/${stateId}`).then(response => {
        const { status, data } = response.data;

        if (status === 'error') {
            // Stav sa nepodarilo zmenit
            screen.showSnackbar('error', __('Stav objednávky sa nepodarilo zmeniť'));
            return;
        }

        screen.showSnackbar('success', __('Stav objednávky bol zmenený'));

        dispatch({ type: CHANGE_MTOKNA_WINDOW_STATE, payload: data });
    });
};

export const readyPickupMtoknaWindow = (screen, id) => {
    return dispatch => request(`/mtokna/readyForPickupWindow/${id}`).then(response => {
        const { status, data } = response.data;

        if (status === 'error') {
            // Stav sa nepodarilo zmenit
            screen.showSnackbar('error', __('Nepodarilo sa zmeniť objednávku'));
            return;
        }

        screen.showSnackbar('success', __('Objednávka je pripravená na vyzdvihnutie'));

        dispatch({ type: READY_PICKUP_MTOKNA_WINDOW, payload: data });
    });
};

export const pickupMtoknaWindow = (screen, id) => {
    return dispatch => request(`/mtokna/pickupWindow/${id}`).then(response => {
        const { status, data } = response.data;

        if (status === 'error') {
            // Stav sa nepodarilo zmenit
            screen.showSnackbar('error', __('Nepodarilo sa zmeniť objednávku'));
            return;
        }

        screen.showSnackbar('success', __('Objednávka je vyzdvihnutá'));

        dispatch({ type: PICKUP_MTOKNA_WINDOW, payload: data });
    });
};

export const activateMtoknaWindow = (screen, id) => {
    return dispatch => request(`/mtokna/activateWindow/${id}`).then(response => {
        const { status, data } = response.data;

        if (status === 'error') {
            // Stav sa nepodarilo zmenit
            screen.showSnackbar('error', __('Objednávku sa nepodarilo aktivovať'));
            return;
        }

        screen.showSnackbar('success', __('Objednávka bola aktivovaná'));

        dispatch({ type: ACTIVATE_MTOKNA_WINDOW, payload: data });
    });
};

export const changeMtoknaWindowData = (id, data) => ({ type: EDIT_MTOKNA_WINDOW, payload: { id, data } });
