import { FETCH_MTOKNA_SELLERS, CLEAN_MTOKNA_SELLERS, CHANGE_MTOKNA_SELLER_EMAIL, CHANGE_MTOKNA_SELLER_SMTP } from '../actions/types';
import _ from 'lodash';
import { toNumber } from '../functions';

const MTOKNA_SELLERS_DEFAULT_STATE = {};

const MtoknaSellersReducer = (state = MTOKNA_SELLERS_DEFAULT_STATE, { type, payload }) => {
    switch (type) {
        case FETCH_MTOKNA_SELLERS:
            return payload;

        case CHANGE_MTOKNA_SELLER_EMAIL:
            return { ...state, items: _.map(state.items, item => {
                if (toNumber(item.user_id) === toNumber(payload.id)) {
                    item = { ...item, _matchingData: {
                        ...item._matchingData,
                        Users: { ...item._matchingData.Users, email: payload.email }
                    } };
                }

                return item;
            }) };

        case CHANGE_MTOKNA_SELLER_SMTP:
            return { ...state, items: _.map(state.items, item => {
                if (toNumber(item.user_id) === toNumber(payload.id)) {
                    item = { ...item, smtp: payload.smtp };
                }

                return item;
            }) };

        case CLEAN_MTOKNA_SELLERS:
            return MTOKNA_SELLERS_DEFAULT_STATE;

        default:
            return state;
    }
};

export default MtoknaSellersReducer;
