import _ from 'lodash';
import { toNumber } from '../functions';
import {
    FETCH_MTOKNA_COMPLAINTS,
    CLEAN_MTOKNA_COMPLAINTS,
    EDIT_MTOKNA_COMPLAINT,
    DELETE_MTOKNA_COMPLAINT,
    EXPORT_MTOKNA_COMPLAINT,
    CHANGE_MTOKNA_COMPLAINT_STATE,
    CANCEL_MTOKNA_COMPLAINT,
} from '../actions/types';

const MTOKNA_COMPLAINTS_DEFAULT_STATE = {};

/**
 * Reducer pre objednavky.
 */
const MtoknaComplaintsReducer = (state = MTOKNA_COMPLAINTS_DEFAULT_STATE, { type, payload }) => {
    switch (type) {
        case FETCH_MTOKNA_COMPLAINTS:
            return payload;

        case CLEAN_MTOKNA_COMPLAINTS:
        case DELETE_MTOKNA_COMPLAINT:
            return MTOKNA_COMPLAINTS_DEFAULT_STATE;

        case EXPORT_MTOKNA_COMPLAINT:
            return { ...state, items: _.map(state.items, item => {
                if (_.has(payload, item.id)) {
                    // Nasli sme objednavku nastavime priznak ako exportovany
                    item = { ...item, exported: 1 };
                }

                return item;
            }) };

        case CHANGE_MTOKNA_COMPLAINT_STATE:
        case CANCEL_MTOKNA_COMPLAINT:
            return { ...state, items: _.map(state.items, item => {
                if (toNumber(item.id) === toNumber(payload.id)) {
                    // Nasli sme objednavku zmenime data
                    item = { ...item, _matchingData: { ...item._matchingData, MtoknaComplaints: { ...item._matchingData.MtoknaComplaints, state: payload.state } } };
                }

                return item;
            }) };

        case EDIT_MTOKNA_COMPLAINT:
            // Editacia objednavky
            return { ...state, items: _.map(state.items, item => {
                if (toNumber(item.id) === toNumber(payload.id)) {
                    // Nasli sme objednavku
                    item = { ...item, data: { ...item.data, ...payload.data } };
                }

                return item;
            }) };

        default:
            return state;
    }
};

export default MtoknaComplaintsReducer;
