import React from 'react';
import { connect } from 'react-redux';
import { withCookies } from 'react-cookie';
import _ from 'lodash';
import PdfIcon from '@mui/icons-material/PictureAsPdf';
import DeleteIcon from '@mui/icons-material/Delete';
import { ListScreen, Checkbox } from '../components';
import {
    fetchInvoices,
    cleanInvoices,
    setUser,
    changeUserSetting,
    deleteInvoice,
    deleteInvoices,
} from '../actions';
import { __, formatAmount, request, toNumber, getInvoiceButtons } from '../functions';
import { INVOICE_TYPES } from '../config';
import '../assets/styles/invoices.css';

/**
 * Faktury.
 */
class InvoicesScreen extends ListScreen {
    /**
     * Title.
     *
     * @type {string}
     */
    title = __('Faktúry');

    /**
     * Zoznam statusov.
     *
     * @type {Object}
     */
    statuses = {
        unpaid: { color: '#c24646', name: __('Neuhradená') },
        partially: { color: '#ff9931', name: __('Čiastočne uhradená') },
        paid: { color: '#459350', name: __('Uhradená') },
    };

    /**
     * Default state.
     *
     * @type {Object}
     */
    state = { ...ListScreen.state, ...{
        downloadedPdf: [],
        loadingEbenicaExport: false,
        lightbox: {
            delete: false,
            multidelete: false,
            multipdf: false,
        },
    }};

    /**
     * Memory cache.
     *
     * @type {Object}
     */
    memoryCache = {};

    /**
     * Je validne pravo?
     *
     * @return {boolean}
     */
    isValidPermission() {
        return this.hasPermission('invoices');
    }

    /**
     * Vratime nazov tabulky.
     *
     * @return {string}
     */
    getTableName() {
        return 'invoices';
    }

    /**
     * Vratime zoznam tagov.
     *
     * @return {Array}
     */
    getTags() {
        const { user } = this.props;
        const { loadingEbenicaExport } = this.state;

        const userEshopId = toNumber(user.user_eshop_id);

        return [
            _.includes([54,112,113,114,115,137], userEshopId) ? {
                name: !loadingEbenicaExport ? __('Export') : this.renderLoading(18, 'inherit'),
                onClick: () => this.ebenicaExport(),
            } : null,
        ];
    }

    /**
     * Synchronizacia.
     */
    ebenicaExport() {
        this.setState({ loadingEbenicaExport: true });

        request('/user-eshops/ebenicaExport/invoices').then(response => {
            const { status, data } = response.data;

            this.setState({ loadingEbenicaExport: false });

            if (status === 'error') {
                this.showSnackbar('error', __('Nepodarilo sa stiahnuť export'));
                return;
            }

            this.showSnackbar('success', __('Export vám bude odoslaný na email do pár minút'));
        });
    }

    /**
     * Vratime bunky.
     *
     * @param {Object} item
     *
     * @return {Array}
     */
    getCells(item) {
        const { downloadedPdf } = this.state;

        if (!_.has(this.memoryCache, 'hasInvoicePdf')) {
            // Nemame v memory cache
            this.memoryCache.hasInvoicePdf = this.hasAccountingAction('pdf');
        }

        if (!_.has(this.memoryCache, 'hasPermissionPdf')) {
            // Nemame v memory cache
            this.memoryCache.hasPermissionPdf = this.hasPermission('invoices-pdf');
        }

        if (!_.has(this.memoryCache, 'hasPermissionDelete')) {
            // Nemame v memory cache
            this.memoryCache.hasPermissionDelete = this.hasPermission('invoices-delete');
        }

        // Vytiahneme tlacitka pre fakturu
        let buttons = getInvoiceButtons(item, {
            downloadedPdf,
            hasInvoicePdf: this.memoryCache.hasInvoicePdf,
            hasPermissionPdf: this.memoryCache.hasPermissionPdf,
            hasPermissionDelete: this.memoryCache.hasPermissionDelete,
        });

        // Nastavime callbacky
        if (_.has(buttons, 'delete')) {
            buttons.delete.callback = (callbackLoading, callback) => this.confirmDelete(item.id, callbackLoading, callback);
        }

        if (_.has(buttons, 'pdf')) {
            buttons.pdf.callback = (callbackLoading, callback) => this.pdf(item.id, callbackLoading, callback);
        }

        // Vytiahneme nastavenia buniek
        const cellsSettings = this.getCellsSettings();

        // Vytiahneme options buniek
        const cellsOptions = this.getCellsOptions();

        return [
            <div>
                <div style={{ backgroundColor: this.statuses[item.status].color }} />
                {this.getCellValue(cellsOptions[cellsSettings.settings[0][0]].get(item))}
                {this.getCellValue(cellsOptions[cellsSettings.settings[0][1]].get(item))}
                {this.getCellValue(cellsOptions[cellsSettings.settings[0][2]].get(item))}
            </div>,
            <div>
                {this.getCellValue(cellsOptions[cellsSettings.settings[1][0]].get(item))}
                {this.getCellValue(cellsOptions[cellsSettings.settings[1][1]].get(item))}
                {this.getCellValue(cellsOptions[cellsSettings.settings[1][2]].get(item))}
            </div>,
            <div>
                {this.getCellValue(cellsOptions[cellsSettings.settings[2][0]].get(item))}
                {this.getCellValue(cellsOptions[cellsSettings.settings[2][1]].get(item))}
                {this.getCellValue(cellsOptions[cellsSettings.settings[2][2]].get(item))}
            </div>,
            <div>{this.renderTableButtons(item.id, buttons)}</div>,
        ];
    }

    /**
     * Vratime options buniek.
     *
     * @return {Object}
     */
    getCellsOptions() {
        const { user, items } = this.props;

        return {
            name: {
                label: __('Názov'),
                get: item => _.truncate(item.data.name, { length: 48 }),
            },
            number: {
                label: __('Číslo'),
                get: item => item.data.number,
            },
            amount: {
                label: __('Suma'),
                get: item => formatAmount(item.data.amount, item.data.currency),
            },
            order_number: {
                label: __('Číslo objednávky'),
                get: item => item._matchingData.Orders.data.number,
            },
            type: {
                label: __('Typ'),
                get: item => INVOICE_TYPES[item.type],
            },
            status: {
                label: __('Stav'),
                get: item => this.statuses[item.status].name,
            },
            paid: {
                label: __('Uhradená suma'),
                get: item => formatAmount(item.data.paid, user.settings.currency),
            },
            variable: {
                label: __('Variabilný symbol'),
                get: item => item.data.variable,
            },
            payment_type: {
                label: __('Typ úhrady'),
                get: item => _.has(items.accounting_data.payment_types, item.data.payment_type)
                    ? items.accounting_data.payment_types[item.data.payment_type]
                    : '',
            },
        };
    }

    /**
     * Vratime multiselect.
     *
     * @return {Array}
     */
    getMultiselect() {
        return [
            this.hasAccountingAction('pdf') ? {
                confirm: (ids, callback, callbackLoading) => this.showLightbox('multipdf', {
                    ids,
                    callback,
                    callbackLoading,
                }),
                icon: <PdfIcon />,
                text: __('Stiahnuť PDF'),
                limit: 50,
                permission: 'invoices-pdf',
            } : null,
            this.hasAccountingAction('delete') ? {
                confirm: (ids, callback, callbackLoading) => this.showLightbox('multidelete', {
                    ids,
                    callback,
                    callbackLoading,
                }),
                icon: <DeleteIcon />,
                text: __('Zmazať'),
                permission: 'invoices-delete',
            } : null,
        ];
    }

    /**
     * Vratime filter.
     *
     * @return {Object}
     */
    getFilter() {
        const { items } = this.props;

        return _.reduce(items.filter, (result, options, key) => {
            switch (key) {
                case 'status':
                    return { ...result, ...{ [key]: {
                        type: 'select',
                        name: __('Stav'),
                        value: '',
                        options: _.reduce(
                            this.statuses,
                            (result, { name }, index) => ({ ...result, [index]: name }),
                            {}
                        ),
                    }}};

                case 'type':
                    return { ...result, ...{ [key]: {
                        type: 'select',
                        name: __('Typ'),
                        value: '',
                        options: INVOICE_TYPES,
                    }}};

                case 'search':
                    return { ...result, ...{ [key]: {
                        type: 'input',
                        name: __('Hľadať'),
                        value: '',
                    }}};

                default:
                    return result;
            }
        }, {});
    }

    /**
     * Vratime empty text.
     *
     * @return {string}
     */
    getEmptyText() {
        return __('Zatiaľ nemáte žiadne faktúry');
    }

    /**
     * Pridame stiahnute pdf.
     *
     * @param {Array} ids
     *
     * @return {Array}
     */
    getDownloadedPdf(ids) {
        let { downloadedPdf } = this.state;

        _.each(ids, id => {
            downloadedPdf = [ ...downloadedPdf, toNumber(id) ];
        });

        return downloadedPdf;
    }

    /**
     * Zobrazime lightbox na zmazanie.
     *
     * @param {number} id
     * @param {function} callbackLoading
     * @param {function} callback
     */
    confirmDelete(id, callbackLoading, callback) {
        this.showLightbox('delete', { id, callbackLoading, callback });
    }

    /**
     * Zmazanie faktury.
     */
    async delete() {
        const { deleteInvoice, items } = this.props;
        const { lightbox } = this.state;

        // Zavolame loading
        lightbox.delete.callbackLoading();

        // Zavrieme lightbox
        this.closeLightbox('delete');

        // Zmazeme fakturu
        await deleteInvoice(this, lightbox.delete.id);

        // Zavolame callback
        lightbox.delete.callback();

        // Znovu nacitame zoznam od prvej stranky
        this.fetchList(1, 0, items.filtered);
    }

    /**
     * Stiahnutie pdf.
     *
     * @param {number} id
     * @param {function} callbackLoading
     * @param {function} callback
     */
    async pdf(id, callbackLoading, callback) {
        // Zavolame loading
        callbackLoading();

        await request(`/invoices/pdf/${id}`).then(response => {
            const { status, data } = response.data;

            if (status === 'error') {
                this.showSnackbar('error', __('Nepodarilo sa stiahnuť pdf'));
                return;
            }

            this.setState({ downloadedPdf: this.getDownloadedPdf([id]) });

            if (data.link.indexOf('inline') !== -1) {
                // Chceme inline
                window.open(data.link, '_blank');
                return;
            }

            // Stiahneme subor
            window.location = data.link;
        });

        // Zavolame callback
        callback();
    }

    /**
     * Zmazanie faktur cez multiselect.
     */
    async multiDelete() {
        const { deleteInvoices, items } = this.props;
        const { lightbox } = this.state;

        // Zavrieme lightbox
        this.closeLightbox('multidelete');

        // Zobrazime loading
        lightbox.multidelete.callbackLoading();

        // Zmazeme faktur
        await deleteInvoices(this, lightbox.multidelete.ids, items.filtered);

        // Zavolame callback
        lightbox.multidelete.callback();

        // Znovu nacitame zoznam od prvej stranky
        this.fetchList(1, 0, items.filtered);
    }

    /**
     * Stiahnutie pdf faktur cez multiselect.
     */
    async multiPdf() {
        const { items, user } = this.props;
        const { lightbox } = this.state;

        // Zavrieme lightbox
        this.closeLightbox('multipdf');

        // Zobrazime loading
        lightbox.multipdf.callbackLoading();

        let params = { merge_multi_pdf: user.settings.merge_multi_pdf };

        // Nasetujeme filter
        _.forEach(items.filtered, (value, key) => {
            params = { ...params, ...{ [`filter_${key}`]: value } };
        });

        await request(`/invoices/multiPdf/${lightbox.multipdf.ids}`, params).then(response => {
            const { status, data } = response.data;

            if (status === 'error') {
                this.showSnackbar('error', __('Nepodarilo sa stiahnuť zoznam pdf'));
                return;
            }

            // Nastavime pdfka ako precitane
            this.setState({ downloadedPdf: this.getDownloadedPdf(lightbox.multipdf.ids.split(',')) });

            // Stiahneme subor
            window.location = data.link;
        });

        // Zavolame callback
        lightbox.multipdf.callback();
    }

    /**
     * Rendrujeme screen.
     *
     * @return {JSX.Element|null}
     */
    renderScreen() {
        const { user, changeUserSetting } = this.props;
        const { lightbox } = this.state;

        const hasDelete = this.hasAccountingAction('delete');

        return (
            <div>
                {this.renderLightbox(
                    'delete',
                    __('Naozaj chcete zmazať túto faktúru?'),
                    !_.isEmpty(lightbox.delete) ? <div>
                        <Checkbox
                            label={__('Zmazať faktúru aj z ekonomického systému')}
                            value={user.settings.invoice_delete_in_acccounting}
                            onChange={checked => changeUserSetting('invoice_delete_in_acccounting', checked)}
                        />
                    </div> : null,
                    __('Áno'),
                    __('Nie'),
                    () => this.delete()
                )}
                {this.renderLightbox(
                    'multidelete',
                    __('Naozaj chcete zmazať označené faktúry?'),
                    !_.isEmpty(lightbox.multidelete) ? <div>
                        {hasDelete ? <Checkbox
                            label={__('Zmazať faktúry aj z ekonomického systému')}
                            value={user.settings.invoice_delete_in_acccounting}
                            onChange={checked => changeUserSetting('invoice_delete_in_acccounting', checked)}
                        /> : null}
                    </div> : null,
                    __('Áno'),
                    __('Nie'),
                    () => this.multiDelete()
                )}
                {this.renderLightbox(
                    'multipdf',
                    __('Stiahnutie faktúr do pdf'),
                    !_.isEmpty(lightbox.multipdf) ? <div>
                        {hasDelete ? <Checkbox
                            label={__('Zlúčiť do jedného pdf súboru')}
                            value={user.settings.merge_multi_pdf}
                            onChange={checked => changeUserSetting('merge_multi_pdf', checked)}
                        /> : null}
                    </div> : null,
                    __('Áno'),
                    __('Nie'),
                    () => this.multiPdf()
                )}
            </div>
        );
    }
}

const stateToProps = ({ invoices, user }) => ({ items: invoices, user });

export default withCookies(connect(stateToProps, {
    fetch: fetchInvoices,
    clean: cleanInvoices,
    setUser,
    changeUserSetting,
    deleteInvoice,
    deleteInvoices,
})(InvoicesScreen));
