import React from 'react';
import _ from 'lodash';
import ViewIcon from '@mui/icons-material/Folder';
import PdfIcon from '@mui/icons-material/PictureAsPdf';
import InvoicesIcon from '@mui/icons-material/Description';
import PaidIcon from '@mui/icons-material/MonetizationOn';
import CouriersIcon from '@mui/icons-material/LocalShipping';
import DeleteIcon from '@mui/icons-material/Delete';
import OrderPdfIcon from '@mui/icons-material/Archive';
import SyncIcon from '@mui/icons-material/Sync';
import { __, toNumber } from '../functions';
import { BANKS, GATEWAYS, IMAGES } from '../config';

/**
 * Vratime buttony pre objednavku.
 *
 * @param {Object} item
 * @param {Object} options
 *
 * @return {Object}
 */
export const getOrderButtons = (item, options = {}) => {
    options = { ...{
        settings: {},
        downloadedPdf: [],
        canceledPackages: [],
        hasInvoiceCreate: false,
        hasInvoicePdf: false,
        hasInvoicing: false,
        hasPermissionView: false,
        hasPermissionPdfOrder: false,
        hasPermissionSync: false,
        hasPermissionPdf: false,
        hasPermissionCreateInvoice: false,
        hasPermissionPaid: false,
        hasPermissionSend: false,
        hasPermissionDelete: false,
        userCouriers: {},
        couriers: {},
        courierSettings: {},
        courierSettingsIds: [],
    }, ...options };

    let buttons = {
        view: {
            name: __('Zobraziť detail'),
            icon: <ViewIcon />,
            callback: () => {},
            options: {},
        },
        pdfOrder: {
            name: __('PDF objednávky'),
            icon: <OrderPdfIcon color={toNumber(item.exported) === 1 ? 'secondary' : 'inherit'} />,
            callback: () => {},
            options: {},
        },
        sync: {
            name: __('Synchronizovať s eshopom'),
            icon: <SyncIcon />,
            callback: () => {},
            options: {},
        },
    };

    if (!options.hasPermissionView) {
        // Nema pravo
        buttons = _.omit(buttons, ['view']);
    }

    if (!options.hasPermissionPdfOrder) {
        // Nema pravo
        buttons = _.omit(buttons, ['pdfOrder']);
    }

    if (!options.hasPermissionSync) {
        // Nema pravo
        buttons = _.omit(buttons, ['sync']);
    }

    if (
        !_.isEmpty(options.settings.meta_data_pdf_link)
        && _.has(item.data.meta_data, options.settings.meta_data_pdf_link)
        && !_.isEmpty(item.data.meta_data[options.settings.meta_data_pdf_link])
        && options.hasPermissionPdf
    ) {
        // Mame meta data field na pdf link
        buttons = { ...buttons, pdfLink: {
                name: __('Stiahnuť PDF'),
                icon: <PdfIcon />,
                callback: () => {},
                options: { pdf_link: item.data.meta_data[options.settings.meta_data_pdf_link] },
            } };
    } else if (!_.isEmpty(item.invoice) && options.hasPermissionPdf) {
        // Objednavka ma fakturu
        // Vytiahneme id faktury
        let isMserverWaiting = (_.has(item, 'waiting_mserver') && item.waiting_mserver);

        if (
            _.has(item.invoice, 'additional')
            && _.has(item.invoice.additional, 'waiting_mserver')
            && item.invoice.additional.waiting_mserver
        ) {
            isMserverWaiting = true;
        }

        const invoiceId = _.has(item.invoice, 'reference_id') && toNumber(item.invoice.reference_id) > 0
            ? item.invoice.reference_id
            : item.invoice_id;

        buttons = { ...buttons, pdf: {
            name: isMserverWaiting ? `${__('Faktúra č.')} ${item.invoice.number} - ${__('čaká na spracovanie v Pohode')}` : `${__('Faktúra č.')} ${item.invoice.number} - ${__('stiahnuť PDF')}`,
            icon: <PdfIcon color={isMserverWaiting ? 'orange' : (options.hasInvoicePdf && item.invoice_status_pdf === 'newly' && !_.includes(options.downloadedPdf, item.id)
                ? 'secondary'
                : 'inherit')} />,
            callback: () => {},
            options: { invoiceId, disabled: !options.hasInvoicePdf, isMserverWaiting },
        } };
    } else if (options.hasInvoiceCreate && options.hasPermissionCreateInvoice) {
        // Mozeme vytvarat fakturu
        // Je faktura waiting?
        const isInvoiceWaiting = (_.has(item, 'waiting_invoice') && item.waiting_invoice)
            || (_.has(item.data, 'waiting_invoice') && item.data.waiting_invoice);

        const isInvoiceError = (_.has(item, 'error_invoice') && item.error_invoice)
            || (_.has(item.data, 'error_invoice') && item.data.error_invoice);

        const invoiceCreateText = isInvoiceError
            ? isInvoiceError
            : (isInvoiceWaiting
                    ? (options.hasInvoicing
                        ? __('K objednávke bude v najbližšom čase automaticky vystavená faktúra. Kliknutím vystavíte manuálne.')
                        : __('Objednávka bude v najbližšom čase automaticky odoslaná do ekonomického systému. Kliknutím odošlete manuálne.'))
                    : (options.hasInvoicing ? __('Vystaviť faktúru') : __('Odoslať do ekonomického systému'))
            );

        buttons = { ...buttons, createInvoice: {
            name: invoiceCreateText,
            icon: <InvoicesIcon color={isInvoiceError ? 'error' : (isInvoiceWaiting ? 'action' : 'inherit')} />,
            callback: () => {},
            options: {},
        } };
    }

    // Je uhradene?
    const paid = item.invoice_status === 'paid' || item.data.paid;
    const allBanks = { ...BANKS, ...GATEWAYS };

    // Caka zasielka na uhradu?
    let packagePayment = item.package.number === 'waiting_payment';

    // Caka zasielka na import
    let packageImport = item.package.number === 'waiting_import';

    // Bola zasielka odoslana?
    let packageSend = item.package.number !== '' && !packagePayment && !packageImport;

    // Ma zasielka error?
    let packageError = item.package.error !== '';

    // Je zasielka waiting?
    let packageWaiting = (_.has(item, 'waiting_package') && item.waiting_package)
        || (_.has(item.data, 'waiting_package') && item.data.waiting_package);

    if (_.includes(options.canceledPackages, item.id)) {
        packagePayment = false;
        packageImport = false;
        packageSend = false;
        packageError = false;
        packageWaiting = false;
    }

    // Kurier ktorym sa odosiela
    let sendCourier = item.package.courier !== '' ? item.package.courier : null;

    if (sendCourier === null) {
        // Vytiahneme konkretneho kuriera
        _.forEach(options.userCouriers, courier => {
            if (courier.settings.delivery_id.toString() === item.data.delivery_type.id.toString()) {
                sendCourier = options.couriers[courier.courier_id];
            } else if (courier.settings.delivery_id.toString().indexOf('===') !== -1) {
                const delivery_ids = courier.settings.delivery_id.toString().split('===');

                _.forEach(delivery_ids, delivery_id => {
                    if (delivery_id.toString() === item.data.delivery_type.id.toString()) {
                        sendCourier = options.couriers[courier.courier_id];
                    }
                })
            }
        });

        if (
            sendCourier === null
            && toNumber(options.courierSettings.default_courier) > 0
            && _.includes(options.courierSettingsIds, options.courierSettings.default_courier.toString())
            && item.data.delivery_type.allow_default_courier
        ) {
            // Pozrieme na default kuriera
            _.forEach(options.user_couriers, courier => {
                if (courier.id.toString() === options.courierSettings.default_courier.toString()) {
                    sendCourier = options.couriers[courier.courier_id];
                }
            });
        }
    }

    buttons = { ...buttons,
        paid: {
            name: paid
                ? (_.has(item, 'payment') && _.has(item.payment, 'by') ? `${__('Uhradené')} - ${_.has(allBanks, item.payment.by) ? allBanks[item.payment.by] : __('manuálne')}` : __('Uhradené'))
                : __('Uhradiť'),
            icon: <PaidIcon color={paid ? 'secondary' : 'inherit'} />,
            callback: () => {},
            options: { paid },
        },
        send: {
            name: packageImport ? __('Zásielka čaká na spracovanie u prepravcu.') : (packagePayment ? __('Zásielka čaká na úhradu u prepravcu. Odkaz na platbu nájdete v zozname štítkov.') : (
                packageSend
                    ? (item.package.number === 'without_number'
                    ? __('Odoslané')
                    : __('Číslo zasielky: {NUMBER}. Kliknutím skopírujete do schránky.').replace('{NUMBER}', item.package.number))
                    : (
                        packageError
                            ? __('Chyba: {ERROR}. Kliknutím odošlete znovu.').replace('{ERROR}', item.package.error)
                            : (
                                packageWaiting
                                    ? __('Objednávka bude v najbližšom čase automatický odoslaná prepravcovi. Kliknutím odošlete manuálne.')
                                    : __('Odoslať prepravcovi')
                            )
                    )
            )),
            icon: <div className="courier-button"><CouriersIcon color={packageSend
                ? 'secondary'
                : (packageError
                        ? 'error'
                        : (packageWaiting || packagePayment || packageImport ? 'action' : 'inherit')
                )} />{sendCourier !== null ? <img
                src={IMAGES[`${sendCourier.toLowerCase()}-mini.png`]}
                className="courier-button__icon"
                alt={sendCourier}
            /> : null}</div>,
            callback: () => {},
            options: { packagePayment, packageImport, packageSend, packageError },
        },
        delete: {
            name: __('Zmazať'),
            icon: <DeleteIcon />,
            callback: () => {},
            options: {},
        },
    };

    if (!options.hasPermissionPaid) {
        // Nema pravo
        buttons = _.omit(buttons, ['paid']);
    }

    if (!options.hasPermissionSend) {
        // Nema pravo
        buttons = _.omit(buttons, ['send']);
    }

    if (!options.hasPermissionDelete) {
        // Nema pravo
        buttons = _.omit(buttons, ['delete']);
    }

    return buttons;
};
