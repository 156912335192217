import {FETCH_MTOKNA_BANNERS, CLEAN_MTOKNA_BANNERS, DELETE_MTOKNA_BANNER, CHANGE_MTOKNA_BANNER} from '../actions/types';
import _ from "lodash";
import {toNumber} from "../functions";

const MTOKNA_BANNERS_DEFAULT_STATE = {};

/**
 * Reducer pre sablony.
 */
const MtoknaBannersReducer = (state = MTOKNA_BANNERS_DEFAULT_STATE, { type, payload }) => {
    switch (type) {
        case FETCH_MTOKNA_BANNERS:
            return payload;

        case CHANGE_MTOKNA_BANNER:
            return { ...state, items: _.map(state.items, item => {
                if (toNumber(item.id) === toNumber(payload.id)) {
                    item = { ...item, type: payload.type };
                }

                return item;
            }) };

        case CLEAN_MTOKNA_BANNERS:
        case DELETE_MTOKNA_BANNER:
            return MTOKNA_BANNERS_DEFAULT_STATE;

        default:
            return state;
    }
};

export default MtoknaBannersReducer;
