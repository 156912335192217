import React from 'react';
import { connect } from 'react-redux';
import { withCookies } from 'react-cookie';
import _ from 'lodash';
import {ListScreen, Input, Select} from '../components';
import {
    fetchMtoknaSellers,
    cleanMtoknaSellers,
    setUser,
    changeUserSetting,
    changeMtoknaSellerEmail,
    changeMtoknaSellerPassword,
    changeMtoknaSellerSmtp,
} from '../actions';
import {__, formatDate, isEmptyString, isValidEmail, request} from '../functions';
import '../assets/styles/mtokna_sellers.css';
import EditIcon from "@mui/icons-material/Edit";
import PasswordIcon from "@mui/icons-material/Password";
import GroupRemoveIcon from "@mui/icons-material/GroupRemove";
import EmailIcon from '@mui/icons-material/Email';
import {PASSWORD_MIN_LENGTH} from "../config";

/**
 * Klienti.
 */
class MtoknaSellersScreen extends ListScreen {
    /**
     * Title.
     *
     * @type {string}
     */
    title = __('Predajcovia MO');

    /**
     * Default state.
     *
     * @type {Object}
     */
    state = { ...ListScreen.state, ...{
        lightbox: {
            create: false,
            move: false,
            email: false,
            password: false,
            smtp: false,
        },
    }};

    /**
     * Memory cache.
     *
     * @type {Object}
     */
    memoryCache = {};

    /**
     * Vratime nazov tabulky.
     *
     * @return {string}
     */
    getTableName() {
        return 'mtokna_sellers';
    }

    /**
     * Vratime zoznam stlpcov.
     *
     * @return {Array}
     */
    getColumns() {
        return [
            <div>{__('ID')}</div>,
            <div>{__('E-mail')}</div>,
            <div>{__('Registrácia')}</div>,
            <div></div>,
        ];
    }

    /**
     * Vratime bunky.
     *
     * @param {Object} item
     *
     * @return {Array}
     */
    getCells(item) {
        const { user } = this.props;

        return [
            <div>
                {item.id}
            </div>,
            <div>
                {item._matchingData.Users.email}
            </div>,
            <div>
                {formatDate(item._matchingData.Users.created, 'dd.mm.yyyy hh:ii')}
            </div>,
            <div>
                {!user.mtokna_moderator ? this.renderTableButton(
                    item.id,
                    __('Presunúť do VO'),
                    <GroupRemoveIcon />,
                    (callbackLoading, callback) => this.confirmMove(item.user_id, callbackLoading, callback),
                    {}
                ) : null}
                {!user.mtokna_moderator ? this.renderTableButton(
                    item.id,
                    __('Zmena emailu'),
                    <EditIcon />,
                    (callbackLoading, callback) => this.confirmEmail(item.user_id, callbackLoading, callback, item._matchingData.Users.email),
                    {}
                ) : null}
                {!user.mtokna_moderator ? this.renderTableButton(
                    item.id,
                    __('Zmena hesla'),
                    <PasswordIcon />,
                    (callbackLoading, callback) => this.confirmPassword(item.user_id, callbackLoading, callback),
                    {}
                ) : null}
            </div>,
        ];
    }

    /**
     * Vratime filter.
     *
     * @return {Object}
     */
    getFilter() {
        const { items } = this.props;

        return _.reduce(items.filter, (result, options, key) => {
            switch (key) {
                case 'Users-email':
                    return { ...result, ...{ [key]: {
                        type: 'input',
                        name: __('Email'),
                        value: '',
                    }}};

                default:
                    return result;
            }
        }, {});
    }

    getTags() {
        const { user } = this.props;

        if (user.mtokna_moderator) {
            return [];
        }

        const random = Math.random().toString(36).slice(-8);

        return [
            {
                name: __('Nový MO'),
                onClick: () => this.showLightbox('create', {
                    loading: false,
                    email: '',
                    password: random,
                    password_repeat: random,
                }),
            }
        ];
    }

    /**
     * Vratime empty text.
     *
     * @return {string}
     */
    getEmptyText() {
        return __('Zatiaľ nemáte žiadnych predajcov');
    }

    /**
     * Event po zmene emailu.
     *
     * @param {string} value
     */
    onChangeEmail(value) {
        const { lightbox } = this.state;

        this.setState({ lightbox: { ...lightbox, email: { ...lightbox.email, email: value } } })
    }

    /**
     * Event po zmene emailu.
     *
     * @param {string} value
     */
    onChangeSmtp(key, value) {
        const { lightbox } = this.state;

        this.setState({ lightbox: { ...lightbox, smtp: { ...lightbox.smtp, [key]: value } } })
    }

    /**
     * Event po zmene emailu.
     *
     * @param {string} value
     */
    onChangeSmtpSetting(key, value) {
        const { lightbox } = this.state;

        this.setState({ lightbox: { ...lightbox, smtp: { ...lightbox.smtp, settings: { ...lightbox.smtp.settings, [key]: value } } } })
    }

    /**
     * Event po zmene hesla.
     *
     * @param {string} value
     */
    onChangePassword(value) {
        const { lightbox } = this.state;

        this.setState({ lightbox: { ...lightbox, password: { ...lightbox.password, password: value } } })
    }

    /**
     * Event po zmene hesla.
     *
     * @param {string} value
     */
    onChangePasswordRepeat(value) {
        const { lightbox } = this.state;

        this.setState({ lightbox: { ...lightbox, password: { ...lightbox.password, password_repeat: value } } })
    }

    /**
     * Event po zmene create.
     *
     * @param {string} key
     * @param {string} value
     */
    onChangeCreate(key, value) {
        const { lightbox } = this.state;

        this.setState({ lightbox: { ...lightbox, create: { ...lightbox.create, [key]: value } } })
    }

    /**
     * Zobrazime lightbox na email.
     *
     * @param {number} id
     * @param {function} callbackLoading
     * @param {function} callback
     * @param {string} email
     */
    confirmEmail(id, callbackLoading, callback, email) {
        this.showLightbox('email', { id, callbackLoading, callback, email });
    }

    /**
     * Zobrazime lightbox na email.
     *
     * @param {number} id
     * @param {function} callbackLoading
     * @param {function} callback
     */
    confirmPassword(id, callbackLoading, callback) {
        const password = Math.random().toString(36).slice(-8);

        this.showLightbox('password', { id, callbackLoading, callback, password, password_repeat: password });
    }

    /**
     * Zobrazime lightbox na email.
     *
     * @param {number} id
     * @param {function} callbackLoading
     * @param {function} callback
     */
    confirmMove(id, callbackLoading, callback) {
        this.showLightbox('move', { id, callbackLoading, callback });
    }

    /**
     * Ulozenie emailu.
     */
    async changeEmail() {
        const { changeMtoknaSellerEmail } = this.props;
        const { lightbox } = this.state;

        // Zavolame loading
        lightbox.email.callbackLoading();

        // Zavrieme lightbox
        this.closeLightbox('email');

        // Zmenime email
        await changeMtoknaSellerEmail(this, lightbox.email.id, lightbox.email.email);

        // Zavolame callback
        lightbox.email.callback();
    }

    /**
     * Ulozenie hesla.
     */
    async changePassword() {
        const { changeMtoknaSellerPassword } = this.props;
        const { lightbox } = this.state;

        // Zavolame loading
        lightbox.password.callbackLoading();

        // Zavrieme lightbox
        this.closeLightbox('password');

        // Zmenime heslo
        await changeMtoknaSellerPassword(this, lightbox.password.id, lightbox.password.password, lightbox.password.password_repeat);

        // Zavolame callback
        lightbox.password.callback();
    }

    /**
     * Ulozenie hesla.
     */
    async move() {
        const { lightbox } = this.state;

        // Zavolame loading
        lightbox.move.callbackLoading();

        // Zavrieme lightbox
        this.closeLightbox('move');

        await request(`/mtokna/changeUserToOwner/${lightbox.move.id}`).then(response => {
            const { status } = response.data;

            if (status === 'error') {
                this.showSnackbar('error', __('Nepodarilo sa zmeniť predajcu'));
                return;
            }

            // Refreshneme
            window.location = window.location.pathname;
        });

        // Zavolame callback
        lightbox.move.callback();
    }

    /**
     * Ulozenie hesla.
     */
    saveSmtp() {
        const { changeMtoknaSellerSmtp } = this.props;
        const { lightbox } = this.state;

        let error = '';

        if (isEmptyString(lightbox.smtp.settings.host)) {
            // Nie je zadany host
            error = __('Nie je zadaný server host');
        } else if (isEmptyString(lightbox.smtp.settings.email)) {
            // Nie je zadany email
            error = __('Nie je zadaný email');
        } else if (isEmptyString(lightbox.smtp.settings.password)) {
            // Nie je zadane heslo
            error = __('Nie je zadané heslo');
        } else if (isEmptyString(lightbox.smtp.settings.name)) {
            // Nie je zadany odosielatel
            error = __('Nie je zadaný názov odosielateľa');
        } else if (!isEmptyString(lightbox.smtp.settings.reply) && !isValidEmail(lightbox.smtp.settings.reply)) {
            // Neplatne reply to
            error = __('Neplatný email pre odpoveď');
        }

        if (error !== '') {
            // Mame error
            this.showSnackbar('error', error);
            return;
        }

        this.onChangeSmtp('loading', true);

        changeMtoknaSellerSmtp(this, lightbox.smtp.id, lightbox.smtp.settings);
    }

    create() {
        const { lightbox } = this.state;

        if (!isValidEmail(lightbox.create.email)) {
            // Nie je vyplneny email
            this.showSnackbar('error', __('Neplatný email'));
            return;
        } else if (lightbox.create.password.length < PASSWORD_MIN_LENGTH) {
            // Nie je vyplnene heslo
            this.showSnackbar(
                'error',
                __('Minimálna dĺžka hesla je {COUNT} znakov').replace('{COUNT}', PASSWORD_MIN_LENGTH.toString())
            );
            return;
        } else if (lightbox.create.password !== lightbox.create.password_repeat) {
            // Heslo a heslo znovu sa nerovnaju
            this.showSnackbar('error', __('Heslá nie sú rovnaké'));
            return;
        }

        this.onChangeCreate('loading', true);

        const register_data = {
            email: lightbox.create.email,
            password: lightbox.create.password,
            password_repeat: lightbox.create.password_repeat,
        };

        request('/mtokna/register?seller=1', register_data, 'POST').then(response => {
            const { status } = response.data;

            if (status === 'error') {
                this.onChangeCreate('loading', false);
                this.showSnackbar('error', __('Email sa už používa'));
                return;
            }

            // Refreshneme
            window.location = window.location.pathname;
        });
    }

    /**
     * Rendrujeme screen.
     *
     * @return {JSX.Element|null}
     */
    renderScreen() {
        const { lightbox } = this.state;

        return (
            <div>
                {this.renderLightbox(
                    'move',
                    __('Naozaj chcete presunúť tohto predajcu do VO?'),
                    null,
                    __('Áno'),
                    __('Nie'),
                    () => this.move()
                )}
                {this.renderLightbox(
                    'email',
                    __('Zmena emailu'),
                    !_.isEmpty(lightbox.email) ? <div>
                        <Input
                            label={__('Email')}
                            value={lightbox.email.email}
                            onChange={value => this.onChangeEmail(value)}
                        />
                    </div> : null,
                    __('Uložiť'),
                    __('Zrušiť'),
                    () => this.changeEmail()
                )}
                {this.renderLightbox(
                    'password',
                    __('Zmena hesla'),
                    !_.isEmpty(lightbox.password) ? <div>
                        <div>
                            <Input
                                label={__('Heslo')}
                                value={lightbox.password.password}
                                onChange={value => this.onChangePassword(value)}
                            />
                        </div>
                        <div style={{ marginTop: '20px' }}>
                            <Input
                                label={__('Heslo znovu')}
                                value={lightbox.password.password_repeat}
                                onChange={value => this.onChangePasswordRepeat(value)}
                            />
                        </div>
                    </div> : null,
                    __('Uložiť'),
                    __('Zrušiť'),
                    () => this.changePassword()
                )}
                {this.renderLightbox(
                    'create',
                    __('Nový MO'),
                    !_.isEmpty(lightbox.create) ? (lightbox.create.loading ? this.renderLoading(20) : <div>
                        <div>
                            <Input
                                label={__('Email')}
                                value={lightbox.create.email}
                                onChange={value => this.onChangeCreate('email', value)}
                            />
                        </div>
                        <div style={{ marginTop: '20px' }}>
                            <Input
                                label={__('Heslo')}
                                value={lightbox.create.password}
                                onChange={value => this.onChangeCreate('password', value)}
                            />
                        </div>
                        <div style={{ marginTop: '20px' }}>
                            <Input
                                label={__('Heslo znovu')}
                                value={lightbox.create.password_repeat}
                                onChange={value => this.onChangeCreate('password_repeat', value)}
                            />
                        </div>
                    </div>) : null,
                    __('Uložiť'),
                    __('Zrušiť'),
                    () => this.create()
                )}
                {this.renderLightbox(
                    'smtp',
                    __('SMTP'),
                    !_.isEmpty(lightbox.smtp) ? (lightbox.smtp.loading ? this.renderLoading(20) : <div>
                        <Input
                            label={__('Server host')}
                            value={lightbox.smtp.settings.host}
                            onChange={value => this.onChangeSmtpSetting('host', value)}
                            placeholder="smtp.websupport.sk"
                        />
                        <div style={{ marginTop: '20px' }}>
                            <Input
                                label={__('Email')}
                                value={lightbox.smtp.settings.email}
                                onChange={value => this.onChangeSmtpSetting('email', value)}
                                placeholder="info@eshop.sk"
                            />
                        </div>
                        <div style={{ marginTop: '20px' }}>
                            <Input
                                label={__('Heslo')}
                                value={lightbox.smtp.settings.password}
                                onChange={value => this.onChangeSmtpSetting('password', value)}
                                type="password"
                            />
                        </div>
                        <div style={{ marginTop: '20px' }}>
                            <Select
                                label={__('Zabezpečenie pripojenia')}
                                value={lightbox.smtp.settings.encryption}
                                options={{ 'tls': 'TLS', 'ssl': 'SSL' }}
                                onChange={value => this.onChangeSmtpSetting('encryption', value)}
                            />
                        </div>
                        <div style={{ marginTop: '20px' }}>
                            <Input
                                label={__('Port')}
                                value={lightbox.smtp.settings.port}
                                onChange={value => this.onChangeSmtpSetting('port', value)}
                                placeholder={__('Doplnené podľa zabezpečenia pripojenia')}
                            />
                        </div>
                        <div style={{ marginTop: '20px' }}>
                            <Input
                                label={__('Názov odosielateľa')}
                                value={lightbox.smtp.settings.name}
                                onChange={value => this.onChangeSmtpSetting('name', value)}
                                maxLength={20}
                                placeholder="Eshop.sk"
                            />
                        </div>
                        <div style={{ marginTop: '20px' }}>
                            <Input
                                label={__('Email na ktorý príde odpoveď (reply to)')}
                                value={lightbox.smtp.settings.reply}
                                onChange={value => this.onChangeSmtpSetting('reply', value)}
                                placeholder="info@eshop.sk"
                            />
                        </div>
                    </div>) : null,
                    __('Uložiť'),
                    __('Zrušiť'),
                    () => this.saveSmtp()
                )}
            </div>
        );
    }
}

const stateToProps = ({ mtokna_sellers, user }) => ({ items: mtokna_sellers, user });

export default withCookies(connect(stateToProps, {
    fetch: fetchMtoknaSellers,
    clean: cleanMtoknaSellers,
    setUser,
    changeUserSetting,
    changeMtoknaSellerEmail,
    changeMtoknaSellerPassword,
    changeMtoknaSellerSmtp,
})(MtoknaSellersScreen));
