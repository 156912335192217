import { request } from '../functions';
import {
    FETCH_MTOKNA_ESHOP,
    CREATE_MTOKNA_ESHOP,
} from './types';

/**
 * Nacitanie eshopu.
 *
 * @param {Screen} screen
 */
export const fetchMtoknaEshop = screen => {
    return dispatch => request('/mtokna/eshops').then(({ data }) => {
        const { user } = data.sync;
        const { setUser } = screen.props;

        // Nasetujeme usera
        setUser(user);

        dispatch({ type: FETCH_MTOKNA_ESHOP, payload: data.data });
    });
};

/**
 * Vytvorenie prepojenia na eshop.
 *
 * @param {number} id
 */
export const createMtoknaEshop = id => ({ type: CREATE_MTOKNA_ESHOP, payload: id });
