import React from 'react';
import { connect } from 'react-redux';
import { withCookies } from 'react-cookie';
import _ from 'lodash';
import DeleteIcon from '@mui/icons-material/Delete';
import { ListScreen, Input } from '../components';
import {
    fetchClients,
    cleanClients,
    setUser,
    changeUserSetting,
    deleteClient,
    deleteClients,
    changeClientNote,
} from '../actions';
import {__, formatAmount, getClientButtons, request, toNumber} from '../functions';
import '../assets/styles/clients.css';

/**
 * Klienti.
 */
class ClientsScreen extends ListScreen {
    /**
     * Title.
     *
     * @type {string}
     */
    title = __('Zákaznici');

    /**
     * Default state.
     *
     * @type {Object}
     */
    state = { ...ListScreen.state, ...{
        loadingEbenicaExport: false,
        lightbox: {
            note: false,
            delete: false,
            multidelete: false,
        },
    }};

    /**
     * Memory cache.
     *
     * @type {Object}
     */
    memoryCache = {};

    /**
     * Je validne pravo?
     *
     * @return {boolean}
     */
    isValidPermission() {
        return this.hasPermission('clients');
    }

    /**
     * Vratime nazov tabulky.
     *
     * @return {string}
     */
    getTableName() {
        return 'clients';
    }

    /**
     * Vratime zoznam tagov.
     *
     * @return {Array}
     */
    getTags() {
        const { user } = this.props;
        const { loadingEbenicaExport } = this.state;

        const userEshopId = toNumber(user.user_eshop_id);

        return [
            _.includes([54,112,113,114,115,137], userEshopId) ? {
                name: !loadingEbenicaExport ? __('Export') : this.renderLoading(18, 'inherit'),
                onClick: () => this.ebenicaExport(),
            } : null,
        ];
    }

    /**
     * Synchronizacia.
     */
    ebenicaExport() {
        this.setState({ loadingEbenicaExport: true });

        request('/user-eshops/ebenicaExport/clients').then(response => {
            const { status, data } = response.data;

            this.setState({ loadingEbenicaExport: false });

            if (status === 'error') {
                this.showSnackbar('error', __('Nepodarilo sa stiahnuť export'));
                return;
            }

            this.showSnackbar('success', __('Export vám bude odoslaný na email do pár minút'));
        });
    }

    /**
     * Vratime bunky.
     *
     * @param {Object} item
     *
     * @return {Array}
     */
    getCells(item) {
        if (!_.has(this.memoryCache, 'hasPermissionView')) {
            // Nemame v memory cache
            this.memoryCache.hasPermissionView = this.hasPermission('clients-view');
        }

        if (!_.has(this.memoryCache, 'hasPermissionComment')) {
            // Nemame v memory cache
            this.memoryCache.hasPermissionComment = this.hasPermission('clients-comment');
        }

        if (!_.has(this.memoryCache, 'hasPermissionDelete')) {
            // Nemame v memory cache
            this.memoryCache.hasPermissionDelete = this.hasPermission('clients-delete');
        }

        // Vytiahneme tlacitka pre zakaznika
        let buttons = getClientButtons(item, {
            hasPermissionView: this.memoryCache.hasPermissionView,
            hasPermissionComment: this.memoryCache.hasPermissionComment,
            hasPermissionDelete: this.memoryCache.hasPermissionDelete,
        });

        // Nastavime callbacky
        if (_.has(buttons, 'note')) {
            buttons.note.callback = (callbackLoading, callback) => this.confirmNote(item.id, callbackLoading, callback, _.toString(item.note));
        }

        if (_.has(buttons, 'delete')) {
            buttons.delete.callback = (callbackLoading, callback) => this.confirmDelete(item.id, callbackLoading, callback);
        }

        // Vytiahneme nastavenia buniek
        const cellsSettings = this.getCellsSettings();

        // Vytiahneme options buniek
        const cellsOptions = this.getCellsOptions();

        return [
            <div>
                {this.getCellValue(cellsOptions[cellsSettings.settings[0][0]].get(item))}
                {this.getCellValue(cellsOptions[cellsSettings.settings[0][1]].get(item))}
                {this.getCellValue(cellsOptions[cellsSettings.settings[0][2]].get(item))}
            </div>,
            <div>
                {this.getCellValue(cellsOptions[cellsSettings.settings[1][0]].get(item))}
                {this.getCellValue(cellsOptions[cellsSettings.settings[1][1]].get(item))}
                {this.getCellValue(cellsOptions[cellsSettings.settings[1][2]].get(item))}
            </div>,
            <div>
                {this.getCellValue(cellsOptions[cellsSettings.settings[2][0]].get(item))}
                {this.getCellValue(cellsOptions[cellsSettings.settings[2][1]].get(item))}
                {this.getCellValue(cellsOptions[cellsSettings.settings[2][2]].get(item))}
            </div>,
            <div>{this.renderTableButtons(item.id, buttons)}</div>,
        ];
    }

    /**
     * Vratime options buniek.
     *
     * @return {Object}
     */
    getCellsOptions() {
        const { user } = this.props;

        return {
            name: {
                label: __('Názov'),
                get: item => _.truncate(item.data.name, { length: 48 }),
            },
            delivery_name: {
                label: __('Názov (dodanie)'),
                get: item => _.truncate(item.data.delivery_name, { length: 48 }),
            },
            contact_name: {
                label: __('Kontaktná osoba'),
                get: item => _.truncate(item.data.contact_name, { length: 48 }),
            },
            delivery_contact_name: {
                label: __('Kontaktná osoba (dodanie)'),
                get: item => _.truncate(item.data.delivery_contact_name, { length: 48 }),
            },
            company: {
                label: __('Firemné údaje'),
                get: item => !_.isEmpty(item.data.ico)
                    ? _.truncate(`${__('IČO')}: ${item.data.ico}, ${__('DIČ')}: ${item.data.dic}, ${__('IČ DPH')}: ${item.data.ic_dph}`, { length: 48 })
                    : '',
            },
            email: {
                label: __('Email'),
                get: item => item.data.email,
            },
            phone: {
                label: __('Tel. číslo'),
                get: item => item.data.phone,
            },
            address: {
                label: __('Fakturačná adresa'),
                get: item => _.truncate(`${item.data.address}, ${item.data.city}`, { length: 48 }),
            },
            delivery_address: {
                label: __('Dodacia adresa'),
                get: item => _.truncate(`${item.data.delivery_address}, ${item.data.delivery_city}`, { length: 48 }),
            },
            delivery_phone: {
                label: __('Tel. číslo (dodanie)'),
                get: item => item.data.delivery_phone,
            },
            delivery_note: {
                label: __('Poznámka'),
                get: item => _.truncate(item.data.delivery_note, { length: 48 }),
            },
            orders_sum: {
                label: __('Suma objednávok'),
                get: item => formatAmount(item.orders_sum, user.settings.currency),
            },
            orders_count: {
                label: __('Počet objednávok'),
                get: item => formatAmount(item.orders, 'ks', 0),
            },
            note: {
                label: __('Interná poznámka'),
                get: item => _.truncate(item.note, { length: 48 }),
            },
        };
    }

    /**
     * Vratime multiselect.
     *
     * @return {Array}
     */
    getMultiselect() {
        return [
            {
                confirm: (ids, callback, callbackLoading) => this.showLightbox('multidelete', {
                    ids,
                    callback,
                    callbackLoading,
                }),
                icon: <DeleteIcon />,
                text: __('Zmazať'),
                permission: 'clients-delete',
            }
        ];
    }

    /**
     * Vratime filter.
     *
     * @return {Object}
     */
    getFilter() {
        const { items } = this.props;

        return _.reduce(items.filter, (result, options, key) => {
            switch (key) {
                case 'search':
                    return { ...result, ...{ [key]: {
                        type: 'input',
                        name: __('Hľadať'),
                        value: '',
                    }}};

                default:
                    return result;
            }
        }, {});
    }

    /**
     * Vratime empty text.
     *
     * @return {string}
     */
    getEmptyText() {
        return __('Zatiaľ nemáte žiadnych zákazníkov');
    }

    /**
     * Event po zmene poznamky.
     *
     * @param {string} note
     */
    onChangeNote(note) {
        const { lightbox } = this.state;

        this.setState({ lightbox: { ...lightbox, note: { ...lightbox.note, note } } })
    }

    /**
     * Zobrazime lightbox na poznamku.
     *
     * @param {number} id
     * @param {function} callbackLoading
     * @param {function} callback
     * @param {string} note
     */
    confirmNote(id, callbackLoading, callback, note) {
        this.showLightbox('note', { id, callbackLoading, callback, note });
    }

    /**
     * Zobrazime lightbox na zmazanie.
     *
     * @param {number} id
     * @param {function} callbackLoading
     * @param {function} callback
     */
    confirmDelete(id, callbackLoading, callback) {
        this.showLightbox('delete', { id, callbackLoading, callback });
    }

    /**
     * Ulozenie poznamky.
     */
    async note() {
        const { changeClientNote } = this.props;
        const { lightbox } = this.state;

        // Zavolame loading
        lightbox.note.callbackLoading();

        // Zavrieme lightbox
        this.closeLightbox('note');

        // Zmenime poznamku
        await changeClientNote(this, lightbox.note.id, lightbox.note.note);

        // Zavolame callback
        lightbox.note.callback();
    }

    /**
     * Zmazanie klienta.
     */
    async delete() {
        const { deleteClient, items } = this.props;
        const { lightbox } = this.state;

        // Zavolame loading
        lightbox.delete.callbackLoading();

        // Zavrieme lightbox
        this.closeLightbox('delete');

        // Zmazeme klienta
        await deleteClient(this, lightbox.delete.id);

        // Zavolame callback
        lightbox.delete.callback();

        // Znovu nacitame zoznam od prvej stranky
        this.fetchList(1, 0, items.filtered);
    }

    /**
     * Zmazanie klientov cez multiselect.
     */
    async multiDelete() {
        const { deleteClients, items } = this.props;
        const { lightbox } = this.state;

        // Zavrieme lightbox
        this.closeLightbox('multidelete');

        // Zobrazime loading
        lightbox.multidelete.callbackLoading();

        // Zmazeme klientov
        await deleteClients(this, lightbox.multidelete.ids, items.filtered);

        // Zavolame callback
        lightbox.multidelete.callback();

        // Znovu nacitame zoznam od prvej stranky
        this.fetchList(1, 0, items.filtered);
    }

    /**
     * Rendrujeme screen.
     *
     * @return {JSX.Element|null}
     */
    renderScreen() {
        const { lightbox } = this.state;

        return (
            <div>
                {this.renderLightbox(
                    'note',
                    __('Interná poznámka'),
                    !_.isEmpty(lightbox.note) ? <div>
                        <Input
                            label={__('Interná poznámka')}
                            value={lightbox.note.note}
                            onChange={value => this.onChangeNote(value)}
                        />
                    </div> : null,
                    __('Uložiť'),
                    __('Zrušiť'),
                    () => this.note()
                )}
                {this.renderLightbox(
                    'delete',
                    __('Naozaj chcete zmazať tohto zákazníka?'),
                    null,
                    __('Áno'),
                    __('Nie'),
                    () => this.delete()
                )}
                {this.renderLightbox(
                    'multidelete',
                    __('Naozaj chcete zmazať označených zákazníkov?'),
                    null,
                    __('Áno'),
                    __('Nie'),
                    () => this.multiDelete()
                )}
            </div>
        );
    }
}

const stateToProps = ({ clients, user }) => ({ items: clients, user });

export default withCookies(connect(stateToProps, {
    fetch: fetchClients,
    clean: cleanClients,
    setUser,
    changeUserSetting,
    deleteClient,
    deleteClients,
    changeClientNote,
})(ClientsScreen));
