import _ from 'lodash';
import { FETCH_EXPORTS, CLEAN_EXPORTS, DELETE_EXPORT } from './types';
import { request, __ } from '../functions';

/**
 * Nacitanie exportov.
 *
 * @param {Screen} screen
 * @param {object} options
 */
export const fetchExports = (screen, options = {}) => {
    const defaults = {
        page: 1,
        per_page: 0,
        filtered: {},
    };

    options = { ...defaults, ...options };

    let params = { page: options.page, per_page: options.per_page };

    // Nasetujeme filter
    _.forEach(options.filtered, (value, key) => {
        params = { ...params, ...{ [`filter_${key}`]: value } };
    });

    return dispatch => request('/exports/index', params).then(response => {
        const { data, sync } = response.data;
        const { setUser } = screen.props;

        // Nasetujeme user data
        setUser(sync.user);

        dispatch({ type: FETCH_EXPORTS, payload: data });
    });
};

/**
 * Zmazanie exportu.
 *
 * @param {Screen} screen
 * @param {number} id
 */
export const deleteExport = (screen, id) => {
    return dispatch => request(`/exports/delete/${id}`).then(response => {
        const { status } = response.data;

        if (status === 'error') {
            // Export sa nepodarilo zmazat
            screen.showSnackbar('error', __('Nepodarilo sa zmazať export'));
            return;
        }

        screen.showSnackbar('success', __('Export bol zmazaný'));

        dispatch({ type: DELETE_EXPORT });
    });
};

/**
 * Vycistenie exportov.
 */
export const cleanExports = () => ({ type: CLEAN_EXPORTS });
