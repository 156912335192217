import React from 'react';
import { connect } from 'react-redux';
import { withCookies } from 'react-cookie';
import _ from 'lodash';
import {Screen, Logo, Input, Button, Checkbox} from '../components';
import { __, request } from '../functions';
import { COOKIE_SETTINGS, COOKIE_AFFILIATE_NAME } from '../config';
import '../assets/styles/affiliate-register.css';

/**
 * Affiliate.
 */
class AffiliateRegisterScreen extends Screen {
    /**
     * Title.
     *
     * @type {string}
     */
    title = __('Affiliate');

    /**
     * Default state.
     *
     * @type {Object}
     */
    state = {
        name: '',
        password: '',
        password_repeat: '',
        vop: false,
    };

    /**
     * Auth required.
     *
     * @type {boolean}
     */
    authRequired = false;

    /**
     * Komponenta bola pripojena.
     *
     * @return {boolean}
     */
    componentDidMount() {
        const { name } = this.props;

        if (name === 'upgates') {
            window.location = '/?source=upgates';
            return;
        }

        if (!_.isEmpty(name)) {
            this.setState({ name });
        }

        return super.componentDidMount();
    }

    /**
     * Prihlasenie.
     *
     * @return boolean
     */
    login() {
        const { name, password, password_repeat, vop } = this.state;

        if (_.isEmpty(name)) {
            // Nie je vyplneny email
            this.showSnackbar('error', __('Nevyplnili ste meno'));
            return false;
        } else if (_.isEmpty(password)) {
            // Nie je vyplnene heslo
            this.showSnackbar('error', __('Nevyplnili ste heslo'));
            return false;
        } else if (password !== password_repeat) {
            // Heslo a heslo znovu sa nerovnaju
            this.showSnackbar('error', __('Heslá nie sú rovnaké'));
            return false;
        } else if (!vop) {
            // Nesuhlas s VOP
            this.showSnackbar('error', __('Musíte súhlasiť s podmienkami Partnerského programu'));
            return false;
        }

        this.setState({ loading: true });

        request('/affiliates/register', { name, password, password_repeat }, 'POST').then(response => {
            const { status, data } = response.data;

            this.setState({ loading: false });

            if (status === 'error') {
                // Prihlasenie sa nepodarilo
                this.showSnackbar('error', __('Nesprávne prihlasovacie údaje'));
                return;
            }

            // Zavolame callback
            this.afterLogin(data.token);
        });
    }

    /**
     * Callback po prihlaseni.
     *
     * @param {string} token
     */
    afterLogin(token) {
        const { cookies } = this.props;

        // Ulozime token do cookie
        cookies.set(COOKIE_AFFILIATE_NAME, token, COOKIE_SETTINGS);

        window.location = '/affiliate-portal';
    }

    /**
     * Event po zmene hodnoty.
     *
     * @param {string} name
     * @param {string} value
     */
    onChangeValue(name, value) {
        this.setState({ [name]: value });
    }

    /**
     * Rendrovanie.
     *
     * @returns {JSX.Element}
     */
    render() {
        const { name, password, password_repeat, loading, vop } = this.state;

        if (name === '') {
            return null;
        }

        return (
            <div className="affiliate-register">
                <div className="affiliate-register__window">
                    <Logo onClick={() => { window.location = '/'; }} color="dark" />
                    <div className="affiliate-register__window__title">{__('Affiliate registrácia')}</div>
                    <div className="affiliate-register__window__text">{__('Pre pokračovanie sa prosím registrujte')}</div>
                    <Input
                        label={__('Meno')}
                        value={name}
                        onChange={value => this.onChangeValue('name', value)}
                        disabled
                    />
                    <Input
                        type="password"
                        label={__('Heslo')}
                        value={password}
                        onChange={value => this.onChangeValue('password', value)}
                    />
                    <Input
                        type="password"
                        label={__('Heslo znovu')}
                        value={password_repeat}
                        onChange={value => this.onChangeValue('password_repeat', value)}
                    />
                    <Checkbox
                        label={<span>
                        <span>{__('Súhlasím s')} </span>
                        <a href="/affiliate-vop.pdf?v=1" target="_blank">{__('podmienkami Partnerského programu')}</a>
                    </span>}
                        value={vop}
                        onChange={checked => this.onChangeValue('vop', checked)}
                    />
                    <Button
                        onClick={() => this.login()}
                        className="affiliate-register__window__button"
                        loading={loading}
                    >{__('Registrovať')}</Button>
                </div>
                {this.renderSnackbar()}
            </div>
        );
    }
}

const stateToProps = ({ user }) => ({ user });

export default withCookies(connect(stateToProps)(AffiliateRegisterScreen));
