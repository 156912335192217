import { CLEAN_EXPORT, FETCH_EXPORT } from './types';
import { __, request, toNumber } from '../functions';

/**
 * Edit exportu.
 *
 * @param {Screen} screen
 * @param {number} id
 */
export const fetchExport = (screen, id) => {
    return dispatch => request(`/exports/view/${id}`).then(response => {
        const { data, sync } = response.data;
        const { setUser } = screen.props;

        // Nasetujeme user data
        setUser(sync.user);

        dispatch({ type: FETCH_EXPORT, payload: data });
    });
};

/**
 * Vytvorime export.
 *
 * @param {Screen} screen
 * @param {Object} data
 */
export const createExport = (screen, data) => {
    const edit = toNumber(data.id) > 0;

    return dispatch => request(`/exports/${edit ? 'edit' : 'create'}`, data, 'POST').then(response => {
        const { status } = response.data;

        screen.setState({ loading: false });

        if (status === 'error') {
            // Nepodarilo sa ulozit export
            screen.showSnackbar('error', __('Nepodarilo sa uložiť export'));
            return;
        }

        window.location = '/exports';
    });
};

/**
 * Vycistenie exportu.
 */
export const cleanExport = () => ({ type: CLEAN_EXPORT });
