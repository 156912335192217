import _ from 'lodash';
import {
    FETCH_MTOKNA_MANUAL_CUSTOMER_ORDERS,
    CLEAN_MTOKNA_MANUAL_CUSTOMER_ORDERS,
    EDIT_MTOKNA_MANUAL_CUSTOMER_ORDER,
    DELETE_MTOKNA_MANUAL_CUSTOMER_ORDER,
} from './types';
import {request, __, toNumber} from '../functions';

export const fetchMtoknaManualCustomerOrders = (screen, options = {}) => {
    const defaults = {
        page: 1,
        per_page: 0,
        filtered: {},
        callback: null,
    };

    options = { ...defaults, ...options };

    let params = { page: options.page, per_page: options.per_page };

    // Nasetujeme filter
    _.forEach(options.filtered, (value, key) => {
        params = { ...params, ...{ [`filter_${key}`]: value } };
    });

    return dispatch => request('/mtokna/customerOrders', params).then(response => {
        const { data, sync } = response.data;
        const { setUser } = screen.props;

        if (options.callback !== null) {
            // Zavolame callback
            options.callback();
        }

        // Nasetujeme user data
        setUser(sync.user);

        dispatch({ type: FETCH_MTOKNA_MANUAL_CUSTOMER_ORDERS, payload: data });
    });
};

export const cleanMtoknaManualCustomerOrders = () => ({ type: CLEAN_MTOKNA_MANUAL_CUSTOMER_ORDERS });

/**
 * Zmazanie objednavky.
 *
 * @param {Screen} screen
 * @param {number} id
 */
export const deleteMtoknaManualCustomerOrder = (screen, id) => {
    return dispatch => request(`/mtokna/deleteManualCustomerOrder/${id}`, { order_delete_in_eshop: false }).then(response => {
        const { status } = response.data;

        if (status === 'error') {
            // Objednavku sa nepodarilo zmazat
            screen.showSnackbar('error', __('Objednávku sa nepodarilo zmazať pretože má evidovanú faktúru'));
            return;
        }

        screen.showSnackbar('success', __('Objednávka bola zmazaná'));

        dispatch({ type: DELETE_MTOKNA_MANUAL_CUSTOMER_ORDER });
    });
};

export const changeMtoknaManualCustomerOrderData = (id, data) => ({ type: EDIT_MTOKNA_MANUAL_CUSTOMER_ORDER, payload: { id, data } });
