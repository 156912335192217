import _ from 'lodash';
import {
    FETCH_MTOKNA_POINTS,
    CLEAN_MTOKNA_POINTS,
    CHANGE_MTOKNA_POINT,
    DELETE_MTOKNA_POINT,
} from './types';
import { request, __ } from '../functions';

export const fetchMtoknaPoints = (screen, options = {}) => {
    const defaults = {
        page: 1,
        per_page: 0,
        filtered: {},
    };

    options = { ...defaults, ...options };

    let params = { page: options.page, per_page: options.per_page };

    // Nasetujeme filter
    _.forEach(options.filtered, (value, key) => {
        params = { ...params, ...{ [`filter_${key}`]: value } };
    });

    return dispatch => request('/mtokna/points', params).then(response => {
        const { data, sync } = response.data;
        const { setUser } = screen.props;

        // Nasetujeme user data
        setUser(sync.user);

        dispatch({ type: FETCH_MTOKNA_POINTS, payload: data });
    });
};

export const changeMtoknaPoint = (screen, id, data) => {
    return dispatch => request(`/mtokna/editPoint/${id}`, { data }, 'POST').then(response => {
        const { status } = response.data;

        if (status === 'error') {
            screen.showSnackbar('error', __('Nepodarilo sa zmeniť odberné miesto'));
            return;
        }

        screen.showSnackbar('success', __('Odberné miesto bolo zmenené'));

        dispatch({ type: CHANGE_MTOKNA_POINT, payload: { id, data } });
    });
};

export const deleteMtoknaPoint = (screen, id) => {
    return dispatch => request(`/mtokna/removePoint/${id}`).then(response => {
        const { status } = response.data;

        if (status === 'error') {
            screen.showSnackbar('error', __('Nepodarilo sa zmazať odberné miesto'));
            return;
        }

        screen.showSnackbar('success', __('Odberné miesto bolo zmazané'));

        dispatch({ type: DELETE_MTOKNA_POINT });
    });
};

export const cleanMtoknaPoints = () => ({ type: CLEAN_MTOKNA_POINTS });
