import { FETCH_MTOKNA_WINDOW_SETTINGS, CLEAN_MTOKNA_WINDOW_SETTINGS } from '../actions/types';

const MTOKNA_WINDOW_SETTINGS_DEFAULT_STATE = {};

/**
 * Reducer pre sablonu.
 */
const MtoknaWindowSettingsReducer = (state = MTOKNA_WINDOW_SETTINGS_DEFAULT_STATE, { type, payload }) => {
    switch (type) {
        case FETCH_MTOKNA_WINDOW_SETTINGS:
            return payload;

        case CLEAN_MTOKNA_WINDOW_SETTINGS:
            return MTOKNA_WINDOW_SETTINGS_DEFAULT_STATE;

        default:
            return state;
    }
};

export default MtoknaWindowSettingsReducer;
