import { SET_USER, CLEAN_USER, CHANGE_USER_SETTING, FETCH_USER_NOTIFICATIONS } from '../actions/types';

const USER_DEFAULT_STATE = [];

/**
 * Reducer pre usera.
 */
const UserReducer = (state = USER_DEFAULT_STATE, { type, payload }) => {
    switch (type) {
        case SET_USER:
            // Nasetovanie usera
            if (typeof window.LiveAgent === 'function') {
                // Nasetujeme detaily pre ticket
                window.LiveAgent.addTicketField('email', payload.email);
                window.LiveAgent.addTicketField('user_eshop_id', payload.user_eshop_id);
            }

            return payload;

        case CHANGE_USER_SETTING:
            // Zmena user settingu
            return { ...state, settings: { ...state.settings, [payload.name]: payload.value } };

        case FETCH_USER_NOTIFICATIONS:
            // Nacitanie notifikacii usera
            return { ...state, notifications: payload };

        case CLEAN_USER:
            // Vycistenie usera
            return USER_DEFAULT_STATE;

        default:
            return state;
    }
};

export default UserReducer;
