import React from 'react';
import { connect } from 'react-redux';
import { withCookies } from 'react-cookie';
import _ from 'lodash';
import AddonsIcon from '@mui/icons-material/Extension';
import DeleteIcon from '@mui/icons-material/Delete';
import { ListScreen, Checkbox, Select } from '../components';
import {
    fetchInvoicePayments,
    cleanInvoicePayments,
    setUser,
    changeUserSetting,
    addonInvoicePayments,
    deleteInvoicePayment,
    deleteInvoicePayments,
} from '../actions';
import { __, request, plural, formatDate, formatAmount, getInvoicePaymentButtons, toNumber } from '../functions';
import { BANKS, GATEWAYS } from '../config';
import '../assets/styles/invoice_payments.css';

/**
 * Uhrady.
 */
class InvoicePaymentsScreen extends ListScreen {
    /**
     * Title.
     *
     * @type {string}
     */
    title = __('Platby');

    /**
     * Default state.
     *
     * @type {Object}
     */
    state = { ...ListScreen.state, ...{
        downloadedPdf: [],
        loadingSync: false,
        lightbox: {
            delete: false,
            multidelete: false,
            multiaddon: false,
        },
    }};

    /**
     * Memory cache.
     *
     * @type {Object}
     */
    memoryCache = {};

    /**
     * Je validne pravo?
     *
     * @return {boolean}
     */
    isValidPermission() {
        return this.hasPermission('payments');
    }

    /**
     * Vratime zoznam tagov.
     *
     * @return {Array}
     */
    getTags() {
        const { user, items } = this.props;
        const { loadingSync } = this.state;

        const userEshopId = toNumber(user.user_eshop_id);

        return [
            items.synchronized === '1970-01-01 00:00:00'
                ? { name: __('Úvodná synchronizácia prebehne v nasledujúcich minútach') }
                : { name: formatDate(items.synchronized, __('Synchronizované s ekonomickým systémom o hh:ii')) },
            userEshopId > 0 ? {
                name: !loadingSync ? __('Synchronizovať') : this.renderLoading(18, 'inherit'),
                onClick: () => this.sync(),
                className: `sync ${loadingSync ? 'sync-loading' : ''}`,
            } : null,
        ];
    }

    /**
     * Vratime nazov tabulky.
     *
     * @return {string}
     */
    getTableName() {
        return 'invoice_payments';
    }

    /**
     * Vratime bunky.
     *
     * @param {Object} item
     *
     * @return {Array}
     */
    getCells(item) {
        const { downloadedPdf } = this.state;

        if (!_.has(this.memoryCache, 'hasInvoicePdf')) {
            // Nemame v memory cache
            this.memoryCache.hasInvoicePdf = this.hasAccountingAction('pdf');
        }

        if (!_.has(this.memoryCache, 'hasPermissionPdf')) {
            // Nemame v memory cache
            this.memoryCache.hasPermissionPdf = this.hasPermission('invoices-pdf');
        }

        if (!_.has(this.memoryCache, 'hasPermissionDelete')) {
            // Nemame v memory cache
            this.memoryCache.hasPermissionDelete = this.hasPermission('payments-delete');
        }

        // Vytiahneme tlacitka pre platbu
        let buttons = getInvoicePaymentButtons(item, {
            downloadedPdf,
            hasInvoicePdf: this.memoryCache.hasInvoicePdf,
            hasPermissionPdf: this.memoryCache.hasPermissionPdf,
            hasPermissionDelete: this.memoryCache.hasPermissionDelete,
        });

        // Nastavime callbacky
        if (_.has(buttons, 'pdf')) {
            buttons.delete.callback = (callbackLoading, callback) => this.confirmDelete(item.id, callbackLoading, callback);
        }

        if (_.has(buttons, 'pdf')) {
            buttons.pdf.callback = (callbackLoading, callback) => this.pdf(item._matchingData.Invoices.id, callbackLoading, callback);
        }

        // Vytiahneme nastavenia buniek
        const cellsSettings = this.getCellsSettings();

        // Vytiahneme options buniek
        const cellsOptions = this.getCellsOptions();

        return [
            <div>
                {this.getCellValue(cellsOptions[cellsSettings.settings[0][0]].get(item))}
                {this.getCellValue(cellsOptions[cellsSettings.settings[0][1]].get(item))}
                {this.getCellValue(cellsOptions[cellsSettings.settings[0][2]].get(item))}
            </div>,
            <div>
                {this.getCellValue(cellsOptions[cellsSettings.settings[1][0]].get(item))}
                {this.getCellValue(cellsOptions[cellsSettings.settings[1][1]].get(item))}
                {this.getCellValue(cellsOptions[cellsSettings.settings[1][2]].get(item))}
            </div>,
            <div>
                {this.getCellValue(cellsOptions[cellsSettings.settings[2][0]].get(item))}
                {this.getCellValue(cellsOptions[cellsSettings.settings[2][1]].get(item))}
                {this.getCellValue(cellsOptions[cellsSettings.settings[2][2]].get(item))}
            </div>,
            <div>{this.renderTableButtons(item.id, buttons)}</div>,
        ];
    }

    /**
     * Vratime options buniek.
     *
     * @return {Object}
     */
    getCellsOptions() {
        const { items, user } = this.props;

        const allBanks = { ...BANKS, ...GATEWAYS };

        return {
            invoice_name: {
                label: __('Faktúra'),
                get: item => _.truncate(item._matchingData.Invoices.data.name, { length: 48 }),
            },
            amount: {
                label: __('Suma'),
                get: item => formatAmount(item.data.amount, item.data.currency),
            },
            created: {
                label: __('Dátum'),
                get: item => formatDate(item.data.date),
            },
            order_number: {
                label: __('Číslo objednávky'),
                get: item => item._matchingData.Orders.data.number,
            },
            order_client_name: {
                label: __('Zákaznik'),
                get: item => _.truncate(item._matchingData.Orders.data.client.name, { length: 48 }),
            },
            order_amount: {
                label: __('Suma objednávky'),
                get: item => formatAmount(item._matchingData.Orders.data.amount, user.settings.currency),
            },
            invoice_variable: {
                label: __('Variabilný symbol'),
                get: item => item._matchingData.Invoices.data.variable,
            },
            type: {
                label: __('Typ'),
                get: item => _.has(items.accounting_data.payment_types, item.data.type)
                    ? items.accounting_data.payment_types[item.data.type]
                    : '',
            },
            by: {
                label: __('Vytvorené'),
                get: item => !_.isEmpty(item.data.by) && item.data.by !== 'manual' && _.has(allBanks, item.data.by)
                    ? allBanks[item.data.by]
                    : __('Manuálne'),
            },
        };
    }

    /**
     * Vratime multiselect.
     *
     * @return {Array}
     */
    getMultiselect() {
        const { items } = this.props;

        // Vytiahneme zoznam aktivnych addonov
        const addons = _.reduce(
            items.user_addons,
            (result, addon_id) => (_.has(items.addons, addon_id) ? { ...result, [addon_id]: items.addons[addon_id] } : result),
            {}
        );

        return [
            !_.isEmpty(addons) ? {
                confirm: (ids, callback, callbackLoading) => this.showLightbox('multiaddon', {
                    ids,
                    callback,
                    callbackLoading,
                    addonId: _.keys(addons)[0],
                }),
                icon: <AddonsIcon />,
                text: __('Doplnok'),
            } : null,
            {
                confirm: (ids, callback, callbackLoading) => this.showLightbox('multidelete', {
                    ids,
                    callback,
                    callbackLoading,
                }),
                icon: <DeleteIcon />,
                text: __('Zmazať'),
                permission: 'payments-delete',
            }
        ];
    }

    /**
     * Vratime filter.
     *
     * @return {Object}
     */
    getFilter() {
        const { items } = this.props;

        return _.reduce(items.filter, (result, options, key) => {
            switch (key) {
                case 'type':
                    return { ...result, ...{ [key]: {
                        type: 'select',
                        name: __('Typ'),
                        value: '',
                        options: items.accounting_data.payment_types,
                    }}};

                case 'date-from':
                    return { ...result, ...{ [key]: {
                        type: 'date',
                        name: __('Dátum od'),
                        value: null,
                    }}};

                case 'date-to':
                    return { ...result, ...{ [key]: {
                        type: 'date',
                        name: __('Dátum do'),
                        value: null,
                    }}};

                case 'search':
                    return { ...result, ...{ [key]: {
                        type: 'input',
                        name: __('Hľadať'),
                        value: '',
                    }}};

                default:
                    return result;
            }
        }, {});
    }

    /**
     * Vratime empty text.
     *
     * @return {string}
     */
    getEmptyText() {
        return __('Zatiaľ nemáte žiadne platby');
    }

    /**
     * Pridame stiahnute pdf.
     *
     * @param {Array} ids
     *
     * @return {Array}
     */
    getDownloadedPdf(ids) {
        let { downloadedPdf } = this.state;

        _.each(ids, id => {
            downloadedPdf = [ ...downloadedPdf, toNumber(id) ];
        });

        return downloadedPdf;
    }

    /**
     * Zobrazime lightbox na zmazanie.
     *
     * @param {number} id
     * @param {function} callbackLoading
     * @param {function} callback
     */
    confirmDelete(id, callbackLoading, callback) {
        this.showLightbox('delete', { id, callbackLoading, callback });
    }

    /**
     * Stiahnutie pdf.
     *
     * @param {number} id
     * @param {function} callbackLoading
     * @param {function} callback
     */
    async pdf(id, callbackLoading, callback) {
        // Zavolame loading
        callbackLoading();

        await request(`/invoices/pdf/${id}`).then(response => {
            const { status, data } = response.data;

            if (status === 'error') {
                this.showSnackbar('error', __('Nepodarilo sa stiahnuť pdf'));
                return;
            }

            this.setState({ downloadedPdf: this.getDownloadedPdf([id]) });

            if (data.link.indexOf('inline') !== -1) {
                // Chceme inline
                window.open(data.link, '_blank');
                return;
            }

            // Stiahneme subor
            window.location = data.link;
        });

        // Zavolame callback
        callback();
    }

    /**
     * Zmazanie platby.
     */
    async delete() {
        const { deleteInvoicePayment, items } = this.props;
        const { lightbox } = this.state;

        // Zavolame loading
        lightbox.delete.callbackLoading();

        // Zavrieme lightbox
        this.closeLightbox('delete');

        // Zmazeme platby
        await deleteInvoicePayment(this, lightbox.delete.id);

        // Zavolame callback
        lightbox.delete.callback();

        // Znovu nacitame zoznam od prvej stranky
        this.fetchList(1, 0, items.filtered);
    }

    /**
     * Zmazanie platieb cez multiselect.
     */
    async multiDelete() {
        const { deleteInvoicePayments, items } = this.props;
        const { lightbox } = this.state;

        // Zavrieme lightbox
        this.closeLightbox('multidelete');

        // Zobrazime loading
        lightbox.multidelete.callbackLoading();

        // Zmazeme platby
        await deleteInvoicePayments(this, lightbox.multidelete.ids, items.filtered);

        // Zavolame callback
        lightbox.multidelete.callback();

        // Znovu nacitame zoznam od prvej stranky
        this.fetchList(1, 0, items.filtered);
    }

    /**
     * Zavolanie addonu cez multiselect.
     */
    async multiAddon() {
        const { addonInvoicePayments, items } = this.props;
        const { lightbox } = this.state;

        // Zavrieme lightbox
        this.closeLightbox('multiaddon');

        // Zobrazime loading
        lightbox.multiaddon.callbackLoading();

        // Zavolame addon
        await addonInvoicePayments(this, lightbox.multiaddon.ids, lightbox.multiaddon.addonId, items.filtered);

        // Zavolame callback
        lightbox.multiaddon.callback();
    }

    /**
     * Synchronizujeme.
     */
    sync() {
        this.setState({ loadingSync: true });

        request('/user-eshops/syncPayments').then(response => {
            const { status, data } = response.data;

            this.setState({ loadingSync: false });

            // Vycistime zoznam
            this.cleanList();

            const { items } = this.props;
            const count = status === 'success' ? data.items.length : 0;

            // Nacitame prvu stranku
            this.fetchList(1, 0, items.filtered, () => this.showSnackbar('success', `${count} ${plural(count, [
                __('platba bola načítaná'),
                __('platby boli načítané'),
                __('platieb bolo načítaných'),
            ])}`));
        });
    }

    /**
     * Event po zmene addonu v lightboxe.
     *
     * @param {string} value
     */
    onChangeMultiAddon(value) {
        const { lightbox } = this.state;

        this.setState({ lightbox: { ...lightbox, multiaddon: { ...lightbox.multiaddon, addonId: value } } });
    }

    /**
     * Rendrujeme screen.
     *
     * @return {JSX.Element|null}
     */
    renderScreen() {
        const { items, user, changeUserSetting } = this.props;
        const { lightbox } = this.state;

        const hasDelete = this.hasAccountingAction('delete');

        // Vytiahneme zoznam aktivnych addonov
        const addons = _.reduce(
            items.user_addons,
            (result, addon_id) => (_.has(items.addons, addon_id) ? { ...result, [addon_id]: items.addons[addon_id] } : result),
            {}
        );

        return (
            <div>
                {this.renderLightbox(
                    'delete',
                    __('Naozaj chcete zmazať túto platbu?'),
                    !_.isEmpty(lightbox.delete) ? <div>
                        {hasDelete ? <Checkbox
                            label={__('Zmazať platbu aj z ekonomického systému')}
                            value={user.settings.invoice_payment_delete_in_acccounting}
                            onChange={checked => changeUserSetting('invoice_payment_delete_in_acccounting', checked)}
                        /> : null}
                    </div> : null,
                    __('Áno'),
                    __('Nie'),
                    () => this.delete()
                )}
                {this.renderLightbox(
                    'multidelete',
                    __('Naozaj chcete zmazať označené platby?'),
                    !_.isEmpty(lightbox.multidelete) ? <div>
                        {hasDelete ? <Checkbox
                            label={__('Zmazať platby aj z ekonomického systému')}
                            value={user.settings.invoice_payment_delete_in_acccounting}
                            onChange={checked => changeUserSetting('invoice_payment_delete_in_acccounting', checked)}
                        /> : null}
                    </div> : null,
                    __('Áno'),
                    __('Nie'),
                    () => this.multiDelete()
                )}
                {this.renderLightbox(
                    'multiaddon',
                    __('Doplnok'),
                    !_.isEmpty(lightbox.multiaddon) ? <div>
                        <Select
                            options={addons}
                            value={lightbox.multiaddon.addonId}
                            onChange={value => this.onChangeMultiAddon(value)}
                            allowEmpty={false}
                        />
                    </div> : null,
                    __('Odoslať'),
                    __('Zrušiť'),
                    () => this.multiAddon()
                )}
            </div>
        );
    }
}

const stateToProps = ({ invoice_payments, user }) => ({ items: invoice_payments, user });

export default withCookies(connect(stateToProps, {
    fetch: fetchInvoicePayments,
    clean: cleanInvoicePayments,
    setUser,
    changeUserSetting,
    deleteInvoicePayment,
    deleteInvoicePayments,
    addonInvoicePayments,
})(InvoicePaymentsScreen));
