import { FETCH_EMPLOYEE, CLEAN_EMPLOYEE } from '../actions/types';

const EMPLOYEE_DEFAULT_STATE = {};

/**
 * Reducer pre zamestnanca.
 */
const EmployeeReducer = (state = EMPLOYEE_DEFAULT_STATE, { type, payload }) => {
    switch (type) {
        case FETCH_EMPLOYEE:
            // Nacitanie zamestnanca
            return payload;

        case CLEAN_EMPLOYEE:
            // Vycistenie zamestnanca
            return EMPLOYEE_DEFAULT_STATE;

        default:
            return state;
    }
};

export default EmployeeReducer;
