import React, { Component } from 'react';
import ButtonBase from '@mui/material/ButtonBase';
import { getCustomizationKey } from '../functions';
import { IMAGES } from '../config';
import '../assets/styles/logo.css';

/**
 * Logo komponenta.
 */
class Logo extends Component {
    /**
     * Default props.
     *
     * @type {{
     *     className: string,
     *     color: string,
     *     onClick: function,
     * }}
     */
    static defaultProps = {
        className: '',
        color: '',
        onClick: () => {},
    };

    /**
     * Rendrovanie.
     *
     * @returns {JSX.Element}
     */
    render() {
        const { className, color, onClick } = this.props;

        // Customizacia
        const customization = getCustomizationKey();

        let content = (
            <div className={`logo ${className} logo-${color}`}>
                <span>GOOD</span>
                <span>ESHOP</span>
            </div>
        );

        if (customization !== '') {
            // Mame customizaciu
            content = <img className="logo-img" src={IMAGES[`${customization}.png`]} alt="logo" />

            if (customization === 'mtokna' && color === '') {
                content = <img className="logo-img" src={IMAGES[`${customization}-white.png`]} alt="logo" />
            }
        }

        return (
            <ButtonBase
                focusRipple
                onClick={onClick}
            >
                {content}
            </ButtonBase>
        );
    }
}

export { Logo };
