import _ from 'lodash';
import { toNumber } from '../functions';
import {
    FETCH_MTOKNA_MANUAL_CUSTOMER_ORDERS,
    CLEAN_MTOKNA_MANUAL_CUSTOMER_ORDERS,
    EDIT_MTOKNA_MANUAL_CUSTOMER_ORDER,
    DELETE_MTOKNA_MANUAL_CUSTOMER_ORDER,
} from '../actions/types';

const MTOKNA_MANUAL_CUSTOMER_ORDERS_DEFAULT_STATE = {};

/**
 * Reducer pre objednavky.
 */
const MtoknaManualCustomerOrdersReducer = (state = MTOKNA_MANUAL_CUSTOMER_ORDERS_DEFAULT_STATE, { type, payload }) => {
    switch (type) {
        case FETCH_MTOKNA_MANUAL_CUSTOMER_ORDERS:
            return payload;

        case CLEAN_MTOKNA_MANUAL_CUSTOMER_ORDERS:
        case DELETE_MTOKNA_MANUAL_CUSTOMER_ORDER:
            return MTOKNA_MANUAL_CUSTOMER_ORDERS_DEFAULT_STATE;

        case EDIT_MTOKNA_MANUAL_CUSTOMER_ORDER:
            // Editacia objednavky
            return { ...state, items: _.map(state.items, item => {
                if (toNumber(item.id) === toNumber(payload.id)) {
                    // Nasli sme objednavku
                    item = { ...item, data: { ...item.data, ...payload.data } };
                }

                return item;
            }) };

        default:
            return state;
    }
};

export default MtoknaManualCustomerOrdersReducer;
