import _ from 'lodash';
import { __, request } from '../functions';
import { FETCH_EMPLOYEES, DELETE_EMPLOYEE, CLEAN_EMPLOYEES } from './types';

/**
 * Nacitanie zamestnancov.
 *
 * @param {Screen} screen
 * @param {object} options
 */
export const fetchEmployees = (screen, options = {}) => {
    const defaults = {
        page: 1,
        per_page: 0,
        filtered: {},
    };

    options = { ...defaults, ...options };

    let params = { page: options.page, per_page: options.per_page };

    // Nasetujeme filter
    _.forEach(options.filtered, (value, key) => {
        params = { ...params, ...{ [`filter_${key}`]: value } };
    });

    return dispatch => request('/user-employees/index', params).then(response => {
        const { data, sync } = response.data;
        const { setUser } = screen.props;

        // Nasetujeme user data
        setUser(sync.user);

        dispatch({ type: FETCH_EMPLOYEES, payload: data });
    });
};

/**
 * Zmazanie zamestnanca.
 *
 * @param {Screen} screen
 * @param {number} id
 */
export const deleteEmployee = (screen, id) => {
    return dispatch => request(`/user-employees/delete/${id}`).then(response => {
        const { status } = response.data;

        if (status === 'error') {
            // Nepodarilo sa odstranit zamestnanca
            screen.showSnackbar('error', __('Nepodarilo sa zmazať zamestnanca'));
            return;
        }

        screen.showSnackbar('success', __('Zamestnanec bol zmazaný'));

        dispatch({ type: DELETE_EMPLOYEE, payload: id });
    });
};

/**
 * Vycistenie zamestnancov.
 */
export const cleanEmployees = () => ({ type: CLEAN_EMPLOYEES });
