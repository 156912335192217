import _ from 'lodash';
import {
    FETCH_RAINPRO_ORDERS,
    CLEAN_RAINPRO_ORDERS,
    SYNC_RAINPRO_ORDER,
    EDIT_RAINPRO_ORDER,
    PICKER_RAINPRO_ORDER,
    SEND_RAINPRO_ORDERS,
} from './types';
import { request, __ } from '../functions';

export const fetchRainproOrders = (screen, options = {}) => {
    const defaults = {
        page: 1,
        per_page: 0,
        filtered: {},
        callback: null,
    };

    options = { ...defaults, ...options };

    let params = { page: options.page, per_page: options.per_page };

    // Nasetujeme filter
    _.forEach(options.filtered, (value, key) => {
        params = { ...params, ...{ [`filter_${key}`]: value } };
    });

    let uri = '/rainpro/orders';

    return dispatch => request(uri, params).then(response => {
        const { data, sync } = response.data;
        const { setUser } = screen.props;

        if (options.callback !== null) {
            // Zavolame callback
            options.callback();
        }

        // Nasetujeme user data
        setUser(sync.user);

        dispatch({ type: FETCH_RAINPRO_ORDERS, payload: data });
    });
};

export const cleanRainproOrders = () => ({ type: CLEAN_RAINPRO_ORDERS });

export const syncRainproOrder = (screen, id) => {
    return dispatch => request(`/rainpro/syncOrder/${id}`).then(response => {
        const { status, data } = response.data;

        if (status === 'error') {
            // Objednavku sa nepodarilo synchronizovat
            screen.showSnackbar('error', __('Objednávku sa nepodarilo synchronizovať'));
            return;
        }

        screen.showSnackbar('success', __('Objednávka bola zosynchronizovaná s eshopom'));

        dispatch({ type: SYNC_RAINPRO_ORDER, payload: data });
    });
};

export const commentRainproOrder = (screen, id, order_id, comment) => {
    return dispatch => request(`/rainpro/addComment/${id}`, { comment }, 'POST').then(response => {
        const { status, data } = response.data;

        if (status === 'error') {
            // Objednavku sa nepodarilo zmenit
            screen.showSnackbar('error', __('Nepodarilo sa pridať poznámku'));
            return;
        }

        screen.showSnackbar('success', __('Poznámka bola pridaná'));

        dispatch({ type: PICKER_RAINPRO_ORDER, payload: { id: order_id, items: data.items } });
    });
};

export const sendRainproOrders = data => ({ type: SEND_RAINPRO_ORDERS, payload: data });

export const changeRainproOrderData = (id, data) => ({ type: EDIT_RAINPRO_ORDER, payload: { id, data } });
