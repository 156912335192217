import React from 'react';
import { connect } from 'react-redux';
import { withCookies } from 'react-cookie';
import _ from 'lodash';
import { ButtonBase } from '@mui/material';
import { Screen, Logo, Input, Button, Checkbox } from '../components';
import {__, request, isValidEmail, getCustomization} from '../functions';
import {
    IMAGES,
    COOKIE_TOKEN_NAME,
    COOKIE_SETTINGS,
    PASSWORD_MIN_LENGTH,
    MARKET,
    COOKIE_WEBAREAL_NAME,
    COOKIE_SOURCE_NAME
} from '../config';
import '../assets/styles/register.css';

/**
 * Registracia.
 */
class RegisterScreen extends Screen {
    /**
     * Title.
     *
     * @type {string}
     */
    title = __('Registrácia');

    /**
     * Default state.
     *
     * @type {Object}
     */
    state = {
        email: '',
        password: '',
        passwordRepeat: '',
        vop: false,
        loading: false,
        socialLinks: null,
    };

    /**
     * Auth required.
     *
     * @type {boolean}
     */
    authRequired = false;

    /**
     * Komponenta bola pripojena.
     *
     * @return boolean
     */
    async componentDidMount() {
        if (super.componentDidMount()) {
            // Nacitame linky na soc siete
            let webarealCode = null;

            if (this.isWebareal()) {
                // Zobrazujeme webareal
                const { cookies } = this.props;

                webarealCode = cookies.get(COOKIE_WEBAREAL_NAME, COOKIE_SETTINGS);
            }

            request('/users/social-links', { webarealCode }).then(response => {
                const { data } = response.data;

                this.setState({ socialLinks: data, email: _.has(data, 'email') ? data.email : '' });
            });
        }

        return true;
    }

    /**
     * Event po zmene hodnoty.
     *
     * @param {string} name
     * @param {string} value
     */
    onChangeValue(name, value) {
        this.setState({ [name]: value });
    }

    /**
     * Registracia.
     */
    register() {
        const { email, password, passwordRepeat, vop } = this.state;

        if (!isValidEmail(email)) {
            // Nie je vyplneny email
            this.showSnackbar('error', __('Neplatný email'));
            return;
        } else if (password.length < PASSWORD_MIN_LENGTH) {
            // Nie je vyplnene heslo
            this.showSnackbar(
                'error',
                __('Minimálna dĺžka hesla je {COUNT} znakov').replace('{COUNT}', PASSWORD_MIN_LENGTH.toString())
            );
            return;
        } else if (password !== passwordRepeat) {
            // Heslo a heslo znovu sa nerovnaju
            this.showSnackbar('error', __('Heslá nie sú rovnaké'));
            return;
        } else if (!vop && !this.isMtokna() && !this.isRainpro()) {
            // Nesuhlas s VOP
            this.showSnackbar('error', __('Musíte súhlasiť so Všeobecnými obchodnými podmienkami'));
            return;
        }

        this.setState({ loading: true });

        const { cookies } = this.props;

        // Webareal registracia?
        const isWebareal = this.isWebareal();

        let url = '/users/register';

        if (isWebareal) {
            // Webareal registracia
            const webarealCode = cookies.get(COOKIE_WEBAREAL_NAME, COOKIE_SETTINGS);

            url = `/users/registerWebareal/${webarealCode}`;
        }

        if (this.isMtokna()) {
            url = '/mtokna/register';
        } else if (this.isRainpro()) {
            url = '/rainpro/register';
        }

        // Vytiahneme zdroj
        const source = cookies.get(COOKIE_SOURCE_NAME, COOKIE_SETTINGS);

        request(url, {
            email,
            password,
            password_repeat: passwordRepeat,
            source: !_.isEmpty(source) ? source : null,
        }, 'POST').then(async response => {
            const { status, data } = response.data;

            if (status === 'error') {
                // Registracia sa nepodarila
                this.setState({ loading: false });
                this.showSnackbar('error', __('Email sa už používa'));
                return;
            }

            if (isWebareal && _.has(data, 'credentials') && !_.isEmpty(data.credentials)) {
                // Webareal registracia, vytvorime prepojenie na eshop
                global.token = data.token;

                await request('/user-eshops/create', {
                    name: data.name,
                    eshop_id: 1,
                    eshop_credentials: data.credentials,
                }, 'POST', {}, false).catch(error => {}).then(response => {});
            }

            this.setState({ loading: false });

            // Zavolame callback
            this.afterRegister(data.token);
        });
    }

    /**
     * Callback po registracii.
     *
     * @param {string} token
     */
    afterRegister(token) {
        const { cookies } = this.props;

        // Ulozime token do cookie
        cookies.set(COOKIE_TOKEN_NAME, token, COOKIE_SETTINGS);

        window.location = '/dashboard';
    }

    /**
     * Registracia cez soc. siete.
     *
     * @param {string} link
     */
    socialRegister(link) {
        const { vop } = this.state;

        if (!vop) {
            // Nesuhlas s VOP
            this.showSnackbar('error', __('Musíte súhlasiť so Všeobecnými obchodnými podmienkami'));
            return;
        }

        window.location = link;
    }

    /**
     * Rendrovanie.
     *
     * @returns {JSX.Element}
     */
    render() {
        const { email, password, passwordRepeat, vop, loading, socialLinks } = this.state;

        if (socialLinks === null) {
            // Data nie su nacitane
            return this.renderLoading();
        }

        const vopUrl = MARKET === 'cz' ? '/vop-cz.pdf?v=1' : '/vop.pdf?v=1';

        // Zobrazujeme webareal
        const isWebareal = this.isWebareal();

        // Mame customizaciu?
        const hasCustomization = getCustomization() !== null;

        if (this.isMtokna()) {
            return (
                <div className="register">
                    <Logo onClick={() => { window.location = '/'; }} color="dark" />
                    <div className="register__title">{__('Registrácia')}</div>
                    <div className="register__or" style={{ textAlign: 'center' }}>{__('Ešte nemáte prihlasovacie údaje? Kontaktujte nás na info@dobrezeleziarstvo.sk')}</div>
                    <ButtonBase
                        onClick={() => this.redirect('/login')}
                        className="register__button-switch"
                    >{__('Už máte účet? Prihláste sa.')}</ButtonBase>
                </div>
            );
        } else if (this.isRainpro()) {
            return (
                <div className="register">
                    <Logo onClick={() => { window.location = '/'; }} color="dark" />
                    <div className="register__title">{__('Registrácia')}</div>
                    <div className="register__or" style={{ textAlign: 'center' }}>{__('Ešte nemáte prihlasovacie údaje? Kontaktujte nás na info@rainpro.sk')}</div>
                    <ButtonBase
                        onClick={() => this.redirect('/login')}
                        className="register__button-switch"
                    >{__('Už máte účet? Prihláste sa.')}</ButtonBase>
                </div>
            );
        }

        return (
            <div className="register">
                <Logo onClick={() => { window.location = '/'; }} color="dark" />
                <div className="register__title">{__('Registrácia')}</div>
                {!isWebareal && !hasCustomization ? <div className="register__social">
                    <ButtonBase
                        onClick={!_.isEmpty(socialLinks)
                            ? () => this.socialRegister(socialLinks.google_register)
                            : () => {}}
                        className="register__social__button google"
                        variant="outlined"
                        color="inherit"
                    >
                        <img src={IMAGES['google.svg']} alt="google logo" />
                        <span>{__('Registrovať cez Google')}</span>
                    </ButtonBase>
                </div> : null}
                {!isWebareal && !hasCustomization ? <div className="register__or">{__('Alebo sa registrujte cez email a heslo')}</div> : null}
                <Input
                    type="email"
                    label={__('Email')}
                    value={email}
                    onChange={value => this.onChangeValue('email', value)}
                />
                <Input
                    type="password"
                    label={__('Heslo')}
                    value={password}
                    onChange={value => this.onChangeValue('password', value)}
                />
                <Input
                    type="password"
                    label={__('Heslo znovu')}
                    value={passwordRepeat}
                    onChange={value => this.onChangeValue('passwordRepeat', value)}
                />
                {!hasCustomization ? <Checkbox
                    label={<span>
                        <span>{__('Súhlasím so')} </span>
                        <a href={vopUrl} target="_blank">{__('Všeobecnými obchodnými podmienkami')}</a>
                    </span>}
                    value={vop}
                    onChange={checked => this.onChangeValue('vop', checked)}
                /> : null}
                <Button
                    onClick={() => this.register()}
                    className="register__button"
                    loading={loading}
                >{__('Registrovať')}</Button>
                <ButtonBase
                    onClick={() => this.redirect('/login')}
                    className="register__button-switch"
                >{__('Už máte účet? Prihláste sa.')}</ButtonBase>
                {this.renderSnackbar()}
            </div>
        );
    }
}

const stateToProps = ({ user }) => ({ user });

export default withCookies(connect(stateToProps)(RegisterScreen));
