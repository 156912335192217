import _ from 'lodash';
import {
    FETCH_ORDERS,
    CLEAN_ORDERS,
    SYNC_ORDER,
    SYNC_ORDERS,
    EXPORT_ORDER,
    EXPORT_ORDERS,
    CHANGE_ORDERS_STATE,
    CHANGE_ORDER_STATE,
    CREATE_INVOICE,
    CREATE_INVOICES,
    PAID_ORDER,
    PAID_ORDERS,
    DELETE_ORDER,
    DELETE_ORDERS,
    ADDON_ORDERS,
    EDIT_ORDER,
    SEND_ORDERS,
    SELLER_ORDER,
    SELLER_ORDERS,
    PICKER_ORDER,
} from './types';
import {request, __, toNumber, getCustomizationKey} from '../functions';

/**
 * Nacitanie objednavok.
 *
 * @param {Screen} screen
 * @param {object} options
 */
export const fetchOrders = (screen, options = {}) => {
    const defaults = {
        page: 1,
        per_page: 0,
        filtered: {},
        callback: null,
    };

    options = { ...defaults, ...options };

    let params = { page: options.page, per_page: options.per_page };

    // Nasetujeme filter
    _.forEach(options.filtered, (value, key) => {
        params = { ...params, ...{ [`filter_${key}`]: value } };
    });

    let uri = '/orders/index';

    if (
        (
            _.has(screen.props, 'user')
            && _.has(screen.props.user, 'is_seller')
            && screen.props.user.is_seller
        )
        || window.location.pathname === '/sellers-orders'
    ) {
        uri = '/orders/sellersIndex';
    }

    if (getCustomizationKey() === 'mtokna') {
        uri = '/mtokna/orders';
    }

    return dispatch => request(uri, params).then(response => {
        const { data, sync } = response.data;
        const { setUser } = screen.props;

        if (options.callback !== null) {
            // Zavolame callback
            options.callback();
        }

        // Nasetujeme user data
        setUser(sync.user);

        dispatch({ type: FETCH_ORDERS, payload: data });
    });
};

/**
 * Vycistenie objednavok.
 */
export const cleanOrders = () => ({ type: CLEAN_ORDERS });

/**
 * Sync objednavok.
 *
 * @param {Screen} screen
 * @param {string} ids
 * @param {Object} filtered
 */
export const syncOrders = (screen, ids, filtered = {}) => {
    let params = {};

    // Nasetujeme filter
    _.forEach(filtered, (value, key) => {
        params = { ...params, ...{ [`filter_${key}`]: value } };
    });

    return dispatch => request(`/orders/multiSync/${ids}`, params).then(response => {
        const { status, data } = response.data;

        if (status === 'error') {
            // Nepodarilo sa synchronizovat
            screen.showSnackbar('error', __('Objednávky sa nepodarilo synchronizovať s eshopom'));
            return;
        }

        screen.showSnackbar('success', __('Objednávky boli synchronizované s eshopom'));

        dispatch({ type: SYNC_ORDERS, payload: data });
    });
};

/**
 * Zmena stavu objednavky.
 *
 * @param {Screen} screen
 * @param {number} id
 * @param {number} stateId
 * @param {bool} not_change_in_eshop
 */
export const changeOrderState = (screen, id, stateId, not_change_in_eshop = false) => {
    let params = {};

    if (not_change_in_eshop) {
        params = { not_change_in_eshop: true };
    }

    return dispatch => request(`/orders/changeState/${id}/${stateId}`, params).then(response => {
        const { status, data } = response.data;

        if (status === 'error') {
            // Stav sa nepodarilo zmenit
            screen.showSnackbar('error', __('Stav objednávky sa nepodarilo zmeniť'));
            return;
        }

        screen.showSnackbar('success', __('Stav objednávky bol zmenený'));

        dispatch({ type: CHANGE_ORDER_STATE, payload: data });
    });
};

/**
 * Zmazanie objednavky.
 *
 * @param {Screen} screen
 * @param {number} id
 */
export const deleteOrder = (screen, id) => {
    const { order_delete_in_eshop } = screen.props.user.settings;

    return dispatch => request(`/orders/delete/${id}`, { order_delete_in_eshop }).then(response => {
        const { status } = response.data;

        if (status === 'error') {
            // Objednavku sa nepodarilo zmazat
            screen.showSnackbar('error', __('Objednávku sa nepodarilo zmazať pretože má evidovanú faktúru'));
            return;
        }

        screen.showSnackbar('success', __('Objednávka bola zmazaná'));

        dispatch({ type: DELETE_ORDER });
    });
};

/**
 * Uhradenie objednavky.
 *
 * @param {Screen} screen
 * @param {number} id
 */
export const paidOrder = (screen, id) => {
    return dispatch => request(`/orders/paid/${id}`).then(response => {
        const { status, data } = response.data;

        if (status === 'error') {
            // Objednavku sa nepodarilo uhradit
            screen.showSnackbar('error', __('Objednávku sa nepodarilo uhradiť'));
            return;
        }

        screen.showSnackbar('success', __('Objednávka bola uhradená'));

        dispatch({ type: PAID_ORDER, payload: data });
    });
};

/**
 * Synchronizacia objednavky.
 *
 * @param {Screen} screen
 * @param {number} id
 */
export const syncOrder = (screen, id) => {
    return dispatch => request(`/orders/sync/${id}`).then(response => {
        const { status, data } = response.data;

        if (status === 'error') {
            // Objednavku sa nepodarilo synchronizovat
            screen.showSnackbar('error', __('Objednávku sa nepodarilo synchronizovať'));
            return;
        }

        screen.showSnackbar('success', __('Objednávka bola zosynchronizovaná s eshopom'));

        dispatch({ type: SYNC_ORDER, payload: data });
    });
};

/**
 * Stiahnutie order pdf.
 *
 * @param {Screen} screen
 * @param {number} id
 * @param {number} pdfExportId
 */
export const pdfOrder = (screen, id, pdfExportId) => {
    return dispatch => request(`/orders/export/${id}/${pdfExportId}`).then(response => {
        const { status, data } = response.data;

        if (status === 'error') {
            screen.showSnackbar('error', __('Nepodarilo sa stiahnuť pdf objednávky'));
            return;
        }

        screen.showSnackbar('success', __('Objednávka bola exportovaná'));

        // Stiahneme subor
        window.location = data.link;

        dispatch({ type: EXPORT_ORDER, payload: { [id]: id } });
    });
};

/**
 * Vytvorenie faktury.
 *
 * @param {Screen} screen
 * @param {number} id
 */
export const createInvoice = (screen, id) => {
    id = toNumber(id);

    return dispatch => request(`/orders/createInvoice/${id}`).then(response => {
        const { status, data } = response.data;
        let hasError = false;

        if (status === 'success') {
            hasError = _.has(data, id) && _.isString(data[id]);
        }

        if (status === 'error' || hasError) {
            // Fakturu sa nepodarilo vytvorit
            screen.showSnackbar('error', __('Faktúru sa nepodarilo vytvoriť'));

            if (_.isEmpty(data)) {
                return;
            }
        }

        if (!hasError) {
            screen.showSnackbar('success', __('Faktúra bola vytvorená'));
        }

        dispatch({ type: CREATE_INVOICE, payload: data });
    });
};

/**
 * Stiahnutie order pdf.
 *
 * @param {Screen} screen
 * @param {string} ids
 * @param {number} pdfExportId
 * @param {Object} filtered
 */
export const pdfOrders = (screen, ids, pdfExportId, filtered = {}) => {
    let params = { merge_multi_order_pdf: screen.props.user.settings.merge_multi_order_pdf };

    // Nasetujeme filter
    _.forEach(filtered, (value, key) => {
        params = { ...params, ...{ [`filter_${key}`]: value } };
    });

    return dispatch => request(`/orders/multiExport/${ids}/${pdfExportId}`, params).then(response => {
        const { status, data } = response.data;

        if (status === 'error') {
            screen.showSnackbar('error', __('Nepodarilo sa stiahnuť zoznam pdf objednávok'));
            return;
        }

        screen.showSnackbar('success', __('Objednávky boli exportované'));

        // Stiahneme subor
        window.location = data.link;

        dispatch({ type: EXPORT_ORDERS, payload: data.ids });
    });
};

/**
 * Zmena stavu objednavok.
 *
 * @param {Screen} screen
 * @param {string} ids
 * @param {number} stateId
 * @param {Object} filtered
 */
export const changeOrdersState = (screen, ids, stateId, filtered = {}) => {
    let params = {};

    // Nasetujeme filter
    _.forEach(filtered, (value, key) => {
        params = { ...params, ...{ [`filter_${key}`]: value } };
    });

    return dispatch => request(`/orders/multiChangeState/${ids}/${stateId}`, params).then(response => {
        const { status, data } = response.data;

        if (status === 'error') {
            // Stav sa nepodarilo zmenit
            screen.showSnackbar('error', __('Stav objednávok sa nepodarilo zmeniť'));
            return;
        }

        screen.showSnackbar('success', __('Stav objednávok bol zmenený'));

        dispatch({ type: CHANGE_ORDERS_STATE, payload: data });
    });
};

/**
 * Addon objednavok.
 *
 * @param {Screen} screen
 * @param {string} ids
 * @param {number} addonId
 * @param {Object} filtered
 */
export const addonOrders = (screen, ids, addonId, filtered = {}) => {
    let params = {};

    // Nasetujeme filter
    _.forEach(filtered, (value, key) => {
        params = { ...params, ...{ [`filter_${key}`]: value } };
    });

    return dispatch => request(`/orders/multiAddon/${ids}/${addonId}`, params).then(response => {
        const { status, data } = response.data;

        if (status === 'error') {
            // Nepodarilo sa nacitat doplnok
            screen.showSnackbar('error', __('Nepodarilo sa načítať doplnok'));
            return;
        }

        screen.showSnackbar('success', __('Doplnok bol načítaný'));

        if (_.has(data, 'link')) {
            // Addon ma subor na stiahnutie
            window.location = data.link;
        }

        dispatch({ type: ADDON_ORDERS });
    });
};

/**
 * Vytvorenie faktur k objednavkam cez multiselect.
 *
 * @param {Screen} screen
 * @param {string} ids
 * @param {Object} filtered
 */
export const createInvoices = (screen, ids, filtered = {}) => {
    let params = {};

    // Nasetujeme filter
    _.forEach(filtered, (value, key) => {
        params = { ...params, ...{ [`filter_${key}`]: value } };
    });

    return dispatch => request(`/orders/multiCreateInvoice/${ids}`, params).then(response => {
        const { status, data } = response.data;
        let hasError = false;

        if (status === 'success') {
            // Pozrieme ci nejaka faktura skoncila chybou
            _.each(data, item => {
                if (_.isString(item)) {
                    hasError = true;
                }
            });
        }

        if (status === 'error' || hasError) {
            // Objednavky sa nepodarilo vytvorit
            screen.showSnackbar('error', __('Niektoré faktúry sa nepodarilo vystaviť'));

            if (_.isEmpty(data)) {
                return;
            }
        }

        if (!hasError) {
            screen.showSnackbar('success', __('Faktúry boli vytvorené'));
        }

        dispatch({ type: CREATE_INVOICES, payload: data });
    });
};

/**
 * Uhradenie objednavok.
 *
 * @param {Screen} screen
 * @param {string} ids
 * @param {Object} filtered
 */
export const paidOrders = (screen, ids, filtered = {}) => {
    let params = {};

    // Nasetujeme filter
    _.forEach(filtered, (value, key) => {
        params = { ...params, ...{ [`filter_${key}`]: value } };
    });

    return dispatch => request(`/orders/multiPaid/${ids}`, params).then(response => {
        const { status, data } = response.data;

        if (status === 'error') {
            // Objednavky sa nepodarilo uhradit
            screen.showSnackbar('error', __('Objednávky sa nepodarilo uhradiť'));
            return;
        }

        screen.showSnackbar('success', __('Objednávky boli uhradené'));

        dispatch({ type: PAID_ORDERS, payload: data });
    });
};

/**
 * Zmazanie objednavok cez multiselect.
 *
 * @param {Screen} screen
 * @param {string} ids
 * @param {Object} filtered
 */
export const deleteOrders = (screen, ids, filtered = {}) => {
    let params = { order_delete_in_eshop: screen.props.user.settings.order_delete_in_eshop };

    // Nasetujeme filter
    _.forEach(filtered, (value, key) => {
        params = { ...params, ...{ [`filter_${key}`]: value } };
    });

    return dispatch => request(`/orders/multiDelete/${ids}`, params).then(response => {
        const { status } = response.data;

        if (status === 'error') {
            // Objednavky sa nepodarilo zmazat
            screen.showSnackbar('error', __('Objednávky sa nepodarilo zmazať pretože majú evidované faktúry'));
            return;
        }

        screen.showSnackbar('success', __('Objednávky boli zmazané'));

        dispatch({ type: DELETE_ORDERS });
    });
};

/**
 * Editacia objednavky.
 *
 * @param {Screen} drawer
 * @param {number} id
 * @param {Object} data
 */
export const editOrder = (drawer, id, data) => {
    return dispatch => request(`/orders/edit/${id}`, data, 'POST').then(response => {
        const { status } = response.data;

        if (status === 'error') {
            // Objednavku sa nepodarilo zmenit
            drawer.setState({ loading: false });
            drawer.props.showSnackbar('error', __('Nepodarilo sa zmeniť objednávku'));
            return;
        }

        // Zavrieme
        drawer.props.onClose();
        drawer.props.showSnackbar('success', __('Objednávka bola zmenená'));

        dispatch({ type: EDIT_ORDER, payload: { id, data } });
    });
};

/**
 * Editacia objednavky.
 *
 * @param {Screen} screen
 * @param {number} id
 * @param {Object} data
 */
export const sellerOrder = (screen, id, data) => {
    return dispatch => request(`/orders/sellerUpdate/${id}`, { data }, 'POST').then(response => {
        const { status, data } = response.data;

        if (status === 'error') {
            // Objednavku sa nepodarilo zmenit
            screen.showSnackbar('error', __('Nepodarilo sa uložiť objednávku'));
            return;
        }

        // Zavrieme
        screen.closeLightbox('sellers');
        screen.showSnackbar('success', __('Objednávka bola zmenená'));

        dispatch({ type: SELLER_ORDER, payload: data });
    });
};

/**
 * Multi sellers.
 *
 * @param {Screen} screen
 * @param {string} ids
 * @param {Object} metadata
 * @param {Object} filtered
 */
export const sellerOrders = (screen, ids, metadata, filtered = {}) => {
    let params = {};

    // Nasetujeme filter
    _.forEach(filtered, (value, key) => {
        params = { ...params, ...{ [`filter_${key}`]: value } };
    });

    return dispatch => request(`/orders/multiSellerUpdate/${ids}`, { data: metadata }, 'POST', params).then(response => {
        const { status, data } = response.data;

        if (status === 'error') {
            screen.showSnackbar('error', __('Nepodarilo sa uložiť objednávky'));
            return;
        }

        screen.closeLightbox('multisellers');
        screen.showSnackbar('success', __('Objednávky boli zmenené'));

        dispatch({ type: SELLER_ORDERS, payload: data });
    });
};

export const addRainproPicker = (screen, order_id, user_id, cod) => {
    return dispatch => request(`/rainpro/createOrder/${order_id}/${user_id}/${cod}`).then(response => {
        const { status, data } = response.data;

        if (status === 'error') {
            // Objednavku sa nepodarilo zmenit
            screen.showSnackbar('error', __('Nepodarilo sa priradiť objednávku'));
            return;
        }

        screen.showSnackbar('success', __('Objednávka bola priradená'));

        dispatch({ type: PICKER_ORDER, payload: { id: order_id, items: data.items } });
    });
};

export const removeRainproPicker = (screen, id, order_id) => {
    return dispatch => request(`/rainpro/removeOrder/${id}`).then(response => {
        const { status, data } = response.data;

        if (status === 'error') {
            // Objednavku sa nepodarilo zmenit
            screen.showSnackbar('error', __('Nepodarilo sa zrušiť objednávku'));
            return;
        }

        screen.showSnackbar('success', __('Objednávka bola zrušená'));

        dispatch({ type: PICKER_ORDER, payload: { id: order_id, items: data.items } });
    });
};

/**
 * Zmenime data objednavky.
 *
 * @param {number} id
 * @param {Object} data
 */
export const changeOrderData = (id, data) => ({ type: EDIT_ORDER, payload: { id, data } });

/**
 * Odosleme objednavky.
 *
 * @param {Object} data
 */
export const sendOrders = data => ({ type: SEND_ORDERS, payload: data });
