import _ from 'lodash';
import {
    FETCH_MTOKNA_MODERATORS,
    CLEAN_MTOKNA_MODERATORS,
    CHANGE_MTOKNA_MODERATOR_EMAIL,
    DELETE_MTOKNA_MODERATOR,
} from './types';
import { request, __ } from '../functions';

export const fetchMtoknaModerators = (screen, options = {}) => {
    const defaults = {
        page: 1,
        per_page: 0,
        filtered: {},
    };

    options = { ...defaults, ...options };

    let params = { page: options.page, per_page: options.per_page };

    // Nasetujeme filter
    _.forEach(options.filtered, (value, key) => {
        params = { ...params, ...{ [`filter_${key}`]: value } };
    });

    return dispatch => request('/mtokna/moderators', params).then(response => {
        const { data, sync } = response.data;
        const { setUser } = screen.props;

        // Nasetujeme user data
        setUser(sync.user);

        dispatch({ type: FETCH_MTOKNA_MODERATORS, payload: data });
    });
};

export const changeMtoknaModeratorEmail = (screen, id, email) => {
    const settings = {
        email_new: email,
    };

    return dispatch => request(`/mtokna/changeUserSettings/${id}`, { settings }, 'POST').then(response => {
        const { status } = response.data;

        if (status === 'error') {
            screen.showSnackbar('error', __('Nepodarilo sa zmeniť email'));
            return;
        }

        screen.showSnackbar('success', __('Email bol zmenený'));

        dispatch({ type: CHANGE_MTOKNA_MODERATOR_EMAIL, payload: { id, email } });
    });
};

export const changeMtoknaModeratorPassword = (screen, id, password, password_repeat) => {
    const settings = {
        password_new: password,
        password_new_repeat: password_repeat,
    };

    return dispatch => request(`/mtokna/changeUserSettings/${id}`, { settings }, 'POST').then(response => {
        const { status } = response.data;

        if (status === 'error') {
            screen.showSnackbar('error', __('Nepodarilo sa zmeniť heslo'));
            return;
        }

        screen.showSnackbar('success', __('Heslo bolo zmenené'));
    });
};

export const deleteMtoknaModerator = (screen, id) => {
    return dispatch => request(`/mtokna/removeModerator/${id}`).then(response => {
        const { status } = response.data;

        if (status === 'error') {
            screen.showSnackbar('error', __('Nepodarilo sa zmazať zástupcu'));
            return;
        }

        screen.showSnackbar('success', __('Zástupca bol zmazaný'));

        dispatch({ type: DELETE_MTOKNA_MODERATOR });
    });
};

export const cleanMtoknaModerators = () => ({ type: CLEAN_MTOKNA_MODERATORS });
