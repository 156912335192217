import { FETCH_ADERYN_PRODUCT_LOGS, CLEAN_ADERYN_PRODUCT_LOGS } from '../actions/types';

const ADERYN_PRODUCT_LOGS_DEFAULT_STATE = {};

const AderynProductLogsReducer = (state = ADERYN_PRODUCT_LOGS_DEFAULT_STATE, { type, payload }) => {
    switch (type) {
        case FETCH_ADERYN_PRODUCT_LOGS:
            return payload;

        case CLEAN_ADERYN_PRODUCT_LOGS:
            return ADERYN_PRODUCT_LOGS_DEFAULT_STATE;

        default:
            return state;
    }
};

export default AderynProductLogsReducer;
