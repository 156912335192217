import { FETCH_MTOKNA_WINDOW_SELLER, CLEAN_MTOKNA_WINDOW_SELLER } from './types';
import { request } from '../functions';

export const fetchMtoknaWindowSeller = (screen) => {
    return dispatch => request('/mtokna/viewWindowSeller').then(response => {
        const { data, sync } = response.data;
        const { setUser } = screen.props;

        // Nasetujeme user data
        setUser(sync.user);

        dispatch({ type: FETCH_MTOKNA_WINDOW_SELLER, payload: data });
    });
};

export const cleanMtoknaWindowSeller = () => ({ type: CLEAN_MTOKNA_WINDOW_SELLER });
