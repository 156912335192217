import React from 'react';
import _ from 'lodash';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import Editor from 'ckeditor5-build-full';
import { Chip, IconButton, Tooltip } from '@mui/material';
import HelpIcon from '@mui/icons-material/Help';
import Barcode from 'react-barcode';
import { Navigate, Button, Input, Logs } from '../components';
import { __, request, toNumber, getProductButtons } from '../functions';
import '../assets/styles/product.css';

/**
 * Produkt komponenta.
 */
class Product extends Navigate {
    /**
     * Default props.
     *
     * @type {Object}
     */
    static defaultProps = {
        id: 0,
        user: {},
    };

    /**
     * Default state.
     *
     * @type {Object}
     */
    state = {
        showLogs: false,
        data: {},
        settings: {},
        loading: false,
    };

    /**
     * Komponenta bola pripojena.
     */
    componentDidMount() {
        const { id, onClose, setTitle, showSnackbar, changeProductData } = this.props;

        // Nacitame data produktu
        request(`/products/view/${id}`).then(response => {
            const { status, data } = response.data;

            if (status === 'error') {
                // Produkt neexistuje
                showSnackbar('error', __('Produkt neexistuje'));
                onClose();
                return;
            }

            // Nastavime title
            setTitle(data.name);

            // Zmenime data v tabulke
            changeProductData(id, data.data);

            this.setState({ data });
        });
    }

    /**
     * Event po zmene nastaveni.
     *
     * @param {string} name
     * @param {string} value
     */
    onChangeSetting(name, value) {
        const { settings } = this.state;

        this.setState({ settings: { ...settings, [name]: value } });
    }

    /**
     * Zavolame akciu.
     *
     * @param {string} name
     */
    callAction(name) {
        const { buttons, onClose } = this.props;

        // Zavrieme drawer
        onClose();

        // Zavolame callback
        buttons[name].callback();
    }

    /**
     * Zobrazime logy.
     */
    showLogs() {
        this.setState({ showLogs: true });
    }

    /**
     * Zobrazime produkt.
     */
    showProduct() {
        this.setState({ showLogs: false });
    }

    /**
     * Ulozenie.
     */
    save() {
        const { id, editProduct, showSnackbar } = this.props;
        const { settings } = this.state;

        if (this.isDemo()) {
            showSnackbar('error', __('Produkt nie je možné uložiť na DEMO účte'));
            return;
        }

        this.setState({ loading: true });

        // Editujeme produkt
        editProduct(this, id, settings);
    }

    /**
     * Rendrujeme hodnotu.
     *
     * @param {string} value
     *
     * @return {string}
     */
    renderValue(value) {
        return !_.isEmpty(value) ? value : '-';
    }

    /**
     * Rendrovanie.
     *
     * @return {JSX.Element}
     */
    render() {
        const { id, user } = this.props;
        const { data, settings, showLogs, loading } = this.state;

        if (_.isEmpty(data)) {
            // Data nie su nacitane
            return <div
                className="product-content product-content-loading"
            >{this.renderLoading()}</div>;
        }

        // Vytiahneme tlacitka pre produkt
        const buttons = getProductButtons(data, {
            hasPermissionDelete: this.hasPermission('products-delete'),
        });

        return (
            <div className="product-content">
                <div className="product-content__buttons">
                    <div className="product-content__buttons__left">
                        {!showLogs ? <Button
                            onClick={() => this.showLogs()}
                            color="shadow"
                            className=""
                        >{__('Zobraziť pohyby')}</Button> : null}
                        {showLogs ? <Button
                            onClick={() => this.showProduct()}
                            color="shadow"
                            className=""
                        >{__('Zobraziť produkt')}</Button> : null}
                    </div>
                    <div className="product-content__buttons__right">{_.map(buttons, ({ name, icon, callback, options }, key) => {
                        return (
                            <Tooltip title={!options.disabled ? name : ''} key={key}>
                                <IconButton
                                    onClick={() => this.callAction(key)}
                                    disabled={options.disabled}
                                    className={`product-content__buttons__right__button ${options.disabled ? 'disabled' : ''}`}
                                >{icon}</IconButton>
                            </Tooltip>
                        );
                    })}</div>
                </div>
                {!showLogs ? <div className="product-content__panels">
                    <div className="product-content__panels__panel">
                        <div className="product-content__panels__panel__photo">
                            {!_.isEmpty(data.data.img_url) ? <img src={data.data.img_url} alt={data.name} /> : <HelpIcon />}
                        </div>
                        <div className="product-content__panels__panel__barcode">
                            <Barcode value={data.data.ean} displayValue={false} />
                        </div>
                        <Button
                            onClick={() => window.open(data.data.url, '_blank')}
                            color="primary"
                            className="product-content__panels__panel__url"
                        >{__('Zobraziť v eshope')}</Button>
                        <div className="product-content__panels__panel__value">
                            <div className="product-content__panels__panel__value__label">{__('Číslo:')}</div>
                            <div className="product-content__panels__panel__value__value">{this.renderValue(data.data.number)}</div>
                        </div>
                        <div className="product-content__panels__panel__value">
                            <div className="product-content__panels__panel__value__label">{__('EAN:')}</div>
                            <div className="product-content__panels__panel__value__value">{this.renderValue(data.data.ean)}</div>
                        </div>
                        <div className="product-content__panels__panel__value">
                            <div className="product-content__panels__panel__value__label">{__('Výrobca:')}</div>
                            <div className="product-content__panels__panel__value__value">{this.renderValue(data.data.manufacturer)}</div>
                        </div>
                        <div className="product-content__panels__panel__value">
                            <div className="product-content__panels__panel__value__label">{__('Varianta:')}</div>
                            <div className="product-content__panels__panel__value__value">{this.renderValue(data.data.variant)}</div>
                        </div>
                    </div>
                    <div className="product-content__panels__panel">
                        <div className="product-content__panels__panel__settings">
                            <div className="product-content__panels__panel__settings__categories">
                                {_.map(data.data.category, category => {
                                    return (
                                        <Chip
                                            className="product-content__panels__panel__settings__categories__category"
                                            label={category}
                                            key={category}
                                        />
                                    );
                                })}
                            </div>
                            <Input
                                label={__('Názov')}
                                value={_.has(settings, 'name') ? settings.name : data.data.name}
                                onChange={value => this.onChangeSetting('name', value)}
                            />
                            <div className="input__label editor-label">{__('Popis')}</div>
                            <CKEditor
                                editor={Editor}
                                data={_.has(settings, 'description') ? settings.description : data.data.description}
                                onChange={(event, editor) => this.onChangeSetting('description', editor.getData())}
                            />
                            <Input
                                label={__('Cena')}
                                value={_.has(settings, 'total_price') ? settings.total_price : data.data.total_price}
                                onChange={value => this.onChangeSetting('total_price', value)}
                            />
                            {_.includes([6,10], toNumber(user.user_eshop_id)) ? <Input
                                label={__('Nákupná cena')}
                                value={_.has(settings, 'buy_price') ? settings.buy_price : data.data.buy_price}
                                onChange={value => this.onChangeSetting('buy_price', value)}
                            /> : null}
                            <Input
                                label={__('Stav na sklade')}
                                value={_.has(settings, 'stock') ? settings.stock : _.toString(toNumber(data.data.stock))}
                                onChange={value => this.onChangeSetting('stock', value)}
                                type="number"
                            />
                            <Input
                                label={__('Váha')}
                                value={_.has(settings, 'weight') ? settings.weight : data.data.weight}
                                onChange={value => this.onChangeSetting('weight', value)}
                            />
                        </div>
                        <Button
                            onClick={() => this.save()}
                            className="product-content__panels__panel__save"
                            color="green"
                            loading={loading}
                            disabled={_.isEmpty(settings)}
                        >{__('Uložiť')}</Button>
                    </div>
                </div> : <Logs itemType="product" itemId={id} />}
                {this.renderSnackbar()}
            </div>
        );
    }
}

export { Product };
