import _ from 'lodash';
import {
    FETCH_MTOKNA_DOG_PRODUCTS,
    CLEAN_MTOKNA_DOG_PRODUCTS,
    CHANGE_MTOKNA_DOG_PRODUCT,
    DELETE_MTOKNA_DOG_PRODUCT,
} from './types';
import { request, __ } from '../functions';

export const fetchMtoknaDogProducts = (screen, options = {}) => {
    const defaults = {
        page: 1,
        per_page: 0,
        filtered: {},
    };

    options = { ...defaults, ...options };

    let params = { page: options.page, per_page: options.per_page };

    // Nasetujeme filter
    _.forEach(options.filtered, (value, key) => {
        params = { ...params, ...{ [`filter_${key}`]: value } };
    });

    return dispatch => request('/mtokna/dogProducts', params).then(response => {
        const { data, sync } = response.data;
        const { setUser } = screen.props;

        // Nasetujeme user data
        setUser(sync.user);

        dispatch({ type: FETCH_MTOKNA_DOG_PRODUCTS, payload: data });
    });
};

export const changeMtoknaDogProduct = (screen, id, data) => {
    return dispatch => request(`/mtokna/editProductDog/${id}`, data, 'POST').then(response => {
        const { status } = response.data;

        if (status === 'error') {
            screen.showSnackbar('error', __('Nepodarilo sa zmeniť produkt'));
            return;
        }

        screen.showSnackbar('success', __('Produkt bol zmenený'));

        dispatch({ type: CHANGE_MTOKNA_DOG_PRODUCT, payload: { id, data } });
    });
};

export const deleteMtoknaDogProduct = (screen, id) => {
    return dispatch => request(`/mtokna/removeProductDog/${id}`).then(response => {
        const { status } = response.data;

        if (status === 'error') {
            screen.showSnackbar('error', __('Nepodarilo sa zmazať produkt'));
            return;
        }

        screen.showSnackbar('success', __('Produkt bol zmazaný'));

        dispatch({ type: DELETE_MTOKNA_DOG_PRODUCT });
    });
};

export const cleanMtoknaDogProducts = () => ({ type: CLEAN_MTOKNA_DOG_PRODUCTS });
