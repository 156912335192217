import _ from 'lodash';
import {
    FETCH_MTOKNA_CATEGORIES,
    CLEAN_MTOKNA_CATEGORIES,
    DELETE_MTOKNA_CATEGORY,
    CHANGE_MTOKNA_CATEGORY,
    CHANGE_MTOKNA_CATEGORY_STATUS,
} from './types';
import {request, __} from '../functions';

export const fetchMtoknaCategories = (screen, options = {}) => {
    const defaults = {
        page: 1,
        per_page: 0,
        filtered: {},
        callback: null,
    };

    options = { ...defaults, ...options };

    let params = { page: options.page, per_page: options.per_page };

    // Nasetujeme filter
    _.forEach(options.filtered, (value, key) => {
        params = { ...params, ...{ [`filter_${key}`]: value } };
    });

    return dispatch => request('/mtokna/categories', params).then(response => {
        const { data, sync } = response.data;
        const { setUser } = screen.props;

        if (options.callback !== null) {
            // Zavolame callback
            options.callback();
        }

        // Nasetujeme user data
        setUser(sync.user);

        dispatch({ type: FETCH_MTOKNA_CATEGORIES, payload: data });
    });
};

export const cleanMtoknaCategories = () => ({ type: CLEAN_MTOKNA_CATEGORIES });

export const changeMtoknaCategory = (screen, id, data) => {
    return dispatch => request(`/mtokna/editCategory/${id}`, { data }, 'POST').then(response => {
        const { status } = response.data;

        if (status === 'error') {
            screen.showSnackbar('error', __('Nepodarilo sa zmeniť kategóriu'));
            return;
        }

        screen.showSnackbar('success', __('Kategória bola zmenená'));

        dispatch({ type: CHANGE_MTOKNA_CATEGORY, payload: { id, data } });
    });
};

export const changeMtoknaCategoryStatus = (screen, id, category_status) => {
    return dispatch => request(`/mtokna/changeCategoryStatus/${id}/${category_status}`).then(response => {
        const { status } = response.data;

        if (status === 'error') {
            screen.showSnackbar('error', __('Nepodarilo sa zmeniť kategóriu'));
            return;
        }

        screen.showSnackbar('success', __('Kategória bola zmenená'));

        dispatch({ type: CHANGE_MTOKNA_CATEGORY_STATUS, payload: { id, status: category_status } });
    });
};

/**
 * Zmazanie objednavky.
 *
 * @param {Screen} screen
 * @param {number} id
 */
export const deleteMtoknaCategory = (screen, id) => {
    return dispatch => request(`/mtokna/removeCategory/${id}`).then(response => {
        const { status } = response.data;

        if (status === 'error') {
            // Objednavku sa nepodarilo zmazat
            screen.showSnackbar('error', __('Kategóriu sa nepodarilo zmazať'));
            return;
        }

        screen.showSnackbar('success', __('Kategória bola zmazaná'));

        dispatch({ type: DELETE_MTOKNA_CATEGORY });
    });
};
