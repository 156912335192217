import React from 'react';
import { connect } from 'react-redux';
import { withCookies } from 'react-cookie';
import _ from 'lodash';
import DeleteIcon from '@mui/icons-material/Delete';
import DownloadIcon from '@mui/icons-material/Print';
import PaidIcon from '@mui/icons-material/MonetizationOn';
import Apps from '../components/Apps';
import { ListScreen, Select, Button, ContentPart, Input } from '../components';
import {
    fetchLabels,
    cleanLabels,
    setUser,
    deleteLabel,
    downloadLabel,
} from '../actions';
import { __, formatDate, plural, toNumber, request } from '../functions';
import { IMAGES, COURIER_COLORS } from '../config';
import '../assets/styles/labels.css';

/**
 * Stitky.
 */
class LabelsScreen extends ListScreen {
    /**
     * Title.
     *
     * @type {string}
     */
    title = __('Prepravcovia');

    /**
     * Default state.
     *
     * @type {Object}
     */
    state = { ...ListScreen.state, ...{
        settingsLoading: false,
        settings: {},
        lightbox: {
            delete: false,
        },
    }};

    /**
     * Je validne pravo?
     *
     * @return {boolean}
     */
    isValidPermission() {
        return this.hasPermission('labels') || this.hasPermission('settings-couriers');
    }

    /**
     * Vratime zoznam tagov.
     *
     * @return {Array}
     */
    getTags() {
        return [
            { name: __('Štítky budú automatické zmazané po 7 dňoch od vytvorenia.') },
        ];
    }

    /**
     * Vratime nazov tabulky.
     *
     * @return {string}
     */
    getTableName() {
        return 'labels';
    }

    /**
     * Vratime empty text.
     *
     * @return {string}
     */
    getEmptyText() {
        return __('Zatiaľ nemáte žiadne štítky');
    }

    /**
     * Zobrazime lightbox na zmazanie.
     *
     * @param {number} id
     * @param {function} callbackLoading
     * @param {function} callback
     */
    confirmDelete(id, callbackLoading, callback) {
        this.showLightbox('delete', { id, callbackLoading, callback });
    }

    /**
     * Stiahneme stitky.
     *
     * @param {int} id
     */
    download(id) {
        const { items, downloadLabel } = this.props;

        window.location = `${items.host}/user-labels/download/${id}?token=${global.token}`;

        downloadLabel(id);
    }

    /**
     * Zmazanie stitku.
     */
    async delete() {
        const { deleteLabel, items } = this.props;
        const { lightbox } = this.state;

        // Zavolame loading
        lightbox.delete.callbackLoading();

        // Zavrieme lightbox
        this.closeLightbox('delete');

        // Zmazeme stitku
        await deleteLabel(this, lightbox.delete.id);

        // Zavolame callback
        lightbox.delete.callback();

        // Znovu nacitame zoznam od prvej stranky
        this.fetchList(1, 0, items.filtered);
    }

    /**
     * Event po zmene nastaveni.
     *
     * @param {string} name
     * @param {string|number} value
     */
    onChangeSetting(name, value) {
        const { settings } = this.state;

        this.setState({ settings: { ...settings, [name]: value } });
    }

    /**
     * Ulozime nastavenia.
     */
    saveSettings() {
        const { items } = this.props;
        let { settings } = this.state;

        this.setState({ settingsLoading: true });

        settings = { ...items.settings, ...settings };
        settings = { ...settings, min_weight: _.toString(settings.min_weight) };

        request('/user-eshops/changeCourierSettings', { settings }, 'POST').then(response => {
            const { status } = response.data;

            this.setState({ settingsLoading: false, settingsCustomOpened: false });

            if (status === 'error') {
                // Nepodarilo sa zmenit nastavenia
                this.showSnackbar('error', __('Nepodarilo sa zmeniť nastavenia'));
                return;
            }

            this.showSnackbar('success', __('Nastavenia boli zmenené'));
        });
    }

    /**
     * Rendrujeme polozku gridu.
     *
     * @param {Object} item
     *
     * @return {JSX.Element|null}
     */
    renderGridItem(item) {
        const { items } = this.props;

        // Pocet objednavok
        const orders = _.keys(item.orders).length;

        let buttons = {
            download: item.status === 'unpaid' ? {
                name: __('Uhradiť'),
                icon: <PaidIcon color="secondary" />,
                callback: () => window.open(item.url, '_blank'),
                options: {},
            } : {
                name: __('Stiahnuť'),
                icon: <DownloadIcon color={item.status === 'newly' ? 'secondary' : 'inherit'} />,
                callback: () => this.download(item.id),
                options: {
                    disabled: item.status === 'waiting',
                },
            },
            delete: {
                name: __('Zmazať'),
                icon: <DeleteIcon />,
                callback: (callbackLoading, callback) => this.confirmDelete(item.id, callbackLoading, callback),
                options: {},
            },
        };

        if (!this.hasPermission('labels-pdf')) {
            // Nema pravo
            buttons = _.omit(buttons, ['download']);
        }

        if (!this.hasPermission('labels-delete')) {
            // Nema pravo
            buttons = _.omit(buttons, ['delete']);
        }

        return (
            <div className="labels-grid__item">
                <div className="labels-grid__item__header">
                    <div className="labels-grid__item__header__title">{orders} {plural(orders, [
                        __('objednávka'),
                        __('objednávky'),
                        __('objednávok'),
                    ])}</div>
                    <div className="labels-grid__item__header__buttons">
                        {this.renderGridButtons(item.id, buttons)}
                    </div>
                </div>
                <div className="labels-grid__item__content">
                    {_.has(item.data, 'couriers') ? _.map(item.data.couriers, courierId => {
                        // Vytiahneme nazov kuriera
                        const courierName = items.couriers[toNumber(courierId)];

                        return (
                            <div
                                className="labels-grid__item__content__image"
                                style={{ backgroundColor: COURIER_COLORS[courierName].background }}
                                key={courierId}
                            >
                                <img src={IMAGES[`${courierName.toLowerCase()}.png`]} alt={courierName} />
                            </div>
                        );
                    }) : null}
                </div>
                <div className="labels-grid__item__footer">
                    {`${__('Vytvorené')} ${formatDate(item.created, 'dd.mm.yyyy hh:ii')}`}
                </div>
            </div>
        );
    }

    /**
     * Rendrujeme grid.
     *
     * @return {JSX.Element|null}
     */
    renderGrid() {
        if (!this.hasPermission('labels')) {
            // Nema pravo
            return null;
        }

        return super.renderGrid();
    }

    /**
     * Render after header.
     *
     * @return {JSX.Element|null}
     */
    renderAfterHeader() {
        return (
            <div className="labels-after-header">
                {this.hasPermission('settings-couriers') ? <Apps type="couriers" { ...this.props.items } /> : null}
                {this.hasPermission('labels') ? <ContentPart title={__('Štítky')} /> : null}
            </div>
        );
    }

    /**
     * Mame vlastne custom settingy.
     *
     * @return {boolean}
     */
    hasCustomSettings() {
        return this.hasPermission('settings-couriers');
    }

    /**
     * Render vlastnych settingov.
     *
     * @return {JSX.Element|null}
     */
    renderCustomSettings() {
        const { user, items } = this.props;
        const { settings, settingsLoading } = this.state;

        const userEshopId = toNumber(user.user_eshop_id);

        return (
            <div className="labels-settings">
                <div className="labels-settings__list">
                    <Select
                        label={__('Údaj objednávky kde sa má uložit číslo zásielky')}
                        options={items.eshop_data.placeholder_fields}
                        value={_.has(settings, 'placeholder_field') ? settings.placeholder_field : items.settings.placeholder_field}
                        onChange={value => this.onChangeSetting('placeholder_field', value)}
                    />
                    <Select
                        label={__('Predvolený dopravca v rozpise zásielok')}
                        options={items.user_couriers_list}
                        value={_.has(settings, 'default_courier') ? settings.default_courier : items.settings.default_courier}
                        onChange={value => this.onChangeSetting('default_courier', value)}
                    />
                    <Input
                        label={__('Minimálna váha')}
                        value={_.has(settings, 'min_weight') ? settings.min_weight : items.settings.min_weight}
                        onChange={value => this.onChangeSetting('min_weight', value)}
                    />
                </div>
                <Button
                    onClick={() => this.saveSettings()}
                    loading={settingsLoading}
                    disabled={_.isEmpty(settings) || userEshopId === 0}
                    color="green"
                >{__('Uložiť')}</Button>
            </div>
        );
    }

    /**
     * Rendrujeme screen.
     *
     * @return {JSX.Element|null}
     */
    renderScreen() {
        return (
            <div>
                {this.renderLightbox(
                    'delete',
                    __('Naozaj chcete zmazať tento štítok?'),
                    null,
                    __('Áno'),
                    __('Nie'),
                    () => this.delete()
                )}
            </div>
        );
    }
}

const stateToProps = ({ labels, user }) => ({ items: labels, user });

export default withCookies(connect(stateToProps, {
    fetch: fetchLabels,
    clean: cleanLabels,
    setUser,
    deleteLabel,
    downloadLabel,
})(LabelsScreen));
