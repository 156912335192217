import _ from 'lodash';
import { toNumber } from './toNumber';

/**
 * Zaokruhlime cislo na x desatinnych miest.
 *
 * @param {mixed} number
 * @param {number} decimals
 * @returns {number}
 */
export const round = (number, decimals = 2) => toNumber(_.round(toNumber(number), decimals));