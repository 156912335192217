import React from 'react';
import _ from 'lodash';
import { Stepper, Step, StepLabel, Tab, Tabs } from '@mui/material';
import HelpIcon from '@mui/icons-material/Help';
import PaymentIcon from '@mui/icons-material/MonetizationOn';
import DeliveryIcon from '@mui/icons-material/LocalShipping';
import { Button, Input, Message, Navigate, Select } from '../components';
import { formatAmount, formatDate, isEmptyString, request, toNumber } from '../functions';
import { COMPLAINT_TYPES, IMAGES } from '../config';
import '../assets/styles/order-page.css';

/**
 * OrderPage komponenta.
 */
class OrderPage extends Navigate {
    /**
     * Default state.
     *
     * @type {Object}
     */
    state = {
        translate: null,
        tab: 'delivery',
        loadingCancel: false,
        loadingComplaint: false,
        lightbox: {
            cancel: false,
            complaint: false,
            complaintComment: false,
        },
    };

    /**
     * Preklady.
     *
     * @type {Object}
     */
    translations = {
        sk: {
            name: 'Slovensky',
            words: {},
        },
        cz: {
            name: 'Česky',
            words: {
                'Opýtať sa na stav': 'Zeptat se na stav',
                'Objednávka č.': 'Objednávka č.',
                'Uhradené': 'Uhrazeno',
                'Neuhradené': 'Neuhrazeno',
                'Faktúra č.': 'Faktura č.',
                'Stiahnuť': 'Stáhnout',
                'Zásielka č.': 'Zásilka č.',
                'Sledovať': 'Sledovat',
                'Stornovať': 'Stornovat',
                'Reklamovať': 'Reklamovat',
                'Fakturačná adresa': 'Fakturační adresa',
                'IČO': 'IČ',
                'DIČ': 'DIČ',
                'IČ DPH': 'IČ DPH',
                'Dodacia adresa': 'Dodací adresa',
                'Tovar v objednávke': 'Zboží v objednávce',
                'Číslo produktu': 'Číslo produktu',
                'EAN': 'EAN',
                'Rezervované': 'Rezervováno',
                'bez DPH': 'bez DPH',
                'Zľava': 'Sleva',
                'Vytvorené': 'Vytvořeno',
                'Celkom': 'Celkem',
                'Nepodarilo sa stiahnuť faktúru': 'Nepodařilo se stáhnout fakturu',
                'Prijatá': 'Přijata',
                'Spracováva sa': 'Zpracovává se',
                'Odoslaná': 'Odesláno',
                'Doručená': 'Doručena',
                'Nepodarilo sa stornovať objednávku': 'Nepodařilo se stornovat objednávku',
                'Objednávka bola stornovaná': 'Objednávka byla stornována',
                'Naozaj chcete stornovať túto objednávku?': 'Opravdu chcete stornovat tuto objednávku?',
                'Áno': 'Ano',
                'Nie': 'Ne',
                'Stornovaná': 'Stornována',
                'Reklamácia': 'Reklamace',
                'Žiadosť zákaznika na vybavenie': 'Žádost zákazníka na vybavení',
                'Popis': 'Popis',
                'Nižšie prosím vyberte konkrétne produkty a počty kusov': 'Níže prosím vyberte konkrétní produkty a počty kusů',
                'Odoslať': 'Odeslat',
                'Prosím vyplňte popis reklamácie': 'Prosím vyplňte popis reklamace',
                'Prosím zadajte aspoň jeden produkt': 'Prosím zadejte alespoň jeden produkt',
                'Nepodarilo sa reklamovať objednávku': 'Nepodařilo se reklamovat objednávku',
                'Objednávka bola reklamovaná': 'Objednávka byla reklamována',
                'Spracovávame reklamáciu č.': 'Zpracováváme reklamaci č.',
                'Reklamácia bola vyriešená': 'Reklamace byla vyřešena',
                'Pridať vyjadrenie': 'Přidat vyjádření',
                'Vyjadrenie k reklamácii': 'Vyjádření k reklamaci',
                'Vyjadrenie': 'Vyjádření',
                'Prosím vyplňte vyjadrenie k reklamácii': 'Prosím vyplňte vyjádření k reklamaci',
                'Nepodarilo sa uložiť vyjadrenie': 'Nepodařilo se uložit vyjádření',
                'Vyjadrenie bolo uložené': 'Vyjádření bylo uloženo',
            },
        },
        pl: {
            name: 'Polski',
            words: {
                'Opýtať sa na stav': 'Zapytaj o status',
                'Objednávka č.': 'Zamów nr.',
                'Uhradené': 'Płatny',
                'Neuhradené': 'Wybitny',
                'Faktúra č.': 'Faktury nr.',
                'Stiahnuť': 'Ściągnij',
                'Zásielka č.': 'Nr przesyłki',
                'Sledovať': 'Zegarek',
                'Stornovať': 'Anulować',
                'Reklamovať': 'Reklamować',
                'Fakturačná adresa': 'Adres rozliczeniowy',
                'IČO': 'REGON',
                'DIČ': 'NIP',
                'IČ DPH': 'NR VAT',
                'Dodacia adresa': 'Adres dostawy',
                'Tovar v objednávke': 'Towar na zamówienie',
                'Číslo produktu': 'Numer produktu',
                'EAN': 'EAN',
                'Rezervované': 'Skryty',
                'bez DPH': 'bez VAT',
                'Zľava': 'Zniżka',
                'Vytvorené': 'Utworzony',
                'Celkom': 'W sumie',
                'Nepodarilo sa stiahnuť faktúru': 'Nie udało się pobrać faktury',
                'Prijatá': 'Przyjęty',
                'Spracováva sa': 'Jest przetwarzany',
                'Odoslaná': 'Wysłano',
                'Doručená': 'Dostarczony',
                'Nepodarilo sa stornovať objednávku': 'Nie można anulować zamówienia',
                'Objednávka bola stornovaná': 'Zamówienie zostało anulowane',
                'Naozaj chcete stornovať túto objednávku?': 'Czy na pewno chcesz anulować to zamówienie?',
                'Áno': 'Tak',
                'Nie': 'Nie',
                'Stornovaná': 'Anulowany',
                'Reklamácia': 'Skarga',
                'Žiadosť zákaznika na vybavenie': 'Żądanie klienta dotyczące sprzętu',
                'Popis': 'Opis',
                'Nižšie prosím vyberte konkrétne produkty a počty kusov': 'Wybierz poniżej konkretne produkty i ilości',
                'Odoslať': 'Wysyłać',
                'Prosím vyplňte popis reklamácie': 'Prosimy o wypełnienie opisu reklamacji',
                'Prosím zadajte aspoň jeden produkt': 'Podaj co najmniej jeden produkt',
                'Nepodarilo sa reklamovať objednávku': 'Nie udało się odebrać zamówienia',
                'Objednávka bola reklamovaná': 'Zamówienie zostało odebrane',
                'Spracovávame reklamáciu č.': 'Rozpatrujemy roszczenie nr.',
                'Reklamácia bola vyriešená': 'Reklamacja została rozwiązana',
                'Pridať vyjadrenie': 'Dodaj komentarz',
                'Vyjadrenie k reklamácii': 'Oświadczenie w sprawie reklamacji',
                'Vyjadrenie': 'Wyrażenie',
                'Prosím vyplňte vyjadrenie k reklamácii': 'Prosimy o wypełnienie zgłoszenia reklamacyjnego',
                'Nepodarilo sa uložiť vyjadrenie': 'Nie udało się zapisać wyciągu',
                'Vyjadrenie bolo uložené': 'Oświadczenie zostało zapisane',
            },
        },
        hu: {
            name: 'Magyar',
            words: {
                'Opýtať sa na stav': 'Kérdezzen az állapotról',
                'Objednávka č.': 'Megrendelés sz.',
                'Uhradené': 'Fizetett',
                'Neuhradené': 'Kiemelkedő',
                'Faktúra č.': 'Nincs számla.',
                'Stiahnuť': 'Letöltés',
                'Zásielka č.': 'Szállítmány sz.',
                'Sledovať': 'Néz',
                'Stornovať': 'Megszünteti',
                'Reklamovať': 'Hirdet',
                'Fakturačná adresa': 'Számlázási cím',
                'IČO': 'Cégj. szám',
                'DIČ': 'Adószám',
                'IČ DPH': 'EU adószám',
                'Dodacia adresa': 'Szállítási cím',
                'Tovar v objednávke': 'Áruk rendelésre',
                'Číslo produktu': 'Termék szám',
                'EAN': 'EAN',
                'Rezervované': 'Fenntartott',
                'bez DPH': 'ÁFA nélkül',
                'Zľava': 'Kedvezmény',
                'Vytvorené': 'Létrehozva',
                'Celkom': 'Összegezve',
                'Nepodarilo sa stiahnuť faktúru': 'Nem sikerült letölteni a számlát',
                'Prijatá': 'Elfogadott',
                'Spracováva sa': 'Feldolgozás alatt áll',
                'Odoslaná': 'Küldött',
                'Doručená': 'Szállítva',
                'Nepodarilo sa stornovať objednávku': 'A rendelést nem lehet törölni',
                'Objednávka bola stornovaná': 'A rendelést törölték',
                'Naozaj chcete stornovať túto objednávku?': 'Biztosan törli ezt a rendelést?',
                'Áno': 'Igen',
                'Nie': 'Nem',
                'Stornovaná': 'Törölve',
                'Reklamácia': 'Panasz',
                'Žiadosť zákaznika na vybavenie': 'Vevői igény felszerelésre',
                'Popis': 'Leírás',
                'Nižšie prosím vyberte konkrétne produkty a počty kusov': 'Kérjük, az alábbiakban válasszon konkrét termékeket és mennyiségeket',
                'Odoslať': 'Küldeni',
                'Prosím vyplňte popis reklamácie': 'Kérjük, töltse ki a panasz leírását',
                'Prosím zadajte aspoň jeden produkt': 'Kérjük, adjon meg legalább egy terméket',
                'Nepodarilo sa reklamovať objednávku': 'A megrendelés igénylése nem sikerült',
                'Objednávka bola reklamovaná': 'A megrendelést igényelték',
                'Spracovávame reklamáciu č.': 'Számú követelést dolgozunk fel.',
                'Reklamácia bola vyriešená': 'A panaszt megoldották',
                'Pridať vyjadrenie': 'Hozzászólni',
                'Vyjadrenie k reklamácii': 'Nyilatkozat a panaszról',
                'Vyjadrenie': 'Kifejezés',
                'Prosím vyplňte vyjadrenie k reklamácii': 'Kérjük, töltse ki a panasznyilatkozatot',
                'Nepodarilo sa uložiť vyjadrenie': 'Nem sikerült menteni a nyilatkozatot',
                'Vyjadrenie bolo uložené': 'A nyilatkozatot elmentettük',
            },
        },
        en: {
            name: 'English',
            words: {
                'Opýtať sa na stav': 'Ask about status',
                'Objednávka č.': 'Order no.',
                'Uhradené': 'Paid',
                'Neuhradené': 'Outstanding',
                'Faktúra č.': 'Invoice no.',
                'Stiahnuť': 'Download',
                'Zásielka č.': 'Shipment no.',
                'Sledovať': 'Watch',
                'Stornovať': 'Cancel',
                'Reklamovať': 'Advertise',
                'Fakturačná adresa': 'Billing address',
                'IČO': 'ID',
                'DIČ': 'Tax ID',
                'IČ DPH': 'VAT ID',
                'Dodacia adresa': 'Shipping address',
                'Tovar v objednávke': 'Goods on order',
                'Číslo produktu': 'Product number',
                'EAN': 'EAN',
                'Rezervované': 'Reserved',
                'bez DPH': 'without VAT',
                'Zľava': 'Discount',
                'Vytvorené': 'Created',
                'Celkom': 'Total',
                'Nepodarilo sa stiahnuť faktúru': 'Failed to download invoice',
                'Prijatá': 'Accepted',
                'Spracováva sa': 'In process',
                'Odoslaná': 'Sent',
                'Doručená': 'Delivered',
                'Nepodarilo sa stornovať objednávku': 'Unable to cancel order',
                'Objednávka bola stornovaná': 'The order has been cancelled',
                'Naozaj chcete stornovať túto objednávku?': 'Are you sure you want to cancel this order?',
                'Áno': 'Yes',
                'Nie': 'No',
                'Stornovaná': 'Cancelled',
                'Reklamácia': 'Complaint',
                'Žiadosť zákaznika na vybavenie': 'Customer request for equipment',
                'Popis': 'Description',
                'Nižšie prosím vyberte konkrétne produkty a počty kusov': 'Please select specific products and quantities below',
                'Odoslať': 'Send',
                'Prosím vyplňte popis reklamácie': 'Please fill in the complaint description',
                'Prosím zadajte aspoň jeden produkt': 'Please enter at least one product',
                'Nepodarilo sa reklamovať objednávku': 'Failed to claim the order',
                'Objednávka bola reklamovaná': 'The order has been claimed',
                'Spracovávame reklamáciu č.': 'We are processing claim no.',
                'Reklamácia bola vyriešená': 'The complaint has been resolved',
                'Pridať vyjadrenie': 'Add a statement',
                'Vyjadrenie k reklamácii': 'Statement on the complaint',
                'Vyjadrenie': 'Expression',
                'Prosím vyplňte vyjadrenie k reklamácii': 'Please fill in the complaint statement',
                'Nepodarilo sa uložiť vyjadrenie': 'Failed to save statement',
                'Vyjadrenie bolo uložené': 'The statement has been saved',
            },
        },
    };

    /**
     * Vratime aktualny preklad.
     *
     * @return {string}
     */
    getTranslate() {
        const { translate } = this.state;
        const { data } = this.props;

        if (translate === null) {
            // Default podla dodania objednavky
            const translations = this.getTranslations();
            const deliveryCountry = data.order.client.delivery_country.toLowerCase();

            return _.has(translations, deliveryCountry) ? deliveryCountry : 'en';
        }

        return translate;
    }

    /**
     * Vratime preklady.
     *
     * @return {{}}
     */
    getTranslations() {
        return _.reduce(this.translations, (result, settings, country) => {
            return { ...result, [country]: settings.name };
        }, {})
    }

    /**
     * Preklad.
     *
     * @param {string} text
     *
     * @return {string}
     */
    translate(text) {
        const translate = this.getTranslate();
        const { words } = this.translations[translate];

        return _.has(words, text) ? words[text] : text;
    }

    /**
     * Event po zmene reklamacie.
     *
     * @param {string} name
     * @param {string|Object} value
     */
    onChangeComplaint(name, value) {
        const { lightbox } = this.state;

        this.setState({ lightbox: { ...lightbox, complaint: { ...lightbox.complaint, [name]: value } } });
    }

    /**
     * Event po zmene reklamacie.
     *
     * @param {string} name
     * @param {string|Object} value
     */
    onChangeComplaintComment(name, value) {
        const { lightbox } = this.state;

        this.setState({ lightbox: { ...lightbox, complaintComment: { ...lightbox.complaintComment, [name]: value } } });
    }

    /**
     * Event po zmene reklamacie.
     *
     * @param {number} id
     * @param {number} value
     * @param {number} max
     */
    onChangeComplaintProduct(id, value, max) {
        const { lightbox } = this.state;

        this.onChangeComplaint('products', { ...lightbox.complaint.products, [id]: Math.max(Math.min(toNumber(value), max), 0) });
    }

    /**
     * Stiahneme PDF.
     */
    downloadPdf() {
        const { data } = this.props;

        request(`/track-trace/pdf/${data.id}/${data.token}`).then(response => {
            const { status, data } = response.data;

            if (status === 'error') {
                this.showSnackbar('error', this.translate('Nepodarilo sa stiahnuť faktúru'));
                return;
            }

            if (data.link.indexOf('inline') !== -1) {
                // Chceme inline
                window.open(data.link, '_blank');
                return;
            }

            // Stiahneme subor
            window.location = data.link;
        });
    }

    /**
     * Storno.
     */
    cancel() {
        const { data } = this.props;

        this.closeLightbox('cancel');

        this.setState({ loadingCancel: true });

        request(`/track-trace/cancel/${data.id}/${data.token}`).then(response => {
            const { status } = response.data;

            this.setState({ loadingCancel: false });

            if (status === 'error') {
                this.showSnackbar('error', this.translate('Nepodarilo sa stornovať objednávku'));
                return;
            }

            this.showSnackbar('success', this.translate('Objednávka bola stornovaná'));

            setTimeout(() => window.location.reload(), 200);
        });
    }

    /**
     * Ulozime reklamaciu.
     */
    saveComplaint() {
        const { data } = this.props;
        const { lightbox } = this.state;

        // Celkovky pocet ks
        const totalQuantity = _.reduce(lightbox.complaint.products, (result, quantity) => {
            return result + toNumber(quantity);
        }, 0);

        let error = null;

        if (isEmptyString(lightbox.complaint.text)) {
            // Nie je vyplneny popis
            error = this.translate('Prosím vyplňte popis reklamácie');
        } else if (totalQuantity === 0) {
            error = this.translate('Prosím zadajte aspoň jeden produkt');
        }

        if (error !== null) {
            this.showSnackbar('error', error);
            return;
        }

        this.setState({ loadingComplaint: true });

        request(`/track-trace/complaint/${data.id}/${data.token}`, lightbox.complaint, 'POST').then(response => {
            const { status } = response.data;

            this.setState({ loadingComplaint: false });

            if (status === 'error') {
                this.showSnackbar('error', this.translate('Nepodarilo sa reklamovať objednávku'));
                return;
            }

            this.showSnackbar('success', this.translate('Objednávka bola reklamovaná'));

            setTimeout(() => window.location.reload(), 200);
        });
    }

    /**
     * Ulozime reklamaciu.
     */
    saveComplaintComment() {
        const { data } = this.props;
        const { lightbox } = this.state;

        let error = null;

        if (isEmptyString(lightbox.complaintComment.text)) {
            // Nie je vyplneny popis
            error = this.translate('Prosím vyplňte vyjadrenie k reklamácii');
        }

        if (error !== null) {
            this.showSnackbar('error', error);
            return;
        }

        this.setState({ loadingComplaint: true });

        request(`/track-trace/complaint-comment/${data.id}/${data.token}`, lightbox.complaintComment, 'POST').then(response => {
            const { status } = response.data;

            this.setState({ loadingComplaint: false });

            if (status === 'error') {
                this.showSnackbar('error', this.translate('Nepodarilo sa uložiť vyjadrenie'));
                return;
            }

            this.showSnackbar('success', this.translate('Vyjadrenie bolo uložené'));

            setTimeout(() => window.location.reload(), 200);
        });
    }

    /**
     * Rendrovanie.
     *
     * @return {JSX.Element|null}
     */
    render() {
        const { data } = this.props;
        const { tab, loadingCancel, loadingComplaint, lightbox } = this.state;

        const steps = {
            confirm: this.translate('Prijatá'),
            processing: this.translate('Spracováva sa'),
            send: this.translate('Odoslaná'),
            delivered: this.translate('Doručená'),
        };

        // Preklady
        const translateOptions = this.getTranslations();

        // Aktualny preklad
        const translate = this.getTranslate();

        // Vytiahneme DPHcki
        const taxes = _.reduce(data.order.products, (result, product) => {
            return [ ...result, toNumber(product.tax) ];
        }, []);

        // Vytiahneme DPH
        const tax = taxes.length > 0 ? Math.max(Math.max(taxes), 20) : 0;

        // Dodanie bez DPH
        const deliveryAmountWithoutVat = data.order.delivery_type_amount * (100 / (100 + tax));

        // Platba bez DPH
        const paymentAmountWithoutVat = data.order.payment_type_amount * (100 / (100 + tax));

        // Diff bez DPH
        const diffAmountWithoutVat = data.order.amount_diff * (100 / (100 + tax));

        // Celkova suma bez DPH
        let totalWithoutVat = deliveryAmountWithoutVat + paymentAmountWithoutVat + diffAmountWithoutVat;

        let step = 0;
        let text = '';

        _.each(data.settings.states, ({ states, texts }, state) => {
            if (states.indexOf('===') !== -1) {
                states = _.split(states, '===');
            } else {
                states = [states];
            }

            if (_.includes(states, data.order.state.id)) {
                step = toNumber(_.invert(_.keys(data.settings.states))[state]);
                text = _.has(texts, translate) && texts[translate] !== '' ? texts[translate] : '';
            }
        });

        // Je zrusena
        const isCanceled = data.order.state.id === data.settings.cancel_state;

        // Ma reklamaciu?
        const hasComplaint = !_.isEmpty(data.complaint);

        return (
            <div className="order-page">
                <div className="order-page__eshop">
                    <Select
                        options={translateOptions}
                        value={translate}
                        onChange={value => this.setState({ translate: value })}
                        allowEmpty={false}
                        countries
                    />
                    {data.logo !== '' ? <img src={`${data.logo}?v=${Math.random()}`} alt="eshop logo" /> : null}
                    {data.settings.contact !== '' ? <Button
                        onClick={() => window.open(`mailto:${data.settings.contact}`, '_blank')}
                        color="shadow"
                    >{this.translate('Opýtať sa na stav')}</Button> : null}
                </div>
                <div className="order-page__header">
                    <div className="order-page__header__title">{`${this.translate('Objednávka č.')} ${data.order.number}`}</div>
                    <Stepper activeStep={isCanceled ? 1 : step} className="order-page__header__states">
                        {_.map(isCanceled ? { confirm: this.translate('Prijatá'), canceled: this.translate('Stornovaná') } : steps, (step, key) => {
                            return (
                                <Step key={key}>
                                    <StepLabel>{step}</StepLabel>
                                </Step>
                            );
                        })}
                    </Stepper>
                </div>
                <div className="order-page__text">{text}</div>
                <div className="order-page__info">
                    <div className="order-page__info__panel">
                        <div className={`order-page__info__panel__paid ${data.order.paid ? 'paid' : ''}`}>
                            <div className="order-page__info__panel__paid__icon">
                                <div className="order-page__info__panel__paid__icon__dot" />
                            </div>
                            <div className="order-page__info__panel__paid__name">{this.translate(data.order.paid ? 'Uhradené' : 'Neuhradené')}</div>
                        </div>
                        {!_.isEmpty(data.invoice) ? <div className="order-page__info__panel__invoice">
                            <img src={IMAGES['pdf.png']} alt="pdf" />
                            <div className="order-page__info__panel__invoice__name">{`${this.translate('Faktúra č.')} ${data.invoice.number}`}</div>
                            <a onClick={() => this.downloadPdf()}>{this.translate('Stiahnuť')}</a>
                        </div> : null}
                        {!_.isEmpty(data.pdf_link) ? <div className="order-page__info__panel__invoice">
                            <img src={IMAGES['pdf.png']} alt="pdf" />
                            <div className="order-page__info__panel__invoice__name">{this.translate('Faktúra')}</div>
                            <a onClick={() => window.open(data.pdf_link, '_blank')}>{this.translate('Stiahnuť')}</a>
                        </div> : null}
                        {!_.isEmpty(data.package.number) ? <div className="order-page__info__panel__package">
                            <img src={IMAGES[`${data.package.courier.toLowerCase()}-mini.png`]} alt={data.package.courier} />
                            <div className="order-page__info__panel__package__name">{`${this.translate('Zásielka č.')} ${data.package.number}`}</div>
                            <a href={data.package.url} target="_blank">{this.translate('Sledovať')}</a>
                        </div> : null}
                        <div className="order-page__info__panel__buttons">
                            {data.settings.cancel && data.settings.cancel_state !== '' && step === 0 && !isCanceled && !hasComplaint ? <Button
                                onClick={() => this.showLightbox('cancel')}
                                color="red"
                                loading={loadingCancel}
                            >{this.translate('Stornovať')}</Button> : null}
                            {data.settings.complaints && !isCanceled && !hasComplaint ? <Button
                                onClick={() => this.showLightbox('complaint', {
                                    type: _.keys(COMPLAINT_TYPES)[0],
                                    text: '',
                                    products: _.reduce(data.order.products, (result, { id }) => ({ ...result, [id]: '' }), {}),
                                })}
                                color="shadow"
                            >{this.translate('Reklamovať')}</Button> : null}
                            {hasComplaint ? <div className="order-page__info__panel__complaint">
                                {data.complaint.status === 'waiting' ? <Message
                                    type="warning"
                                >{`${this.translate('Spracovávame reklamáciu č.')} ${data.complaint.number}`}</Message> : null}
                                {data.complaint.status === 'processed' ? <Message
                                    type="success"
                                >{this.translate('Reklamácia bola vyriešená')}</Message> : null}
                                <div className="order-page__info__panel__complaint__text">
                                    <div className="order-page__info__panel__complaint__text__date">{formatDate(data.complaint.status === 'waiting' ? data.complaint.date : data.complaint.end_date, 'dd.mm.yyyy')}</div>
                                    <div>{data.complaint.status === 'waiting' ? data.complaint.text : data.complaint.end_text}</div>
                                </div>
                                {data.complaint.end_comment !== '' ? <div className="order-page__info__panel__complaint__text">
                                    <div className="order-page__info__panel__complaint__text__date">{formatDate(data.complaint.end_comment_date, 'dd.mm.yyyy')}</div>
                                    <div>{data.complaint.end_comment}</div>
                                </div> : null}
                                {data.complaint.status === 'processed' && data.complaint.end_comment === '' ? <Button
                                    onClick={() => this.showLightbox('complaintComment', { text: '' })}
                                    color="shadow"
                                >{this.translate('Pridať vyjadrenie')}</Button> : null}
                            </div> : null}
                        </div>
                    </div>
                    <div className="order-page__info__client">
                        <Tabs
                            value={tab}
                            onChange={(event, tab) => this.setState({ tab })}
                            className="order-page__info__client__tabs"
                        >
                            <Tab label={this.translate('Fakturačná adresa')} value="billing" />
                            <Tab label={this.translate('Dodacia adresa')} value="delivery" />
                        </Tabs>
                        {tab === 'billing' ? <div className="order-page__info__client__panel">
                            <div className="order-page__info__client__panel__line">{`${data.order.client.name}${data.order.client.contact_name !== '' ? ' / ' + data.order.client.contact_name : ''}`}</div>
                            {data.order.client.ico !== '' ? <div className="order-page__info__client__panel__line">{`${this.translate('IČO')}: ${data.order.client.ico}, ${this.translate('DIČ')}: ${data.order.client.dic}${data.order.client.ic_dph !== '' ? (', ' + this.translate('IČ DPH') + ': ' + data.order.client.ic_dph) : ''}`}</div> : null}
                            <div className="order-page__info__client__panel__line">
                                <a href={`tel:${data.order.client.phone}`}>{data.order.client.phone}</a>
                                <a href={`mailto:${data.order.client.email}`}>{data.order.client.email}</a>
                            </div>
                            <div className="order-page__info__client__panel__line">{`${data.order.client.address} ${data.order.client.city} ${data.order.client.zip}`}</div>
                            <div className="order-page__info__client__panel__line">{_.has(data.countries, data.order.client.country.toLowerCase()) ? data.countries[data.order.client.country.toLowerCase()] : data.order.client.country}</div>
                        </div> : <div className="order-page__info__client__panel">
                            <div className="order-page__info__client__panel__line">{`${data.order.client.delivery_name}${data.order.client.delivery_contact_name !== '' ? ' / ' + data.order.client.delivery_contact_name : ''}`}</div>
                            <div className="order-page__info__client__panel__line">
                                <a href={`tel:${data.order.client.delivery_phone}`}>{data.order.client.delivery_phone}</a>
                            </div>
                            <div className="order-page__info__client__panel__line">{`${data.order.client.delivery_address} ${data.order.client.delivery_city} ${data.order.client.delivery_zip}`}</div>
                            <div className="order-page__info__client__panel__line">{_.has(data.countries, data.order.client.delivery_country.toLowerCase()) ? data.countries[data.order.client.delivery_country.toLowerCase()] : data.order.client.delivery_country}</div>
                        </div>}
                    </div>
                </div>
                <div className="order-page__products">
                    <div className="order-page__products__title">{this.translate('Tovar v objednávke')}</div>
                    {_.map(data.order.products, (product, key) => {
                        let productData = {};

                        if (_.has(data.products, product.id)) {
                            productData = {
                                img: data.products[product.id].img_url,
                                name: `${data.products[product.id].name}${!_.isEmpty(product.variant) ? ` - ${product.variant}` : ''}`,
                                url: data.products[product.id].url,
                            };
                        } else {
                            productData = {
                                img: '',
                                name: product.name,
                                url: product.url,
                            };
                        }

                        totalWithoutVat += product.unit_price * product.quantity;

                        return (
                            <div className="order-page__products__product" key={key}>
                                <div className="order-page__products__product__left">
                                    <div className="order-page__products__product__left__photo">
                                        {!_.isEmpty(productData.img) ? <img src={productData.img} alt={productData.name} /> : <HelpIcon />}
                                    </div>
                                    <div className="order-page__products__product__left__info">
                                        <a href={productData.url} className="order-page__products__product__left__info__name" target="_blank">{productData.name}</a>
                                        <div className="order-page__products__product__left__info__values">
                                            <div>{this.translate('Číslo produktu')}: {product.number !== '' ? product.number : '-'}</div>
                                            <div>{this.translate('EAN')}: {product.ean !== '' ? product.ean : '-'}</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="order-page__products__product__status">{this.translate('Rezervované')}</div>
                                <div className="order-page__products__product__quantity">{product.quantity}{`${!_.isEmpty(product.unit) ? ` ${product.unit}` : 'x'}`}</div>
                                <div className="order-page__products__product__price">
                                    <span>{formatAmount(product.total_price * product.quantity, data.order.currency)}</span>
                                    <span>{`${formatAmount(product.unit_price * product.quantity, data.order.currency)} ${this.translate('bez DPH')}`}</span>
                                </div>
                            </div>
                        );
                    })}
                    <div className="order-page__products__product">
                        <div className="order-page__products__product__left">
                            <div className="order-page__products__product__left__photo">
                                <DeliveryIcon />
                            </div>
                            <div className="order-page__products__product__left__info">
                                <div className="order-page__products__product__left__info__name">{data.order.delivery_type_name}</div>
                            </div>
                        </div>
                        <div className="order-page__products__product__price">
                            <span>{formatAmount(data.order.delivery_type_amount, data.order.currency)}</span>
                            <span>{`${formatAmount(deliveryAmountWithoutVat, data.order.currency)} ${this.translate('bez DPH')}`}</span>
                        </div>
                    </div>
                    <div className="order-page__products__product">
                        <div className="order-page__products__product__left">
                            <div className="order-page__products__product__left__photo">
                                <PaymentIcon />
                            </div>
                            <div className="order-page__products__product__left__info">
                                <div className="order-page__products__product__left__info__name">{data.order.payment_type_name}</div>
                            </div>
                        </div>
                        <div className="order-page__products__product__price">
                            <span>{formatAmount(data.order.payment_type_amount, data.order.currency)}</span>
                            <span>{`${formatAmount(paymentAmountWithoutVat, data.order.currency)} ${this.translate('bez DPH')}`}</span>
                        </div>
                    </div>
                    {data.order.amount_diff !== 0 ? <div className="order-page__products__product">
                        <div className="order-page__products__product__left">
                            <div className="order-page__products__product__left__photo">
                                <PaymentIcon />
                            </div>
                            <div className="order-page__products__product__left__info">
                                <div className="order-page__products__product__left__info__name">{this.translate('Zľava')}</div>
                            </div>
                        </div>
                        <div className="order-page__products__product__price">
                            <span>{formatAmount(data.order.amount_diff, data.order.currency)}</span>
                            <span>{`${formatAmount(diffAmountWithoutVat, data.order.currency)} ${this.translate('bez DPH')}`}</span>
                        </div>
                    </div> : null}
                </div>
                <div className="order-page__down">
                    <div className="order-page__down__left">{this.translate('Vytvorené')} {formatDate(data.order.date, 'dd.mm.yyyy')}</div>
                    <div className="order-page__down__summary">
                        <div className="order-page__down__summary__line">
                            <div className="order-page__down__summary__line__name">{this.translate('Celkom')}</div>
                            <div className="order-page__down__summary__line__value">{formatAmount(data.order.amount, data.order.currency)}</div>
                        </div>
                        <div className="order-page__down__summary__line">
                            <div className="order-page__down__summary__line__name">{this.translate('bez DPH')}</div>
                            <div className="order-page__down__summary__line__value">{formatAmount(totalWithoutVat, data.order.currency)}</div>
                        </div>
                    </div>
                </div>
                {this.renderLightbox(
                    'complaint',
                    this.translate('Reklamácia'),
                    !_.isEmpty(lightbox.complaint) ? <div className="complaint-lightbox">
                        <Select
                            label={this.translate('Žiadosť zákaznika na vybavenie')}
                            value={lightbox.complaint.type}
                            options={COMPLAINT_TYPES}
                            onChange={value => this.onChangeComplaint('type', value)}
                            allowEmpty={false}
                        />
                        <Input
                            label={this.translate('Popis')}
                            value={lightbox.complaint.text}
                            onChange={value => this.onChangeComplaint('text', value)}
                            multiline
                        />
                        <Message type="info">{this.translate('Nižšie prosím vyberte konkrétne produkty a počty kusov')}</Message>
                        {_.map(data.order.products, (product, key) => {
                            let productData = {};

                            if (_.has(data.products, product.id)) {
                                productData = {
                                    img: data.products[product.id].img_url,
                                    name: `${data.products[product.id].name}${!_.isEmpty(product.variant) ? ` - ${product.variant}` : ''}`,
                                    url: data.products[product.id].url,
                                };
                            } else {
                                productData = {
                                    img: '',
                                    name: product.name,
                                    url: product.url,
                                };
                            }

                            return (
                                <div className="complaint-lightbox__product" key={key}>
                                    <div className="complaint-lightbox__product__left">
                                        <div className="complaint-lightbox__product__left__photo">
                                            {!_.isEmpty(productData.img) ? <img src={productData.img} alt={productData.name} /> : <HelpIcon />}
                                        </div>
                                        <div className="complaint-lightbox__product__left__info">
                                            <a href={productData.url} className="complaint-lightbox__product__left__info__name" target="_blank">{productData.name}</a>
                                            <div className="complaint-lightbox__product__left__info__values">
                                                <div>{this.translate('Číslo produktu')}: {product.number !== '' ? product.number : '-'}</div>
                                                <div>{this.translate('EAN')}: {product.ean !== '' ? product.ean : '-'}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <Input
                                        placeholder={product.quantity.toString()}
                                        value={lightbox.complaint.products[product.id]}
                                        onChange={value => this.onChangeComplaintProduct(product.id, value, toNumber(product.quantity))}
                                        type="number"
                                    />
                                </div>
                            );
                        })}
                        <Button
                            onClick={() => this.saveComplaint()}
                            loading={loadingComplaint}
                            className="complaint-lightbox__button"
                            color="green"
                        >{this.translate('Odoslať')}</Button>
                    </div> : null,
                )}
                {this.renderLightbox(
                    'complaintComment',
                    this.translate('Vyjadrenie k reklamácii'),
                    !_.isEmpty(lightbox.complaintComment) ? <div className="complaint-lightbox">
                        <Input
                            label={this.translate('Vyjadrenie')}
                            value={lightbox.complaintComment.text}
                            onChange={value => this.onChangeComplaintComment('text', value)}
                            multiline
                        />
                        <Button
                            onClick={() => this.saveComplaintComment()}
                            loading={loadingComplaint}
                            className="complaint-lightbox__button"
                            color="green"
                        >{this.translate('Odoslať')}</Button>
                    </div> : null
                )}
                {this.renderLightbox(
                    'cancel',
                    this.translate('Naozaj chcete stornovať túto objednávku?'),
                    null,
                    this.translate('Áno'),
                    this.translate('Nie'),
                    () => this.cancel()
                )}
                {this.renderSnackbar()}
            </div>
        );
    }
}

export { OrderPage };
