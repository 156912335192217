import React from 'react';
import { connect } from 'react-redux';
import { withCookies } from 'react-cookie';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import { Tooltip, IconButton, ButtonBase, Badge } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import LogoutIcon from '@mui/icons-material/PowerSettingsNew';
import DownIcon from '@mui/icons-material/ExpandMore';
import SettingsIcon from '@mui/icons-material/Settings';
import NotificationsIcon from '@mui/icons-material/Notifications';
import SubscriptionIcon from '@mui/icons-material/Payment';
import EmployeesIcon from '@mui/icons-material/People';
import EventsIcon from '@mui/icons-material/Event';
import SearchIcon from '@mui/icons-material/Search';
import LoginIcon from '@mui/icons-material/Login';
import CloseIcon from '@mui/icons-material/Close';
import ActiveIcon from '@mui/icons-material/Done';
import EnterIcon from '@mui/icons-material/Login';
import HelpIcon from '@mui/icons-material/Help';
import { Navigate, Input, Button, EventsList, Subscription } from '../components';
import { __, isEmptyString, request, toNumber } from '../functions';
import { setUser, changeMobileMenu } from '../actions';
import { IMAGES, COOKIE_TOKEN_NAME, COOKIE_SETTINGS, ESHOP_COLORS, TUTORIAL_STEPS } from '../config';
import '../assets/styles/header.css';

/**
 * Header komponenta.
 */
class Header extends Navigate {
    /**
     * Default state.
     *
     * @type {Object}
     */
    state = {
        search: '',
        showSearch: false,
        showEshops: false,
    };

    /**
     * Odhlasenie.
     */
    logout() {
        const { cookies } = this.props;

        // Zmazeme token
        cookies.remove(COOKIE_TOKEN_NAME, COOKIE_SETTINGS);

        window.location = '/login';
    }

    /**
     * Zobrazime menu.
     */
    openMenu() {
        const { changeMobileMenu } = this.props;

        changeMobileMenu('full');
    }

    /**
     * Zobrazime/schovame vyhladavanie.
     */
    showSearch() {
        this.setState({ showSearch: !this.state.showSearch });
    }

    /**
     * Zobrazime/schovame eshopy.
     */
    showEshops() {
        this.setState({ showEshops: !this.state.showEshops });
    }

    /**
     * Event po zmene vyhladavania.
     *
     * @param {string} search
     */
    onChangeSearch(search) {
        this.setState({ search });
    }

    /**
     * Vyhladavanie.
     */
    search() {
        const { search } = this.state;

        if (isEmptyString(search)) {
            return;
        }

        this.setState({ search: '' });

        const path = `/orders/${search}`;

        if (this.getPath().indexOf('/orders') !== -1) {
            window.location = path;
            return;
        }

        this.redirect(path);
    }

    /**
     * Vyhladavanie.
     */
    searchPassword() {
        const { search } = this.state;

        if (isEmptyString(search)) {
            return;
        }

        this.setState({ search: '' });

        const path = `/dashboard/${search}`;

        if (this.getPath().indexOf('/dashboard') !== -1) {
            window.location = path;
            return;
        }

        this.redirect(path);
    }

    /**
     * Zmenime eshop.
     *
     * @param {number|string} id
     */
    changeEshop(id) {
        request(`/users/changeEshop/${id}`).then(response => {
            const { status } = response.data;

            if (status === 'error') {
                this.showSnackbar('error', __('Do tohto eshopu nemáte prístup'));
                return;
            }

            let path = this.getPath();

            if (path.indexOf('/orders') !== -1) {
                path = '/orders';
            }

            // Refreshneme
            window.location = path;
        });
    }

    /**
     * Skipneme tutorial step.
     *
     * @param {number} step
     */
    skipTutorialStep(step) {
        let nextStep = step + 1;
        nextStep = nextStep > _.keys(TUTORIAL_STEPS).length ? 0 : nextStep;

        // Ulozime nastavenie
        request('/user-eshops/changeSetting', { tutorial_step: nextStep }).then(response => {
            // Presmerujeme
            if (nextStep === 0) {
                window.location.reload();
                return;
            }

            this.redirect(TUTORIAL_STEPS[nextStep].redirect);
        });
    }

    /**
     * Rendrovanie.
     *
     * @return {JSX.Element}
     */
    render() {
        const { search, showEshops, showSearch } = this.state;
        const { user, setUser } = this.props;

        if (_.isEmpty(user)) {
            // Nie sme prihlaseny
            return null;
        }

        const userEshopId = toNumber(user.user_eshop_id);

        // Nazov eshopu
        const eshopName = userEshopId > 0 ? user.eshops[user.user_eshops_refs[userEshopId]].name : '';

        // Nazov user eshopu
        const userEshopName = userEshopId > 0 ? user.user_eshops[userEshopId] : __('Nezadaný názov');

        // Mobilna verzia
        const isMobile = this.isMobile();

        // User ma posledne dni clenstva
        const hasLastSubscriptionsDays = _.has(user, 'subscription_days')
            && user.subscription_days !== ''
            && toNumber(user.subscription_days) <= 5;

        // Vytiahneme pocet udalosti ktore su aktivne
        const processingEvents = _.reduce(user.events, (result, { status }) => {
            result += (status !== 'processed' ? 1 : 0);

            return result;
        }, 0);

        // Je to demo?
        const isDemo = this.isDemo();

        let showSubscription = userEshopId > 0 && !isDemo && !user.brand;

        if (user.subscription && _.has(user, 'subscription_end') && user.subscription_end.substring(0, 4) === '2050') {
            // Nezobrazujeme clenstvo
            showSubscription = false;
        }

        // Aktualny krok tutorialu
        const tutorialStep = this.getTutorialStep();

        const isMtokna = this.isMtokna();
        const isRainpro = this.isRainpro();

        return (
            <div className="header">
                <div className="header__left">
                    {isMobile ? <Tooltip title={__('Zobraziť menu')}>
                        <ButtonBase
                            className="header__left__menu-button"
                            color="inherit"
                            onClick={() => this.openMenu()}
                        >
                            <MenuIcon />
                        </ButtonBase>
                    </Tooltip> : null}
                    {isMobile && this.hasPermission('orders') && !isMtokna && !isRainpro ? <Tooltip title={__('Vyhľadávanie')}>
                        <IconButton
                            onClick={() => this.showSearch()}
                            className="header__left__search header__right__button"
                        >
                            {!showSearch ? <SearchIcon /> : <CloseIcon />}
                        </IconButton>
                    </Tooltip> : null}
                    {(!isMobile || showSearch) && this.hasPermission('orders') && tutorialStep === 0 && !user.is_seller && !isMtokna && !isRainpro ? <Input
                        placeholder={__('Zadajte číslo objednávky')}
                        value={search}
                        onChange={value => this.onChangeSearch(value)}
                        onKeyPress={value => { if (value === 'Enter') { this.search(); } }}
                        icon={<SearchIcon />}
                        rightButton={<IconButton onClick={() => this.search()}><EnterIcon /></IconButton>}
                        variant="filled"
                    /> : null}
                    {isMtokna && user.mtokna_type === 'mtokna_seller' ? <Input
                        placeholder={__('Zadajte heslo objednávky')}
                        value={search}
                        onChange={value => this.onChangeSearch(value)}
                        onKeyPress={value => { if (value === 'Enter') { this.searchPassword(); } }}
                        icon={<SearchIcon />}
                        rightButton={<IconButton onClick={() => this.searchPassword()}><EnterIcon /></IconButton>}
                        variant="filled"
                    /> : null}
                    {tutorialStep > 0 && !isMtokna && !isRainpro ? <div className="header__left__tutorial">
                        <div className="header__left__tutorial__steps"><HelpIcon /> {__('Sprievodca')}: {tutorialStep}/{_.keys(TUTORIAL_STEPS).length} - {_.has(TUTORIAL_STEPS, tutorialStep) ? TUTORIAL_STEPS[tutorialStep].name : ''}</div>
                        <Button
                            onClick={this.getPath() === TUTORIAL_STEPS[tutorialStep].redirect && tutorialStep > 1
                                ? () => this.skipTutorialStep(tutorialStep)
                                : () => this.redirect(TUTORIAL_STEPS[tutorialStep].redirect)}
                            color={this.getPath() === TUTORIAL_STEPS[tutorialStep].redirect && tutorialStep > 1 ? 'shadow' : 'primary'}
                        >{this.getPath() === TUTORIAL_STEPS[tutorialStep].redirect && tutorialStep > 1
                            ? __('Preskočiť')
                            : __('Poďme na to!')}</Button>
                    </div> : null}
                </div>
                <div className="header__right">
                    {!showSearch && this.hasPermission('notifications') && !isMtokna && !isRainpro ? <Tooltip title={__('Upozornenia')}>
                        <Link to={user.notifications > 0 ? '/notifications/high' : '/notifications'}>
                            <IconButton
                                className={`header__right__button ${this.getPath() === '/notifications'
                                    ? 'active'
                                    : ''}`}
                            >
                                <Badge badgeContent={user.notifications} color="primary">
                                    <NotificationsIcon />
                                </Badge>
                            </IconButton>
                        </Link>
                    </Tooltip> : null}
                    {!isMobile && this.hasPermission('events') && !isMtokna && !isRainpro ? <Tooltip title={__('Udalosti')}>
                        <IconButton
                            onClick={() => this.showDrawer('events')}
                            className={`header__right__button ${this.isDrawerOpened('events')
                                ? 'active'
                                : ''}`}
                        >
                            <Badge badgeContent={processingEvents} color="primary">
                                <EventsIcon />
                            </Badge>
                        </IconButton>
                    </Tooltip> : null}
                    {!isMobile && this.hasPermission('subscription') && showSubscription && !isMtokna && !isRainpro ? <Tooltip title={__('Členstvo')}>
                        <IconButton
                            onClick={() => this.showDrawer('subscription')}
                            className={`header__right__button ${this.isDrawerOpened('subscription')
                                ? 'active'
                                : ''}`}
                        >
                            <Badge
                                badgeContent={user.subscription
                                    ? (hasLastSubscriptionsDays ? user.subscription_days : <ActiveIcon />)
                                    : <CloseIcon />}
                                color={user.subscription
                                    ? (hasLastSubscriptionsDays ? 'orange' : 'secondary')
                                    : 'error'}
                            >
                                <SubscriptionIcon />
                            </Badge>
                        </IconButton>
                    </Tooltip> : null}
                    {!isMobile && user.type !== 'employee' && !user.is_seller && !isMtokna && !isRainpro ? <Tooltip title={__('Zamestnanci')}>
                        <Link to="/employees">
                            <IconButton
                                className={`header__right__button ${this.getPath().indexOf('/employee') !== -1
                                    ? 'active'
                                    : ''}`}
                            >
                                <EmployeesIcon />
                            </IconButton>
                        </Link>
                    </Tooltip> : null}
                    {!showSearch ? <ButtonBase
                        onClick={!isMtokna && !isRainpro ? () => this.showEshops() : () => {}}
                        className="header__right__eshop"
                    >
                        {userEshopId > 0 && !isMtokna && !isRainpro ? <div
                            className="header__right__eshop__logo"
                            style={{ backgroundColor: ESHOP_COLORS[eshopName].background }}
                        >
                            <img
                                src={IMAGES[`${eshopName.toLowerCase()}.png`]}
                                alt={eshopName}
                            />
                        </div> : <div className="header__right__eshop__logo">
                            <HelpIcon />
                        </div>}
                        <div className="header__right__eshop__info">
                            {!isMtokna && !isRainpro ? <div className="header__right__eshop__info__name">
                                {_.truncate(userEshopName, { length: 16 })}
                            </div> : (isRainpro ? (<div className="header__right__eshop__info__name">{user.rainpro_type === 'rainpro_admin' ? __('Admin') : __('Skladník')}</div>) : (<div className="header__right__eshop__info__name">{user.mtokna_type === 'mtokna_admin'
                                ? (user.mtokna_moderator ? __('Obchodný zástupca') : __('Predajca Admin'))
                                : (user.mtokna_type === 'mtokna_owner' ? __('Predajca VO') : __('Predajca MO'))}</div>))}
                            <div className="header__right__eshop__info__user">
                                {_.truncate(user.email, { length: 24 })}
                            </div>
                        </div>
                        {!isMtokna && !isRainpro ? <DownIcon className={showEshops ? 'rotate' : ''} /> : null}
                    </ButtonBase> : null}
                    {!isMobile && !isDemo && !isMtokna && !isRainpro ? <Tooltip title={__('Nastavenia')}>
                        <Link to="/settings">
                            <IconButton
                                className={`header__right__button ${this.getPath() === '/settings'
                                    ? 'active'
                                    : ''}`}
                            >
                                <SettingsIcon />
                            </IconButton>
                        </Link>
                    </Tooltip> : null}
                    {!showSearch ? <Tooltip title={__('Odhlásenie')}>
                        <IconButton
                            onClick={() => this.logout()}
                            className="header__right__button"
                        >
                            <LogoutIcon />
                        </IconButton>
                    </Tooltip> : null}
                </div>
                {showEshops ? <div className="header__eshops">
                    <div className="header__eshops__hide-shadow" />
                    {_.map(user.user_eshops, (name, id) => {
                        const eshopId = user.user_eshops_refs[id];
                        const eshopName = user.eshops[toNumber(eshopId)].name;

                        return (
                            <ButtonBase
                                onClick={() => this.changeEshop(id)}
                                className="header__eshops__eshop"
                                key={id}
                            >
                                <div
                                    className="header__eshops__eshop__logo"
                                    style={{ backgroundColor: ESHOP_COLORS[eshopName].background }}
                                >
                                    <img src={IMAGES[`${eshopName.toLowerCase()}.png`]} alt={eshopName} />
                                </div>
                                <div className="header__eshops__eshop__name">
                                    {_.truncate(name, { length: 18 })}
                                </div>
                                <LoginIcon />
                            </ButtonBase>
                        );
                    })}
                    {user.type !== 'employee' && !user.brand && !this.isDemo() ? <Button
                        onClick={() => this.changeEshop('new')}
                        disabled={userEshopId === 0}
                    >{__('Nový eshop')}</Button> : null}
                </div> : null}
                {this.renderSnackbar()}
                {this.renderDrawer('events', {
                    title: __('Udalosti'),
                    content: props => <EventsList items={user.events} {...props} />,
                })}
                {this.renderDrawer('subscription', {
                    title: __('Členstvo'),
                    content: props => <Subscription user={user} {...props} setUser={setUser} />,
                })}
            </div>
        );
    }
}

const stateToProps = ({ user }) => ({ user });

export default withCookies(connect(stateToProps, { setUser, changeMobileMenu })(Header));
