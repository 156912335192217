import {
    FETCH_MTOKNA_ESHOP,
    CREATE_MTOKNA_ESHOP,
} from '../actions/types';

const MTOKNA_ESHOP_DEFAULT_STATE = [];

/**
 * Reducer pre eshop.
 */
const MtoknaEshopReducer = (state = MTOKNA_ESHOP_DEFAULT_STATE, { type, payload }) => {
    switch (type) {
        case FETCH_MTOKNA_ESHOP:
            // Nacitanie eshopu
            return payload;

        case CREATE_MTOKNA_ESHOP:
            // Vytvorenie eshopu
            return { ...state, id: payload };

        default:
            return state;
    }
};

export default MtoknaEshopReducer;
