import React from 'react';
import _ from 'lodash';
import { Tooltip, Chip } from '@mui/material';
import LinkIcon from '@mui/icons-material/Link';
import { Navigate, Button, Input, ContentPart, Select, Message, Switch } from '../components';
import { __, request, toNumber } from '../functions';
import '../assets/styles/marketplace.css';

/**
 * Marketplace komponenta.
 */
class Marketplace extends Navigate {
    /**
     * Default props.
     *
     * @type {Object}
     */
    static defaultProps = {
        type: '',
    };

    /**
     * Default state.
     *
     * @type {Object}
     */
    state = {
        showSetup: false,
        data: {},
        settings: {},
        fields: {},
        loading: false,
        eshops: {},
        loadingData: false,
    };

    /**
     * Zoznam labelov.
     *
     * @type {Object}
     */
    labels = {
        'market': __('Lokalizácia'),
        'eshop_id': __('Eshop'),
        'email': __('Prihlasovací email'),
        'password': __('Prihlasovacie heslo'),
        'apikey': __('API klúč'),
        'vendor_id': __('Vendor ID'),
        'shop_id': __('ID obchodu'),
        'client_key': __('Client key'),
        'secret_key': __('Secret key'),
        'client_id': __('Client id'),
        'client_secret': __('Client secret'),
        'device_code': __('Device code'),
    };

    /**
     * Komponenta bola pripojena.
     */
    componentDidMount() {
        const { title, setTitle, user } = this.props;

        // Nastavime title
        setTitle(title);

        if (toNumber(user.user_eshop_id) === 0) {
            return;
        }

        // Nacitame data
        this.fetch();
    }

    /**
     * Nacitame data.
     */
    fetch() {
        const { type, onClose } = this.props;

        // Nacitame data trhoviska
        request('/user-marketplaces/get', { marketplace: type }).then(response => {
            const { status, data } = response.data;

            if (status === 'error') {
                // Trhovisko neexistuje
                onClose();
                return;
            }

            this.setState({
                data,
                settings: data.settings,
                fields: this.getDefaultFields(data.marketplaces[data.id].fields),
            });
        });
    }

    /**
     * Nacitame data.
     */
    fetchData() {
        const { type, showSnackbar } = this.props;

        this.setState({ loadingData: true });

        // Nacitame data trhoviska
        request('/user-marketplaces/fetchData', { marketplace: type }).then(response => {
            const { status, data } = response.data;

            if (status === 'error') {
                // Trhovisko neexistuje
                this.setState({ loadingData: false });
                showSnackbar('error', __('Nepodarilo sa načítať dáta trhoviska'));
                return;
            }

            this.setState({ loadingData: false, data: { ...this.state.data, data: { ...this.state.data.data, ...data.data } } });

            showSnackbar('success', __('Dáta trhoviska boli načítané'));
        });
    }

    /**
     * Vratime zoznam default fieldov.
     *
     * @param {Object} fields
     *
     * @return {Object}
     */
    getDefaultFields(fields) {
        return _.reduce(
            fields,
            (result, field, name) => ({ ...result, [name]: _.isObject(field) ? _.keys(field)[0] : '' }),
            {}
        );
    }

    /**
     * Event po zmene nastaveni.
     *
     * @param {string} name
     * @param {string} value
     */
    onChangeSetting(name, value) {
        const { settings } = this.state;

        this.setState({ settings: { ...settings, [name]: value } });
    }

    /**
     * Event po zmene specifickeho nastavenia.
     *
     * @param {string} name
     * @param {string} value
     */
    onChangeSpecificSetting(name, value) {
        const { settings } = this.state;

        this.onChangeSetting('specific', { ...settings.specific, [name]: value });
    }

    /**
     * Event po zmene fieldu.
     *
     * @param {string} field
     * @param {string} value
     */
    onChangeField(field, value) {
        const { fields } = this.state;

        this.setState({ fields: { ...fields, [field]: value } });
    }

    /**
     * Zobrazime setup.
     */
    showSetup() {
        this.setState({ showSetup: true });
    }

    /**
     * Schovame setup.
     */
    closeSetup() {
        this.setState({ showSetup: false });
    }

    /**
     * Skontrolujeme credentials.
     *
     * @param {boolean} create
     */
    checkCredentials(create) {
        const { showSnackbar } = this.props;
        const { data, fields, eshops } = this.state;

        this.setState({ loading: true });

        if (!_.isEmpty(eshops)) {
            // Mame zadany zoznam eshopov, pokracujeme dalej
            this.save(fields, create);
            return;
        }

        request('/user-marketplaces/credentials', fields, 'POST', { marketplace: data.marketplaces[data.id].name }).then(response => {
            const { status, data } = response.data;

            if (status === 'error') {
                this.setState({ loading: false });
                showSnackbar('error', __('Nepodarilo sa prihlásiť'));
                return;
            }

            if (_.has(data.credentials, 'auth_url')) {
                // Presmerujeme na auth url
                window.location = data.credentials.auth_url;
                return;
            }

            if (!_.has(data.credentials, 'eshop_id')) {
                // Nie je zadane eshop id
                this.save(data.credentials, create);
                return;
            }

            const { eshops, eshop_id } = data.credentials;

            if (eshop_id !== '' && eshop_id !== '0' && eshop_id !== 0) {
                // Mame zadanu polozku, pokracujeme
                this.save(data.credentials, create);
                return;
            }

            // Mame viac eshopov musime zobrazit select
            this.setState({
                loading: false,
                fields: { ...data.credentials, eshop_id: _.keys(eshops)[0] },
                eshops,
            });
        });
    }

    /**
     * Ulozenie.
     *
     * @param {Object} credentials
     * @param {boolean} create
     */
    save(credentials, create = false) {
        const { showSnackbar, createMarketplace } = this.props;
        const { data } = this.state;

        if (create) {
            // Vytvarame
            request('/user-marketplaces/create', { marketplace_id: data.id, credentials }, 'POST', { marketplace: data.marketplaces[data.id].name }).then(response => {
                const { status } = response.data;

                if (status === 'error') {
                    // Nepodarilo sa vytvorit
                    this.setState({ loading: false });
                    showSnackbar('error', __('Nepodarilo sa prihlásiť'));
                    return;
                }

                this.setState({ loading: false, eshops: {}, data: {} });

                showSnackbar('success', __('Prihlasovacie údaje boli uložené'));

                // Nacitame data znovu
                this.fetch();

                // Vytvorime
                createMarketplace(data.id);
            });
            return;
        }

        // Zmenime nastavenia
        request('/user-marketplaces/changeCredentials', { credentials }, 'POST', { marketplace: data.marketplaces[data.id].name }).then(response => {
            const { status } = response.data;

            this.setState({ loading: false });

            if (status === 'error') {
                // Nepodarilo sa zmenit credentials
                showSnackbar('error', __('Nepodarilo sa zmeniť prihlasovacie údaje'));
                return;
            }

            this.setState({ loading: false, data: {}, eshops: {}, showSetup: false });

            showSnackbar('success', __('Prihlasovacie údaje boli zmenené'));

            // Nacitame data znovu
            this.fetch();
        });
    }

    /**
     * Zmazeme trhovisko.
     */
    delete() {
        const { showSnackbar, onClose, deleteMarketplace } = this.props;
        const { data } = this.state;

        this.setState({ loadingDelete: true });

        request('/user-marketplaces/delete', { marketplace: data.marketplaces[data.id].name }).then(response => {
            const { status } = response.data;

            if (status === 'error') {
                // Nepodarilo sa zmazat
                this.setState({ loadingDelete: false });
                showSnackbar('error', __('Nepodarilo sa deaktivovať trhovisko'));
                return;
            }

            showSnackbar('success', __('Trhovisko bolo deaktivované'));

            // Zavrieme
            onClose();

            // Zmazeme
            deleteMarketplace(data.id);
        });
    }

    /**
     * Zmena nastaveni.
     */
    changeSettings() {
        const { showSnackbar, onClose } = this.props;
        const { data, settings } = this.state;

        this.setState({ loading: true });

        request('/user-marketplaces/changeSettings', { settings }, 'POST', { marketplace: data.marketplaces[data.id].name }).then(response => {
            const { status } = response.data;

            this.setState({ loading: false });

            if (status === 'error') {
                // Nepodarilo sa zmenit nastavenia
                showSnackbar('error', __('Nepodarilo sa zmeniť nastavenia'));
                return;
            }

            showSnackbar('success', __('Nastavenia boli zmenené'));

            // Zavrieme
            onClose();
        });
    }

    /**
     * Event po kliku na link.
     *
     * @param {string} link
     */
    onClickLink(link) {
        const { showSnackbar } = this.props;

        // Nastavime link do clipboardu
        navigator.clipboard.writeText(link);

        showSnackbar('success', __('Skopirované do schránky'));
    }

    /**
     * Rendrovanie fieldov.
     *
     * @param {Object} items
     *
     * @return {JSX.Element[]}
     */
    renderFields(items) {
        const { fields } = this.state;

        return _.map(items, (field, name) => {
            const label = _.has(this.labels, name) ? this.labels[name] : '';

            switch (name) {
                case 'market':
                    // Lokalizacia
                    return <Select
                        key={name}
                        label={label}
                        options={field}
                        value={fields[name]}
                        onChange={value => this.onChangeField(name, value)}
                        allowEmpty={false}
                    />;

                case 'eshop_id':
                    // Eshop
                    return <Select
                        key={name}
                        label={label}
                        options={field}
                        value={fields[name]}
                        onChange={value => this.onChangeField(name, value)}
                        allowEmpty={false}
                    />;

                case 'email':
                    // Email
                    return <Input
                        type="email"
                        key={name}
                        label={label}
                        value={fields[name]}
                        onChange={value => this.onChangeField(name, value)}
                    />;

                case 'password':
                    // Heslo
                    return <Input
                        type="password"
                        key={name}
                        label={label}
                        value={fields[name]}
                        onChange={value => this.onChangeField(name, value)}
                    />;

                case 'apikey':
                    // Apikey
                    return <Input
                        key={name}
                        label={label}
                        value={fields[name]}
                        onChange={value => this.onChangeField(name, value)}
                    />;

                case 'vendor_id':
                    // CS cart vendor id
                    return <Input
                        key={name}
                        label={label}
                        value={fields[name]}
                        onChange={value => this.onChangeField(name, value)}
                    />;

                case 'shop_id':
                case 'client_key':
                case 'secret_key':
                case 'client_id':
                case 'client_secret':
                case 'device_code':
                    // Shop id
                    return <Input
                        key={name}
                        label={label}
                        value={fields[name]}
                        onChange={value => this.onChangeField(name, value)}
                    />;

                default:
                    return null;
            }
        });
    }

    /**
     * Vratime nastavenia.
     *
     * @param {string} name
     *
     * @return {JSX.Element|null}
     */
    renderSettings(name) {
        const { data, settings } = this.state;

        switch (name) {
            case 'Heureka':
                // Vytiahneme zoznam stavov
                const states = _.reduce(data.eshop_data.states, (result, { id, name }) => ({ ...result, [id]: name }), {});

                // Je aktivny kosik?
                const active = !_.isEmpty(data.data) && data.data.status === 'active';

                return (<div>
                    <Tooltip title={__('Kliknutím skopírujete do schránky.')}>
                        <Chip
                            onClick={() => this.onClickLink(data.data.link)}
                            label={data.data.link}
                            clickable
                            color="primary"
                            className="marketplace-content__data__settings__link"
                            icon={<LinkIcon />}
                        />
                    </Tooltip>
                    {active
                        ? <Message type="success">{__('Košík je aktivný.')}</Message>
                        : <Message type="warning">{__('Košík zatial nie je aktivný. Musíte sa prihlásiť do administrácie heureky a zadať vyššie uvedenú API url. Následne musí košík prejsť schvalovacím procesom.')}</Message>}
                    <Select
                        label={__('Stav objednávky po storne')}
                        options={states}
                        value={settings.specific.state_cancel}
                        onChange={value => this.onChangeSpecificSetting('state_cancel', value)}
                    />
                </div>);

            default:
                return (
                    <div>
                        <Switch
                            label={__('Importovať objednávky do eshopu')}
                            checked={settings.import_orders}
                            onChange={value => this.onChangeSetting('import_orders', value)}
                        />
                    </div>
                );
        }
    }

    /**
     * Rendrovanie.
     *
     * @return {JSX.Element}
     */
    render() {
        const { user, type } = this.props;
        const { data, loading, loadingDelete, showSetup, eshops, loadingData } = this.state;

        if (toNumber(user.user_eshop_id) === 0) {
            return <div
                className="marketplace-content"
            ><Message type="warning">{__('Najprv musíte aktivovať eshop')}</Message></div>;
        }

        if (_.isEmpty(data)) {
            // Data nie su nacitane
            return <div
                className="marketplace-content marketplace-content-loading"
            >{this.renderLoading()}</div>;
        }

        // Je aktivne?
        const activated = !_.isEmpty(data.credentials);

        // Fieldy na generovanie
        let { name, fields } = data.marketplaces[data.id];

        if (!_.isEmpty(eshops)) {
            // Mame zadany zoznam eshopov zobrazime len ten
            fields = { eshop_id: eshops };
        }

        let showCredentials = false;

        _.each(data.credentials, (value, name) => {
            if (_.has(this.labels, name)) {
                showCredentials = true;
            }
        });

        return (
            <div className="marketplace-content">
                {!showSetup && activated ? <div className="marketplace-content__data">
                    {showCredentials ? <div className="marketplace-content__data__credentials">
                        <div className="marketplace-content__data__credentials__title">{__('Prihlasovacie údaje')}</div>
                        {_.map(data.credentials, (value, name) => {
                            if (!_.has(this.labels, name)) {
                                return null;
                            }

                            if (
                                name === 'eshop_id'
                                && _.has(data.credentials, 'eshops')
                                && _.has(data.credentials.eshops, value)
                            ) {
                                // Chceme nazov
                                value = data.credentials.eshops[value];
                            }

                            return (
                                <div className="marketplace-content__data__credentials__value" key={name}>
                                    <div className="marketplace-content__data__credentials__value__name">
                                        {this.labels[name]}:
                                    </div>
                                    <div className="marketplace-content__data__credentials__value__text">
                                        {_.truncate(value, { length: 30 })}
                                    </div>
                                </div>
                            );
                        })}
                    </div> : null}
                    <div className="marketplace-content__data__buttons">
                        <Button
                            onClick={() => this.showSetup()}
                            color="shadow"
                            className="marketplace-content__data__buttons__button"
                        >{__('Upraviť')}</Button>
                        <Button
                            onClick={() => this.delete()}
                            color="red"
                            className="marketplace-content__data__buttons__button"
                            loading={loadingDelete}
                        >{__('Zmazať')}</Button>
                    </div>
                    <div className="marketplace-content__data__settings">
                        <ContentPart title={__('Nastavenia')} />
                        {this.renderSettings(name)}
                    </div>
                </div> : <div className="marketplace-content__data">
                    {!_.isEmpty(eshops) ? <Message type="warning">{__('Na trhovisku máte zaevidovaných viacero eshopov, prosím vyberte konkrétny eshop.')}</Message> : null}
                    {this.renderFields(fields)}
                </div>}
                <div className="marketplace-content__buttons">
                    {showSetup ? <Button
                        onClick={() => this.closeSetup()}
                        color="shadow"
                        className="marketplace-content__buttons__button"
                    >{__('Naspäť')}</Button> : null}
                    {!showSetup && activated && type !== 'Heureka' && type !== 'Allegro' ? <Button
                        onClick={() => window.location = `/marketplaces/products/${type}`}
                        color="shadow"
                        className="marketplace-content__buttons__button"
                    >{__('Synchronizácia produktov')}</Button> : null}
                    {!showSetup && activated && type === 'Heureka' ? <Button
                        onClick={() => this.fetchData()}
                        color="shadow"
                        className="marketplace-content__buttons__button"
                        loading={loadingData}
                    >{__('Načítať dáta trhoviska')}</Button> : null}
                    <Button
                        onClick={!showSetup && activated
                            ? () => this.changeSettings()
                            : () => this.checkCredentials(!showSetup)}
                        color="green"
                        className="marketplace-content__buttons__button"
                        loading={loading}
                    >{__('Uložiť')}</Button>
                </div>
            </div>
        );
    }
}

export { Marketplace };
