import { FETCH_EXPORTS, CLEAN_EXPORTS, DELETE_EXPORT } from '../actions/types';

const EXPORTS_DEFAULT_STATE = {};

/**
 * Reducer pre exporty.
 */
const ExportsReducer = (state = EXPORTS_DEFAULT_STATE, { type, payload }) => {
    switch (type) {
        case FETCH_EXPORTS:
            // Nacitanie exportov
            return payload;

        case CLEAN_EXPORTS:
        case DELETE_EXPORT:
            // Vycistenie exportov
            // Zmazanie exportu
            return EXPORTS_DEFAULT_STATE;

        default:
            return state;
    }
};

export default ExportsReducer;
