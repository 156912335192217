import React from 'react';
import { connect } from 'react-redux';
import { withCookies } from 'react-cookie';
import _ from 'lodash';
import { Navigate } from '../components';
import { ButtonBase } from '@mui/material';
import { setUser, changeMobileMenu } from '../actions';
import '../assets/styles/mtokna_banner.css';

/**
 * Header komponenta.
 */
class MtoknaBanners extends Navigate {
    /**
     * Default state.
     *
     * @type {Object}
     */
    state = {
        level: 0,
    };

    interval = null;

    componentDidMount() {
        this.interval = setInterval(() => {
            this.nextLevel();
        }, 5000);
    }

    componentWillUnmount() {
        if (this.interval !== null) {
            clearInterval(this.interval);
        }
    }

    nextLevel() {
        const { user } = this.props;
        const { level } = this.state;

        let nextLevel = level + 1;

        if ((nextLevel * 3) >= user.banners.length) {
            nextLevel = 0;
        }

        this.setState({ level: nextLevel });
    }

    onClickBanner(banner) {
        if (
            banner !== null
            && _.has(banner, 'data')
            && _.has(banner.data, 'link')
            && banner.data.link !== ''
        ) {
            window.open(banner.data.link, '_blank');
        }
    }

    renderBanner(banner) {
        return (
            <ButtonBase
                onClick={() => this.onClickBanner(banner)}
                className={`mtokna_banner__panel ${banner === null ? 'mtokna_banner__panel-empty' : ''}`}
            >
                {banner !== null
                    ? <img src={`https://api.goodeshop.sk/${banner.file_path}`} alt={banner.name} />
                    : null}
            </ButtonBase>
        );
    }

    /**
     * Rendrovanie.
     *
     * @return {JSX.Element}
     */
    render() {
        const { user } = this.props;

        if (_.isEmpty(user) || _.isEmpty(user.banners)) {
            // Nie sme prihlaseny
            return null;
        }

        const length = user.banners.length;

        const { level } = this.state;

        let koef = level * 3;

        console.log(level);

        const firstKey = koef;
        const secondKey = koef + 1;
        const thirdKey = koef + 2;

        let firstBanner = length > firstKey ? user.banners[firstKey] : null;
        let secondBanner = length > secondKey ? user.banners[secondKey] : null;
        let thirdBanner = length > thirdKey ? user.banners[thirdKey] : null;

        return (
            <div className="mtokna_banner">
                {this.renderBanner(firstBanner)}
                {this.renderBanner(secondBanner)}
                {this.renderBanner(thirdBanner)}
            </div>
        );
    }
}

const stateToProps = ({ user }) => ({ user });

export default withCookies(connect(stateToProps, { setUser, changeMobileMenu })(MtoknaBanners));
