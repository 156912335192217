import React from 'react';
import { connect } from 'react-redux';
import { withCookies } from 'react-cookie';
import _ from 'lodash';
import { Table, TableHead, TableBody, TableRow, TableCell, IconButton } from '@mui/material';
import ViewIcon from '@mui/icons-material/Folder';
import { Screen } from '../components';
import { fetchAdmin, setUser, fetchAdminProcess, fetchAdminError, cleanAdmin } from '../actions';
import { __, formatAmount, formatDate } from '../functions';
import { COOKIE_SETTINGS, COOKIE_TOKEN_NAME } from '../config';
import '../assets/styles/admin.css';

/**
 * Administracia.
 */
class AdminScreen extends Screen {
    /**
     * Title.
     *
     * @type {string}
     */
    title = __('Admin');

    /**
     * Komponenta bola pripojena.
     *
     * @return boolean
     */
    async componentDidMount() {
        if (super.componentDidMount()) {
            const { fetchAdmin } = this.props;

            fetchAdmin(this);
        }

        return true;
    }

    /**
     * Komponenta bude odpojena.
     */
    componentWillUnmount() {
        const { cleanAdmin } = this.props;

        cleanAdmin();
    }

    /**
     * Zobrazime process.
     *
     * @param {string} name
     * @param {string} date
     */
    showProcess(name, date) {
        const { fetchAdminProcess } = this.props;

        fetchAdminProcess(this, name, date);
    }

    /**
     * Zobrazime error.
     *
     * @param {string} name
     * @param {string} date
     */
    showError(name, date) {
        const { fetchAdminError } = this.props;

        fetchAdminError(this, name, date);
    }

    /**
     * Prihlasenie ako.
     *
     * @param {string} token
     */
    loginAs(token) {
        const { cookies } = this.props;

        // Ulozime token do cookie
        cookies.set(COOKIE_TOKEN_NAME, token, COOKIE_SETTINGS);

        window.location = '/dashboard';
    }

    /**
     * Rendrujeme process.
     *
     * @returns {JSX.Element}
     */
    renderProcess() {
        const { admin } = this.props;

        return (
            <div className="admin">
                <div className="admin__header">
                    <div className="admin__header__left">
                        <div className="admin__header__left__title">{__('Administrácia')}</div>
                    </div>
                </div>
                <div className="admin__content">
                    <div className="table">
                        <div className="table__title">{admin.date} - {admin.name}</div>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Čas</TableCell>
                                    <TableCell>Trvanie</TableCell>
                                    <TableCell>Počet položiek</TableCell>
                                    <TableCell>{null}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {_.map(admin.data, (item, key) => {
                                    return (
                                        <TableRow key={key}>
                                            <TableCell>{formatDate(item.date, 'dd.mm.yyyy hh:ii:ss')}</TableCell>
                                            <TableCell>{item.duration}</TableCell>
                                            <TableCell>{item.items}</TableCell>
                                            <TableCell>{null}</TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </div>
                </div>
            </div>
        );
    }

    /**
     * Rendrujeme error.
     *
     * @returns {JSX.Element}
     */
    renderError() {
        const { admin } = this.props;

        return (
            <div className="admin">
                <div className="admin__header">
                    <div className="admin__header__left">
                        <div className="admin__header__left__title">{__('Administrácia')}</div>
                    </div>
                </div>
                <div className="admin__content">
                    <div className="table">
                        <div className="table__title">{admin.date} - {admin.name}</div>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Čas</TableCell>
                                    <TableCell>Ip</TableCell>
                                    <TableCell>Url</TableCell>
                                    <TableCell>Chyba</TableCell>
                                    <TableCell>Data</TableCell>
                                    <TableCell>{null}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {_.map(admin.data, (item, key) => {
                                    return (
                                        <TableRow key={key}>
                                            <TableCell>{formatDate(item.date, 'dd.mm.yyyy hh:ii:ss')}</TableCell>
                                            <TableCell>{item.ip}</TableCell>
                                            <TableCell>{item.url}</TableCell>
                                            <TableCell>{item.message}</TableCell>
                                            <TableCell>{JSON.stringify(item.data)}</TableCell>
                                            <TableCell>{null}</TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </div>
                </div>
            </div>
        );
    }

    /**
     * Rendrovanie.
     *
     * @returns {JSX.Element}
     */
    render() {
        const { admin } = this.props;

        if (_.isEmpty(admin)) {
            // Data nie su nacitane
            return this.renderLoading();
        }

        if (_.has(admin, 'type')) {
            // Renderujeme konkrenty typ
            switch (admin.type) {
                case 'process':
                default:
                    // Proces
                    return this.renderProcess();

                case 'error':
                    // Error
                    return this.renderError();
            }
        }

        return (
            <div className="admin">
                <div className="admin__header">
                    <div className="admin__header__left">
                        <div className="admin__header__left__title">{__('Administrácia')}</div>
                    </div>
                </div>
                <div className="admin__content">
                    <div className="admin__content__stats">
                        <div className="admin__content__stats__panel">
                            <div className="admin__content__stats__panel__title">Užívatelia</div>
                            <div className="admin__content__stats__panel__value">
                                {admin.users.length} <span>+{admin.stats.users_daily}</span>
                            </div>
                        </div>
                        <div className="admin__content__stats__panel">
                            <div className="admin__content__stats__panel__title">Eshopy</div>
                            <div className="admin__content__stats__panel__value">
                                {admin.stats.eshops} <span>+{admin.stats.eshops_daily}</span>
                            </div>
                        </div>
                        <div className="admin__content__stats__panel">
                            <div className="admin__content__stats__panel__title">Platby</div>
                            <div className="admin__content__stats__panel__value">
                                {formatAmount(admin.stats.payments, 'EUR')} <span>+{formatAmount(admin.stats.payments_daily, 'EUR')}</span>
                            </div>
                        </div>
                        <div className="admin__content__stats__panel">
                            <div className="admin__content__stats__panel__title">Error log</div>
                            <div className={`admin__content__stats__panel__value ${admin.stats.error_log ? 'red' : ''}`}>
                                {admin.stats.error_log ? 'áno' : 'nie'}
                            </div>
                        </div>
                        <div className="admin__content__stats__panel">
                            <div className="admin__content__stats__panel__title">Debug log</div>
                            <div className={`admin__content__stats__panel__value ${admin.stats.debug_log ? 'red' : ''}`}>
                                {admin.stats.debug_log ? 'áno' : 'nie'}
                            </div>
                        </div>
                        <div className="admin__content__stats__panel">
                            <div className="admin__content__stats__panel__title">Dlho v procese</div>
                            <div className="admin__content__stats__panel__value">{admin.stats.processing}</div>
                        </div>
                    </div>
                    <div className="table">
                        <div className="table__title">{__('Errory')}</div>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Názov</TableCell>
                                    <TableCell>Počet</TableCell>
                                    <TableCell>Posledné volanie</TableCell>
                                    <TableCell>Posledná chyba</TableCell>
                                    <TableCell>{null}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {_.map(admin.errors, (items, name) => {
                                    return (
                                        <TableRow key={name}>
                                            <TableCell>{name}</TableCell>
                                            <TableCell>{items.length}</TableCell>
                                            <TableCell>{formatDate(items[0].date, 'dd.mm.yyyy hh:ii:ss')}</TableCell>
                                            <TableCell>{items[0].message}</TableCell>
                                            <TableCell>
                                                <IconButton
                                                    onClick={() => this.showError(name, admin.date)}
                                                ><ViewIcon /></IconButton>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </div>
                    <div className="table">
                        <div className="table__title">{__('Users')}</div>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Id</TableCell>
                                    <TableCell>Email</TableCell>
                                    <TableCell>{null}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {_.map(admin.users, ({ id, email, token }) => {
                                    return (
                                        <TableRow key={id}>
                                            <TableCell>{id}</TableCell>
                                            <TableCell>{email}</TableCell>
                                            <TableCell>
                                                <IconButton
                                                    onClick={() => this.loginAs(token)}
                                                ><ViewIcon /></IconButton>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </div>
                    <div className="table">
                        <div className="table__title">{__('Procesy')}</div>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Proces</TableCell>
                                    <TableCell>Počet</TableCell>
                                    <TableCell>Posledné volanie</TableCell>
                                    <TableCell>Min. trvanie</TableCell>
                                    <TableCell>Max. trvanie</TableCell>
                                    <TableCell>Priemerné trvanie</TableCell>
                                    <TableCell>{null}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {_.map(admin.processes, (items, name) => {
                                    // Vytiahneme zoznam trvani
                                    const durations = _.reduce(items, (result, item) => ([ ...result, item.duration ]), []);

                                    return (
                                        <TableRow key={name}>
                                            <TableCell>{name}</TableCell>
                                            <TableCell>{items.length}</TableCell>
                                            <TableCell>{formatDate(items[0].date, 'dd.mm.yyyy hh:ii:ss')}</TableCell>
                                            <TableCell>{formatAmount(Math.min(...durations))}</TableCell>
                                            <TableCell>{formatAmount(Math.max(...durations))}</TableCell>
                                            <TableCell>{formatAmount(_.sum(durations) / durations.length)}</TableCell>
                                            <TableCell>
                                                <IconButton
                                                    onClick={() => this.showProcess(name, admin.date)}
                                                ><ViewIcon /></IconButton>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </div>
                </div>
                {this.renderSnackbar()}
            </div>
        );
    }
}

const stateToProps = ({ admin, user }) => ({ admin, user });

export default withCookies(connect(stateToProps, {
    fetchAdmin,
    fetchAdminError,
    fetchAdminProcess,
    cleanAdmin,
    setUser,
})(AdminScreen));
