import { CHANGE_MOBILE_MENU } from '../actions/types';

const MENU_DEFAULT_STATE = 'mini';

/**
 * Reducer pre menu.
 */
const MenuReducer = (state = MENU_DEFAULT_STATE, { type, payload }) => {
    switch (type) {
        case CHANGE_MOBILE_MENU:
            // Zmena menu
            return payload;

        default:
            return state;
    }
};

export default MenuReducer;
