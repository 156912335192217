import React, { Component } from 'react';
import SnackbarContent from '@mui/material/SnackbarContent';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import InfoIcon from '@mui/icons-material/Info';
import '../assets/styles/message.css';

/**
 * Message komponenta.
 */
class Message extends Component {
    /**
     * Default props.
     *
     * @type {{
     *     type: string,
     *     icon: string,
     *     className: string,
     * }}
     */
    static defaultProps = {
        type: 'success',
        icon: '',
        className: '',
    };

    /**
     * Zoznam ikon.
     *
     * @type {object}
     */
    icons = {
        success: <CheckCircleIcon />,
        error: <ErrorIcon />,
        info: <InfoIcon />,
        warning: <InfoIcon />,
    };

    /**
     * Rendrovanie.
     *
     * @returns {JSX.Element}
     */
    render() {
        const { type, icon, children, className } = this.props;

        const icon_name = icon !== '' ? icon : type;

        return (
            <div className={`message ${type} ${className}`}>
                <SnackbarContent
                    className="message-content"
                    message={<span className="message-text">{this.icons[icon_name]}{children}</span>}
                />
            </div>
        );
    }
}

export { Message };
