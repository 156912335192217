import { CLEAN_MTOKNA_OWNER_SMTP, FETCH_MTOKNA_OWNER_SMTP } from './types';
import { request } from '../functions';

export const fetchMtoknaOwnerSmtp = (screen, id = '') => {
    return dispatch => request(`/mtokna/ownerSmtp/${id}`).then(response => {
        const { data, sync } = response.data;
        const { setUser } = screen.props;

        // Nasetujeme user data
        setUser(sync.user);

        dispatch({ type: FETCH_MTOKNA_OWNER_SMTP, payload: data });
    });
};

export const cleanMtoknaOwnerSmtp = () => ({ type: CLEAN_MTOKNA_OWNER_SMTP });
