import axios from 'axios';
import _ from 'lodash';
import { getUrl } from './getUrl';
import { MARKET } from '../config';

/**
 * GET/POST request.
 *
 * @param {string} uri
 * @param {object|boolean} data
 * @param {string} method
 * @param {object} params
 * @param {boolean} logout
 */
export const request = (uri, data = false, method = 'GET', params = {}, logout = true) => {
    // Nasetujeme config pre axios
    const config = {
        method: (method === 'FILE' || method === 'FILES') ? 'POST' : method,
        url: `${getUrl()}${uri}`,
        headers: {
            'Accept': 'application/json',
            'Content-Type': (method === 'FILE' || method === 'FILES') ? 'multipart/form-data' : 'application/json; charset=UTF-8',
            'Authorization': !_.isUndefined(global.token) ? global.token : '',
        }
    };

    // Zoznam GET parametrov
    params.token = !_.isUndefined(global.token) ? global.token : '';
    params.source = 'frontend';
    params.version = '2';
    params.market_type = MARKET;

    switch (method) {
        case 'GET':
            // Pribalime GET parametre
            params = { ...params, ...data };
            break;

        case 'POST':
            // Mame form data ide o klasicky request
            config.data = JSON.stringify(data);
            break;

        case 'FILE':
            const fileData = new FormData();
            fileData.append('file', data);

            config.data = fileData;
            break;

        case 'FILES':
            const filesData = new FormData();

            _.each(data, (item, key) => {
                filesData.append(key, item);
            });

            config.data = filesData;
            break;

        default:
            break;
    }

    params = _.join(_.map(_.keys(params), key => `${key}=${encodeURIComponent(params[key])}`), '&');

    config.url = `${config.url}?${params}`;

    const result = axios(config);

    return logout ? result.catch(error => window.location = '/logout') : result;
};
