import { CLEAN_MTOKNA_TEMPLATE, FETCH_MTOKNA_TEMPLATE } from './types';
import { __, request, toNumber } from '../functions';

/**
 * Nacitanie sablony.
 *
 * @param {Screen} screen
 * @param {number} id
 */
export const fetchMtoknaTemplate = (screen, id) => {
    return dispatch => request(`/mtokna/viewTemplate/${id}`).then(response => {
        const { data, sync } = response.data;
        const { setUser } = screen.props;

        // Nasetujeme user data
        setUser(sync.user);

        dispatch({ type: FETCH_MTOKNA_TEMPLATE, payload: data });
    });
};

/**
 * Vytvorime sablonu.
 *
 * @param {Screen} screen
 * @param {Object} data
 * @param {string} type
 */
export const createMtoknaTemplate = (screen, data, type) => {
    const edit = toNumber(data.id) > 0;

    return dispatch => request(`/mtokna/${edit ? 'editTemplate' : 'createTemplate'}`, data, 'POST').then(response => {
        const { status } = response.data;

        screen.setState({ loading: false });

        if (status === 'error') {
            // Nepodarilo sa ulozit sablony
            screen.showSnackbar('error', __('Nepodarilo sa uložiť email'));
            return;
        }

        window.location = `/templates/${type}`;
    });
};

/**
 * Vycistenie sablony.
 */
export const cleanMtoknaTemplate = () => ({ type: CLEAN_MTOKNA_TEMPLATE });
