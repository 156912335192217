import React from 'react';
import { connect } from 'react-redux';
import { withCookies } from 'react-cookie';
import _ from 'lodash';
import {ListScreen, Input, Select, Message} from '../components';
import {
    fetchMtoknaCategories,
    cleanMtoknaCategories,
    setUser,
    changeUserSetting,
    deleteMtoknaCategory,
    changeMtoknaCategory,
    changeMtoknaCategoryStatus,
} from '../actions';
import { __, formatDate, isEmptyString, request, toNumber } from '../functions';
import '../assets/styles/mtokna_categories.css';
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from '@mui/icons-material/Delete';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

/**
 * Klienti.
 */
class MtoknaCategoriesScreen extends ListScreen {
    /**
     * Title.
     *
     * @type {string}
     */
    title = __('Kategórie');

    /**
     * Default state.
     *
     * @type {Object}
     */
    state = { ...ListScreen.state, ...{
        lightbox: {
            create: false,
            edit: false,
            delete: false,
            status: false,
        },
    }};

    /**
     * Memory cache.
     *
     * @type {Object}
     */
    memoryCache = {};

    /**
     * Vratime nazov tabulky.
     *
     * @return {string}
     */
    getTableName() {
        return 'mtokna_categories';
    }

    /**
     * Vratime zoznam stlpcov.
     *
     * @return {Array}
     */
    getColumns() {
        return [
            <div>{__('Názov')}</div>,
            <div>{__('Predajca VO')}</div>,
            <div>{__('Nadradená')}</div>,
            <div>{__('Vytvorené')}</div>,
            <div></div>,
        ];
    }

    /**
     * Vratime bunky.
     *
     * @param {Object} item
     *
     * @return {Array}
     */
    getCells(item) {
        const { user, items } = this.props;

        const user_id = toNumber(item.user_id);
        const parent_id = toNumber(item.parent_id);
        const default_category = _.has(items.owner_settings, user_id) ? items.owner_settings[user_id].category_name : '';

        return [
            <div>{item.name}</div>,
            <div>{item._matchingData.Users.email}</div>,
            <div>{parent_id > 0 && _.has(items.categories, user_id) && _.has(items.categories[user_id], parent_id)
                ? items.categories[user_id][parent_id].name
                : default_category}</div>,
            <div>{formatDate(item.created, 'dd.mm.yyyy hh:ii')}</div>,
            <div>
                {!user.mtokna_moderator ? this.renderTableButton(
                    item.id,
                    item.status === 'public' ? __('Verejné') : __('Skryté'),
                    <CheckCircleIcon color={item.status === 'public' ? 'secondary' : 'inherit'} />,
                    (callbackLoading, callback) => this.confirmStatus(item.id, callbackLoading, callback, {
                        status: item.status === 'public' ? 'draft' : 'public',
                    }),
                    {}
                ) : null}
                {!user.mtokna_moderator ? this.renderTableButton(
                    item.id,
                    __('Upraviť'),
                    <EditIcon />,
                    (callbackLoading, callback) => this.confirmEdit(item.id, callbackLoading, callback, {
                        user_id: item.user_id,
                        name: item.name,
                        parent_id: toNumber(item.parent_id) > 0 ? item.parent_id : 'parent',
                    }),
                    {}
                ) : null}
                {!user.mtokna_moderator ? this.renderTableButton(
                    item.id,
                    __('Zmazať'),
                    <DeleteIcon />,
                    (callbackLoading, callback) => this.confirmDelete(item.id, callbackLoading, callback),
                    {}
                ) : null}
            </div>,
        ];
    }

    /**
     * Vratime filter.
     *
     * @return {Object}
     */
    getFilter() {
        const { items } = this.props;

        return _.reduce(items.filter, (result, options, key) => {
            switch (key) {
                case 'user_id':
                    return { ...result, ...{ [key]: {
                        type: 'select',
                        name: __('Predajca VO'),
                        value: '',
                        autocomplete: true,
                        options,
                    }}};

                case 'name':
                    return { ...result, ...{ [key]: {
                        type: 'input',
                        name: __('Názov kategórie'),
                        value: '',
                    }}};

                default:
                    return result;
            }
        }, {});
    }

    getTags() {
        const { user } = this.props;

        if (user.mtokna_moderator) {
            return [];
        }

        return [
            {
                name: __('Nová kategória'),
                onClick: () => this.showLightbox('create', {
                    loading: false,
                    data: {
                        user_id: '',
                        name: '',
                        parent_id: 'parent',
                    },
                }),
            }
        ];
    }

    /**
     * Vratime empty text.
     *
     * @return {string}
     */
    getEmptyText() {
        return __('Zatiaľ nemáte žiadnu kategóriu');
    }

    /**
     * Event po zmene hesla.
     *
     * @param {string} key
     * @param {string} value
     */
    onChangeEdit(key, value) {
        const { lightbox } = this.state;

        this.setState({ lightbox: { ...lightbox, edit: {
            ...lightbox.edit,
            data: { ...lightbox.edit.data, [key]: value }
        } } });
    }

    /**
     * Event po zmene create.
     *
     * @param {string} key
     * @param {string} value
     */
    onChangeCreate(key, value) {
        const { lightbox } = this.state;

        this.setState({ lightbox: { ...lightbox, create: {
            ...lightbox.create,
            data: { ...lightbox.create.data, [key]: value }
        } } });
    }

    /**
     * Event po zmene create.
     *
     * @param {string} value
     */
    onChangeCreateLoading(value) {
        const { lightbox } = this.state;

        this.setState({ lightbox: { ...lightbox, create: { ...lightbox.create, loading: value } } })
    }

    /**
     * Zobrazime lightbox na email.
     *
     * @param {number} id
     * @param {function} callbackLoading
     * @param {function} callback
     * @param {Object} data
     */
    confirmEdit(id, callbackLoading, callback, data) {
        this.showLightbox('edit', { id, callbackLoading, callback, data });
    }

    /**
     * Zobrazime lightbox na email.
     *
     * @param {number} id
     * @param {function} callbackLoading
     * @param {function} callback
     * @param {Object} data
     */
    confirmStatus(id, callbackLoading, callback, data) {
        this.showLightbox('status', { id, callbackLoading, callback, data });
    }

    /**
     * Zobrazime lightbox na email.
     *
     * @param {number} id
     * @param {function} callbackLoading
     * @param {function} callback
     */
    confirmDelete(id, callbackLoading, callback) {
        this.showLightbox('delete', { id, callbackLoading, callback });
    }

    /**
     * Ulozenie emailu.
     */
    async edit() {
        const { changeMtoknaCategory } = this.props;
        const { lightbox } = this.state;

        if (isEmptyString(lightbox.edit.data.name)) {
            this.showSnackbar('error', __('Nie je vyplnený názov'));
            return;
        }

        // Zavolame loading
        lightbox.edit.callbackLoading();

        // Zavrieme lightbox
        this.closeLightbox('edit');

        // Zmenime email
        await changeMtoknaCategory(this, lightbox.edit.id, {
            name: lightbox.edit.data.name,
            parent_id: lightbox.edit.data.parent_id !== 'parent' ? lightbox.edit.data.parent_id : null,
        });

        // Zavolame callback
        lightbox.edit.callback();
    }

    /**
     * Ulozenie emailu.
     */
    async changeStatus() {
        const { changeMtoknaCategoryStatus } = this.props;
        const { lightbox } = this.state;

        // Zavolame loading
        lightbox.status.callbackLoading();

        // Zavrieme lightbox
        this.closeLightbox('status');

        // Zmenime email
        await changeMtoknaCategoryStatus(this, lightbox.status.id, lightbox.status.data.status);

        // Zavolame callback
        lightbox.status.callback();
    }

    /**
     * Zmazanie miesta.
     */
    async delete() {
        const { deleteMtoknaCategory, items } = this.props;
        const { lightbox } = this.state;

        // Zavolame loading
        lightbox.delete.callbackLoading();

        // Zavrieme lightbox
        this.closeLightbox('delete');

        // Zmazeme klienta
        await deleteMtoknaCategory(this, lightbox.delete.id);

        // Zavolame callback
        lightbox.delete.callback();

        // Znovu nacitame zoznam od prvej stranky
        this.fetchList(1, 0, items.filtered);
    }

    create() {
        const { items } = this.props;
        const { lightbox } = this.state;

        if (toNumber(lightbox.create.data.user_id) === 0) {
            this.showSnackbar('error', __('Nie je vyplnený predajca'));
            return;
        } else if (items.owner_settings[toNumber(lightbox.create.data.user_id)].category_name === '') {
            this.showSnackbar('error', __('Nie je vyplnená hlavná kategória v nastaveniach predajcu'));
            return;
        } else if (isEmptyString(lightbox.create.data.name)) {
            this.showSnackbar('error', __('Nie je vyplnený názov'));
            return;
        }

        this.onChangeCreateLoading(true);

        request('/mtokna/createCategory', { user_id: lightbox.create.data.user_id, data: {
            name: lightbox.create.data.name,
            parent_id: lightbox.create.data.parent_id !== 'parent' ? lightbox.create.data.parent_id : null,
        } }, 'POST').then(response => {
            const { status } = response.data;

            if (status === 'error') {
                this.onChangeCreateLoading(false);
                this.showSnackbar('error', __('Kategóriu sa nepodarilo vytvoriť'));
                return;
            }

            // Refreshneme
            window.location = window.location.pathname;
        });
    }

    getParentCategories(type) {
        const { items } = this.props;
        const { lightbox } = this.state;

        const user_id = toNumber(lightbox[type].data.user_id);

        let user_categories = {};

        if (_.has(items.categories, user_id)) {
            let childs = [];

            if (type === 'edit') {
                childs = this.getChilds(items.categories[user_id], lightbox.edit.id);
            }

            user_categories = _.reduce(items.categories[user_id], (result, item) => {
                const id = toNumber(item.id);

                if (type === 'edit' && id === toNumber(lightbox.edit.id)) {
                    // Not allow same category to parent
                    return result;
                }

                if (type === 'edit' && _.includes(childs, id)) {
                    // Not allow child category to parent
                    return result;
                }

                return { ...result, [item.id]: item.name };
            }, {});
        }

        return { parent: items.owner_settings[user_id].category_name, ...user_categories };
    }

    getChilds(categories, category_id) {
        let childs = [];

        for (let i = 0; i < 10; i++) {
            _.each(categories, category => {
                const id = toNumber(category.id);

                if (
                    i === 0
                    && toNumber(category.parent_id) === toNumber(category_id)
                    && !_.includes(childs, id)
                ) {
                    childs = [ ...childs, id ];
                } else if (
                    _.includes(childs, toNumber(category.parent_id))
                    && !_.includes(childs, id)
                ) {
                    childs = [ ...childs, id ];
                }
            });
        }

        return childs;
    }

    /**
     * Rendrujeme screen.
     *
     * @return {JSX.Element|null}
     */
    renderScreen() {
        const { items } = this.props;
        const { lightbox } = this.state;

        return (
            <div>
                {this.renderLightbox(
                    'delete',
                    __('Naozaj chcete zmazať túto kategóriu?'),
                    null,
                    __('Áno'),
                    __('Nie'),
                    () => this.delete()
                )}
                {this.renderLightbox(
                    'status',
                    __('Naozaj chcete zmeniť stav tejto kategórii?'),
                    null,
                    __('Áno'),
                    __('Nie'),
                    () => this.changeStatus()
                )}
                {this.renderLightbox(
                    'edit',
                    __('Úprava kategórie'),
                    !_.isEmpty(lightbox.edit) ? <div>
                        <div>
                            <Select
                                label={__('Predajca VO')}
                                value={lightbox.edit.data.user_id}
                                options={items.owners}
                                disabled
                            />
                        </div>
                        <div style={{ marginTop: '20px' }}>
                            <Input
                                label={__('Názov')}
                                value={lightbox.edit.data.name}
                                onChange={value => this.onChangeEdit('name', value)}
                            />
                        </div>
                        <div style={{ marginTop: '20px' }}>
                            {items.owner_settings[toNumber(lightbox.edit.data.user_id)].category_name === ''
                                ? <Message type="warning">{__('Nie je vyplnená hlavná kategória v nastaveniach predajcu')}</Message>
                                : <Select
                                    label={__('Nadradená')}
                                    value={lightbox.edit.data.parent_id}
                                    onChange={value => this.onChangeEdit('parent_id', value)}
                                    options={this.getParentCategories('edit')}
                                    allowEmpty={false}
                                />}
                        </div>
                    </div> : null,
                    __('Uložiť'),
                    __('Zrušiť'),
                    () => this.edit()
                )}
                {this.renderLightbox(
                    'create',
                    __('Nová kategória'),
                    !_.isEmpty(lightbox.create) ? (lightbox.create.loading ? this.renderLoading(20) : <div>
                        <div>
                            <Select
                                label={__('Predajca VO')}
                                value={lightbox.create.data.user_id}
                                onChange={value => this.onChangeCreate('user_id', value)}
                                options={items.owners}
                            />
                        </div>
                        <div style={{ marginTop: '20px' }}>
                            <Input
                                label={__('Názov')}
                                value={lightbox.create.data.name}
                                onChange={value => this.onChangeCreate('name', value)}
                            />
                        </div>
                        {toNumber(lightbox.create.data.user_id) > 0 ? <div style={{ marginTop: '20px' }}>
                            {items.owner_settings[toNumber(lightbox.create.data.user_id)].category_name === ''
                                ? <Message type="warning">{__('Nie je vyplnená hlavná kategória v nastaveniach predajcu')}</Message>
                                : <Select
                                    label={__('Nadradená')}
                                    value={lightbox.create.data.parent_id}
                                    onChange={value => this.onChangeCreate('parent_id', value)}
                                    options={this.getParentCategories('create')}
                                    allowEmpty={false}
                                />}
                        </div> : null}
                    </div>) : null,
                    __('Uložiť'),
                    __('Zrušiť'),
                    () => this.create()
                )}
            </div>
        );
    }
}

const stateToProps = ({ mtokna_categories, user }) => ({ items: mtokna_categories, user });

export default withCookies(connect(stateToProps, {
    fetch: fetchMtoknaCategories,
    clean: cleanMtoknaCategories,
    setUser,
    changeUserSetting,
    deleteMtoknaCategory,
    changeMtoknaCategory,
    changeMtoknaCategoryStatus,
})(MtoknaCategoriesScreen));
