import React from 'react';
import { connect } from 'react-redux';
import { withCookies } from 'react-cookie';
import _ from 'lodash';
import {Button, Input, ListScreen, Select} from '../components';
import {
    fetchMtoknaDogProducts,
    cleanMtoknaDogProducts,
    setUser,
    changeUserSetting,
    changeMtoknaDogProduct,
    deleteMtoknaDogProduct,
} from '../actions';
import {__, formatDate, isEmptyString, request} from '../functions';
import '../assets/styles/mtokna_dog_products.css';
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from '@mui/icons-material/Delete';

/**
 * Klienti.
 */
class MtoknaDogProductsScreen extends ListScreen {
    /**
     * Title.
     *
     * @type {string}
     */
    title = __('Produkty');

    /**
     * Default state.
     *
     * @type {Object}
     */
    state = { ...ListScreen.state, ...{
        lightbox: {
            delete: false,
            create: false,
            edit: false,
        },
    }};

    /**
     * Memory cache.
     *
     * @type {Object}
     */
    memoryCache = {};

    /**
     * Vratime nazov tabulky.
     *
     * @return {string}
     */
    getTableName() {
        return 'mtokna_dog_products';
    }

    /**
     * Vratime zoznam stlpcov.
     *
     * @return {Array}
     */
    getColumns() {
        return [
            <div>{__('SKU')}</div>,
            <div>{__('Názov')}</div>,
            <div>{__('XML zdroj')}</div>,
            <div>{__('Limit pod (vrátane)')}</div>,
            <div>{__('Limit nad (vrátane)')}</div>,
            <div>{__('Vytvorené')}</div>,
            <div></div>,
        ];
    }

    /**
     * Vratime bunky.
     *
     * @param {Object} item
     *
     * @return {Array}
     */
    getCells(item) {
        const { user } = this.props;

        return [
            <div>{_.truncate(item.sku, { length: 32 })}</div>,
            <div>{_.truncate(item.name, { length: 64 })}</div>,
            <div>{_.truncate(item._matchingData.MtoknaDogs.name, { length: 32 })}</div>,
            <div>{item.limit_down}</div>,
            <div>{item.limit_up}</div>,
            <div>{formatDate(item.created, 'dd.mm.yyyy')}</div>,
            <div>
                {!user.mtokna_moderator ? this.renderTableButton(
                    item.id,
                    __('Upraviť'),
                    <EditIcon />,
                    (callbackLoading, callback) => this.confirmEdit(item, callbackLoading, callback),
                    {}
                ) : null}
                {!user.mtokna_moderator ? this.renderTableButton(
                    item.id,
                    __('Zmazať'),
                    <DeleteIcon />,
                    (callbackLoading, callback) => this.confirmDelete(item.id, callbackLoading, callback),
                    {}
                ) : null}
            </div>,
        ];
    }

    /**
     * Vratime filter.
     *
     * @return {Object}
     */
    getFilter() {
        const { items } = this.props;

        return _.reduce(items.filter, (result, options, key) => {
            switch (key) {
                case 'sku':
                    return { ...result, ...{ [key]: {
                        type: 'input',
                        name: __('SKU'),
                        value: '',
                    }}};

                case 'mtokna_dog_id':
                    return { ...result, ...{ [key]: {
                        type: 'select',
                        name: __('XML zdroj'),
                        value: '',
                        options,
                        multiple: true,
                    }}};

                default:
                    return result;
            }
        }, {});
    }

    getTags() {
        const { user } = this.props;

        if (user.mtokna_moderator) {
            return [];
        }

        return [
            {
                name: __('Nový produkt'),
                onClick: () => this.showLightbox('create', {
                    loading: false,
                    data: {
                        mtokna_dog_id: '',
                        sku: '',
                        name: '',
                        limit_down: '',
                        limit_up: '',
                    },
                }),
            }
        ];
    }

    /**
     * Vratime empty text.
     *
     * @return {string}
     */
    getEmptyText() {
        return __('Zatiaľ nemáte žiadne produkty');
    }

    /**
     * Event po zmene create.
     *
     * @param {string} key
     * @param {string} value
     */
    onChangeEdit(key, value) {
        const { lightbox } = this.state;

        this.setState({ lightbox: { ...lightbox, edit: {
            ...lightbox.edit,
            data: { ...lightbox.edit.data, [key]: value }
        } } });
    }

    /**
     * Event po zmene create.
     *
     * @param {string} value
     */
    onChangeEditLoading(value) {
        const { lightbox } = this.state;

        this.setState({ lightbox: { ...lightbox, edit: { ...lightbox.edit, loading: value } } })
    }

    /**
     * Event po zmene create.
     *
     * @param {string} key
     * @param {string} value
     */
    onChangeCreate(key, value) {
        const { lightbox } = this.state;

        this.setState({ lightbox: { ...lightbox, create: {
            ...lightbox.create,
            data: { ...lightbox.create.data, [key]: value }
        } } });
    }

    /**
     * Event po zmene create.
     *
     * @param {string} value
     */
    onChangeCreateLoading(value) {
        const { lightbox } = this.state;

        this.setState({ lightbox: { ...lightbox, create: { ...lightbox.create, loading: value } } })
    }

    /**
     * Zobrazime lightbox na email.
     *
     * @param {object} item
     * @param {function} callbackLoading
     * @param {function} callback
     */
    confirmEdit(item, callbackLoading, callback) {
        this.showLightbox('edit', {
            id: item.id,
            data: {
                sku: item.sku,
                name: item.name,
                limit_down: item.limit_down,
                limit_up: item.limit_up,
            },
            callbackLoading,
            callback,
        });
    }

    /**
     * Zobrazime lightbox na email.
     *
     * @param {number} id
     * @param {function} callbackLoading
     * @param {function} callback
     */
    confirmDelete(id, callbackLoading, callback) {
        this.showLightbox('delete', { id, callbackLoading, callback });
    }

    /**
     * Zmazanie miesta.
     */
    async delete() {
        const { deleteMtoknaDogProduct, items } = this.props;
        const { lightbox } = this.state;

        // Zavolame loading
        lightbox.delete.callbackLoading();

        // Zavrieme lightbox
        this.closeLightbox('delete');

        // Zmazeme klienta
        await deleteMtoknaDogProduct(this, lightbox.delete.id);

        // Zavolame callback
        lightbox.delete.callback();

        // Znovu nacitame zoznam od prvej stranky
        this.fetchList(1, 0, items.filtered);
    }

    create() {
        const { lightbox } = this.state;
        const { items } = this.props;

        if (isEmptyString(lightbox.create.data.mtokna_dog_id)) {
            this.showSnackbar('error', __('XML zdroj nie je vyplnený'));
            return;
        }

        if (isEmptyString(lightbox.create.data.sku)) {
            this.showSnackbar('error', __('SKU nie je vyplnené'));
            return;
        }

        this.onChangeCreateLoading(true);

        request('/mtokna/createProductDog', lightbox.create.data, 'POST').then(response => {
            const { status } = response.data;

            this.onChangeCreateLoading(false);

            if (status === 'error') {
                // Nepodarilo sa nahrat prilohu
                this.showSnackbar('error', __('Nepodarilo sa vytvoriť produkt'));
                return;
            }

            this.closeLightbox('create');

            // Znovu nacitame zoznam od prvej stranky
            this.fetchList(1, 0, items.filtered);
        });
    }

    async edit() {
        const { changeMtoknaDogProduct } = this.props;
        const { lightbox } = this.state;

        if (isEmptyString(lightbox.edit.data.sku)) {
            this.showSnackbar('error', __('SKU nie je vyplnené'));
            return;
        }

        // Zavolame loading
        lightbox.edit.callbackLoading();

        // Zavrieme lightbox
        this.closeLightbox('edit');

        // Zmazeme klienta
        await changeMtoknaDogProduct(this, lightbox.edit.id, lightbox.edit.data);

        // Zavolame callback
        lightbox.edit.callback();
    }

    /**
     * Rendrujeme screen.
     *
     * @return {JSX.Element|null}
     */
    renderScreen() {
        const { items } = this.props;
        const { lightbox } = this.state;

        return (
            <div>
                {this.renderLightbox(
                    'delete',
                    __('Naozaj chcete zmazať tento produkt?'),
                    null,
                    __('Áno'),
                    __('Nie'),
                    () => this.delete()
                )}
                {this.renderLightbox(
                    'create',
                    __('Nový produkt'),
                    !_.isEmpty(lightbox.create) ? (lightbox.create.loading ? this.renderLoading(20) : <div className="product-dog-products-create">
                        <Select
                            label={__('XML zdroj')}
                            value={lightbox.create.data.mtokna_dog_id}
                            options={items.dogs}
                            onChange={value => this.onChangeCreate('mtokna_dog_id', value)}
                            allowEmpty={false}
                        />
                        <Input
                            label={__('SKU')}
                            value={lightbox.create.data.sku}
                            onChange={value => this.onChangeCreate('sku', value)}
                            required
                        />
                        <Input
                            label={__('Názov')}
                            value={lightbox.create.data.name}
                            onChange={value => this.onChangeCreate('name', value)}
                        />
                        <Input
                            label={__('Limit pod (vrátane)')}
                            value={lightbox.create.data.limit_down}
                            onChange={value => this.onChangeCreate('limit_down', value)}
                        />
                        <Input
                            label={__('Limit nad (vrátane)')}
                            value={lightbox.create.data.limit_up}
                            onChange={value => this.onChangeCreate('limit_up', value)}
                        />
                    </div>) : null,
                    __('Uložiť'),
                    __('Zrušiť'),
                    () => this.create()
                )}
                {this.renderLightbox(
                    'edit',
                    __('Upraviť produkt'),
                    !_.isEmpty(lightbox.edit) ? (lightbox.edit.loading ? this.renderLoading(20) : <div className="product-dog-products-create">
                        <Input
                            label={__('SKU')}
                            value={lightbox.edit.data.sku}
                            onChange={value => this.onChangeEdit('sku', value)}
                            required
                        />
                        <Input
                            label={__('Názov')}
                            value={lightbox.edit.data.name}
                            onChange={value => this.onChangeEdit('name', value)}
                        />
                        <Input
                            label={__('Limit pod (vrátane)')}
                            value={lightbox.edit.data.limit_down}
                            onChange={value => this.onChangeEdit('limit_down', value)}
                        />
                        <Input
                            label={__('Limit nad (vrátane)')}
                            value={lightbox.edit.data.limit_up}
                            onChange={value => this.onChangeEdit('limit_up', value)}
                        />
                    </div>) : null,
                    __('Uložiť'),
                    __('Zrušiť'),
                    () => this.edit()
                )}
            </div>
        );
    }
}

const stateToProps = ({ mtokna_dog_products, user }) => ({ items: mtokna_dog_products, user });

export default withCookies(connect(stateToProps, {
    fetch: fetchMtoknaDogProducts,
    clean: cleanMtoknaDogProducts,
    setUser,
    changeUserSetting,
    changeMtoknaDogProduct,
    deleteMtoknaDogProduct,
})(MtoknaDogProductsScreen));
