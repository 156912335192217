import React from 'react';
import { connect } from 'react-redux';
import { withCookies } from 'react-cookie';
import _ from 'lodash';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import Editor from 'ckeditor5-build-full';
import {Tooltip, Chip, TableBody, TableRow, TableCell, ButtonBase, Table} from '@mui/material';
import HelpIcon from '@mui/icons-material/Help';
import UploadIcon from '@mui/icons-material/Publish';
import DeleteIcon from '@mui/icons-material/Delete';
import { Screen, Message, Input, Select, Checkbox, Button } from '../components';
import { fetchTemplate, setUser, createTemplate, cleanTemplate } from '../actions';
import {__, toNumber, isEmptyString, request, formatDate, formatAmount} from '../functions';
import { TEMPLATES_EVENTS, PLACEHOLDERS, ADDITIONAL_PLACEHOLDERS, EMAIL_PLACEHOLDERS } from '../config';
import '../assets/styles/template.css';

/**
 * Email.
 */
class TemplateScreen extends Screen {
    /**
     * Title.
     *
     * @type {string}
     */
    title = __('Nový email');

    /**
     * Default state.
     *
     * @type {Object}
     */
    state = {
        data: {},
        loading: false,
        loadingUpload: false,
        lightbox: {
            templates: false,
        },
    };

    /**
     * Default nastavenia.
     *
     * @type {{
     *     manual: boolean,
     * }}
     */
    defaultSettings = {
        manual: true,
    };

    /**
     * Komponenta bola pripojena.
     *
     * @return boolean
     */
    async componentDidMount() {
        if (super.componentDidMount()) {
            const { fetchTemplate } = this.props;

            // Vytiahneme id emailu
            const templateId = this.getTemplateId();

            // Nasetujeme title
            this.setTitle(templateId > 0 ? __('Úprava emailu') : __('Nový email'));

            // Nacitame email
            await fetchTemplate(this, templateId);

            const { template } = this.props;
            const defaultEvent = _.keys(TEMPLATES_EVENTS)[0];

            let data = {
                name: '',
                event: defaultEvent,
                subject: '',
                text: TEMPLATES_EVENTS[defaultEvent].text,
                settings: TEMPLATES_EVENTS[defaultEvent].settings,
            };

            if (!_.isEmpty(template.template)) {
                // Mame data z editu
                data = {
                    name: template.template.name,
                    event: template.template.event,
                    subject: template.template.subject,
                    text: template.template.text,
                    settings: template.template.settings,
                };

                if (!_.has(data.settings, 'payment_type') && _.has(TEMPLATES_EVENTS[template.template.event].settings, 'payment_type')) {
                    // V starych datach nie je payment type a v default sablone uz je
                    data = { ...data, settings: { ...data.settings, payment_type: '' } };
                }

                if (!_.has(data.settings, 'delivery_type') && _.has(TEMPLATES_EVENTS[template.template.event].settings, 'delivery_type')) {
                    // V starych datach nie je delivery type a v default sablone uz je
                    data = { ...data, settings: { ...data.settings, delivery_type: '' } };
                }
            }

            if (!_.has(data.settings, 'manual')) {
                // Sablona nema default nastavenie
                data = { ...data, settings: { ...data.settings, ...this.defaultSettings } };
            }

            this.setState({ data });
        }

        return true;
    }

    /**
     * Komponenta bude odpojena.
     */
    componentWillUnmount() {
        const { cleanTemplate } = this.props;

        cleanTemplate();
    }

    /**
     * Vratime id sablony.
     *
     * @return {number}
     */
    getTemplateId() {
        const { location } = this.props;

        return toNumber(location.pathname.replace('/template/', ''));
    }

    /**
     * Ulozenie.
     */
    save() {
        let { data } = this.state;

        let error = '';

        if (isEmptyString(data.name)) {
            // Nie je vyplneny nazov
            error = __('Nie je vyplnený názov');
        } else if (isEmptyString(data.subject)) {
            // Nie je vyplneny predmet
            error = __('Nie je vyplnený predmet');
        } else if (isEmptyString(data.text)) {
            // Nie je vyplneny text
            error = __('Nie je vyplnená šablóna');
        }

        if (error !== '') {
            // Mame error
            this.showSnackbar('error', error);
            return;
        }

        this.setState({ loading: true });

        const { createTemplate } = this.props;

        // Ulozime sablonu
        createTemplate(this, { ...data, id: this.getTemplateId() });
    }

    /**
     * Event po zmene dat.
     *
     * @param {string} type
     * @param {string} value
     */
    onChange(type, value) {
        const { data } = this.state;

        let additional = {};

        if (type === 'event') {
            // Menime event, nastavime settings
            additional = {
                text: TEMPLATES_EVENTS[value].text,
                settings: { ...TEMPLATES_EVENTS[value].settings, ...this.defaultSettings }
            };

            if (_.has(additional.settings, 'state')) {
                // Mame stav objednavky, nasetujeme default
                const { template } = this.props;

                additional = { ...additional, settings: { ...additional.settings, state: template.eshop_data.states[0].id } };
            }
        }

        this.setState({ data: { ...data, [type]: value, ...additional } });
    }

    /**
     * Event po zmene nastavenia.
     *
     * @param {string} type
     * @param {string|boolean} value
     */
    onChangeSetting(type, value) {
        const { data } = this.state;

        this.setState({ data: { ...data, settings: { ...data.settings, [type]: value } } });
    }

    /**
     * Event po kliku na placeholder.
     *
     * @param {string} placeholder
     */
    onClickPlaceholder(placeholder) {
        // Nastavime placeholder do clipboardu
        navigator.clipboard.writeText(placeholder);

        this.showSnackbar('success', __('Skopirované do schránky'));
    }

    /**
     * Event po uploade prilohy.
     *
     * @param {Object} file
     */
    uploadAttachment(file) {
        if (file.type !== 'application/pdf') {
            // Nie je to pdf
            this.showSnackbar('error', __('Neplatný typ súboru'));
            return;
        }

        if (file.size > 5242880) {
            // Velky subor
            this.showSnackbar('error', __('Súbor je väčší ako 5MB'));
            return;
        }

        this.setState({ loadingUpload: true });

        request('/templates/uploadAttachment', file, 'FILE').then(response => {
            const { status } = response.data;

            this.setState({ loadingUpload: false });

            if (status === 'error') {
                // Nepodarilo sa nahrat prilohu
                this.showSnackbar('error', __('Nepodarilo sa nahrať prílohu'));
                return;
            }

            // Nastavime prilohu
            this.onChangeSetting('attachment', file.name);
        });
    }

    /**
     * Zmazanie prilohy.
     *
     * @param {string} name
     */
    deleteAttachment(name) {
        this.setState({ loadingUpload: true });

        request('/templates/deleteAttachment', { name }, 'POST').then(response => {
            const { status } = response.data;

            this.setState({ loadingUpload: false });

            if (status === 'error') {
                // Nepodarilo sa zmazat prilohu
                this.showSnackbar('error', __('Nepodarilo sa zmazať prílohu'));
                return;
            }

            // Zrusime prilohu
            this.onChangeSetting('attachment', '');
        });
    }

    showTemplates() {
        const { lightbox } = this.state;

        const lightbox_data = {
            loading: true,
            items: [],
            eshop_data: {},
        };

        this.showLightbox('templates', lightbox_data);

        request('/templates/fullList').then(response => {
            const { status, data } = response.data;

            if (status === 'error') {
                this.closeTemplates();
                return;
            }

            this.setState({ lightbox: { ...lightbox, templates: { ...lightbox_data, items: data.items, eshop_data: data.eshop_data, loading: false } } });
        });
    }

    fillTemplate(subject, text) {
        const { data } = this.state;

        this.setState({ data: { ...data, subject, text } });

        this.closeTemplates();
    }

    closeTemplates() {
        this.closeLightbox('templates');
    }

    /**
     * Rendrovanie.
     *
     * @returns {JSX.Element}
     */
    render() {
        const { template, user } = this.props;
        const { data, loading, loadingUpload, lightbox } = this.state;

        if (_.isEmpty(data)) {
            // Data nie su nacitane
            return this.renderLoading();
        }

        if (!this.hasPermission('templates-edit')) {
            // Nema pravo
            return null;
        }

        // Vytiahneme zoznam stavov
        const states = _.reduce(
            template.eshop_data.states,
            (result, { id, name }) => ({ ...result, [id]: name }),
            {}
        );

        // Vytiahneme typy uhrad
        const payment_types = _.reduce(
            template.eshop_data.payments,
            (result, { id, name }) => ({ ...result, [id]: name }),
            {}
        );

        // Vytiahneme typy dodani
        const delivery_types = _.reduce(
            template.eshop_data.delivers,
            (result, { id, name }) => ({ ...result, [id]: name }),
            {}
        );

        // Je to edit?
        const isEdit = this.getTemplateId();

        // Vytiahneme settingy ktore zobrazujeme
        const show_settings = _.reduce(data.settings, (result, value, key) => {
            if (_.includes(['state', 'payment_type', 'delivery_type', 'attachment', 'delay'], key)) {
                // Nechceme zobrazovat
                return result;
            }

            return { ...result, [key]: value };
        }, {});

        // Priloha
        const attachment = _.has(data.settings, 'attachment') ? data.settings.attachment : null;

        return (
            <div className="template">
                <div className="template__header">
                    <div className="template__header__left">
                        <div className="template__header__left__title">
                            {isEdit ? __('Úprava emailu') : __('Nový email')}
                        </div>
                    </div>
                </div>
                <div className="template__content">
                    <Message type="info">{__('Do emailu môžete vkladať špecialné slova ktoré sa pri odoslaní automaticky doplnia podľa objednávky.')}</Message>
                    <div className="template__content__panels">
                        <div className="template__content__panels__panel">
                            <div className="template__content__panels__panel__title">{__('Špeciálne slová')}</div>
                            <div className="template__content__panels__panel__text">{__('Kliknutím skopírujete do schránky.')}</div>
                            <div className="template__content__panels__panel__placeholders">
                                {_.map({ ...PLACEHOLDERS, ...ADDITIONAL_PLACEHOLDERS, ...EMAIL_PLACEHOLDERS }, (text, placeholder) => {
                                    if (_.includes(['{order.payment_type}', '{order.delivery_type}'], placeholder)) {
                                        // Nechceme zobrazovat
                                        return null;
                                    }

                                    return (
                                        <div key={placeholder}>
                                            <Tooltip title={text}>
                                                <Chip
                                                    onClick={() => this.onClickPlaceholder(placeholder)}
                                                    label={placeholder}
                                                    clickable
                                                    color="primary"
                                                    deleteIcon={<HelpIcon />}
                                                />
                                            </Tooltip>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                        <div className="template__content__panels__panel">
                            <Input
                                label={__('Názov')}
                                value={data.name}
                                onChange={value => this.onChange('name', value)}
                            />
                            <Select
                                label={__('Udalosť')}
                                value={data.event}
                                onChange={value => this.onChange('event', value)}
                                options={_.reduce(TEMPLATES_EVENTS, (result, event, key) => ({ ...result, [key]: event.name }), {})}
                                allowEmpty={false}
                            />
                            {_.has(data.settings, 'state') ? <Select
                                label={__('Stav objednávky')}
                                options={states}
                                value={data.settings.state}
                                onChange={value => this.onChangeSetting('state', value)}
                                allowEmpty={false}
                            /> : null}
                            {_.has(data.settings, 'payment_type') ? <Select
                                label={__('Typ platby objednávky')}
                                options={payment_types}
                                value={data.settings.payment_type}
                                onChange={value => this.onChangeSetting('payment_type', value)}
                            /> : null}
                            {_.has(data.settings, 'delivery_type') ? <Select
                                label={__('Typ dodania objednávky')}
                                options={delivery_types}
                                value={data.settings.delivery_type}
                                onChange={value => this.onChangeSetting('delivery_type', value)}
                            /> : null}
                            <Input
                                label={__('Predmet')}
                                value={data.subject}
                                onChange={value => this.onChange('subject', value)}
                                placeholder={__('Objednávka {order.number}')}
                            />
                            <div className="input">
                                <CKEditor
                                    editor={Editor}
                                    data={data.text}
                                    onChange={(event, editor) => this.onChange('text', editor.getData())}
                                />
                            </div>
                            {_.includes([35,38,39,40,41,42,43,44,75,92,93,128,545], toNumber(user.user_eshop_id)) ? <Button
                                onClick={() => this.showTemplates()}
                                style={{ marginTop: '20px' }}
                            >{__('Načítať šablónu')}</Button> : null}
                            <div
                                onClick={attachment ? () => this.deleteAttachment(attachment) : null}
                                className="template__content__panels__panel__upload"
                            >
                                {loadingUpload ? this.renderLoading(20) : null}
                                {!loadingUpload && !attachment ? <UploadIcon /> : null}
                                {!loadingUpload && !attachment ? <div className="template__content__panels__panel__upload__text">{__('Nahrajte prílohu vo formáte PDF.')}</div> : null}
                                {!loadingUpload && !attachment ? <input type="file" onChange={event => this.uploadAttachment(event.target.files[0])} /> : null}
                                {!loadingUpload && attachment ? <div className="template__content__panels__panel__upload__text">
                                    {attachment}
                                    <DeleteIcon />
                                </div> : null}
                            </div>
                            <div className="template__content__panels__panel__settings">
                                <div className="template__content__panels__panel__settings__title">{__('Nastavenia')}</div>
                                <Input
                                    label={__('Zdržanie odoslania o počet hodín')}
                                    value={data.settings.delay}
                                    onChange={value => this.onChangeSetting('delay', value)}
                                    type="number"
                                />
                                {_.map(show_settings, (setting, key) => {
                                    const label = key === 'manual'
                                        ? __('Odosielať pri manuálnom volaní udalosti')
                                        : TEMPLATES_EVENTS[data.event].labels[key];

                                    return (
                                        <Checkbox
                                            label={label}
                                            value={setting}
                                            onChange={checked => this.onChangeSetting(key, checked)}
                                            key={key}
                                        />
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                    <Button
                        onClick={() => this.save()}
                        loading={loading}
                        disabled={loadingUpload}
                        className="template__content__button"
                        color="green"
                    >{__('Uložiť')}</Button>
                </div>
                {this.renderLightbox(
                    'templates',
                    __('Šablóny'),
                    !_.isEmpty(lightbox.templates) ? (<div>{lightbox.templates.loading ? this.renderLoading(20) : <div>
                        <Table size="small">
                            <TableBody>
                                {_.map(lightbox.templates.items, (item, key) => {
                                    let callback = () => this.fillTemplate(item.subject, item.text);

                                    const states = _.reduce(
                                        lightbox.templates.eshop_data[item.user_eshop_id].states,
                                        (result, { id, name }) => ({ ...result, [id]: name }),
                                        {}
                                    );

                                    // Vytiahneme typy uhrad
                                    const payment_types = _.reduce(
                                        lightbox.templates.eshop_data[item.user_eshop_id].payments,
                                        (result, { id, name }) => ({ ...result, [id]: name }),
                                        {}
                                    );

                                    // Vytiahneme typy dodani
                                    const delivery_types = _.reduce(
                                        lightbox.templates.eshop_data[item.user_eshop_id].delivers,
                                        (result, { id, name }) => ({ ...result, [id]: name }),
                                        {}
                                    );

                                    return (
                                        <TableRow key={key}>
                                            <TableCell><ButtonBase onClick={callback}><span style={{ color: '#5c70ff' }}>{_.truncate(item.name, { length: 64 })}</span></ButtonBase></TableCell>
                                            <TableCell>{item._matchingData.UserEshops.name}</TableCell>
                                            <TableCell>{TEMPLATES_EVENTS[item.event].name}</TableCell>
                                            <TableCell>{_.has(item.settings, 'state') && item.settings.state !== ''
                                                ? (_.has(states, item.settings.state) ? states[item.settings.state] : item.settings.state)
                                                : '-'}</TableCell>
                                            <TableCell>{_.has(item.settings, 'payment_type') && item.settings.payment_type !== ''
                                                ? (_.has(payment_types, item.settings.payment_type) ? _.truncate(payment_types[item.settings.payment_type], { length: 32 }) : item.settings.payment_type)
                                                : '-'}</TableCell>
                                            <TableCell>{_.has(item.settings, 'delivery_type') && item.settings.delivery_type !== ''
                                                ? (_.has(delivery_types, item.settings.delivery_type) ? _.truncate(delivery_types[item.settings.delivery_type], { length: 32 }) : item.settings.delivery_type)
                                                : '-'}</TableCell>
                                            <TableCell>{formatDate(item.created, 'dd.mm.yyyy')}</TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </div>}</div>) : null,
                    '',
                    __('Zrušiť'),
                    () => {},
                    false,
                    () => this.closeTemplates(),
                    'mtokna-templates-lightbox'
                )}
                {this.renderSnackbar()}
            </div>
        );
    }
}

const stateToProps = ({ template, user }) => ({ template, user });

export default withCookies(connect(stateToProps, {
    fetchTemplate,
    setUser,
    createTemplate,
    cleanTemplate,
})(TemplateScreen));
