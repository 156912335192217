import {
    FETCH_INVOICE_PAYMENTS,
    CLEAN_INVOICE_PAYMENTS,
    DELETE_INVOICE_PAYMENT,
    DELETE_INVOICE_PAYMENTS,
    ADDON_INVOICE_PAYMENTS,
} from '../actions/types';

const INVOICE_PAYMENTS_DEFAULT_STATE = {};

/**
 * Reducer pre platby.
 */
const InvoicePaymentsReducer = (state = INVOICE_PAYMENTS_DEFAULT_STATE, { type, payload }) => {
    switch (type) {
        case FETCH_INVOICE_PAYMENTS:
            // Nacitanie platieb
            return payload;

        case CLEAN_INVOICE_PAYMENTS:
        case DELETE_INVOICE_PAYMENT:
        case DELETE_INVOICE_PAYMENTS:
            // Vycistenie platieb
            // Zmazanie platby
            // Zmazanie platieb cez multiselect
            return INVOICE_PAYMENTS_DEFAULT_STATE;

        case ADDON_INVOICE_PAYMENTS:
        default:
            return state;
    }
};

export default InvoicePaymentsReducer;
