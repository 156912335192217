import _ from 'lodash';
import {
    FETCH_MTOKNA_OWNERS,
    CLEAN_MTOKNA_OWNERS,
    CHANGE_MTOKNA_OWNER_EMAIL,
    CHANGE_MTOKNA_OWNER_SETTINGS,
} from './types';
import { request, __ } from '../functions';

export const fetchMtoknaOwners = (screen, options = {}) => {
    const defaults = {
        page: 1,
        per_page: 0,
        filtered: {},
    };

    options = { ...defaults, ...options };

    let params = { page: options.page, per_page: options.per_page };

    // Nasetujeme filter
    _.forEach(options.filtered, (value, key) => {
        params = { ...params, ...{ [`filter_${key}`]: value } };
    });

    return dispatch => request('/mtokna/owners', params).then(response => {
        const { data, sync } = response.data;
        const { setUser } = screen.props;

        // Nasetujeme user data
        setUser(sync.user);

        dispatch({ type: FETCH_MTOKNA_OWNERS, payload: data });
    });
};

export const changeMtoknaOwnerEmail = (screen, id, email) => {
    const settings = {
        email_new: email,
    };

    return dispatch => request(`/mtokna/changeUserSettings/${id}`, { settings }, 'POST').then(response => {
        const { status } = response.data;

        if (status === 'error') {
            screen.showSnackbar('error', __('Nepodarilo sa zmeniť email'));
            return;
        }

        screen.showSnackbar('success', __('Email bol zmenený'));

        dispatch({ type: CHANGE_MTOKNA_OWNER_EMAIL, payload: { id, email } });
    });
};

export const changeMtoknaOwnerPassword = (screen, id, password, password_repeat) => {
    const settings = {
        password_new: password,
        password_new_repeat: password_repeat,
    };

    return dispatch => request(`/mtokna/changeUserSettings/${id}`, { settings }, 'POST').then(response => {
        const { status } = response.data;

        if (status === 'error') {
            screen.showSnackbar('error', __('Nepodarilo sa zmeniť heslo'));
            return;
        }

        screen.showSnackbar('success', __('Heslo bolo zmenené'));
    });
};

export const changeMtoknaOwnerSettings = (screen, id, settings, type = 'settings', reducerSettings = null) => {
    return dispatch => request(`/mtokna/setOwnerSettings/${id}`, { settings }, 'POST').then(response => {
        const { status } = response.data;

        if (status === 'error') {
            if (type === 'eshops') {
                screen.onChangeEshops('loading', false);
            } else {
                screen.onChangeSettings('loading', false);
            }

            screen.showSnackbar('error', __('Nepodarilo sa uložiť nastavenia'));
            return;
        }

        if (type === 'eshops') {
            screen.closeLightbox('eshops');
        } else {
            screen.closeLightbox('settings');
        }

        screen.showSnackbar('success', __('Nastavenia boli uložené'));

        if (reducerSettings !== null) {
            dispatch({ type: CHANGE_MTOKNA_OWNER_SETTINGS, payload: { id, settings: reducerSettings } });
            return;
        }

        dispatch({ type: CHANGE_MTOKNA_OWNER_SETTINGS, payload: { id, settings } });
    });
};

export const cleanMtoknaOwners = () => ({ type: CLEAN_MTOKNA_OWNERS });
