import React from 'react';
import _ from 'lodash';
import { __, formatDate, round } from '../functions';
import { Navigate } from './Navigate';
import '../assets/styles/events-list.css';

/**
 * Events list komponenta.
 */
class EventsList extends Navigate {
    /**
     * Default props.
     *
     * @type {Object}
     */
    static defaultProps = {
        items: [],
    };

    /**
     * Rendrujeme nazov.
     *
     * @param {Object} item
     *
     * @return {string}
     */
    renderTitle(item) {
        switch (item.event) {
            case 'products':
                return __('Synchronizácia produktov z eshopu');

            case 'storage':
                return __('Synchronizácia skladu do eshopu');

            case 'sync':
                return __('Synchronizácia produktov z eshopu do druhého eshopu');

            case 'check_modified_products':
                return __('Kontrola zmenených produktov');

            default:
                return '';
        }
    }

    /**
     * Rendrujeme info.
     *
     * @param {Object} item
     *
     * @return {string}
     */
    renderInfoName(item) {
        switch (item.event) {
            case 'products':
            case 'storage':
            case 'sync':
                return __('Počet produktov');

            default:
                return '';
        }
    }

    /**
     * Rendrujeme content.
     *
     * @return {JSX.Element|unknown[]}
     */
    renderContent() {
        const { items } = this.props;

        return _.map(items, (item, key) => {
            const title = this.renderTitle(item);

            if (title === '') {
                return null;
            }

            let step = item.status === 'processed' ? item.steps : item.step;

            return (
                <div
                    className="events-list__event"
                    key={key}
                >
                    <div className="events-list__event__date">
                        <div className="events-list__event__date__background" />
                        <div className="events-list__event__date__dot" />
                        <div className="events-list__event__date__day">{formatDate(item.created, 'dd')}</div>
                        <div className="events-list__event__date__day-name">{formatDate(item.created, 'day_name')}</div>
                    </div>
                    <div className="events-list__event__info">
                        <div className="events-list__event__info__name">{title}</div>
                        <div className="events-list__event__info__progress">
                            <div className="events-list__event__info__progress__info">
                                <div className="events-list__event__info__progress__info__name">{this.renderInfoName(item)}</div>
                                <div className="events-list__event__info__progress__info__value">
                                    {step * item.step_chunk}/{item.steps * item.step_chunk}
                                </div>
                            </div>
                            <div className="events-list__event__info__progress__line">
                                <div
                                    className="events-list__event__info__progress__line__in"
                                    style={{ width: `${round(step / item.steps * 100, 0)}%` }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            );
        });
    }

    /**
     * Rendrovanie.
     *
     * @return {JSX.Element}
     */
    render() {
        return (
            <div className="events-list">
                {this.renderContent()}
            </div>
        );
    }
}

export { EventsList };
