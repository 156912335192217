import React from 'react';
import { connect } from 'react-redux';
import { withCookies } from 'react-cookie';
import _ from 'lodash';
import {ListScreen, Select, RainproOrder, Checkbox, Input, Packages} from '../components';
import {
    fetchRainproOrders,
    cleanRainproOrders,
    setUser,
    changeUserSetting,
    syncRainproOrder,
    changeRainproOrderData,
    commentRainproOrder,
    sendRainproOrders,
} from '../actions';
import {__, formatDate, formatAmount, getOrderButtons, toNumber} from '../functions';
import {ORDER_SOURCES} from '../config';
import '../assets/styles/rainpro_orders.css';
import CouriersIcon from "@mui/icons-material/LocalShipping";
import EditIcon from "@mui/icons-material/Edit";
import {ButtonBase} from "@mui/material";

/**
 * Objednavky.
 */
class RainproOrdersScreen extends ListScreen {
    /**
     * Title.
     *
     * @type {string}
     */
    title = __('Objednávky');

    /**
     * Default state.
     *
     * @type {Object}
     */
    state = { ...ListScreen.state, ...{
        loadingSync: false,
        lightbox: {
            pickers: false,
        },
    }};

    // Memory cache
    memoryCacheStates = null;

    /**
     * Bol volany klik na objednavku.
     *
     * @type {boolean}
     */
    callViewClick = false;

    defaultState = {};

    /**
     * Nacitame zoznam.
     *
     * @param {number} page
     * @param {number} per_page
     * @param {Object} filtered
     * @param {function|null} callback
     */
    async fetchList(page = 1, per_page = 0, filtered = {}, callback = null) {
        this.page = page;

        const { fetch } = this.props;

        // Nacitame zoznam
        await fetch(this, { page, per_page, filtered, callback });

        const { items, user } = this.props;

        if (user.rainpro_type === 'rainpro_admin') {
            window.location = '/pickers';
            return;
        }

        if (!_.isEmpty(items.filtered)) {
            // Nastavime filter
            this.setState({ filtered: items.filtered });
        }

        this.afterFetchList();
    }

    /**
     * Vratime zoznam tagov.
     *
     * @return {Array}
     */
    getTags() {
        return [];
    }

    /**
     * Vratime nazov tabulky.
     *
     * @return {string}
     */
    getTableName() {
        return 'rainpro_orders';
    }

    getColumns() {
        return [
            <div>{`${__('Číslo')} / ${__('Suma')} / ${__('Dátum')}`}</div>,
            <div>{`${__('Zákaznik')} / ${__('E-mail')} / ${__('Eshop')}`}</div>,
            <div>{`${__('Tel. číslo')} / ${__('Fakturačná adresa')} / ${__('Dodacia adresa')}`}</div>,
            <div>{`${__('Poznámka')}`}</div>,
            <div></div>,
        ];
    }

    /**
     * Vratime bunky.
     *
     * @param {Object} item
     *
     * @return {Array}
     */
    getCells(item) {
        const { user, items } = this.props;

        let states = { [item.data.state.id]: item.data.state.name };

        const p = item._matchingData.RainproOrders.package;

        // Vytiahneme tlacitka pre objednavku
        let buttons = getOrderButtons({ ...item, package: p }, {
            settings: {
                meta_data_pdf_link: '',
            },
            hasPermissionView: true,
            hasPermissionSync: true,
            hasPermissionSend: true,
            userCouriers: [],
            couriers: items.couriers,
            courierSettings: {
                default_courier: 0,
            },
        });

        // Nastavime callbacky
        if (typeof buttons.view !== 'undefined') {
            buttons.view.callback = (callbackLoading, callback) => this.showDrawer('order', {
                id: item.id,
                buttons,
                callbackLoading,
                callback,
            });
        }

        if (typeof buttons.sync !== 'undefined') {
            buttons.sync.callback = (callbackLoading, callback) => this.syncOrder(item.id, callbackLoading, callback);
        }

        if (typeof buttons.send !== 'undefined') {
            buttons.send.callback = buttons.send.options.packagePayment || buttons.send.options.packageImport
                ? () => {}
                : (buttons.send.options.packageSend
                        ? () => this.onClickPackageNumber(p.number)
                        : () => this.showDrawer('packages', { ids: item.id })
                );
        }

        let source = '';

        if (item.data.source !== '' && _.has(ORDER_SOURCES, item.data.source)) {
            // Je vyplneny zdroj
            const sourceConfig = ORDER_SOURCES[item.data.source];

            source = <div className="source" style={{ backgroundColor: sourceConfig.background }}>
                {sourceConfig.name}
            </div>;
        }

        let color = item.data.state.color;

        return [
            <div>
                <div style={{ backgroundColor: color }} />
                <div>
                    <div>{item.data.number}</div>
                    {source}
                </div>
                <div>{formatAmount(item.data.amount, item.data.currency)}</div>
                <div>{formatDate(item.data.date)}</div>
            </div>,
            <div>
                <div>{_.truncate(item.data.client.name, { length: 32 })}</div>
                <div>{_.truncate(item.data.client.email, { length: 32 })}</div>
                <div>{_.has(items.user_eshops, item.user_eshop_id) ? items.user_eshops[item.user_eshop_id].name : '-'}</div>
            </div>,
            <div>
                <div>{item.data.client.phone}</div>
                <div>{_.truncate(`${item.data.client.address}, ${item.data.client.city}`, { length: 32 })}</div>
                <div>{_.truncate(`${item.data.client.delivery_address}, ${item.data.client.delivery_city}`, { length: 32 })}</div>
            </div>,
            <div>
                <div>{_.truncate(item.data.client.delivery_note, { length: 32 })}</div>
                <div>-</div>
                <div>-</div>
            </div>,
            <div>
                {!this.isMobile() ? <div>
                    <Select
                        options={states}
                        value={item.data.state.id}
                        onChange={value => this.confirmState(item.id, value, '')}
                        allowEmpty={false}
                        variant="standard"
                    />
                </div> : null}
                <div>
                    {this.renderTableButton(
                        item.id,
                        __('Skladníci'),
                        <EditIcon />,
                        () => this.showPickers(item),
                        {}
                    )}
                    {this.renderTableButtons(item.id, buttons, ['pdfLink', 'pdf', 'createInvoice', 'paid', 'delete'], false)}
                </div>
            </div>,
        ];
    }

    showPickers(item) {
        const { items } = this.props;

        const settings = _.reduce(items.pickers, (result, email, id) => {
            let picker_order = null;

            _.each(item.picker_orders, i => {
                if (toNumber(i.user_id) === toNumber(id)) {
                    picker_order = i;
                }
            });

            return { ...result, [id]: {
                    picker_order,
                    cod: picker_order !== null && toNumber(picker_order.cod) === 1,
                    comment: picker_order !== null ? picker_order.comment : '',
                } };
        }, {});

        this.showLightbox('pickers', { id: item.id, number: item.data.number, settings, loading: false });
    }

    closePickers() {
        this.closeLightbox('pickers');
    }

    onChangePickersSetting(user_id, key, value) {
        const { lightbox } = this.state;

        this.setState({ lightbox: { ...lightbox, pickers: { ...lightbox.pickers, settings: { ...lightbox.pickers.settings, [user_id]: { ...lightbox.pickers.settings[user_id], [key]: value } } } } });
    }

    async commentOrder(id, order_id, comment) {
        const { lightbox } = this.state;
        const { commentRainproOrder } = this.props;

        this.setState({ lightbox: { ...lightbox, pickers: { ...lightbox.pickers, loading: true } } });

        await commentRainproOrder(this, id, order_id, comment);

        this.closePickers();
    }

    /**
     * Event po kliku na cislo zasielky.
     *
     * @param {string} number
     */
    onClickPackageNumber(number) {
        // Nastavime cislo do clipboardu
        navigator.clipboard.writeText(number);

        this.showSnackbar('success', __('Skopirované do schránky'));
    }

    /**
     * Vratime options buniek.
     *
     * @return {Object}
     */
    getCellsOptions() {
        return {
            number: {
                label: __('Číslo'),
                get: item => item.data.number,
            },
            amount: {
                label: __('Suma'),
                get: item => formatAmount(item.data.amount, item.data.currency),
            },
            created: {
                label: __('Dátum'),
                get: item => formatDate(item.data.date),
            },
            client_name: {
                label: __('Zákaznik'),
                get: item => _.truncate(item.data.client.name, { length: 32 }),
            },
            client_delivery_name: {
                label: __('Zákaznik (dodanie)'),
                get: item => _.truncate(item.data.client.delivery_name, { length: 32 }),
            },
            client_contact_name: {
                label: __('Kontaktná osoba'),
                get: item => _.truncate(item.data.client.contact_name, { length: 32 }),
            },
            client_delivery_contact_name: {
                label: __('Kontaktná osoba (dodanie)'),
                get: item => _.truncate(item.data.client.delivery_contact_name, { length: 32 }),
            },
            client_company: {
                label: __('Firemné údaje'),
                get: item => !_.isEmpty(item.data.client.ico)
                    ? _.truncate(`${__('IČO')}: ${item.data.client.ico}, ${__('DIČ')}: ${item.data.client.dic}, ${__('IČ DPH')}: ${item.data.client.ic_dph}`, { length: 32 })
                    : '',
            },
            client_address: {
                label: __('Fakturačná adresa'),
                get: item => _.truncate(`${item.data.client.address}, ${item.data.client.city}`, { length: 32 }),
            },
            client_delivery_address: {
                label: __('Dodacia adresa'),
                get: item => _.truncate(`${item.data.client.delivery_address}, ${item.data.client.delivery_city}`, { length: 32 }),
            },
            client_phone: {
                label: __('Tel. číslo'),
                get: item => item.data.client.phone,
            },
            client_delivery_phone: {
                label: __('Tel. číslo (dodanie)'),
                get: item => item.data.client.delivery_phone,
            },
            client_email: {
                label: __('E-mail'),
                get: item => _.truncate(item.data.client.email, { length: 32 }),
            },
            client_note: {
                label: __('Poznámka'),
                get: item => _.truncate(item.data.client.delivery_note, { length: 32 }),
            },
            delivery_name: {
                label: __('Dodanie'),
                get: item => _.truncate(item.data.delivery_type_name, { length: 32 }),
            },
            payment_name: {
                label: __('Platba'),
                get: item => _.truncate(item.data.payment_type_name, { length: 32 }),
            },
            internal_comment: {
                label: __('Interná poznámka'),
                get: item => _.truncate(item.data.internal_comment, { length: 32 }),
            },
            package_number: {
                label: __('Číslo zásielky'),
                get: item => item.package.number.toString().indexOf('-') === -1 ? item.package.number : '',
            },
        };
    }

    /**
     * Vratime multiselect.
     *
     * @return {Array}
     */
    getMultiselect() {
        return [];
    }

    /**
     * Vratime nastavenia buniek.
     *
     * @return {Object}
     */
    getCellsSettings() {
        return {};
    }

    /**
     * Vratime filter.
     *
     * @return {Object}
     */
    getFilter() {
        const { items } = this.props;

        return _.reduce(items.filter, (result, options, key) => {
            switch (key) {
                case 'number':
                    return { ...result, ...{ [key]: {
                        type: 'input',
                        name: __('Číslo objednávky'),
                        value: '',
                    }}};

                default:
                    return result;
            }
        }, {});
    }

    /**
     * Vratime empty text.
     *
     * @return {string}
     */
    getEmptyText() {
        return __('Zatiaľ nemáte žiadne objednávky');
    }

    /**
     * Synchronizacia objednavky.
     *
     * @param {number} id
     * @param {function} callbackLoading
     * @param {function} callback
     */
    async syncOrder(id, callbackLoading, callback) {
        const { syncRainproOrder } = this.props;

        // Zavolame loading
        callbackLoading();

        // Synchronizacia objednavky
        await syncRainproOrder(this, id);

        // Zavolame callback
        callback();
    }

    /**
     * Rendrujeme screen.
     *
     * @return {JSX.Element|null}
     */
    renderScreen() {
        const { lightbox } = this.state;
        const { items, user, changeRainproOrderData, sendRainproOrders } = this.props;

        return (
            <div>
                {this.renderLightbox(
                    'pickers',
                    !_.isEmpty(lightbox.pickers) ? `${__('Objednávka č.')} ${lightbox.pickers.number}` : __('Skladníci'),
                    !_.isEmpty(lightbox.pickers) ? <div>{lightbox.pickers.loading ? this.renderLoading(20) :
                        <div>
                            {_.map(lightbox.pickers.settings, (item, user_id) => {
                                if (_.isEmpty(item.picker_order)) {
                                    return null;
                                }

                                const edit = toNumber(user_id) === toNumber(user.id);
                                const send = _.has(item.picker_order, 'package') && !_.isEmpty(item.picker_order.package) && item.picker_order.package.number !== '';

                                return (
                                    <div style={{ marginBottom: '20px' }}>
                                        <div key={user_id} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: '5px' }}>
                                            <div style={{ width: '300px'}}>{_.has(items.pickers, user_id) ? items.pickers[user_id] : '-'}</div>
                                            <div>
                                                <Checkbox
                                                    label={__('Dobierka')}
                                                    value={item.cod}
                                                    onChange={checked => {}}
                                                    style={{ flexDirection: 'row' }}
                                                    disabled
                                                />
                                            </div>
                                            <ButtonBase
                                                onClick={edit ? () => this.commentOrder(toNumber(item.picker_order.id), lightbox.pickers.id, item.comment) : () => {}}
                                                style={{ marginLeft: '20px', color: edit ? '#5c70ff' : '#c8c4c4', whiteSpace: 'nowrap' }}
                                                disabled={!edit}
                                            >
                                                {__('Pridať poznámku')}
                                            </ButtonBase>
                                        </div>
                                        <div>
                                            <Input
                                                placeholder={__('Poznámka')}
                                                value={item.comment}
                                                onChange={edit ? value => this.onChangePickersSetting(user_id, 'comment', value) : value => {}}
                                            />
                                        </div>
                                        <div style={{ marginTop: '10px' }}>
                                            {send
                                                ? <span style={{ color: '#459350' }}>{__(`Stav: odoslané (${item.picker_order.package.courier}) - č. ${item.picker_order.package.number}`)}</span>
                                                : <span style={{ color: '#c8c4c4' }}>{__('Stav: neodoslané')}</span>}
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    }</div> : null,
                    '',
                    __('Zrušiť'),
                    () => {},
                    false,
                    () => this.closePickers()
                )}
                {this.renderDrawer('packages', {
                    title: __('Odoslanie objednávok'),
                    content: props => <Packages
                        user={user}
                        showSnackbar={(type, message) => this.showSnackbar(type, message)}
                        sendOrders={sendRainproOrders}
                        rainpro={true}
                        {...props}
                    />,
                })}
                {this.renderDrawer('order', {
                    title: __('Objednávka'),
                    content: props => <RainproOrder
                        user={user}
                        showSnackbar={(type, message) => this.showSnackbar(type, message)}
                        changeRainproOrderData={changeRainproOrderData}
                        settings={items.settings}
                        {...props}
                    />,
                })}
                {this.renderOffer()}
            </div>
        );
    }
}

const stateToProps = ({ rainpro_orders, user }) => ({ items: rainpro_orders, user });

export default withCookies(connect(stateToProps, {
    fetch: fetchRainproOrders,
    clean: cleanRainproOrders,
    setUser,
    changeUserSetting,
    syncRainproOrder,
    changeRainproOrderData,
    commentRainproOrder,
    sendRainproOrders,
})(RainproOrdersScreen));
