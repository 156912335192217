import _ from 'lodash';
import { FETCH_ADDONS, CREATE_ADDON, DELETE_ADDON, CLEAN_ADDONS } from '../actions/types';

const ADDONS_DEFAULT_STATE = [];

/**
 * Reducer pre addony.
 */
const AddonsReducer = (state = ADDONS_DEFAULT_STATE, { type, payload }) => {
    switch (type) {
        case FETCH_ADDONS:
            // Nacitanie addonov
            return payload;

        case CREATE_ADDON:
            // Vytvorenie addonu
            return { ...state, user_addons: { ...state.user_addons, [payload.id]: payload.addon_id } };

        case DELETE_ADDON:
            // Zmazanie addonu
            const userAddons = _.omit(state.user_addons, [_.toString(payload)]);

            return { ...state, user_addons: userAddons };

        case CLEAN_ADDONS:
            // Vycistenie addonov
            return ADDONS_DEFAULT_STATE;

        default:
            return state;
    }
};

export default AddonsReducer;
