import _ from 'lodash';
import {
    FETCH_PRODUCTS,
    CLEAN_PRODUCTS,
    CHANGE_PRODUCT_STOCK,
    DELETE_PRODUCT,
    DELETE_PRODUCTS,
    EDIT_PRODUCT,
    CHANGE_PRODUCT_MALL,
    CHANGE_PRODUCT_ALLEGRO,
} from '../actions/types';
import { toNumber } from '../functions';

const PRODUCTS_DEFAULT_STATE = {};

/**
 * Reducer pre produkty.
 */
const ProductsReducer = (state = PRODUCTS_DEFAULT_STATE, { type, payload }) => {
    switch (type) {
        case FETCH_PRODUCTS:
            // Nacitanie produktov
            return payload;

        case CHANGE_PRODUCT_STOCK:
            // Zmenime stav na sklade
            return { ...state, items: _.map(state.items, item => {
                if (toNumber(item.id) === toNumber(payload.id)) {
                    // Nasli sme produkt
                    item = { ...item, data: { ...item.data, stock: payload.stock } };
                }

                return item;
            }) };

        case CHANGE_PRODUCT_MALL:
            // Pridame/zrusime produkt na mall
            return { ...state, items: _.map(state.items, item => {
                if (toNumber(item.id) === toNumber(payload.id)) {
                    // Nasli sme produkt
                    let settings = item.data.settings;

                    if (_.has(settings, 'mall')) {
                        // Zrusime
                        _.unset(settings, 'mall');
                    } else {
                        // Pridame
                        settings = { ...settings, mall: 1 };
                    }

                    item = { ...item, data: { ...item.data, settings } };
                }

                return item;
            }) };

        case CHANGE_PRODUCT_ALLEGRO:
            // Pridame/zrusime produkt na allegro
            return { ...state, items: _.map(state.items, item => {
                if (toNumber(item.id) === toNumber(payload.id)) {
                    // Nasli sme produkt
                    let settings = item.data.settings;

                    if (_.has(settings, 'allegro')) {
                        // Zrusime
                        _.unset(settings, 'allegro');
                    } else {
                        // Pridame
                        settings = { ...settings, allegro: 1 };
                    }

                    item = { ...item, data: { ...item.data, settings } };
                }

                return item;
            }) };

        case EDIT_PRODUCT:
            // Editacia produktu
            return { ...state, items: _.map(state.items, item => {
                if (toNumber(item.id) === toNumber(payload.id)) {
                    // Nasli sme produkt
                    item = { ...item, data: { ...item.data, ...payload.data } };

                    if (_.has(payload.data, 'name')) {
                        // Nastavime aj nazov
                        item = { ...item, name: payload.data.name };
                    }
                }

                return item;
            }) };

        case CLEAN_PRODUCTS:
        case DELETE_PRODUCT:
        case DELETE_PRODUCTS:
            // Vycistenie produktov
            // Zmazanie produktu
            // Zmazanie produktov
            return PRODUCTS_DEFAULT_STATE;

        default:
            return state;
    }
};

export default ProductsReducer;
