import _ from 'lodash';
import { request, toNumber, __ } from '../functions';
import {
    FETCH_ESHOP,
    FETCH_ESHOP_DATA,
    CHANGE_ESHOP_AUTO_FIELD,
    CHANGE_ESHOP_SETTINGS,
    CREATE_ESHOP,
} from './types';
import { TUTORIAL_STEPS } from '../config';

/**
 * Nacitanie eshopu.
 *
 * @param {Screen} screen
 */
export const fetchEshop = screen => {
    return dispatch => request('/user-eshops/eshop').then(({ data }) => {
        const { user } = data.sync;
        const { setUser } = screen.props;

        // Nasetujeme usera
        setUser(user);

        dispatch({ type: FETCH_ESHOP, payload: data.data });
    });
};

/**
 * Nacitanie dat eshopu.
 *
 * @param {Screen} screen
 */
export const fetchEshopData = screen => {
    return dispatch => request('/user-eshops/eshopData').then(response => {
        const { status, data } = response.data;

        screen.setState({ loadingData: false });

        if (status === 'error') {
            // Nepodarilo sa nacitat data
            screen.showSnackbar('error', __('Nepodarilo sa načítať dáta eshopu'));
            return;
        }

        screen.showSnackbar('success', __('Údaje z eshopu boli načítané'));

        dispatch({ type: FETCH_ESHOP_DATA, payload: data });
    });
};

/**
 * Zmenime auto field eshopu.
 *
 * @param {Screen} screen
 * @param {string} field
 * @param {boolean} value
 */
export const changeEshopAutoField = (screen, field, value) => {
    return dispatch => request(`/user-eshops/setEshopAuto/${field}/${toNumber(value)}`).then(({ data }) => {
        screen.showSnackbar('success', value ? __('Zapnuté') : __('Vypnuté'));

        dispatch({ type: CHANGE_ESHOP_AUTO_FIELD, payload: { field, value } });
    });
};

/**
 * Zmenime nastavenia eshopu.
 *
 * @param {Screen} screen
 * @param {Object} settings
 */
export const changeEshopSettings = (screen, settings) => {
    let params = {};

    if (screen.getTutorialStep() === 2) {
        // Sme na druhom kroku, nastavime treti
        params = { tutorial_step: 3 };
    }

    return dispatch => request('/user-eshops/changeEshopSettings', { settings }, 'POST', params).then(response => {
        const { status } = response.data;

        screen.setState({ loading: false });

        if (status === 'error') {
            // Nepodarilo sa zmenit nastavenia
            screen.showSnackbar('error', __('Nepodarilo sa zmeniť nastavenia'));
            return;
        }

        screen.showSnackbar('success', __('Nastavenia boli zmenené'));

        dispatch({ type: CHANGE_ESHOP_SETTINGS, payload: settings });

        if (_.has(params, 'tutorial_step')) {
            // Prechadzame na dalsi krok, presmerujeme
            setTimeout(() => screen.redirect(TUTORIAL_STEPS[3].redirect), 1000);
        }
    });
};

/**
 * Vytvorenie prepojenia na eshop.
 *
 * @param {number} id
 */
export const createEshop = id => ({ type: CREATE_ESHOP, payload: id });
