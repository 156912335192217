import _ from 'lodash';
import {
    FETCH_MTOKNA_MANUAL_ORDERS,
    CLEAN_MTOKNA_MANUAL_ORDERS,
    EXPORT_MTOKNA_MANUAL_ORDER,
    CHANGE_MTOKNA_MANUAL_ORDER_STATE,
    EDIT_MTOKNA_MANUAL_ORDER,
    DELETE_MTOKNA_MANUAL_ORDER,
    CREATE_MTOKNA_MANUAL_INVOICE,
    ADDON_MTOKNA_MANUAL_ORDERS,
    SEND_MTOKNA_MANUAL_ORDERS,
    EXPORT_MTOKNA_MANUAL_ORDERS,
} from './types';
import {request, __, toNumber} from '../functions';

export const fetchMtoknaManualOrders = (screen, options = {}) => {
    const defaults = {
        page: 1,
        per_page: 0,
        filtered: {},
        callback: null,
    };

    options = { ...defaults, ...options };

    let params = { page: options.page, per_page: options.per_page };

    // Nasetujeme filter
    _.forEach(options.filtered, (value, key) => {
        params = { ...params, ...{ [`filter_${key}`]: value } };
    });

    return dispatch => request('/mtokna/manualOrders', params).then(response => {
        const { data, sync } = response.data;
        const { setUser } = screen.props;

        if (options.callback !== null) {
            // Zavolame callback
            options.callback();
        }

        // Nasetujeme user data
        setUser(sync.user);

        dispatch({ type: FETCH_MTOKNA_MANUAL_ORDERS, payload: data });
    });
};

export const cleanMtoknaManualOrders = () => ({ type: CLEAN_MTOKNA_MANUAL_ORDERS });

export const changeMtoknaManualOrderState = (screen, id, stateId) => {
    return dispatch => request(`/mtokna/changeManualOrderState/${id}/${stateId}`).then(response => {
        const { status, data } = response.data;

        if (status === 'error') {
            // Stav sa nepodarilo zmenit
            screen.showSnackbar('error', __('Stav objednávky sa nepodarilo zmeniť'));
            return;
        }

        screen.showSnackbar('success', __('Stav objednávky bol zmenený'));

        dispatch({ type: CHANGE_MTOKNA_MANUAL_ORDER_STATE, payload: data });
    });
};

export const pdfMtoknaManualOrder = (screen, id) => {
    return dispatch => request(`/mtokna/pdfManualOrder/${id}`).then(response => {
        const { status, data } = response.data;

        if (status === 'error') {
            screen.showSnackbar('error', __('Nepodarilo sa stiahnuť pdf objednávky'));
            return;
        }

        screen.showSnackbar('success', __('Objednávka bola exportovaná'));

        // Stiahneme subor
        window.location = data.link;

        dispatch({ type: EXPORT_MTOKNA_MANUAL_ORDER, payload: { [id]: id } });
    });
};

/**
 * Zmazanie objednavky.
 *
 * @param {Screen} screen
 * @param {number} id
 */
export const deleteMtoknaManualOrder = (screen, id) => {
    return dispatch => request(`/mtokna/deleteManualOrder/${id}`, { order_delete_in_eshop: false }).then(response => {
        const { status } = response.data;

        if (status === 'error') {
            // Objednavku sa nepodarilo zmazat
            screen.showSnackbar('error', __('Objednávku sa nepodarilo zmazať pretože má evidovanú faktúru'));
            return;
        }

        screen.showSnackbar('success', __('Objednávka bola zmazaná'));

        dispatch({ type: DELETE_MTOKNA_MANUAL_ORDER });
    });
};

export const changeMtoknaManualOrderData = (id, data) => ({ type: EDIT_MTOKNA_MANUAL_ORDER, payload: { id, data } });

/**
 * Vytvorenie faktury.
 *
 * @param {Screen} screen
 * @param {number} id
 */
export const createMtoknaManualInvoice = (screen, id) => {
    id = toNumber(id);

    return dispatch => request(`/mtokna/createManualOrderInvoice/${id}`).then(response => {
        const { status, data } = response.data;
        let hasError = false;

        if (status === 'success') {
            hasError = _.has(data, id) && _.isString(data[id]);
        }

        if (status === 'error' || hasError) {
            // Fakturu sa nepodarilo vytvorit
            screen.showSnackbar('error', __('Faktúru sa nepodarilo vytvoriť'));

            if (_.isEmpty(data)) {
                return;
            }
        }

        if (!hasError) {
            screen.showSnackbar('success', __('Faktúra bola vytvorená'));
        }

        dispatch({ type: CREATE_MTOKNA_MANUAL_INVOICE, payload: data });
    });
};

/**
 * Stiahnutie order pdf.
 *
 * @param {Screen} screen
 * @param {string} ids
 * @param {Object} filtered
 */
export const pdfMtoknaManualOrders = (screen, ids, filtered = {}) => {
    let params = { merge_multi_order_pdf: screen.props.user.settings.merge_multi_order_pdf };

    // Nasetujeme filter
    _.forEach(filtered, (value, key) => {
        params = { ...params, ...{ [`filter_${key}`]: value } };
    });

    return dispatch => request(`/mtokna/multiManualPdfOrders/${ids}/`, params).then(response => {
        const { status, data } = response.data;

        if (status === 'error') {
            screen.showSnackbar('error', __('Nepodarilo sa stiahnuť zoznam pdf objednávok'));
            return;
        }

        screen.showSnackbar('success', __('Objednávky boli exportované'));

        // Stiahneme subor
        window.location = data.link;

        dispatch({ type: EXPORT_MTOKNA_MANUAL_ORDERS, payload: data.ids });
    });
};

/**
 * Addon objednavok.
 *
 * @param {Screen} screen
 * @param {string} ids
 * @param {number} addonId
 * @param {Object} filtered
 */
export const addonMtoknaManualOrders = (screen, ids, addonId, filtered = {}) => {
    let params = {};

    // Nasetujeme filter
    _.forEach(filtered, (value, key) => {
        params = { ...params, ...{ [`filter_${key}`]: value } };
    });

    return dispatch => request(`/mtokna/multiManualAddon/${ids}/${addonId}`, params).then(response => {
        const { status, data } = response.data;

        if (status === 'error') {
            // Nepodarilo sa nacitat doplnok
            screen.showSnackbar('error', __('Nepodarilo sa načítať doplnok'));
            return;
        }

        screen.showSnackbar('success', __('Doplnok bol načítaný'));

        let counter = 0;

        _.each(data, item => {
            if (_.has(item, 'link')) {
                // Addon ma subor na stiahnutie
                setTimeout(() => window.location = item.link, counter * 500);
            }

            counter++;
        });

        dispatch({ type: ADDON_MTOKNA_MANUAL_ORDERS });
    });
};

/**
 * Odosleme objednavky.
 *
 * @param {Object} data
 */
export const sendMtoknaManualOrders = data => ({ type: SEND_MTOKNA_MANUAL_ORDERS, payload: data });
