import { CLEAN_MTOKNA_OWNER_SMTP, FETCH_MTOKNA_OWNER_SMTP } from '../actions/types';

const MTOKNA_OWNER_SMTP_DEFAULT_STATE = {};

/**
 * Reducer pre sablonu.
 */
const MtoknaOwnerSmtpReducer = (state = MTOKNA_OWNER_SMTP_DEFAULT_STATE, { type, payload }) => {
    switch (type) {
        case FETCH_MTOKNA_OWNER_SMTP:
            return payload;

        case CLEAN_MTOKNA_OWNER_SMTP:
            return MTOKNA_OWNER_SMTP_DEFAULT_STATE;

        default:
            return state;
    }
};

export default MtoknaOwnerSmtpReducer;
