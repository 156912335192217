import React from 'react';
import { connect } from 'react-redux';
import { withCookies } from 'react-cookie';
import _ from 'lodash';
import {Screen, Message, Input, Button, Select} from '../components';
import { fetchMtoknaOwnerSmtp, setUser, cleanMtoknaOwnerSmtp } from '../actions';
import {__, isEmptyString, isValidEmail, request} from '../functions';
import '../assets/styles/mtokna_owner_smtp.css';
import {ButtonBase} from "@mui/material";

/**
 * Email.
 */
class MtoknaSellerOwnerScreen extends Screen {
    /**
     * Title.
     *
     * @type {string}
     */
    title = __('SMTP');

    /**
     * Default state.
     *
     * @type {Object}
     */
    state = {
        data: {},
        user_eshop_id: '',
        loading: false,
        logo: '',
    };

    /**
     * Komponenta bola pripojena.
     *
     * @return boolean
     */
    componentDidMount() {
        if (super.componentDidMount()) {
            this.load();
        }

        return true;
    }

    /**
     * Komponenta bude odpojena.
     */
    componentWillUnmount() {
        const { cleanMtoknaOwnerSmtp } = this.props;

        cleanMtoknaOwnerSmtp();
    }

    async load(id = '') {
        const { fetchMtoknaOwnerSmtp } = this.props;

        // Nacitame smtp
        await fetchMtoknaOwnerSmtp(this, id);

        const { smtp } = this.props;

        let data = {
            host: '',
            email: '',
            password: '',
            encryption: '',
            port: '',
            name: '',
            reply: '',
        };
        let user_eshop_id = '';
        let logo = '';

        if (_.has(smtp, 'user_eshop_id')) {
            user_eshop_id = smtp.user_eshop_id.toString();
        }

        if (_.has(smtp, 'logo')) {
            logo = smtp.logo;
        }

        if (!_.isEmpty(smtp.smtp)) {
            // Mame data z editu
            data = {
                host: smtp.smtp.host,
                email: smtp.smtp.email,
                password: smtp.smtp.password,
                encryption: smtp.smtp.encryption,
                port: smtp.smtp.port,
                name: smtp.smtp.name,
                reply: smtp.smtp.reply,
            };
        }

        this.setState({ data, user_eshop_id, logo, loading: false });
    }

    /**
     * Ulozenie.
     */
    save() {
        let { data, user_eshop_id } = this.state;

        let error = '';

        if (isEmptyString(data.host)) {
            // Nie je zadany host
            error = __('Nie je zadaný server host');
        } else if (isEmptyString(data.email)) {
            // Nie je zadany email
            error = __('Nie je zadaný email');
        } else if (isEmptyString(data.password)) {
            // Nie je zadane heslo
            error = __('Nie je zadané heslo');
        } else if (isEmptyString(data.name)) {
            // Nie je zadany odosielatel
            error = __('Nie je zadaný názov odosielateľa');
        } else if (!isEmptyString(data.reply) && !isValidEmail(data.reply)) {
            // Neplatne reply to
            error = __('Neplatný email pre odpoveď');
        }

        if (error !== '') {
            // Mame error
            this.showSnackbar('error', error);
            return;
        }

        this.setState({ loading: true });

        request(`/mtokna/smtp/${user_eshop_id}`, data, 'POST').then(response => {
            const { status, data } = response.data;

            this.setState({ loading: false });

            if (status === 'error') {
                this.showSnackbar('error', _.has(data, 'message') ? data.message : __('Nepodarilo sa uložiť SMTP'));
                return;
            }

            this.showSnackbar('success', __('Nastavenia boli uložené'));
        })
    }

    /**
     * Event po zmene dat.
     *
     * @param {string} type
     * @param {string} value
     */
    onChange(type, value) {
        const { data } = this.state;

        let additional = {};

        this.setState({ data: { ...data, [type]: value, ...additional } });
    }

    onChangeUserEshopId(value) {
        this.setState({ loading: true });

        this.load(value);
    }

    /**
     * Nahratie loga.
     *
     * @param {Object} file
     */
    uploadLogo(file) {
        const { user_eshop_id } = this.state;

        request('/user-eshops/logo', file, 'FILE', { user_eshop_id }).then(response => {
            const { status, data } = response.data;

            if (status === 'error') {
                // Nepodarilo sa nahrat logo
                this.showSnackbar('error', __('Nepodarilo sa nahrať logo'));
                return;
            }

            this.setState({ logo: data.url });
        });
    }

    /**
     * Rendrovanie.
     *
     * @returns {JSX.Element}
     */
    render() {
        const { user } = this.props;
        const { data, loading, user_eshop_id, logo } = this.state;

        if (_.isEmpty(data) || loading) {
            // Data nie su nacitane
            return this.renderLoading();
        }

        return (
            <div className="mtokna_owner_smtp">
                <div className="mtokna_owner_smtp__header">
                    <div className="mtokna_owner_smtp__header__left">
                        <div className="mtokna_owner_smtp__header__left__title">{__('SMTP')}</div>
                    </div>
                    <div className="mtokna_owner_smtp__header__right">
                        <Select
                            value={user_eshop_id}
                            options={user.user_eshops}
                            onChange={value => this.onChangeUserEshopId(value)}
                            allowEmpty={false}
                        />
                    </div>
                </div>
                <div className="mtokna_owner_smtp__content">
                    <Message type="warning">{__('Pre správne pripojenie si skontrolujte u poskytovateľa SMTP či máte povolené prihlasovanie zo zahraničia.')}</Message>
                    <div className="mtokna_owner_smtp__content__panels">
                        <div className="mtokna_owner_smtp__content__panels__panel">
                            <Input
                                label={__('Server host')}
                                value={data.host}
                                onChange={value => this.onChange('host', value)}
                                placeholder="smtp.websupport.sk"
                            />
                            <Input
                                label={__('Email')}
                                value={data.email}
                                onChange={value => this.onChange('email', value)}
                                placeholder="info@eshop.sk"
                            />
                            <Input
                                label={__('Heslo')}
                                value={data.password}
                                onChange={value => this.onChange('password', value)}
                                type="password"
                            />
                            <Select
                                label={__('Zabezpečenie pripojenia')}
                                value={data.encryption}
                                options={{ 'tls': 'TLS', 'ssl': 'SSL' }}
                                onChange={value => this.onChange('encryption', value)}
                            />
                            <Input
                                label={__('Port')}
                                value={data.port}
                                onChange={value => this.onChange('port', value)}
                                placeholder={__('Doplnené podľa zabezpečenia pripojenia')}
                            />
                        </div>
                        <div className="mtokna_owner_smtp__content__panels__panel">
                            <Input
                                label={__('Názov odosielateľa')}
                                value={data.name}
                                onChange={value => this.onChange('name', value)}
                                maxLength={20}
                                placeholder="Eshop.sk"
                            />
                            <Input
                                label={__('Email na ktorý príde odpoveď (reply to)')}
                                value={data.reply}
                                onChange={value => this.onChange('reply', value)}
                                placeholder="info@eshop.sk"
                            />
                            <div className="mtokna_owner_smtp__content__panels__panel__upload-text">{__('Logo')}</div>
                            <ButtonBase className="mtokna_owner_smtp__content__panels__panel__upload">
                                {logo === ''
                                    ? <div className="mtokna_owner_smtp__content__panels__panel__upload__text">{__('Kliknite alebo presuňte súbor (jpg, png, max. 5MB)')}</div>
                                    : <img src={`${logo}?v=${Math.random()}`} alt="logo eshop" />}
                                <input type="file" onChange={event => this.uploadLogo(event.target.files[0])} />
                            </ButtonBase>
                        </div>
                    </div>
                    <Button
                        onClick={() => this.save()}
                        loading={loading}
                        className="mtokna_owner_smtp__content__button"
                        color="green"
                    >{__('Uložiť')}</Button>
                </div>
                {this.renderSnackbar()}
            </div>
        );
    }
}

const stateToProps = ({ mtokna_owner_smtp, user }) => ({ smtp: mtokna_owner_smtp, user });

export default withCookies(connect(stateToProps, {
    fetchMtoknaOwnerSmtp,
    setUser,
    cleanMtoknaOwnerSmtp,
})(MtoknaSellerOwnerScreen));
