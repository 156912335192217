import _ from 'lodash';
import {
    FETCH_MTOKNA_SELLERS,
    CLEAN_MTOKNA_SELLERS,
    CHANGE_MTOKNA_SELLER_EMAIL,
    CHANGE_MTOKNA_SELLER_SMTP,
} from './types';
import { request, __ } from '../functions';

export const fetchMtoknaSellers = (screen, options = {}) => {
    const defaults = {
        page: 1,
        per_page: 0,
        filtered: {},
    };

    options = { ...defaults, ...options };

    let params = { page: options.page, per_page: options.per_page };

    // Nasetujeme filter
    _.forEach(options.filtered, (value, key) => {
        params = { ...params, ...{ [`filter_${key}`]: value } };
    });

    return dispatch => request('/mtokna/sellers', params).then(response => {
        const { data, sync } = response.data;
        const { setUser } = screen.props;

        // Nasetujeme user data
        setUser(sync.user);

        dispatch({ type: FETCH_MTOKNA_SELLERS, payload: data });
    });
};

export const changeMtoknaSellerEmail = (screen, id, email) => {
    const settings = {
        email_new: email,
    };

    return dispatch => request(`/mtokna/changeUserSettings/${id}`, { settings }, 'POST').then(response => {
        const { status } = response.data;

        if (status === 'error') {
            screen.showSnackbar('error', __('Nepodarilo sa zmeniť email'));
            return;
        }

        screen.showSnackbar('success', __('Email bol zmenený'));

        dispatch({ type: CHANGE_MTOKNA_SELLER_EMAIL, payload: { id, email } });
    });
};

export const changeMtoknaSellerPassword = (screen, id, password, password_repeat) => {
    const settings = {
        password_new: password,
        password_new_repeat: password_repeat,
    };

    return dispatch => request(`/mtokna/changeUserSettings/${id}`, { settings }, 'POST').then(response => {
        const { status } = response.data;

        if (status === 'error') {
            screen.showSnackbar('error', __('Nepodarilo sa zmeniť heslo'));
            return;
        }

        screen.showSnackbar('success', __('Heslo bolo zmenené'));
    });
};

export const changeMtoknaSellerSmtp = (screen, id, smtp) => {
    return dispatch => request(`/mtokna/smtp/${id}`, smtp, 'POST').then(response => {
        const { status, data } = response.data;

        if (status === 'error') {
            screen.onChangeSmtp('loading', false);
            screen.showSnackbar('error', _.has(data, 'message') ? data.message : __('Nepodarilo sa uložiť SMTP'));
            return;
        }

        screen.closeLightbox('smtp');
        screen.showSnackbar('success', __('Nastavenia boli uložené'));

        dispatch({ type: CHANGE_MTOKNA_SELLER_SMTP, payload: { id, smtp } });
    });
};

export const cleanMtoknaSellers = () => ({ type: CLEAN_MTOKNA_SELLERS });
