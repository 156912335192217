import _ from 'lodash';
import {
    FETCH_LABELS,
    CLEAN_LABELS,
    DELETE_LABEL,
    DOWNLOAD_LABEL,
    CREATE_COURIER,
    DELETE_COURIER,
} from './types';
import { request, __ } from '../functions';

/**
 * Nacitanie stitkov.
 *
 * @param {Screen} screen
 * @param {object} options
 */
export const fetchLabels = (screen, options = {}) => {
    const defaults = {
        page: 1,
        per_page: 0,
        filtered: {},
    };

    options = { ...defaults, ...options };

    let params = { page: options.page, per_page: options.per_page };

    // Nasetujeme filter
    _.forEach(options.filtered, (value, key) => {
        params = { ...params, ...{ [`filter_${key}`]: value } };
    });

    return dispatch => request('/user-labels/index', params).then(response => {
        const { data, sync } = response.data;
        const { setUser } = screen.props;

        // Nasetujeme user data
        setUser(sync.user);

        dispatch({ type: FETCH_LABELS, payload: data });
    });
};

/**
 * Zmazanie stitku.
 *
 * @param {Screen} screen
 * @param {number} id
 */
export const deleteLabel = (screen, id) => {
    return dispatch => request(`/user-labels/delete/${id}`).then(response => {
        const { status } = response.data;

        if (status === 'error') {
            // Stitok sa nepodarilo zmazat
            screen.showSnackbar('error', __('Nepodarilo sa zmazať štítok'));
            return;
        }

        screen.showSnackbar('success', __('Štítok bol zmazaný'));

        dispatch({ type: DELETE_LABEL });
    });
};

/**
 * Stiahnutie stitku.
 *
 * @param {number} id
 */
export const downloadLabel = id => ({ type: DOWNLOAD_LABEL, payload: id });

/**
 * Vycistenie stitkov.
 */
export const cleanLabels = () => ({ type: CLEAN_LABELS });

/**
 * Vytvorenie prepojenia na prepravcu.
 *
 * @param {number} id
 */
export const createCourier = id => ({ type: CREATE_COURIER, payload: id });

/**
 * Zmazanie prepojenia na prepravcu.
 *
 * @param {number} id
 */
export const deleteCourier = id => ({ type: DELETE_COURIER, payload: id });
