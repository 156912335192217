import _ from 'lodash';
import {
    FETCH_ADERYN_PRODUCTS,
    CLEAN_ADERYN_PRODUCTS,
    CHANGE_ADERYN_PRODUCT,
} from './types';
import { request, __ } from '../functions';

export const fetchAderynProducts = (screen, options = {}) => {
    const defaults = {
        page: 1,
        per_page: 0,
        filtered: {},
    };

    options = { ...defaults, ...options };

    let params = { page: options.page, per_page: options.per_page };

    // Nasetujeme filter
    _.forEach(options.filtered, (value, key) => {
        params = { ...params, ...{ [`filter_${key}`]: value } };
    });

    return dispatch => request('/aderyn/products', params).then(response => {
        const { data, sync } = response.data;
        const { setUser } = screen.props;

        // Nasetujeme user data
        setUser(sync.user);

        dispatch({ type: FETCH_ADERYN_PRODUCTS, payload: data });
    });
};

export const changeAderynProduct = (screen, sku, value, comment) => {
    return dispatch => request('/aderyn/createLog', { sku, value, comment }, 'POST').then(response => {
        const { status, data } = response.data;

        if (status === 'error') {
            screen.showSnackbar('error', __('Nepodarilo sa upraviť produkt'));
            return;
        }

        screen.showSnackbar('success', __('Stav na sklade bol zmenený'));

        dispatch({ type: CHANGE_ADERYN_PRODUCT, payload: data });
    });
};

export const cleanAderynProducts = () => ({ type: CLEAN_ADERYN_PRODUCTS });
