import React from 'react';
import { connect } from 'react-redux';
import { withCookies } from 'react-cookie';
import _ from 'lodash';
import {ListScreen} from '../components';
import {
    fetchAderynProductLogs,
    cleanAderynProductLogs,
    setUser,
    changeUserSetting,
} from '../actions';
import { __, formatDate } from '../functions';
import '../assets/styles/aderyn_product_logs.css';

/**
 * Klienti.
 */
class AderynProductLogsScreen extends ListScreen {
    /**
     * Title.
     *
     * @type {string}
     */
    title = __('Pohyby');

    /**
     * Default state.
     *
     * @type {Object}
     */
    state = { ...ListScreen.state, ...{
        lightbox: {},
    }};

    /**
     * Memory cache.
     *
     * @type {Object}
     */
    memoryCache = {};

    /**
     * Nacitame zoznam.
     *
     * @param {number} page
     * @param {number} per_page
     * @param {Object} filtered
     * @param {function|null} callback
     */
    async fetchList(page = 1, per_page = 0, filtered = {}, callback = null) {
        this.page = page;

        const { fetch, params } = this.props;

        // Nacitame zoznam
        await fetch(this, { page, per_page, filtered, callback }, params.id);

        const { items } = this.props;

        if (!_.isEmpty(items.filtered)) {
            // Nastavime filter
            this.setState({ filtered: items.filtered });
        }

        this.afterFetchList();
    }

    /**
     * Vratime nazov tabulky.
     *
     * @return {string}
     */
    getTableName() {
        return 'aderyn_product_logs';
    }

    /**
     * Vratime zoznam stlpcov.
     *
     * @return {Array}
     */
    getColumns() {
        return [
            <div>{__('Dátum')}</div>,
            <div>{__('Pohyb')}</div>,
            <div>{__('Užívateľ')}</div>,
            <div>{__('Poznámka')}</div>,
            <div></div>,
        ];
    }

    /**
     * Vratime bunky.
     *
     * @param {Object} item
     *
     * @return {Array}
     */
    getCells(item) {
        return [
            <div>{formatDate(item.created, 'dd.mm.yyyy hh:ii')}</div>,
            <div>{item.value}</div>,
            <div>{_.has(item.data, 'email') && item.data.email !== '' ? item.data.email : '-'}</div>,
            <div>{_.has(item.data, 'comment') && item.data.comment !== '' ? item.data.comment : '-'}</div>,
            <div></div>,
        ];
    }

    /**
     * Vratime empty text.
     *
     * @return {string}
     */
    getEmptyText() {
        return __('Zatiaľ nemáte žiadne pohyby');
    }
}

const stateToProps = ({ aderyn_product_logs, user }) => ({ items: aderyn_product_logs, user });

export default withCookies(connect(stateToProps, {
    fetch: fetchAderynProductLogs,
    clean: cleanAderynProductLogs,
    setUser,
    changeUserSetting,
})(AderynProductLogsScreen));
