import _ from 'lodash';
import {
    FETCH_MTOKNA_PRODUCTS,
    CLEAN_MTOKNA_PRODUCTS,
    DELETE_MTOKNA_PRODUCT,
    CHANGE_MTOKNA_PRODUCT_STATUS,
} from './types';
import {request, __} from '../functions';

export const fetchMtoknaProducts = (screen, options = {}) => {
    const defaults = {
        page: 1,
        per_page: 0,
        filtered: {},
        callback: null,
    };

    options = { ...defaults, ...options };

    let params = { page: options.page, per_page: options.per_page };

    // Nasetujeme filter
    _.forEach(options.filtered, (value, key) => {
        params = { ...params, ...{ [`filter_${key}`]: value } };
    });

    return dispatch => request('/mtokna/products', params).then(response => {
        const { data, sync } = response.data;
        const { setUser } = screen.props;

        if (options.callback !== null) {
            // Zavolame callback
            options.callback();
        }

        // Nasetujeme user data
        setUser(sync.user);

        dispatch({ type: FETCH_MTOKNA_PRODUCTS, payload: data });
    });
};

export const cleanMtoknaProducts = () => ({ type: CLEAN_MTOKNA_PRODUCTS });

export const changeMtoknaProductStatus = (screen, id, product_status) => {
    return dispatch => request(`/mtokna/changeProductStatus/${id}/${product_status}`).then(response => {
        const { status } = response.data;

        if (status === 'error') {
            screen.showSnackbar('error', __('Nepodarilo sa zmeniť produkt'));
            return;
        }

        screen.showSnackbar('success', __('Produkt bol zmenený'));

        dispatch({ type: CHANGE_MTOKNA_PRODUCT_STATUS, payload: { id, status: product_status } });
    });
};

/**
 * Zmazanie objednavky.
 *
 * @param {Screen} screen
 * @param {number} id
 */
export const deleteMtoknaProduct = (screen, id) => {
    return dispatch => request(`/mtokna/removeProduct/${id}`).then(response => {
        const { status } = response.data;

        if (status === 'error') {
            // Objednavku sa nepodarilo zmazat
            screen.showSnackbar('error', __('Produkt sa nepodarilo zmazať'));
            return;
        }

        screen.showSnackbar('success', __('Produkt bol zmazaný'));

        dispatch({ type: DELETE_MTOKNA_PRODUCT });
    });
};
