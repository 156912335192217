import { FETCH_MTOKNA_TEMPLATES, CLEAN_MTOKNA_TEMPLATES } from '../actions/types';

const MTOKNA_TEMPLATES_DEFAULT_STATE = {};

/**
 * Reducer pre sablony.
 */
const MtoknaTemplatesReducer = (state = MTOKNA_TEMPLATES_DEFAULT_STATE, { type, payload }) => {
    switch (type) {
        case FETCH_MTOKNA_TEMPLATES:
            return payload;

        case CLEAN_MTOKNA_TEMPLATES:
            return MTOKNA_TEMPLATES_DEFAULT_STATE;

        default:
            return state;
    }
};

export default MtoknaTemplatesReducer;
