import {
    FETCH_NOTIFICATIONS,
    CLEAN_NOTIFICATIONS,
    DELETE_NOTIFICATION,
    DELETE_NOTIFICATIONS,
    READ_NOTIFICATION,
    READ_NOTIFICATIONS,
} from '../actions/types';
import _ from 'lodash';
import { toNumber } from '../functions';

const NOTIFICATIONS_DEFAULT_STATE = {};

/**
 * Reducer pre notifikacie.
 */
const NotificationsReducer = (state = NOTIFICATIONS_DEFAULT_STATE, { type, payload }) => {
    switch (type) {
        case FETCH_NOTIFICATIONS:
            // Nacitanie notifikacii
            return payload;

        case READ_NOTIFICATION:
        case READ_NOTIFICATIONS:
            // Precitanie notifikacie
            // Precitanie notifikacii
            return { ...state, items: _.map(state.items, item => {
                if (_.includes(payload, toNumber(item.id))) {
                    // Nasli sme notifikaciu, oznacime ako precitanu
                    item = { ...item, status: 'read' };
                }

                return item;
            }) };

        case CLEAN_NOTIFICATIONS:
        case DELETE_NOTIFICATION:
        case DELETE_NOTIFICATIONS:
            // Vycistenie notifikacii
            // Zmazanie notifikacie
            // Zmazanie notifikacii
            return NOTIFICATIONS_DEFAULT_STATE;

        default:
            return state;
    }
};

export default NotificationsReducer;
