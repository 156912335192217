import {
    FETCH_ACCOUNTING,
    FETCH_ACCOUNTING_DATA,
    CHANGE_ACCOUNTING_AUTO_FIELD,
    CHANGE_ACCOUNTING_SETTINGS,
    CREATE_ACCOUNTING,
    DELETE_ACCOUNTING,
} from '../actions/types';

const ACCOUNTING_DEFAULT_STATE = [];

/**
 * Reducer pre fakturacny system.
 */
const AccountingReducer = (state = ACCOUNTING_DEFAULT_STATE, { type, payload }) => {
    switch (type) {
        case FETCH_ACCOUNTING:
            // Nacitanie fakturacneho systenu
            return payload;

        case FETCH_ACCOUNTING_DATA:
            // Nacitanie dat fakturacneho systemu
            return { ...state,
                data: payload.data,
                synchronized: payload.synchronized,
                ftp: payload.ftp,
                web: payload.web,
            };

        case CHANGE_ACCOUNTING_AUTO_FIELD:
            // Zmena auto fieldu
            return { ...state, [payload.field]: payload.value ? 'on' : 'off' };

        case CHANGE_ACCOUNTING_SETTINGS:
            // Zmena nastaveni fakturacneho systemu
            return { ...state, settings: payload };

        case CREATE_ACCOUNTING:
            // Vytvorenie ekonomickeho systemu
            return { ...state, id: payload };

        case DELETE_ACCOUNTING:
            // Zmazanie ekonomickeho systemu
            return { ...state, id: 0 };

        default:
            return state;
    }
};

export default AccountingReducer;
