import { FETCH_MARKETPLACES, CLEAN_MARKETPLACES, CREATE_MARKETPLACE, DELETE_MARKETPLACE } from '../actions/types';
import _ from 'lodash';
import { toNumber } from '../functions';

const MARKETPLACES_DEFAULT_STATE = {};

/**
 * Reducer pre trhoviska.
 */
const MarketplacesReducer = (state = MARKETPLACES_DEFAULT_STATE, { type, payload }) => {
    switch (type) {
        case FETCH_MARKETPLACES:
            // Nacitanie trhovisk
            return payload;

        case CLEAN_MARKETPLACES:
            // Vycistenie trhovisk
            return MARKETPLACES_DEFAULT_STATE;

        case CREATE_MARKETPLACE:
            // Vytvorime trhovisko
            return { ...state, user_marketplaces: [ ...state.user_marketplaces, ...[payload] ] };

        case DELETE_MARKETPLACE:
            // Zrusime trhovisko
            return { ...state, user_marketplaces: _.remove(state.user_marketplaces, i => toNumber(i) !== toNumber(payload)) };

        default:
            return state;
    }
};

export default MarketplacesReducer;
