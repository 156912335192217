import React from 'react';
import _ from 'lodash';
import { Navigate, Input, Select, Message, Button } from '../components';
import { __, request, toNumber } from '../functions';
import '../assets/styles/storage-drawer.css';

/**
 * Storage komponenta.
 */
class Storage extends Navigate {
    /**
     * Default props.
     *
     * @type {Object}
     */
    static defaultProps = {
        id: 0,
    };

    /**
     * Default state.
     *
     * @type {Object}
     */
    state = {
        showSetup: false,
        data: {},
        fields: {},
        companies: {},
        loading: false,
    };

    /**
     * Zoznam labelov.
     *
     * @type {Object}
     */
    labels = {
        'market': __('Lokalizácia'),
        'company_id': __('Firma'),
        'email': __('Prihlasovací email'),
        'password': __('Prihlasovacie heslo'),
        'url': __('Url adresa'),
        'api_key': __('API klúč'),
        'db_path': __('Cesta ku zdrojovej ONIX databáze'),
        'order_id': __('ID typu Objednávka'),
        'storage_code': __('Kód skladu'),
        'abra_url': __('ABRA Flex url adresa'),
        'client_id': __('Client Id'),
        'client_secret': __('Client Secret'),
        'ico': __('IČO účtovnej jednotky'),
        'storage_id': __('Kód skladu (pre vytváranie skladových zásob)'),
        'storage_price_id': __('Kód cenovej skupiny (pre vytváranie skladových zásob)'),
    };

    /**
     * Zoznam labelov nastaveni.
     *
     * @type {Object}
     */
    settingsLabels = {
        'video': __('Návod'),
        'host': __('FTP server'),
        'user': __('Prihlasovacie meno'),
        'password': __('Prihlasovacie heslo'),
        'file': __('Názov súboru'),
        'download': __('Súbor'),
    };

    /**
     * Komponenta bola pripojena.
     */
    componentDidMount() {
        const { title, setTitle, user } = this.props;

        // Nastavime title
        setTitle(title);

        if (toNumber(user.user_eshop_id) === 0) {
            return;
        }

        // Nacitame data
        this.fetch();
    }

    /**
     * Nacitame data.
     */
    fetch() {
        const { id, onClose } = this.props;

        // Nacitame data skladu
        request('/user-eshops/storage').then(response => {
            const { status, data } = response.data;

            if (status === 'error') {
                // Brana neexistuje
                onClose();
                return;
            }

            this.setState({
                data,
                fields: this.getDefaultFields(data.storages[id].fields),
            });
        });
    }

    /**
     * Vratime zoznam default fieldov.
     *
     * @param {Object} fields
     *
     * @return {Object}
     */
    getDefaultFields(fields) {
        return _.reduce(
            fields,
            (result, field, name) => ({ ...result, [name]: _.isObject(field) ? _.keys(field)[0] : '' }),
            {}
        );
    }

    /**
     * Event po zmene fieldu.
     *
     * @param {string} field
     * @param {string} value
     */
    onChangeField(field, value) {
        const { fields } = this.state;

        this.setState({ fields: { ...fields, [field]: value } });
    }

    /**
     * Zobrazime setup.
     */
    showSetup() {
        this.setState({ showSetup: true });
    }

    /**
     * Schovame setup.
     */
    closeSetup() {
        this.setState({ showSetup: false });
    }

    /**
     * Skontrolujeme credentials.
     *
     * @param {boolean} create
     */
    checkCredentials(create) {
        const { id, showSnackbar } = this.props;
        const { fields, companies } = this.state;

        this.setState({ loading: true });

        if (!_.isEmpty(companies)) {
            // Mame zadany zoznam firiem, pokracujeme dalej
            this.save(fields, create);
            return;
        }

        request(`/user-eshops/storageCredentials/${id}`, fields, 'POST').then(response => {
            const { status, data } = response.data;

            if (status === 'error') {
                this.setState({ loading: false });
                showSnackbar('error', __('Nepodarilo sa prihlásiť do skladu'));
                return;
            }

            if (!_.has(data.credentials, 'company_id')) {
                // Nie je zadane company id
                this.save(data.credentials, create);
                return;
            }

            const { companies, company_id } = data.credentials;

            if (company_id !== '' && company_id !== '0' && company_id !== 0) {
                // Mame zadanu polozku, pokracujeme
                this.save(data.credentials, create);
                return;
            }

            // Mame viac firiem musime zobrazit select
            this.setState({
                loading: false,
                fields: { ...data.credentials, company_id: _.keys(companies)[0] },
                companies,
            });
        });
    }

    /**
     * Ulozenie.
     *
     * @param {Object} credentials
     * @param {boolean} create
     */
    save(credentials, create) {
        const { id, showSnackbar, createStorage, refreshData } = this.props;

        request('/user-eshops/changeStorageCredentials', { storage_id: id, credentials }, 'POST').then(response => {
            const { status } = response.data;

            if (status === 'error') {
                // Nepodarilo sa zmenit credentials
                this.setState({ loading: false });
                showSnackbar('error', create
                    ? __('Nepodarilo sa aktivovať sklad')
                    : __('Nepodarilo sa zmeniť prihlasovacie údaje'));
                return;
            }

            // Vycistime data
            this.setState({ loading: false, data: {}, companies: {}, showSetup: false });

            showSnackbar('success', create
                ? __('Sklad bol aktivovaný')
                : __('Prihlasovacie údaje boli zmenené')
            );

            // Nacitame data znovu
            this.fetch();

            if (create) {
                // Vytvorime
                createStorage(id);
            }

            // Refreshneme data
            refreshData();
        });
    }

    /**
     * Zmazeme sklad.
     */
    delete() {
        const { showSnackbar, onClose, deleteStorage } = this.props;

        this.setState({ loading: true });

        request('/user-eshops/deleteStorage').then(response => {
            const { status } = response.data;

            if (status === 'error') {
                // Nepodarilo sa zmazat
                this.setState({ loading: false });
                showSnackbar('error', __('Nepodarilo sa deaktivovať sklad'));
                return;
            }

            showSnackbar('error', __('Sklad bol deaktivovaný'));

            // Zavrieme
            onClose();

            // Zmazeme
            deleteStorage();
        });
    }

    /**
     * Vratime tutorial link.
     *
     * @param {string} name
     *
     * @return {string|null}
     */
    getTutorialLink(name) {
        switch (name) {
            case 'Pohoda':
                return '/blog/storage-pohoda';

            default:
                return null;
        }
    }

    /**
     * Vratime custom message.
     *
     * @param {string} name
     *
     * @return {string|null}
     */
    getCustomMessage(name) {
        switch (name) {
            case 'Pohoda':
                return __('Zadávate prihlasovacie údaje do https://www.mpohoda.sk');

            default:
                return null;
        }
    }

    /**
     * Rendrovanie fieldov.
     *
     * @param {Object} items
     *
     * @return {JSX.Element[]}
     */
    renderFields(items) {
        const { fields } = this.state;

        return _.map(items, (field, name) => {
            const label = _.has(this.labels, name) ? this.labels[name] : '';

            switch (name) {
                case 'market':
                    // Lokalizacia
                    return <Select
                        key={name}
                        label={label}
                        options={field}
                        value={fields[name]}
                        onChange={value => this.onChangeField(name, value)}
                        allowEmpty={false}
                    />;

                case 'company_id':
                    // Firma
                    return <Select
                        key={name}
                        label={label}
                        options={field}
                        value={fields[name]}
                        onChange={value => this.onChangeField(name, value)}
                        allowEmpty={false}
                    />;

                case 'email':
                    // Email
                    return <Input
                        type="email"
                        key={name}
                        label={label}
                        value={fields[name]}
                        onChange={value => this.onChangeField(name, value)}
                    />;

                case 'password':
                    // Heslo
                    return <Input
                        type="password"
                        key={name}
                        label={label}
                        value={fields[name]}
                        onChange={value => this.onChangeField(name, value)}
                    />;

                case 'url':
                    // Url adresa
                    return <Input
                        key={name}
                        label={label}
                        value={fields[name]}
                        onChange={value => this.onChangeField(name, value)}
                        placeholder="http://195.146.148.111"
                    />;

                case 'api_key':
                case 'client_id':
                case 'client_secret':
                case 'ico':
                    // API kluc
                    return <Input
                        key={name}
                        label={label}
                        value={fields[name]}
                        onChange={value => this.onChangeField(name, value)}
                    />;

                case 'db_path':
                    // DB path
                    return <Input
                        key={name}
                        label={label}
                        value={fields[name]}
                        onChange={value => this.onChangeField(name, value)}
                        placeholder="C:\ProgramData\ONIX\Data\Demo\Demo.ndb"
                    />;

                case 'order_id':
                    // Order id
                    return <Input
                        key={name}
                        label={label}
                        value={fields[name]}
                        onChange={value => this.onChangeField(name, value)}
                        placeholder="1000013"
                    />;

                case 'storage_code':
                    // Kod skladu
                    return <Input
                        key={name}
                        label={label}
                        value={fields[name]}
                        onChange={value => this.onChangeField(name, value)}
                        placeholder="HS"
                    />;

                case 'abra_url':
                    // Abra url
                    return <Input
                        key={name}
                        label={label}
                        value={fields[name]}
                        onChange={value => this.onChangeField(name, value)}
                        placeholder="https://demo.flexibee.eu/v2/c/demo"
                    />;

                case 'storage_id':
                    // Pohoda mServer kod skladu
                    return <Input
                        key={name}
                        label={label}
                        value={fields[name]}
                        onChange={value => this.onChangeField(name, value)}
                        placeholder="01"
                    />;

                case 'storage_price_id':
                    // Pohoda mServer kod cenovej skupiny
                    return <Input
                        key={name}
                        label={label}
                        value={fields[name]}
                        onChange={value => this.onChangeField(name, value)}
                        placeholder="SK"
                    />;

                default:
                    return null;
            }
        });
    }

    /**
     * Rendrovanie.
     *
     * @return {JSX.Element}
     */
    render() {
        const { id, user } = this.props;
        const { data, companies, loading, loadingDelete, showSetup } = this.state;

        if (toNumber(user.user_eshop_id) === 0) {
            return <div
                className="storage-drawer-content"
            ><Message type="warning">{__('Najprv musíte aktivovať eshop')}</Message></div>;
        }

        if (_.isEmpty(data)) {
            // Data nie su nacitane
            return <div
                className="storage-drawer-content storage-drawer-content-loading"
            >{this.renderLoading()}</div>;
        }

        // Je aktivne?
        const activated = toNumber(data.id) === toNumber(id);

        // Fieldy na generovanie
        let { name, fields } = data.storages[id];

        if (!_.isEmpty(companies)) {
            // Mame zadany zoznam firiem zobrazime len ten
            fields = { company_id: companies };
        }

        // Vytiahneme credentials
        const credentials = _.reduce(data.credentials, (result, value, name) => {
            if (!_.has(this.labels, name)) {
                return result;
            }

            return { ...result, [name]: value };
        }, {});

        // Vytiahneme custom message
        const customMessage = this.getCustomMessage(name);

        // Vytiahneme tutorial link
        const tutorialLink = this.getTutorialLink(name);

        return (
            <div className="storage-drawer-content">
                {activated && !showSetup
                    ? <div className="storage-drawer-content__data">
                        {!_.isEmpty(credentials) ? <div className="storage-drawer-content__data__credentials">
                            <div className="storage-drawer-content__data__credentials__title">{__('Prihlasovacie údaje')}</div>
                            {_.map(credentials, (value, name) => {
                                if (
                                    name === 'company_id'
                                    && _.has(data.credentials, 'companies')
                                    && _.has(data.credentials.companies, value)
                                ) {
                                    // Chceme nazov
                                    value = data.credentials.companies[value];
                                }

                                return (
                                    <div className="storage-drawer-content__data__credentials__value" key={name}>
                                        <div className="storage-drawer-content__data__credentials__value__name">
                                            {this.labels[name]}:
                                        </div>
                                        <div className="storage-drawer-content__data__credentials__value__text">
                                            {_.truncate(value, { length: 30 })}
                                        </div>
                                    </div>
                                );
                            })}
                        </div> : null}
                        {!_.isEmpty(credentials) ? <div className="storage-drawer-content__data__buttons">
                            <Button
                                onClick={() => this.showSetup()}
                                color="shadow"
                                className="storage-drawer-content__data__buttons__button"
                            >{__('Upraviť')}</Button>
                        </div> : null}
                        {!_.isEmpty(data.ftp) ? <div className="storage-drawer-content__data__credentials">
                            <div className="storage-drawer-content__data__credentials__title">{__('Nastavenia skladu')}</div>
                            {_.map(data.ftp, (value, name) => {
                                if (value === '') {
                                    return null;
                                }

                                if (!_.has(this.settingsLabels, name)) {
                                    return null;
                                }

                                if (name === 'video') {
                                    value = <a href={value} target="_blank" rel="noopener noreferrer">{__('Zobraziť video')}</a>;
                                } else if (name === 'download') {
                                    value = <a href={value} target="_blank" rel="noopener noreferrer">{__('Stiahnuť prílohu')}</a>;
                                }

                                return (
                                    <div className="storage-drawer-content__data__credentials__value" key={name}>
                                        <div className="storage-drawer-content__data__credentials__value__name">
                                            {this.settingsLabels[name]}:
                                        </div>
                                        <div className="storage-drawer-content__data__credentials__value__text">
                                            {value}
                                        </div>
                                    </div>
                                );
                            })}
                        </div> : null}
                        {!_.isEmpty(data.accounting_ftp) ? <div className="storage-drawer-content__data__credentials second">
                            <div className="storage-drawer-content__data__credentials__title">{__('Nastavenia prijatých objednávok')}</div>
                            {_.map(data.accounting_ftp, (value, name) => {
                                if (value === '') {
                                    return null;
                                }

                                if (!_.has(this.settingsLabels, name)) {
                                    return null;
                                }

                                if (name === 'video') {
                                    value = <a href={value} target="_blank" rel="noopener noreferrer">{__('Zobraziť video')}</a>;
                                } else if (name === 'download') {
                                    value = <a href={value} target="_blank" rel="noopener noreferrer">{__('Stiahnuť prílohu')}</a>;
                                }

                                return (
                                    <div className="storage-drawer-content__data__credentials__value" key={name}>
                                        <div className="storage-drawer-content__data__credentials__value__name">
                                            {this.settingsLabels[name]}:
                                        </div>
                                        <div className="storage-drawer-content__data__credentials__value__text">
                                            {value}
                                        </div>
                                    </div>
                                );
                            })}
                        </div> : null}
                    </div>
                    : <div className="storage-drawer-content__data">
                        {_.isEmpty(companies) && tutorialLink !== null ? <Message type="warning">
                            <span style={{ marginRight: 6 }}>{__('Pripravili sme pre vás')}</span>
                            <a href={tutorialLink} target="_blank">{__('návod na aktiváciu')}</a>
                        </Message> : null}
                        {_.isEmpty(companies) && customMessage !== null ? <Message type="info">{customMessage}</Message> : null}
                        {!_.isEmpty(companies) ? <Message type="warning">{__('V sklade máte zaevidovaných viacero firiem, prosím vyberte konkrétnu firmu')}</Message> : null}
                        {this.renderFields(fields)}
                    </div>}
                <div className="storage-drawer-content__buttons">
                    {showSetup ? <Button
                        onClick={() => this.closeSetup()}
                        color="shadow"
                        className="storage-drawer-content__buttons__button"
                    >{__('Naspäť')}</Button> : null}
                    {activated && !showSetup ? <Button
                        onClick={() => this.delete()}
                        color="red"
                        className="storage-drawer-content__buttons__button"
                        loading={loadingDelete}
                    >{__('Deaktivovať')}</Button> : <Button
                        onClick={() => this.checkCredentials(!activated)}
                        color="green"
                        className="storage-drawer-content__buttons__button"
                        loading={loading}
                    >{showSetup ? __('Uložiť') : __('Aktivovať')}</Button>}
                </div>
            </div>
        );
    }
}

export { Storage };
