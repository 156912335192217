import {
    FETCH_MTOKNA_PRODUCTS,
    CLEAN_MTOKNA_PRODUCTS,
    DELETE_MTOKNA_PRODUCT,
    CHANGE_MTOKNA_PRODUCT_STATUS,
} from '../actions/types';
import _ from "lodash";
import {toNumber} from "../functions";

const MTOKNA_PRODUCTS_DEFAULT_STATE = {};

/**
 * Reducer pre objednavky.
 */
const MtoknaProductsReducer = (state = MTOKNA_PRODUCTS_DEFAULT_STATE, { type, payload }) => {
    switch (type) {
        case FETCH_MTOKNA_PRODUCTS:
            return payload;

        case CHANGE_MTOKNA_PRODUCT_STATUS:
            return { ...state, items: _.map(state.items, item => {
                if (toNumber(item.id) === toNumber(payload.id)) {
                    item = { ...item, status: payload.status };
                }

                return item;
            }) };

        case CLEAN_MTOKNA_PRODUCTS:
        case DELETE_MTOKNA_PRODUCT:
            return MTOKNA_PRODUCTS_DEFAULT_STATE;

        default:
            return state;
    }
};

export default MtoknaProductsReducer;
