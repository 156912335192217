import React from 'react';
import { connect } from 'react-redux';
import { withCookies } from 'react-cookie';
import _ from 'lodash';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import Editor from 'ckeditor5-build-full';
import { Tooltip, Chip } from '@mui/material';
import HelpIcon from '@mui/icons-material/Help';
import { Screen, Message, Input, Select, Button } from '../components';
import { fetchExport, setUser, createExport, cleanExport } from '../actions';
import { __, toNumber, isEmptyString } from '../functions';
import { EXPORT_TYPES, PLACEHOLDERS, ADDITIONAL_PLACEHOLDERS } from '../config';
import '../assets/styles/export.css';

/**
 * Export.
 */
class ExportScreen extends Screen {
    /**
     * Title.
     *
     * @type {string}
     */
    title = __('Nový export');

    /**
     * Default state.
     *
     * @type {Object}
     */
    state = {
        data: {},
        loading: false,
    };

    /**
     * Komponenta bola pripojena.
     *
     * @return boolean
     */
    async componentDidMount() {
        if (super.componentDidMount()) {
            const { fetchExport } = this.props;

            // Vytiahneme id exportu
            const exportId = this.getExportId();

            // Nasetujeme title
            this.setTitle(exportId > 0 ? __('Úprava exportu') : __('Nový export'));

            // Nacitame export
            await fetchExport(this, exportId);

            const { export_data } = this.props;

            let data = {
                type: _.keys(EXPORT_TYPES)[0],
                name: '',
                data: '',
            };

            if (!_.isEmpty(export_data)) {
                // Mame data z editu
                data = {
                    type: export_data.type,
                    name: export_data.name,
                    data: export_data.data,
                };
            }

            this.setState({ data });
        }

        return true;
    }

    /**
     * Komponenta bude odpojena.
     */
    componentWillUnmount() {
        const { cleanExport } = this.props;

        cleanExport();
    }

    /**
     * Vratime id exportu.
     *
     * @return {number}
     */
    getExportId() {
        const { location } = this.props;

        return toNumber(location.pathname.replace('/export/', ''));
    }

    /**
     * Ulozenie.
     */
    save() {
        let { data } = this.state;

        let error = '';

        if (isEmptyString(data.name)) {
            // Nie je vyplneny nazov
            error = __('Nie je vyplnený názov');
        } else if (isEmptyString(data.data)) {
            // Nie je vyplnena sablona
            error = __('Nie je vyplnená šablóna');
        }

        if (this.isDemo()) {
            error = __('Export nie je možné uložiť na DEMO účte');
        }

        if (error !== '') {
            // Mame error
            this.showSnackbar('error', error);
            return;
        }

        this.setState({ loading: true });

        const { createExport } = this.props;

        data = { ...data, id: this.getExportId() };

        if (data.type === 'pdf') {
            data = { ...data, data: data.data.replace(/<table>/g, '<table border="1" cellpadding="2">') };
        }

        // Ulozime export
        createExport(this, data);
    }

    /**
     * Event po zmene dat.
     *
     * @param {string} type
     * @param {string} value
     */
    onChange(type, value) {
        const { data } = this.state;

        this.setState({ data: { ...data, [type]: value } });
    }

    /**
     * Event po kliku na placeholder.
     *
     * @param {string} placeholder
     */
    onClickPlaceholder(placeholder) {
        // Nastavime placeholder do clipboardu
        navigator.clipboard.writeText(placeholder);

        this.showSnackbar('success', __('Skopirované do schránky'));
    }

    /**
     * Rendrovanie.
     *
     * @returns {JSX.Element}
     */
    render() {
        const { export_data } = this.props;
        const { data, loading } = this.state;

        if (_.isEmpty(data)) {
            // Data nie su nacitane
            return this.renderLoading();
        }

        if (!this.hasPermission('exports-edit')) {
            // Nema pravo
            return null;
        }

        // Je to edit?
        const isEdit = this.getExportId() > 0;

        // Je to default?
        const isDefault = _.has(export_data, 'def') && toNumber(export_data.def) === 1;

        return (
            <div className="export">
                <div className="export__header">
                    <div className="export__header__left">
                        <div className="export__header__left__title">
                            {isEdit ? __('Úprava exportu') : __('Nový export')}
                        </div>
                    </div>
                </div>
                <div className="export__content">
                    <Message type="info">{__('Do exportov môžete vkladať špecialné slova ktoré sa pri exportovaní automaticky doplnia podľa objednávky.')}</Message>
                    <div className="export__content__panels">
                        <div className="export__content__panels__panel">
                            <div className="export__content__panels__panel__title">{__('Špeciálne slová')}</div>
                            <div className="export__content__panels__panel__text">{__('Kliknutím skopírujete do schránky.')}</div>
                            <div className="export__content__panels__panel__placeholders">
                                {_.map({ ...PLACEHOLDERS, ...ADDITIONAL_PLACEHOLDERS }, (text, placeholder) => {
                                    if (_.includes(['{order.payment_type}', '{order.delivery_type}'], placeholder)) {
                                        // Nechceme zobrazovat
                                        return null;
                                    }

                                    return (
                                        <div key={placeholder}>
                                            <Tooltip title={text}>
                                                <Chip
                                                    onClick={() => this.onClickPlaceholder(placeholder)}
                                                    label={placeholder}
                                                    clickable
                                                    color="primary"
                                                    deleteIcon={<HelpIcon />}
                                                />
                                            </Tooltip>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                        <div className="export__content__panels__panel">
                            <Select
                                label={__('Typ')}
                                value={data.type}
                                options={EXPORT_TYPES}
                                onChange={value => this.onChange('type', value)}
                                allowEmpty={false}
                                disabled={isDefault}
                            />
                            <Input
                                label={__('Názov')}
                                value={data.name}
                                onChange={value => this.onChange('name', value)}
                                disabled={isDefault}
                            />
                            <Message type="info">{data.type === 'excel'
                                ? __('V šablóne si môžete zvoliť ľubovoľný počet riadkov. Stĺpce oddeľte medzi sebou bodkočiarkou ";".')
                                : __('V šablóne môžete použiť HTML kód.')}</Message>
                            {data.type === 'excel' ? <Input
                                label={__('Šablóna')}
                                value={data.data}
                                onChange={value => this.onChange('data', value)}
                                placeholder={`${__('Objednávka č.')} {order.number};{order.client.name};{order.amount};{order.delivery_name};{order.payment_name}`}
                                multiline
                            /> : <div className="input">
                                <div className="input__label editor-label">{__('Šablóna')}</div>
                                <CKEditor
                                    editor={Editor}
                                    data={data.data}
                                    onChange={(event, editor) => this.onChange('data', editor.getData())}
                                />
                            </div>}
                        </div>
                    </div>
                    <Button
                        onClick={() => this.save()}
                        loading={loading}
                        className="export__content__button"
                        color="green"
                    >{__('Uložiť')}</Button>
                </div>
                {this.renderSnackbar()}
            </div>
        );
    }
}

const stateToProps = ({ export_data, user }) => ({ export_data, user });

export default withCookies(connect(stateToProps, {
    fetchExport,
    setUser,
    createExport,
    cleanExport,
})(ExportScreen));
