import React, { Component } from 'react';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { TextField, Typography } from '@mui/material';
import { __, isMobile } from '../functions';
import '../assets/styles/input.css';

/**
 * Input komponenta.
 */
class Input extends Component {
    /**
     * Default props.
     *
     * @type {{
     *     type: string,
     *     label: string,
     *     value: string,
     *     maxLength: number,
     *     required: boolean,
     *     multiline: boolean,
     *     focus: boolean,
     *     disabled: boolean,
     *     error: boolean,
     *     inputRef: ref,
     *     content: null,
     *     rows: number,
     *     placeholder: string,
     *     onChange: function,
     *     icon: JSX.Element|null,
     *     variant: string,
     * }}
     */
    static defaultProps = {
        type: 'text',
        label: '',
        value: '',
        maxLength: null,
        required: false,
        multiline: false,
        focus: false,
        disabled: false,
        error: false,
        inputRef: null,
        content: null,
        rightButton: null,
        rows: 0,
        placeholder: '',
        onChange: () => {},
        onKeyPress: () => {},
        onBlur: () => {},
        icon: null,
        variant: 'outlined',
    };

    /**
     * Default state.
     *
     * @type {{
     *     value: string
     * }}
     */
    state = {
        value: '',
    };

    /**
     * Komponenta bola pripojena.
     */
    componentDidMount() {
        const { value } = this.props;

        // Nasetujeme value do state z props
        this.setState({ value });
    }

    /**
     * Komponenta bola zmenena.
     *
     * @param {Object} prevProps
     * @param {Object} prevState
     * @param snapshot
     */
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.state.value !== this.props.value) {
            // Nesedi hodnota v state a props, aktualizujeme
            this.setState({ value: this.props.value });
        }
    }

    /**
     * Event po zmene.
     *
     * @param {string} value
     */
    onChange(value) {
        this.setState({ value });

        this.props.onChange(value);
    }

    /**
     * Event po stlaceni klavesa.
     *
     * @param {string} value
     */
    onKeyPress(value) {
        this.props.onKeyPress(value);
    }

    onBlur(value) {
        this.props.onBlur(value);
    }

    /**
     * Rendrovanie.
     *
     * @returns {JSX.Element}
     */
    render() {
        const { type, label, maxLength, required, multiline, rows, focus, disabled, inputRef, placeholder, error, icon, variant, rightButton } = this.props;
        const { value } = this.state;

        let inputProps = {};

        if (maxLength !== null) {
            inputProps = { ...inputProps, maxLength };
        }

        let content = this.props.content !== null ? this.props.content : <TextField
            type={type}
            onChange={event => this.onChange(event.target.value)}
            onKeyPress={event => this.onKeyPress(event.key)}
            onBlur={event => this.onBlur(event.target.value)}
            value={value}
            variant={variant}
            inputProps={inputProps}
            required={required}
            multiline={multiline}
            rows={rows}
            autoFocus={focus}
            disabled={disabled}
            inputRef={inputRef}
            placeholder={placeholder}
            fullWidth
            error={error}
        />;

        if (type === 'date') {
            content = (
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    {!isMobile() ? <DesktopDatePicker
                        inputFormat="dd.MM.yyyy"
                        value={value}
                        onChange={date => this.onChange(date)}
                        renderInput={(params) => <TextField {...params} className="input__container" variant="outlined" error={false} />}
                        cancelText={__('Zrušiť')}
                    /> : <MobileDatePicker
                        inputFormat="dd.MM.yyyy"
                        value={value}
                        onChange={date => this.onChange(date)}
                        renderInput={(params) => <TextField {...params} className="input__container" variant="outlined" />}
                        cancelText={__('Zrušiť')}
                    />}
                </LocalizationProvider>
            );
        }

        return (
            <div className={`input ${disabled ? 'disabled' : ''} ${icon !== null ? 'with-icon' : ''} ${rightButton !== null ? 'with-right-button' : ''}`}>
                {icon}
                {label !== ''
                    ? <Typography variant="body2" className="input__label">{`${label}${required ? ' *' : ''}`}</Typography>
                    : null}
                {content}
                {rightButton !== null ? <div className="input__right-button">{rightButton}</div> : null}
            </div>
        );
    }
}

export { Input };
