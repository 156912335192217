import React from 'react';
import { connect } from 'react-redux';
import { withCookies } from 'react-cookie';
import _ from 'lodash';
import {ListScreen} from '../components';
import {
    fetchMtoknaSchemes,
    cleanMtoknaSchemes,
    setUser,
    changeUserSetting,
    changeMtoknaScheme,
    deleteMtoknaScheme,
} from '../actions';
import {__, formatDate, request} from '../functions';
import '../assets/styles/mtokna_schemes.css';
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from '@mui/icons-material/Delete';
import UploadIcon from "@mui/icons-material/Publish";

/**
 * Klienti.
 */
class MtoknaSchemesScreen extends ListScreen {
    /**
     * Title.
     *
     * @type {string}
     */
    title = __('XML Schémy');

    /**
     * Default state.
     *
     * @type {Object}
     */
    state = { ...ListScreen.state, ...{
        lightbox: {
            delete: false,
            create: false,
        },
    }};

    /**
     * Memory cache.
     *
     * @type {Object}
     */
    memoryCache = {};

    /**
     * Vratime nazov tabulky.
     *
     * @return {string}
     */
    getTableName() {
        return 'mtokna_schemes';
    }

    /**
     * Vratime zoznam stlpcov.
     *
     * @return {Array}
     */
    getColumns() {
        return [
            <div>{__('Názov')}</div>,
            <div>{__('Vytvorené')}</div>,
            <div></div>,
        ];
    }

    /**
     * Vratime bunky.
     *
     * @param {Object} item
     *
     * @return {Array}
     */
    getCells(item) {
        const { user } = this.props;

        return [
            <div>{_.truncate(item.data.name, { length: 32 })}</div>,
            <div>{formatDate(item.created, 'dd.mm.yyyy')}</div>,
            <div>
                {this.renderTableButton(
                    item.id,
                    __('Upraviť'),
                    <EditIcon />,
                    (callbackLoading, callback) => this.redirect(`/schemes/view/${item.id}`),
                    {}
                )}
                {!user.mtokna_moderator ? this.renderTableButton(
                    item.id,
                    __('Zmazať'),
                    <DeleteIcon />,
                    (callbackLoading, callback) => this.confirmDelete(item.id, callbackLoading, callback),
                    {}
                ) : null}
            </div>,
        ];
    }

    /**
     * Vratime filter.
     *
     * @return {Object}
     */
    getFilter() {
        const { items } = this.props;

        return _.reduce(items.filter, (result, options, key) => {
            switch (key) {
                case 'name':
                    return { ...result, ...{ [key]: {
                        type: 'input',
                        name: __('Názov'),
                        value: '',
                    }}};

                default:
                    return result;
            }
        }, {});
    }

    getTags() {
        const { user } = this.props;

        if (user.mtokna_moderator) {
            return [];
        }

        return [
            {
                name: __('Nová schéma'),
                onClick: () => this.showLightbox('create', {
                    loading: false,
                    data: {},
                }),
            }
        ];
    }

    /**
     * Vratime empty text.
     *
     * @return {string}
     */
    getEmptyText() {
        return __('Zatiaľ nemáte žiadnu schému');
    }

    /**
     * Event po zmene create.
     *
     * @param {string} key
     * @param {string} value
     */
    onChangeCreate(key, value) {
        const { lightbox } = this.state;

        this.setState({ lightbox: { ...lightbox, create: {
            ...lightbox.create,
            data: { ...lightbox.create.data, [key]: value }
        } } });
    }

    /**
     * Event po zmene create.
     *
     * @param {string} value
     */
    onChangeCreateLoading(value) {
        const { lightbox } = this.state;

        this.setState({ lightbox: { ...lightbox, create: { ...lightbox.create, loading: value } } })
    }

    /**
     * Zobrazime lightbox na email.
     *
     * @param {number} id
     * @param {function} callbackLoading
     * @param {function} callback
     */
    confirmDelete(id, callbackLoading, callback) {
        this.showLightbox('delete', { id, callbackLoading, callback });
    }

    /**
     * Zmazanie miesta.
     */
    async delete() {
        const { deleteMtoknaScheme, items } = this.props;
        const { lightbox } = this.state;

        // Zavolame loading
        lightbox.delete.callbackLoading();

        // Zavrieme lightbox
        this.closeLightbox('delete');

        // Zmazeme klienta
        await deleteMtoknaScheme(this, lightbox.delete.id);

        // Zavolame callback
        lightbox.delete.callback();

        // Znovu nacitame zoznam od prvej stranky
        this.fetchList(1, 0, items.filtered);
    }

    uploadFile(file) {
        if (file.type !== 'text/xml') {
            // Nie je to pdf
            this.showSnackbar('error', __('Neplatný typ súboru'));
            return;
        }

        this.onChangeCreate('loading', true);

        request('/mtokna/uploadSchemeImport', file, 'FILE').then(response => {
            const { status } = response.data;

            this.onChangeCreate('loading', false);

            if (status === 'error') {
                // Nepodarilo sa nahrat prilohu
                this.showSnackbar('error', __('Nepodarilo sa nahrať súbor'));
                return;
            }

            // Nastavime prilohu
            window.location = '/schemes/view/new';
        });
    }

    /**
     * Rendrujeme screen.
     *
     * @return {JSX.Element|null}
     */
    renderScreen() {
        const { lightbox } = this.state;

        return (
            <div>
                {this.renderLightbox(
                    'delete',
                    __('Naozaj chcete zmazať túto schému?'),
                    null,
                    __('Áno'),
                    __('Nie'),
                    () => this.delete()
                )}
                {this.renderLightbox(
                    'create',
                    __('Nová schéma'),
                    !_.isEmpty(lightbox.create) ? (lightbox.create.loading ? this.renderLoading(20) : <div>
                        <div className="mtokna_schemes__upload">
                            <UploadIcon />
                            <div className="mtokna_schemes__upload__text">{__('Nahrajte súbor vo formáte xml.')}</div>
                            <input type="file" onChange={event => this.uploadFile(event.target.files[0])} />
                        </div>
                    </div>) : null,
                    __('Uložiť'),
                    __('Zrušiť'),
                    () => this.create()
                )}
            </div>
        );
    }
}

const stateToProps = ({ mtokna_schemes, user }) => ({ items: mtokna_schemes, user });

export default withCookies(connect(stateToProps, {
    fetch: fetchMtoknaSchemes,
    clean: cleanMtoknaSchemes,
    setUser,
    changeUserSetting,
    changeMtoknaScheme,
    deleteMtoknaScheme,
})(MtoknaSchemesScreen));
