import React from 'react';
import { connect } from 'react-redux';
import { withCookies } from 'react-cookie';
import _ from 'lodash';
import {ListScreen, Select, MtoknaOrder, Input, Checkbox, Packages} from '../components';
import {
    fetchMtoknaManualOrders,
    cleanMtoknaManualOrders,
    setUser,
    changeUserSetting,
    pdfMtoknaManualOrder,
    changeMtoknaManualOrderState,
    changeMtoknaManualOrderData,
    deleteMtoknaManualOrder,
    createMtoknaManualInvoice,
    pdfMtoknaManualOrders,
    addonMtoknaManualOrders,
    sendMtoknaManualOrders,
} from '../actions';
import {__, formatDate, formatAmount, getOrderButtons, toNumber, request} from '../functions';
import { ORDER_SOURCES } from '../config';
import '../assets/styles/mtokna_manual_orders.css';
import EditIcon from "@mui/icons-material/Edit";
import OrderPdfIcon from "@mui/icons-material/Archive";
import CouriersIcon from "@mui/icons-material/LocalShipping";
import AddonsIcon from "@mui/icons-material/Extension";

/**
 * Objednavky.
 */
class MtoknaManualOrdersScreen extends ListScreen {
    /**
     * Title.
     *
     * @type {string}
     */
    title = __('Vyúčtovanie');

    /**
     * Default state.
     *
     * @type {Object}
     */
    state = { ...ListScreen.state, ...{
        loadingSync: false,
        lightbox: {
            state: false,
            create: false,
            delete: false,
            multiorderpdf: false,
            multiaddon: false,
        },
    }};

    // Memory cache
    memoryCacheStates = null;

    /**
     * Bol volany klik na objednavku.
     *
     * @type {boolean}
     */
    callViewClick = false;

    defaultState = {};

    /**
     * Nacitame zoznam.
     *
     * @param {number} page
     * @param {number} per_page
     * @param {Object} filtered
     * @param {function|null} callback
     */
    async fetchList(page = 1, per_page = 0, filtered = {}, callback = null) {
        this.page = page;

        if (window.location.pathname === '/manual-seller') {
            this.title = __('Objednávky u dodávateľa');
        }

        const { fetch } = this.props;

        // Nacitame zoznam
        await fetch(this, { page, per_page, filtered, callback });

        const { items, user } = this.props;

        if (!_.isEmpty(items.filtered)) {
            // Nastavime filter
            this.setState({ filtered: items.filtered });
        }

        this.afterFetchList();
    }

    /**
     * Vratime zoznam tagov.
     *
     * @return {Array}
     */
    getTags() {
        const { user } = this.props;

        const user_eshops = {
            39: 'VSETKO PRE OKNA',
            38: 'DOBRÉ ŽELEZIARSTVO',
        };

        return [
            user.mtokna_type === 'mtokna_admin' && !user.mtokna_moderator ? {
                name: __('Nové VO vyúčtovanie'),
                onClick: () => this.showLightbox('create', {
                    type: 'vo',
                    user_eshop_id: 39,
                    user_eshops,
                }),
            } : null,
            {
                name: user.mtokna_type === 'mtokna_admin' ? __('Nové MO vyúčtovanie') : __('Nová objednávka'),
                onClick: () => this.redirect('/manual/mo/38/new'),
            }
        ];
    }

    /**
     * Vratime nazov tabulky.
     *
     * @return {string}
     */
    getTableName() {
        return 'mtokna_manual_orders';
    }

    getColumns() {
        return [
            <div>{`${__('Číslo')} / ${__('Suma')} / ${__('Dátum')}`}</div>,
            <div>{`${__('Zákaznik')} / ${__('E-mail')} / ${__('Eshop')}`}</div>,
            <div>{`${__('Tel. číslo')} / ${__('Fakturačná adresa')} / ${__('Dodacia adresa')}`}</div>,
            <div>{`${__('Poznámka')} / ${__('Dodanie')} / ${__('Platba')}`}</div>,
            <div></div>,
        ];
    }

    /**
     * Event po zmene hesla.
     *
     * @param {string} value
     */
    onChangeCreateUserEshopId(value) {
        const { lightbox } = this.state;

        this.setState({ lightbox: { ...lightbox, create: { ...lightbox.create, user_eshop_id: value } } })
    }

    saveCreate() {
        const { lightbox } = this.state;

        this.redirect(`/manual/${lightbox.create.type}/${lightbox.create.user_eshop_id}/new`);
    }

    /**
     * Vratime bunky.
     *
     * @param {Object} item
     *
     * @return {Array}
     */
    getCells(item) {
        const { user, items } = this.props;

        if (this.memoryCacheStates === null) {
            // Nemame v memory cache
            // Naformatujeme zoznam stavov
            this.memoryCacheStates = _.reduce(items.states, (result, { id, name }) => ({ ...result, [id]: name }), {});
        }

        // Vytiahneme tlacitka pre objednavku
        let buttons = getOrderButtons(item, {
            settings: {
                meta_data_pdf_link: '',
            },
            hasPermissionView: true,
            hasPermissionPdfOrder: true,
            hasPermissionSync: true,
            hasPermissionPdf: true,
            hasInvoicePdf: true,
            hasPermissionDelete: true,
            hasPermissionCreateInvoice: true,
            hasInvoiceCreate: true,
            hasInvoicing: true,
            hasPermissionSend: true,
            userCouriers: [],
            couriers: items.couriers,
            courierSettings: {
                default_courier: 0,
            },
        });

        // Nastavime callbacky
        if (typeof buttons.view !== 'undefined') {
            buttons.view.callback = (callbackLoading, callback) => this.showDrawer('order', {
                id: item.id,
                buttons,
                callbackLoading,
                callback,
            });

            if (
                !_.isEmpty(this.defaultFiltered)
                && _.has(this.defaultFiltered, 'search')
                && this.defaultFiltered.search.toString() === item.data.number.toString()
                && !this.callViewClick
            ) {
                // Vyhladavame podla cisla objednavky
                this.callViewClick = true;

                // Zobrazime view
                buttons.view.callback(() => {}, () => {});
            }
        }

        // Nastavime callbacky
        if (typeof buttons.pdfOrder !== 'undefined') {
            buttons.pdfOrder.callback = (callbackLoading, callback) => this.pdfOrder(item.id, callbackLoading, callback);
        }

        if (typeof buttons.sync !== 'undefined') {
            buttons.sync.callback = (callbackLoading, callback) => this.syncOrder(item.id, callbackLoading, callback);
        }

        if (typeof buttons.createInvoice !== 'undefined') {
            buttons.createInvoice.callback = (callbackLoading, callback) => this.createInvoice(item.id, callbackLoading, callback);
        }

        if (typeof buttons.pdf !== 'undefined') {
            buttons.pdf.callback = (callbackLoading, callback) => this.pdf(buttons.pdf.options.invoiceId, item.id, callbackLoading, callback);
        }

        if (typeof buttons.delete !== 'undefined') {
            buttons.delete.callback = (callbackLoading, callback) => this.confirmDelete(item.id, callbackLoading, callback);
        }

        if (typeof buttons.send !== 'undefined') {
            buttons.send.callback = buttons.send.options.packagePayment || buttons.send.options.packageImport
                ? () => {}
                : (buttons.send.options.packageSend
                        ? () => this.onClickPackageNumber(item.package.number)
                        : () => this.showDrawer('packages', { ids: item.id })
                );
        }

        let source = <div className="source" style={{ backgroundColor: '#5c70ff' }}>
            {__('MO')}
        </div>;

        let is_vo = false;

        _.each(item.data.products, product => {
            if (_.has(product.meta_data, 'owner_order_id') && toNumber(product.meta_data.owner_order_id) > 0) {
                is_vo = true;
            }
        });

        if (is_vo) {
            // Je vyplneny zdroj
            source = <div className="source" style={{ backgroundColor: '#459350' }}>
                {__('VO')}
            </div>;
        }

        let states = {};

        if (_.has(items.user_eshops, item.user_eshop_id)) {
            states = _.reduce(items.user_eshops[item.user_eshop_id].states, (result, { id, name }) => ({ ...result, [id]: name }), {});
        }

        const is_seller = user.mtokna_type === 'mtokna_seller';

        let removeButtons = ['sync', 'pdfLink', 'paid'];

        if (is_seller) {
            removeButtons = ['sync', 'pdfLink', 'createInvoice', 'paid', 'send', 'delete', 'pdfOrder', 'view'];
        }

        if (user.mtokna_moderator) {
            removeButtons = ['sync', 'pdfLink', 'createInvoice', 'paid', 'send', 'delete'];

            states = { [item.data.state.id]: states[item.data.state.id] };
        }

        return [
            <div>
                <div style={{ backgroundColor: item.data.state.color }} />
                <div>
                    <div>{item.data.number}</div>
                    {source}
                </div>
                <div>{formatAmount(item.data.amount, item.data.currency)}</div>
                <div>{formatDate(item.data.date)}</div>
            </div>,
            <div>
                <div>{_.truncate(item.data.client.name, { length: 32 })}</div>
                <div>{_.truncate(item.data.client.email, { length: 32 })}</div>
                <div>{_.has(items.user_eshops, item.user_eshop_id) ? items.user_eshops[item.user_eshop_id].name : '-'}</div>
            </div>,
            <div>
                <div>{item.data.client.phone}</div>
                <div>{_.truncate(`${item.data.client.address}, ${item.data.client.city}`, { length: 32 })}</div>
                <div>{_.truncate(`${item.data.client.delivery_address}, ${item.data.client.delivery_city}`, { length: 32 })}</div>
            </div>,
            <div>
                <div>{_.truncate(item.data.client.delivery_note, { length: 32 })}</div>
                <div>{_.truncate(item.data.delivery_type_name, { length: 32 })}</div>
                <div>{_.truncate(item.data.payment_type_name, { length: 32 })}</div>
            </div>,
            <div>
                {!this.isMobile() && !is_seller ? <div>
                    <Select
                        options={states}
                        value={item.data.state.id}
                        onChange={value => this.confirmState(item.id, value, '')}
                        allowEmpty={false}
                        variant="standard"
                    />
                </div> : null}
                <div>
                    {this.renderTableButton(
                        item.id,
                        __('Upraviť'),
                        <EditIcon />,
                        () => this.redirect(`/manual/${is_vo ? 'vo' : 'mo'}/${item.user_eshop_id}/${item.id}`),
                        {}
                    )}
                    {this.renderTableButtons(item.id, buttons, removeButtons, false)}
                </div>
            </div>,
        ];
    }

    /**
     * Event po kliku na cislo zasielky.
     *
     * @param {string} number
     */
    onClickPackageNumber(number) {
        // Nastavime cislo do clipboardu
        navigator.clipboard.writeText(number);

        this.showSnackbar('success', __('Skopirované do schránky'));
    }

    /**
     * Vratime options buniek.
     *
     * @return {Object}
     */
    getCellsOptions() {
        return {
            number: {
                label: __('Číslo'),
                get: item => item.data.number,
            },
            amount: {
                label: __('Suma'),
                get: item => formatAmount(item.data.amount, item.data.currency),
            },
            created: {
                label: __('Dátum'),
                get: item => formatDate(item.data.date),
            },
            client_name: {
                label: __('Zákaznik'),
                get: item => _.truncate(item.data.client.name, { length: 32 }),
            },
            client_delivery_name: {
                label: __('Zákaznik (dodanie)'),
                get: item => _.truncate(item.data.client.delivery_name, { length: 32 }),
            },
            client_contact_name: {
                label: __('Kontaktná osoba'),
                get: item => _.truncate(item.data.client.contact_name, { length: 32 }),
            },
            client_delivery_contact_name: {
                label: __('Kontaktná osoba (dodanie)'),
                get: item => _.truncate(item.data.client.delivery_contact_name, { length: 32 }),
            },
            client_company: {
                label: __('Firemné údaje'),
                get: item => !_.isEmpty(item.data.client.ico)
                    ? _.truncate(`${__('IČO')}: ${item.data.client.ico}, ${__('DIČ')}: ${item.data.client.dic}, ${__('IČ DPH')}: ${item.data.client.ic_dph}`, { length: 32 })
                    : '',
            },
            client_address: {
                label: __('Fakturačná adresa'),
                get: item => _.truncate(`${item.data.client.address}, ${item.data.client.city}`, { length: 32 }),
            },
            client_delivery_address: {
                label: __('Dodacia adresa'),
                get: item => _.truncate(`${item.data.client.delivery_address}, ${item.data.client.delivery_city}`, { length: 32 }),
            },
            client_phone: {
                label: __('Tel. číslo'),
                get: item => item.data.client.phone,
            },
            client_delivery_phone: {
                label: __('Tel. číslo (dodanie)'),
                get: item => item.data.client.delivery_phone,
            },
            client_email: {
                label: __('E-mail'),
                get: item => _.truncate(item.data.client.email, { length: 32 }),
            },
            client_note: {
                label: __('Poznámka'),
                get: item => _.truncate(item.data.client.delivery_note, { length: 32 }),
            },
            delivery_name: {
                label: __('Dodanie'),
                get: item => _.truncate(item.data.delivery_type_name, { length: 32 }),
            },
            payment_name: {
                label: __('Platba'),
                get: item => _.truncate(item.data.payment_type_name, { length: 32 }),
            },
            internal_comment: {
                label: __('Interná poznámka'),
                get: item => _.truncate(item.data.internal_comment, { length: 32 }),
            },
            package_number: {
                label: __('Číslo zásielky'),
                get: item => item.package.number.toString().indexOf('-') === -1 ? item.package.number : '',
            },
        };
    }

    /**
     * Vratime multiselect.
     *
     * @return {Array}
     */
    getMultiselect() {
        const { user, items } = this.props;

        if (user.mtokna_type !== 'mtokna_admin') {
            return [];
        }

        // Vytiahneme zoznam aktivnych addonov
        const addons = _.reduce(
            items.user_addons,
            (result, addon_id) => (_.has(items.addons, addon_id) ? { ...result, [addon_id]: items.addons[addon_id] } : result),
            {}
        );

        return [
            !_.isEmpty(addons) ? {
                confirm: (ids, callback, callbackLoading) => this.showLightbox('multiaddon', {
                    ids,
                    callback,
                    callbackLoading,
                    addonId: _.keys(addons)[0],
                }),
                icon: <AddonsIcon />,
                text: __('Doplnok'),
            } : null,
            {
                confirm: (ids, callback, callbackLoading) => this.showLightbox('multiorderpdf', {
                    ids,
                    callback,
                    callbackLoading,
                }),
                icon: <OrderPdfIcon />,
                text: __('PDF objednávok'),
                limit: 200,
                permission: 'orders-pdf',
            },
            !user.mtokna_moderator ? {
                confirm: (ids, callback) => this.showDrawer('packages', { ids, filtered: items.filtered, callback }),
                icon: <CouriersIcon />,
                text: __('Odoslať prepravcovi'),
                limit: 300,
                permission: 'orders-send',
            } : null,
        ];
    }

    /**
     * Vratime nastavenia buniek.
     *
     * @return {Object}
     */
    getCellsSettings() {
        return {};
    }

    /**
     * Vratime filter.
     *
     * @return {Object}
     */
    getFilter() {
        const { items } = this.props;

        return _.reduce(items.filter, (result, options, key) => {
            switch (key) {
                case 'user_eshop_id':
                    return { ...result, ...{ [key]: {
                        type: 'select',
                        name: __('Eshop'),
                        value: '',
                        options,
                        multiple: true,
                    }}};

                case 'invoices-status_pdf':
                    return { ...result, ...{ [key]: {
                                type: 'select',
                                name: __('PDF'),
                                value: '',
                                options: {
                                    'newly': __('Nové'),
                                    'download': __('Prečítané'),
                                },
                            }}};

                case 'state_id':
                    return { ...result, ...{ [key]: {
                                type: 'select',
                                name: __('Stav'),
                                value: '',
                                options,
                                multiple: true,
                            }}};

                case 'MtoknaManualFilters-partner':
                    return { ...result, ...{ [key]: {
                                type: 'select',
                                name: __('Partner'),
                                value: '',
                                options,
                            }}};

                case 'MtoknaManualFilters-email':
                    return { ...result, ...{ [key]: {
                                type: 'select',
                                name: __('Partner email'),
                                value: '',
                                autocomplete: true,
                                options,
                            }}};

                case 'MtoknaManualFilters-name':
                    return { ...result, ...{ [key]: {
                                type: 'select',
                                name: __('Partner názov'),
                                value: '',
                                autocomplete: true,
                                options,
                            }}};

                default:
                    return result;
            }
        }, {});
    }

    /**
     * Vratime empty text.
     *
     * @return {string}
     */
    getEmptyText() {
        return __('Zatiaľ nemáte žiadne objednávky');
    }

    /**
     * Zobrazime lightbox na zmazanie.
     *
     * @param {number} id
     * @param {function} callbackLoading
     * @param {function} callback
     */
    confirmDelete(id, callbackLoading, callback) {
        this.showLightbox('delete', { id, callbackLoading, callback });
    }

    /**
     * Vytvorenie faktury.
     *
     * @param {number} id
     * @param {function} callbackLoading
     * @param {function} callback
     */
    async createInvoice(id, callbackLoading, callback) {
        const { createMtoknaManualInvoice } = this.props;

        // Zavolame loading
        callbackLoading();

        // Vytvorime fakturu
        await createMtoknaManualInvoice(this, id);

        // Zavolame callback
        callback();
    }

    /**
     * Zavolanie addonu cez multiselect.
     */
    async multiAddon() {
        const { lightbox } = this.state;
        const { addonMtoknaManualOrders, items } = this.props;

        // Zavrieme lightbox
        this.closeLightbox('multiaddon');

        // Zobrazime loading
        lightbox.multiaddon.callbackLoading();

        // Zavolame addon
        await addonMtoknaManualOrders(this, lightbox.multiaddon.ids, lightbox.multiaddon.addonId, items.filtered);

        // Zavolame callback
        lightbox.multiaddon.callback();
    }

    /**
     * Stiahnutie pdf objednavok cez multiselect.
     */
    async multiOrderPdf() {
        const { lightbox } = this.state;
        const { items, pdfMtoknaManualOrders } = this.props;

        // Zavrieme lightbox
        this.closeLightbox('multiorderpdf');

        // Zobrazime loading
        lightbox.multiorderpdf.callbackLoading();

        await pdfMtoknaManualOrders(this, lightbox.multiorderpdf.ids, items.filtered);

        // Zavolame callback
        lightbox.multiorderpdf.callback();
    }

    /**
     * Zmazanie objednavky.
     */
    async delete() {
        const { deleteMtoknaManualOrder, items } = this.props;
        const { lightbox } = this.state;

        // Zavolame loading
        lightbox.delete.callbackLoading();

        // Zavrieme lightbox
        this.closeLightbox('delete');

        // Zmazeme objednavku
        await deleteMtoknaManualOrder(this, lightbox.delete.id);

        // Zavolame callback
        lightbox.delete.callback();

        // Znovu nacitame zoznam od prvej stranky
        this.fetchList(1, 0, items.filtered);
    }

    /**
     * Event po zmene stavu objednavky.
     */
    async changeState() {
        const { lightbox } = this.state;
        const { changeMtoknaManualOrderState } = this.props;

        // Zavrieme lightbox
        this.closeLightbox('state');

        // Zobrazime loading
        this.loadingTableButton(this.getLoadingTableButtonId(lightbox.state.orderId, lightbox.state.viewText));

        // Zmenime stav
        await changeMtoknaManualOrderState(this, lightbox.state.orderId, lightbox.state.stateId);

        // Zrusime loading table button
        this.clearTableButton();
    }

    async pdf(id, orderId, callbackLoading, callback) {
        const { user } = this.props;

        // Zavolame loading
        callbackLoading();

        await request(`/mtokna/${user.mtokna_type === 'mtokna_admin' ? 'manualOrderInvoicePdf' : 'invoicePdf'}/${id}`).then(response => {
            const { status, data } = response.data;

            if (status === 'error') {
                this.showSnackbar('error', __('Nepodarilo sa stiahnuť pdf'));
                return;
            }

            if (data.link.indexOf('inline') !== -1) {
                // Chceme inline
                window.open(data.link, '_blank');
                return;
            }

            // Stiahneme subor
            window.location = data.link;
        });

        // Zavolame callback
        callback();
    }

    /**
     * Event po zmene addonu v lightboxe.
     *
     * @param {string} value
     */
    onChangeMultiAddon(value) {
        const { lightbox } = this.state;

        this.setState({ lightbox: { ...lightbox, multiaddon: { ...lightbox.multiaddon, addonId: value } } });
    }

    /**
     * Zobrazime lightbox na zmenu stavu.
     *
     * @param {number} orderId
     * @param {string} stateId
     * @param {string} viewText
     */
    confirmState(orderId, stateId, viewText) {
        this.showLightbox('state', { orderId, stateId, viewText });
    }

    /**
     * Stiahnutie order pdf.
     *
     * @param {number} id
     * @param {function} callbackLoading
     * @param {function} callback
     */
    async pdfOrder(id, callbackLoading, callback) {
        const { pdfMtoknaManualOrder } = this.props;

        // Zavolame loading
        callbackLoading();

        // Zavolame callback
        await pdfMtoknaManualOrder(this, id);

        // Zavolame callback
        callback();
    }

    /**
     * Rendrujeme screen.
     *
     * @return {JSX.Element|null}
     */
    renderScreen() {
        const { lightbox } = this.state;
        const { items, user, changeMtoknaManualOrderData, sendMtoknaManualOrders } = this.props;

        // Vytiahneme zoznam aktivnych addonov
        let addons = {};

        if (_.has(items, 'user_addons')) {
            addons = _.reduce(
                items.user_addons,
                (result, addon_id) => (_.has(items.addons, addon_id) ? { ...result, [addon_id]: items.addons[addon_id] } : result),
                {}
            );
        }

        return (
            <div>
                {this.renderLightbox(
                    'state',
                    __('Naozaj chcete zmeniť stav tejto objednávke?'),
                    null,
                    __('Áno'),
                    __('Nie'),
                    () => this.changeState()
                )}
                {this.renderLightbox(
                    'create',
                    __('Nová objednávka'),
                    !_.isEmpty(lightbox.create) ? <div>
                        <Select
                            label={__('Eshop')}
                            value={lightbox.create.user_eshop_id}
                            options={lightbox.create.user_eshops}
                            onChange={value => this.onChangeCreateUserEshopId(value)}
                            allowEmpty={false}
                        />
                    </div> : null,
                    __('Uložiť'),
                    __('Zrušiť'),
                    () => this.saveCreate()
                )}
                {this.renderLightbox(
                    'delete',
                    __('Naozaj chcete zmazať túto objednávku?'),
                    null,
                    __('Áno'),
                    __('Nie'),
                    () => this.delete()
                )}
                {this.renderLightbox(
                    'multiorderpdf',
                    __('Stiahnutie objednávok do pdf'),
                    !_.isEmpty(lightbox.multiorderpdf) ? <div>
                        <Checkbox
                            label={__('Zlúčiť do jedného pdf súboru')}
                            value={user.settings.merge_multi_order_pdf}
                            onChange={checked => changeUserSetting('merge_multi_order_pdf', checked)}
                        />
                    </div> : null,
                    __('Áno'),
                    __('Nie'),
                    () => this.multiOrderPdf()
                )}
                {this.renderLightbox(
                    'multiaddon',
                    __('Doplnok'),
                    !_.isEmpty(lightbox.multiaddon) ? <div>
                        <Select
                            options={addons}
                            value={lightbox.multiaddon.addonId}
                            onChange={value => this.onChangeMultiAddon(value)}
                            allowEmpty={false}
                        />
                    </div> : null,
                    __('Odoslať'),
                    __('Zrušiť'),
                    () => this.multiAddon()
                )}
                {this.renderDrawer('order', {
                    title: __('Objednávka'),
                    content: props => <MtoknaOrder
                        user={user}
                        showSnackbar={(type, message) => this.showSnackbar(type, message)}
                        changeMtoknaOrderData={changeMtoknaManualOrderData}
                        settings={items.settings}
                        manual={true}
                        {...props}
                    />,
                })}
                {this.renderDrawer('packages', {
                    title: __('Odoslanie objednávok'),
                    content: props => <Packages
                        user={user}
                        showSnackbar={(type, message) => this.showSnackbar(type, message)}
                        sendOrders={sendMtoknaManualOrders}
                        mtokna={true}
                        mtoknaManual={true}
                        {...props}
                    />,
                })}
                {this.renderOffer()}
            </div>
        );
    }
}

const stateToProps = ({ mtokna_manual_orders, user }) => ({ items: mtokna_manual_orders, user });

export default withCookies(connect(stateToProps, {
    fetch: fetchMtoknaManualOrders,
    clean: cleanMtoknaManualOrders,
    setUser,
    changeUserSetting,
    pdfMtoknaManualOrder,
    changeMtoknaManualOrderState,
    changeMtoknaManualOrderData,
    deleteMtoknaManualOrder,
    createMtoknaManualInvoice,
    pdfMtoknaManualOrders,
    addonMtoknaManualOrders,
    sendMtoknaManualOrders,
})(MtoknaManualOrdersScreen));
