import React from 'react';
import { connect } from 'react-redux';
import { withCookies } from 'react-cookie';
import _ from 'lodash';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/AddCircle';
import { ListScreen } from '../components';
import {
    fetchComplaints,
    cleanComplaints,
    setUser,
    changeUserSetting,
    deleteComplaint,
    deleteComplaints
} from '../actions';
import { __, formatDate, getComplaintButtons, toNumber } from '../functions';
import { COMPLAINT_STATUSES, COMPLAINT_TYPES } from '../config';
import '../assets/styles/complaints.css';

/**
 * Reklamacie.
 */
class ComplaintsScreen extends ListScreen {
    /**
     * Title.
     *
     * @type {string}
     */
    title = __('Reklamácie');

    /**
     * Default state.
     *
     * @type {Object}
     */
    state = { ...ListScreen.state, ...{
        lightbox: {
            delete: false,
            multidelete: false,
        },
    }};

    /**
     * Memory cache.
     *
     * @type {Object}
     */
    memoryCache = {};

    /**
     * Je validne pravo?
     *
     * @return {boolean}
     */
    isValidPermission() {
        return this.hasPermission('complaints');
    }

    /**
     * Vratime nazov tabulky.
     *
     * @return {string}
     */
    getTableName() {
        return 'complaints';
    }

    /**
     * Vratime zoznam tagov.
     *
     * @return {Array}
     */
    getTags() {
        const { user } = this.props;

        return [
            this.hasPermission('complaints-edit') ? {
                name: __('Nová reklamácia'),
                color: 'secondary',
                icon: <AddIcon />,
                onClick: () => this.redirect('/complaint'),
                disabled: toNumber(user.user_eshop_id) === 0,
            } : null,
        ];
    }

    /**
     * Vratime bunky.
     *
     * @param {Object} item
     *
     * @return {Array}
     */
    getCells(item) {
        if (!_.has(this.memoryCache, 'hasPermissionEdit')) {
            // Nemame v memory cache
            this.memoryCache.hasPermissionEdit = this.hasPermission('complaints-edit');
        }

        if (!_.has(this.memoryCache, 'hasPermissionDelete')) {
            // Nemame v memory cache
            this.memoryCache.hasPermissionDelete = this.hasPermission('complaints-delete');
        }

        // Vytiahneme tlacitka pre reklamaciu
        let buttons = getComplaintButtons(item, {
            hasPermissionEdit: this.memoryCache.hasPermissionEdit,
            hasPermissionDelete: this.memoryCache.hasPermissionDelete,
        });

        // Nastavime callbacky
        if (_.has(buttons, 'edit')) {
            buttons.edit.callback = (callbackLoading, callback) => this.redirect(`/complaint/${item.id}`);
        }

        if (_.has(buttons, 'delete')) {
            buttons.delete.callback = (callbackLoading, callback) => this.confirmDelete(item.id, callbackLoading, callback);
        }

        // Vytiahneme nastavenia buniek
        const cellsSettings = this.getCellsSettings();

        // Vytiahneme options buniek
        const cellsOptions = this.getCellsOptions();

        return [
            <div>
                {this.getCellValue(cellsOptions[cellsSettings.settings[0][0]].get(item))}
                {this.getCellValue(cellsOptions[cellsSettings.settings[0][1]].get(item))}
                {this.getCellValue(cellsOptions[cellsSettings.settings[0][2]].get(item))}
            </div>,
            <div>
                {this.getCellValue(cellsOptions[cellsSettings.settings[1][0]].get(item))}
                {this.getCellValue(cellsOptions[cellsSettings.settings[1][1]].get(item))}
                {this.getCellValue(cellsOptions[cellsSettings.settings[1][2]].get(item))}
            </div>,
            <div>
                {this.getCellValue(cellsOptions[cellsSettings.settings[2][0]].get(item))}
                {this.getCellValue(cellsOptions[cellsSettings.settings[2][1]].get(item))}
                {this.getCellValue(cellsOptions[cellsSettings.settings[2][2]].get(item))}
            </div>,
            <div>{this.renderTableButtons(item.id, buttons)}</div>,
        ];
    }

    /**
     * Vratime options buniek.
     *
     * @return {Object}
     */
    getCellsOptions() {
        const { items } = this.props;

        return {
            number: {
                label: __('Číslo'),
                get: item => item.data.number,
            },
            order_number: {
                label: __('Číslo objednávky'),
                get: item => item._matchingData.Orders.data.number,
            },
            buy_date: {
                label: __('Dátum nákupu'),
                get: item => formatDate(item._matchingData.Orders.data.date, 'dd.mm.yyyy'),
            },
            date: {
                label: __('Dátum podania'),
                get: item => formatDate(item.data.date, 'dd.mm.yyyy'),
            },
            status: {
                label: __('Stav'),
                get: item => <span className={`color-${item.status}`}>{COMPLAINT_STATUSES[item.status]}</span>,
            },
            name: {
                label: __('Predmet'),
                get: item => _.truncate(item.data.name, { length: 48 }),
            },
            text: {
                label: __('Popis'),
                get: item => _.truncate(item.data.text, { length: 48 }),
            },
            type: {
                label: __('Žiadosť zákaznika na vybavenie'),
                get: item => COMPLAINT_TYPES[item.data.type],
            },
            user_id: {
                label: __('Osoba zodpovedná za vybavenie'),
                get: item => _.has(items.users, item.user_id) ? items.users[item.user_id] : item.user_id,
            },
            end_date: {
                label: __('Dátum vybavenia'),
                get: item => item.data.end_date !== '' ? formatDate(item.data.end_date, 'dd.mm.yyyy') : '',
            },
            end_text: {
                label: __('Spôsob vybavenia'),
                get: item => item.data.end_text,
            },
            end_comment: {
                label: __('Vyjadrenie zákaznika k vybaveniu'),
                get: item => item.data.end_comment,
            },
        };
    }

    /**
     * Vratime multiselect.
     *
     * @return {Array}
     */
    getMultiselect() {
        return [
            {
                confirm: (ids, callback, callbackLoading) => this.showLightbox('multidelete', {
                    ids,
                    callback,
                    callbackLoading,
                }),
                icon: <DeleteIcon />,
                text: __('Zmazať'),
                permission: 'complaints-delete',
            }
        ];
    }

    /**
     * Vratime filter.
     *
     * @return {Object}
     */
    getFilter() {
        const { items } = this.props;

        return _.reduce(items.filter, (result, options, key) => {
            switch (key) {
                case 'status':
                    return { ...result, ...{ [key]: {
                        type: 'select',
                        name: __('Stav'),
                        options: COMPLAINT_STATUSES,
                        value: '',
                    }}};

                case 'search':
                    return { ...result, ...{ [key]: {
                        type: 'input',
                        name: __('Hľadať'),
                        value: '',
                    }}};

                default:
                    return result;
            }
        }, {});
    }

    /**
     * Vratime empty text.
     *
     * @return {string}
     */
    getEmptyText() {
        return __('Zatiaľ nemáte žiadnu reklamáciu');
    }

    /**
     * Zobrazime lightbox na zmazanie.
     *
     * @param {number} id
     * @param {function} callbackLoading
     * @param {function} callback
     */
    confirmDelete(id, callbackLoading, callback) {
        this.showLightbox('delete', { id, callbackLoading, callback });
    }

    /**
     * Zmazanie reklamacie.
     */
    async delete() {
        const { deleteComplaint, items } = this.props;
        const { lightbox } = this.state;

        // Zavolame loading
        lightbox.delete.callbackLoading();

        // Zavrieme lightbox
        this.closeLightbox('delete');

        // Zmazeme klienta
        await deleteComplaint(this, lightbox.delete.id);

        // Zavolame callback
        lightbox.delete.callback();

        // Znovu nacitame zoznam od prvej stranky
        this.fetchList(1, 0, items.filtered);
    }

    /**
     * Zmazanie reklamacii cez multiselect.
     */
    async multiDelete() {
        const { deleteComplaints, items } = this.props;
        const { lightbox } = this.state;

        // Zavrieme lightbox
        this.closeLightbox('multidelete');

        // Zobrazime loading
        lightbox.multidelete.callbackLoading();

        // Zmazeme klientov
        await deleteComplaints(this, lightbox.multidelete.ids, items.filtered);

        // Zavolame callback
        lightbox.multidelete.callback();

        // Znovu nacitame zoznam od prvej stranky
        this.fetchList(1, 0, items.filtered);
    }

    /**
     * Rendrujeme screen.
     *
     * @return {JSX.Element|null}
     */
    renderScreen() {
        const { lightbox } = this.state;

        return (
            <div>
                {this.renderLightbox(
                    'delete',
                    __('Naozaj chcete zmazať túto reklamáciu?'),
                    null,
                    __('Áno'),
                    __('Nie'),
                    () => this.delete()
                )}
                {this.renderLightbox(
                    'multidelete',
                    __('Naozaj chcete zmazať označené reklamácie?'),
                    null,
                    __('Áno'),
                    __('Nie'),
                    () => this.multiDelete()
                )}
            </div>
        );
    }
}

const stateToProps = ({ complaints, user }) => ({ items: complaints, user });

export default withCookies(connect(stateToProps, {
    fetch: fetchComplaints,
    clean: cleanComplaints,
    setUser,
    changeUserSetting,
    deleteComplaint,
    deleteComplaints,
})(ComplaintsScreen));
