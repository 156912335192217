import React from 'react';
import { connect } from 'react-redux';
import { withCookies } from 'react-cookie';
import _ from 'lodash';
import { ButtonBase, Tooltip } from '@mui/material';
import ActivatedIcon from '@mui/icons-material/CheckCircle';
import AddCircle from '@mui/icons-material/AddCircleOutline';
import { Navigate, Gateway, Bank, Storage, Accounting, Eshop, Courier, Marketplace } from '../components';
import { __, toNumber } from '../functions';
import {
    createAddon,
    deleteAddon,
    createGateway,
    deleteGateway,
    createBank,
    deleteBank,
    createStorage,
    deleteStorage,
    createAccounting,
    deleteAccounting,
    createEshop,
    createCourier,
    deleteCourier,
    createMarketplace,
    deleteMarketplace,
    changeUserSetting,
} from '../actions';
import {
    IMAGES,
    COURIERS,
    COURIER_COLORS,
    BANKS,
    BANK_COLORS,
    GATEWAYS,
    GATEWAYS_COLORS,
    ADDONS,
    ADDON_COLORS,
    ESHOPS,
    ESHOP_COLORS,
    ACCOUNTINGS,
    ACCOUNTING_COLORS,
    STORAGES,
    STORAGE_COLORS,
    MARKETPLACES,
    MARKETPLACES_COLORS,
} from '../config';
import '../assets/styles/apps.css';

/**
 * Apps komponenta.
 */
class Apps extends Navigate {
    /**
     * Default props.
     *
     * @type {Object}
     */
    static defaultProps = {
        type: '',
    };

    /**
     * Default state.
     *
     * @type {Object}
     */
    state = {
        loadingAddon: false,
    };

    /**
     * Pridame novy addon.
     *
     * @param {number} id
     */
    addAddon(id) {
        const { createAddon } = this.props;

        this.setState({ loadingAddon: id });

        // Vytvorime addon
        createAddon(this, id);
    }

    /**
     * Zmazeme addon.
     *
     * @param {number} id
     * @param {number} addonId
     */
    deleteAddon(id, addonId) {
        const { deleteAddon } = this.props;

        this.setState({ loadingAddon: addonId });

        // Zmazeme addon
        deleteAddon(this, id);
    }

    /**
     * Rendrujeme eshopy.
     *
     * @return {JSX.Element}
     */
    renderEshops() {
        const { eshops, eshopId, createEshop } = this.props;

        return (
            <div className="apps">
                {_.map(eshops, ({ name }, id) => {
                    // Je aktivny?
                    const activated = toNumber(id) === toNumber(eshopId);

                    if (name === 'Blank' && !activated) {
                        // Neaktivny blank nezobrazujeme
                        return null;
                    }

                    // Povolujeme
                    const allow = activated || toNumber(eshopId) === 0;

                    return (
                        <Tooltip title={this.isDemo() ? `${ESHOPS[name]} - ${__('nastavenie nie je povolené na DEMO účte')}` : ESHOPS[name]} key={id}>
                            <ButtonBase
                                onClick={allow && !this.isDemo()
                                    ? () => this.showDrawer('eshop', { id: toNumber(id), title: ESHOPS[name] })
                                    : () => {}}
                                className="apps__app"
                                style={{ backgroundColor: ESHOP_COLORS[name].background }}
                            >
                                <img src={IMAGES[`${name.toLowerCase()}.png`]} alt={name} />
                                {activated
                                    ? <ActivatedIcon className="apps__app__icon" color="secondary" />
                                    : null
                                }
                            </ButtonBase>
                        </Tooltip>
                    );
                })}
                {this.renderDrawer('eshop', {
                    content: props => <Eshop
                        showSnackbar={(type, message) => this.showSnackbar(type, message)}
                        createEshop={createEshop}
                        refreshData={_.has(this.props, 'refreshData') ? this.props.refreshData : () => {}}
                        {...props}
                    />,
                })}
                {this.renderSnackbar()}
            </div>
        );
    }

    /**
     * Rendrujeme fakturacne systemy.
     *
     * @return {JSX.Element}
     */
    renderAccountings() {
        const { accountings, accountingId, createAccounting, deleteAccounting, user } = this.props;

        // Propagacne systemy
        let propagation = { Money: { name: 'Money' }, MoneyS4: { name: 'MoneyS4' } };

        if (window.location.pathname === '/seller-accounting') {
            propagation = {};
        }

        const items = { ...accountings, ...propagation };

        return (
            <div className="apps">
                {_.map(items, ({ name }, id) => {
                    const rawId = id;

                    id = toNumber(id);

                    // Je aktivny?
                    const activated = id > 0 && id === toNumber(accountingId);

                    if (name === 'Omega' || (name === 'Oberon' && !activated)) {
                        // Omega
                        // Neaktivny oberon nezobrazujeme
                        return null;
                    }

                    return (
                        <Tooltip title={this.isDemo() ? `${ACCOUNTINGS[name]} - ${__('nastavenie nie je povolené na DEMO účte')}` : ACCOUNTINGS[name]} key={rawId}>
                            <ButtonBase
                                onClick={!this.isDemo()
                                    ? () => this.showDrawer('accounting', { id, name, title: ACCOUNTINGS[name] })
                                    : () => {}}
                                className="apps__app"
                                style={{ backgroundColor: ACCOUNTING_COLORS[name].background }}
                            >
                                <img src={IMAGES[`${name.toLowerCase()}.png`]} alt={name} />
                                {activated
                                    ? <ActivatedIcon className="apps__app__icon" color="secondary" />
                                    : null
                                }
                            </ButtonBase>
                        </Tooltip>
                    );
                })}
                {this.renderDrawer('accounting', {
                    content: props => <Accounting
                        showSnackbar={(type, message) => this.showSnackbar(type, message)}
                        createAccounting={createAccounting}
                        deleteAccounting={deleteAccounting}
                        user={user}
                        hasAccountingAction={(type, accountingId) => this.hasAccountingAction(type, accountingId)}
                        refreshData={_.has(this.props, 'refreshData') ? this.props.refreshData : () => {}}
                        {...props}
                    />,
                })}
                {this.renderSnackbar()}
            </div>
        );
    }

    /**
     * Rendrujeme sklady.
     *
     * @return {JSX.Element}
     */
    renderStorages() {
        const { storages, storageId, createStorage, deleteStorage, user } = this.props;

        return (
            <div className="apps">
                {_.map(storages, ({ name }, id) => {
                    // Je aktivny?
                    const activated = toNumber(id) === toNumber(storageId);

                    if (((name === 'Ikelp' || name === 'Mrp') && !activated)) {
                        // Neaktivny mrp, ikelp nezobrazujeme
                        return null;
                    }

                    return (
                        <Tooltip title={this.isDemo() ? `${STORAGES[name]} - ${__('nastavenie nie je povolené na DEMO účte')}` : STORAGES[name]} key={id}>
                            <ButtonBase
                                onClick={!this.isDemo()
                                    ? () => this.showDrawer('storage', { id: toNumber(id), title: STORAGES[name] })
                                    : () => {}}
                                className="apps__app"
                                style={{ backgroundColor: STORAGE_COLORS[name].background }}
                            >
                                <img src={IMAGES[`${name.toLowerCase()}.png`]} alt={name} />
                                {activated
                                    ? <ActivatedIcon className="apps__app__icon" color="secondary" />
                                    : null
                                }
                            </ButtonBase>
                        </Tooltip>
                    );
                })}
                {this.renderDrawer('storage', {
                    content: props => <Storage
                        showSnackbar={(type, message) => this.showSnackbar(type, message)}
                        createStorage={createStorage}
                        deleteStorage={deleteStorage}
                        user={user}
                        refreshData={_.has(this.props, 'refreshData') ? this.props.refreshData : () => {}}
                        {...props}
                    />,
                })}
                {this.renderSnackbar()}
            </div>
        );
    }

    /**
     * Rendrujeme kurierov.
     *
     * @return {JSX.Element}
     */
    renderCouriers() {
        const { couriers, user_couriers, createCourier, deleteCourier, user, changeUserSetting } = this.props;

        return (
            <div className="apps">
                {_.map(couriers, (name, id) => {
                    // Je aktivny?
                    const activated = _.includes(_.values(user_couriers), toNumber(id));

                    return (
                        <Tooltip title={this.isDemo() ? `${COURIERS[name]} - ${__('nastavenie nie je povolené na DEMO účte')}` : COURIERS[name]} key={id}>
                            <ButtonBase
                                onClick={!this.isDemo()
                                    ? () => this.showDrawer('courier', { type: name, title: COURIERS[name] })
                                    : () => {}}
                                className="apps__app"
                                style={{ backgroundColor: COURIER_COLORS[name].background }}
                            >
                                <img src={IMAGES[`${name.toLowerCase()}.png`]} alt={name} />
                                {activated
                                    ? <ActivatedIcon className="apps__app__icon" color="secondary" />
                                    : null
                                }
                            </ButtonBase>
                        </Tooltip>
                    );
                })}
                {this.renderDrawer('courier', {
                    content: props => <Courier
                        showSnackbar={(type, message) => this.showSnackbar(type, message)}
                        createCourier={createCourier}
                        deleteCourier={deleteCourier}
                        changeUserSetting={changeUserSetting}
                        user={user}
                        {...props}
                    />,
                })}
                {this.renderSnackbar()}
            </div>
        );
    }

    /**
     * Rendrujeme banky.
     *
     * @return {JSX.Element}
     */
    renderBanks() {
        const { banks, user_banks, gateways, user_gateways, createGateway, deleteGateway, createBank, deleteBank, email, user } = this.props;

        return (
            <div className="apps">
                {_.map(banks, (name, id) => {
                    // Je aktivny?
                    const activated = _.includes(_.map(_.keys(user_banks), id => toNumber(id)), toNumber(id));

                    return (
                        <Tooltip title={BANKS[name]} key={id}>
                            <ButtonBase
                                onClick={() => this.showDrawer('bank', {
                                    id,
                                    hash: activated ? user_banks[id] : '',
                                    title: BANKS[name],
                                    activated,
                                    generateEmail: hash => email.replace('{name}', hash),
                                })}
                                className="apps__app"
                                style={{ backgroundColor: BANK_COLORS[name].background }}
                            >
                                <img src={IMAGES[`${name.toLowerCase()}.png`]} alt={name} />
                                {activated
                                    ? <ActivatedIcon className="apps__app__icon" color="secondary" />
                                    : null
                                }
                            </ButtonBase>
                        </Tooltip>
                    );
                })}
                {_.map(gateways, (name, id) => {
                    // Je aktivny?
                    const activated = _.includes(_.values(user_gateways), toNumber(id));

                    return (
                        <Tooltip title={this.isDemo() ? `${GATEWAYS[name]} - ${__('nastavenie nie je povolené na DEMO účte')}` : GATEWAYS[name]} key={id}>
                            <ButtonBase
                                onClick={!this.isDemo()
                                    ? () => this.showDrawer('gateway', { type: name, title: GATEWAYS[name] })
                                    : () => {}}
                                className="apps__app"
                                style={{ backgroundColor: GATEWAYS_COLORS[name].background }}
                            >
                                <img src={IMAGES[`${name.toLowerCase()}.png`]} alt={name} />
                                {activated
                                    ? <ActivatedIcon className="apps__app__icon" color="secondary" />
                                    : null
                                }
                            </ButtonBase>
                        </Tooltip>
                    );
                })}
                {this.renderDrawer('bank', {
                    content: props => <Bank
                        showSnackbar={(type, message) => this.showSnackbar(type, message)}
                        createBank={createBank}
                        deleteBank={deleteBank}
                        user={user}
                        {...props}
                    />,
                })}
                {this.renderDrawer('gateway', {
                    content: props => <Gateway
                        showSnackbar={(type, message) => this.showSnackbar(type, message)}
                        createGateway={createGateway}
                        deleteGateway={deleteGateway}
                        user={user}
                        {...props}
                    />,
                })}
                {this.renderSnackbar()}
            </div>
        );
    }

    /**
     * Rendrujeme addony.
     *
     * @return {JSX.Element}
     */
    renderAddons() {
        const { addons, user_addons, user } = this.props;
        const { loadingAddon } = this.state;

        const userEshopId = toNumber(user.user_eshop_id);

        return (
            <div className="apps">
                {_.map(addons, (name, id) => {
                    id = toNumber(id);

                    let itemId = 0;

                    _.each(user_addons, (addonId, key) => {
                        if (toNumber(addonId) === id) {
                            // Nasli sme addon
                            itemId = key;
                        }
                    });

                    // Je aktivny?
                    const activated = itemId > 0;

                    // Nacitava
                    const loaded = id === loadingAddon;

                    return (
                        <Tooltip title={`${ADDONS[name]} - ${activated
                            ? __('kliknutím deaktivujete')
                            : __('kliknutím aktivujete')
                        }`} key={id}>
                            <ButtonBase
                                onClick={userEshopId > 0
                                    ? (activated ? () => this.deleteAddon(itemId, id) : () => this.addAddon(id))
                                    : () => {}}
                                className="apps__app"
                                style={{ backgroundColor: ADDON_COLORS[name].background }}
                            >
                                {loaded
                                    ? this.renderLoading(30)
                                    : <img src={IMAGES[`${name.toLowerCase()}.png`]} alt={name} />}
                                {activated
                                    ? <ActivatedIcon className="apps__app__icon" color="secondary" />
                                    : null
                                }
                            </ButtonBase>
                        </Tooltip>
                    );
                })}
            </div>
        );
    }

    /**
     * Rendrujeme trhoviska.
     *
     * @return {JSX.Element}
     */
    renderMarketplaces() {
        const { marketplaces, user_marketplaces, createMarketplace, deleteMarketplace, user } = this.props;

        return (
            <div className="apps">
                {_.map(marketplaces, (name, id) => {
                    // Je aktivny?
                    const activated = _.includes(_.values(user_marketplaces), toNumber(id));

                    return (
                        <Tooltip title={this.isDemo() ? `${MARKETPLACES[name]} - ${__('nastavenie nie je povolené na DEMO účte')}` : MARKETPLACES[name]} key={id}>
                            <ButtonBase
                                onClick={!this.isDemo()
                                    ? () => this.showDrawer('marketplace', { type: name, title: MARKETPLACES[name] })
                                    : () => {}}
                                className="apps__app"
                                style={{ backgroundColor: MARKETPLACES_COLORS[name].background }}
                            >
                                <img src={IMAGES[`${name.toLowerCase()}.png`]} alt={name} />
                                {activated
                                    ? <ActivatedIcon className="apps__app__icon" color="secondary" />
                                    : null
                                }
                            </ButtonBase>
                        </Tooltip>
                    );
                })}
                {this.renderDrawer('marketplace', {
                    content: props => <Marketplace
                        showSnackbar={(type, message) => this.showSnackbar(type, message)}
                        createMarketplace={createMarketplace}
                        deleteMarketplace={deleteMarketplace}
                        user={user}
                        {...props}
                    />,
                })}
                {this.renderSnackbar()}
            </div>
        );
    }

    /**
     * Rendrujeme eshopy.
     *
     * @return {JSX.Element}
     */
    renderMtokna() {
        const { user_eshops, eshops, createCallback } = this.props;

        return (
            <div className="apps">
                {_.map(user_eshops, ({ id, eshop_id, name }) => {
                    if (!_.has(eshops, eshop_id)) {
                        return null;
                    }

                    const eshop_name = eshops[eshop_id].name;
                    const allow = true;

                    return (
                        <Tooltip title={name} key={id}>
                            <ButtonBase
                                onClick={allow
                                    ? () => this.showDrawer('eshop', { id: toNumber(eshop_id), title: name, user_eshop_id: id })
                                    : () => {}}
                                className="apps__app"
                                style={{ backgroundColor: ESHOP_COLORS[eshop_name].background }}
                            >
                                <img src={IMAGES[`${eshop_name.toLowerCase()}.png`]} alt={eshop_name} />
                            </ButtonBase>
                        </Tooltip>
                    );
                })}
                {!_.isEmpty(eshops) ? <Tooltip title={__('Nový eshop')}>
                    <ButtonBase
                        onClick={() => createCallback((eshop_id, name) => this.showDrawer('eshop', { id: toNumber(eshop_id), title: name, create: true }))}
                        className="apps__app"
                        style={{ backgroundColor: '#c8c4c4' }}
                    >
                        <AddCircle className="apps__app__add" color="secondary" />
                    </ButtonBase>
                </Tooltip> : null}
                {this.renderDrawer('eshop', {
                    content: props => <Eshop
                        showSnackbar={(type, message) => this.showSnackbar(type, message)}
                        createEshop={() => {}}
                        mtokna={true}
                        {...props}
                    />,
                })}
                {this.renderSnackbar()}
            </div>
        );
    }

    /**
     * Rendrovanie.
     *
     * @return {JSX.Element}
     */
    render() {
        const { type } = this.props;

        switch (type) {
            case 'eshops':
                return this.renderEshops();

            case 'accountings':
                return this.renderAccountings();

            case 'storages':
                return this.renderStorages();

            case 'couriers':
                return this.renderCouriers();

            case 'banks':
                return this.renderBanks();

            case 'addons':
                return this.renderAddons();

            case 'marketplaces':
                return this.renderMarketplaces();

            case 'mtokna':
                return this.renderMtokna();

            default:
                return null;
        }
    }
}

const stateToProps = ({ user }) => ({ user });

export default withCookies(connect(stateToProps, {
    createAddon,
    deleteAddon,
    createGateway,
    deleteGateway,
    createBank,
    deleteBank,
    createStorage,
    deleteStorage,
    createAccounting,
    deleteAccounting,
    createEshop,
    createCourier,
    deleteCourier,
    createMarketplace,
    deleteMarketplace,
    changeUserSetting,
})(Apps));
