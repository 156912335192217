import _ from 'lodash';
import { FETCH_MTOKNA_TEMPLATES, CLEAN_MTOKNA_TEMPLATES } from './types';
import { request, __ } from '../functions';

/**
 * Nacitanie sablon.
 *
 * @param {Screen} screen
 * @param {object} options
 */
export const fetchMtoknaTemplates = (screen, options = {}) => {
    const defaults = {
        page: 1,
        per_page: 0,
        filtered: {},
    };

    options = { ...defaults, ...options };

    let params = { page: options.page, per_page: options.per_page };

    // Nasetujeme filter
    _.forEach(options.filtered, (value, key) => {
        params = { ...params, ...{ [`filter_${key}`]: value } };
    });

    return dispatch => request('/mtokna/templates', params).then(response => {
        const { data, sync } = response.data;
        const { setUser } = screen.props;

        // Nasetujeme user data
        setUser(sync.user);

        dispatch({ type: FETCH_MTOKNA_TEMPLATES, payload: data });
    });
};

/**
 * Vycistenie sablon.
 */
export const cleanMtoknaTemplates = () => ({ type: CLEAN_MTOKNA_TEMPLATES });
