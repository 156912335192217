import React from 'react';
import { connect } from 'react-redux';
import { withCookies } from 'react-cookie';
import _ from 'lodash';
import { Tooltip, IconButton } from '@mui/material';
import CopyIcon from '@mui/icons-material/FileCopy';
import { Screen, Button, Input, Switch, Message } from '../components';
import { fetchEmployee, setUser, createEmployee, cleanEmployee } from '../actions';
import { __, toNumber, isEmptyString, request, isValidEmail } from '../functions';
import { PERMISSIONS } from '../config';
import '../assets/styles/employee.css';

/**
 * Zamestnanec.
 */
class EmployeeScreen extends Screen {
    /**
     * Title.
     *
     * @type {string}
     */
    title = __('Nový zamestnanec');

    /**
     * Default state.
     *
     * @type {Object}
     */
    state = {
        data: {},
        loading: false,
        loadingCheck: false,
        checkedEmail: false,
        userId: 0,
        password: '',
    };

    /**
     * Komponenta bola pripojena.
     *
     * @return boolean
     */
    async componentDidMount() {
        if (super.componentDidMount()) {
            const { fetchEmployee } = this.props;

            // Vytiahneme id zamestnanca
            const employeeId = this.getEmployeeId();

            // Nasetujeme title
            this.setTitle(employeeId > 0 ? __('Úprava zamestnanca') : __('Nový zamestnanec'));

            // Nacitame zamestnanca
            await fetchEmployee(this, employeeId);

            const { employee } = this.props;

            // Vratime zoznam default pravomoci
            const defaultPermissions = this.getDefaultPermissions();

            let data = {
                name: '',
                email: '',
                permissions: defaultPermissions,
            };

            if (!_.isEmpty(employee)) {
                // Mame data z editu
                data = {
                    name: employee.name,
                    email: employee._matchingData.Users.email,
                    permissions: !_.isEmpty(employee.permissions)
                        ? { ...defaultPermissions, ...employee.permissions }
                        : defaultPermissions,
                };
            }

            this.setState({ data });
        }

        return true;
    }

    /**
     * Komponenta bude odpojena.
     */
    componentWillUnmount() {
        const { cleanEmployee } = this.props;

        cleanEmployee();
    }

    /**
     * Vratime id zamestnanca.
     *
     * @return {number}
     */
    getEmployeeId() {
        const { location } = this.props;

        return toNumber(location.pathname.replace('/employee/', ''));
    }

    /**
     * Event po kliku na heslo.
     *
     * @param {string} password
     */
    onClickPassword(password) {
        // Nastavime heslo do clipboardu
        navigator.clipboard.writeText(password);

        this.showSnackbar('success', __('Skopirované do schránky'));
    }

    /**
     * Skontrolujeme email zamestnanca.
     */
    checkEmail() {
        const { data } = this.state;

        if (!isValidEmail(data.email)) {
            // Email nie je validny
            this.showSnackbar('error', __('Neplatný email'));
            return;
        }

        this.setState({ loadingCheck: true });

        // Vygenerujeme heslo
        const password = Math.random().toString(36).substr(2, 8);

        // Zavolame registraciu
        request('/users/register?employee=1', { email: data.email, password, password_repeat: password }, 'POST').then(response => {
            const { status, data } = response.data;

            if (status === 'error') {
                // Registracia sa nepodarila, nasetujeme user id
                if (data.type !== 'employee') {
                    // Ucet nie je zamestnanec
                    this.setState({ loadingCheck: false });
                    this.showSnackbar('error', __('Tento účet nemôže byť použitý ako účet zamestnanca'));
                    return;
                }

                this.setState({ loadingCheck: false, checkedEmail: true, userId: data.id });
                return;
            }

            // Email neexistoval, vytvorili sme usera, zobrazime heslo
            this.setState({ loadingCheck: false, checkedEmail: true, userId: data.id, password });
        });
    }

    /**
     * Ulozenie.
     */
    save() {
        let { data, userId } = this.state;

        let error = '';

        if (isEmptyString(data.name)) {
            // Nie je vyplneny nazov
            error = __('Nie je vyplnený názov');
        }

        if (this.isDemo()) {
            error = __('Zamestnanca nie je možné uložiť na DEMO účte');
        }

        if (error !== '') {
            // Mame error
            this.showSnackbar('error', error);
            return;
        }

        this.setState({ loading: true });

        const { createEmployee } = this.props;

        // Vytiahneme id zamestnanca
        const id = this.getEmployeeId();

        data = { ...data, id };

        if (id === 0) {
            // Vytvarame
            data = { ...data, user_id: userId };
        }

        // Ulozime zamestnanca
        createEmployee(this, data);
    }

    /**
     * Event po zmene dat.
     *
     * @param {string} type
     * @param {string} value
     */
    onChange(type, value) {
        const { data } = this.state;

        this.setState({ data: { ...data, [type]: value } });
    }

    /**
     * Event po zmene dat.
     *
     * @param {string} type
     * @param {string} value
     */
    onChangePermission(type, value) {
        const { data } = this.state;

        this.setState({ data: { ...data, permissions: { ...data.permissions, [type]: value } } });
    }

    /**
     * Vratime skupiny pravomoci.
     *
     * @return {Object}
     */
    getPermissionsGroups() {
        return _.reduce(PERMISSIONS, (result, permission, name) => {
            const group = name.split('-');

            switch (group.length) {
                case 1:
                    // Hlavna skupina
                    return { ...result, [name]: { [name]: permission } };

                case 2:
                    // Podskupina
                    return { ...result, [group[0]]: { ...result[group[0]], [name]: permission } };

                default:
                    return result;
            }
        }, {});
    }

    /**
     * Rendrovanie.
     *
     * @returns {JSX.Element}
     */
    render() {
        const { data, loading, loadingCheck, checkedEmail, password } = this.state;

        if (_.isEmpty(data)) {
            // Data nie su nacitane
            return this.renderLoading();
        }

        // Je to edit?
        const isEdit = this.getEmployeeId() > 0;

        // Vytiahneme skupiny pravomoci
        const permissions = this.getPermissionsGroups();

        // Ulozit povolime ak je edit alebo pri adde sme skontrolovali email
        const allowSave = isEdit || checkedEmail;

        return (
            <div className="employee">
                <div className="employee__header">
                    <div className="employee__header__left">
                        <div className="employee__header__left__title">
                            {isEdit ? __('Úprava zamestnanca') : __('Nový zamestnanec')}
                        </div>
                    </div>
                </div>
                <div className="employee__content">
                    <div className="employee__content__panels">
                        <div className="employee__content__panels__panel">
                            <Input
                                label={__('Názov')}
                                value={data.name}
                                onChange={value => this.onChange('name', value)}
                            />
                            <Input
                                label={__('E-mail')}
                                value={data.email}
                                onChange={value => this.onChange('email', value)}
                                disabled={allowSave}
                            />
                            {password ? <Message type="warning">{__('Bol vytvorený nový účet. Prosím uložte si jeho heslo, po prihlásení ho bude možné zmeniť.')}</Message> : null}
                            {password ? <div className="employee__content__panels__panel__password">
                                <Input
                                    label={__('Heslo')}
                                    value={password}
                                    disabled
                                />
                                <Tooltip title={__('Skopírovať do schránky')}>
                                    <IconButton
                                        onClick={() => this.onClickPassword(password)}
                                    >
                                        <CopyIcon />
                                    </IconButton>
                                </Tooltip>
                            </div>: null}
                            <Button
                                onClick={() => this.checkEmail()}
                                color="green"
                                loading={loadingCheck}
                                disabled={allowSave}
                            >{allowSave ? __('Overené') : __('Overiť')}</Button>
                        </div>
                        <div className="employee__content__panels__panel">
                            <div className="employee__content__panels__panel__permissions">
                                <div className="employee__content__panels__panel__permissions__title">{__('Nastavenie právomoci')}</div>
                                <Message type="info">{__('Konkrétnemu zamestnancovi viete nastaviť úkony ktoré môže vykonávať na svojom účte')}</Message>
                                {_.map(permissions, (group, name) => {
                                    return (
                                        <div
                                            className="employee__content__panels__panel__permissions__section"
                                            key={name}
                                        >
                                            {_.map(group, (permission, name) => <Switch
                                                label={permission.name}
                                                checked={data.permissions[name]}
                                                onChange={value => this.onChangePermission(name, value)}
                                                key={name}
                                            />)}
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                    <Button
                        onClick={() => this.save()}
                        loading={loading}
                        disabled={!allowSave}
                        className="employee__content__button"
                        color="green"
                    >{allowSave ? __('Uložiť') : __('Najprv musíte overiť email')}</Button>
                </div>
                {this.renderSnackbar()}
            </div>
        );
    }
}

const stateToProps = ({ employee, user }) => ({ employee, user });

export default withCookies(connect(stateToProps, {
    fetchEmployee,
    setUser,
    createEmployee,
    cleanEmployee,
})(EmployeeScreen));
