import React from 'react';
import { connect } from 'react-redux';
import { withCookies } from 'react-cookie';
import _ from 'lodash';
import { Tooltip } from '@mui/material';
import Apps from '../components/Apps';
import { Screen, ContentHeader, Button, Input, Select, ContentPart, Message } from '../components';
import { fetchEshop, fetchEshopData, setUser, changeEshopAutoField, changeEshopSettings } from '../actions';
import { __, isEmptyString, isValidEmail, isValidZip, isValidPhone, formatDate, toNumber } from '../functions';
import { COUNTRIES } from '../config';
import '../assets/styles/eshop.css';

/**
 * Eshop.
 */
class EshopScreen extends Screen {
    /**
     * Title.
     *
     * @type {string}
     */
    title = __('Nastavenia eshopu');

    /**
     * Default state.
     *
     * @type {Object}
     */
    state = {
        settings: {},
        loading: false,
        loadingData: false,
    };

    /**
     * Komponenta bola pripojena.
     *
     * @return boolean
     */
    async componentDidMount() {
        if (super.componentDidMount()) {
            const { fetchEshop } = this.props;

            await fetchEshop(this);

            const { eshop } = this.props;

            this.setState({ settings: eshop.settings });
        }

        return true;
    }

    /**
     * Event po zmene auto fieldu.
     *
     * @param {string} field
     * @param {boolean} value
     */
    onChangeAutoField(field, value) {
        const { changeEshopAutoField } = this.props;

        changeEshopAutoField(this, field, value);
    }

    /**
     * Event po zmene settingu.
     *
     * @param {string} type
     * @param {string} value
     */
    onChangeSetting(type, value) {
        const { settings } = this.state;

        this.setState({ settings: { ...settings, [type]: value } });
    }

    /**
     * Nacitame data eshopu.
     */
    refreshData() {
        const { fetchEshopData } = this.props;

        this.setState({ loadingData: true });

        fetchEshopData(this);
    }

    /**
     * Zmena nastaveni.
     */
    changeSettings() {
        const { changeEshopSettings } = this.props;
        const { settings } = this.state;

        if (isEmptyString(settings.name)) {
            // Nie je vyplneny nazov eshopu
            this.showSnackbar('error', __('Nie je vyplnený názov eshopu'));
            return;
        } else if (isEmptyString(settings.address)) {
            // Nie je vyplnena adresa eshopu
            this.showSnackbar('error', __('Nie je vyplnená adresa eshopu'));
            return;
        } else if (isEmptyString(settings.city)) {
            // Nie je vyplnene mesto psc
            this.showSnackbar('error', __('Nie je vyplnené mesto eshopu'));
            return;
        } else if (!isValidZip(settings.zip)) {
            // Neplatne psc eshopu
            this.showSnackbar('error', __('Neplatné PSČ eshopu'));
            return;
        } else if (settings.email !== '' && !isValidEmail(settings.email)) {
            // Neplatny email
            this.showSnackbar('error', __('Neplatný email eshopu'));
            return;
        } else if (settings.phone !== '' && !isValidPhone(settings.phone)) {
            // Neplatne cislo
            this.showSnackbar('error', __('Neplatné tel. číslo eshopu'));
            return;
        }

        if (this.isDemo()) {
            this.showSnackbar('error', __('Nastavenie nie je možné uložiť na DEMO účte'));
            return;
        }

        this.setState({ loading: true });

        changeEshopSettings(this, settings);
    }

    /**
     * Rendrovanie.
     *
     * @returns {JSX.Element}
     */
    render() {
        const { user, eshop } = this.props;
        const { settings, loadingData, loading } = this.state;

        if (_.isEmpty(settings)) {
            // Data nie su nacitane
            return this.renderLoading();
        }

        if (!this.hasPermission('settings-eshop')) {
            // Nema pravo
            return null;
        }

        // Vytiahneme id eshopu
        const id = toNumber(eshop.id);

        // Je aktivny?
        const active = id > 0;

        // Aktualny krok tutorialu
        const tutorialStep = this.getTutorialStep();

        return (
            <div className="eshop">
                <ContentHeader
                    title={__('Eshop')}
                    tags={active ? [
                        { label: formatDate(eshop.synchronized, __('Synchronizované s eshopom o hh:ii')) }
                    ] : null}
                    rightContent={active ? <div>
                        <Tooltip title={__('Načíta aktuálne údaje z eshopu (typy platieb, dopravy...)')}>
                            <span>
                                <Button
                                    onClick={() => this.refreshData()}
                                    loading={loadingData}
                                >{__('Načítať údaje')}</Button>
                            </span>
                        </Tooltip>
                    </div> : null}
                />
                {tutorialStep === 1
                    ? <Message className="eshop__tutorial-message" type="warning">{__('Vyberte svôj eshop z ponuky.')}</Message>
                    : (tutorialStep === 2
                        ? <Message className="eshop__tutorial-message" type="warning">{__('Systém doplnil automaticky niektoré údaje eshopu. Prosím vyplňte ostatné chýbajúce a kliknite na tlačítko "Uložiť".')}</Message>
                        : null)}
                {tutorialStep !== 2 ? <Apps
                    type="eshops"
                    eshopId={eshop.id}
                    refreshData={() => this.refreshData()}
                    { ...eshop }
                /> : null}
                {tutorialStep === 1 || tutorialStep === 2 ? null : <ContentPart title={__('Nastavenia')} />}
                {tutorialStep === 1 ? null : <div className="eshop__settings">
                    <div className="eshop__settings__panel">
                        <div className="eshop__settings__panel__title">{__('Adresa')}</div>
                        <Input
                            label={__('Názov')}
                            value={settings.name}
                            onChange={value => this.onChangeSetting('name', value)}
                        />
                        <Input
                            label={__('Adresa')}
                            value={settings.address}
                            onChange={value => this.onChangeSetting('address', value)}
                        />
                        <Input
                            label={__('Mesto')}
                            value={settings.city}
                            onChange={value => this.onChangeSetting('city', value)}
                        />
                        <Input
                            label={__('PSČ')}
                            value={settings.zip}
                            onChange={value => this.onChangeSetting('zip', value)}
                        />
                        <Select
                            label={__('Lokalizácia')}
                            options={COUNTRIES}
                            value={settings.country}
                            onChange={value => this.onChangeSetting('country', value)}
                            allowEmpty={false}
                        />
                    </div>
                    <div className="eshop__settings__panel">
                        <div className="eshop__settings__panel__title">{__('Kontaktné údaje')}</div>
                        <Input
                            label={__('Názov')}
                            value={settings.person}
                            onChange={value => this.onChangeSetting('person', value)}
                        />
                        <Input
                            type="email"
                            label={__('Email')}
                            value={settings.email}
                            onChange={value => this.onChangeSetting('email', value)}
                        />
                        <Input
                            label={__('Tel. číslo')}
                            value={settings.phone}
                            onChange={value => this.onChangeSetting('phone', value)}
                        />
                        <Input
                            label={__('Web')}
                            value={settings.web}
                            onChange={value => this.onChangeSetting('web', value)}
                        />
                    </div>
                    {_.includes(['WooCommerce', 'Ecwid', 'Shopify', 'Api', 'Shoprenter'], eshop.name) ? <div className="eshop__settings__panel">
                        <div className="eshop__settings__panel__title">{__('Technické údaje')}</div>
                        <Message type="info">{__('Nie je povinné vyplnovať tieto údaje. Ide o špecifikácie údajov pre pluginy vo vašom eshope.')}</Message>
                        <Input
                            label={__('Meta-dáta pole pre názov firmy')}
                            value={settings.meta_data_name}
                            onChange={value => this.onChangeSetting('meta_data_name', value)}
                        />
                        <Input
                            label={__('Meta-dáta pole pre IČO')}
                            value={settings.meta_data_ico}
                            onChange={value => this.onChangeSetting('meta_data_ico', value)}
                        />
                        <Input
                            label={__('Meta-dáta pole pre DIČ')}
                            value={settings.meta_data_dic}
                            onChange={value => this.onChangeSetting('meta_data_dic', value)}
                        />
                        <Input
                            label={__('Meta-dáta pole pre IČ DPH')}
                            value={settings.meta_data_ic_dph}
                            onChange={value => this.onChangeSetting('meta_data_ic_dph', value)}
                        />
                        <Input
                            label={__('Meta-dáta pole pre odberné miesto')}
                            value={settings.meta_data_pickup_point}
                            onChange={value => this.onChangeSetting('meta_data_pickup_point', value)}
                        />
                        <Input
                            label={__('Meta-dáta pole pre PDF faktúry')}
                            value={settings.meta_data_pdf_link}
                            onChange={value => this.onChangeSetting('meta_data_pdf_link', value)}
                        />
                    </div> : null}
                    <Button
                        onClick={() => this.changeSettings()}
                        loading={loading}
                        color="green"
                    >{__('Uložiť')}</Button>
                </div>}
                {this.renderSnackbar()}
            </div>
        );
    }
}

const stateToProps = ({ eshop, user }) => ({ eshop, user });

export default withCookies(connect(stateToProps, {
    fetchEshop,
    fetchEshopData,
    setUser,
    changeEshopAutoField,
    changeEshopSettings,
})(EshopScreen));
