import React from 'react';
import { connect } from 'react-redux';
import { withCookies } from 'react-cookie';
import _ from 'lodash';
import { Screen, Logo, Input, Button } from '../components';
import { __, request } from '../functions';
import { COOKIE_SETTINGS, COOKIE_AFFILIATE_NAME } from '../config';
import '../assets/styles/affiliate-login.css';

/**
 * Affiliate.
 */
class AffiliateLoginScreen extends Screen {
    /**
     * Title.
     *
     * @type {string}
     */
    title = __('Affiliate');

    /**
     * Default state.
     *
     * @type {Object}
     */
    state = {
        name: '',
        password: '',
    };

    /**
     * Auth required.
     *
     * @type {boolean}
     */
    authRequired = false;

    /**
     * Prihlasenie.
     *
     * @return boolean
     */
    login() {
        const { name, password } = this.state;

        if (_.isEmpty(name)) {
            // Nie je vyplneny email
            this.showSnackbar('error', __('Nevyplnili ste meno'));
            return false;
        } else if (_.isEmpty(password)) {
            // Nie je vyplnene heslo
            this.showSnackbar('error', __('Nevyplnili ste heslo'));
            return false;
        }

        this.setState({ loading: true });

        request('/affiliates/login', { name, password }, 'POST').then(response => {
            const { status, data } = response.data;

            this.setState({ loading: false });

            if (status === 'error') {
                // Prihlasenie sa nepodarilo
                this.showSnackbar('error', __('Nesprávne prihlasovacie údaje'));
                return;
            }

            // Zavolame callback
            this.afterLogin(data.token);
        });
    }

    /**
     * Callback po prihlaseni.
     *
     * @param {string} token
     */
    afterLogin(token) {
        const { cookies } = this.props;

        // Ulozime token do cookie
        cookies.set(COOKIE_AFFILIATE_NAME, token, COOKIE_SETTINGS);

        window.location = '/affiliate-portal';
    }

    /**
     * Event po zmene hodnoty.
     *
     * @param {string} name
     * @param {string} value
     */
    onChangeValue(name, value) {
        this.setState({ [name]: value });
    }

    /**
     * Rendrovanie.
     *
     * @returns {JSX.Element}
     */
    render() {
        const { name, password, loading } = this.state;

        return (
            <div className="affiliate-login">
                <div className="affiliate-login__window">
                    <Logo onClick={() => { window.location = '/'; }} color="dark" />
                    <div className="affiliate-login__window__title">{__('Affiliate prihlásenie')}</div>
                    <div className="affiliate-login__window__text">{__('Pre pokračovanie sa prosím prihláste')}</div>
                    <Input
                        label={__('Meno')}
                        value={name}
                        onChange={value => this.onChangeValue('name', value)}
                    />
                    <Input
                        type="password"
                        label={__('Heslo')}
                        value={password}
                        onChange={value => this.onChangeValue('password', value)}
                    />
                    <Button
                        onClick={() => this.login()}
                        className="affiliate-login__window__button"
                        loading={loading}
                    >{__('Prihlásiť')}</Button>
                </div>
                {this.renderSnackbar()}
            </div>
        );
    }
}

const stateToProps = ({ user }) => ({ user });

export default withCookies(connect(stateToProps)(AffiliateLoginScreen));
