import {
    FETCH_CAB_PRODUCTS,
    CLEAN_CAB_PRODUCTS,
    ADD_CAB_UPGATES_PRODUCT,
    REMOVE_CAB_UPGATES_PRODUCT,
    ADD_CAB_ALLEGRO_PRODUCT,
    REMOVE_CAB_ALLEGRO_PRODUCT,
    ADD_CAB_MALL_PRODUCT,
    REMOVE_CAB_MALL_PRODUCT,
    CHANGE_CAB_UPGATES_PRODUCT_PRICE,
    CHANGE_CAB_MARKETPLACE_PRODUCT_PRICE,
} from '../actions/types';
import _ from 'lodash';
import { toNumber } from '../functions';

const CAB_PRODUCTS_DEFAULT_STATE = {};

const CabProductsReducer = (state = CAB_PRODUCTS_DEFAULT_STATE, { type, payload }) => {
    switch (type) {
        case FETCH_CAB_PRODUCTS:
            return payload;

        case ADD_CAB_UPGATES_PRODUCT:
            return { ...state, items: _.map(state.items, item => {
                if (item.sku === payload.sku) {
                    item = { ...item, data: [ ...item.data, {
                        type: 'upgates',
                        user_eshop_id: payload.user_eshop_id,
                    } ] };
                }

                return item;
            }) };

        case REMOVE_CAB_UPGATES_PRODUCT:
            return { ...state, items: _.map(state.items, item => {
                if (item.sku === payload.sku) {
                    item = { ...item, data: _.reduce(item.data, (result, source) => {
                        if (
                            source.type === 'upgates'
                            && _.has(source, 'user_eshop_id')
                            && toNumber(source.user_eshop_id) === toNumber(payload.user_eshop_id)
                        ) {
                            return result;
                        }

                        return [ ...result, source ];
                    }, []) };
                }

                return item;
            }) };

        case ADD_CAB_ALLEGRO_PRODUCT:
            return { ...state, items: _.map(state.items, item => {
                if (item.sku === payload.sku) {
                    item = { ...item, data: [ ...item.data, {
                        type: 'allegro',
                            user_eshop_id: payload.user_eshop_id,
                    } ] };
                }

                return item;
            }) };

        case REMOVE_CAB_ALLEGRO_PRODUCT:
            return { ...state, items: _.map(state.items, item => {
                if (item.sku === payload.sku) {
                    item = { ...item, data: _.reduce(item.data, (result, source) => {
                        if (
                            source.type === 'allegro'
                            && _.has(source, 'user_eshop_id')
                            && toNumber(source.user_eshop_id) === toNumber(payload.user_eshop_id)
                        ) {
                            return result;
                        }

                        return [ ...result, source ];
                    }, []) };
                }

                return item;
            }) };

        case ADD_CAB_MALL_PRODUCT:
            return { ...state, items: _.map(state.items, item => {
                if (item.sku === payload.sku) {
                    item = { ...item, data: [ ...item.data, {
                        type: 'mall',
                        user_eshop_id: payload.user_eshop_id,
                    } ] };
                }

                return item;
            }) };

        case REMOVE_CAB_MALL_PRODUCT:
            return { ...state, items: _.map(state.items, item => {
                if (item.sku === payload.sku) {
                    item = { ...item, data: _.reduce(item.data, (result, source) => {
                        if (
                            source.type === 'mall'
                            && _.has(source, 'user_eshop_id')
                            && toNumber(source.user_eshop_id) === toNumber(payload.user_eshop_id)
                        ) {
                            return result;
                        }

                        return [ ...result, source ];
                    }, []) };
                }

                return item;
            }) };

        case CLEAN_CAB_PRODUCTS:
            return CAB_PRODUCTS_DEFAULT_STATE;

        case CHANGE_CAB_UPGATES_PRODUCT_PRICE:
        case CHANGE_CAB_MARKETPLACE_PRODUCT_PRICE:
        default:
            return state;
    }
};

export default CabProductsReducer;
