import _ from 'lodash';
import { toNumber } from '../functions';
import {
    FETCH_MTOKNA_WINDOWS,
    CLEAN_MTOKNA_WINDOWS,
    EDIT_MTOKNA_WINDOW,
    DELETE_MTOKNA_WINDOW,
    CHANGE_MTOKNA_WINDOW_STATE,
    READY_PICKUP_MTOKNA_WINDOW,
    PICKUP_MTOKNA_WINDOW,
    ACTIVATE_MTOKNA_WINDOW,
} from '../actions/types';

const MTOKNA_WINDOWS_DEFAULT_STATE = {};

/**
 * Reducer pre objednavky.
 */
const MtoknaWindowsReducer = (state = MTOKNA_WINDOWS_DEFAULT_STATE, { type, payload }) => {
    switch (type) {
        case FETCH_MTOKNA_WINDOWS:
            return payload;

        case CLEAN_MTOKNA_WINDOWS:
        case DELETE_MTOKNA_WINDOW:
            return MTOKNA_WINDOWS_DEFAULT_STATE;

        case EDIT_MTOKNA_WINDOW:
            // Editacia objednavky
            return { ...state, items: _.map(state.items, item => {
                if (toNumber(item.id) === toNumber(payload.id)) {
                    // Nasli sme objednavku
                    item = { ...item, data: { ...item.data, ...payload.data } };
                }

                return item;
            }) };

        case CHANGE_MTOKNA_WINDOW_STATE:
        case READY_PICKUP_MTOKNA_WINDOW:
        case PICKUP_MTOKNA_WINDOW:
        case ACTIVATE_MTOKNA_WINDOW:
            return { ...state, items: _.map(state.items, item => {
                    if (!_.isEmpty(payload[toNumber(item.id)])) {
                        // Nasli sme objednavku zmenime data
                        item = { ...item, data: payload[toNumber(item.id)] };
                    }

                    return item;
                }) };

        default:
            return state;
    }
};

export default MtoknaWindowsReducer;
