import React from 'react';
import { connect } from 'react-redux';
import { withCookies } from 'react-cookie';
import { Navigate, Button } from '../components';
import { __ } from '../functions';
import { COOKIE_SETTINGS, IMAGES, COOKIE_WEBAREAL_NAME, COOKIE_TOKEN_NAME } from '../config';
import '../assets/styles/webareal.css';

/**
 * Webareal komponenta.
 */
class Webareal extends Navigate {

    /**
     * Aktivacia.
     */
    activate() {
        if (global.token !== '') {
            // Odhlasime
            const { cookies } = this.props;

            cookies.remove(COOKIE_TOKEN_NAME, COOKIE_SETTINGS);

            window.location = '/register';
            return;
        }

        this.redirect('/register');
    }

    /**
     * Rendrovanie.
     *
     * @return {JSX.Element|null}
     */
    render() {
        const { cookies } = this.props;

        let code = null;
        let domain = null;

        if (window.location.search.toString().indexOf('webareal_code') !== -1) {
            // Mame kod v url
            code = /webareal_code=(.*?)&/.exec(window.location.search);
            code = code.length === 2 ? code[1] : '';

            domain = /domain=(.*)/.exec(window.location.search);
            domain = domain.length === 2 ? domain[1] : '';

            // Ulozime data do cookie
            cookies.set(COOKIE_WEBAREAL_NAME, code, COOKIE_SETTINGS);
            cookies.set('goodeshop-webareal-domain', domain, COOKIE_SETTINGS);
        } else {
            // Nacitavame data z cookie
            code = cookies.get(COOKIE_WEBAREAL_NAME, COOKIE_SETTINGS);
            domain = cookies.get('goodeshop-webareal-domain', COOKIE_SETTINGS);
        }

        return (
            <div className="webareal">
                <div className="webareal__content">
                    <img src={IMAGES['webareal-marketplace.png']} alt="webareal marketplace" />
                    <div className="webareal__content__text">{__('Eshop')}: {domain}</div>
                    <Button
                        onClick={() => this.activate()}
                        color="green"
                    >{__('Aktivovať na 7 dní zdarma')}</Button>
                </div>
            </div>
        );
    }
}

const stateToProps = ({ user }) => ({ user });

export default withCookies(connect(stateToProps)(Webareal));
