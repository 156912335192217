import React from 'react';
import { connect } from 'react-redux';
import { withCookies } from 'react-cookie';
import _ from 'lodash';
import PdfIcon from '@mui/icons-material/PictureAsPdf';
import Apps from '../components/Apps';
import { ListScreen, ContentPart, Select, Checkbox, Button } from '../components';
import { fetchBmails, cleanBmails, setUser } from '../actions';
import { __, formatAmount, formatDate, request, toNumber } from '../functions';
import { BANKS, GATEWAYS } from '../config';
import '../assets/styles/bmails.css';

/**
 * Bmaily.
 */
class BmailsScreen extends ListScreen {
    /**
     * Title.
     *
     * @type {string}
     */
    title = __('Banka');

    /**
     * Default state.
     *
     * @type {Object}
     */
    state = { ...ListScreen.state, ...{
        settingsLoading: false,
        settings: {},
    }};

    /**
     * Je validne pravo?
     *
     * @return {boolean}
     */
    isValidPermission() {
        return this.hasPermission('bmails') || this.hasPermission('settings-bank');
    }

    /**
     * Vratime nazov tabulky.
     *
     * @return {string}
     */
    getTableName() {
        return 'bmails';
    }

    /**
     * Vratime stlpce.
     *
     * @return {Array}
     */
    getColumns() {
        return [
            __('Dátum'),
            __('Banka'),
            __('Suma'),
            __('VS'),
            __('Protiúčet'),
            '',
        ];
    }

    /**
     * Vratime bunky.
     *
     * @param {Object} item
     *
     * @return {Array}
     */
    getCells(item) {
        let buttons = {
            pdf: {
                name: __('Stiahnuť PDF'),
                icon: <PdfIcon />,
                callback: (callbackLoading, callback) => this.pdf(item.invoice_id, callbackLoading, callback),
                options: {
                    disabled: toNumber(item.invoice_id) === 0,
                },
            }
        };

        if (!this.hasPermission('invoices-pdf')) {
            // Nema pravo
            buttons = _.omit(buttons, ['pdf']);
        }

        return [
            formatDate(item.created, 'dd.mm.yyyy hh:ii'),
            _.has(BANKS, item.data.bank) ? BANKS[item.data.bank] : GATEWAYS[item.data.bank],
            formatAmount(item.data.amount, item.data.currency),
            item.variable,
            _.has(item.data, 'account') ? item.data.account : '',
            this.renderTableButtons(item.id, buttons),
        ];
    }

    /**
     * Vratime filter.
     *
     * @return {Object}
     */
    getFilter() {
        const { items } = this.props;

        return _.reduce(items.filter, (result, options, key) => {
            switch (key) {
                case 'variable':
                    return { ...result, ...{ [key]: {
                        type: 'input',
                        name: __('Variabilný symbol'),
                        value: '',
                    }}};

                default:
                    return result;
            }
        }, {});
    }

    /**
     * Vratime empty text.
     *
     * @return {string}
     */
    getEmptyText() {
        return __('Zatiaľ nemáte žiadne pohyby');
    }

    /**
     * Stiahnutie pdf.
     *
     * @param {number} id
     * @param {function} callbackLoading
     * @param {function} callback
     */
    async pdf(id, callbackLoading, callback) {
        // Zavolame loading
        callbackLoading();

        await request(`/invoices/pdf/${id}`).then(response => {
            const { status, data } = response.data;

            if (status === 'error') {
                this.showSnackbar('error', __('Nepodarilo sa stiahnuť pdf'));
                return;
            }

            if (data.link.indexOf('inline') !== -1) {
                // Chceme inline
                window.open(data.link, '_blank');
                return;
            }

            // Stiahneme subor
            window.location = data.link;
        });

        // Zavolame callback
        callback();
    }

    /**
     * Event po zmene nastaveni.
     *
     * @param {string} name
     * @param {string|number} value
     */
    onChangeSetting(name, value) {
        const { settings } = this.state;

        this.setState({ settings: { ...settings, [name]: value } });
    }

    /**
     * Ulozime nastavenia.
     */
    saveSettings() {
        let { settings } = this.state;

        this.setState({ settingsLoading: true });

        if (_.has(settings, 'state_paid')) {
            settings = { ...settings, state_paid: _.toString(settings.state_paid) };
        }

        request('/user-banks/save', { settings }, 'POST').then(response => {
            const { status } = response.data;

            this.setState({ settingsLoading: false, settingsCustomOpened: false });

            if (status === 'error') {
                // Nepodarilo sa zmenit nastavenia
                this.showSnackbar('error', __('Nepodarilo sa zmeniť nastavenia'));
                return;
            }

            this.showSnackbar('success', __('Nastavenia boli zmenené'));
        });
    }

    /**
     * Mame vlastne custom settingy.
     *
     * @return {boolean}
     */
    hasCustomSettings() {
        return this.hasPermission('settings-bank');
    }

    /**
     * Render vlastnych settingov.
     *
     * @return {JSX.Element|null}
     */
    renderCustomSettings() {
        const { user, items } = this.props;
        const { settings, settingsLoading } = this.state;

        const userEshopId = toNumber(user.user_eshop_id);

        // Vytiahneme zoznam stavov
        const states = _.reduce(items.eshop_data.states, (result, { id, name }) => ({ ...result, [id]: name }), {});

        return (
            <div className="bmails-settings">
                <div className="bmails-settings__list">
                    <Select
                        label={__('Stav objednávky po úhrade')}
                        options={states}
                        value={_.has(settings, 'state_paid') ? settings.state_paid : items.eshop_settings.state_paid}
                        onChange={value => this.onChangeSetting('state_paid', value)}
                    />
                    <Checkbox
                        label={__('Párovať podla VS faktúry')}
                        value={_.has(settings, 'pair_by_variable') ? settings.pair_by_variable : items.eshop_settings.pair_by_variable}
                        onChange={checked => this.onChangeSetting('pair_by_variable', checked)}
                    />
                    <Checkbox
                        label={__('Párovať podla čísla faktúry')}
                        value={_.has(settings, 'pair_by_invoice_number') ? settings.pair_by_invoice_number : items.eshop_settings.pair_by_invoice_number}
                        onChange={checked => this.onChangeSetting('pair_by_invoice_number', checked)}
                    />
                    <Checkbox
                        label={__('Párovať podla čísla objednávky')}
                        value={_.has(settings, 'pair_by_order_number') ? settings.pair_by_order_number : items.eshop_settings.pair_by_order_number}
                        onChange={checked => this.onChangeSetting('pair_by_order_number', checked)}
                    />
                </div>
                <Button
                    onClick={() => this.saveSettings()}
                    loading={settingsLoading}
                    disabled={_.isEmpty(settings) || userEshopId === 0}
                    color="green"
                >{__('Uložiť')}</Button>
            </div>
        );
    }

    /**
     * Render after header.
     *
     * @return {JSX.Element|null}
     */
    renderAfterHeader() {
        return (
            <div className="bmails-after-header">
                {this.hasPermission('settings-bank') ? <Apps type="banks" { ...this.props.items } /> : null}
                {this.hasPermission('bmails') ? <ContentPart title={__('Pohyby')} /> : null}
            </div>
        );
    }

    /**
     * Rendrujeme tabulku.
     *
     * @return {JSX.Element|null}
     */
    renderTable() {
        if (!this.hasPermission('bmails')) {
            // Nema pravo
            return null;
        }

        return super.renderTable();
    }
}

const stateToProps = ({ bmails, user }) => ({ items: bmails, user });

export default withCookies(connect(stateToProps, {
    fetch: fetchBmails,
    clean: cleanBmails,
    setUser,
})(BmailsScreen));
