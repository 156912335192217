import _ from 'lodash';
import {
    FETCH_MARKETPLACE_PRODUCTS,
    CLEAN_MARKETPLACE_PRODUCTS,
    SAVE_MARKETPLACE_PRODUCTS,
} from './types';
import { __, request } from '../functions';

/**
 * Nacitanie marketplace produktov.
 *
 * @param {Screen} screen
 * @param {string} marketplace
 * @param {object} options
 */
export const fetchMarketplaceProducts = (screen, marketplace, options = {}) => {
    const defaults = {
        page: 1,
        per_page: 0,
        filtered: {},
    };

    options = { ...defaults, ...options };

    let params = {
        marketplace,
        page: options.page,
        per_page: 20,
        not_update_per_page: 1,
    };

    // Nasetujeme filter
    _.forEach(options.filtered, (value, key) => {
        params = { ...params, ...{ [`filter_${key}`]: value } };
    });

    return dispatch => request('/products/index', params).then(response => {
        const { data, sync } = response.data;
        const { setUser } = screen.props;

        // Vypneme loading
        screen.setState({ loading: false });

        if (_.has(sync, 'user')) {
            // Nasetujeme user data
            setUser(sync.user);
        }

        dispatch({ type: FETCH_MARKETPLACE_PRODUCTS, payload: data });
    });
};

/**
 * Vycistenie marketplace produktov.
 */
export const cleanMarketplaceProducts = () => ({ type: CLEAN_MARKETPLACE_PRODUCTS });

/**
 * Ulozenie marketplace produktov.
 *
 * @param {Screen} screen
 * @param {string} marketplace
 * @param {Object} products
 * @param {Object} deleted
 */
export const saveMarketplaceProducts = (screen, marketplace, products, deleted) => {
    return dispatch => request('/user-marketplaces/saveProducts', { products, deleted }, 'POST', { marketplace }).then(response => {
        const { status, data } = response.data;

        screen.setState({ loadingProducts: false });

        if (status === 'error') {
            // Nepodarilo sa ulozit
            screen.setState({ loadingProducts: false });
            screen.showSnackbar('error', __('Nepodarilo sa uložiť produkty'));
            return;
        }

        screen.setState({ loadingProducts: false, settings: {}, deleted: {} });
        screen.showSnackbar('success', __('Produkty boli uložené'));

        dispatch({ type: SAVE_MARKETPLACE_PRODUCTS, payload: data.items });
    });
};
