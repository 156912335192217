import React from 'react';
import { connect } from 'react-redux';
import { withCookies } from 'react-cookie';
import _ from 'lodash';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Create';
import AddIcon from '@mui/icons-material/AddCircle';
import { ListScreen } from '../components';
import { fetchExports, cleanExports, setUser, deleteExport } from '../actions';
import { __, formatDate, toNumber } from '../functions';
import { IMAGES } from '../config';
import '../assets/styles/exports.css';

/**
 * Exporty.
 */
class ExportsScreen extends ListScreen {
    /**
     * Title.
     *
     * @type {string}
     */
    title = __('Exporty');

    /**
     * Default state.
     *
     * @type {Object}
     */
    state = { ...ListScreen.state, ...{
        lightbox: {
            delete: false,
        },
    }};

    /**
     * Je validne pravo?
     *
     * @return {boolean}
     */
    isValidPermission() {
        return this.hasPermission('exports');
    }

    /**
     * Vratime zoznam tagov.
     *
     * @return {Array}
     */
    getTags() {
        const { user } = this.props;

        return [
            this.hasPermission('exports-edit') ? {
                name: __('Nový export'),
                color: 'secondary',
                icon: <AddIcon />,
                onClick: () => this.redirect('/export'),
                disabled: toNumber(user.user_eshop_id) === 0,
            } : null,
        ];
    }

    /**
     * Vratime nazov tabulky.
     *
     * @return {string}
     */
    getTableName() {
        return 'exports';
    }

    /**
     * Vratime empty text.
     *
     * @return {string}
     */
    getEmptyText() {
        return __('Zatiaľ nemáte žiadne exporty');
    }

    /**
     * Zobrazime lightbox na zmazanie.
     *
     * @param {number} id
     * @param {function} callbackLoading
     * @param {function} callback
     */
    confirmDelete(id, callbackLoading, callback) {
        this.showLightbox('delete', { id, callbackLoading, callback });
    }

    /**
     * Zmazanie exportu.
     */
    async delete() {
        const { deleteExport, items } = this.props;
        const { lightbox } = this.state;

        // Zavolame loading
        lightbox.delete.callbackLoading();

        // Zavrieme lightbox
        this.closeLightbox('delete');

        // Zmazeme exportu
        await deleteExport(this, lightbox.delete.id);

        // Zavolame callback
        lightbox.delete.callback();

        // Znovu nacitame zoznam od prvej stranky
        this.fetchList(1, 0, items.filtered);
    }

    /**
     * Rendrujeme polozku gridu.
     *
     * @param {Object} item
     *
     * @return {JSX.Element|null}
     */
    renderGridItem(item) {
        let buttons = {
            edit: {
                name: __('Upraviť'),
                icon: <EditIcon />,
                callback: () => this.redirect(`/export/${item.id}`),
                options: {},
            },
            delete: {
                name: __('Zmazať'),
                icon: <DeleteIcon />,
                callback: (callbackLoading, callback) => this.confirmDelete(item.id, callbackLoading, callback),
                options: {
                    disabled: toNumber(item.def) === 1,
                },
            },
        };

        if (!this.hasPermission('exports-edit')) {
            // Nema pravo
            buttons = _.omit(buttons, ['edit']);
        }

        if (!this.hasPermission('exports-delete')) {
            // Nema pravo
            buttons = _.omit(buttons, ['delete']);
        }

        return (
            <div className="exports-grid__item">
                <div className="exports-grid__item__header">
                    <div className="exports-grid__item__header__title">{item.name}</div>
                    <div className="exports-grid__item__header__buttons">
                        {this.renderGridButtons(item.id, buttons)}
                    </div>
                </div>
                {item.type === 'pdf'
                    ? <img src={IMAGES['pdf.png']} alt="pdf" />
                    : <img src={IMAGES['excel.png']} alt="excel" />}
                <div className="exports-grid__item__footer">
                    {`${__('Vytvorené')} ${formatDate(item.created, 'dd.mm.yyyy hh:ii')}`}
                </div>
            </div>
        );
    }

    /**
     * Rendrujeme screen.
     *
     * @return {JSX.Element|null}
     */
    renderScreen() {
        return (
            <div>
                {this.renderLightbox(
                    'delete',
                    __('Naozaj chcete zmazať tento export?'),
                    null,
                    __('Áno'),
                    __('Nie'),
                    () => this.delete()
                )}
            </div>
        );
    }
}

const stateToProps = ({ exports, user }) => ({ items: exports, user });

export default withCookies(connect(stateToProps, {
    fetch: fetchExports,
    clean: cleanExports,
    setUser,
    deleteExport,
})(ExportsScreen));
