import _ from 'lodash';
import { CURRENCIES } from '../config';

/**
 * Zformatujeme menu.
 *
 * @param {string} currency
 *
 * @returns {string}
 */
export const formatCurrency = currency => _.has(CURRENCIES, currency) ? CURRENCIES[currency] : currency;