import React from 'react';
import { connect } from 'react-redux';
import { withCookies } from 'react-cookie';
import _ from 'lodash';
import { Tooltip } from '@mui/material';
import Apps from '../components/Apps';
import { Screen, ContentHeader, Button, Input, Select, ContentPart, Message } from '../components';
import { fetchMtoknaEshop, setUser } from '../actions';
import { __, isEmptyString, isValidEmail, isValidZip, isValidPhone, formatDate, toNumber } from '../functions';
import { COUNTRIES } from '../config';
import '../assets/styles/mtokna_eshop.css';

/**
 * Eshop.
 */
class MtoknaEshopScreen extends Screen {
    /**
     * Title.
     *
     * @type {string}
     */
    title = __('Nastavenia eshopu');

    /**
     * Default state.
     *
     * @type {Object}
     */
    state = {
        loading: false,
        lightbox: {
            create: false,
        }
    };

    /**
     * Komponenta bola pripojena.
     *
     * @return boolean
     */
    componentDidMount() {
        if (super.componentDidMount()) {
            const { fetchMtoknaEshop } = this.props;

            fetchMtoknaEshop(this);
        }

        return true;
    }

    onChangeCreateEshop(value) {
        const { lightbox } = this.state;

        this.setState({ lightbox: { ...lightbox, create: { ...lightbox.create, eshop_id: value } } });
    }

    create() {
        const { eshop } = this.props;
        const { lightbox } = this.state;

        if (toNumber(lightbox.create.eshop_id) === 0) {
            return;
        }

        this.closeLightbox('create');

        lightbox.create.callback(lightbox.create.eshop_id, eshop.eshops[lightbox.create.eshop_id].name);
    }

    /**
     * Rendrovanie.
     *
     * @returns {JSX.Element}
     */
    render() {
        const { eshop } = this.props;
        const { lightbox } = this.state;

        if (_.isEmpty(eshop)) {
            // Data nie su nacitane
            return this.renderLoading();
        }

        const eshops = _.reduce(eshop.eshops, (result, { name }, id) => {
            return { ...result, [id]: name };
        }, {});

        return (
            <div className="eshop">
                <ContentHeader
                    title={__('Eshopy')}
                    rightContent={<div>
                        <Tooltip title={__('Stiahnuť aktuálnu verziu WooCommerce pluginu')}>
                            <span><Button
                                onClick={() => { window.location = `https://api.goodeshop.sk/mtokna/downloadWooPlugin?token=${global.token}`; }}
                            >{__('WooCommerce plugin')}</Button></span>
                        </Tooltip>
                    </div>}
                />
                <Apps
                    type="mtokna"
                    user_eshops={eshop.user_eshops}
                    eshops={eshop.eshops}
                    createCallback={callback => this.showLightbox('create', { callback, eshop_id: 0 })}
                />
                {this.renderLightbox(
                    'create',
                    __('Vyberte eshop'),
                    !_.isEmpty(lightbox.create) ? <div>
                        <Select
                            label={__('Eshop')}
                            value={lightbox.create.eshop_id}
                            options={eshops}
                            onChange={value => this.onChangeCreateEshop(value)}
                            allowEmpty={true}
                        />
                    </div> : null,
                    __('Uložiť'),
                    __('Zrušiť'),
                    () => this.create()
                )}
                {this.renderSnackbar()}
            </div>
        );
    }
}

const stateToProps = ({ mtokna_eshop, user }) => ({ eshop: mtokna_eshop, user });

export default withCookies(connect(stateToProps, {
    fetchMtoknaEshop,
    setUser,
})(MtoknaEshopScreen));
