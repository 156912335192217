import _ from 'lodash';
import { toNumber } from '../functions';
import {
    FETCH_ORDERS,
    CLEAN_ORDERS,
    SYNC_ORDER,
    SYNC_ORDERS,
    EXPORT_ORDER,
    EXPORT_ORDERS,
    CHANGE_ORDERS_STATE,
    CHANGE_ORDER_STATE,
    CREATE_INVOICE,
    CREATE_INVOICES,
    PAID_ORDER,
    PAID_ORDERS,
    DELETE_ORDER,
    DELETE_ORDERS,
    ADDON_ORDERS,
    EDIT_ORDER,
    SEND_ORDERS,
    SELLER_ORDER,
    SELLER_ORDERS,
    PICKER_ORDER,
} from '../actions/types';

const ORDERS_DEFAULT_STATE = {};

/**
 * Reducer pre objednavky.
 */
const OrdersReducer = (state = ORDERS_DEFAULT_STATE, { type, payload }) => {
    switch (type) {
        case FETCH_ORDERS:
            // Nacitanie objednavok
            return payload;

        case CLEAN_ORDERS:
        case DELETE_ORDER:
        case DELETE_ORDERS:
            // Vycistenie objednavok
            // Zmazanie objednavky
            // Zmazanie objednavok
            return ORDERS_DEFAULT_STATE;

        case CREATE_INVOICE:
        case CREATE_INVOICES:
            // Vytvorenie faktury
            // Vytvorenie faktur
            return { ...state, items: _.map(state.items, item => {
                if (!_.isEmpty(payload[toNumber(item.id)])) {
                    // Nasli sme objednavku, pridame a nastavime objednavku ako procesovanu
                    const data = payload[toNumber(item.id)];

                    item = _.isString(data)
                        ? { ...item, error_invoice: data }
                        : { ...item,
                            invoice: data,
                            invoice_status_pdf: 'newly',
                            invoice_status: data.paid > 0 ? 'paid' : 'unpaid',
                    };
                }

                return item;
            }) };

        case PAID_ORDER:
        case PAID_ORDERS:
            // Uhradenie objednavky
            // Uhradenie objednavok
            return { ...state, items: _.map(state.items, item => {
                if (_.has(payload, item.id)) {
                    // Nasli sme objednavku nastavime status faktury ako uhradeny
                    item = !_.isEmpty(payload[item.id])
                        ? { ...item, data: { ...item.data, state: payload[item.id], paid: true } }
                        : { ...item, invoice_status: 'paid' };
                }

                return item;
            }) };

        case EDIT_ORDER:
            // Editacia objednavky
            return { ...state, items: _.map(state.items, item => {
                if (toNumber(item.id) === toNumber(payload.id)) {
                    // Nasli sme objednavku
                    item = { ...item, data: { ...item.data, ...payload.data } };
                }

                return item;
            }) };

        case SEND_ORDERS:
            // Odoslanie objednavok
            const { packages, datas } = payload;

            return { ...state, items: _.map(state.items, item => {
                if (!_.isEmpty(packages[toNumber(item.id)])) {
                    // Nasli sme objednavku nastavime zasielku
                    item = { ...item, package: packages[toNumber(item.id)] };
                }

                if (!_.isEmpty(datas[toNumber(item.id)])) {
                    // Nasli sme objednavku nastavime data
                    item = { ...item, data: datas[toNumber(item.id)] };
                }

                return item;
            }) };

        case SYNC_ORDER:
        case SYNC_ORDERS:
        case CHANGE_ORDER_STATE:
        case CHANGE_ORDERS_STATE:
        case SELLER_ORDER:
        case SELLER_ORDERS:
            // Synchronizacia objednavky
            // Synchronizacia objednavok
            // Zmena stavu objednavky
            // Zmena stavu objednavok
            return { ...state, items: _.map(state.items, item => {
                if (!_.isEmpty(payload[toNumber(item.id)])) {
                    // Nasli sme objednavku zmenime data
                    item = { ...item, data: payload[toNumber(item.id)] };
                }

                return item;
            }) };

        case EXPORT_ORDER:
        case EXPORT_ORDERS:
            // Export objednavky
            // Export objednavok
            return { ...state, items: _.map(state.items, item => {
                if (_.has(payload, item.id)) {
                    // Nasli sme objednavku nastavime priznak ako exportovany
                    item = { ...item, exported: 1 };
                }

                return item;
            }) };

        case PICKER_ORDER:
            return { ...state, items: _.map(state.items, item => {
                if (toNumber(item.id) === toNumber(payload.id)) {
                    // Nasli sme objednavku zmenime data
                    item = { ...item, picker_orders: payload.items };
                }

                return item;
            }) };

        case ADDON_ORDERS:
        default:
            // Addon objednavok
            return state;
    }
};

export default OrdersReducer;
