import {
    FETCH_MTOKNA_OWNERS,
    CLEAN_MTOKNA_OWNERS,
    CHANGE_MTOKNA_OWNER_EMAIL,
    CHANGE_MTOKNA_OWNER_SETTINGS,
} from '../actions/types';
import _ from 'lodash';
import { toNumber } from '../functions';

const MTOKNA_OWNERS_DEFAULT_STATE = {};

const MtoknaOwnersReducer = (state = MTOKNA_OWNERS_DEFAULT_STATE, { type, payload }) => {
    switch (type) {
        case FETCH_MTOKNA_OWNERS:
            return payload;

        case CHANGE_MTOKNA_OWNER_EMAIL:
            return { ...state, items: _.map(state.items, item => {
                if (toNumber(item.user_id) === toNumber(payload.id)) {
                    item = { ...item, _matchingData: {
                        ...item._matchingData,
                        Users: { ...item._matchingData.Users, email: payload.email }
                    } };
                }

                return item;
            }) };

        case CHANGE_MTOKNA_OWNER_SETTINGS:
            return { ...state, items: _.map(state.items, item => {
                if (toNumber(item.user_id) === toNumber(payload.id)) {
                    item = { ...item, settings: payload.settings };
                }

                return item;
            }) };

        case CLEAN_MTOKNA_OWNERS:
            return MTOKNA_OWNERS_DEFAULT_STATE;

        default:
            return state;
    }
};

export default MtoknaOwnersReducer;
