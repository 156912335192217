import _ from 'lodash';
import {
    FETCH_MTOKNA_ORDERS,
    CLEAN_MTOKNA_ORDERS,
    SYNC_MTOKNA_ORDER,
    EXPORT_MTOKNA_ORDER,
    EXPORT_MTOKNA_ORDERS,
    CHANGE_MTOKNA_ORDER_STATE,
    SELLER_MTOKNA_ORDER,
    SELLER_MTOKNA_ORDERS,
    EDIT_MTOKNA_ORDER,
    READY_MTOKNA_ORDER,
    PICKUP_MTOKNA_ORDER,
    SEND_MTOKNA_ORDERS,
    LOCK_MTOKNA_ORDER,
    UNLOCK_MTOKNA_ORDER,
} from './types';
import { request, __ } from '../functions';

export const fetchMtoknaOrders = (screen, options = {}, all = false) => {
    const defaults = {
        page: 1,
        per_page: 0,
        filtered: {},
        callback: null,
    };

    options = { ...defaults, ...options };

    let params = { page: options.page, per_page: options.per_page };

    // Nasetujeme filter
    _.forEach(options.filtered, (value, key) => {
        params = { ...params, ...{ [`filter_${key}`]: value } };
    });

    let uri = '/mtokna/orders';

    if (all) {
        uri = '/mtokna/allOrders';
    }

    return dispatch => request(uri, params).then(response => {
        const { data, sync } = response.data;
        const { setUser } = screen.props;

        if (options.callback !== null) {
            // Zavolame callback
            options.callback();
        }

        // Nasetujeme user data
        setUser(sync.user);

        dispatch({ type: FETCH_MTOKNA_ORDERS, payload: data });
    });
};

export const cleanMtoknaOrders = () => ({ type: CLEAN_MTOKNA_ORDERS });

export const changeMtoknaOrderState = (screen, id, stateId) => {
    return dispatch => request(`/mtokna/changeOrderState/${id}/${stateId}`).then(response => {
        const { status, data } = response.data;

        if (status === 'error') {
            // Stav sa nepodarilo zmenit
            screen.showSnackbar('error', __('Stav objednávky sa nepodarilo zmeniť'));
            return;
        }

        screen.showSnackbar('success', __('Stav objednávky bol zmenený'));

        dispatch({ type: CHANGE_MTOKNA_ORDER_STATE, payload: { id, state: stateId } });
    });
};

export const syncMtoknaOrder = (screen, id) => {
    return dispatch => request(`/mtokna/syncOrder/${id}`).then(response => {
        const { status, data } = response.data;

        if (status === 'error') {
            // Objednavku sa nepodarilo synchronizovat
            screen.showSnackbar('error', __('Objednávku sa nepodarilo synchronizovať'));
            return;
        }

        screen.showSnackbar('success', __('Objednávka bola zosynchronizovaná s eshopom'));

        dispatch({ type: SYNC_MTOKNA_ORDER, payload: data });
    });
};

export const pdfMtoknaOrder = (screen, id) => {
    return dispatch => request(`/mtokna/pdfOrder/${id}`).then(response => {
        const { status, data } = response.data;

        if (status === 'error') {
            screen.showSnackbar('error', __('Nepodarilo sa stiahnuť pdf objednávky'));
            return;
        }

        screen.showSnackbar('success', __('Objednávka bola exportovaná'));

        // Stiahneme subor
        window.location = data.link;

        dispatch({ type: EXPORT_MTOKNA_ORDER, payload: { [id]: id } });
    });
};

export const pdfMtoknaOrders = (screen, ids, filtered = {}) => {
    let params = { merge_multi_order_pdf: screen.props.user.settings.merge_multi_order_pdf };

    // Nasetujeme filter
    _.forEach(filtered, (value, key) => {
        params = { ...params, ...{ [`filter_${key}`]: value } };
    });

    return dispatch => request(`/mtokna/multiPdfOrders/${ids}`, params).then(response => {
        const { status, data } = response.data;

        if (status === 'error') {
            screen.showSnackbar('error', __('Nepodarilo sa stiahnuť zoznam pdf objednávok'));
            return;
        }

        screen.showSnackbar('success', __('Objednávky boli exportované'));

        // Stiahneme subor
        window.location = data.link;

        dispatch({ type: EXPORT_MTOKNA_ORDERS, payload: data.ids });
    });
};

export const sellerMtoknaOrder = (screen, id, data) => {
    return dispatch => request(`/mtokna/orderUpdate/${id}`, { data }, 'POST').then(response => {
        const { status, data } = response.data;

        if (status === 'error') {
            // Objednavku sa nepodarilo zmenit
            screen.showSnackbar('error', __('Nepodarilo sa uložiť objednávku'));
            return;
        }

        // Zavrieme
        screen.closeLightbox('sellers');
        screen.showSnackbar('success', __('Objednávka bola zmenená'));

        dispatch({ type: SELLER_MTOKNA_ORDER, payload: data });
    });
};

export const lockMtoknaOrder = (screen, id) => {
    return dispatch => request(`/mtokna/lockOrder/${id}`).then(response => {
        const { status, data } = response.data;

        if (status === 'error') {
            // Objednavku sa nepodarilo zmenit
            screen.showSnackbar('error', __('Nepodarilo sa zamknúť objednávku'));
            return;
        }

        // Zavrieme
        screen.showSnackbar('success', __('Objednávka bola zamknutá'));

        dispatch({ type: LOCK_MTOKNA_ORDER, payload: data });
    });
};

export const unlockMtoknaOrder = (screen, id) => {
    return dispatch => request(`/mtokna/unlockOrder/${id}`).then(response => {
        const { status, data } = response.data;

        if (status === 'error') {
            // Objednavku sa nepodarilo zmenit
            screen.showSnackbar('error', __('Nepodarilo sa odomknúť objednávku'));
            return;
        }

        // Zavrieme
        screen.showSnackbar('success', __('Objednávka bola odomknutá'));

        dispatch({ type: UNLOCK_MTOKNA_ORDER, payload: data });
    });
};

export const sellerMtoknaOrders = (screen, ids, metadata, filtered = {}) => {
    let params = {};

    // Nasetujeme filter
    _.forEach(filtered, (value, key) => {
        params = { ...params, ...{ [`filter_${key}`]: value } };
    });

    return dispatch => request(`/mtokna/multiOrderUpdate/${ids}`, { data: metadata }, 'POST', params).then(response => {
        const { status, data } = response.data;

        if (status === 'error') {
            screen.showSnackbar('error', __('Nepodarilo sa uložiť objednávky'));
            return;
        }

        screen.closeLightbox('multisellers');
        screen.showSnackbar('success', __('Objednávky boli zmenené'));

        dispatch({ type: SELLER_MTOKNA_ORDERS, payload: data });
    });
};

export const readyMtoknaOrder = (screen, id) => {
    return dispatch => request(`/mtokna/readyForPickup/${id}`).then(response => {
        const { status, data } = response.data;

        if (status === 'error') {
            // Objednavku sa nepodarilo zmenit
            screen.onChangeReady('loading', false);
            screen.showSnackbar('error', __('Nepodarilo sa zmeniť objednávku'));
            return;
        }

        // Zavrieme
        screen.closeLightbox('ready');
        screen.showSnackbar('success', __('Objednávka je pripravená na vyzdvihnutie'));

        dispatch({ type: READY_MTOKNA_ORDER, payload: { id, state: data.state } });
    });
};

export const pickupMtoknaOrder = (screen, id, password) => {
    return dispatch => request(`/mtokna/pickup/${id}/${password}`).then(response => {
        const { status, data } = response.data;

        screen.closeLightbox('pickup');

        if (status === 'error') {
            // Objednavku sa nepodarilo zmenit
            screen.showSnackbar('error', __('Nesprávne heslo'));
            return;
        }

        // Zavrieme
        screen.showSnackbar('success', __('Objednávka je vyzdvihnutá'));

        dispatch({ type: PICKUP_MTOKNA_ORDER, payload: { id, state: data.state, meta_data: data.meta_data } });
    });
};

export const sendMtoknaOrders = data => ({ type: SEND_MTOKNA_ORDERS, payload: data });

export const changeMtoknaOrderData = (id, data) => ({ type: EDIT_MTOKNA_ORDER, payload: { id, data } });
