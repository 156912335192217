import _ from 'lodash';
import {
    FETCH_PRODUCTS,
    CLEAN_PRODUCTS,
    CHANGE_PRODUCT_STOCK,
    DELETE_PRODUCT,
    DELETE_PRODUCTS,
    EDIT_PRODUCT,
    CHANGE_PRODUCT_MALL,
    CHANGE_PRODUCT_ALLEGRO,
} from './types';
import { __, request } from '../functions';

/**
 * Nacitanie produktov.
 *
 * @param {Screen} screen
 * @param {object} options
 */
export const fetchProducts = (screen, options = {}) => {
    const defaults = {
        page: 1,
        per_page: 0,
        filtered: {},
    };

    options = { ...defaults, ...options };

    let params = { page: options.page, per_page: options.per_page };

    // Nasetujeme filter
    _.forEach(options.filtered, (value, key) => {
        params = { ...params, ...{ [`filter_${key}`]: value } };
    });

    return dispatch => request('/products/index', params).then(response => {
        const { data, sync } = response.data;
        const { setUser } = screen.props;

        // Nasetujeme user data
        setUser(sync.user);

        dispatch({ type: FETCH_PRODUCTS, payload: data });
    });
};

/**
 * Nastavime produktu stav na sklade.
 *
 * @param {Screen} screen
 * @param {number} id
 * @param {string} stock
 */
export const changeProductStock = (screen, id, stock) => {
    return dispatch => request(`/products/edit/${id}`, { stock }, 'POST').then(response => {
        const { status } = response.data;

        if (status === 'error') {
            // Produkt sa nepodarilo zmenit
            screen.showSnackbar('error', __('Nepodarilo sa zmeniť stav na sklade'));
            return;
        }

        screen.showSnackbar('success', __('Stav na sklade bol zmenený'));

        dispatch({ type: CHANGE_PRODUCT_STOCK, payload: { id, stock } });
    });
};

/**
 * Zmazanie produktu.
 *
 * @param {Screen} screen
 * @param {number} id
 */
export const deleteProduct = (screen, id) => {
    return dispatch => request(`/products/delete/${id}`).then(response => {
        const { status } = response.data;

        if (status === 'error') {
            // Produkt sa nepodarilo zmazat
            screen.showSnackbar('error', __('Nepodarilo sa zmazať produkt'));
            return;
        }

        screen.showSnackbar('success', __('Produkt bol zmazaný'));

        dispatch({ type: DELETE_PRODUCT });
    });
};

/**
 * Zmazanie produktov cez multiselect.
 *
 * @param {Screen} screen
 * @param {string} ids
 * @param {Object} filtered
 */
export const deleteProducts = (screen, ids, filtered = {}) => {
    let params = {};

    // Nasetujeme filter
    _.forEach(filtered, (value, key) => {
        params = { ...params, ...{ [`filter_${key}`]: value } };
    });

    return dispatch => request(`/products/multiDelete/${ids}`, params).then(response => {
        const { status } = response.data;

        if (status === 'error') {
            // Produkty sa nepodarilo zmazat
            screen.showSnackbar('error', __('Nepodarilo sa zmazať produkty'));
            return;
        }

        screen.showSnackbar('success', __('Produkty boli zmazané'));

        dispatch({ type: DELETE_PRODUCTS });
    });
};

/**
 * Editacia produktu.
 *
 * @param {Screen} drawer
 * @param {number} id
 * @param {Object} data
 */
export const editProduct = (drawer, id, data) => {
    return dispatch => request(`/products/edit/${id}`, data, 'POST').then(response => {
        const { status } = response.data;

        if (status === 'error') {
            // Produkt sa nepodarilo zmenit
            drawer.setState({ loading: false });
            drawer.props.showSnackbar('error', __('Nepodarilo sa zmeniť produkt'));
            return;
        }

        // Zavrieme
        drawer.props.onClose();
        drawer.props.showSnackbar('success', __('Produkt bol zmenený'));

        dispatch({ type: EDIT_PRODUCT, payload: { id, data } });
    });
};

/**
 * Zobrazime/zrusime produkt na mall.
 *
 * @param {Screen} screen
 * @param {number} id
 * @param {boolean} show
 */
export const changeProductMall = (screen, id, show) => {
    return dispatch => request(`/products/mall/${id}`).then(response => {
        const { status } = response.data;

        if (status === 'error') {
            // Produkt sa nepodarilo zmenit
            screen.showSnackbar('error', __('Nepodarilo sa pridať alebo zmeniť produkt na mall'));
            return;
        }

        screen.showSnackbar('success', show ? __('Produkt bol pridaný na mall') : __('Produkt bol zrušený na mall'));

        dispatch({ type: CHANGE_PRODUCT_MALL, payload: { id } });
    });
};

/**
 * Zobrazime/zrusime produkt na allegro.
 *
 * @param {Screen} screen
 * @param {number} id
 * @param {boolean} show
 * @param {string} category_id
 */
export const changeProductAllegro = (screen, id, show, category_id = '') => {
    return dispatch => request(`/products/allegro/${id}`, { category_id }).then(response => {
        const { status } = response.data;

        if (status === 'error') {
            // Produkt sa nepodarilo zmenit
            screen.showSnackbar('error', __('Nepodarilo sa pridať alebo zmeniť produkt na allegro'));
            return;
        }

        screen.showSnackbar('success', show ? __('Produkt bol pridaný na allegro') : __('Produkt bol zrušený na allegro'));

        dispatch({ type: CHANGE_PRODUCT_ALLEGRO, payload: { id } });
    });
};

/**
 * Zmenime data produktu.
 *
 * @param {number} id
 * @param {Object} data
 */
export const changeProductData = (id, data) => ({ type: EDIT_PRODUCT, payload: { id, data } });

/**
 * Vycistenie produktov.
 */
export const cleanProducts = () => ({ type: CLEAN_PRODUCTS });
