import React from 'react';
import { connect } from 'react-redux';
import { withCookies } from 'react-cookie';
import _ from 'lodash';
import { ResponsiveContainer, AreaChart, Area, XAxis, Tooltip, CartesianGrid } from 'recharts';
import PeriodIcon from '@mui/icons-material/Event';
import { Screen, Button, Select, Switch, EventsList } from '../components';
import { __, formatDate, formatAmount, getGrowth, toNumber, getUrl } from '../functions';
import { fetchDashboard, setUser } from '../actions';
import {
    ACCOUNTING_COLORS,
    BANK_COLORS,
    COURIER_COLORS,
    ESHOP_COLORS,
    GATEWAYS_COLORS,
    IMAGES,
    STORAGE_COLORS,
    MARKETPLACES_COLORS,
    TUTORIAL_STEPS,
} from '../config';
import '../assets/styles/dashboard.css';

/**
 * Dashboard.
 */
class DashboardScreen extends Screen {
    /**
     * Title.
     *
     * @type {string}
     */
    title = __('Prehľady');

    /**
     * Defaut state.
     *
     * @type {{
     *     loading: boolean,
     *     period: string,
     *     graphPrevYear: boolean,
     * }}
     */
    state = {
        loading: false,
        period: '',
        graphPrevYear: false,
    };

    /**
     * Eshop required.
     *
     * @type {boolean}
     */
    eshopRequired = false;

    /**
     * Komponenta bola pripojena.
     *
     * @return boolean
     */
    async componentDidMount() {
        if (super.componentDidMount()) {
            // Nacitame data
            const { fetchDashboard } = this.props;

            await fetchDashboard(this);

            const { dashboard } = this.props;

            this.setState({ period: formatDate(dashboard.stats.date, 'yyyy-mm') });
        }

        return true;
    }

    /**
     * Event po zmene obdobia.
     *
     * @param {string} period
     */
    async onChangePeriod(period) {
        const { fetchDashboard } = this.props;

        this.setState({ period, loading: true });

        // Nacitame dashboard
        await fetchDashboard(this, period);

        this.setState({ loading: false });
    }

    /**
     * Event po zmene grafu predosleho roku.
     *
     * @param {boolean} graphPrevYear
     */
    onChangeGraphPrevYear(graphPrevYear) {
        this.setState({ graphPrevYear });
    }

    /**
     * Rendrovanie.
     *
     * @returns {JSX.Element}
     */
    render() {
        const { dashboard, user } = this.props;
        const { period, loading, graphPrevYear } = this.state;

        if (_.isEmpty(dashboard) || _.isEmpty(period)) {
            // Data nie su nacitane
            return this.renderLoading();
        }

        // Datumy pre filter
        const dates = _.reduce(
            dashboard.periods.reverse(),
            (result, date) => ({ ...result, [formatDate(date, 'yyyy-mm')]: formatDate(date, 'mm/yyyy') }),
            {}
        );

        // Rast objednavok za posledne obdobie
        const total_sum_growth = getGrowth(dashboard.stats.total_sum, dashboard.stats.previous.total_sum);

        // Rast priemernej objednavky za posledne obdobie
        const average_sum_growth = dashboard.stats.total_count > 0
            ? getGrowth(dashboard.stats.total_sum / dashboard.stats.total_count, dashboard.stats.previous.total_sum / dashboard.stats.previous.total_count)
            : 0;

        // Rast faktur za posledne obdobie
        const invoices_growth = getGrowth(dashboard.stats.total_invoices, dashboard.stats.previous.total_invoices);

        // Rast zasielok za posledne obdobie
        const packages_growth = getGrowth(dashboard.stats.total_packages, dashboard.stats.previous.total_packages);

        // Rok periody
        const period_year = formatDate(period, 'yyyy');

        // Data grafu aktualny rok
        let graph_data = _.reduce(
            dashboard.stats.days,
            (result, stats, date) => ([ ...result, {
                name: formatDate(date, 'dd'),
                year: formatDate(date, 'yyyy'),
                [formatDate(date, 'yyyy')]: stats.orders_sum,
                ...stats,
            } ]),
            []
        );

        if (_.has(dashboard.stats, 'previous_year')) {
            // Data grafu predosly rok
            const graph_data_prev = _.reduce(
                dashboard.stats.previous_year.days,
                (result, stats, date) => ({ ...result, [formatDate(date, 'dd')] : stats }),
                {}
            );

            graph_data = _.map(graph_data, month => {
                return { ...month, [toNumber(month.year) - 1]: _.has(graph_data_prev, month.name)
                    ? graph_data_prev[month.name].orders_sum
                    : 0 };
            });
        }

        let apps = [];

        if (_.has(dashboard.eshops, dashboard.eshop_id)) {
            // Mame eshop
            const eshopName = dashboard.eshops[dashboard.eshop_id].name;

            apps = [ ...apps, {
                image: `${eshopName.toLowerCase()}.png`,
                name: eshopName,
                background: ESHOP_COLORS[eshopName].background,
            } ];
        }

        if (_.has(dashboard.accountings, dashboard.accounting_id)) {
            // Mame fakturacny system
            const accountingName = dashboard.accountings[dashboard.accounting_id].name;

            apps = [ ...apps, {
                image: `${accountingName.toLowerCase()}.png`,
                name: accountingName,
                background: ACCOUNTING_COLORS[accountingName].background,
            } ];
        }

        if (_.has(dashboard.storages, dashboard.storage_id)) {
            // Mame sklad
            const storageName = dashboard.storages[dashboard.storage_id].name;

            apps = [ ...apps, {
                image: `${storageName.toLowerCase()}.png`,
                name: storageName,
                background: STORAGE_COLORS[storageName].background,
            } ];
        }

        // Prejdeme prepravcov
        _.each(dashboard.user_couriers, courierId => {
            if (_.has(dashboard.couriers, courierId)) {
                // Mame kuriera
                const courierName = dashboard.couriers[courierId];

                apps = [ ...apps, {
                    image: `${courierName.toLowerCase()}.png`,
                    name: courierName,
                    background: COURIER_COLORS[courierName].background,
                } ];
            }
        });

        // Prejdeme banky
        _.each(dashboard.user_banks, (hash, bankId) => {
            if (_.has(dashboard.banks, bankId)) {
                // Mame banku
                const bankName = dashboard.banks[bankId];

                apps = [ ...apps, {
                    image: `${bankName.toLowerCase()}.png`,
                    name: bankName,
                    background: BANK_COLORS[bankName].background,
                } ];
            }
        });

        // Prejdeme platobne brany
        _.each(dashboard.user_gateways, gatewayId => {
            if (_.has(dashboard.gateways, gatewayId)) {
                // Mame branu
                const gatewayName = dashboard.gateways[gatewayId];

                apps = [ ...apps, {
                    image: `${gatewayName.toLowerCase()}.png`,
                    name: gatewayName,
                    background: GATEWAYS_COLORS[gatewayName].background,
                } ];
            }
        });

        // Prejdeme trhoviska
        _.each(dashboard.user_marketplaces, marketplaceId => {
            if (_.has(dashboard.marketplaces, marketplaceId)) {
                // Mame trhovisko
                const marketplaceName = dashboard.marketplaces[marketplaceId];

                apps = [ ...apps, {
                    image: `${marketplaceName.toLowerCase()}.png`,
                    name: marketplaceName,
                    background: MARKETPLACES_COLORS[marketplaceName].background,
                } ];
            }
        });

        const allowExport = _.has(user, 'user_eshop_id') && _.includes([54,112,113,114,115,137], user.user_eshop_id);

        return (
            <div className={`dashboard ${allowExport ? 'dashboard-with-export' : ''}`}>
                <div className="dashboard__header">
                    <div className="dashboard__header__title">{__('Prehľady')}</div>
                    <div className="dashboard__header__right">
                        <Button
                            className="dashboard__header__right__period"
                            color="white"
                            loading={loading}
                        >
                            <PeriodIcon className="dashboard__header__right__period__icon" />
                            <div className="dashboard__header__right__period__content">
                                <div className="dashboard__header__right__period__content__name">{__('Zmeniť obdobie')}</div>
                                <div className="dashboard__header__right__period__content__value">
                                    {formatDate(period, 'mm/yyyy')}
                                </div>
                            </div>
                            <Select
                                options={dates}
                                value={period}
                                onChange={value => this.onChangePeriod(value)}
                                allowEmpty={false}
                            />
                        </Button>
                        {allowExport ? <Button
                            onClick={() => window.open(`${getUrl()}/user-eshops/ebenica/${period}?market=sk&version=2&source=frontend&token=${!_.isUndefined(global.token) ? global.token : ''}`, '_blank')}
                            className="dashboard__header__right__export"
                        >{__('Export')}</Button> : null}
                    </div>
                </div>
                <div className="dashboard__stats">
                    <div className="dashboard__stats__panel">
                        <div className="dashboard__stats__panel__values">
                            <div className="dashboard__stats__panel__values__value">
                                <div className="dashboard__stats__panel__values__value__info">
                                    <div className="dashboard__stats__panel__values__value__info__value">
                                        {formatAmount(dashboard.stats.total_sum, user.settings.currency)}
                                    </div>
                                    <div className="dashboard__stats__panel__values__value__info__name">{__('Suma objednávok')}</div>
                                    <div className="dashboard__stats__panel__values__value__info__growth">
                                        <span
                                            className={total_sum_growth >= 0 ? 'green' : 'red'}
                                        >{total_sum_growth >= 0 ? '+' : ''}{formatAmount(total_sum_growth, '%')}</span> {__('oproti minulému mesiacu')}
                                    </div>
                                </div>
                                <img src={IMAGES['dashboard-orders.png']} alt="objednavky" />
                            </div>
                            <div className="dashboard__stats__panel__values__value">
                                <div className="dashboard__stats__panel__values__value__info">
                                    <div className="dashboard__stats__panel__values__value__info__value">
                                        {dashboard.stats.total_invoices}
                                    </div>
                                    <div className="dashboard__stats__panel__values__value__info__name">{__('Počet faktúr')}</div>
                                    <div className="dashboard__stats__panel__values__value__info__growth">
                                        <span
                                            className={invoices_growth >= 0 ? 'green' : 'red'}
                                        >{invoices_growth >= 0 ? '+' : ''}{formatAmount(invoices_growth, '%')}</span> {__('oproti minulému mesiacu')}
                                    </div>
                                </div>
                                <img src={IMAGES['dashboard-invoices.png']} alt="faktury" />
                            </div>
                            <div className="dashboard__stats__panel__values__value">
                                <div className="dashboard__stats__panel__values__value__info">
                                    <div className="dashboard__stats__panel__values__value__info__value">
                                        {dashboard.stats.total_packages}
                                    </div>
                                    <div className="dashboard__stats__panel__values__value__info__name">{__('Počet zásielok')}</div>
                                    <div className="dashboard__stats__panel__values__value__info__growth">
                                        <span
                                            className={packages_growth >= 0 ? 'green' : 'red'}
                                        >{packages_growth >= 0 ? '+' : ''}{formatAmount(packages_growth, '%')}</span> {__('oproti minulému mesiacu')}
                                    </div>
                                </div>
                                <img src={IMAGES['dashboard-packages.png']} alt="zasielky" />
                            </div>
                            <div className="dashboard__stats__panel__values__value">
                                <div className="dashboard__stats__panel__values__value__info">
                                    <div className="dashboard__stats__panel__values__value__info__value">
                                        {formatAmount(dashboard.stats.total_count > 0
                                            ? dashboard.stats.total_sum / dashboard.stats.total_count
                                            : 0, user.settings.currency)}
                                    </div>
                                    <div className="dashboard__stats__panel__values__value__info__name">{__('Priemerná objednávka')}</div>
                                    <div className="dashboard__stats__panel__values__value__info__growth">
                                        <span
                                            className={average_sum_growth >= 0 ? 'green' : 'red'}
                                        >{average_sum_growth >= 0 ? '+' : ''}{formatAmount(average_sum_growth, '%')}</span> {__('oproti minulému mesiacu')}
                                    </div>
                                </div>
                                <img src={IMAGES['dashboard-order.png']} alt="objednavka" />
                            </div>
                        </div>
                    </div>
                    <div className="dashboard__stats__panel">
                        <div className="dashboard__stats__panel__setup">
                            <div className="dashboard__stats__panel__setup__left">
                                <div className="dashboard__stats__panel__setup__left__header">
                                    <div className="dashboard__stats__panel__setup__left__header__title">{__('Vaše prepojenia')}</div>
                                    {_.isEmpty(apps) ? <div className="dashboard__stats__panel__setup__left__header__text">
                                        {__('Žial zatiaľ nemáte aktívne žiadne prepojenie. Zostavili sme pre vás jednoduchého sprievodcu krokov pri ktorom si ľahko nastavíte prepojenia ktoré potrebujete.')}
                                    </div> : <div className="dashboard__stats__panel__setup__left__header__apps">{_.map(apps, ({ name, image, background }) => (
                                        <div className="dashboard__stats__panel__setup__left__header__apps__app" style={{ backgroundColor: background }}>
                                            <img src={IMAGES[image]} alt={name} />
                                        </div>
                                    ))}</div>}
                                </div>
                                <Button
                                    onClick={() => this.redirect(TUTORIAL_STEPS[1].redirect)}
                                >{!_.isEmpty(apps) ? __('Upraviť') : __('Poďme na to!')}</Button>
                            </div>
                            <div className="dashboard__stats__panel__setup__right">
                                <img src={IMAGES['dashboard-setup.png']} alt="setup" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="dashboard__graph">
                    <div className="dashboard__graph__panel">
                        <div className="dashboard__graph__panel__graph">
                            <div className="dashboard__graph__panel__graph__header">
                                <div className="dashboard__graph__panel__graph__header__title">{__('Objednávky')}</div>
                                <Switch
                                    label={__('Predchádzajúci rok')}
                                    checked={graphPrevYear}
                                    onChange={value => this.onChangeGraphPrevYear(value)}
                                />
                            </div>
                            <ResponsiveContainer>
                                <AreaChart data={graph_data}>
                                    <XAxis dataKey="name" />
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <Tooltip />
                                    <Area type="monotone" dataKey={period_year} stroke="#5c70ff" fill="#EFF1FF" />
                                    {_.has(dashboard.stats, 'previous_year') && graphPrevYear
                                        ? <Area type="monotone" dataKey={toNumber(period_year) - 1} stroke="#459350" fill="#59B867" />
                                        : null}
                                </AreaChart>
                            </ResponsiveContainer>
                        </div>
                    </div>
                    <div className="dashboard__graph__panel">
                        {this.hasPermission('events') ? <div className="dashboard__graph__panel__events">
                            <div className="dashboard__graph__panel__events__header">
                                <div className="dashboard__graph__panel__events__header__title">{__('Udalosti')}</div>
                            </div>
                            <div className="dashboard__graph__panel__events__content">
                                <EventsList items={_.slice(user.events, 0, 2)} />
                            </div>
                            <Button onClick={() => this.showDrawer('events')}>{__('Zobraziť viac')}</Button>
                        </div> : null}
                    </div>
                </div>
                {this.renderDrawer('events', {
                    title: __('Udalosti'),
                    content: props => <EventsList items={user.events} {...props} />,
                })}
            </div>
        );
    }
}

const stateToProps = ({ dashboard, user }) => ({ dashboard, user });

export default withCookies(connect(stateToProps, {
    fetchDashboard,
    setUser,
})(DashboardScreen));
