import React from 'react';
import { connect } from 'react-redux';
import { withCookies } from 'react-cookie';
import _ from 'lodash';
import { IconButton, Tooltip } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import EnterIcon from '@mui/icons-material/Login';
import HelpIcon from '@mui/icons-material/Help';
import { Screen, Button, Input, Select, Checkbox, Message } from '../components';
import { fetchComplaint, setUser, createComplaint, cleanComplaint } from '../actions';
import { __, toNumber, isEmptyString, request, formatAmount } from '../functions';
import { COMPLAINT_TYPES, COMPLAINT_STATUSES } from '../config';
import '../assets/styles/complaint.css';

/**
 * Reklamacia.
 */
class ComplaintScreen extends Screen {
    /**
     * Title.
     *
     * @type {string}
     */
    title = __('Nová reklamácia');

    /**
     * Default state.
     *
     * @type {Object}
     */
    state = {
        data: {},
        number: '',
        order: {},
        loading: false,
        loadingSearch: false,
    };

    /**
     * Komponenta bola pripojena.
     *
     * @return boolean
     */
    async componentDidMount() {
        if (super.componentDidMount()) {
            const { fetchComplaint } = this.props;

            // Vytiahneme id reklamacie
            const complaintId = this.getComplaintId();

            // Nasetujeme title
            this.setTitle(complaintId > 0 ? __('Úprava reklamácie') : __('Nová reklamácia'));

            // Nacitame reklamaciu
            await fetchComplaint(this, complaintId);

            const { complaint } = this.props;

            let data = {
                order_id: null,
                user_id: toNumber(_.keys(complaint.users)[0]),
                status: _.keys(COMPLAINT_STATUSES)[0],
                number: _.has(complaint, 'next_number') ? complaint.next_number : '',
                name: '',
                text: '',
                type: _.keys(COMPLAINT_TYPES)[0],
                end_text: '',
                end_comment: '',
                products: {},
                date: '',
                end_date: '',
            };
            let order = {};
            let number = '';

            if (_.has(complaint, 'data') && !_.isEmpty(complaint.data)) {
                // Mame data z editu
                number = complaint.order.number;
                order = complaint.order;
                data = {
                    order_id: toNumber(complaint.order_id),
                    user_id: toNumber(complaint.user_id),
                    status: complaint.status,
                    number: complaint.data.number,
                    name: complaint.data.name,
                    text: complaint.data.text,
                    type: complaint.data.type,
                    end_text: complaint.data.end_text,
                    end_comment: complaint.data.end_comment,
                    products: complaint.data.products,
                    date: complaint.data.date,
                    end_date: complaint.data.end_date,
                };
            }

            this.setState({ number, data, order });
        }

        return true;
    }

    /**
     * Komponenta bude odpojena.
     */
    componentWillUnmount() {
        const { cleanComplaint } = this.props;

        cleanComplaint();
    }

    /**
     * Vratime id reklamacie.
     *
     * @return {number}
     */
    getComplaintId() {
        const { location } = this.props;

        return toNumber(location.pathname.replace('/complaint/', ''));
    }

    /**
     * Ulozenie.
     */
    save() {
        let { data } = this.state;

        let error = '';

        if (isEmptyString(data.number)) {
            // Nie je vyplnene cislo
            error = __('Nie je vyplnené číslo reklamácie');
        } else if (isEmptyString(data.name)) {
            // Nie je vyplnene cislo
            error = __('Nie je vyplnený predmet');
        } else if (isEmptyString(data.text)) {
            // Nie je vyplnene cislo
            error = __('Nie je vyplnený popis');
        } else if (data.status === 'processed' && isEmptyString(data.end_text)) {
            // Nie je vyplnene cislo
            error = __('Nie je vyplnený spôsob vybavenia');
        } else if (this.isDemo()) {
            error = __('Reklamáciu nie je možné uložiť na DEMO účte');
        }

        if (error !== '') {
            // Mame error
            this.showSnackbar('error', error);
            return;
        }

        this.setState({ loading: true });

        const { createComplaint } = this.props;

        data = { ...data, id: this.getComplaintId() };

        // Ulozime reklamaciu
        createComplaint(this, data);
    }

    /**
     * Event po zmene dat.
     *
     * @param {string} type
     * @param {string|Object} value
     */
    onChange(type, value) {
        const { data } = this.state;

        this.setState({ data: { ...data, [type]: value } });
    }

    /**
     * Event po zmene produkt quantity.
     *
     * @param {number} id
     * @param {number} value
     * @param {number} max
     */
    onChangeProductQuantity(id, value, max) {
        const { data } = this.state;

        this.onChange('products', { ...data.products, [id]: { ...data.products[id], quantity: Math.min(toNumber(value), max) } });
    }

    /**
     * Vyhladanie objednavky.
     */
    search() {
        const { number } = this.state;

        this.setState({ loadingSearch: true });

        request(`/orders/search/${number}`).then(response => {
            const { status, data } = response.data;

            if (status === 'error') {
                this.setState({ loadingSearch: false });
                this.showSnackbar('error', __('Nenašli sme žiadnu objednávku'));
                return;
            }

            this.setState({
                loadingSearch: false,
                order: data,
                data: {
                    ...this.state.data,
                    order_id: data.reference_id,
                    products: _.reduce(data.products, (result, product) => ({ ...result, [product.id]: { ...product, quantity: '' } }), {}),
                },
            });
        });
    }

    /**
     * Rendrovanie.
     *
     * @returns {JSX.Element}
     */
    render() {
        const { complaint } = this.props;
        const { data, loading, number, order, loadingSearch } = this.state;

        if (_.isEmpty(data)) {
            // Data nie su nacitane
            return this.renderLoading();
        }

        if (!this.hasPermission('complaints-edit')) {
            // Nema pravo
            return null;
        }

        // Je to edit?
        const isEdit = this.getComplaintId() > 0;

        // Celkovky pocet ks
        const totalQuantity = _.reduce(data.products, (result, product) => {
            return result + toNumber(product.quantity);
        }, 0);

        // Je disabled?
        const disabled = _.isEmpty(order) || totalQuantity === 0;

        return (
            <div className="complaint">
                <div className="complaint__header">
                    <div className="complaint__header__left">
                        <div className="complaint__header__left__title">
                            {isEdit ? __('Úprava reklamácie') : __('Nová reklamácia')}
                        </div>
                    </div>
                </div>
                <div className="complaint__content">
                    <div className="complaint__content__panels">
                        <div className="complaint__content__panels__panel">
                            <Input
                                placeholder={__('Zadajte číslo objednávky')}
                                value={number}
                                onChange={!isEdit ? value => this.setState({ number: value }) : () => {}}
                                onKeyPress={!isEdit ? value => { if (value === 'Enter') { this.search(); } } : () => {}}
                                icon={<SearchIcon />}
                                rightButton={!isEdit ? <Tooltip title={__('Hľadať')}><IconButton onClick={() => this.search()}><EnterIcon /></IconButton></Tooltip> : null}
                                variant="filled"
                            />
                            {disabled ? <Message type="info">{__('Najprv musíte zvoliť tovar v objednávke')}</Message> : null}
                            {loadingSearch ? this.renderLoading(40) : <div className="complaint__content__panels__panel__products">{_.map(order.products, (product, key) => {
                                if (toNumber(product.quantity) === 0) {
                                    // Prazdne nezobrazujeme
                                    return null;
                                }

                                let productData = {};

                                if (_.has(order.products_data, product.id)) {
                                    productData = {
                                        img: order.products_data[product.id].img_url,
                                        name: `${order.products_data[product.id].name}${!_.isEmpty(product.variant) ? ` - ${product.variant}` : ''}`,
                                    };
                                } else {
                                    productData = {
                                        img: '',
                                        name: product.name,
                                    };
                                }

                                return (
                                    <div className="complaint__content__panels__panel__products__product" key={key}>
                                        <div className="complaint__content__panels__panel__products__product__photo">
                                            {!_.isEmpty(productData.img) ? <img src={productData.img} alt={productData.name} /> : <HelpIcon />}
                                        </div>
                                        <div className="complaint__content__panels__panel__products__product__info">
                                            <div className="complaint__content__panels__panel__products__product__info__name">{productData.name}</div>
                                            <div className="complaint__content__panels__panel__products__product__info__values">
                                                <div>{__('Počet')}: {product.quantity}{`${!_.isEmpty(product.unit) ? ` ${product.unit}` : 'x'}`}</div>
                                                <div>{__('Jednotková cena')}: {formatAmount(product.total_price, order.currency)}</div>
                                                <div>{__('Celková cena')}: {formatAmount(product.total_price * product.quantity, order.currency)}</div>
                                                <div>{__('EAN')}: {product.ean !== '' ? product.ean : ''}</div>
                                            </div>
                                            {!isEdit ? <div className="complaint__content__panels__panel__products__product__info__inputs">
                                                <Input
                                                    placeholder={__('Zadajte počet ks na reklamáciu')}
                                                    value={_.has(data.products, product.id) ? data.products[product.id].quantity : ''}
                                                    onChange={value => this.onChangeProductQuantity(product.id, value, toNumber(product.quantity))}
                                                    type="number"
                                                />
                                            </div> : null}
                                        </div>
                                    </div>
                                );
                            })}</div>}
                        </div>
                        <div className="complaint__content__panels__panel">
                            <Input
                                label={__('Číslo reklamácie')}
                                value={data.number}
                                onChange={value => this.onChange('number', value)}
                            />
                            <Input
                                label={__('Predmet')}
                                value={data.name}
                                onChange={value => this.onChange('name', value)}
                            />
                            <Input
                                label={__('Popis')}
                                value={data.text}
                                onChange={value => this.onChange('text', value)}
                                multiline
                            />
                            <Select
                                label={__('Žiadosť zákaznika na vybavenie')}
                                value={data.type}
                                options={COMPLAINT_TYPES}
                                onChange={value => this.onChange('type', value)}
                                allowEmpty={false}
                            />
                            <Select
                                label={__('Osoba zodpovedná za vybavenie')}
                                value={data.user_id}
                                options={complaint.users}
                                onChange={value => this.onChange('user_id', toNumber(value))}
                            />
                            <Checkbox
                                label={__('Vybavené')}
                                value={data.status === 'processed'}
                                onChange={checked => this.onChange('status', checked ? 'processed' : 'waiting')}
                            />
                            {data.status === 'processed' ? <Input
                                label={__('Spôsob vybavenia')}
                                value={data.end_text}
                                onChange={value => this.onChange('end_text', value)}
                                multiline
                            /> : null}
                            {data.status === 'processed' ? <Input
                                label={__('Vyjadrenie zákaznika k vybaveniu')}
                                value={data.end_comment}
                                onChange={value => this.onChange('end_comment', value)}
                                multiline
                            /> : null}
                        </div>
                    </div>
                    <Button
                        onClick={() => this.save()}
                        loading={loading}
                        className="complaint__content__button"
                        color="green"
                        disabled={disabled}
                    >{__('Uložiť')}</Button>
                </div>
                {this.renderSnackbar()}
            </div>
        );
    }
}

const stateToProps = ({ complaint, user }) => ({ complaint, user });

export default withCookies(connect(stateToProps, {
    fetchComplaint,
    setUser,
    createComplaint,
    cleanComplaint,
})(ComplaintScreen));
