import { CLEAN_COMPLAINT, FETCH_COMPLAINT } from './types';
import { __, request, toNumber } from '../functions';

/**
 * Edit reklamacie.
 *
 * @param {Screen} screen
 * @param {number} id
 */
export const fetchComplaint = (screen, id) => {
    return dispatch => request(`/complaints/view/${id}`).then(response => {
        const { data, sync } = response.data;
        const { setUser } = screen.props;

        // Nasetujeme user data
        setUser(sync.user);

        dispatch({ type: FETCH_COMPLAINT, payload: data });
    });
};

/**
 * Vytvorime reklamacie.
 *
 * @param {Screen} screen
 * @param {Object} data
 */
export const createComplaint = (screen, data) => {
    const edit = toNumber(data.id) > 0;

    return dispatch => request(`/complaints/${edit ? 'edit' : 'create'}`, data, 'POST').then(response => {
        const { status } = response.data;

        screen.setState({ loading: false });

        if (status === 'error') {
            // Nepodarilo sa ulozit reklamaciu
            screen.showSnackbar('error', __('Nepodarilo sa uložiť reklamáciu'));
            return;
        }

        window.location = '/complaints';
    });
};

/**
 * Vycistenie reklamacie.
 */
export const cleanComplaint = () => ({ type: CLEAN_COMPLAINT });
