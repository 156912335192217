import _ from 'lodash';
import {FETCH_MTOKNA_VIDEOS, CLEAN_MTOKNA_VIDEOS, DELETE_MTOKNA_VIDEO} from './types';
import { request, __ } from '../functions';

/**
 * Nacitanie sablon.
 *
 * @param {Screen} screen
 * @param {object} options
 */
export const fetchMtoknaVideos = (screen, options = {}) => {
    const defaults = {
        page: 1,
        per_page: 0,
        filtered: {},
    };

    options = { ...defaults, ...options };

    let params = { page: options.page, per_page: options.per_page };

    // Nasetujeme filter
    _.forEach(options.filtered, (value, key) => {
        params = { ...params, ...{ [`filter_${key}`]: value } };
    });

    return dispatch => request('/mtokna/videos', params).then(response => {
        const { data, sync } = response.data;
        const { setUser } = screen.props;

        // Nasetujeme user data
        setUser(sync.user);

        dispatch({ type: FETCH_MTOKNA_VIDEOS, payload: data });
    });
};

export const deleteMtoknaVideo = (screen, id) => {
    return dispatch => request(`/mtokna/removeVideo/${id}`).then(response => {
        const { status } = response.data;

        if (status === 'error') {
            screen.showSnackbar('error', __('Nepodarilo sa zmazať súbor'));
            return;
        }

        screen.showSnackbar('success', __('Súbor bol zmazaný'));

        dispatch({ type: DELETE_MTOKNA_VIDEO });
    });
};

/**
 * Vycistenie sablon.
 */
export const cleanMtoknaVideos = () => ({ type: CLEAN_MTOKNA_VIDEOS });
