import {
    FETCH_RAINPRO_PICKERS,
    CLEAN_RAINPRO_PICKERS,
    CHANGE_RAINPRO_PICKER_EMAIL,
    CHANGE_RAINPRO_PICKER_SETTINGS,
} from '../actions/types';
import _ from 'lodash';
import { toNumber } from '../functions';

const RAINPRO_PICKERS_DEFAULT_STATE = {};

const RainproPickersReducer = (state = RAINPRO_PICKERS_DEFAULT_STATE, { type, payload }) => {
    switch (type) {
        case FETCH_RAINPRO_PICKERS:
            return payload;

        case CHANGE_RAINPRO_PICKER_EMAIL:
            return { ...state, items: _.map(state.items, item => {
                if (toNumber(item.user_id) === toNumber(payload.id)) {
                    item = { ...item, _matchingData: {
                        ...item._matchingData,
                        Users: { ...item._matchingData.Users, email: payload.email }
                    } };
                }

                return item;
            }) };

        case CHANGE_RAINPRO_PICKER_SETTINGS:
            return { ...state, items: _.map(state.items, item => {
                if (toNumber(item.user_id) === toNumber(payload.id)) {
                    item = { ...item, settings: payload.settings };
                }

                return item;
            }) };

        case CLEAN_RAINPRO_PICKERS:
            return RAINPRO_PICKERS_DEFAULT_STATE;

        default:
            return state;
    }
};

export default RainproPickersReducer;
