import React from 'react';
import { connect } from 'react-redux';
import { withCookies } from 'react-cookie';
import _ from 'lodash';
import { OrderPage, Screen } from '../components';
import { request } from '../functions';
import '../assets/styles/order-public.css';

/**
 * Verejna stranka objednavky.
 */
class OrderPublic extends Screen {
    /**
     * Default state.
     *
     * @type {Object}
     */
    state = {
        data: {},
    };

    /**
     * Komponenta bola pripojena.
     *
     * @return boolean
     */
    componentDidMount() {
        // Nacitame data
        const { params } = this.props;

        request(`/track-trace/view/${params.id}/${params.token}/`).then(response => {
            const { data } = response.data;

            this.setState({ data });
        });
    }

    /**
     * Rendrovanie.
     *
     * @returns {JSX.Element}
     */
    render() {
        const { data } = this.state;

        if (_.isEmpty(data)) {
            return this.renderLoading();
        }

        return (
            <div className="order-public">
                <OrderPage data={data} />
                {this.renderSnackbar()}
            </div>
        );
    }
}

const stateToProps = ({ user }) => ({ user });

export default withCookies(connect(stateToProps)(OrderPublic));
