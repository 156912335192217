import { FETCH_MARKETPLACE_PRODUCTS, CLEAN_MARKETPLACE_PRODUCTS, SAVE_MARKETPLACE_PRODUCTS } from '../actions/types';

const MARKETPLACE_PRODUCTS_DEFAULT_STATE = {};

/**
 * Reducer pre marketplace produkty.
 */
const MarketplaceProductsReducer = (state = MARKETPLACE_PRODUCTS_DEFAULT_STATE, { type, payload }) => {
    switch (type) {
        case FETCH_MARKETPLACE_PRODUCTS:
            // Nacitanie produktov
            return payload;

        case CLEAN_MARKETPLACE_PRODUCTS:
            // Vycistenie produktov
            return MARKETPLACE_PRODUCTS_DEFAULT_STATE;

        case SAVE_MARKETPLACE_PRODUCTS:
            // Ulozime marketplaces produkty
            return { ...state, marketplace_products_sync: payload };

        default:
            return state;
    }
};

export default MarketplaceProductsReducer;
