import _ from 'lodash';
import {
    FETCH_ADERYN_PRODUCT_LOGS,
    CLEAN_ADERYN_PRODUCT_LOGS,
} from './types';
import { request, __ } from '../functions';

export const fetchAderynProductLogs = (screen, options = {}, id) => {
    const defaults = {
        page: 1,
        per_page: 0,
        filtered: {},
    };

    options = { ...defaults, ...options };

    let params = { page: options.page, per_page: options.per_page };

    // Nasetujeme filter
    _.forEach(options.filtered, (value, key) => {
        params = { ...params, ...{ [`filter_${key}`]: value } };
    });

    return dispatch => request(`/aderyn/logs/${id}`, params).then(response => {
        const { data, sync } = response.data;
        const { setUser } = screen.props;

        // Nasetujeme user data
        setUser(sync.user);

        dispatch({ type: FETCH_ADERYN_PRODUCT_LOGS, payload: data });
    });
};

export const cleanAderynProductLogs = () => ({ type: CLEAN_ADERYN_PRODUCT_LOGS });
