import React from 'react';
import { BrowserRouter as Router, useLocation, useNavigate, useParams } from 'react-router-dom';
import { Provider } from 'react-redux';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Menu from './components/Menu';
import Content from './components/Content';
import './assets/styles/app.css';

global.language = 'sk';

const theme = createTheme({
    typography: {
        fontFamily: 'Poppins, sans-serif',
        useNextVariants: true,
    },
    palette: {
        primary: {
            main: '#5c70ff',
            contrastText: '#fff',
        },
        secondary: {
            main: '#459350',
            contrastText: '#fff',
        },
        error: {
            main: '#c24646',
            contrastText: '#fff',
        },
        orange: {
            main: '#ff9931',
            contrastText: '#fff',
        },
    },
});

const Body = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const params = useParams();

    return (
        <div>
            <Menu location={location} navigate={navigate} params={params} />
            <Content location={location} navigate={navigate} params={params} />
        </div>
    );
};

const App = ({ store }) => {
    return (
        <Provider store={store}>
            <ThemeProvider theme={theme}>
                <Router>
                    <Body />
                </Router>
            </ThemeProvider>
        </Provider>
    );
};

export default App;
