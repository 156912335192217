import _ from 'lodash';
import { toNumber } from '../functions';
import {
    FETCH_RAINPRO_ORDERS,
    CLEAN_RAINPRO_ORDERS,
    SYNC_RAINPRO_ORDER,
    EDIT_RAINPRO_ORDER,
    PICKER_RAINPRO_ORDER,
    SEND_RAINPRO_ORDERS,
} from '../actions/types';

const RAINPRO_ORDERS_DEFAULT_STATE = {};

/**
 * Reducer pre objednavky.
 */
const RainproOrdersReducer = (state = RAINPRO_ORDERS_DEFAULT_STATE, { type, payload }) => {
    switch (type) {
        case FETCH_RAINPRO_ORDERS:
            return payload;

        case CLEAN_RAINPRO_ORDERS:
            return RAINPRO_ORDERS_DEFAULT_STATE;

        case EDIT_RAINPRO_ORDER:
            // Editacia objednavky
            return { ...state, items: _.map(state.items, item => {
                if (toNumber(item.id) === toNumber(payload.id)) {
                    // Nasli sme objednavku
                    item = { ...item, data: { ...item.data, ...payload.data } };
                }

                return item;
            }) };

        case SYNC_RAINPRO_ORDER:
            return { ...state, items: _.map(state.items, item => {
                if (!_.isEmpty(payload[toNumber(item.id)])) {
                    // Nasli sme objednavku zmenime data
                    item = { ...item, data: payload[toNumber(item.id)] };
                }

                return item;
            }) };

        case PICKER_RAINPRO_ORDER:
            return { ...state, items: _.map(state.items, item => {
                    if (toNumber(item.id) === toNumber(payload.id)) {
                        // Nasli sme objednavku zmenime data
                        item = { ...item, picker_orders: payload.items };
                    }

                    return item;
                }) };

        case SEND_RAINPRO_ORDERS:
            const { packages, datas } = payload;

            return { ...state, items: _.map(state.items, item => {
                    if (!_.isEmpty(packages[toNumber(item.id)])) {
                        // Nasli sme objednavku nastavime zasielku
                        item = { ...item, _matchingData: { ...item._matchingData, RainproOrders: { ...item._matchingData.RainproOrders, package: packages[toNumber(item.id)] } } };
                    }

                    if (!_.isEmpty(datas[toNumber(item.id)])) {
                        // Nasli sme objednavku nastavime data
                        item = { ...item, data: datas[toNumber(item.id)] };
                    }

                    return item;
                }) };

        default:
            return state;
    }
};

export default RainproOrdersReducer;
