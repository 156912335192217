import React from 'react';
import { connect } from 'react-redux';
import { withCookies } from 'react-cookie';
import _ from 'lodash';
import {Input, ListScreen} from '../components';
import {
    fetchCabProducts,
    cleanCabProducts,
    setUser,
    changeUserSetting,
    addCabUpGatesProduct,
    removeCabUpGatesProduct,
    addCabAllegroProduct,
    removeCabAllegroProduct,
    addCabMallProduct,
    removeCabMallProduct,
    changeCabUpGatesProductPrice,
    changeCabMarketplaceProductPrice,
} from '../actions';
import {__, toNumber} from '../functions';
import '../assets/styles/cab_products.css';
import EditIcon from "@mui/icons-material/Edit";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import {ButtonBase} from "@mui/material";

/**
 * Klienti.
 */
class CabProductsScreen extends ListScreen {
    /**
     * Title.
     *
     * @type {string}
     */
    title = __('Produkty');

    /**
     * Default state.
     *
     * @type {Object}
     */
    state = { ...ListScreen.state, ...{
        lightbox: {
            edit: false,
            price: false,
        },
    }};

    /**
     * Memory cache.
     *
     * @type {Object}
     */
    memoryCache = {};

    /**
     * Vratime nazov tabulky.
     *
     * @return {string}
     */
    getTableName() {
        return 'cab_products';
    }

    /**
     * Vratime zoznam stlpcov.
     *
     * @return {Array}
     */
    getColumns() {
        return [
            <div>{__('SKU')}</div>,
            <div>{__('Upgates')}</div>,
            <div>{__('Allegro')}</div>,
            <div>{__('Mall')}</div>,
            <div></div>,
        ];
    }

    /**
     * Vratime bunky.
     *
     * @param {Object} item
     *
     * @return {Array}
     */
    getCells(item) {
        let eshops = 0;
        let allegro = 0;
        let mall = 0;

        _.each(item.data, source => {
            if (source.type === 'upgates') {
                eshops++;
            } else if (source.type === 'allegro') {
                allegro++;
            } else {
                mall++;
            }
        });

        return [
            <div>{item.sku}</div>,
            <div>{eshops}</div>,
            <div>{allegro}</div>,
            <div>{mall}</div>,
            <div>
                {this.renderTableButton(
                    item.id,
                    __('Cena - UpGates'),
                    <AttachMoneyIcon />,
                    (callbackLoading, callback) => this.confirmPrice(item.id, callbackLoading, callback, {
                        loading: false,
                        sku: item.sku,
                        price: '',
                        type: 'upgates',
                    }),
                    {}
                )}
                {this.renderTableButton(
                    item.id,
                    __('Cena - Mall,Allegro'),
                    <AttachMoneyIcon />,
                    (callbackLoading, callback) => this.confirmPrice(item.id, callbackLoading, callback, {
                        loading: false,
                        sku: item.sku,
                        price: '',
                        type: 'marketplace',
                    }),
                    {}
                )}
                {this.renderTableButton(
                    item.id,
                    __('Upraviť'),
                    <EditIcon />,
                    (callbackLoading, callback) => this.confirmEdit(item.id, callbackLoading, callback, {
                        loading: false,
                        sku: item.sku,
                        data: item.data,
                        allegro: {},
                    }),
                    {}
                )}
            </div>,
        ];
    }

    /**
     * Vratime filter.
     *
     * @return {Object}
     */
    getFilter() {
        const { items } = this.props;

        return _.reduce(items.filter, (result, options, key) => {
            switch (key) {
                case 'sku':
                    return { ...result, ...{ [key]: {
                        type: 'input',
                        name: __('SKU'),
                        value: '',
                    }}};

                default:
                    return result;
            }
        }, {});
    }

    /**
     * Vratime empty text.
     *
     * @return {string}
     */
    getEmptyText() {
        return __('Zatiaľ nemáte žiadne produkty');
    }

    /**
     * Event po zmene hesla.
     *
     * @param {string} key
     * @param {string} value
     */
    onChangeEdit(key, value) {
        const { lightbox } = this.state;

        this.setState({ lightbox: { ...lightbox, edit: {
            ...lightbox.edit,
            data: { ...lightbox.edit.data, [key]: value }
        } } });
    }

    /**
     * Event po zmene hesla.
     *
     * @param {string} key
     * @param {string} value
     */
    onChangePrice(key, value) {
        const { lightbox } = this.state;

        this.setState({ lightbox: { ...lightbox, price: {
                    ...lightbox.price,
                    data: { ...lightbox.price.data, [key]: value }
                } } });
    }

    /**
     * Zobrazime lightbox na email.
     *
     * @param {number} id
     * @param {function} callbackLoading
     * @param {function} callback
     * @param {Object} data
     */
    confirmEdit(id, callbackLoading, callback, data) {
        this.showLightbox('edit', { id, callbackLoading, callback, data });
    }

    /**
     * Zobrazime lightbox na email.
     *
     * @param {number} id
     * @param {function} callbackLoading
     * @param {function} callback
     * @param {Object} data
     */
    confirmPrice(id, callbackLoading, callback, data) {
        this.showLightbox('price', { id, callbackLoading, callback, data });
    }

    savePrice() {
        const { changeCabUpGatesProductPrice, changeCabMarketplaceProductPrice } = this.props;

        const { lightbox } = this.state;

        this.onChangePrice('loading', true);

        if (lightbox.price.data.type === 'marketplace') {
            changeCabMarketplaceProductPrice(this, lightbox.price.data.sku, lightbox.price.data.price);
            return;
        }

        changeCabUpGatesProductPrice(this, lightbox.price.data.sku, lightbox.price.data.price);
    }

    addProduct(type, user_eshop_id) {
        const { addCabUpGatesProduct, addCabAllegroProduct, addCabMallProduct } = this.props;
        const { lightbox } = this.state;

        this.onChangeEdit('loading', true);

        switch (type) {
            case 'upgates':
                addCabUpGatesProduct(this, lightbox.edit.data.sku, user_eshop_id);
                break;

            case 'allegro':
                addCabAllegroProduct(
                    this,
                    lightbox.edit.data.sku,
                    user_eshop_id,
                    _.has(lightbox.edit.data.allegro, user_eshop_id) ? lightbox.edit.data.allegro[user_eshop_id] : ''
                );
                break;

            case 'mall':
                addCabMallProduct(this, lightbox.edit.data.sku, user_eshop_id);
                break;
        }
    }

    removeProduct(type, user_eshop_id) {
        const { removeCabUpGatesProduct, removeCabAllegroProduct, removeCabMallProduct } = this.props;
        const { lightbox } = this.state;

        this.onChangeEdit('loading', true);

        switch (type) {
            case 'upgates':
                removeCabUpGatesProduct(this, lightbox.edit.data.sku, user_eshop_id);
                break;

            case 'allegro':
                removeCabAllegroProduct(this, lightbox.edit.data.sku, user_eshop_id);
                break;

            case 'mall':
                removeCabMallProduct(this, lightbox.edit.data.sku, user_eshop_id);
                break;
        }
    }

    onChangeAllegroCategoryId(user_eshop_id, value) {
        const { lightbox } = this.state;

        let allegro = lightbox.edit.data.allegro;
        allegro = { ...allegro, [user_eshop_id]: value };

        this.onChangeEdit('allegro', allegro);
    }

    /**
     * Rendrujeme screen.
     *
     * @return {JSX.Element|null}
     */
    renderScreen() {
        const { items, user } = this.props;
        const { lightbox } = this.state;

        return (
            <div>
                {this.renderLightbox(
                    'price',
                    __('Cena'),
                    !_.isEmpty(lightbox.price) ? (lightbox.price.data.loading ? this.renderLoading(20) : <div>
                        <div>
                            <Input
                                label={__('Cena')}
                                value={lightbox.price.data.price}
                                onChange={value => this.onChangePrice('price', value)}
                            />
                        </div>
                    </div>) : null,
                    __('Uložiť'),
                    __('Zrušiť'),
                    () => this.savePrice()
                )}
                {this.renderLightbox(
                    'edit',
                    __('Úprava'),
                    !_.isEmpty(lightbox.edit) ? (lightbox.edit.data.loading ? this.renderLoading(20) : <div className="cab-products-lightbox">
                        {_.map(items.user_eshop_ids, user_eshop_id => {
                            let active = false;

                            _.each(lightbox.edit.data.data, source => {
                                if (source.type === 'upgates' && toNumber(source.user_eshop_id) === toNumber(user_eshop_id)) {
                                    active = true;
                                }
                            });

                            return (
                                <div className="cab-products-lightbox__item" key={user_eshop_id}>
                                    <div className="cab-products-lightbox__item__name">UpGates - {user.user_eshops[user_eshop_id]}</div>
                                    {!active
                                        ? <ButtonBase
                                            onClick={() => this.addProduct('upgates', user_eshop_id)}
                                            className="add-button"
                                        >{__('Pridať')}</ButtonBase>
                                        : <ButtonBase
                                            onClick={() => this.removeProduct('upgates', user_eshop_id)}
                                            className="remove-button"
                                        >{__('Zmazať')}</ButtonBase>}
                                </div>
                            );
                        })}
                        {_.map(items.user_marketplaces.allegro, user_eshop_id => {
                            let active = false;

                            _.each(lightbox.edit.data.data, source => {
                                if (source.type === 'allegro' && toNumber(source.user_eshop_id) === toNumber(user_eshop_id)) {
                                    active = true;
                                }
                            });

                            return (
                                <div className="cab-products-lightbox__item" key={user_eshop_id}>
                                    <div className="cab-products-lightbox__item__name">Allegro - {user.user_eshops[user_eshop_id]}</div>
                                    <div className="cab-products-lightbox__item__right">
                                        {!active ? <Input
                                            placeholder={__('ID kategórie')}
                                            value={_.has(lightbox.edit.data.allegro, user_eshop_id) ? lightbox.edit.data.allegro[user_eshop_id] : ''}
                                            onChange={value => this.onChangeAllegroCategoryId(user_eshop_id, value)}
                                        /> : null}
                                        {!active
                                            ? <ButtonBase
                                                onClick={() => this.addProduct('allegro', user_eshop_id)}
                                                className="add-button"
                                            >{__('Pridať')}</ButtonBase>
                                            : <ButtonBase
                                                onClick={() => this.removeProduct('allegro', user_eshop_id)}
                                                className="remove-button"
                                            >{__('Zmazať')}</ButtonBase>}
                                    </div>
                                </div>
                            );
                        })}
                        {_.map(items.user_marketplaces.mall, user_eshop_id => {
                            let active = false;

                            _.each(lightbox.edit.data.data, source => {
                                if (source.type === 'mall' && toNumber(source.user_eshop_id) === toNumber(user_eshop_id)) {
                                    active = true;
                                }
                            });

                            return (
                                <div className="cab-products-lightbox__item" key={user_eshop_id}>
                                    <div className="cab-products-lightbox__item__name">Mall - {user.user_eshops[user_eshop_id]}</div>
                                    {!active
                                        ? <ButtonBase
                                            onClick={() => this.addProduct('mall', user_eshop_id)}
                                            className="add-button"
                                        >{__('Pridať')}</ButtonBase>
                                        : <ButtonBase
                                            onClick={() => this.removeProduct('mall', user_eshop_id)}
                                            className="remove-button"
                                        >{__('Zmazať')}</ButtonBase>}
                                </div>
                            );
                        })}
                    </div>) : null,
                    '',
                    __('Zrušiť'),
                )}
            </div>
        );
    }
}

const stateToProps = ({ cab_products, user }) => ({ items: cab_products, user });

export default withCookies(connect(stateToProps, {
    fetch: fetchCabProducts,
    clean: cleanCabProducts,
    setUser,
    changeUserSetting,
    addCabUpGatesProduct,
    removeCabUpGatesProduct,
    addCabAllegroProduct,
    removeCabAllegroProduct,
    addCabMallProduct,
    removeCabMallProduct,
    changeCabUpGatesProductPrice,
    changeCabMarketplaceProductPrice,
})(CabProductsScreen));
