import React from 'react';
import _ from 'lodash';
import {
    TableContainer,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    IconButton,
    Tooltip,
} from '@mui/material';
import PdfIcon from '@mui/icons-material/PictureAsPdf';
import { Button, Navigate, Message, ContentPart, Input, Select } from '../components';
import { __, toNumber, formatDate, request, formatAmount, isEmptyString, isValidZip } from '../functions';
import { MARKET, PRICE, PRICE_CZ, PRICE_HU, PRICE_PL } from '../config';
import '../assets/styles/subscription.css';

/**
 * Subscription komponenta.
 */
class Subscription extends Navigate {
    /**
     * Default props.
     *
     * @type {Object}
     */
    static defaultProps = {
        user: {},
    };

    /**
     * Default state.
     *
     * @type {Object}
     */
    state = {
        loading: false,
        payments: null,
        loadingPdf: null,
        section: 'payments',
        settings: {},
        year: 1,
    };

    /**
     * Komponenta bola pripojena.
     */
    componentDidMount() {
        this.fetch();
    }

    /**
     * Nacitame data.
     */
    fetch() {
        request('/subscriptions').then(({ data }) => {
            let settings = data.data.settings.invoicing;

            if (!_.has(settings, 'country')) {
                // Nema krajinu
                settings = { ...settings, country: MARKET };
            }

            this.setState({ payments: data.data, settings });
        });
    }

    /**
     * Stiahnutie pdf.
     *
     * @param {number} id
     */
    pdf(id) {
        // Nastavime button loading
        this.setState({ loadingPdf: id });

        request(`/subscriptions/pdf/${id}`).then(response => {
            const { status, data } = response.data;

            this.setState({ loadingPdf: null });

            if (status === 'error') {
                this.showSnackbar('error', __('Nepodarilo sa stiahnuť pdf'));
                return;
            }

            // Stiahneme subor
            window.location = data.link;
        });
    }

    /**
     * Event po zmene sekcie.
     *
     * @param {string} section
     */
    onChangeSection(section) {
        this.setState({ section });
    }

    /**
     * Event po zmene roku.
     *
     * @param {number} year
     */
    onChangeYear(year) {
        this.setState({ year });
    }

    /**
     * Event po zmene settingu.
     *
     * @param {string} type
     * @param {string} value
     */
    onChangeSetting(type, value) {
        const { settings } = this.state;

        this.setState({ settings: { ...settings, [type]: value } });
    }

    /**
     * Kupa.
     */
    buy() {
        const { setUser } = this.props;
        const { settings, year } = this.state;

        let error = '';

        if (isEmptyString(settings.name)) {
            // Nie je vyplneny nazov
            error = __('Nie je vyplnený názov');
        } else if (isEmptyString(settings.address)) {
            // Nie je vyplnena adresa
            error = __('Nie je vyplnená adresa');
        } else if (isEmptyString(settings.city)) {
            // Nie je vyplnene mesto
            error = __('Nie je vyplnené mesto');
        } else if (!isValidZip(settings.zip)) {
            // Neplatne PSC
            error = __('Neplatné PSČ');
        }

        if (error !== '') {
            this.showSnackbar('error', error);
            return null;
        }

        this.setState({ loading: true });

        request(`/subscriptions/createProforma/${year}`, { settings }, 'POST').then(response => {
            const { status, data, sync } = response.data;

            if (status === 'error') {
                // Nepodarilo sa generovat
                this.showSnackbar('error', __('Nepodarilo sa vygenerovať faktúru'));
                this.setState({ loading: false });
                return;
            }

            // Nasetujeme user data
            setUser(sync.user);

            // Stiahneme fakturu
            request(`/subscriptions/pdf/${data.id}`).then(response => {
                const { status, data } = response.data;

                if (status === 'error') {
                    // Nepodarilo sa stiahnut pdf
                    this.showSnackbar('error', __('Nepodarilo sa stiahnuť pdf'));
                    this.setState({ loading: false });
                    return;
                }

                // Stiahneme subor
                window.location = data.link;

                this.setState({ loading: false, section: 'payments', payments: null });

                // Nacitame platby nanovo
                this.fetch();
            });
        });
    }

    /**
     * Vratime menu na zaklade trhu.
     *
     * @param {string} market
     *
     * @return {string}
     */
    getCurrency(market) {
        switch (market) {
            case 'cz':
                return 'CZK';

            case 'pl':
                return 'PLN';

            case 'hu':
                return 'HUF';

            default:
                return 'EUR';
        }
    }

    /**
     * Rendrujeme platby.
     *
     * @return {JSX.Element}
     */
    renderPayments() {
        const { payments, loadingPdf } = this.state;

        return (
            <TableContainer className="subscription-content__data__payments__table">
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>{__('Dátum')}</TableCell>
                            <TableCell>{__('Suma')}</TableCell>
                            <TableCell>{__('Popis')}</TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {_.map(payments.payments, (payment, key) => {
                            let name = payment.type === 'month' ? __('Mesačné členstvo') : __('Ročné členstvo');
                            name = `${name} (${payment.orders} ${__('objednávok')})`;

                            return (
                                <TableRow key={key}>
                                    <TableCell>{formatDate(payment.created, 'dd.mm.yyyy hh:ii')}</TableCell>
                                    <TableCell>{formatAmount(payment.amount, this.getCurrency(payment.market))}</TableCell>
                                    <TableCell>{name}</TableCell>
                                    <TableCell>
                                        <Tooltip title={payment.status === 'waiting'
                                            ? __('V najbližšom čase bude vystavená faktúra')
                                            : (payment.status === 'expecting' ? __('Čaká na úhradu') : __('Stiahnuť PDF'))}>
                                            <IconButton
                                                onClick={payment.status === 'waiting'
                                                    ? () => {}
                                                    : () => this.pdf(payment.id)}
                                            >
                                                {loadingPdf === payment.id
                                                    ? this.renderLoading(20)
                                                    : <PdfIcon color={payment.status === 'waiting' || payment.status === 'expecting'
                                                        ? 'orange'
                                                        : 'inherit'} />}
                                            </IconButton>
                                        </Tooltip>
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        );
    }

    /**
     * Rendrovanie.
     *
     * @return {JSX.Element}
     */
    render() {
        const { user } = this.props;
        const { payments, section, settings, year, loading } = this.state;

        if (payments === null) {
            // Data nie su nacitane
            return <div
                className="subscription-content subscription-content-loading"
            >{this.renderLoading()}</div>;
        }

        // User ma posledne dni clenstva
        const hasLastSubscriptionsDays = _.has(user, 'subscription_days')
            && user.subscription_days !== ''
            && toNumber(user.subscription_days) <= 5;

        // Vytiahneme cenu
        const price = settings.country === 'cz'
            ? PRICE_CZ
            : (settings.country === 'pl'
                ? PRICE_PL
                : (settings.country === 'hu' ? PRICE_HU : PRICE));

        // Vytiahneme zlavu
        const discountPercent = (year - 1) * 10;

        // Cena za rok bez zlavu
        const priceYearWithoutDiscount = price * 12 * year;

        // Cena za rok
        let priceYear = (price * 12 - (price * 12 * (discountPercent / 100))) * year;

        if (toNumber(payments.discount) > 0) {
            // Zlava
            priceYear = priceYear - (priceYear * (payments.discount / 100));
        }

        // Cena za mesiac
        const priceMonth = priceYear / (12 * year);

        // Mena
        const currency = this.getCurrency(settings.country);

        return (
            <div className="subscription-content">
                {section === 'payments' ? <div className="subscription-content__data">
                    <Message
                        type={user.subscription ? (hasLastSubscriptionsDays ? 'warning' : 'success') : 'error'}
                    >{user.subscription
                        ? (hasLastSubscriptionsDays
                            ? __('Vaše členstvo skončí o {DAYS} {DAYS_TEXT}')
                                .replace('{DAYS}', user.subscription_days)
                                .replace('{DAYS_TEXT}', toNumber(user.subscription_days) === 1 ? __('deň') : __('dní'))
                            : __('Vaše členstvo je aktívne a končí {END}')
                                .replace('{END}', formatDate(user.subscription_end, 'dd.mm.yyyy')))
                        : __('Vaše členstvo je neaktívne')}</Message>
                    <ContentPart title={__('Platby')} />
                    <div className="subscription-content__data__payments">
                        {_.isEmpty(payments.payments)
                            ? <div className="subscription-content__data__payments__empty">{__('Zatiaľ nemáte žiadne platby')}</div>
                            : this.renderPayments()}
                    </div>
                </div> : <div className="subscription-content__data">
                    <div className="subscription-content__data__title">{__('Fakturačné údaje')}</div>
                    <div className="subscription-content__data__panels">
                        <div className="subscription-content__data__panels__panel">
                            <Input
                                label={__('Adresa')}
                                value={settings.address}
                                onChange={value => this.onChangeSetting('address', value)}
                            />
                            <Input
                                label={__('Mesto')}
                                value={settings.city}
                                onChange={value => this.onChangeSetting('city', value)}
                            />
                            <Input
                                label={__('PSČ')}
                                value={settings.zip}
                                onChange={value => this.onChangeSetting('zip', value)}
                            />
                            <Select
                                label={__('Krajina')}
                                value={settings.country}
                                options={{ sk: 'sk', cz: 'cz', pl: 'pl', hu: 'hu' }}
                                onChange={value => this.onChangeSetting('country', value)}
                                allowEmpty={false}
                            />
                        </div>
                        <div className="subscription-content__data__panels__panel">
                            <Input
                                label={__('Názov')}
                                value={settings.name}
                                onChange={value => this.onChangeSetting('name', value)}
                            />
                            <Input
                                label={__('IČO')}
                                value={settings.ico}
                                onChange={value => this.onChangeSetting('ico', value)}
                            />
                            <Input
                                label={__('DIČ')}
                                value={settings.dic}
                                onChange={value => this.onChangeSetting('dic', value)}
                            />
                            {settings.country === 'sk' ? <Input
                                label={__('IČ DPH')}
                                value={settings.ic_dph}
                                onChange={value => this.onChangeSetting('ic_dph', value)}
                            /> : null}
                        </div>
                    </div>
                </div>}
                <div className="subscription-content__button">
                    {section === 'payment' ? <div className="subscription-content__button__summary">
                        <Message type="info">{__('Za každý ďalší rok navyše máte 10% zľavu')}</Message>
                        <div className="subscription-content__button__summary__line">
                            <div className="subscription-content__button__summary__line__name">{__('Obdobie')}:</div>
                            <div className="subscription-content__button__summary__line__value">
                                <Select
                                    value={year}
                                    options={{
                                        1: __('1 rok'),
                                        2:  __('2 roky'),
                                        3:  __('3 roky'),
                                        4:  __('4 roky'),
                                        5:  __('5 rokov'),
                                    }}
                                    onChange={value => this.onChangeYear(value)}
                                    allowEmpty={false}
                                />
                            </div>
                        </div>
                        <div className="subscription-content__button__summary__line">
                            <div className="subscription-content__button__summary__line__name">{__('Cena za mesiac')}:</div>
                            <div className="subscription-content__button__summary__line__value">{formatAmount(priceMonth, currency)}</div>
                        </div>
                        {toNumber(payments.discount) > 0 ? <div className="subscription-content__button__summary__line">
                            <div className="subscription-content__button__summary__line__name">{__('Zľava od partnera')}:</div>
                            <div className="subscription-content__button__summary__line__value">-{formatAmount(priceYear * (toNumber(payments.discount) / 100), currency)}</div>
                        </div> : null}
                        {year > 1 ? <div className="subscription-content__button__summary__line">
                            <div className="subscription-content__button__summary__line__name">{__('Zľava')}:</div>
                            <div className="subscription-content__button__summary__line__value">-{formatAmount(priceYearWithoutDiscount - priceYear, currency)}</div>
                        </div> : null}
                        <div className="subscription-content__button__summary__line">
                            <div className="subscription-content__button__summary__line__name">{__('Celková cena')}:</div>
                            <div className="subscription-content__button__summary__line__value">{formatAmount(priceYear, currency)}</div>
                        </div>
                    </div> : null}
                    {section === 'payment' && payments.expend_date !== null
                        ? <Message type="info">{__('Po vygenerovaní faktúry vám predlžíme členstvo do {DATE} aby ste mali čas na úhradu faktúry').replace('{DATE}', formatDate(payments.expend_date, 'dd.mm.yyyy'))}</Message>
                        : null}
                    <Button
                        onClick={section === 'payments'
                            ? () => this.onChangeSection('payment')
                            : () => this.buy()}
                        color={section === 'payments' ? 'primary' : 'green'}
                        loading={loading}
                    >{section === 'payments' ? __('Kúpiť členstvo') : __('Vygenerovať faktúru')}</Button>
                </div>
                {this.renderSnackbar()}
            </div>
        );
    }
}

export { Subscription };
