import _ from 'lodash';
import {
    FETCH_INVOICE_PAYMENTS,
    CLEAN_INVOICE_PAYMENTS,
    DELETE_INVOICE_PAYMENT,
    DELETE_INVOICE_PAYMENTS,
    ADDON_INVOICE_PAYMENTS,
} from './types';
import { request, __ } from '../functions';

/**
 * Nacitanie platieb.
 *
 * @param {Screen} screen
 * @param {object} options
 */
export const fetchInvoicePayments = (screen, options = {}) => {
    const defaults = {
        page: 1,
        per_page: 0,
        filtered: {},
        callback: null,
    };

    options = { ...defaults, ...options };

    let params = { page: options.page, per_page: options.per_page };

    // Nasetujeme filter
    _.forEach(options.filtered, (value, key) => {
        params = { ...params, ...{ [`filter_${key}`]: value } };
    });

    return dispatch => request('/invoice-payments/index', params).then(response => {
        const { data, sync } = response.data;
        const { setUser } = screen.props;

        if (options.callback !== null) {
            // Zavolame callback
            options.callback();
        }

        // Nasetujeme user data
        setUser(sync.user);

        dispatch({ type: FETCH_INVOICE_PAYMENTS, payload: data });
    });
};

/**
 * Zmazanie platby.
 *
 * @param {Screen} screen
 * @param {number} id
 */
export const deleteInvoicePayment = (screen, id) => {
    const { invoice_payment_delete_in_acccounting } = screen.props.user.settings;

    return dispatch => request(
        `/invoice-payments/delete/${id}`,
        { invoice_payment_delete_in_acccounting }
    ).then(response => {
        const { status } = response.data;

        if (status === 'error') {
            // Platbu sa nepodarilo zmazat
            screen.showSnackbar('error', __('Platbu sa nepodarilo zmazať'));
            return;
        }

        screen.showSnackbar('success', __('Platba bola zmazaná'));

        dispatch({ type: DELETE_INVOICE_PAYMENT });
    });
};

/**
 * Zmazanie platieb cez multiselect.
 *
 * @param {Screen} screen
 * @param {string} ids
 * @param {Object} filtered
 */
export const deleteInvoicePayments = (screen, ids, filtered = {}) => {
    const { invoice_payment_delete_in_acccounting } = screen.props.user.settings;

    let params = { invoice_payment_delete_in_acccounting };

    // Nasetujeme filter
    _.forEach(filtered, (value, key) => {
        params = { ...params, ...{ [`filter_${key}`]: value } };
    });

    return dispatch => request(`/invoice-payments/multiDelete/${ids}`, params).then(response => {
        const { status } = response.data;

        if (status === 'error') {
            // Platby sa nepodarilo zmazat
            screen.showSnackbar('error', __('Platby sa nepodarilo zmazať'));
            return;
        }

        screen.showSnackbar('success', __('Platby boli zmazané'));

        dispatch({ type: DELETE_INVOICE_PAYMENTS });
    });
};

/**
 * Addon platieb.
 *
 * @param {Screen} screen
 * @param {string} ids
 * @param {number} addonId
 * @param {Object} filtered
 */
export const addonInvoicePayments = (screen, ids, addonId, filtered = {}) => {
    let params = {};

    // Nasetujeme filter
    _.forEach(filtered, (value, key) => {
        params = { ...params, ...{ [`filter_${key}`]: value } };
    });

    return dispatch => request(`/invoice-payments/multiAddon/${ids}/${addonId}`, params).then(response => {
        const { status, data } = response.data;

        if (status === 'error') {
            // Nepodarilo sa nacitat doplnok
            screen.showSnackbar('error', __('Nepodarilo sa načítať doplnok'));
            return;
        }

        screen.showSnackbar('success', __('Doplnok bol načítaný'));

        if (_.has(data, 'link')) {
            // Addon ma subor na stiahnutie
            window.location = data.link;
        }

        dispatch({ type: ADDON_INVOICE_PAYMENTS });
    });
};

/**
 * Vycistenie platieb.
 */
export const cleanInvoicePayments = () => ({ type: CLEAN_INVOICE_PAYMENTS });
