import {FETCH_MTOKNA_DOCUMENTS, CLEAN_MTOKNA_DOCUMENTS, DELETE_MTOKNA_DOCUMENT} from '../actions/types';

const MTOKNA_DOCUMENTS_DEFAULT_STATE = {};

/**
 * Reducer pre sablony.
 */
const MtoknaDocumentsReducer = (state = MTOKNA_DOCUMENTS_DEFAULT_STATE, { type, payload }) => {
    switch (type) {
        case FETCH_MTOKNA_DOCUMENTS:
            return payload;

        case CLEAN_MTOKNA_DOCUMENTS:
        case DELETE_MTOKNA_DOCUMENT:
            return MTOKNA_DOCUMENTS_DEFAULT_STATE;

        default:
            return state;
    }
};

export default MtoknaDocumentsReducer;
