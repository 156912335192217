import React from 'react';
import _ from 'lodash';
import { Navigate } from './Navigate';
import { __, getCustomization } from '../functions';
import { COOKIE_TOKEN_NAME, COOKIE_SETTINGS, COOKIE_AFFILIATE_NAME } from '../config';

/**
 * Screen komponenta.
 */
class Screen extends Navigate {
    /**
     * Title.
     *
     * @type {string}
     */
    title = '';

    /**
     * Povolujeme eventy?
     *
     * @type {boolean}
     */
    allowEvents = true;

    /**
     * Auth required.
     *
     * @type {boolean}
     */
    authRequired = true;

    /**
     * Redirect po auth required.
     *
     * @type {boolean}
     */
    authRequiredRedirect = true;

    /**
     * Eshop required.
     *
     * @type {boolean}
     */
    eshopRequired = true;

    /**
     * Komponenta bola pripojena.
     *
     * @return boolean
     */
    componentDidMount() {
        if (!this.allowEvents) {
            return true;
        }

        window.scrollTo(0, 0);

        // Nasetujeme title
        this.setTitle(this.title);

        const { cookies } = this.props;

        if (this.getPath() === '/logout') {
            // Chceme odhlasit
            // Zmazeme token
            cookies.remove(COOKIE_TOKEN_NAME, COOKIE_SETTINGS);

            window.location = '/login';
            return false;
        } else if (this.getPath() === '/affiliate-logout') {
            // Chceme affiliate odhlasit
            // Zmazeme token
            cookies.remove(COOKIE_AFFILIATE_NAME, COOKIE_SETTINGS);

            window.location = '/affiliate-login';
            return false;
        }

        // Pozrieme sa ci mame ulozeny token
        const token = cookies.get(COOKIE_TOKEN_NAME, COOKIE_SETTINGS);

        global.token = !_.isUndefined(token) ? token : '';

        if (this.authRequired && _.isEmpty(token)) {
            // Vyzaduje si auth a nemame token, presmerujeme na login
            window.location = '/login';
            return false;
        } else if (!this.authRequired && this.authRequiredRedirect && !_.isEmpty(token)) {
            // Nevyzaduje si auth a mame token
            if (this.getPath().indexOf('/blog/') !== -1) {
                // Clanok povolime
                return true;
            }

            window.location = '/dashboard';
            return false;
        }

        return true;
    }

    /**
     * Nasetujeme title.
     *
     * @param {string} title
     */
    setTitle(title) {
        const customization = getCustomization();
        const domain_name = customization !== null ? customization.domain : __('GoodEshop.sk');

        // Nasetujeme title
        document.title = !_.isEmpty(title) ? `${title} - ${domain_name}` : domain_name;
    }
}

export { Screen };
