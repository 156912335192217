import { CLEAN_ORDER, FETCH_CREATE_ORDER } from '../actions/types';

const ORDER_DEFAULT_STATE = {};

/**
 * Reducer pre objednavku.
 */
const OrderReducer = (state = ORDER_DEFAULT_STATE, { type, payload }) => {
    switch (type) {
        case FETCH_CREATE_ORDER:
            // Nacitanie vytvorenia objednavky
            return payload;

        case CLEAN_ORDER:
            // Vycistenie objednavky
            return ORDER_DEFAULT_STATE;

        default:
            return state;
    }
};

export default OrderReducer;
