import {
    FETCH_STORAGE,
    FETCH_STORAGE_DATA,
    CHANGE_STORAGE_AUTO_FIELD,
    CHANGE_STORAGE_SETTINGS,
    CREATE_STORAGE,
    DELETE_STORAGE,
} from '../actions/types';

const STORAGE_DEFAULT_STATE = [];

/**
 * Reducer pre sklad.
 */
const StorageReducer = (state = STORAGE_DEFAULT_STATE, { type, payload }) => {
    switch (type) {
        case FETCH_STORAGE:
            // Nacitanie skladu
            return payload;

        case FETCH_STORAGE_DATA:
            // Nacitanie dat skladu
            return { ...state, data: payload.data, synchronized: payload.synchronized, ftp: payload.ftp, web: payload.web };

        case CHANGE_STORAGE_AUTO_FIELD:
            // Zmena auto fieldu
            return { ...state, [payload.field]: payload.value ? 'on' : 'off' };

        case CHANGE_STORAGE_SETTINGS:
            // Zmena nastaveni skladu
            return { ...state, settings: payload };

        case CREATE_STORAGE:
            // Vytvorenie skladu
            return { ...state, id: payload };

        case DELETE_STORAGE:
            // Zmazanie skladu
            return { ...state, id: 0 };

        default:
            return state;
    }
};

export default StorageReducer;
