import React from 'react';
import { connect } from 'react-redux';
import { withCookies } from 'react-cookie';
import AddIcon from '@mui/icons-material/AddCircle';
import EditIcon from "@mui/icons-material/Create";
import { ListScreen } from '../components';
import { fetchMtoknaTemplates, cleanMtoknaTemplates, setUser, changeUserSetting } from '../actions';
import { __, formatDate } from '../functions';
import { IMAGES } from '../config';
import '../assets/styles/mtokna_templates.css';
import _ from "lodash";

/**
 * Emaily.
 */
class MtoknaTemplatesScreen extends ListScreen {
    /**
     * Title.
     *
     * @type {string}
     */
    title = __('Emaily');

    /**
     * Default state.
     *
     * @type {Object}
     */
    state = { ...ListScreen.state, ...{
        lightbox: {},
    }};

    /**
     * Vratime zoznam tagov.
     *
     * @return {Array}
     */
    getTags() {
        const { user } = this.props;

        if (
            user.mtokna_moderator
            || user.mtokna_type === 'mtokna_owner'
            || user.mtokna_type === 'mtokna_seller'
        ) {
            return [];
        }

        return [
            {
                name: __('Nový email'),
                color: 'secondary',
                icon: <AddIcon />,
                onClick: () => this.redirect(`/template/0/${this.getType()}`),
            },
        ];
    }

    /**
     * Vratime nazov tabulky.
     *
     * @return {string}
     */
    getTableName() {
        return 'mtokna_templates';
    }

    /**
     * Vratime empty text.
     *
     * @return {string}
     */
    getEmptyText() {
        return __('Zatiaľ nemáte žiadne emaily');
    }

    getType() {
        const { params } = this.props;

        return _.has(params, 'type') ? params.type : 'vo';
    }

    /**
     * Rendrujeme polozku gridu.
     *
     * @param {Object} item
     *
     * @return {JSX.Element|null}
     */
    renderGridItem(item) {
        const { user } = this.props;

        const type = this.getType();

        if (user.mtokna_type === 'mtokna_owner' && item.name.indexOf('Reklamácia -') !== -1) {
            return null;
        }

        if (type === 'vo' && item.name.indexOf('MO -') !== -1) {
            return null;
        } else if (type === 'mo' && item.name.indexOf('MO -') === -1) {
            return null;
        }

        let buttons = {
            edit: {
                name: (user.mtokna_moderator
                || user.mtokna_type === 'mtokna_owner'
                || user.mtokna_type === 'mtokna_seller') ? __('Zobraziť') : __('Upraviť'),
                icon: <EditIcon />,
                callback: () => this.redirect(`/template/${item.id}/${type}`),
                options: {},
            },
        };

        return (
            <div className="mtokna_templates-grid__item">
                <div className="mtokna_templates-grid__item__header">
                    <div className="mtokna_templates-grid__item__header__title">{item.name}</div>
                    <div className="mtokna_templates-grid__item__header__buttons">
                        {this.renderGridButtons(item.id, buttons)}
                    </div>
                </div>
                <div className="mtokna_templates-grid__item__content">
                    <img src={IMAGES['email.png']} alt="email" />
                </div>
                <div className="mtokna_templates-grid__item__footer">
                    {`${__('Vytvorené')} ${formatDate(item.created, 'dd.mm.yyyy hh:ii')}`}
                </div>
            </div>
        );
    }
}

const stateToProps = ({ mtokna_templates, user }) => ({ items: mtokna_templates, user });

export default withCookies(connect(stateToProps, {
    fetch: fetchMtoknaTemplates,
    clean: cleanMtoknaTemplates,
    setUser,
    changeUserSetting,
})(MtoknaTemplatesScreen));
