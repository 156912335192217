import _ from 'lodash';
import {FETCH_MTOKNA_FILES, CLEAN_MTOKNA_FILES, DELETE_MTOKNA_FILE} from './types';
import { request, __ } from '../functions';

/**
 * Nacitanie sablon.
 *
 * @param {Screen} screen
 * @param {object} options
 */
export const fetchMtoknaFiles = (screen, options = {}) => {
    const defaults = {
        page: 1,
        per_page: 0,
        filtered: {},
    };

    options = { ...defaults, ...options };

    let params = { page: options.page, per_page: options.per_page };

    // Nasetujeme filter
    _.forEach(options.filtered, (value, key) => {
        params = { ...params, ...{ [`filter_${key}`]: value } };
    });

    return dispatch => request('/mtokna/files', params).then(response => {
        const { data, sync } = response.data;
        const { setUser } = screen.props;

        // Nasetujeme user data
        setUser(sync.user);

        dispatch({ type: FETCH_MTOKNA_FILES, payload: data });
    });
};

export const deleteMtoknaFile = (screen, id) => {
    return dispatch => request(`/mtokna/removeFile/${id}`).then(response => {
        const { status } = response.data;

        if (status === 'error') {
            screen.showSnackbar('error', __('Nepodarilo sa zmazať súbor'));
            return;
        }

        screen.showSnackbar('success', __('Súbor bol zmazaný'));

        dispatch({ type: DELETE_MTOKNA_FILE });
    });
};

/**
 * Vycistenie sablon.
 */
export const cleanMtoknaFiles = () => ({ type: CLEAN_MTOKNA_FILES });
