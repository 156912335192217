import { FETCH_CLIENTS, CLEAN_CLIENTS, DELETE_CLIENT, DELETE_CLIENTS, CHANGE_CLIENT_NOTE } from '../actions/types';
import _ from 'lodash';
import { toNumber } from '../functions';

const CLIENTS_DEFAULT_STATE = {};

/**
 * Reducer pre klientov.
 */
const ClientsReducer = (state = CLIENTS_DEFAULT_STATE, { type, payload }) => {
    switch (type) {
        case FETCH_CLIENTS:
            // Nacitanie klientov
            return payload;

        case CHANGE_CLIENT_NOTE:
            // Zmenime poznamku klientovi
            return { ...state, items: _.map(state.items, item => {
                if (toNumber(item.id) === toNumber(payload.id)) {
                    // Nasli sme klienta
                    item = { ...item, note: payload.note };
                }

                return item;
            }) };

        case CLEAN_CLIENTS:
        case DELETE_CLIENT:
        case DELETE_CLIENTS:
            // Vycistenie klientov
            // Zmazanie klienta
            // Zmazanie klientov cez multiselect
            return CLIENTS_DEFAULT_STATE;

        default:
            return state;
    }
};

export default ClientsReducer;
