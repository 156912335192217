import React from 'react';
import _ from 'lodash';
import { Navigate, Button, Input, Select, ContentPart, Message } from '../components';
import { __, request, toNumber } from '../functions';
import '../assets/styles/gateway.css';

/**
 * Gateway komponenta.
 */
class Gateway extends Navigate {
    /**
     * Default props.
     *
     * @type {Object}
     */
    static defaultProps = {
        type: '',
    };

    /**
     * Default state.
     *
     * @type {Object}
     */
    state = {
        showSetup: false,
        data: {},
        settings: {},
        fields: {},
        loading: false,
    };

    /**
     * Zoznam labelov.
     *
     * @type {Object}
     */
    labels = {
        'secret_key': __('Secret key'),
        'bcid': __('Besteron ID (CID)'),
        'api_key': __('API kľúč'),
        'goid': __('Gopay ID (Identifikátor eshopu)'),
        'client_id': __('ID zákazníka (Client id)'),
        'client_secret': __('Tajomstvo zákazníka (Client secret)'),
        'email': __('Prihlasovací email'),
        'company': __('Spoločnosť'),
        'username': __('Prihlasovacie meno'),
        'password': __('Prihlasovacie heslo'),
    };

    /**
     * Komponenta bola pripojena.
     */
    componentDidMount() {
        const { title, setTitle, user } = this.props;

        // Nastavime title
        setTitle(title);

        if (toNumber(user.user_eshop_id) === 0) {
            return;
        }

        // Nacitame data
        this.fetch();
    }

    /**
     * Nacitame data.
     */
    fetch() {
        const { type, onClose } = this.props;

        // Nacitame data brany
        request('/user-gateways/get', { gateway: type }).then(response => {
            const { status, data } = response.data;

            if (status === 'error') {
                // Brana neexistuje
                onClose();
                return;
            }

            this.setState({
                data,
                settings: data.settings,
                fields: this.getDefaultFields(data.gateways[data.id].fields),
            });
        });
    }

    /**
     * Vratime zoznam default fieldov.
     *
     * @param {Object} fields
     *
     * @return {Object}
     */
    getDefaultFields(fields) {
        return _.reduce(
            fields,
            (result, field, name) => ({ ...result, [name]: _.isObject(field) ? _.keys(field)[0] : '' }),
            {}
        );
    }

    /**
     * Event po zmene nastaveni.
     *
     * @param {string} name
     * @param {string} value
     */
    onChangeSetting(name, value) {
        const { settings } = this.state;

        this.setState({ settings: { ...settings, [name]: value } });
    }

    /**
     * Event po zmene fieldu.
     *
     * @param {string} field
     * @param {string} value
     */
    onChangeField(field, value) {
        const { fields } = this.state;

        this.setState({ fields: { ...fields, [field]: value } });
    }

    /**
     * Zobrazime setup.
     */
    showSetup() {
        this.setState({ showSetup: true });
    }

    /**
     * Schovame setup.
     */
    closeSetup() {
        this.setState({ showSetup: false });
    }

    /**
     * Skontrolujeme credentials.
     *
     * @param {boolean} create
     */
    checkCredentials(create) {
        const { showSnackbar } = this.props;
        const { data, fields } = this.state;

        this.setState({ loading: true });

        request('/user-gateways/credentials', fields, 'POST', { gateway: data.gateways[data.id].name }).then(response => {
            const { status, data } = response.data;

            if (status === 'error') {
                this.setState({ loading: false });
                showSnackbar('error', __('Nepodarilo sa prihlásiť'));
                return;
            }

            // Ulozime
            this.save(data.credentials, create);
        });
    }

    /**
     * Ulozenie.
     *
     * @param {Object} credentials
     * @param {boolean} create
     */
    save(credentials, create = false) {
        const { showSnackbar, createGateway } = this.props;
        const { data } = this.state;

        if (create) {
            // Vytvarame
            request('/user-gateways/create', { gateway_id: data.id, credentials }, 'POST', { gateway: data.gateways[data.id].name }).then(response => {
                const { status } = response.data;

                if (status === 'error') {
                    // Nepodarilo sa vytvorit
                    this.setState({ loading: false });
                    showSnackbar('error', __('Nepodarilo sa prihlásiť'));
                    return;
                }

                this.setState({ loading: false, data: {} });

                showSnackbar('success', __('Prihlasovacie údaje boli uložené'));

                // Nacitame data znovu
                this.fetch();

                // Vytvorime
                createGateway(data.id);
            });
            return;
        }

        // Zmenime nastavenia
        request('/user-gateways/changeCredentials', { credentials }, 'POST', { gateway: data.gateways[data.id].name }).then(response => {
            const { status } = response.data;

            this.setState({ loading: false });

            if (status === 'error') {
                // Nepodarilo sa zmenit credentials
                showSnackbar('error', __('Nepodarilo sa zmeniť prihlasovacie údaje'));
                return;
            }

            this.setState({ loading: false, data: {}, showSetup: false });

            showSnackbar('success', __('Prihlasovacie údaje boli zmenené'));

            // Nacitame data znovu
            this.fetch();
        });
    }

    /**
     * Zmazeme produkt.
     */
    delete() {
        const { showSnackbar, onClose, deleteGateway } = this.props;
        const { data } = this.state;

        this.setState({ loadingDelete: true });

        request('/user-gateways/delete', { gateway: data.gateways[data.id].name }).then(response => {
            const { status } = response.data;

            if (status === 'error') {
                // Nepodarilo sa zmazat
                this.setState({ loadingDelete: false });
                showSnackbar('error', __('Nepodarilo sa deaktivovať platobnú bránu'));
                return;
            }

            showSnackbar('success', __('Platobná brána bola deaktivovaná'));

            // Zavrieme
            onClose();

            // Zmazeme
            deleteGateway(data.id);
        });
    }

    /**
     * Zmena nastaveni.
     */
    changeSettings() {
        const { showSnackbar, onClose } = this.props;
        const { data, settings } = this.state;

        this.setState({ loading: true });

        request('/user-gateways/changeSettings', { settings }, 'POST', { gateway: data.gateways[data.id].name }).then(response => {
            const { status } = response.data;

            this.setState({ loading: false });

            if (status === 'error') {
                // Nepodarilo sa zmenit nastavenia
                showSnackbar('error', __('Nepodarilo sa zmeniť nastavenia'));
                return;
            }

            showSnackbar('success', __('Nastavenia boli zmenené'));

            // Zavrieme
            onClose();
        });
    }

    /**
     * Rendrovanie fieldov.
     *
     * @param {Object} items
     *
     * @return {JSX.Element[]}
     */
    renderFields(items) {
        const { fields } = this.state;

        return _.map(items, (field, name) => {
            const label = _.has(this.labels, name) ? this.labels[name] : '';

            switch (name) {
                case 'secret_key':
                    // Tajny kluc
                    return <Input
                        key={name}
                        label={label}
                        value={fields[name]}
                        onChange={value => this.onChangeField(name, value)}
                    />;

                case 'bcid':
                    // Besteron CID
                    return <Input
                        key={name}
                        label={label}
                        value={fields[name]}
                        onChange={value => this.onChangeField(name, value)}
                    />;

                case 'api_key':
                    // Api kluc
                    return <Input
                        key={name}
                        label={label}
                        value={fields[name]}
                        onChange={value => this.onChangeField(name, value)}
                    />;

                case 'goid':
                    // Gopay ID
                    return <Input
                        key={name}
                        label={label}
                        value={fields[name]}
                        onChange={value => this.onChangeField(name, value)}
                    />;

                case 'client_id':
                    // Client id
                    return <Input
                        key={name}
                        label={label}
                        value={fields[name]}
                        onChange={value => this.onChangeField(name, value)}
                    />;

                case 'client_secret':
                    // Client secret
                    return <Input
                        key={name}
                        label={label}
                        value={fields[name]}
                        onChange={value => this.onChangeField(name, value)}
                    />;

                case 'email':
                    // Email
                    return <Input
                        type="email"
                        key={name}
                        label={label}
                        value={fields[name]}
                        onChange={value => this.onChangeField(name, value)}
                    />;

                case 'company':
                    // Spolocnost
                    return <Input
                        key={name}
                        label={label}
                        value={fields[name]}
                        onChange={value => this.onChangeField(name, value)}
                    />;

                case 'username':
                    // Prihlasovacie meno
                    return <Input
                        key={name}
                        label={label}
                        value={fields[name]}
                        onChange={value => this.onChangeField(name, value)}
                    />;

                case 'password':
                    // Heslo
                    return <Input
                        type="password"
                        key={name}
                        label={label}
                        value={fields[name]}
                        onChange={value => this.onChangeField(name, value)}
                    />;

                default:
                    return null;
            }
        });
    }

    /**
     * Rendrovanie.
     *
     * @return {JSX.Element}
     */
    render() {
        const { user } = this.props;
        const { data, loading, loadingDelete, showSetup, settings } = this.state;

        if (toNumber(user.user_eshop_id) === 0) {
            return <div
                className="gateway-drawer-content"
            ><Message type="warning">{__('Najprv musíte aktivovať eshop')}</Message></div>;
        }

        if (_.isEmpty(data)) {
            // Data nie su nacitane
            return <div
                className="gateway-content gateway-content-loading"
            >{this.renderLoading()}</div>;
        }

        // Je aktivne?
        const activated = !_.isEmpty(data.credentials);

        // Fieldy na generovanie
        const { name, fields } = data.gateways[data.id];

        return (
            <div className="gateway-content">
                {!showSetup && activated ? <div className="gateway-content__data">
                    <div className="gateway-content__data__credentials">
                        <div className="gateway-content__data__credentials__title">{__('Prihlasovacie údaje')}</div>
                        {_.map(data.credentials, (value, name) => {
                            if (!_.has(this.labels, name)) {
                                return null;
                            }

                            return (
                                <div className="gateway-content__data__credentials__value" key={name}>
                                    <div className="gateway-content__data__credentials__value__name">
                                        {this.labels[name]}:
                                    </div>
                                    <div className="gateway-content__data__credentials__value__text">
                                        {_.truncate(value, { length: 30 })}
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                    <div className="gateway-content__data__buttons">
                        <Button
                            onClick={() => this.showSetup()}
                            color="shadow"
                            className="gateway-content__data__buttons__button"
                        >{__('Upraviť')}</Button>
                        <Button
                            onClick={() => this.delete()}
                            color="red"
                            className="gateway-content__data__buttons__button"
                            loading={loadingDelete}
                        >{__('Zmazať')}</Button>
                    </div>
                    <div className="gateway-content__data__settings">
                        <ContentPart title={__('Nastavenia')} />
                        <Select
                            label={__('Referencia objednávky')}
                            options={{ 'number': __('Číslo objednávky'), 'id': __('ID objednávky') }}
                            value={settings.reference_id}
                            onChange={value => this.onChangeSetting('reference_id', value)}
                            allowEmpty={false}
                        />
                        {name === 'Stripe' ? <Input
                            label={__('Názov vlastného polia pre referenciu objednávky')}
                            value={settings.meta_data_reference}
                            onChange={value => this.onChangeSetting('meta_data_reference', value)}
                        /> : null}
                    </div>
                </div> : <div className="gateway-content__data">
                    {this.renderFields(fields)}
                </div>}
                <div className="gateway-content__buttons">
                    {showSetup ? <Button
                        onClick={() => this.closeSetup()}
                        color="shadow"
                        className="gateway-content__buttons__button"
                    >{__('Naspäť')}</Button> : null}
                    <Button
                        onClick={!showSetup && activated
                            ? () => this.changeSettings()
                            : () => this.checkCredentials(!showSetup)}
                        color="green"
                        className="gateway-content__buttons__button"
                        loading={loading}
                    >{__('Uložiť')}</Button>
                </div>
            </div>
        );
    }
}

export { Gateway };
