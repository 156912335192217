import React from 'react';
import _ from 'lodash';
import HelpIcon from '@mui/icons-material/Help';
import { Navigate, Select, Input } from '../components';
import { __, request, formatDate, formatAmount } from '../functions';
import '../assets/styles/rainpro_order.css';

/**
 * Order komponenta.
 */
class RainproOrder extends Navigate {
    /**
     * Default props.
     *
     * @type {Object}
     */
    static defaultProps = {
        id: 0,
    };

    /**
     * Default state.
     *
     * @type {Object}
     */
    state = {
        showLogs: false,
        data: {},
        settings: {},
        loading: false,
        loadingState: false,
    };

    /**
     * Komponenta bola pripojena.
     */
    componentDidMount() {
        const { id, onClose, setTitle, showSnackbar, changeRainproOrderData } = this.props;

        // Nacitame data objednavky
        request(`/rainpro/viewOrder/${id}`).then(response => {
            const { status, data } = response.data;

            if (status === 'error') {
                // Objednavka neexistuje
                showSnackbar('error', __('Objednávka neexistuje'));
                onClose();
                return;
            }

            // Nastavime title
            setTitle(`${__('Objednávka č.')} ${data.data.number}`);

            // Zmenime data v tabulke
            changeRainproOrderData(id, data.data);

            this.setState({ data: { ...data, invoice: {} } });
        });
    }

    /**
     * Event po zmene nastaveni.
     *
     * @param {string} name
     * @param {string} value
     */
    onChangeSetting(name, value) {
        const { settings } = this.state;

        this.setState({ settings: { ...settings, [name]: value } });
    }

    /**
     * Event po zmene klient nastavenia.
     *
     * @param {string} name
     * @param {string} value
     */
    onChangeClientSetting(name, value) {
        const { settings } = this.state;

        const client = _.has(settings, 'client') ? settings.client : {};

        this.setState({ settings: { ...settings, client: { ...client, [name]: value } } });
    }

    /**
     * Zobrazime logy.
     */
    showLogs() {
        this.setState({ showLogs: true });
    }

    /**
     * Zobrazime objednavky.
     */
    showOrder() {
        this.setState({ showLogs: false });
    }

    /**
     * Rendrujeme hodnotu.
     *
     * @param {string} value
     *
     * @return {string}
     */
    renderValue(value) {
        return !_.isEmpty(value) ? value : '-';
    }

    /**
     * Rendrovanie.
     *
     * @return {JSX.Element}
     */
    render() {
        const { data, settings, loadingState, stateId } = this.state;

        if (_.isEmpty(data)) {
            // Data nie su nacitane
            return <div
                className="rainpro_order-content order-content-loading"
            >{this.renderLoading()}</div>;
        }

        return (
            <div className="rainpro_order-content">
                <div className="rainpro_order-content__buttons">
                    <div className="rainpro_order-content__buttons__left">
                    </div>
                </div>
                <div className="rainpro_order-content__panels">
                    <div className="rainpro_order-content__panels__panel">
                        <div className="rainpro_order-content__panels__panel__up">
                            <div className="rainpro_order-content__panels__panel__up__state">
                                {!loadingState ? <Select
                                    label={__('Stav')}
                                    options={{ [data.data.state.id]: data.data.state.name }}
                                    value={_.has(data, 'state') ? data.state : (!_.isEmpty(stateId) ? stateId : data.data.state.id)}
                                    allowEmpty={false}
                                /> : this.renderLoading(30)}
                            </div>
                            <div className="rainpro_order-content__panels__panel__up__value">
                                <div className="rainpro_order-content__panels__panel__up__value__label">{__('Vytvorené:')}</div>
                                <div className="rainpro_order-content__panels__panel__up__value__value">
                                    {this.renderValue(formatDate(data.created, 'dd.mm.yyyy hh:ii'))}
                                </div>
                            </div>
                            <div className="rainpro_order-content__panels__panel__up__value">
                                <div className="rainpro_order-content__panels__panel__up__value__label">{__('Platba:')}</div>
                                <div className="rainpro_order-content__panels__panel__up__value__value">
                                    {this.renderValue(data.data.payment_type_name)}
                                </div>
                            </div>
                        </div>
                        <div className="rainpro_order-content__panels__panel__amount">
                            <span>{formatAmount(data.data.amount, data.data.currency)}</span>
                            <span>{__('Celková suma')}</span>
                        </div>
                    </div>
                    <div className="rainpro_order-content__panels__panel">
                        <div className="rainpro_order-content__panels__panel__client">
                            <div className="rainpro_order-content__panels__panel__client__panel">
                                <div className="rainpro_order-content__panels__panel__client__panel__title">{__('Fakturačné údaje')}</div>
                                <Input
                                    label={__('Meno')}
                                    value={_.has(settings, 'client') && _.has(settings.client, 'name')
                                        ? settings.client.name
                                        : data.data.client.name}
                                    onChange={value => this.onChangeClientSetting('name', value)}
                                    disabled
                                />
                                <Input
                                    label={__('Ulica')}
                                    value={_.has(settings, 'client') && _.has(settings.client, 'address')
                                        ? settings.client.address
                                        : data.data.client.address}
                                    onChange={value => this.onChangeClientSetting('address', value)}
                                    disabled
                                />
                                <Input
                                    label={__('Mesto')}
                                    value={_.has(settings, 'client') && _.has(settings.client, 'city')
                                        ? settings.client.city
                                        : data.data.client.city}
                                    onChange={value => this.onChangeClientSetting('city', value)}
                                    disabled
                                />
                                <Input
                                    label={__('PSČ')}
                                    value={_.has(settings, 'client') && _.has(settings.client, 'zip')
                                        ? settings.client.zip
                                        : data.data.client.zip}
                                    onChange={value => this.onChangeClientSetting('zip', value)}
                                    disabled
                                />
                                <Input
                                    label={__('Tel. číslo')}
                                    value={_.has(settings, 'client') && _.has(settings.client, 'phone')
                                        ? settings.client.phone
                                        : data.data.client.phone}
                                    onChange={value => this.onChangeClientSetting('phone', value)}
                                    disabled
                                />
                                <Input
                                    label={__('Email')}
                                    value={_.has(settings, 'client') && _.has(settings.client, 'email')
                                        ? settings.client.email
                                        : data.data.client.email}
                                    onChange={value => this.onChangeClientSetting('email', value)}
                                    disabled
                                />
                            </div>
                            <div className="rainpro_order-content__panels__panel__client__panel">
                                <div className="rainpro_order-content__panels__panel__client__panel__title">{__('Dodacie údaje')}</div>
                                <Input
                                    label={__('Meno')}
                                    value={_.has(settings, 'client') && _.has(settings.client, 'delivery_name')
                                        ? settings.client.delivery_name
                                        : data.data.client.delivery_name}
                                    onChange={value => this.onChangeClientSetting('delivery_name', value)}
                                    disabled
                                />
                                <Input
                                    label={__('Ulica')}
                                    value={_.has(settings, 'client') && _.has(settings.client, 'delivery_address')
                                        ? settings.client.delivery_address
                                        : data.data.client.delivery_address}
                                    onChange={value => this.onChangeClientSetting('delivery_address', value)}
                                    disabled
                                />
                                <Input
                                    label={__('Mesto')}
                                    value={_.has(settings, 'client') && _.has(settings.client, 'delivery_city')
                                        ? settings.client.delivery_city
                                        : data.data.client.delivery_city}
                                    onChange={value => this.onChangeClientSetting('delivery_city', value)}
                                    disabled
                                />
                                <Input
                                    label={__('PSČ')}
                                    value={_.has(settings, 'client') && _.has(settings.client, 'delivery_zip')
                                        ? settings.client.delivery_zip
                                        : data.data.client.delivery_zip}
                                    onChange={value => this.onChangeClientSetting('delivery_zip', value)}
                                    disabled
                                />
                                <Input
                                    label={__('Tel. číslo')}
                                    value={_.has(settings, 'client') && _.has(settings.client, 'delivery_phone')
                                        ? settings.client.delivery_phone
                                        : data.data.client.delivery_phone}
                                    onChange={value => this.onChangeClientSetting('delivery_phone', value)}
                                    disabled
                                />
                                <Input
                                    label={__('Poznámka')}
                                    value={_.has(settings, 'client') && _.has(settings.client, 'delivery_note')
                                        ? settings.client.delivery_note
                                        : data.data.client.delivery_note}
                                    onChange={value => this.onChangeClientSetting('delivery_note', value)}
                                    multiline
                                    disabled
                                />
                            </div>
                        </div>
                        <div className="rainpro_order-content__panels__panel__products">
                            <div className="rainpro_order-content__panels__panel__products__title">{__('Produkty')}</div>
                            {_.map(data.data.products, (product, key) => {
                                let productData = {};

                                if (_.has(data.products, product.id)) {
                                    productData = {
                                        img: data.products[product.id].img_url,
                                        name: `${data.products[product.id].name}${!_.isEmpty(product.variant) ? ` - ${product.variant}` : ''}`,
                                    };
                                } else {
                                    productData = {
                                        img: '',
                                        name: product.name,
                                    };
                                }

                                let name = productData.name;

                                return (
                                    <div className="rainpro_order-content__panels__panel__products__product" key={key}>
                                        <div className="rainpro_order-content__panels__panel__products__product__photo">
                                            {!_.isEmpty(productData.img) ? <img src={productData.img} alt={productData.name} /> : <HelpIcon />}
                                        </div>
                                        <div className="rainpro_order-content__panels__panel__products__product__info">
                                            <div className="rainpro_order-content__panels__panel__products__product__info__name">{name}</div>
                                            <div className="rainpro_order-content__panels__panel__products__product__info__values">
                                                <div>{__('SKU')}: {this.renderValue(product.number)}</div>
                                                <div>{__('Počet')}: {product.quantity}{`${!_.isEmpty(product.unit) ? ` ${product.unit}` : 'x'}`}</div>
                                                <div>{__('Jednotková cena')}: {formatAmount(product.total_price, data.data.currency)}</div>
                                                <div>{__('Celková cena')}: {formatAmount(product.total_price * product.quantity, data.data.currency)}</div>
                                                <div>{__('EAN')}: {this.renderValue(product.ean)}</div>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
                {this.renderSnackbar()}
            </div>
        );
    }
}

export { RainproOrder };
