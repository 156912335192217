import { SET_USER, CLEAN_USER, CHANGE_USER_SETTING, FETCH_USER_NOTIFICATIONS } from './types';
import { request } from '../functions';

/**
 * Nasetovanie usera.
 *
 * @param {Object} payload
 */
export const setUser = payload => ({ type: SET_USER, payload });

/**
 * Vycistenie usera.
 */
export const cleanUser = () => ({ type: CLEAN_USER });

/**
 * Zmena user settingu.
 *
 * @param {string} name
 * @param {string|Object} value
 */
export const changeUserSetting = (name, value) => ({ type: CHANGE_USER_SETTING, payload: { name, value } });

/**
 * Nacitanie poctu neprecitanych notifikacii.
 */
export const fetchUserNotifications = () => {
    return dispatch => request('/user-eshops/notifications').then(({ data }) => {
        dispatch({ type: FETCH_USER_NOTIFICATIONS, payload: data.data.count });
    });
};
