import {
    FETCH_MTOKNA_CATEGORIES,
    CLEAN_MTOKNA_CATEGORIES,
    DELETE_MTOKNA_CATEGORY,
    CHANGE_MTOKNA_CATEGORY,
    CHANGE_MTOKNA_CATEGORY_STATUS,
} from '../actions/types';
import _ from "lodash";
import {toNumber} from "../functions";

const MTOKNA_CATEGORIES_DEFAULT_STATE = {};

/**
 * Reducer pre objednavky.
 */
const MtoknaCategoriesReducer = (state = MTOKNA_CATEGORIES_DEFAULT_STATE, { type, payload }) => {
    switch (type) {
        case FETCH_MTOKNA_CATEGORIES:
            return payload;

        case CHANGE_MTOKNA_CATEGORY:
            return { ...state, items: _.map(state.items, item => {
                if (toNumber(item.id) === toNumber(payload.id)) {
                    item = { ...item, ...payload.data };
                }

                return item;
            }) };

        case CHANGE_MTOKNA_CATEGORY_STATUS:
            return { ...state, items: _.map(state.items, item => {
                if (toNumber(item.id) === toNumber(payload.id)) {
                    item = { ...item, status: payload.status };
                }

                return item;
            }) };

        case CLEAN_MTOKNA_CATEGORIES:
        case DELETE_MTOKNA_CATEGORY:
            return MTOKNA_CATEGORIES_DEFAULT_STATE;

        default:
            return state;
    }
};

export default MtoknaCategoriesReducer;
