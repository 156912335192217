import React from 'react';
import { connect } from 'react-redux';
import { withCookies } from 'react-cookie';
import _ from 'lodash';
import { Tooltip } from '@mui/material';
import Apps from '../components/Apps';
import { Screen, ContentHeader, Button, ContentPart, Switch, Message, Select } from '../components';
import { fetchStorage, fetchStorageData, setUser, changeStorageAutoField, changeStorageSettings } from '../actions';
import { __, formatDate, toNumber } from '../functions';
import '../assets/styles/storage.css';

/**
 * Sklad.
 */
class StorageScreen extends Screen {
    /**
     * Title.
     *
     * @type {string}
     */
    title = __('Sklad');

    /**
     * Default state.
     *
     * @type {Object}
     */
    state = {
        settings: {},
        loading: false,
        loadingData: false,
    };

    /**
     * Komponenta bola pripojena.
     *
     * @return boolean
     */
    async componentDidMount() {
        if (super.componentDidMount()) {
            const { fetchStorage } = this.props;

            await fetchStorage(this);

            const { storage } = this.props;

            this.setState({ settings: storage.settings });
        }

        return true;
    }

    /**
     * Event po zmene auto fieldu.
     *
     * @param {string} field
     * @param {boolean} value
     */
    onChangeAutoField(field, value) {
        const { changeStorageAutoField } = this.props;

        changeStorageAutoField(this, field, value);
    }

    /**
     * Event po zmene settingu.
     *
     * @param {string} type
     * @param {string} value
     */
    onChangeSetting(type, value) {
        const { settings } = this.state;

        this.setState({ settings: { ...settings, [type]: value } });
    }

    /**
     * Nacitame data fakturacneho systemu.
     */
    refreshData() {
        const { fetchStorageData } = this.props;

        this.setState({ loadingData: true });

        fetchStorageData(this);
    }

    /**
     * Zmena nastaveni.
     */
    changeSettings() {
        const { changeStorageSettings } = this.props;
        const { settings } = this.state;

        if (this.isDemo()) {
            this.showSnackbar('error', __('Nastavenie nie je možné uložiť na DEMO účte'));
            return;
        }

        this.setState({ loading: true });

        changeStorageSettings(this, settings);
    }

    /**
     * Rendrovanie.
     *
     * @returns {JSX.Element}
     */
    render() {
        const { storage } = this.props;
        const { settings, loading, loadingData } = this.state;

        if (_.isEmpty(settings)) {
            // Data nie su nacitane
            return this.renderLoading();
        }

        if (!this.hasPermission('settings-storage')) {
            // Nema pravo
            return null;
        }

        // Vytiahneme id skladu
        const id = toNumber(storage.id);

        // Je aktivny sklad?
        const active = id > 0;

        // Je povolene synchronizovanie objednavok
        const syncOrders = active ? storage.storages[id].settings.sync_orders : false;

        return (
            <div className="storage">
                <ContentHeader
                    title={__('Sklad')}
                    tags={[
                        active
                            ? { label: formatDate(storage.synchronized, __('Synchronizované so skladom o hh:ii')) }
                            : null
                    ]}
                    rightContent={active ? <div>
                        <Tooltip title={__('Načíta aktuálne údaje zo skladu')}>
                            <span><Button
                                onClick={() => this.refreshData()}
                                loading={loadingData}
                            >{__('Načítať údaje')}</Button></span>
                        </Tooltip>
                    </div> : null}
                />
                <Apps
                    type="storages"
                    storageId={storage.id}
                    refreshData={() => this.refreshData()}
                    { ...storage }
                />
                <ContentPart title={__('Nastavenia')} />
                {!active
                    ? (<div className="storage__settings">
                        <Message type="warning">{__('Najprv musíte aktivovať sklad')}</Message>
                    </div>)
                    : <div className="storage__settings">
                        {syncOrders ? <div className="storage__settings__switchers">
                            <Switch
                                label={__('Automatický import objednávok')}
                                checked={storage.auto_orders === 'on'}
                                onChange={value => this.onChangeAutoField('auto_orders', value)}
                            />
                        </div> : null}
                        <Message type="info" className="storage__settings__message">
                            {__('Produkty sa synchronizujú približne každú hodinu.')}
                        </Message>
                        <div className="storage__settings__panels">
                            <div className="storage__settings__panels__panel">
                                <div className="input__label">{__('Nastavenia synchronizácie')}</div>
                                <Switch
                                    label={__('Vytvoriť nový produkt')}
                                    checked={settings.create_products}
                                    onChange={value => this.onChangeSetting('create_products', value)}
                                />
                                <Switch
                                    label={__('Aktualizovať obrázky')}
                                    checked={settings.update_images}
                                    onChange={value => this.onChangeSetting('update_images', value)}
                                />
                                <Switch
                                    label={__('Aktualizovať kategórie')}
                                    checked={settings.update_categories}
                                    onChange={value => this.onChangeSetting('update_categories', value)}
                                />
                                <Switch
                                    label={__('Aktualizovať názov')}
                                    checked={settings.update_name}
                                    onChange={value => this.onChangeSetting('update_name', value)}
                                />
                                <Switch
                                    label={__('Aktualizovať popis')}
                                    checked={settings.update_description}
                                    onChange={value => this.onChangeSetting('update_description', value)}
                                />
                                <Switch
                                    label={__('Aktualizovať cenu')}
                                    checked={settings.update_price}
                                    onChange={value => this.onChangeSetting('update_price', value)}
                                />
                                <Switch
                                    label={__('Aktualizovať stav na sklade')}
                                    checked={settings.update_stock}
                                    onChange={value => this.onChangeSetting('update_stock', value)}
                                />
                                <Switch
                                    label={__('Aktualizovať váhu')}
                                    checked={settings.update_weight}
                                    onChange={value => this.onChangeSetting('update_weight', value)}
                                />
                            </div>
                            <div className="storage__settings__panels__panel">
                                <Select
                                    label={__('Párovať produkt podľa')}
                                    options={{ ean: __('EAN'), number: __('Číslo') }}
                                    value={settings.check_field}
                                    onChange={value => this.onChangeSetting('check_field', value)}
                                    allowEmpty={false}
                                />
                            </div>
                        </div>
                        <Button
                            onClick={() => this.changeSettings()}
                            loading={loading}
                            className="storage__settings__button"
                            color="green"
                        >{__('Uložiť')}</Button>
                    </div>}
                {this.renderSnackbar()}
            </div>
        );
    }
}

const stateToProps = ({ storage, user }) => ({ storage, user });

export default withCookies(connect(stateToProps, {
    fetchStorage,
    fetchStorageData,
    setUser,
    changeStorageAutoField,
    changeStorageSettings,
})(StorageScreen));
