import React from 'react';
import { connect } from 'react-redux';
import { withCookies } from 'react-cookie';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import ReactCountryFlag from 'react-country-flag';
import { Tooltip, ButtonBase, Breadcrumbs, Tab, Tabs } from '@mui/material';
import EmailIcon from '@mui/icons-material/AlternateEmail';
import ArrowRightIcon from '@mui/icons-material/ArrowRightAlt';
import { Screen, Button, Logo } from '../components';
import { __, request, toNumber, formatAmount, getCustomization } from '../functions';
import {
    IMAGES,
    ESHOPS,
    ESHOP_COLORS,
    ACCOUNTINGS,
    ACCOUNTING_COLORS,
    COURIERS,
    COURIER_COLORS,
    BANKS,
    BANK_COLORS,
    GATEWAYS,
    GATEWAYS_COLORS,
    STORAGES,
    STORAGE_COLORS,
    MARKETPLACES,
    MARKETPLACES_COLORS,
    PRICE,
    DEMO_TOKEN,
    COOKIE_TOKEN_NAME,
    COOKIE_SETTINGS,
    MARKET,
    PRICE_CZ,
    PRICE_PL,
    PRICE_HU,
    COOKIE_READ_NAME,
    ESHOP_POSITIONS,
    COURIER_POSITIONS,
    ACCOUNTING_POSITIONS,
    BANK_POSITIONS,
    GATEWAYS_POSITIONS,
    MARKETPLACES_POSITIONS,
    COOKIE_SOURCE_NAME,
} from '../config';
import '../assets/styles/public.css';

/**
 * Public.
 */
class PublicScreen extends Screen {
    /**
     * Auth required.
     *
     * @type {boolean}
     */
    authRequired = false;

    /**
     * Default state.
     *
     * @type {Object}
     */
    state = {
        scroll: false,
        stats: {},
        affiliate: false,
        branding: false,
        blog: false,
        blogArticle: null,
        blogCategory: 'all',
        readCookies: true,
        tab: 'orders',
        lightbox: {
            affiliate: false,
            branding: false,
        },
    };

    /**
     * Blog kategorie.
     *
     * @type {Object}
     */
    blogCategories = {
        all: __('Všetko'),
        eshops: __('Eshopy'),
        accountings: __('Ekonomické systémy'),
        storages: __('Sklady'),
        //gateways: __('Platobné brány'),
    };

    /**
     * Clanky.
     *
     * @type {Object}
     */
    blogArticles = {
        'eshop-woocommerce': {
            id: 1,
            uri: 'eshop-woocommerce',
            category: 'eshops',
            name: __('Ako prepojiť WooCommerce eshop?'),
            text: __('<p>Pre API prepojenie bude náš systém potrebovať Kľúč zákazníka (Consumer key) a Tajomstvo zákazníka (Consumer secret). Nižšie nájdete postup ako ich vygenerovať.</p><p><img src="{IMAGE1}" /></p><p>Vyplňte názov, zvoľte používateľa a nastavte práva na "Čítať / Zapísať".</p><p><img src="{IMAGE2}" /></p><p>Po vygenerovaní sa vám zobrazí Kľúč zákazníka (Consumer key) a Tajomstvo zákazníka (Consumer secret).</p><p><img src="{IMAGE3}" /></p>').replace('{IMAGE1}', IMAGES['eshop-woocommerce-1.jpg']).replace('{IMAGE2}', IMAGES['eshop-woocommerce-2.jpg']).replace('{IMAGE3}', IMAGES['eshop-woocommerce-3.jpg']),
            background: '#8154b1',
        },
        'accounting-omega': {
            id: 2,
            uri: 'accounting-omega',
            category: 'accountings',
            name: __('Ako prepojiť fakturáciu s Omegou?'),
            text: __('<p>Pre prepojenie nášho systému s Omegou využívame ich službu <a href="https://kros.sk/fakturacia" target="_blank">https://kros.sk/fakturacia</a> kde je potrebné aby ste mali ich PRO balík.</p><p>Čo získate týmto prepojením?</p><p><ul><li>Automatickú online fakturáciu</li><li>Zasielanie faktúr emailom</li><li>Evidenciu a párovanie úhrad</li></ul></p><p><h2>1. Registrácia v KROS fakturácii</h2></p><p>Najprv si musíte vytvoriť účet v aplikácii KROS fakturácia <a href="https://fakturacia.kros.sk/" target="_blank">na tejto stránke</a>.</p><p><h2>2. Nastavenie synchronizácie s Omegou</h2></p><p>Nižšie na obrázku vidíte postup ako jednoducho nastaviť prepojenie aplikácie KROS fakturácia s Omegou. Prvýkrát pri využívaní tejto služby je potrebné sa prihlásiť pod kontom užívateľa ktorým ste sa registrovali do aplikácie KROS fakturácia, potom už sťahovanie bude fungovať bez prihlásenia.</p><p><img src="{IMAGE1}" /></p><p><h2>3. Prepojenie s našim systémom</h2></p><p>V sekcii Ekonomický systém si u nás vyberiete aplikáciu Omega a zadáte prihlasovacie údaje pod ktorými ste sa registrovali na webovom rozhraní aplikácie KROS fakturácia. To je celé. Od tohto momentu môže náš systém vystavovať faktúry v aplikácii KROS fakturácia s následnou synchronizáciou do Omegy.</p><p><img src="{IMAGE2}" /></p>').replace('{IMAGE1}', IMAGES['accounting-omega-1.jpg']).replace('{IMAGE2}', IMAGES['accounting-omega-2.jpg']),
            background: '#005ca8',
        },
        'accounting-pohoda': {
            id: 3,
            uri: 'accounting-pohoda',
            category: 'accountings',
            name: __('Ako prepojiť fakturáciu s Pohodou?'),
            text: __('<p>Pre prepojenie nášho systému s Pohodou využívame ich službu <a href="https://mpohoda.sk" target="_blank">https://mpohoda.sk</a> ktorá je zadarmo pre majiteľov softvéru Pohoda.</p><p>Čo získate týmto prepojením?</p><p><ul><li>Automatickú online fakturáciu</li><li>Zasielanie faktúr emailom</li><li>Evidenciu a párovanie úhrad</li></ul></p><p><h2>1. Registrácia v mPOHODA</h2></p><p>Najprv si musíte vytvoriť účet v aplikácii mPOHODA <a href="https://app.mpohoda.sk/ucet/registrace" target="_blank">na tejto stránke</a>.</p><p><h2>2. Nastavenie synchronizácie s Pohodou</h2></p><p>Nižšie na obrázku vidíte postup ako jednoducho nastaviť prepojenie aplikácie mPOHODA s Pohodou. V Globálnom nastavení v  sekcii mPOHODA zaškrtnite voľbu Povoliť komunikáciu. Potom už len vyplňte prihlasovacie meno (e-mail) a heslo, pod ktorými ste sa registrovali na webovom rozhraní aplikácie mPOHODA.</p><p><img src="{IMAGE1}" /></p><p><h2>3. Prepojenie s našim systémom</h2></p><p>V sekcii Ekonomický systém si u nás vyberiete aplikáciu Pohoda a zadáte prihlasovacie údaje pod ktorými ste sa registrovali na webovom rozhraní aplikácie mPOHODA. To je celé. Od tohto momentu môže náš systém vystavovať faktúry v aplikácii mPOHODA s následnou synchronizáciou do Pohody.</p><p><img src="{IMAGE2}" /></p>').replace('{IMAGE1}', IMAGES['accounting-pohoda-1.gif']).replace('{IMAGE2}', IMAGES['accounting-pohoda-2.jpg']),
            background: '#004389',
        },
        'accounting-mrp': {
            id: 4,
            uri: 'accounting-mrp',
            category: 'accountings',
            name: __('Ako prepojiť fakturáciu s MRP?'),
            text: __('<p>Pre prepojenie nášho systému s MRP využívame ich službu <a href="https://turbofaktura.sk" target="_blank">https://turbofaktura.sk</a> ktorá je zadarmo.</p><p>Čo získate týmto prepojením?</p><p><ul><li>Automatickú online fakturáciu</li><li>Zasielanie faktúr emailom</li><li>Evidenciu a párovanie úhrad</li></ul></p><p><h2>1. Registrácia v Turbofaktúre</h2></p><p>Najprv si musíte vytvoriť účet v aplikácii Turbofaktúra <a href="https://www.turbofaktura.sk/registrace/" target="_blank">na tejto stránke</a>.</p><p><h2>2. Prepojenie s našim systémom</h2></p><p>V sekcii Ekonomický systém si u nás vyberiete aplikáciu MRP a zadáte prihlasovacie údaje pod ktorými ste sa registrovali na webovom rozhraní aplikácie Turbofaktúra. To je celé. Od tohto momentu môže náš systém vystavovať faktúry v aplikácii Turbofaktúra s následnou synchronizáciou do MRP.</p><p><img src="{IMAGE1}" /></p><p><h2>3. Synchronizácia s MRP</h2></p><p>Nižšie na obrázku vidíte ako jednoducho prebieha import faktúr z aplikácie Turbofaktúra do MRP. Stačí kliknúť v MRP na tlačítko Turbofaktúra.</p><p><img src="{IMAGE2}" /></p>').replace('{IMAGE1}', IMAGES['accounting-mrp-1.jpg']).replace('{IMAGE2}', IMAGES['accounting-mrp-2.png']),
            background: 'white',
        },
        'accounting-moneys3': {
            id: 5,
            uri: 'accounting-moneys3',
            category: 'accountings',
            name: __('Ako prepojiť fakturáciu s Money S3?'),
            text: __('<p>Pre prepojenie nášho systému s Money S3 využívame ich službu <a href="https://idoklad.sk" target="_blank">https://idoklad.sk</a> kde je potrebné aby ste mali ich Prémiové predplatné ktoré podporuje API.</p><p>Čo získate týmto prepojením?</p><p><ul><li>Automatickú online fakturáciu</li><li>Zasielanie faktúr emailom</li><li>Evidenciu a párovanie úhrad</li></ul></p><p><h2>1. Registrácia v iDoklade</h2></p><p>Najprv si musíte vytvoriť účet v aplikácii iDoklad <a href="https://app.idoklad.sk/Account/Registration" target="_blank">na tejto stránke</a>.</p><p><h2>2. Prepojenie s našim systémom</h2></p><p>V sekcii Ekonomický systém si u nás vyberiete aplikáciu iDoklad a zadáte prihlasovacie údaje pod ktorými ste sa registrovali na webovom rozhraní aplikácie iDoklad. To je celé. Od tohto momentu môže náš systém vystavovať faktúry v aplikácii iDoklad s následnou synchronizáciou do Money S3.</p><p><img src="{IMAGE1}" /></p><p><h2>3. Synchronizácia s Money S3</h2></p><p><a href="https://www.idoklad.sk/pub/Help/index.html?1_prepojenie_s_money_s3.html" target="_blank">Na tejto stránke</a> nájdete postup ako nastaviť synchronizáciu dát medzi iDokladom a Money S3.</p>').replace('{IMAGE1}', IMAGES['accounting-money-1.jpg']),
            background: 'white',
        },
        'accounting-moneys4': {
            id: 6,
            uri: 'accounting-moneys4',
            category: 'accountings',
            name: __('Ako prepojiť fakturáciu s Money S4?'),
            text: __('<p>Pre prepojenie nášho systému s Money S4 využívame ich službu <a href="https://idoklad.sk" target="_blank">https://idoklad.sk</a> kde je potrebné aby ste mali ich Prémiové predplatné ktoré podporuje API.</p><p>Čo získate týmto prepojením?</p><p><ul><li>Automatickú online fakturáciu</li><li>Zasielanie faktúr emailom</li><li>Evidenciu a párovanie úhrad</li></ul></p><p><h2>1. Registrácia v iDoklade</h2></p><p>Najprv si musíte vytvoriť účet v aplikácii iDoklad <a href="https://app.idoklad.sk/Account/Registration" target="_blank">na tejto stránke</a>.</p><p><h2>2. Prepojenie s našim systémom</h2></p><p>V sekcii Ekonomický systém si u nás vyberiete aplikáciu iDoklad a zadáte prihlasovacie údaje pod ktorými ste sa registrovali na webovom rozhraní aplikácie iDoklad. To je celé. Od tohto momentu môže náš systém vystavovať faktúry v aplikácii iDoklad s následnou synchronizáciou do Money S4.</p><p><img src="{IMAGE1}" /></p><p><h2>3. Synchronizácia s Money S4</h2></p><p><a href="https://www.idoklad.sk/pub/Help/index.html?1_prepojenie_s_money_s4_s5.html" target="_blank">Na tejto stránke</a> nájdete postup ako nastaviť synchronizáciu dát medzi iDokladom a Money S4.</p>').replace('{IMAGE1}', IMAGES['accounting-money-1.jpg']),
            background: 'white',
        },
        'storage-pohoda': {
            id: 8,
            uri: 'storage-pohoda',
            category: 'storages',
            name: __('Ako prepojiť sklad s Pohodou?'),
            text: __('<p>Pre prepojenie nášho systému s Pohodou využívame ich službu <a href="https://mpohoda.sk" target="_blank">https://mpohoda.sk</a> ktorá je zadarmo pre majiteľov softvéru Pohoda.</p><p>Čo získate týmto prepojením?</p><p><ul><li>Automatickú synchronizáciu stavov na sklade</li><li>Automatickú synchronizáciu cien, názvov produktov a pod</li><li>Vytváranie nových produktov v eshope</li><li>Import prijatých objednávok</li></ul></p><p><h2>1. Registrácia v mPOHODA</h2></p><p>Najprv si musíte vytvoriť účet v aplikácii mPOHODA <a href="https://app.mpohoda.sk/ucet/registrace" target="_blank">na tejto stránke</a>.</p><p><h2>2. Nastavenie synchronizácie s Pohodou</h2></p><p>Nižšie na obrázku vidíte postup ako jednoducho nastaviť prepojenie aplikácie mPOHODA s Pohodou. V Globálnom nastavení v  sekcii mPOHODA zaškrtnite voľbu Povoliť komunikáciu. Potom už len vyplňte prihlasovacie meno (e-mail) a heslo, pod ktorými ste sa registrovali na webovom rozhraní aplikácie mPOHODA.</p><p><img src="{IMAGE1}" /></p><p><h2>3. Nastavenie synchronizácie skladov</h2></p><p>V Pohode je ešte potrebné zašktnúť nastavenie "Synchronizácia zásob s mPOHODA" pri skladoch ktoré potrebujete synchronizovať.</p><p><img src="{IMAGE3}" /></p><p><h2>4. Prepojenie s našim systémom</h2></p><p>V sekcii Sklad si u nás vyberiete aplikáciu Pohoda a zadáte prihlasovacie údaje pod ktorými ste sa registrovali na webovom rozhraní aplikácie mPOHODA. To je celé. Od tohto momentu môže náš systém synchronizovať skladové karty s produktami v eshope.</p><p><img src="{IMAGE2}" /></p>').replace('{IMAGE1}', IMAGES['accounting-pohoda-1.gif']).replace('{IMAGE2}', IMAGES['storage-pohoda-1.jpg']).replace('{IMAGE3}', IMAGES['storage-pohoda-2.jpg']),
            background: '#004389',
        },
        'eshop-shopify': {
            id: 9,
            uri: 'eshop-shopify',
            category: 'eshops',
            name: __('Ako prepojiť Shopify eshop?'),
            text: __('<p>Pre API prepojenie bude náš systém potrebovať API klúč a API heslo. Nižšie nájdete postup ako ich vygenerovať.</p><p><img src="{IMAGE1}" /></p><p><img src="{IMAGE2}" /></p><p><img src="{IMAGE3}" /></p><p><img src="{IMAGE4}" /></p><p><img src="{IMAGE5}" /></p><p><img src="{IMAGE6}" /></p><p>V tomto kroku musíte nastaviť práva ku ktorým bude môcť pristupovať náš systém cez API. Konkrétne treba aby ste mali zaškrtnuté tieto:</p><p><ul><li>write_assigned_fulfillment_orders</li><li>read_assigned_fulfillment_orders</li><li>write_customers</li><li>read_customers</li><li>write_draft_orders</li><li>read_draft_orders</li><li>write_fulfillments</li><li>read_fulfillments</li><li>write_inventory</li><li>read_inventory</li><li>write_order_edits</li><li>read_order_edits</li><li>write_orders</li><li>read_orders</li><li>write_product_listings</li><li>read_product_listings</li><li>write_products</li><li>read_products</li><li>read_shipping</li><li>write_shipping</li><li>write_merchant_managed_fulfillment_orders</li><li>read_merchant_managed_fulfillment_orders</li></ul></ul></p><p><img src="{IMAGE7}" /></p><p><img src="{IMAGE8}" /></p><p>Na tomto obrázku vidíte 2 údaje ktoré potrebuje náš systém. Prvý vyznačený údaj je API password a druhý vyznačený údaj je API klúč.</p><p><img src="{IMAGE9}" /></p>').replace('{IMAGE1}', IMAGES['eshop-shopify-1.jpg']).replace('{IMAGE2}', IMAGES['eshop-shopify-2.jpg']).replace('{IMAGE3}', IMAGES['eshop-shopify-3.jpg']).replace('{IMAGE4}', IMAGES['eshop-shopify-4.jpg']).replace('{IMAGE5}', IMAGES['eshop-shopify-5.jpg']).replace('{IMAGE6}', IMAGES['eshop-shopify-6.jpg']).replace('{IMAGE7}', IMAGES['eshop-shopify-7.jpg']).replace('{IMAGE8}', IMAGES['eshop-shopify-8.jpg']).replace('{IMAGE9}', IMAGES['eshop-shopify-9.jpg']),
            background: 'white',
        },
        'eshop-prestashop': {
            id: 10,
            uri: 'eshop-prestashop',
            category: 'eshops',
            name: __('Ako prepojiť Prestashop eshop?'),
            text: __('<p>Pre API prepojenie bude náš systém potrebovať API klúč. Nižšie nájdete postup ako ho vygenerovať.</p><p><img src="{IMAGE1}" /></p><p><img src="{IMAGE2}" /></p><p>V tomto kroku musíte nastaviť práva ku ktorým bude môcť pristupovať náš systém cez API. Konkrétne treba aby ste mali zaškrtnuté tieto:</p><p><ul><li>addresses</li><li>carriers</li><li>countries</li><li>currencies</li><li>customers</li><li>deliveries</li><li>images</li><li>order_carriers</li><li>order_details</li><li>order_histories</li><li>order_invoices</li><li>order_payments</li><li>order_slip</li><li>order_states</li><li>orders</li><li>product_customization_fields</li><li>product_feature_values</li><li>product_features</li><li>product_option_values</li><li>product_options</li><li>product_suppliers</li><li>products</li><li>stock_availables</li><li>stock_movement_reasons</li><li>stock_movements</li><li>stocks</li><li>stores</li><li>tax_rule_groups</li><li>tax_rules</li><li>taxes</li></ul></ul></p><p><img src="{IMAGE3}" /></p><p>Na tejto stránke nájdete vygenerovaný API klúč.</p><p><img src="{IMAGE4}" /></p>').replace('{IMAGE1}', IMAGES['eshop-prestashop-1.jpg']).replace('{IMAGE2}', IMAGES['eshop-prestashop-2.jpg']).replace('{IMAGE3}', IMAGES['eshop-prestashop-3.jpg']).replace('{IMAGE4}', IMAGES['eshop-prestashop-4.jpg']),
            background: 'white',
        },
        'eshop-upgates': {
            id: 11,
            uri: 'eshop-upgates',
            category: 'eshops',
            name: __('Ako prepojiť Upgates eshop?'),
            text: __('<p>Pre API prepojenie bude náš systém potrebovať API url adresu, API login a API klúč. Nižšie nájdete postup ako ho vygenerovať.</p><p><img src="{IMAGE1}" /></p>').replace('{IMAGE1}', IMAGES['eshop-upgates-0.jpg']),
            background: 'white',
        },
    };

    /**
     * Tabs.
     *
     * @type {Object}
     */
    tabs = {
        orders: [
            { title: __('Všetky objednávky na jednom mieste'), text: __('Náš systém vám naimportuje a synchronizuje objednávky z vášho eshopu a trhovísk (Mall, Heureka a pod). Objednávky z trhovísk vieme vytvoriť priamo v eshope aby vám sedel sklad. Ak potrebujete zaevidovať objednávku ručne tak ani to nebude problém.') },
            { title: __('Pracujte s objednávkami rýchlo a jednoducho'), text: __('U nás viete objednávkam meniť ich stav, odoslať email zákaznikovi, vystaviť faktúru, zaevidovať úhradu, odoslať prepravcovi prípadne stiahnuť objednávku do PDF alebo Excelu.') },
            { title: __('Automatizujte opakujúce sa procesy'), text: __('Už ďalej nemusíte ručne meniť stavy objednávok, odosielať emaily, vystavovať faktúry a pod. Náš systém vám automaticky zmení stav objednávky a odošle email zákaznikovi po vystavení faktúry, odoslaní zásielky, doručení zásielky atď.') },
        ],
        invoices: [
            { title: __('Automatizácia fakturácie'), text: __('Napojte sa cez nás na svoju fakturáciu alebo účtovný systém a vystavujte manuálne na 1 klik alebo automaticky po zmene stavu objednávky faktúry k svojim objednávkam. Vieme rozlišovať klasické faktúry alebo zálohové faktúry. Automatický email s faktúrou zákaznikovi je samozrejmosť.') },
            { title: __('Evidencia úhrad a párovanie platieb'), text: __('Pri faktúrach a objednávkach je dôležité mať prehľadnú a automatickú evidenciu prijatých úhrad. Napojíme vás na vašu banku alebo platobnú bránu a automaticky priradíme platbu k objednávke alebo faktúre.') },
            { title: __('Zálohové faktúry a dobropisy'), text: __('Ak potrebujete vystavovať zálohové faktúry s následním vyučtovaním alebo dobropisy po zrušení objednávky tak aj toto zvládame.') },
        ],
        products: [
            { title: __('Synchronizácia produktov so skladom'), text: __('Napojte si svoj externý skladový systém a náš systém vám bude automaticky synchronizovať produkty zo skladu do eshopu. Systém vie aktualizovať ceny, sklad, názov a podobné vlastnosti produktu. Ak produkt ktorý sa objaví v sklade neexistuje v eshope tak ho systém vytvorí.') },
            { title: __('Aktualizácia produktov na trhoviskách'), text: __('Ak používate trhoviská ako sú napríklad Mall, Amazon, Etsy a pod, tak uvítate automatickú aktualizáciu skladových zásob a cien smerom z eshopu na trhovisko. Ak potrebujete naimportovať nové produkty z eshopu na trhovisko, tak náš systém to zvládne na pár kliknutí.') },
            { title: __('Automatický import objednávok'), text: __('Náš sklad zvláda aj spätnú aktualizáciu údajov, teda importovať nové objednávky z eshopu do skladového systému. Ak používate trhoviská tak z tých vie zase systém vytvoriť objednávky priamo v eshope. Tým pádom vám budú vždy sedieť skladové zásoby.') },
        ],
        packages: [
            { title: __('Všetci prepravcovia na jednom mieste'), text: __('Už sa nemusíte prihlasovať do interných systémov vašich prepravcov balíkov. Cez naše prostredie jednoducho na pár klikov odošlete objednávky viacerím prepravcom naraz. Následne sa vám stiahnu automaticky čísla zásielok a štítky ktoré si vytlačíte.') },
            { title: __('Kontrola údajov pred odoslaním'), text: __('Náš systém automaticky upravuje údaje objednávok tak aby boli správne zadané, príklad ak vám zákaznik uvedie zlý formát tel. čísla. Náš systém podporuje aj kontrolu produktov cez skenovanie EAN kódov aby ste si boli istý že nachystaná zásielka je v poriadku.') },
            { title: __('Automatizácia procesov'), text: __('Aby ste nemuseli robiť furt tie isté úkony dookola ručne, tak odoslaním zásielok cez náš systém vám automaticky stiahneme čísla zásielok, ktoré odošleme do eshopu, zmeníme stav objednávky po odoslaní alebo po doručení a ak je potrebné pošleme aj email zákaznikovi.') },
        ],
        track: [
            { title: __('Dajte možnosť zákaznikovi sledovať stav'), text: __('Ku každej vašej objednávke poskytujeme verejnú stránku ktorú viete poslať emailom svojim zákaznikom. Na tejto stránke uvidia aktuálny stav svojej objednávky, prípadne si môžu stiahnuť vystavenú faktúru alebo sledovať zásielku. Objednávku je možné aj stornovať alebo reklamovať.') },
            { title: __('Evidencia reklamácii na jednom mieste'), text: __('Cez verejnú stránku objednávku vám vedia vaši klienti jednoducho reklamovať váš produkt. Reklamácia sa následne zobrazí v našom systéme kde ju môžete priradiť konkrétnemu zamestnancovi. Následne po vybavení reklamácie sa tento stav a spôsob vybavenia zobrazí zákaznikovi.') },
            { title: __('Chcete vidieť ukážku?'), text: __('Klikom na tento <a href="{LINK}" target="_blank">odkaz</a> sa vám zobrazí verejná stránka našej testovacej objednávky z DEMO účtu.').replace('{LINK}', `https://goodeshop.${MARKET}/orders/public/242962/4db38639f7c32131d5de0e9349b19cc0e83e8092`) },
        ],
    };

    /**
     * Nahodne clanky.
     *
     * @type {Object|null}
     */
    shuffledArticles = null;

    /**
     * Konstruktor.
     *
     * @param {Object} props
     */
    constructor(props) {
        super(props);

        this.infoRef = React.createRef();
        this.integrationRef = React.createRef();
        this.pricingRef = React.createRef();
        this.contactRef = React.createRef();
    }

    /**
     * Komponenta bola pripojena.
     *
     * @return boolean
     */
    async componentDidMount() {
        // Nacitame statistiky
        const path = this.getPath();

        if (path.indexOf('/set-token/') !== -1) {
            const { cookies } = this.props;

            // Ulozime token do cookie
            cookies.set(COOKIE_TOKEN_NAME, path.replace('/set-token/', ''), COOKIE_SETTINGS);

            window.location = '/dashboard';
            return;
        }

        const { source } = this.props;

        if (!_.isEmpty(source)) {
            // Mame zdroj, ulozime do cookie
            const { cookies } = this.props;

            // Ulozime source do cookie
            cookies.set(COOKIE_SOURCE_NAME, source, COOKIE_SETTINGS);
        }

        if (!super.componentDidMount()) {
            return false;
        }

        if (path.indexOf('/blog/') !== -1) {
            // Nacitavame clanok
            const blogArticle = path.replace('/blog/', '');

            if (_.has(this.blogArticles, blogArticle)) {
                this.setState({ blog: true, blogArticle });
            }
        } else if (path === '/blog') {
            // Nacitavame blog
            this.setState({ blog: true });
        } else if (path === '/affiliate') {
            // Nacitavame affiliate
            this.setState({ affiliate: true });
        } else if (path === '/branding') {
            // Nacitavame branding
            this.setState({ branding: true });
        }

        if (getCustomization() !== null) {
            // Ide o customizaciu
            window.location = '/login';
            return;
        }

        await request('/stats.php', false, 'GET', {}, false).then(response => {
            this.setState({ stats: response.data, readCookies: this.readCookies() });
        }).catch(error => {
            this.setState({ stats: { orders: 0, invoices: 0, packages: 0 }, readCookies: this.readCookies() });
        });

        // Pridame listener
        window.addEventListener('scroll', this.handleScroll);

        return true;
    }

    /**
     * Komponenta bude odpojena.
     */
    componentWillUnmount() {
        // Odstranime listener
        window.removeEventListener('scroll', this.handleScroll);
    }

    /**
     * Handlujeme scrolovanie.
     *
     * @param {Object} event
     */
    handleScroll = event => {
        const { scroll } = this.state;

        if (!scroll && window.scrollY > 0) {
            // Zacali sme scrolovat
            this.setState({ scroll: true });
        } else if (scroll && window.scrollY === 0) {
            // Prestali sme scrolovat
            this.setState({ scroll: false });
        }
    }

    /**
     * Ma precitane cookie?
     *
     * @return {boolean}
     */
    readCookies() {
        // Pozrieme sa ci mame ulozeny token
        const { cookies } = this.props;

        return !_.isUndefined(cookies.get(COOKIE_READ_NAME, COOKIE_SETTINGS));
    }

    /**
     * Scrolujeme navrch.
     */
    scrollToTop() {
        this.scrollTo(0);
    }

    /**
     * Scrolujeme na poziciu.
     *
     * @param {number} top
     */
    scrollTo(top) {
        window.scrollTo({ top, left: 0, behavior: 'smooth' });
    }

    /**
     * Scrolujeme na element.
     *
     * @param {Object} ref
     */
    scrollToElement(ref) {
        this.scrollTo(ref.current.offsetTop - 80);
    }

    /**
     * Zobrazime demo.
     */
    startDemo() {
        const { cookies } = this.props;

        // Ulozime token do cookie
        cookies.set(COOKIE_TOKEN_NAME, DEMO_TOKEN, COOKIE_SETTINGS);

        window.location = '/dashboard';
    }

    /**
     * Event po zmene blog kategorie.
     *
     * @param {string} blogCategory
     */
    onChangeBlogCategory(blogCategory) {
        this.setState({ blogCategory });
    }

    /**
     * Akceptacia cookies.
     */
    acceptCookies() {
        const { cookies } = this.props;

        this.setState({ readCookies: true });

        // Ulozime token do cookie
        cookies.set(COOKIE_READ_NAME, 'yes', COOKIE_SETTINGS);
    }

    /**
     * Vratime platbu.
     *
     * @param {number}  quantity
     *
     * @return {number}
     */
    getPrice(quantity = 1) {
        return MARKET === 'cz'
            ? PRICE_CZ * quantity
            : (MARKET === 'pl'
                ? PRICE_PL * quantity
                : (MARKET === 'hu'
                    ? PRICE_HU * quantity
                    : PRICE * quantity));
    }

    /**
     * Vratime zlavu.
     *
     * @return {number}
     */
    getDiscount() {
        return MARKET === 'cz'
            ? 250
            : (MARKET === 'pl'
                ? 47
                : (MARKET === 'hu'
                    ? 4000
                    : 10));
    }

    /**
     * Vratime menu.
     *
     * @return {string}
     */
    getCurrency() {
        return MARKET === 'cz'
            ? 'CZK'
            : (MARKET === 'pl'
                ? 'PLN'
                : (MARKET === 'hu'
                    ? 'HUF'
                    : 'EUR'));
    }

    /**
     * Rendrujeme sluzbu.
     *
     * @param {string} title
     * @param {string} img
     * @param {string} color
     * @param {Object} positions
     *
     * @return {JSX.Element}
     */
    renderService(title, img, color, positions = {}) {
        let style = { backgroundColor: color };

        if (!_.isEmpty(positions)) {
            style = { ...style, ...positions };
        }

        return (
            <div
                className="extension"
                style={style}
                key={title}
            >
                <Tooltip title={title}>
                    <img
                        className="extension__img"
                        src={IMAGES[img]}
                        alt={title}
                    />
                </Tooltip>
            </div>
        );
    }

    /**
     * Rendrujeme blog.
     *
     * @return {JSX.Element}
     */
    renderBlog() {
        const { blogCategory, blogArticle } = this.state;

        if (blogArticle !== null) {
            // Zobrazujeme clanok
            const article = this.blogArticles[blogArticle];
            const image = `article-${article.id}.png`;

            return (
                <div className="public blog">
                    <div className="public__header">
                        {this.renderMenu()}
                    </div>
                    <div className="public__article">
                        <div className="public__article__content">
                            {global.token === '' ? <Breadcrumbs className="sections">
                                <Link to="/">{__('Hlavná stránka')}</Link>
                                <Link to="/blog">{__('Blog')}</Link>
                                <Link to={`/blog/${article.uri}`}>{article.name}</Link>
                            </Breadcrumbs> : null}
                            <div className="public__article__content__photo" style={{ backgroundColor: article.background }}>
                                <img
                                    src={_.has(IMAGES, image) ? IMAGES[image] : IMAGES['article-1.png']}
                                    alt={article.name}
                                />
                            </div>
                            <div className="public__article__content__title">{article.name}</div>
                            <div
                                className="public__article__content__text"
                                dangerouslySetInnerHTML={{ __html: article.text }}
                            />
                        </div>
                        <div className="public__article__list">
                            {this.renderArticles(4)}
                        </div>
                    </div>
                </div>
            );
        }

        return (
            <div className="public blog">
                <div className="public__header">
                    {this.renderMenu()}
                </div>
                <div className="public__blog">
                    <div className="public__blog__title">{__('Blog')}</div>
                    <div className="public__blog__text">{__('Na tejto stránke nájdete novinky z nášho systému, návody na aktivácie eshopov, ekonomických systémom, skladov, bánk, platobných brán a mnoho iného.')}</div>
                    <div className="public__blog__categories">
                        {_.map(this.blogCategories, (name, key) => {
                            return (
                                <Button
                                    onClick={() => this.onChangeBlogCategory(key)}
                                    key={key}
                                    className={key === blogCategory ? 'active' : ''}
                                >{name}</Button>
                            );
                        })}
                    </div>
                    {this.renderArticles()}
                </div>
            </div>
        );
    }

    /**
     * Rendrujeme affiliate.
     *
     * @return {JSX.Element}
     */
    renderAffiliate() {
        // Cena
        const price = this.getPrice(12);

        // Zlava
        const discount = price * 0.05;

        // Zlavnena cena
        const discountPrice = price - discount;

        // Provizia
        const provision = discountPrice * 0.3;

        return (
            <div className="public affiliate">
                <div className="public__header">
                    {this.renderMenu()}
                </div>
                <div className="public__affiliate">
                    <div className="public__affiliate__title">{__('Affiliate program')}</div>
                    <div className="public__affiliate__text">{__('30% z každej platby pre vás :)')}</div>
                    <div className="public__affiliate__panel">
                        <div className="public__affiliate__panel__title">{__('Naše členstvo')}</div>
                        <div className="public__affiliate__panel__title-text">{__('Zoberme si príklad :)')}</div>
                        <div className="public__affiliate__panel__prices">
                            <div>{__('Túto sumu u nás platí každý eshop ročne. Platí sa vždy na rok dopredu.')}</div>
                            <span>{formatAmount(price, this.getCurrency())}</span>
                            <div>{__('Ako náš partner viete ponuknúť zľavu 5% svojim klientom na prvú platbu.')}</div>
                            <span>-{formatAmount(discount, this.getCurrency())}</span>
                            <div>{__('Klient od vás teda zaplatí')}</div>
                            <span>{formatAmount(discountPrice, this.getCurrency())}</span>
                            <div>{__('Ako náš partner máte z každej platby 30% na svoj účet, od nás dostanete.')}</div>
                            <span>+{formatAmount(provision, this.getCurrency())}</span>
                        </div>
                        <Button
                            onClick={() => this.showLightbox('affiliate')}
                            color="green"
                        >{__('Stať sa partnerom')}</Button>
                    </div>
                </div>
                {this.renderLightbox(
                    'affiliate',
                    __('Affiliate program'),
                    <div className="affiliate-lightbox">{__('Aktuálne si vyberáme partnerov pre náš Affiliate program. Ak by ste mali záujem tak nás prosím kontaktujte na')}<a href="mailto:info@goodeshop.sk">info@goodeshop.sk</a></div>,
                    '',
                    __('Zrušiť'),
                )}
            </div>
        );
    }

    /**
     * Rendrujeme branding.
     *
     * @return {JSX.Element}
     */
    renderBranding() {
        return (
            <div className="public branding">
                <div className="public__header">
                    {this.renderMenu()}
                </div>
                <div className="public__branding">
                    <div className="public__branding__title">{__('Branding')}</div>
                    <div className="public__branding__text">{__('Ak ste digitálna agentúra alebo webové štúdio vieme vám poskytnúť náš systém obrandovaný pod vašou značkou.')}</div>
                    <div className="public__branding__subtext">{__('Vyriešime za vás napojenia na externé systémy a mnoho iných funkcií pre vaše eshopy :)')}</div>
                    <div className="public__branding__windows">
                        <div className="public__branding__windows__panel">
                            <div className="public__branding__windows__panel__title">{__('Vlastná doména')}</div>
                            <div className="public__branding__windows__panel__text">{__('Podporujeme nastavenie DNS záznamu pre subdoménu na vašej strane.')}</div>
                        </div>
                        <div className="public__branding__windows__panel">
                            <div className="public__branding__windows__panel__title">{__('Vlastné logo a farby')}</div>
                            <div className="public__branding__windows__panel__text">{__('Nastavíme vám vaše logo a farby miesto tých našich.')}</div>
                        </div>
                        <div className="public__branding__windows__panel">
                            <div className="public__branding__windows__panel__title">{__('Fakturujeme priamo vám')}</div>
                            <div className="public__branding__windows__panel__text">{__('My vám budeme fakturovať našu cenu za každý eshop. Vaše eshopy si budete spoplatnovať na vašej strane.')}</div>
                        </div>
                        <div className="public__branding__windows__panel">
                            <div className="public__branding__windows__panel__title">{__('Správa systému')}</div>
                            <div className="public__branding__windows__panel__text">{__('Systém spravujeme stále my aj poskytujeme technickú podporu pri riešení problémov.')}</div>
                        </div>
                        <div className="public__branding__windows__panel">
                            <div className="public__branding__windows__panel__title">{__('Verejná stránka a cenník')}</div>
                            <div className="public__branding__windows__panel__text">{__('Takéto údaje vaši klienti priamo nevidia. Majú dostupný len prihlasovací formulár z verejných stránok.')}</div>
                        </div>
                        <div className="public__branding__windows__panel">
                            <div className="public__branding__windows__panel__title">{__('Všetko pod hlavným účtom')}</div>
                            <div className="public__branding__windows__panel__text">{__('Vám ako agentúre nastavíme hlavný účet cez ktorý budete vedieť pristupovať ku všetkým vašim eshopom.')}</div>
                        </div>
                    </div>
                    <Button
                        onClick={() => this.showLightbox('branding')}
                        color="green"
                    >{__('Ideme do toho!')}</Button>
                </div>
                {this.renderLightbox(
                    'branding',
                    __('Branding'),
                    <div className="branding-lightbox">{__('Ak by ste mali záujem tak nás prosím kontaktujte na')}<a href="mailto:info@goodeshop.sk">info@goodeshop.sk</a></div>,
                    '',
                    __('Zrušiť'),
                )}
            </div>
        );
    }

    /**
     * Rendrujeme clanky.
     *
     * @param {number} limit
     *
     * @return {JSX.Element}
     */
    renderArticles(limit = 0) {
        const { blogCategory } = this.state;

        let articles = this.blogArticles;
        let counter = 0;

        if (limit > 0) {
            // Mame limit, urobime nahodne poradie
            articles = this.shuffledArticles !== null ? this.shuffledArticles : _.shuffle(articles);

            this.shuffledArticles = articles;
        }

        return (
            <div className="articles">
                {_.map(articles, ({ id, uri, category, name, text }) => {
                    counter++;

                    if (limit > 0 && counter > limit) {
                        // Nezobrazujeme
                        return null;
                    }

                    const image = `article-${id}.png`;

                    if (blogCategory !== 'all' && blogCategory !== category) {
                        // Nezobrazujeme
                        return null;
                    }

                    return (
                        <ButtonBase
                            onClick={() => this.redirect(`/blog/${uri}`)}
                            className="articles__article"
                            key={uri}
                        >
                            <img
                                src={_.has(IMAGES, image) ? IMAGES[image] : IMAGES['article-1.png']}
                                alt={name}
                            />
                            <div className="articles__article__content">
                                <div className="articles__article__content__category">{this.blogCategories[category]}</div>
                                <div className="articles__article__content__name">{name}</div>
                                <div className="articles__article__content__text"
                                     dangerouslySetInnerHTML={{ __html: _.truncate(text.replace('<p>', '').replace('</p>', '').replace(/<a.*?<\/a>/g, ''), { length: 130 }) }}
                                />
                                <div className="articles__article__content__button">
                                    <ArrowRightIcon />
                                    <span>{__('Prečítať článok')}</span>
                                </div>
                            </div>
                        </ButtonBase>
                    );
                })}
            </div>
        );
    }

    /**
     * Rendrujeme menu.
     *
     * @return {JSX.Element}
     */
    renderMenu() {
        const { scroll, blog, affiliate, branding } = this.state;

        // Zobrazujeme webareal?
        const isWebareal = this.isWebareal();

        const loginButton = <Button
            onClick={() => this.redirect(affiliate ? '/affiliate-login' : '/login')}
            className="login-button"
        >{__('Prihlásenie')}</Button>;

        return (
            <div className={`public__header__menu ${scroll ? 'menu__mini' : ''}`}>
                <div className="public__header__menu__content">
                    <Logo
                        onClick={this.isMobile() ? () => { window.location = '/'; } : () => this.scrollToTop()}
                        color="dark"
                        className="public__header__menu__content__logo"
                    />
                    <div className="public__header__menu__content__buttons">
                        {!blog && !affiliate && !branding ? <Button onClick={() => this.scrollToElement(this.infoRef)}>{__('Naše riešenie')}</Button> : null}
                        {!blog && !affiliate && !branding ? <Button onClick={() => this.scrollToElement(this.integrationRef)}>{__('Integrácie')}</Button> : null}
                        {!blog && !affiliate && !branding ? <Button onClick={() => this.scrollToElement(this.pricingRef)}>{__('Cenník')}</Button> : null}
                        {!blog && !affiliate && !branding
                            ? <Button onClick={() => this.redirect('/blog')}>{__('Blog')}</Button>
                            : (global.token === '' ? <Button onClick={() => this.redirect('/')}>{__('Naspäť na hlavnú stránku')}</Button> : null)}
                        {global.token === '' && !isWebareal ? loginButton : null}
                    </div>
                    <div className="public__header__menu__content__trial">
                        {global.token === '' && !isWebareal ? <Button
                            onClick={affiliate
                                ? () => this.showLightbox('affiliate')
                                : (branding ? () => this.showLightbox('branding') : () => this.redirect('/register'))}
                            color="green"
                        >{affiliate
                            ? __('Stať sa partnerom')
                            : (branding ? __('Ideme do toho!') : __('7 dní zdarma'))}</Button> : null}
                        {isWebareal ? loginButton : null}
                    </div>
                </div>
            </div>
        );
    }

    /**
     * Rendrovanie.
     *
     * @returns {JSX.Element}
     */
    render() {
        const { stats, blog, affiliate, readCookies, tab, branding } = this.state;
        const { cookies } = this.props;

        if (_.isEmpty(stats)) {
            // Nemame stats, zobrazime loading
            return <div className="public">{this.renderLoading()}</div>;
        }

        if (blog) {
            // Zobrazujeme blog
            return this.renderBlog();
        } else if (affiliate) {
            // Zobrazujeme affiliate
            return this.renderAffiliate();
        } else if (branding) {
            // Zobrazujeme branding
            return this.renderBranding();
        }

        // VOP url
        const vopUrl = MARKET === 'cz' ? '/vop-cz.pdf?v=1' : '/vop.pdf?v=1';

        // Vytiahneme zdroj
        const source = cookies.get(COOKIE_SOURCE_NAME, COOKIE_SETTINGS);

        return (
            <div className="public">
                <div className="public__header">
                    <div className="public__header__head">
                        <div className="public__header__head__content">
                            <div className="public__header__head__content__countries">
                                <a href={`https://goodeshop.sk${!_.isEmpty(source) ? ('?source=' + source) : ''}`}><ReactCountryFlag countryCode="SK" svg /></a>
                                <a href={`https://goodeshop.cz${!_.isEmpty(source) ? ('?source=' + source) : ''}`}><ReactCountryFlag countryCode="CZ" svg /></a>
                                <a href={`https://goodeshop.pl${!_.isEmpty(source) ? ('?source=' + source) : ''}`}><ReactCountryFlag countryCode="PL" svg /></a>
                                <a href={`https://goodeshop.hu${!_.isEmpty(source) ? ('?source=' + source) : ''}`}><ReactCountryFlag countryCode="HU" svg /></a>
                            </div>
                            <div className="public__header__head__content__buttons">
                                <ButtonBase onClick={() => this.redirect('/branding')}>{__('Branding')}</ButtonBase>
                                <ButtonBase onClick={() => this.redirect('/affiliate')}>{__('Affiliate program')}</ButtonBase>
                                <ButtonBase onClick={() => this.scrollToElement(this.contactRef)}>{__('Kontakt')}</ButtonBase>
                                <a href={MARKET === 'cz'
                                    ? 'https://www.facebook.com/goodeshopcz'
                                    : 'https://www.facebook.com/goodeshopsk'} target="_blank">
                                    <img src={IMAGES['facebook.png']} alt="facebook" />
                                </a>
                            </div>
                        </div>
                    </div>
                    {this.renderMenu()}
                    <div className="public__header__content">
                        <div className="public__header__content__slogan">{__('Pomáhame eshopom :)')}</div>
                        <div className="public__header__content__title">{__('Kompletné')}<span>{__('Externé')}</span></div>
                        <div className="public__header__content__subtitle">{__('riešenie pre')}<span>{__('Eshop')}</span></div>
                        <div className="public__header__content__text">{__('Spravujte eshop a jeho prepojenia na rôzne služby na jednom mieste.')}</div>
                        <Button onClick={() => this.startDemo()}>{__('Vyskúšať DEMO účet')}</Button>
                        <img src={IMAGES['public.png']} alt="public" />
                        {_.map(ESHOPS, (name, key) => {
                            if (key === 'Blank') {
                                return null;
                            }

                            return this.renderService(name, `${key.toLowerCase()}.png`, ESHOP_COLORS[key].background, ESHOP_POSITIONS[key] );
                        })}
                        {_.map(COURIERS, (name, key) => this.renderService(name, `${key.toLowerCase()}.png`, COURIER_COLORS[key].background, COURIER_POSITIONS[key] ))}
                        {_.map(ACCOUNTINGS, (name, key) => this.renderService(name, `${key.toLowerCase()}.png`, ACCOUNTING_COLORS[key].background, ACCOUNTING_POSITIONS[key] ))}
                        {_.map(BANKS, (name, key) => this.renderService(name, `${key.toLowerCase()}.png`, BANK_COLORS[key].background, BANK_POSITIONS[key] ))}
                        {_.map(GATEWAYS, (name, key) => this.renderService(name, `${key.toLowerCase()}.png`, GATEWAYS_COLORS[key].background, GATEWAYS_POSITIONS[key] ))}
                        {_.map(MARKETPLACES, (name, key) => this.renderService(name, `${key.toLowerCase()}.png`, MARKETPLACES_COLORS[key].background, MARKETPLACES_POSITIONS[key] ))}
                    </div>
                </div>
                <div className="public__windows">
                    <div className="public__windows__panel">
                        <div className="public__windows__panel__title">{__('Pod jednou strechou')}</div>
                        <div className="public__windows__panel__text">{__('Centralizujeme vám všetky možné prepojenia vášho eshopu na jedno miesto pre vás a vašich zamestnancov.')}</div>
                    </div>
                    <div className="public__windows__panel">
                        <div className="public__windows__panel__title">{__('Prehladnosť a dostupnosť')}</div>
                        <div className="public__windows__panel__text">{__('Naša aplikácia je jednoduchá na používanie. U nás máte dostupné všetky potrebné dáta eshopu online 24/7.')}</div>
                    </div>
                    <div className="public__windows__panel">
                        <div className="public__windows__panel__title">{__('Automatizácia a bezpečnosť')}</div>
                        <div className="public__windows__panel__text">{__('Automatizujeme denné úkony s eshopom. Vaše dáta držíme v bezpečí. Sú cryptované cez najnovšie technológie.')}</div>
                    </div>
                </div>
                <div className="public__info" ref={this.infoRef}>
                    <div className="public__info__title">{__('Naše riešenie')}</div>
                    <div className="public__info__text">{__('Dostupné odkiaľkoľvek aj na mobiloch.')}</div>
                    <Tabs
                        value={tab}
                        onChange={(event, tab) => this.setState({ tab })}
                        className="public__info__tabs"
                    >
                        <Tab label={__('Správa objednávok')} value="orders" />
                        <Tab label={__('Fakturácia')} value="invoices" />
                        <Tab label={__('Sklad a produkty')} value="products" />
                        <Tab label={__('Expedícia zásielok')} value="packages" />
                        <Tab label={__('Sledovanie objednávky')} value="track" />
                    </Tabs>
                    <div className="public__info__tabs-content">
                        <div className="public__info__tabs-content__panel">
                            <div className="public__info__tabs-content__panel__title">{this.tabs[tab][0].title}</div>
                            <div className="public__info__tabs-content__panel__text">{this.tabs[tab][0].text}</div>
                        </div>
                        <div className="public__info__tabs-content__panel">
                            <div className="public__info__tabs-content__panel__title">{this.tabs[tab][1].title}</div>
                            <div className="public__info__tabs-content__panel__text">{this.tabs[tab][1].text}</div>
                        </div>
                        <div className="public__info__tabs-content__panel">
                            <div className="public__info__tabs-content__panel__title">{this.tabs[tab][2].title}</div>
                            <div
                                className="public__info__tabs-content__panel__text"
                                dangerouslySetInnerHTML={{ __html: this.tabs[tab][2].text }}
                            />
                        </div>
                    </div>
                    <div className="public__info__bigtext">{__('Pripravili sme pre vás DEMO účet s testovacími objednávkami na ktorých si môžete vyskúšať našu službu. Stačí kliknúť nižšie na tlačítko.')}</div>
                    <div className="public__info__extensions-text">{__('Aktívne napojenia na DEMO účte')}</div>
                    <div className="public__info__extensions">
                        {this.renderService(ESHOPS.WooCommerce, 'woocommerce.png', ESHOP_COLORS.WooCommerce.background)}
                        {this.renderService(ACCOUNTINGS.SuperFaktura, 'superfaktura.png', ACCOUNTING_COLORS.SuperFaktura.background)}
                        {this.renderService(COURIERS.Qdl, 'qdl.png', COURIER_COLORS.Qdl.background)}
                    </div>
                    <Button onClick={() => this.startDemo()}>{__('Prejsť do DEMO účtu')}</Button>
                </div>
                <div className="public__custom">
                    <div className="public__custom__title">{__('Vaše požiadavky na mieru')}</div>
                    <div className="public__custom__text">{__('Uvedomujeme si že každý eshop má svoje jedinečné požiadavky podľa ktorých funguje. Vyvynuli sme náš systém tak aby sme vedeli vašim požiadavkam na mieru pomerne ľahko vyhovieť. Na tomto si dávame záležať a prezentujeme to ako našu najväčšiu konkurenčnú výhodu ktorú vám žiadny iný softvér neposkytne.')}</div>
                    <div className="public__custom__text2">{__('Pod to sa podpisujeme :)')}</div>
                </div>
                <div className="public__clients">
                    <div className="public__clients__title">{__('Stovky spokojných klientov')}</div>
                    <div className="public__clients__text">{__('Pridajte sa k nám :)')}</div>
                    <div className="public__clients__logos">
                        <img src={IMAGES['clients-5.png']} alt="client logo 5" />
                        <img src={IMAGES['clients-2.png']} alt="client logo 2" />
                        <img src={IMAGES['clients-1.png']} alt="client logo 1" />
                        <img src={IMAGES['clients-3.png']} alt="client logo 3" />
                        <img src={IMAGES['clients-4.png']} alt="client logo 4" />
                        <img src={IMAGES['clients-6.png']} alt="client logo 6" />
                        <img src={IMAGES['clients-7.png']} alt="client logo 7" />
                        <img src={IMAGES['clients-8.png']} alt="client logo 8" />
                    </div>
                </div>
                <div className="public__integrations" ref={this.integrationRef}>
                    <div className="public__integrations__title">{__('Integrácie pre vás')}</div>
                    <div className="public__integrations__text">{__('Potrebujete iné riešenie?')}<br />{__('Kontaktujte nás a zadarmo doplníme :)')}</div>
                    <div className="public__integrations__panels">
                        <div className="public__integrations__panels__panel">
                            <div className="public__integrations__panels__panel__title">{__('Eshopy')}</div>
                            <div className="public__integrations__panels__panel__logos mini">
                                {_.map(ESHOPS, (name, key) => {
                                    if (key === 'Blank') {
                                        return null;
                                    }

                                    return this.renderService(name, `${key.toLowerCase()}.png`, ESHOP_COLORS[key].background);
                                })}
                            </div>
                        </div>
                        <div className="public__integrations__panels__panel">
                            <div className="public__integrations__panels__panel__title">{__('Ekonomika')}</div>
                            <div className="public__integrations__panels__panel__logos mini">
                                {_.map(ACCOUNTINGS, (name, key) => {
                                    if (key === 'Kros') {
                                        // Nezobrazujeme
                                        return null;
                                    }

                                    return this.renderService(name, `${key.toLowerCase()}.png`, ACCOUNTING_COLORS[key].background);
                                })}
                            </div>
                        </div>
                        <div className="public__integrations__panels__panel">
                            <div className="public__integrations__panels__panel__title">{__('Prepravcovia')}</div>
                            <div className="public__integrations__panels__panel__logos mini">
                                {_.map(COURIERS, (name, key) => this.renderService(name, `${key.toLowerCase()}.png`, COURIER_COLORS[key].background))}
                            </div>
                        </div>
                        <div className="public__integrations__panels__panel">
                            <div className="public__integrations__panels__panel__title">{__('Sklady')}</div>
                            <div className="public__integrations__panels__panel__logos mini">
                                {_.map(STORAGES, (name, key) => _.has(STORAGE_COLORS, key) ? this.renderService(name, `${key.toLowerCase()}.png`, STORAGE_COLORS[key].background) : null)}
                            </div>
                        </div>
                        <div className="public__integrations__panels__panel">
                            <div className="public__integrations__panels__panel__title">{__('Banky')}</div>
                            <div className="public__integrations__panels__panel__logos mini">
                                {_.map({ ...BANKS, ...GATEWAYS }, (name, key) => this.renderService(name, `${key.toLowerCase()}.png`, _.has(BANK_COLORS, key)
                                    ? BANK_COLORS[key].background
                                    : GATEWAYS_COLORS[key].background))}
                            </div>
                        </div>
                        <div className="public__integrations__panels__panel">
                            <div className="public__integrations__panels__panel__title">{__('Trhoviská')}</div>
                            <div className="public__integrations__panels__panel__logos mini">
                                {_.map(MARKETPLACES, (name, key) => _.has(MARKETPLACES_COLORS, key) ? this.renderService(name, `${key.toLowerCase()}.png`, MARKETPLACES_COLORS[key].background) : null)}
                            </div>
                        </div>
                    </div>
                    <div className="public__integrations__api">
                        <div className="public__integrations__api__title">{__('Máte vlastné riešenie eshopu? Nevadí máme API :)')}</div>
                        <Button onClick={() => window.open('https://goodeshop.docs.apiary.io/', '_blank')}>{__('API dokumentácia')}</Button>
                    </div>
                </div>
                <div className="public__security" ref={this.pricingRef}>
                    <div className="public__security__title">{__('Vaše dáta v bezpečí!')}</div>
                    <div className="public__security__text">{__('Všetky vaše dáta uložené u nás sú zacryptované formou najnovších technológii.')}</div>
                    <div className="public__security__bigtext">{__('Nižšie si môžete pozrieť ako vyzerajú dáta uložené v našich systémoch. Zamestnanci ani prípadny útočník ktorý by odcudzil naše dáta ich nevie rozlúštiť bez klúčov. Dáta sú dekódované až po vašom zobrazení vo vašom účte. Vaše dáta neposkytujeme žiadnej tretej strane. Po zmazaní konkrétnych dát u nás sú zmazané natrvalo, neuchovávame žiadne dáta.')}</div>
                    <div className="public__security__panels">
                        <div className="public__security__panels__panel">
                            <div className="public__security__panels__panel__title">{__('Dáta z vášho eshopu')}</div>
                            <div className="public__security__panels__panel__data">{`{"client":{"id":"9daa0437","name":"Štefan Mrkva","phone":"+421909090909","zip":"83106"}}`}</div>
                        </div>
                        <div className="public__security__panels__panel">
                            <div className="public__security__panels__panel__title">{__('Dáta uložené v našom systéme')}</div>
                            <div className="public__security__panels__panel__data">{`mFKX9xqIlMeoHKri6sDFTkwLIJMPjATxFBzCmMTzEDne5wVy8NDSn2YZrk1mXARNLWLNyGxXEWdjJ2hQ2YTBdKiRlGwEK+EhhwfyzwoV9Dhq7YyLIyKA/nma1LWNWrfj`}</div>
                        </div>
                    </div>
                </div>
                {toNumber(stats.orders) > 0 ? <div className="public__stats">
                    <div className="public__stats__panels">
                        <div className="public__stats__panels__panel">
                            <span>{formatAmount(stats.orders, '', 0)}</span>
                            <span>{__('objednávok')}</span>
                        </div>
                        <div className="public__stats__panels__panel">
                            <span>{formatAmount(stats.invoices, '', 0)}</span>
                            <span>{__('faktúr')}</span>
                        </div>
                        <div className="public__stats__panels__panel">
                            <span>{formatAmount(stats.packages, '', 0)}</span>
                            <span>{__('zásielok')}</span>
                        </div>
                    </div>
                </div> : null}
                <div className="public__pricing" ref={this.pricingRef}>
                    <div className="public__pricing__title">{__('Koľko zaplatíte?')}</div>
                    <div className="public__pricing__text">{__('Žiadne skryté poplatky, len fixná suma.')}</div>
                    <div className="public__pricing__panel shadow">
                        <div className="public__pricing__panel__text">{__('Cena obsahuje všetky naše funkcionality.')}</div>
                        <div className="public__pricing__panel__func">- {__('Neobmedzené množstvo objednávok a produktov')}</div>
                        <div className="public__pricing__panel__func">- {__('Fakturácia a evidencia úhrad')}</div>
                        <div className="public__pricing__panel__func">- {__('Odosielanie zásielok prepravcom')}</div>
                        <div className="public__pricing__panel__func">- {__('Synchronizácia produktov so skladom')}</div>
                        <div className="public__pricing__panel__func">- {__('Párovanie úhrad')}</div>
                        <div className="public__pricing__panel__func">- {__('Načítanie objednávok z trhovísk')}</div>
                        <div className="public__pricing__panel__func">- {__('Mailing a exporty')}</div>
                        <div className="public__pricing__panel__func">- {__('Sledovanie objednávky')}</div>
                        <div className="public__pricing__panel__func">- {__('Evidencia zamestnancov a práv')}</div>
                        <div className="public__pricing__panel__func">- {__('Evidencia reklamácii')}</div>
                        <div className="public__pricing__panel__func">{__('a mnoho ďalších...')}</div>
                        <div className="public__pricing__panel__price">
                            <span>{formatAmount(this.getPrice(), this.getCurrency())}</span>
                            <span className="public__pricing__panel__price__text">{__('finálna mesačná platba')}</span>
                            <span className="public__pricing__panel__price__discount">{__('Garancia vrátenia peňazí do 14 dní.')}</span>
                        </div>
                        <Button
                            onClick={() => this.redirect('/register')}
                            color="green"
                        >{__('7 dní zdarma')}</Button>
                    </div>
                </div>
                <div className="public__contact" ref={this.contactRef}>
                    <div className="public__contact__title">
                        <span>{__('Máte otázky, potrebujete poradiť?')}</span><br />
                        <span>{__('Sme pripravení!')}</span>
                    </div>
                    <div className="public__contact__subtitle">
                        <span>{__('Pracovná doba:')} </span><b>Po - Pi (09-16)</b>
                    </div>
                    <div className="public__contact__email">
                        <div className="public__contact__email__icon">
                            <EmailIcon />
                        </div>
                        <div className="public__contact__email__value">info@goodeshop.sk</div>
                    </div>
                </div>
                <div className="public__trial">
                    <div className="public__trial__title">{__('Vyskúšajte nás na 7 dní zadarmo.')}</div>
                    <div className="public__trial__text">
                        <span>{__('Šetríme denne hodiny práce viac ako 500 eshopom vo viac ako 8 krajinách.')}</span>
                    </div>
                    <Button
                        onClick={() => this.redirect('/register')}
                        color="green"
                    >{__('Vyskúšať zadarmo')}</Button>
                    <div className="public__trial__footer">
                        <Button onClick={() => this.redirect('/login')}>{__('Prihlásiť sa')}</Button>
                        <Button onClick={() => this.scrollToElement(this.contactRef)}>{__('Kontakt')}</Button>
                        <Button onClick={() => window.open(vopUrl, '_blank')}>{__('Obchodné podmienky')}</Button>
                        <Button onClick={() => window.open(vopUrl, '_blank')}>{__('Ochrana osobných údajov')}</Button>
                    </div>
                    <div className="public__trial__footer-text">Goodeshop.sk, IČO: 50009583, Karpatské námestie 7770/10A, 83106 Bratislava</div>
                </div>
                {!readCookies ? <div className="public__cookies">
                    <div className="public__cookies__content">
                        <div className="public__cookies__content__text">{__('Súbory cookie používame k správnemu fungovaniu webu, pre reklamné a štatistické účely a k prispôsobeniu obsahu webu individuálnym potrebám užívateľa. Pokiaľ nesúhlasíte s používaním našich súborov cookie tak ich zakážte pre našu webovú stránku v nastaveniach svojho prehliadača.')}</div>
                        <Button
                            onClick={() => this.acceptCookies()}
                        >{__('Súhlasím')}</Button>
                    </div>
                </div> : null}
            </div>
        );
    }
}

const stateToProps = ({ user }) => ({ user });

export default withCookies(connect(stateToProps)(PublicScreen));
