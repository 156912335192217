import {FETCH_MTOKNA_VIDEOS, CLEAN_MTOKNA_VIDEOS, DELETE_MTOKNA_VIDEO} from '../actions/types';

const MTOKNA_VIDEOS_DEFAULT_STATE = {};

/**
 * Reducer pre sablony.
 */
const MtoknaVideosReducer = (state = MTOKNA_VIDEOS_DEFAULT_STATE, { type, payload }) => {
    switch (type) {
        case FETCH_MTOKNA_VIDEOS:
            return payload;

        case CLEAN_MTOKNA_VIDEOS:
        case DELETE_MTOKNA_VIDEO:
            return MTOKNA_VIDEOS_DEFAULT_STATE;

        default:
            return state;
    }
};

export default MtoknaVideosReducer;
