import { FETCH_MTOKNA_WINDOW_SETTINGS, CLEAN_MTOKNA_WINDOW_SETTINGS } from './types';
import { request } from '../functions';

export const fetchMtoknaWindowSettings = (screen) => {
    return dispatch => request('/mtokna/viewWindowSettings').then(response => {
        const { data, sync } = response.data;
        const { setUser } = screen.props;

        // Nasetujeme user data
        setUser(sync.user);

        dispatch({ type: FETCH_MTOKNA_WINDOW_SETTINGS, payload: data });
    });
};

export const cleanMtoknaWindowSettings = () => ({ type: CLEAN_MTOKNA_WINDOW_SETTINGS });
