import React from 'react';
import _ from 'lodash';
import { Chip } from '@mui/material';
import { Navigate } from './Navigate';
import '../assets/styles/content-header.css';

/**
 * Content header komponenta.
 */
class ContentHeader extends Navigate {
    /**
     * Default props.
     *
     * @type {Object}
     */
    static defaultProps = {
        title: '',
        tags: [],
        rightContent: null,
    };

    /**
     * Rendrovanie.
     *
     * @return {JSX.Element|null}
     */
    render() {
        const { title, tags, rightContent } = this.props;

        return (
            <div className="content-header">
                <div className="content-header__left">
                    <div className="content-header__left__title">{title}</div>
                    <div className="content-header__left__tags">
                        {_.map(tags, (tag, key) => {
                            if (tag === null) {
                                return null;
                            }

                            return <Chip key={key} { ...tag } />;
                        })}
                    </div>
                </div>
                <div className="content-header__right">{rightContent}</div>
            </div>
        );
    }
}

export { ContentHeader };
