import React from 'react';
import { connect } from 'react-redux';
import { withCookies } from 'react-cookie';
import _ from 'lodash';
import {Tab, Table, TableBody, TableCell, TableHead, TableRow, Tabs} from '@mui/material';
import { Screen } from '../components';
import {__, formatAmount, formatDate, request, toNumber} from '../functions';
import { COOKIE_SETTINGS, COOKIE_AFFILIATE_NAME } from '../config';
import '../assets/styles/affiliate-portal.css';

/**
 * Affiliate.
 */
class AffiliatePortalScreen extends Screen {
    /**
     * Title.
     *
     * @type {string}
     */
    title = __('Affiliate');

    /**
     * Default state.
     *
     * @type {Object}
     */
    state = {
        data: null,
        tab: 'dashboard',
    };

    /**
     * Auth required.
     *
     * @type {boolean}
     */
    authRequired = false;

    /**
     * Komponenta bola pripojena.
     *
     * @return boolean
     */
    componentDidMount() {
        if (super.componentDidMount()) {
            // Pozrieme sa ci mame ulozeny token
            const { cookies } = this.props;

            const token = cookies.get(COOKIE_AFFILIATE_NAME, COOKIE_SETTINGS);

            if (_.isUndefined(token)) {
                // Nie je prihlaseny
                window.location = '/affiliate-login';
                return false;
            }

            request(`/affiliates/dashboard/${token}`).then(response => {
                const { status, data } = response.data;

                if (status === 'error') {
                    // Prihlasenie sa nepodarilo
                    window.location = '/affiliate-login';
                    return;
                }

                this.setState({ data });
            });
        }
    }

    /**
     * Rendrujeme tab.
     *
     * @return {null}
     */
    renderTab() {
        const { data, tab } = this.state;

        switch (tab) {
            case 'payments':
                return (
                    <div className="affiliate-portal__window__payments">
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell>{__('Dátum')}</TableCell>
                                    <TableCell>{__('Eshop')}</TableCell>
                                    <TableCell>{__('Suma')}</TableCell>
                                    <TableCell>{__('Provízia')}</TableCell>
                                    <TableCell>{__('Vyplatené?')}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {_.map(data.payments_list, (item, key) => {
                                    return (
                                        <TableRow key={key}>
                                            <TableCell>{formatDate(item.created, 'dd.mm.yyyy hh:ii')}</TableCell>
                                            <TableCell>{item.eshop_name}</TableCell>
                                            <TableCell>{formatAmount(item.amount, 'EUR')}</TableCell>
                                            <TableCell>{formatAmount(item.provision, 'EUR')}</TableCell>
                                            <TableCell>{toNumber(item.paid) === 1 ? __('Áno') : __('Nie')}</TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </div>
                );

            case 'dashboard':
                const link = `https://goodeshop.sk?source=${data.name}`;
                const notAffiliate = data.name === 'bu1';

                return (
                    <div className="affiliate-portal__window__dashboard">
                        {!notAffiliate ? <div className="affiliate-portal__window__dashboard__link">
                            <span>{__('Váš referenčný odkaz')}:</span>
                            <a href={link} target="_blank">{link}</a>
                        </div> : null}
                        <div className="affiliate-portal__window__dashboard__panels">
                            {!notAffiliate ? <div className="affiliate-portal__window__dashboard__panels__panel">
                                <div className="affiliate-portal__window__dashboard__panels__panel__name">{__('Užívatelia')}</div>
                                <div className="affiliate-portal__window__dashboard__panels__panel__value">
                                    <span>{data.users}</span>
                                    <span>+{data.users_diff}</span>
                                </div>
                            </div> : null}
                            {!notAffiliate ? <div className="affiliate-portal__window__dashboard__panels__panel">
                                <div className="affiliate-portal__window__dashboard__panels__panel__name">{__('Eshopy')}</div>
                                <div className="affiliate-portal__window__dashboard__panels__panel__value">
                                    <span>{data.eshops}</span>
                                    <span>+{data.eshops_diff}</span>
                                </div>
                            </div> : null}
                            <div className="affiliate-portal__window__dashboard__panels__panel">
                                <div className="affiliate-portal__window__dashboard__panels__panel__name">{__('Platby')}</div>
                                <div className="affiliate-portal__window__dashboard__panels__panel__value">
                                    <span>{formatAmount(data.payments, 'EUR')}</span>
                                    <span>+{formatAmount(data.payments_diff, 'EUR')}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                );

            default:
                return null;
        }
    }

    /**
     * Rendrovanie.
     *
     * @returns {JSX.Element}
     */
    render() {
        const { data, tab } = this.state;

        if (_.isEmpty(data)) {
            return this.renderLoading();
        }

        return (
            <div className="affiliate-portal">
                <div className="affiliate-portal__window">
                    <div className="affiliate-portal__window__header">
                        <div className="affiliate-portal__window__header__title">{__('Affiliate portál')}</div>
                        <div className="affiliate-portal__window__header__right">
                            <div className="affiliate-portal__window__header__right__name">{data.name}</div>
                            <a href="/affiliate-logout">{__('Odhlásenie')}</a>
                        </div>
                    </div>
                    <Tabs
                        value={tab}
                        onChange={(event, tab) => this.setState({ tab })}
                        className="affiliate-portal__window__tabs"
                    >
                        <Tab label={__('Dashboard')} value="dashboard" />
                        <Tab label={__('Platby')} value="payments" />
                    </Tabs>
                    {this.renderTab()}
                </div>
            </div>
        );
    }
}

const stateToProps = ({ user }) => ({ user });

export default withCookies(connect(stateToProps)(AffiliatePortalScreen));
