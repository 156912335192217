import {FETCH_MTOKNA_FILES, CLEAN_MTOKNA_FILES, DELETE_MTOKNA_FILE} from '../actions/types';

const MTOKNA_FILES_DEFAULT_STATE = {};

/**
 * Reducer pre sablony.
 */
const MtoknaFilesReducer = (state = MTOKNA_FILES_DEFAULT_STATE, { type, payload }) => {
    switch (type) {
        case FETCH_MTOKNA_FILES:
            return payload;

        case CLEAN_MTOKNA_FILES:
        case DELETE_MTOKNA_FILE:
            return MTOKNA_FILES_DEFAULT_STATE;

        default:
            return state;
    }
};

export default MtoknaFilesReducer;
