import React from 'react';
import _ from 'lodash';
import ViewIcon from '@mui/icons-material/Folder';
import DeleteIcon from '@mui/icons-material/Delete';
import NoteIcon from '@mui/icons-material/Create';
import { __ } from './__';

/**
 * Vratime buttony pre klienta.
 *
 * @param {Object} item
 * @param {Object} options
 *
 * @return {Object}
 */
export const getProductButtons = (item, options = {}) => {
    options = { ...{
        hasPermissionView: false,
        hasPermissionStock: false,
        hasPermissionDelete: false,
    }, ...options };

    let buttons = {
        view: {
            name: __('Zobraziť detail'),
            icon: <ViewIcon />,
            callback: () => {},
            options: {},
        },
        stock: {
            name: __('Zmeniť stav na sklade'),
            icon: <NoteIcon />,
            callback: () => {},
            options: {},
        },
        delete: {
            name: __('Zmazať'),
            icon: <DeleteIcon />,
            callback: () => {},
            options: {},
        },
    };

    if (!options.hasPermissionView) {
        // Nema pravo
        buttons = _.omit(buttons, ['view']);
    }

    if (!options.hasPermissionStock) {
        // Nema pravo
        buttons = _.omit(buttons, ['stock']);
    }

    if (!options.hasPermissionDelete) {
        // Nema pravo
        buttons = _.omit(buttons, ['delete']);
    }

    return buttons;
};
