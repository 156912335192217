import {
    FETCH_LABELS,
    CLEAN_LABELS,
    DELETE_LABEL,
    DOWNLOAD_LABEL,
    CREATE_COURIER,
    DELETE_COURIER,
} from '../actions/types';
import _ from 'lodash';
import { toNumber } from '../functions';

const LABELS_DEFAULT_STATE = {};

/**
 * Reducer pre stitky.
 */
const LabelsReducer = (state = LABELS_DEFAULT_STATE, { type, payload }) => {
    switch (type) {
        case FETCH_LABELS:
            // Nacitanie stitkov
            return payload;

        case DOWNLOAD_LABEL:
            // Stiahnutie stitku
            return { ...state, items: _.map(state.items, item => {
                if (toNumber(item.id) === toNumber(payload)) {
                    // Nasli sme stitok, zmenime status
                    item = { ...item, status: 'download' };
                }

                return item;
            }) };

        case CREATE_COURIER:
            // Vytvorime prepravcu
            return { ...state, user_couriers: [ ...state.user_couriers, ...[payload] ] };

        case DELETE_COURIER:
            // Zrusime prepravcu
            return { ...state, user_couriers: _.remove(state.user_couriers, i => toNumber(i) !== toNumber(payload)) };

        case CLEAN_LABELS:
        case DELETE_LABEL:
            // Vycistenie stitkov
            // Zmazanie stitku
            return LABELS_DEFAULT_STATE;

        default:
            return state;
    }
};

export default LabelsReducer;
