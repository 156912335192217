import React from 'react';
import { connect } from 'react-redux';
import { withCookies } from 'react-cookie';
import Apps from '../components/Apps';
import { ListScreen } from '../components';
import { fetchAddons, setUser, cleanAddons } from '../actions';
import { __ } from '../functions';
import '../assets/styles/addons.css';

/**
 * Addony.
 */
class AddonsScreen extends ListScreen {
    /**
     * Title.
     *
     * @type {string}
     */
    title = __('Doplnky');

    /**
     * Default state.
     *
     * @type {Object}
     */
    state = ListScreen.state;

    /**
     * Je validne pravo?
     *
     * @return {boolean}
     */
    isValidPermission() {
        return this.hasPermission('addons');
    }

    /**
     * Rendrujeme grid.
     *
     * @return {null}
     */
    renderGrid() {
        return null;
    }

    /**
     * Render after header.
     *
     * @return {JSX.Element|null}
     */
    renderAfterHeader() {
        return (
            <div className="addons-after-header">
                <Apps type="addons" { ...this.props.items } />
            </div>
        );
    }
}

const stateToProps = ({ addons, user }) => ({ items: addons, user });

export default withCookies(connect(stateToProps, {
    fetch: fetchAddons,
    clean: cleanAddons,
    setUser,
})(AddonsScreen));
