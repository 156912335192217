import React from 'react';
import { connect } from 'react-redux';
import { withCookies } from 'react-cookie';
import _ from 'lodash';
import {ListScreen, Input} from '../components';
import {
    fetchAderynProducts,
    cleanAderynProducts,
    setUser,
    changeUserSetting,
    changeAderynProduct,
} from '../actions';
import { __, formatDate } from '../functions';
import '../assets/styles/aderyn_products.css';
import EditIcon from "@mui/icons-material/Edit";
import ArticleIcon from '@mui/icons-material/Article';

/**
 * Klienti.
 */
class AderynProductsScreen extends ListScreen {
    /**
     * Title.
     *
     * @type {string}
     */
    title = __('Sklad');

    /**
     * Default state.
     *
     * @type {Object}
     */
    state = { ...ListScreen.state, ...{
        lightbox: {
            edit: false,
        },
    }};

    /**
     * Memory cache.
     *
     * @type {Object}
     */
    memoryCache = {};

    /**
     * Vratime nazov tabulky.
     *
     * @return {string}
     */
    getTableName() {
        return 'aderyn_products';
    }

    /**
     * Vratime zoznam stlpcov.
     *
     * @return {Array}
     */
    getColumns() {
        return [
            <div>{__('SKU')}</div>,
            <div>{__('Názov')}</div>,
            <div>{__('Stav')}</div>,
            <div>{__('Posledná zmena')}</div>,
            <div></div>,
        ];
    }

    /**
     * Vratime bunky.
     *
     * @param {Object} item
     *
     * @return {Array}
     */
    getCells(item) {
        return [
            <div>{item.sku}</div>,
            <div>{item.name}</div>,
            <div>{item.value}</div>,
            <div>{formatDate(item.last_modified, 'dd.mm.yyyy hh:ii')}</div>,
            <div>
                {this.renderTableButton(
                    item.id,
                    __('Upraviť'),
                    <EditIcon />,
                    (callbackLoading, callback) => this.confirmEdit(item.id, callbackLoading, callback, {
                        sku: item.sku,
                        value: item.value,
                        comment: '',
                    }),
                    {}
                )}
                {this.renderTableButton(
                    item.id,
                    __('Pohyby'),
                    <ArticleIcon />,
                    (callbackLoading, callback) => this.redirect(`/aderyn/storage/${item.id}`),
                    {}
                )}
            </div>,
        ];
    }

    /**
     * Vratime filter.
     *
     * @return {Object}
     */
    getFilter() {
        const { items } = this.props;

        return _.reduce(items.filter, (result, options, key) => {
            switch (key) {
                case 'sku':
                    return { ...result, ...{ [key]: {
                        type: 'input',
                        name: __('SKU'),
                        value: '',
                    }}};

                default:
                    return result;
            }
        }, {});
    }

    /**
     * Vratime empty text.
     *
     * @return {string}
     */
    getEmptyText() {
        return __('Zatiaľ nemáte žiadne produkty');
    }

    /**
     * Event po zmene hesla.
     *
     * @param {string} key
     * @param {string} value
     */
    onChangeEdit(key, value) {
        const { lightbox } = this.state;

        this.setState({ lightbox: { ...lightbox, edit: {
            ...lightbox.edit,
            data: { ...lightbox.edit.data, [key]: value }
        } } });
    }

    /**
     * Zobrazime lightbox na email.
     *
     * @param {number} id
     * @param {function} callbackLoading
     * @param {function} callback
     * @param {Object} data
     */
    confirmEdit(id, callbackLoading, callback, data) {
        this.showLightbox('edit', { id, callbackLoading, callback, data });
    }

    /**
     * Ulozenie emailu.
     */
    async edit() {
        const { changeAderynProduct } = this.props;
        const { lightbox } = this.state;

        // Zavolame loading
        lightbox.edit.callbackLoading();

        // Zavrieme lightbox
        this.closeLightbox('edit');

        // Zmenime email
        await changeAderynProduct(this, lightbox.edit.data.sku, lightbox.edit.data.value, lightbox.edit.data.comment);

        // Zavolame callback
        lightbox.edit.callback();
    }

    /**
     * Rendrujeme screen.
     *
     * @return {JSX.Element|null}
     */
    renderScreen() {
        const { lightbox } = this.state;

        return (
            <div>
                {this.renderLightbox(
                    'edit',
                    __('Úprava stavu'),
                    !_.isEmpty(lightbox.edit) ? <div>
                        <div>
                            <Input
                                label={__('Stav')}
                                value={lightbox.edit.data.value}
                                onChange={value => this.onChangeEdit('value', value)}
                            />
                        </div>
                        <div style={{ marginTop: '20px' }}>
                            <Input
                                label={__('Poznámka')}
                                value={lightbox.edit.data.comment}
                                onChange={value => this.onChangeEdit('comment', value)}
                            />
                        </div>
                    </div> : null,
                    __('Uložiť'),
                    __('Zrušiť'),
                    () => this.edit()
                )}
            </div>
        );
    }
}

const stateToProps = ({ aderyn_products, user }) => ({ items: aderyn_products, user });

export default withCookies(connect(stateToProps, {
    fetch: fetchAderynProducts,
    clean: cleanAderynProducts,
    setUser,
    changeUserSetting,
    changeAderynProduct,
})(AderynProductsScreen));
