import React from 'react';
import { connect } from 'react-redux';
import { withCookies } from 'react-cookie';
import _ from 'lodash';
import { Tooltip, IconButton, Chip } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import HelpIcon from '@mui/icons-material/Help';
import Apps from '../components/Apps';
import { Screen, ContentHeader, Button, ContentPart, Switch, Select, Input, Message } from '../components';
import { fetchAccounting, fetchAccountingData, setUser, changeAccountingAutoField, changeAccountingSettings } from '../actions';
import { __, formatDate, toNumber } from '../functions';
import { INVOICE_TYPES, PLACEHOLDERS } from '../config';
import '../assets/styles/accounting.css';

/**
 * Fakturacny system.
 */
class AccountingScreen extends Screen {
    /**
     * Title.
     *
     * @type {string}
     */
    title = __('Nastavenie ekonomického systému');

    /**
     * Default state.
     *
     * @type {Object}
     */
    state = {
        settings: {},
        loading: false,
        loadingData: false,
    };

    /**
     * Zoznam labelov.
     *
     * @type {Object}
     */
    labels = {
        type: __('Typ'),
        sequence_id: __('Číselník'),
        logo_id: __('Logo'),
        bank_account: __('Bankový účet'),
        centre: __('Stredisko'),
        language: __('Jazyk'),
        name: __('Názov'),
        description: __('Popis'),
        created: __('Dátum vystavenia'),
        delivery: __('Dátum dodania'),
        due: __('Dátum splatnosti'),
        order_no: __('Číslo objednávky'),
        number: __('Číslo faktúry'),
        variable: __('Variabilný symbol'),
        currency: __('Mena'),
        discount: __('Zľava'),
        header_comment: __('Poznámka nad položkami'),
        comment: __('Poznámka'),
        tags: __('Tagy (oddeľte číarkou)'),
        client_name: __('Názov'),
        client_ico: __('IČO'),
        client_dic: __('DIČ'),
        client_ic_dph: __('IČ DPH'),
        client_email: __('Email'),
        client_phone: __('Tel. číslo'),
        client_address: __('Adresa (fakturácia)'),
        client_city: __('Mesto (fakturácia)'),
        client_zip: __('PSČ (fakturácia)'),
        client_country: __('Krajina (fakturácia)'),
        client_delivery_address: __('Adresa (dodanie)'),
        client_delivery_city: __('Mesto (dodanie)'),
        client_delivery_zip: __('PSČ (dodanie)'),
        client_delivery_country: __('Krajina (dodanie)'),
        client_delivery_phone: __('Tel. číslo (dodanie)'),
        product_name: __('Názov'),
        product_description: __('Popis'),
        product_quantity: __('Počet'),
        product_unit: __('Jednotka'),
        product_unit_price: __('Jednotková cena'),
        product_tax: __('DPH'),
        product_ean: __('Unikátne označenie (sku,ean)'),
        product_discount: __('Zľava'),
        product_discount_description: __('Popis zľavy'),
    };

    /**
     * Komponenta bola pripojena.
     *
     * @return boolean
     */
    async componentDidMount() {
        if (super.componentDidMount()) {
            const { fetchAccounting } = this.props;

            await fetchAccounting(this);

            const { accounting } = this.props;

            this.setState({ settings: accounting.settings });
        }

        return true;
    }

    /**
     * Event po zmene auto fieldu.
     *
     * @param {string} field
     * @param {boolean} value
     */
    onChangeAutoField(field, value) {
        const { changeAccountingAutoField } = this.props;

        changeAccountingAutoField(this, field, value);
    }

    /**
     * Event po zmene settingu.
     *
     * @param {string} type
     * @param {string} value
     */
    onChangeSetting(type, value) {
        const { settings } = this.state;

        let additional = {};

        if (type === 'type') {
            // Menime typ faktury, musime vyresetovat ciselnik
            additional = { sequence_id: 0 };
        }

        this.setState({ settings: { ...settings, [type]: value, ...additional } });
    }

    /**
     * Event po kliku na placeholder.
     *
     * @param {string} placeholder
     */
    onClickPlaceholder(placeholder) {
        // Nastavime placeholder do clipboardu
        navigator.clipboard.writeText(placeholder);

        this.showSnackbar('success', __('Skopirované do schránky'));
    }

    /**
     * Nacitame data fakturacneho systemu.
     */
    refreshData() {
        const { fetchAccountingData } = this.props;

        this.setState({ loadingData: true });

        fetchAccountingData(this);
    }

    /**
     * Zmena nastaveni.
     */
    changeSettings() {
        const { changeAccountingSettings } = this.props;
        const { settings } = this.state;

        if (this.isDemo()) {
            this.showSnackbar('error', __('Nastavenie nie je možné uložiť na DEMO účte'));
            return;
        }

        this.setState({ loading: true });

        changeAccountingSettings(this, settings);
    }

    /**
     * Pridame novy create setting.
     */
    addCreateSetting() {
        const { settings } = this.state;

        this.setState({ settings: { ...settings, create_settings: [ ...settings.create_settings, {
            type: _.keys(INVOICE_TYPES)[0],
            state: '',
            payment: '',
            delivery: '',
        } ] } });
    }

    /**
     * Event po zmene create settingu.
     *
     * @param {number} key
     * @param {string} type
     * @param {string} value
     */
    onChangeCreateSetting(key, type, value) {
        const { settings } = this.state;

        const create_settings = _.map(settings.create_settings, (setting, k) => {
            if (k === key) {
                // Editujeme nastavenie
                setting = { ...setting, [type]: value };
            }

            return setting;
        });

        this.setState({ settings: { ...settings, create_settings } });
    }

    /**
     * Zmazeme create setting.
     *
     * @param {int} key
     */
    deleteCreateSetting(key) {
        const { settings } = this.state;

        const createSettings = _.remove(settings.create_settings, (item, k) => k !== key);

        this.setState({ settings: { ...settings, create_settings: createSettings } });
    }

    /**
     * Vratime zgrupnute settingy.
     *
     * @param {Object} settings
     *
     * @return {Object}
     */
    getSettingsGroups(settings) {
        return _.reduce(settings, (result, value, name) => {
            if (
                name === 'state_create'
                || name === 'state_proforma_create'
                || name === 'state_cancel_create'
                || name === 'create_settings'
            ) {
                return result;
            }

            let group = 'invoice';

            if (name.indexOf('client_') !== -1) {
                // Skupina klient
                group = 'client';
            } else if (name.indexOf('product_') !== -1) {
                // Skupina produkt
                group = 'product';
            }

            return { ...result, [group]: { ...result[group], fields: [ ...result[group].fields, { key: name, value } ] } };
        }, {
            invoice: { name: __('Faktúra'), fields: [] },
            client: { name: __('Klient'), fields: [] },
            product: { name: __('Položka'), fields: [] },
        });
    }

    /**
     * Rendrovanie.
     *
     * @returns {JSX.Element}
     */
    render() {
        const { accounting } = this.props;
        const { settings, loading, loadingData } = this.state;

        if (_.isEmpty(settings)) {
            // Data nie su nacitane
            return this.renderLoading();
        }

        if (!this.hasPermission('settings-accounting')) {
            // Nema pravo
            return null;
        }

        // Vytiahneme zoznam stavov
        const states = _.reduce(accounting.eshop_data.states, (result, { id, name }) => ({ ...result, [id]: name }), {});

        // Vytiahneme typy uhrad
        const paymentTypes = _.reduce(accounting.eshop_data.payments, (result, { id, name }) => ({ ...result, [id]: name }), {});

        // Vytiahneme zoznam dodani
        const deliveryTypes = _.reduce(accounting.eshop_data.delivers, (result, { id, name }) => ({ ...result, [id]: name }), {});

        // Vytiahneme id fakturacneho systemu
        const id = toNumber(accounting.id);

        // Je aktivny?
        const active = id > 0;

        // Podporuje online fakturaciu?
        const invoicing = this.hasAccountingAction('invoicing', id);

        // Ma podrobnejsie nastavenia na vytvorenie faktur?
        const hasCreateSettings = !_.isEmpty(settings.create_settings);

        // Zobrazujeme podrobnejsie informacie?
        const showCreateSettings = active && accounting.auto === 'on' && invoicing && hasCreateSettings;

        // Pouziva zalohovu fakturu?
        const useProforma = settings.type === 'proforma'
            || (!hasCreateSettings && settings.state_proforma_create !== '')
            || (hasCreateSettings && _.some(settings.create_settings, setting => setting.type === 'proforma'));

        // Pouziva dobropisy?
        const hasCancel = this.hasAccountingAction('cancel', id);

        const sellerAccounting = window.location.pathname === '/seller-accounting';

        return (
            <div className="eshop">
                <ContentHeader
                    title={__('Ekonomický systém')}
                    tags={[
                        active && invoicing
                            ? { label: formatDate(accounting.synchronized, __('Synchronizované s ekonomickým systémom o hh:ii')) }
                            : null
                    ]}
                    rightContent={<div>
                        {invoicing ? <Tooltip title={__('Načíta aktuálne údaje z ekonomického systému (číselníky, logá...)')}>
                            <span><Button
                                onClick={() => this.refreshData()}
                                loading={loadingData}
                            >{__('Načítať údaje')}</Button></span>
                        </Tooltip> : null}
                    </div>}
                />
                <Apps
                    type="accountings"
                    accountingId={accounting.id}
                    refreshData={() => this.refreshData()}
                    { ...accounting }
                />
                <ContentPart title={__('Nastavenia')} />
                {!active
                    ? (<div className="accounting__settings">
                        <Message type="warning">{__('Najprv musíte aktivovať ekonomický systém')}</Message>
                    </div>)
                    : <div className="accounting__settings">
                    {!sellerAccounting ? <div className="accounting__settings__switchers">
                        <Switch
                            label={invoicing
                                ? __('Automatické vystavenie faktúry')
                                : __('Automatické odoslanie objednávky')}
                            checked={accounting.auto === 'on'}
                            onChange={value => this.onChangeAutoField('auto', value)}
                        />
                        {this.hasAccountingAction('regular_from_proforma', id) && useProforma ? <Switch
                            label={__('Automatické vystavenie ostrej faktúry po úhrade zálohovej')}
                            checked={accounting.proforma_auto === 'on'}
                            onChange={value => this.onChangeAutoField('proforma_auto', value)}
                        /> : null}
                        {this.hasAccountingAction('email', id) ? <Switch
                            label={__('Automatické odoslanie emailu po vystavený')}
                            checked={accounting.email_auto === 'on'}
                            onChange={value => this.onChangeAutoField('email_auto', value)}
                        /> : null}
                    </div> : null}
                    <div className={`accounting__settings__create ${showCreateSettings ? 'full' : ''}`}>
                        {active && accounting.auto === 'on' && !hasCreateSettings ? <Select
                            label={invoicing
                                ? __('Aký má mať stav objednávka aby sa vytvorila faktúra?')
                                : __('Aký má mať stav objednávka aby sa odoslala objednávka?')}
                            options={states}
                            value={settings.state_create}
                            onChange={value => this.onChangeSetting('state_create', value)}
                            placeholder={__('Všetky stavy')}
                        /> : null}
                        {active && accounting.auto === 'on' && invoicing && !hasCreateSettings ? <Select
                            label={__('Aký má mať stav objednávka aby sa vytvorila zálohová faktúra?')}
                            options={states}
                            value={settings.state_proforma_create}
                            onChange={value => this.onChangeSetting('state_proforma_create', value)}
                        /> : null}
                        {active && accounting.auto === 'on' && invoicing && !hasCreateSettings && hasCancel ? <Select
                            label={__('Aký má mať stav objednávka aby sa vytvoril dobropis?')}
                            options={states}
                            value={settings.state_cancel_create}
                            onChange={value => this.onChangeSetting('state_cancel_create', value)}
                        /> : null}
                        {active && invoicing && !hasCreateSettings && !sellerAccounting ? <Button
                            onClick={() => this.addCreateSetting()}
                        >{__('Podrobnejšie nastavenia')}</Button> : null}
                        {showCreateSettings
                            ? _.map(settings.create_settings, (setting, key) => {
                                let types = INVOICE_TYPES;

                                if (!hasCancel) {
                                    types = _.omit(types, ['cancel']);
                                }

                                return (
                                    <div className="accounting__settings__create__event" key={key}>
                                        <Select
                                            label={__('Typ')}
                                            options={types}
                                            value={setting.type}
                                            onChange={value => this.onChangeCreateSetting(key, 'type', value)}
                                            allowEmpty={false}
                                        />
                                        <Select
                                            label={invoicing
                                                ? __('Aký má mať stav objednávka aby sa vytvorila faktúra?')
                                                : __('Aký má mať stav objednávka aby sa odoslala objednávka?')}
                                            options={states}
                                            value={setting.state}
                                            onChange={value => this.onChangeCreateSetting(key, 'state', value)}
                                            placeholder={__('Všetky stavy')}
                                        />
                                        <Select
                                            label={invoicing
                                                ? __('Aký má mať typ dodania objednávka aby sa vytvorila faktúra?')
                                                : __('Typ dodania pri odoslaní objednávky')}
                                            options={deliveryTypes}
                                            value={setting.delivery}
                                            onChange={value => this.onChangeCreateSetting(key, 'delivery', value)}
                                            placeholder={__('Všetky typy')}
                                        />
                                        <div>
                                            <Select
                                                label={invoicing
                                                    ? __('Aký má mať typ platby objednávka aby sa vytvorila faktúra?')
                                                    : __('Typ platby pri odoslaní objednávky')}
                                                options={paymentTypes}
                                                value={setting.payment}
                                                onChange={value => this.onChangeCreateSetting(key, 'payment', value)}
                                                placeholder={__('Všetky typy')}
                                            />
                                            <Tooltip title={__('Zmazať')}>
                                                <IconButton
                                                    onClick={() => this.deleteCreateSetting(key)}
                                                    className="accounting__content__events__settings__setting__button"
                                                >
                                                    <DeleteIcon />
                                                </IconButton>
                                            </Tooltip>
                                        </div>
                                    </div>
                                );
                            })
                            : null}
                        {showCreateSettings ? <Button
                            onClick={() => this.addCreateSetting()}
                        >{__('Pridať nastavenie')}</Button> : null}
                    </div>
                    <div className="accounting__settings__invoice">
                        <div className="accounting__settings__invoice__title">{invoicing
                            ? __('Nastavenie faktúry')
                            : __('Nastavenie objednávky')}</div>
                        <Message type="info">{__('Do nastavení môžete vkladať špecialné slova ktoré sa pri vystavení faktúry automaticky doplnia podľa objednávky. Prázdne položky budú doplnené automaticky podľa ekonomického systému.')}</Message>
                        <div className="accounting__settings__invoice__panels">
                            <div className="accounting__settings__invoice__panels__panel">
                                <div className="accounting__settings__invoice__panels__panel__title">{__('Špeciálne slová')}</div>
                                <div className="accounting__settings__invoice__panels__panel__text">{__('Kliknutím skopírujete do schránky.')}</div>
                                <div className="accounting__settings__invoice__panels__panel__placeholders">
                                    {_.map(PLACEHOLDERS, (text, placeholder) => (
                                        <div key={placeholder}>
                                            <Tooltip title={text}>
                                                <Chip
                                                    onClick={() => this.onClickPlaceholder(placeholder)}
                                                    label={placeholder}
                                                    clickable
                                                    color="primary"
                                                    deleteIcon={<HelpIcon />}
                                                />
                                            </Tooltip>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            {_.map(this.getSettingsGroups(accounting.settings), ({ name, fields }, key) => {
                                return (
                                    <div className="accounting__settings__invoice__panels__panel" key={key}>
                                        <div className="accounting__settings__invoice__panels__panel__title">{name}</div>
                                        {_.map(fields, ({ key }) => {
                                            if (_.includes(['type', 'sequence_id', 'logo_id', 'bank_account', 'centre', 'language'], key)) {
                                                let options = {};

                                                if (!invoicing) {
                                                    return null;
                                                }

                                                switch (key) {
                                                    case 'type':
                                                        // Typ faktury
                                                        options = _.omit(INVOICE_TYPES, ['cancel']);
                                                        break;

                                                    case 'sequence_id':
                                                        // Ciselnik
                                                        options = settings.type === 'proforma'
                                                            ? accounting.data.sequences_proforma
                                                            : accounting.data.sequences_regular;
                                                        break;

                                                    case 'logo_id':
                                                        // Loga
                                                        options = accounting.data.logos;
                                                        break;

                                                    case 'bank_account':
                                                        // Bankove ucty
                                                        options = accounting.data.bank_accounts;
                                                        break;

                                                    case 'centre':
                                                        // Stredisko
                                                        options = accounting.data.centres;
                                                        break;

                                                    case 'language':
                                                        // Jazyk
                                                        options = accounting.data.languages;
                                                        break;

                                                    default:
                                                        break;
                                                }

                                                if (_.isEmpty(options)) {
                                                    // Ak su prazdne options, nezobrazime
                                                    return null;
                                                }

                                                return <Select
                                                    key={key}
                                                    label={this.labels[key]}
                                                    options={options}
                                                    value={settings[key]}
                                                    onChange={value => this.onChangeSetting(key, value)}
                                                    placeholder={__('Doplnené ekonomickým systémom')}
                                                />;
                                            }

                                            return <Input
                                                key={key}
                                                label={this.labels[key]}
                                                value={settings[key]}
                                                onChange={value => this.onChangeSetting(key, value)}
                                                placeholder={__('Doplnené ekonomickým systémom')}
                                                multiline={key === 'comment' || key === 'header_comment'}
                                            />;
                                        })}
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                    <Button
                        onClick={() => this.changeSettings()}
                        loading={loading}
                        className="accounting__settings__button"
                        color="green"
                    >{__('Uložiť')}</Button>
                </div>}
                {this.renderSnackbar()}
            </div>
        );
    }
}

const stateToProps = ({ accounting, user }) => ({ accounting, user });

export default withCookies(connect(stateToProps, {
    fetchAccounting,
    fetchAccountingData,
    setUser,
    changeAccountingAutoField,
    changeAccountingSettings,
})(AccountingScreen));
