import React from 'react';
import { connect } from 'react-redux';
import { withCookies } from 'react-cookie';
import Apps from '../components/Apps';
import { ListScreen } from '../components';
import { fetchMarketplaces, setUser, cleanMarketplaces } from '../actions';
import { __ } from '../functions';
import '../assets/styles/marketplaces.css';

/**
 * Trhoviska.
 */
class MarketplacesScreen extends ListScreen {
    /**
     * Title.
     *
     * @type {string}
     */
    title = __('Trhoviská');

    /**
     * Default state.
     *
     * @type {Object}
     */
    state = ListScreen.state;

    /**
     * Je validne pravo?
     *
     * @return {boolean}
     */
    isValidPermission() {
        return this.hasPermission('marketplaces');
    }

    /**
     * Rendrujeme grid.
     *
     * @return {null}
     */
    renderGrid() {
        return null;
    }

    /**
     * Render after header.
     *
     * @return {JSX.Element|null}
     */
    renderAfterHeader() {
        return (
            <div className="marketplaces-after-header">
                <Apps type="marketplaces" { ...this.props.items } />
            </div>
        );
    }
}

const stateToProps = ({ marketplaces, user }) => ({ items: marketplaces, user });

export default withCookies(connect(stateToProps, {
    fetch: fetchMarketplaces,
    clean: cleanMarketplaces,
    setUser,
})(MarketplacesScreen));
