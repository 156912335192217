import { request } from '../functions';
import { FETCH_MARKETPLACES, CREATE_MARKETPLACE, DELETE_MARKETPLACE, CLEAN_MARKETPLACES } from './types';

/**
 * Nacitanie trhovisk.
 *
 * @param {Screen} screen
 */
export const fetchMarketplaces = screen => {
    return dispatch => request('/user-marketplaces/dashboard').then(response => {
        const { data, sync } = response.data;

        const { user } = sync;
        const { setUser } = screen.props;

        // Nasetujeme usera
        setUser(user);

        dispatch({ type: FETCH_MARKETPLACES, payload: data });
    });
};

/**
 * Vytvorenie prepojenia na marketplace.
 *
 * @param {number} id
 */
export const createMarketplace = id => ({ type: CREATE_MARKETPLACE, payload: id });

/**
 * Zmazanie prepojenia na marketplace.
 *
 * @param {number} id
 */
export const deleteMarketplace = id => ({ type: DELETE_MARKETPLACE, payload: id });

/**
 * Vycistenie trhovisiek.
 */
export const cleanMarketplaces = () => ({ type: CLEAN_MARKETPLACES });
