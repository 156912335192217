import React from 'react';
import { connect } from 'react-redux';
import { withCookies } from 'react-cookie';
import _ from 'lodash';
import { Tooltip, IconButton, Autocomplete, TextField, CircularProgress } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import SyncIcon from '@mui/icons-material/Sync';
import ActivatedIcon from '@mui/icons-material/CheckCircle';
import { Screen, Button, Input, Select, Checkbox } from '../components';
import { fetchCreateOrder, setUser, createOrder, cleanOrder } from '../actions';
import { __, request, formatAmount, toNumber, isEmptyString, isValidZip } from '../functions';
import { CURRENCIES } from '../config';
import '../assets/styles/order-screen.css';

/**
 * Objednavka.
 */
class OrderScreen extends Screen {
    /**
     * Title.
     *
     * @type {string}
     */
    title = __('Nová objednávka');

    /**
     * Default state.
     *
     * @type {Object}
     */
    state = {
        loadingAres: false,
        loadingVies: false,
        loading: false,
        sameDelivery: true,
        showCompany: false,
        oss: true,
        tax: 0,
        data: {
            order: {
                payment_type: '',
                delivery_type: '',
                currency: '',
            },
            client: {
                name: '',
                ico: '',
                dic: '',
                ic_dph: '',
                email: '',
                phone: '',
                address: '',
                city: '',
                zip: '',
                country: '',
                delivery_address: '',
                delivery_city: '',
                delivery_zip: '',
                delivery_country: '',
                delivery_phone: '',
                delivery_note: '',
            },
            products: [],
            meta_data: {},
            decrease_storage: true,
        },
    };

    /**
     * Komponenta bola pripojena.
     *
     * @return boolean
     */
    async componentDidMount() {
        if (super.componentDidMount()) {
            const { fetchCreateOrder } = this.props;

            // Nacitame data
            await fetchCreateOrder(this);

            const { user } = this.props;
            const { data } = this.state;

            this.setState({ data: {
                ...data,
                order: {
                    ...data.order,
                    currency: user.settings.currency,
                },
                products: [ this.getProduct() ],
                client: {
                    ...data.client,
                    country: user.settings.market,
                    delivery_country: user.settings.market,
                },
            } });
        }

        return true;
    }

    /**
     * Komponenta bude odpojena.
     */
    componentWillUnmount() {
        const { cleanOrder } = this.props;

        cleanOrder();
    }

    /**
     * Komponenta bola zmenena.
     *
     * @param {Object} prevProps
     * @param {Object} prevState
     * @param {Object} snapshot
     */
    componentDidUpdate(prevProps, prevState, snapshot) {
        // Nova sadzba
        const tax = this.getTax();

        if (prevState.tax !== tax) {
            this.setState({ tax });

            // Prepiseme produkty
            _.each(this.state.data.products, (product, key) => {
                setTimeout(() => this.onChangeProduct(key, 'tax', tax.toString()), 50 * key);
            });
        }
    }

    /**
     * Event po zmene rovnakej fakturacnej a dodacej adresy.
     *
     * @param {boolean} value
     */
    onChangeSameDelivery(value) {
        const { data } = this.state;
        let { client } = data;

        if (value) {
            // Chceme rovnaku, nasetujeme
            client = {
                ...client,
                delivery_address: client.address,
                delivery_city: client.city,
                delivery_zip: client.zip,
                delivery_country: client.country,
                delivery_phone: client.phone,
            };
        } else {
            // Nechceme rovnaku, vycistime
            client = {
                ...client,
                delivery_address: '',
                delivery_city: '',
                delivery_zip: '',
                delivery_phone: '',
            };
        }

        this.setState({ data: { ...data, client }, sameDelivery: value });
    }

    /**
     * Event po zmene objednavky.
     *
     * @param {string} type
     * @param {string} value
     */
    onChangeOrder(type, value) {
        const { data } = this.state;

        this.setState({ data: { ...data, order: { ...data.order, [type]: value } } });
    }

    /**
     * Event po zmene velkoobchodu.
     *
     * @param {bool} value
     */
    onChangeStorage(value) {
        const { data } = this.state;

        this.setState({ data: { ...data, decrease_storage: value }});
    }

    /**
     * Event po zmene klienta.
     *
     * @param {string} type
     * @param {string} value
     */
    onChangeClient(type, value) {
        const { data, sameDelivery } = this.state;

        let client = { ...data.client, [type]: value };

        if (sameDelivery) {
            // Dodacia je rovnaka
            switch (type) {
                case 'address':
                    // Menime adresu
                    client = { ...client, delivery_address: value };
                    break;

                case 'city':
                    // Menime mesto
                    client = { ...client, delivery_city: value };
                    break;

                case 'zip':
                    // Menime PSC
                    client = { ...client, delivery_zip: value };
                    break;

                case 'country':
                    // Menime krajinu
                    client = { ...client, delivery_country: value };
                    break;

                case 'phone':
                    // Menime telefon
                    client = { ...client, delivery_phone: value };
                    break;

                default:
                    break;
            }
        }

        this.setState({ data: { ...data, client } });
    }

    /**
     * Event po zmene klienta.
     *
     * @param {number} key
     * @param {string} type
     * @param {string} value
     */
    onChangeProduct(key, type, value) {
        const { data } = this.state;

        const products = _.map(data.products, (product, k) => {
            if (k === key) {
                // Editujeme produkt
                if (type === 'name') {
                    return { ...product, name: value };
                }

                let quantity = product.quantity;
                let unit_price = product.unit_price;
                let tax = product.tax;
                let total_price = product.total_price;

                value = value.replace(/[^0-9.,]/g, '');

                switch (type) {
                    default:
                    case 'quantity':
                        // Menime pocet
                        quantity = value;
                        break;

                    case 'unit_price':
                        // Menime jednotkovu cenu
                        unit_price = value;
                        total_price = formatAmount(toNumber(unit_price) * (tax / 100 + 1), '').replace(' ', '');
                        break;

                    case 'tax':
                        // Menime DPH
                        tax = value;
                        total_price = formatAmount(toNumber(unit_price) * (tax / 100 + 1), '').replace(' ', '');
                        break;

                    case 'total_price':
                        // Menime celkovu cenu
                        total_price = value;
                        unit_price = formatAmount(toNumber(total_price) * (100 / (100 + tax)), '').replace(' ', '');
                        break;
                }

                product = { ...product, quantity, unit_price, tax, total_price };
            }

            return product;
        });

        this.setState({ data: { ...data, products }});
    }

    /**
     * Pridanie produktu.
     */
    addProduct() {
        const { data } = this.state;

        this.setState({ data: { ...data, products: [ ...data.products, this.getProduct() ] } });
    }

    /**
     * Zmazanie produktu.
     *
     * @param {number} key
     */
    deleteProduct(key) {
        const { data } = this.state;
        let { products } = data;

        products = _.remove(products, (item, k) => k !== key);

        this.setState({ data: { ...data, products }});
    }

    /**
     * Vratime data noveho produktu.
     *
     * @return {Object}
     */
    getProduct() {
        const { tax } = this.state;

        return {
            id: '',
            name: '',
            quantity: 1,
            unit_price: '',
            tax,
            total_price: '',
        };
    }

    /**
     * Event po vybrani produktu.
     *
     * @param {number} key
     * @param {Object} product_data
     */
    onSelectProduct(key, product_data) {
        const { user } = this.props;
        const { data } = this.state;

        const products = _.map(data.products, (product, k) => {
            if (k === key) {
                // Editujeme produkt
                product = {
                    ...product,
                    id: product_data !== null ? product_data.id : '',
                    unit_price: product_data !== null ? formatAmount(toNumber(product_data.data.unit_price), '', 2).replace(' ', '') : '',
                    total_price: product_data !== null ? formatAmount(toNumber(product_data.data.total_price), '', 2).replace(' ', '') : '',
                };
            }

            return product;
        });

        this.setState({ data: { ...data, products }});
    }

    /**
     * Nacitanie udajov z ARES.
     */
    checkAres() {
        const { data, sameDelivery } = this.state;

        this.setState({ loadingAres: true });

        request(`/orders/ares/${data.client.ico}/${data.client.country}`).then(response => {
            const { status, data } = response.data;

            this.setState({ loadingAres: false });

            if (status === 'error') {
                // Nepodarilo sa nacitat udaje
                this.showSnackbar('error', __('Nepodarilo sa načítať údaje'));
                return;
            }

            let { client } = this.state.data;

            client = {
                ...client,
                name: data.name,
                address: data.address,
                city: data.city,
                zip: data.zip,
                dic: data.dic,
            };

            if (sameDelivery) {
                // Fakturacna adresa je rovnaka ako dodacia
                client = {
                    ...client,
                    delivery_address: data.address,
                    delivery_city: data.city,
                    delivery_zip: data.zip,
                };
            }

            this.setState({ data: { ...this.state.data, client } });
        });
    }

    /**
     * Overenie VIES.
     */
    checkVies() {
        const { user } = this.props;
        const { data } = this.state;

        this.setState({ loadingVies: true });

        request(`/orders/vies/${data.client.dic}/${data.client.country}`).then(response => {
            const { status } = response.data;

            this.setState({ loadingVies: false, data: { ...data, meta_data: {
                ...data.meta_data,
                vies_date: user.today,
                vies_token: response.data.data.token,
            } } });

            if (response.data.data.token === '') {
                // Nepodarilo sa nacitat udaje
                this.showSnackbar('error', __('Nepodarilo sa overiť cez VIES'));
                return;
            }

            this.showSnackbar('success', __('Overené cez VIES'));
        });
    }

    /**
     * Vratime DPH sadzbu.
     *
     * @return {number}
     */
    getTax() {
        const { order, user } = this.props;
        const { data, oss } =  this.state;

        if (!oss) {
            // Nie je OSS, domaca mena
            return order.vat_rates[user.settings.market];
        }

        if (data.client.dic === '') {
            // Neplatca
            if (_.has(order.vat_rates, data.client.country)) {
                // EU, pouzijeme sadzbu
                return order.vat_rates[data.client.country];
            }

            // Mimo EU bez DPH
            return 0;
        }

        // Platca DPH
        if (!_.has(order.vat_rates, data.client.country)) {
            // Mimo EU
            return 0;
        }

        if (data.client.country === user.settings.market) {
            // Domaca krajina
            return order.vat_rates[user.settings.market];
        }

        if (_.has(data.meta_data, 'vies_token') && data.meta_data.vies_token !== '') {
            // Overene cez VIES, bez DPH
            return 0;
        }

        // Neoverene VIES, sadzba krajiny
        return order.vat_rates[data.client.country];
    }

    /**
     * Ulozenie.
     */
    save() {
        const { data } = this.state;

        let error = '';

        if (data.order.payment_type === '') {
            // Nevyplneny typ uhrady
            error = __('Nie je zadaný typ úhrady');
        } else if (data.order.delivery_type === '') {
            // Nevyplneny typ dodania
            error = __('Nie je zadaný typ dodania');
        } else if (isEmptyString(data.client.name)) {
            // Nevyplneny nazov klienta
            error = __('Nie je zadaný názov zákaznika');
        } else if (!isEmptyString(data.client.zip) && !isValidZip(data.client.zip)) {
            // Neplatne PSC
            error = __('Neplatné PSČ');
        } else if (_.reduce(data.products, (result, product) => product.id !== '' ? result + 1 : result, 0) === 0) {
            // Nie je zadany produkt
            error = __('Nie je zadaný produkt');
        }

        if (error !== '') {
            // Mame error
            this.showSnackbar('error', error);
            return;
        }

        this.setState({ loading: true });

        const { createOrder } = this.props;

        // Ulozime objednavku
        createOrder(this, data);
    }

    /**
     * Rendrovanie.
     *
     * @returns {JSX.Element}
     */
    render() {
        const { order, user } = this.props;
        const { data, loading, sameDelivery, loadingAres, loadingVies, showCompany, oss } = this.state;

        if (_.isEmpty(order)) {
            // Data nie su nacitane
            return this.renderLoading();
        }

        // Vytiahneme zoznam platieb
        const paymentTypes = _.reduce(order.eshop_data.payments, (result, { id, name }) => ({ ...result, [id]: name }), {});

        // Vytiahneme zoznam dodani
        const deliveryTypes = _.reduce(order.eshop_data.delivers, (result, { id, name }) => ({ ...result, [id]: name }), {});

        let unitPrice = 0;
        let totalPrice = 0;

        return (
            <div className="order">
                <div className="order__header">
                    <div className="order__header__left">
                        <div className="order__header__left__title">
                            {__('Nová objednávka')}
                        </div>
                    </div>
                </div>
                <div className="order__content">
                    <div className="order__content__sections">
                        <div className="order__content__sections__section">
                            <Select
                                label={__('Dodanie')}
                                value={data.order.delivery_type}
                                options={deliveryTypes}
                                onChange={value => this.onChangeOrder('delivery_type', value)}
                                allowEmpty={false}
                            />
                            <Select
                                label={__('Platba')}
                                value={data.order.payment_type}
                                options={paymentTypes}
                                onChange={value => this.onChangeOrder('payment_type', value)}
                                allowEmpty={false}
                            />
                            <Select
                                label={__('Mena')}
                                value={data.order.currency}
                                options={CURRENCIES}
                                onChange={value => this.onChangeOrder('currency', value)}
                                allowEmpty={false}
                            />
                            <Input
                                label={__('Meno a priezvisko (názov firmy)')}
                                value={data.client.name}
                                onChange={value => this.onChangeClient('name', value)}
                            />
                            <Input
                                label={__('Email')}
                                value={data.client.email}
                                onChange={value => this.onChangeClient('email', value)}
                            />
                            {toNumber(user.user_eshop_id) === 106 ? <Checkbox
                                label={__('Maloobchod')}
                                value={data.decrease_storage}
                                onChange={checked => this.onChangeStorage(checked)}
                            /> : null}
                            {!showCompany ? <Checkbox
                                label={__('Firma')}
                                value={showCompany}
                                onChange={checked => this.setState({ showCompany: checked })}
                            /> : null}
                            {showCompany ? <div>
                                <div className="order__content__sections__section__sync">
                                    <Input
                                        label={__('IČO')}
                                        value={data.client.ico}
                                        onChange={value => this.onChangeClient('ico', value)}
                                    />
                                    <Tooltip title={__('Načítať údaje z ARES')}>
                                    <span>
                                        <IconButton
                                            onClick={() => this.checkAres()}
                                            className="order__content__sections__section__sync__button"
                                            disabled={isEmptyString(data.client.ico)}
                                        >
                                            {loadingAres
                                                ? <CircularProgress className="loading" color="inherit" size={20} />
                                                : <SyncIcon />}
                                        </IconButton>
                                    </span>
                                    </Tooltip>
                                </div>
                                <div className="order__content__sections__section__sync">
                                    <Input
                                        label={__('DIČ')}
                                        value={data.client.dic}
                                        onChange={value => this.onChangeClient('dic', value)}
                                    />
                                    <Tooltip title={__('Overiť VIES')}>
                                    <span>
                                        <IconButton
                                            onClick={() => this.checkVies()}
                                            className="order__content__sections__section__sync__button"
                                            disabled={isEmptyString(data.client.dic) || (_.has(data.meta_data, 'vies_token') && data.meta_data.vies_token !== '')}
                                        >
                                            {loadingVies
                                                ? <CircularProgress className="loading" color="inherit" size={20} />
                                                : (_.has(data.meta_data, 'vies_token') && data.meta_data.vies_token !== ''
                                                    ? <ActivatedIcon color="secondary" />
                                                    : <SyncIcon />)}
                                        </IconButton>
                                    </span>
                                    </Tooltip>
                                </div>
                            </div> : null}
                        </div>
                        <div className="order__content__sections__section">
                            <Input
                                label={__('Adresa (fakturácia)')}
                                value={data.client.address}
                                onChange={value => this.onChangeClient('address', value)}
                            />
                            <Input
                                label={__('Mesto (fakturácia)')}
                                value={data.client.city}
                                onChange={value => this.onChangeClient('city', value)}
                            />
                            <Input
                                label={__('PSČ (fakturácia)')}
                                value={data.client.zip}
                                onChange={value => this.onChangeClient('zip', value)}
                            />
                            <Select
                                label={__('Krajina (fakturácia)')}
                                value={data.client.country}
                                options={order.countries}
                                onChange={value => this.onChangeClient('country', value)}
                                allowEmpty={false}
                            />
                            <Input
                                label={__('Telefón (fakturácia)')}
                                value={data.client.phone}
                                onChange={value => this.onChangeClient('phone', value)}
                            />
                            <Checkbox
                                label={__('Dodacia adresa rovnaká ako fakturačná')}
                                value={sameDelivery}
                                onChange={checked => this.onChangeSameDelivery(checked)}
                            />
                            {data.client.country !== user.settings.market ? <Checkbox
                                label={__('OSS')}
                                value={oss}
                                onChange={checked => this.setState({ oss: checked })}
                            /> : null}
                        </div>
                        <div className="order__content__sections__section">
                            <Input
                                label={__('Adresa (dodanie)')}
                                value={data.client.delivery_address}
                                onChange={value => this.onChangeClient('delivery_address', value)}
                                disabled={sameDelivery}
                            />
                            <Input
                                label={__('Mesto (dodanie)')}
                                value={data.client.delivery_city}
                                onChange={value => this.onChangeClient('delivery_city', value)}
                                disabled={sameDelivery}
                            />
                            <Input
                                label={__('PSČ (dodanie)')}
                                value={data.client.delivery_zip}
                                onChange={value => this.onChangeClient('delivery_zip', value)}
                                disabled={sameDelivery}
                            />
                            <Select
                                label={__('Krajina (dodanie)')}
                                value={data.client.delivery_country}
                                options={order.countries}
                                onChange={value => this.onChangeClient('delivery_country', value)}
                                disabled={sameDelivery}
                                allowEmpty={false}
                            />
                            <Input
                                label={__('Telefón (dodanie)')}
                                value={data.client.delivery_phone}
                                onChange={value => this.onChangeClient('delivery_phone', value)}
                                disabled={sameDelivery}
                            />
                            <Input
                                label={__('Poznámka (dodanie)')}
                                value={data.client.delivery_note}
                                onChange={value => this.onChangeClient('delivery_note', value)}
                            />
                        </div>
                    </div>
                    <div className="order__content__products">
                        <div className="order__content__products__title">{__('Produkty')}</div>
                        {_.map(data.products, (product, key) => {
                            const quantity = toNumber(product.quantity);
                            unitPrice += toNumber(product.unit_price) * quantity;
                            totalPrice += toNumber(product.total_price) * quantity;

                            return (
                                <div className="order__content__products__product" key={key}>
                                    <Input
                                        label={__('Názov')}
                                        content={<Autocomplete
                                            options={order.products}
                                            getOptionLabel={option => option.data.name}
                                            onChange={(event, value) => this.onSelectProduct(key, value)}
                                            renderInput={(params) => <TextField
                                                { ...params }
                                                placeholder={__('Začnite písať názov...')}
                                                variant="outlined"
                                            />}
                                            noOptionsText={__('Nenašiel sa žiadny produkt')}
                                            clearText={__('Zrušiť')}
                                            freeSolo
                                        />}
                                    />
                                    <Input
                                        label={__('Počet')}
                                        value={product.quantity}
                                        onChange={value => this.onChangeProduct(key, 'quantity', value)}
                                    />
                                    <Input
                                        label={__('Cena bez DPH')}
                                        value={product.unit_price}
                                        onChange={value => this.onChangeProduct(key, 'unit_price', value)}
                                    />
                                    <Input
                                        label={__('DPH')}
                                        value={product.tax}
                                        onChange={value => this.onChangeProduct(key, 'tax', value)}
                                    />
                                    <Input
                                        label={__('Cena s DPH')}
                                        value={product.total_price}
                                        onChange={value => this.onChangeProduct(key, 'total_price', value)}
                                    />
                                    <Tooltip title={__('Zmazať')}>
                                        <span><IconButton
                                            onClick={() => this.deleteProduct(key)}
                                            className="order__content__products__product__delete"
                                            disabled={key === 0}
                                        >
                                            <DeleteIcon />
                                        </IconButton></span>
                                    </Tooltip>
                                </div>
                            );
                        })}
                        <Button
                            onClick={() => this.addProduct()}
                            className="order__content__products__button"
                        >{__('Pridať produkt')}</Button>
                    </div>
                    <div className="order__content__summary">
                        <div className="order__content__summary__line">
                            <div className="order__content__summary__line__name">{__('Bez DPH')}</div>
                            <div className="order__content__summary__line__value">{formatAmount(unitPrice, data.order.currency)}</div>
                        </div>
                        <div className="order__content__summary__line">
                            <div className="order__content__summary__line__name">{__('Celkom')}</div>
                            <div className="order__content__summary__line__value">{formatAmount(totalPrice, data.order.currency)}</div>
                        </div>
                    </div>
                    <Button
                        onClick={() => this.save()}
                        loading={loading}
                        className="order__content__button"
                        color="green"
                    >{__('Uložiť')}</Button>
                </div>
                {this.renderSnackbar()}
            </div>
        );
    }
}

const stateToProps = ({ order, user }) => ({ order, user });

export default withCookies(connect(stateToProps, {
    fetchCreateOrder,
    setUser,
    createOrder,
    cleanOrder,
})(OrderScreen));
