import { request, toNumber, __ } from '../functions';
import {
    FETCH_STORAGE,
    FETCH_STORAGE_DATA,
    CHANGE_STORAGE_AUTO_FIELD,
    CHANGE_STORAGE_SETTINGS,
    CREATE_STORAGE,
    DELETE_STORAGE,
} from './types';

/**
 * Nacitanie skladu.
 *
 * @param {Screen} screen
 */
export const fetchStorage = screen => {
    return dispatch => request('/user-eshops/storage').then(({ data }) => {
        const { user } = data.sync;
        const { setUser } = screen.props;

        // Nasetujeme usera
        setUser(user);

        dispatch({ type: FETCH_STORAGE, payload: data.data });
    });
};

/**
 * Nacitanie dat skladu.
 *
 * @param {Screen} screen
 */
export const fetchStorageData = screen => {
    return dispatch => request('/user-eshops/storageData').then(response => {
        const { status, data } = response.data;

        screen.setState({ loadingData: false });

        if (status === 'error') {
            // Nepodarilo sa nacitat data
            screen.showSnackbar('error', __('Nepodarilo sa načítať dáta zo skladu'));
            return;
        }

        screen.showSnackbar('success', __('Údaje zo skladu boli načítané'));

        dispatch({ type: FETCH_STORAGE_DATA, payload: data });
    });
};

/**
 * Zmenime auto field skladu.
 *
 * @param {Screen} screen
 * @param {string} field
 * @param {boolean} value
 */
export const changeStorageAutoField = (screen, field, value) => {
    return dispatch => request(`/user-eshops/setStorageAuto/${field}/${toNumber(value)}`).then(({ data }) => {
        screen.showSnackbar('success', value ? __('Zapnuté') : __('Vypnuté'));

        dispatch({ type: CHANGE_STORAGE_AUTO_FIELD, payload: { field, value } });
    });
};

/**
 * Zmenime nastavenia skladu.
 *
 * @param {Screen} screen
 * @param {Object} settings
 */
export const changeStorageSettings = (screen, settings) => {
    return dispatch => request('/user-eshops/changeStorageSettings', { settings }, 'POST').then(response => {
        const { status } = response.data;

        screen.setState({ loading: false });

        if (status === 'error') {
            // Nepodarilo sa zmenit nastavenia
            screen.showSnackbar('error', __('Nepodarilo sa zmeniť nastavenia'));
            return;
        }

        screen.showSnackbar('success', __('Nastavenia boli zmenené'));

        dispatch({ type: CHANGE_STORAGE_SETTINGS, payload: settings });
    });
};

/**
 * Vytvorenie prepojenia na sklad.
 *
 * @param {number} id
 */
export const createStorage = id => ({ type: CREATE_STORAGE, payload: id });

/**
 * Zmazanie prepojenia na sklad.
 */
export const deleteStorage = () => ({ type: DELETE_STORAGE });
