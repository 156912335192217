import { CLEAN_COMPLAINT, FETCH_COMPLAINT } from '../actions/types';

const COMPLAINT_DEFAULT_STATE = {};

/**
 * Reducer pre reklamaciu.
 */
const ComplaintReducer = (state = COMPLAINT_DEFAULT_STATE, { type, payload }) => {
    switch (type) {
        case FETCH_COMPLAINT:
            // Nacitanie reklamacie
            return payload;

        case CLEAN_COMPLAINT:
            // Vycistenie reklamacie
            return COMPLAINT_DEFAULT_STATE;

        default:
            return state;
    }
};

export default ComplaintReducer;
