import _ from 'lodash';
import {
    FETCH_CAB_PRODUCTS,
    CLEAN_CAB_PRODUCTS,
    ADD_CAB_UPGATES_PRODUCT,
    REMOVE_CAB_UPGATES_PRODUCT,
    ADD_CAB_ALLEGRO_PRODUCT,
    REMOVE_CAB_ALLEGRO_PRODUCT,
    ADD_CAB_MALL_PRODUCT,
    REMOVE_CAB_MALL_PRODUCT,
    CHANGE_CAB_UPGATES_PRODUCT_PRICE,
    CHANGE_CAB_MARKETPLACE_PRODUCT_PRICE,
} from './types';
import { request, __ } from '../functions';

export const fetchCabProducts = (screen, options = {}) => {
    const defaults = {
        page: 1,
        per_page: 0,
        filtered: {},
    };

    options = { ...defaults, ...options };

    let params = { page: options.page, per_page: options.per_page };

    // Nasetujeme filter
    _.forEach(options.filtered, (value, key) => {
        params = { ...params, ...{ [`filter_${key}`]: value } };
    });

    return dispatch => request('/cab/products', params).then(response => {
        const { data, sync } = response.data;
        const { setUser } = screen.props;

        // Nasetujeme user data
        setUser(sync.user);

        dispatch({ type: FETCH_CAB_PRODUCTS, payload: data });
    });
};

export const addCabUpGatesProduct = (screen, sku, user_eshop_id) => {
    return dispatch => request(`/cab/addUpGatesProduct/${sku}/${user_eshop_id}`).then(response => {
        const { status } = response.data;

        screen.onChangeEdit('loading', false);

        if (status === 'error') {
            screen.showSnackbar('error', __('Nepodarilo sa vytvoriť produkt'));
            return;
        }

        screen.closeLightbox('edit');
        screen.showSnackbar('success', __('Produkt bol vytvorený'));

        dispatch({ type: ADD_CAB_UPGATES_PRODUCT, payload: { sku, user_eshop_id } });
    });
};

export const removeCabUpGatesProduct = (screen, sku, user_eshop_id) => {
    return dispatch => request(`/cab/removeUpGatesProduct/${sku}/${user_eshop_id}`).then(response => {
        const { status } = response.data;

        screen.onChangeEdit('loading', false);

        if (status === 'error') {
            screen.showSnackbar('error', __('Nepodarilo sa zmazať produkt'));
            return;
        }

        screen.closeLightbox('edit');
        screen.showSnackbar('success', __('Produkt bol zmazaný'));

        dispatch({ type: REMOVE_CAB_UPGATES_PRODUCT, payload: { sku, user_eshop_id } });
    });
};

export const addCabAllegroProduct = (screen, sku, user_eshop_id, category_id) => {
    return dispatch => request(`/cab/addAllegroProduct/${sku}/${user_eshop_id}/${category_id}`).then(response => {
        const { status } = response.data;

        screen.onChangeEdit('loading', false);

        if (status === 'error') {
            screen.showSnackbar('error', __('Nepodarilo sa vytvoriť produkt'));
            return;
        }

        screen.closeLightbox('edit');
        screen.showSnackbar('success', __('Produkt bol vytvorený'));

        dispatch({ type: ADD_CAB_ALLEGRO_PRODUCT, payload: { sku, user_eshop_id } });
    });
};

export const removeCabAllegroProduct = (screen, sku, user_eshop_id) => {
    return dispatch => request(`/cab/removeAllegroProduct/${sku}/${user_eshop_id}`).then(response => {
        const { status } = response.data;

        screen.onChangeEdit('loading', false);

        if (status === 'error') {
            screen.showSnackbar('error', __('Nepodarilo sa zmazať produkt'));
            return;
        }

        screen.closeLightbox('edit');
        screen.showSnackbar('success', __('Produkt bol zmazaný'));

        dispatch({ type: REMOVE_CAB_ALLEGRO_PRODUCT, payload: { sku, user_eshop_id } });
    });
};

export const addCabMallProduct = (screen, sku, user_eshop_id) => {
    return dispatch => request(`/cab/addMallProduct/${sku}/${user_eshop_id}`).then(response => {
        const { status } = response.data;

        screen.onChangeEdit('loading', false);

        if (status === 'error') {
            screen.showSnackbar('error', __('Nepodarilo sa vytvoriť produkt'));
            return;
        }

        screen.closeLightbox('edit');
        screen.showSnackbar('success', __('Produkt bol vytvorený'));

        dispatch({ type: ADD_CAB_MALL_PRODUCT, payload: { sku, user_eshop_id } });
    });
};

export const removeCabMallProduct = (screen, sku, user_eshop_id) => {
    return dispatch => request(`/cab/removeMallProduct/${sku}/${user_eshop_id}`).then(response => {
        const { status } = response.data;

        screen.onChangeEdit('loading', false);

        if (status === 'error') {
            screen.showSnackbar('error', __('Nepodarilo sa zmazať produkt'));
            return;
        }

        screen.closeLightbox('edit');
        screen.showSnackbar('success', __('Produkt bol zmazaný'));

        dispatch({ type: REMOVE_CAB_MALL_PRODUCT, payload: { sku, user_eshop_id } });
    });
};

export const changeCabUpGatesProductPrice = (screen, sku, price) => {
    return dispatch => request(`/cab/changeUpGatesProductPrice/${sku}/${price}`).then(response => {
        const { status } = response.data;

        if (status === 'error') {
            screen.onChangePrice('loading', false);
            screen.showSnackbar('error', __('Nepodarilo sa upraviť produkt'));
            return;
        }

        screen.closeLightbox('price');
        screen.showSnackbar('success', __('Cena bola zmenená'));

        dispatch({ type: CHANGE_CAB_UPGATES_PRODUCT_PRICE });
    });
};

export const changeCabMarketplaceProductPrice = (screen, sku, price) => {
    return dispatch => request(`/cab/changeMarketplaceProductPrice/${sku}/${price}`).then(response => {
        const { status } = response.data;

        if (status === 'error') {
            screen.onChangePrice('loading', false);
            screen.showSnackbar('error', __('Nepodarilo sa upraviť produkt'));
            return;
        }

        screen.closeLightbox('price');
        screen.showSnackbar('success', __('Cena bola zmenená'));

        dispatch({ type: CHANGE_CAB_MARKETPLACE_PRODUCT_PRICE });
    });
};

export const cleanCabProducts = () => ({ type: CLEAN_CAB_PRODUCTS });
