import React from 'react';
import { connect } from 'react-redux';
import { withCookies } from 'react-cookie';
import _ from 'lodash';
import { Tabs, Tab, ButtonBase } from '@mui/material';
import { Screen, Button, OrderPage, Message, Input, Switch, Select } from '../components';
import { fetchTrackTrace, cleanTrackTrace, setUser, changeTrackTraceSettings, trackTraceUploadLogo } from '../actions';
import { __, request } from '../functions';
import '../assets/styles/track_trace.css';

/**
 * Track trace.
 */
class TrackTraceScreen extends Screen {
    /**
     * Title.
     *
     * @type {string}
     */
    title = __('Track & Trace');

    /**
     * Default state.
     *
     * @type {Object}
     */
    state = {
        loading: false,
        showTranslations: false,
        tab: 'confirm',
        settings: {},
    };

    /**
     * Komponenta bola pripojena.
     *
     * @return boolean
     */
    async componentDidMount() {
        if (super.componentDidMount()) {
            const { fetchTrackTrace } = this.props;

            // Nacitame nastavenia
            await fetchTrackTrace(this);

            const { track_trace } = this.props;

            this.setState({ settings: track_trace.settings });
        }

        return true;
    }

    /**
     * Komponenta bude odpojena.
     */
    componentWillUnmount() {
        const { cleanTrackTrace } = this.props;

        cleanTrackTrace();
    }

    /**
     * Event po zmene nastaveni.
     *
     * @param {string} param
     * @param {string} value
     */
    onChangeSetting(param, value) {
        let { settings, tab } = this.state;

        if (param === 'states') {
            settings = { ...settings, states: { ...settings.states, [tab]: { ...settings.states[tab], states: value } } };
        } else {
            settings = { ...settings, [param]: value };
        }

        this.setState({ settings });
    }

    /**
     * Event po zmene nastavenia textu.
     *
     * @param {string} country
     * @param {string} value
     */
    onChangeSettingText(country, value) {
        const { settings, tab } = this.state;

        this.setState({ settings: {
            ...settings,
            states: { ...settings.states, [tab]: { ...settings.states[tab], texts: { ...settings.states[tab].texts, [country]: value } } },
        } });
    }

    /**
     * Ulozenie nastaveni.
     */
    save() {
        const { changeTrackTraceSettings } = this.props;
        const { settings } = this.state;

        this.setState({ loading: true });

        changeTrackTraceSettings(this, settings);
    }

    /**
     * Nahratie loga.
     *
     * @param {Object} file
     */
    uploadLogo(file) {
        const { trackTraceUploadLogo } = this.props;

        request('/user-eshops/logo', file, 'FILE').then(response => {
            const { status, data } = response.data;

            if (status === 'error') {
                // Nepodarilo sa nahrat logo
                this.showSnackbar('error', __('Nepodarilo sa nahrať logo'));
                return;
            }

            // Nastavime logo
            trackTraceUploadLogo(data.url);
        });
    }

    /**
     * Rendrovanie.
     *
     * @returns {JSX.Element}
     */
    render() {
        const { track_trace } = this.props;
        const { loading, settings, tab, showTranslations } = this.state;

        if (_.isEmpty(track_trace) || _.isEmpty(settings)) {
            // Data nie su nacitane
            return this.renderLoading();
        }

        // Naformatujeme zoznam stavov
        const states = _.reduce(track_trace.states, (result, { id, name }) => ({ ...result, [id]: name }), {});

        return (
            <div className="track-trace">
                <div className="track-trace__settings">
                    <div className="track-trace__settings__title">{__('Nastavenia')}</div>
                    <div className="track-trace__settings__panels">
                        <div className="track-trace__settings__panels__panel">
                            <Message className="track-trace__settings__panels__panel__info" type="info">{__('Nižšie sme pripravili zjednodušený zoznam stavov objednávky. Vyberte stavy z vášho eshopu ktoré patria k danému stavu.')}</Message>
                            <Tabs
                                value={tab}
                                onChange={(event, tab) => this.setState({ tab })}
                                className="track-trace__settings__panels__panel__tabs"
                            >
                                <Tab label={__('Prijatá')} value="confirm" />
                                <Tab label={__('Spracováva sa')} value="processing" />
                                <Tab label={__('Odoslaná')} value="send" />
                                <Tab label={__('Doručená')} value="delivered" />
                            </Tabs>
                            <div className="track-trace__settings__panels__panel__tabs__settings">
                                <div className="track-trace__settings__panels__panel__tabs__settings__left">
                                    <Select
                                        label={__('Stavy objednávky')}
                                        options={states}
                                        value={settings.states[tab].states}
                                        onChange={value => this.onChangeSetting('states', value)}
                                        multiple
                                    />
                                </div>
                                <div className="track-trace__settings__panels__panel__tabs__settings__right">
                                    <Input
                                        label={__('Text')}
                                        value={settings.states[tab].texts.sk}
                                        onChange={value => this.onChangeSettingText('sk', value)}
                                    />
                                    <ButtonBase
                                        onClick={() => this.setState({ showTranslations: !showTranslations })}
                                    >{__('Preložiť')}</ButtonBase>
                                    {showTranslations ? <Input
                                        label={`${__('Text')} (CZ)`}
                                        value={settings.states[tab].texts.cz}
                                        onChange={value => this.onChangeSettingText('cz', value)}
                                    /> : null}
                                    {showTranslations ? <Input
                                        label={`${__('Text')} (PL)`}
                                        value={settings.states[tab].texts.pl}
                                        onChange={value => this.onChangeSettingText('pl', value)}
                                    /> : null}
                                    {showTranslations ? <Input
                                        label={`${__('Text')} (HU)`}
                                        value={settings.states[tab].texts.hu}
                                        onChange={value => this.onChangeSettingText('hu', value)}
                                    /> : null}
                                    {showTranslations ? <Input
                                        label={`${__('Text')} (EN)`}
                                        value={settings.states[tab].texts.en}
                                        onChange={value => this.onChangeSettingText('en', value)}
                                    /> : null}
                                </div>
                            </div>
                        </div>
                        <div className="track-trace__settings__panels__panel">
                            <ButtonBase className="track-trace__settings__panels__panel__upload">
                                {track_trace.logo === ''
                                    ? <div className="track-trace__settings__panels__panel__upload__text">{__('Kliknite alebo presuňte súbor (jpg, png, max. 5MB)')}</div>
                                    : <img src={`${track_trace.logo}?v=${Math.random()}`} alt="logo eshop" />}
                                <input type="file" onChange={event => this.uploadLogo(event.target.files[0])} />
                            </ButtonBase>
                            <Input
                                label={__('Kontaktný email')}
                                value={settings.contact}
                                onChange={value => this.onChangeSetting('contact', value)}
                                type="email"
                            />
                            <Switch
                                label={__('Povoliť reklamácie')}
                                checked={settings.complaints}
                                onChange={checked => this.onChangeSetting('complaints', checked)}
                            />
                            <Switch
                                label={__('Povoliť storno')}
                                checked={settings.cancel}
                                onChange={checked => this.onChangeSetting('cancel', checked)}
                            />
                            {settings.cancel ? <Select
                                label={__('Stav objednávky po storne')}
                                options={states}
                                value={settings.cancel_state}
                                onChange={value => this.onChangeSetting('cancel_state', value)}
                            /> : null}
                        </div>
                    </div>
                    <Button
                        onClick={() => this.save()}
                        loading={loading}
                        className="track-trace__settings__button"
                        color="green"
                        disabled={this.isDemo()}
                    >{__('Uložiť')}</Button>
                    {!_.isEmpty(track_trace.order) ? <div className="track-trace__settings__part" /> : null}
                    {!_.isEmpty(track_trace.order) ? <Message type="info">
                        <span>{__('Nižšie vidíte náhľad poslednej objednávky v eshope.')}</span>
                        <a href={`/orders/public/${track_trace.id}/${track_trace.token}`} target="_blank">{__('Zobraziť z pohľadu zákaznika')}</a>
                    </Message> : null}
                </div>
                {!_.isEmpty(track_trace.order) ? <OrderPage data={track_trace} target="_blank" /> : null}
                {this.renderSnackbar()}
            </div>
        );
    }
}

const stateToProps = ({ track_trace, user }) => ({ track_trace, user });

export default withCookies(connect(stateToProps, {
    fetchTrackTrace,
    setUser,
    cleanTrackTrace,
    changeTrackTraceSettings,
    trackTraceUploadLogo,
})(TrackTraceScreen));
