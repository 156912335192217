import { CLEAN_TEMPLATE, FETCH_TEMPLATE } from '../actions/types';

const TEMPLATE_DEFAULT_STATE = {};

/**
 * Reducer pre sablonu.
 */
const TemplateReducer = (state = TEMPLATE_DEFAULT_STATE, { type, payload }) => {
    switch (type) {
        case FETCH_TEMPLATE:
            // Nacitanie sablony
            return payload;

        case CLEAN_TEMPLATE:
            // Vycistenie sablony
            return TEMPLATE_DEFAULT_STATE;

        default:
            return state;
    }
};

export default TemplateReducer;
