import { FETCH_INVOICES, CLEAN_INVOICES, DELETE_INVOICE, DELETE_INVOICES } from '../actions/types';

const INVOICES_DEFAULT_STATE = {};

/**
 * Reducer pre faktury.
 */
const InvoicesReducer = (state = INVOICES_DEFAULT_STATE, { type, payload }) => {
    switch (type) {
        case FETCH_INVOICES:
            // Nacitanie faktur
            return payload;

        case CLEAN_INVOICES:
        case DELETE_INVOICE:
        case DELETE_INVOICES:
            // Vycistenie faktur
            // Zmazanie faktury
            // Zmazanie faktur cez multiselect
            return INVOICES_DEFAULT_STATE;

        default:
            return state;
    }
};

export default InvoicesReducer;
