import React from 'react';
import _ from 'lodash';
import { Navigate, Button, Message } from '../components';
import { __, request, toNumber } from '../functions';
import '../assets/styles/bank.css';

/**
 * Bank komponenta.
 */
class Bank extends Navigate {
    /**
     * Default props.
     *
     * @type {Object}
     */
    static defaultProps = {
        id: 0,
        hash: '',
        activated: false,
        generateEmail: () => {},
    };

    /**
     * Default state.
     *
     * @type {Object}
     */
    state = {
        loading: false,
        activated: false,
        hash: '',
    };

    /**
     * Komponenta bola pripojena.
     */
    componentDidMount() {
        const { id, title, setTitle, activated, createBank, showSnackbar, user } = this.props;

        // Nastavime title
        setTitle(title);

        if (toNumber(user.user_eshop_id) === 0) {
            return;
        }

        if (!activated) {
            // Nie je aktivna, aktivujeme
            request('/user-banks/create', { bank_id: id }, 'POST').then(response => {
                const { status, data } = response.data;

                if (status === 'error') {
                    // Nepodarilo sa pridat bank
                    showSnackbar('error', __('Nepodarilo sa aktivovať banku'));
                    return;
                }

                showSnackbar('success', __('Banka bola aktivovaná'));

                // Vytvorime
                createBank(id, data.name);

                this.setState({ activated: true, hash: data.name });
            });
            return;
        }

        this.setState({ activated: true });
    }

    /**
     * Zmazanie.
     */
    delete() {
        const { id, deleteBank, onClose, showSnackbar } = this.props;

        this.setState({ loading: true });

        request(`/user-banks/delete/${id}`).then(response => {
            const { status } = response.data;

            if (status === 'error') {
                // Nepodarilo sa zmazat bank
                showSnackbar('error', __('Nepodarilo sa zmazať banku'));
                return;
            }

            showSnackbar('success', __('Banka bola zmazaná'));

            // Zmazeme
            deleteBank(id);

            // Zavrieme
            onClose();
        });
    }

    /**
     * Rendrovanie.
     *
     * @return {JSX.Element}
     */
    render() {
        const { generateEmail, user } = this.props;
        const { loading, activated } = this.state;

        if (toNumber(user.user_eshop_id) === 0) {
            return <div
                className="bank-content"
            ><Message type="warning">{__('Najprv musíte aktivovať eshop')}</Message></div>;
        }

        if (!activated) {
            // Data nie su nacitane
            return <div
                className="bank-content bank-content-loading"
            >{this.renderLoading()}</div>;
        }

        // Vytiahneme has pre email
        const hash = !_.isEmpty(this.state.hash) ? this.state.hash : this.props.hash;

        return (
            <div className="bank-content">
                <div className="bank-content__data">
                    <div className="bank-content__data__credentials">
                        <div className="bank-content__data__credentials__title">{__('Údaje pre banku')}</div>
                        <div className="bank-content__data__credentials__value">
                            <div className="bank-content__data__credentials__value__name">{__('E-mail')}:</div>
                            <div className="bank-content__data__credentials__value__text">{generateEmail(hash)}</div>
                        </div>
                    </div>
                    <Message type="info">{__('Vyššie uvedený email musíte nastaviť vo svojom internet bankingu ako notifikačný email (bmail).')}</Message>
                </div>
                <Button
                    onClick={() => this.delete()}
                    color="red"
                    className="bank-content__button"
                    loading={loading}
                >{__('Zmazať')}</Button>
            </div>
        );
    }
}

export { Bank };
