import React from 'react';
import { Navigate } from './Navigate';
import { IMAGES } from '../config';
import '../assets/styles/content-part.css';

/**
 * Content part komponenta.
 */
class ContentPart extends Navigate {
    /**
     * Default props.
     *
     * @type {Object}
     */
    static defaultProps = {
        title: '',
    };

    /**
     * Rendrovanie.
     *
     * @return {JSX.Element|null}
     */
    render() {
        const { title } = this.props;

        return (
            <div className="content-part">
                <span>{title}</span>
                <img src={IMAGES['draw-arrow.svg']} alt="sipka" />
            </div>
        );
    }
}

export { ContentPart };
