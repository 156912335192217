import React from 'react';
import { connect } from 'react-redux';
import { withCookies } from 'react-cookie';
import _ from 'lodash';
import {Screen, Input, Button} from '../components';
import { fetchMtoknaWindowSettings, setUser, cleanMtoknaWindowSettings } from '../actions';
import {__, request} from '../functions';
import '../assets/styles/mtokna_window_settings.css';

/**
 * Email.
 */
class MtoknaWindowSettingsScreen extends Screen {
    /**
     * Title.
     *
     * @type {string}
     */
    title = __('Žalúzie, sieťky - cenník');

    /**
     * Default state.
     *
     * @type {Object}
     */
    state = {
        data: {},
        loading: false,
    };

    /**
     * Komponenta bola pripojena.
     *
     * @return boolean
     */
    componentDidMount() {
        if (super.componentDidMount()) {
            this.load();
        }

        return true;
    }

    /**
     * Komponenta bude odpojena.
     */
    componentWillUnmount() {
        const { cleanMtoknaWindowSettings } = this.props;

        cleanMtoknaWindowSettings();
    }

    async load() {
        const { fetchMtoknaWindowSettings } = this.props;

        // Nacitame smtp
        await fetchMtoknaWindowSettings(this);

        const { settings } = this.props;

        let data = {
            blinds: {
                isso: '',
                isso1: '',
                isso2: '',
                isso3: '',
                isso16mm: '',
                color1: '',
                color2: '',
                color3: '',
                color4: '',
                color5: '',
                min_area: '',
                max_area: '',
                min_width: '',
                max_width: '',
                min_length: '',
                max_length: '',
                fee1: '',
                fee2: '',
                fee3: '',
                discount: '',
            },
            nets: {
                base: '',
                charge1: '',
                charge2: '',
                charge3: '',
                charge4: '',
                charge5: '',
                fee1: '',
                discount: '',
            },
        };

        if (!_.isEmpty(settings.blinds)) {
            data = { ...data, blinds: { ...data.blinds, ...settings.blinds } };
        }

        if (!_.isEmpty(settings.nets)) {
            data = { ...data, nets: { ...data.nets, ...settings.nets } };
        }

        this.setState({ data, loading: false });
    }

    /**
     * Ulozenie.
     */
    save() {
        let { data } = this.state;

        let error = '';

        if (error !== '') {
            // Mame error
            this.showSnackbar('error', error);
            return;
        }

        this.setState({ loading: true });

        request('/mtokna/editWindowSettings', { data }, 'POST').then(response => {
            const { status, data } = response.data;

            this.setState({ loading: false });

            if (status === 'error') {
                this.showSnackbar('error', _.has(data, 'message') ? data.message : __('Nepodarilo sa uložiť cenník'));
                return;
            }

            this.showSnackbar('success', __('Cenník bol uložený'));
        })
    }

    /**
     * Event po zmene dat.
     *
     * @param {string} type
     * @param {string} setting
     * @param {string} value
     */
    onChange(type, setting, value) {
        const { data } = this.state;

        this.setState({ data: { ...data, [type]: { ...data[type], [setting]: value } } });
    }

    /**
     * Rendrovanie.
     *
     * @returns {JSX.Element}
     */
    render() {
        const { data, loading } = this.state;

        if (_.isEmpty(data) || loading) {
            // Data nie su nacitane
            return this.renderLoading();
        }

        return (
            <div className="mtokna_window_settings">
                <div className="mtokna_window_settings__header">
                    <div className="mtokna_window_settings__header__left">
                        <div className="mtokna_window_settings__header__left__title">{__('Žalúzie, sieťky - cenník')}</div>
                    </div>
                </div>
                <div className="mtokna_window_settings__content">
                    <div className="mtokna_window_settings__content__panels">
                        <div className="mtokna_window_settings__content__panels__panel">
                            <div className="mtokna_window_settings__content__panels__panel__title">{__('Žalúzie')}</div>
                            <Input
                                label={__('Typ - ISSO žalúzie zákl. lamely (€)')}
                                value={data.blinds.isso}
                                onChange={value => this.onChange('blinds', 'isso', value)}
                            />
                            <Input
                                label={__('Typ - ISSO žalúzie lamela* (€)')}
                                value={data.blinds.isso1}
                                onChange={value => this.onChange('blinds', 'isso1', value)}
                            />
                            <Input
                                label={__('Typ - ISSO žalúzie lamela** (€)')}
                                value={data.blinds.isso2}
                                onChange={value => this.onChange('blinds', 'isso2', value)}
                            />
                            <Input
                                label={__('Typ - ISSO žalúzie lamela*** (€)')}
                                value={data.blinds.isso3}
                                onChange={value => this.onChange('blinds', 'isso3', value)}
                            />
                            <Input
                                label={__('Typ - ISSO žalúzie lamela 16MM (€)')}
                                value={data.blinds.isso16mm}
                                onChange={value => this.onChange('blinds', 'isso16mm', value)}
                            />
                            <Input
                                label={__('Farba rámu - 402, 456, 8003, 8004, 7016 (€)')}
                                value={data.blinds.color1}
                                onChange={value => this.onChange('blinds', 'color1', value)}
                            />
                            <Input
                                label={__('Farba rámu - RENOLIT 8001, 8200, 8300, 8400, 8500, 8600 (€)')}
                                value={data.blinds.color2}
                                onChange={value => this.onChange('blinds', 'color2', value)}
                            />
                            <Input
                                label={__('Farba rámu - DREVODEKOR 6783, 6784, 6785, 6792, 6793, 6795 (€)')}
                                value={data.blinds.color3}
                                onChange={value => this.onChange('blinds', 'color3', value)}
                            />
                            <Input
                                label={__('Farba rámu - NÁSTREK RAL (€)')}
                                value={data.blinds.color4}
                                onChange={value => this.onChange('blinds', 'color4', value)}
                            />
                            <Input
                                label={__('Farba rebríka - 415, 302, 431, 402, 456 (€)')}
                                value={data.blinds.color5}
                                onChange={value => this.onChange('blinds', 'color5', value)}
                            />
                            <Input
                                label={__('Minimálna plocha (m2)')}
                                value={data.blinds.min_area}
                                onChange={value => this.onChange('blinds', 'min_area', value)}
                            />
                            <Input
                                label={__('Maximálna plocha (m2)')}
                                value={data.blinds.max_area}
                                onChange={value => this.onChange('blinds', 'max_area', value)}
                            />
                            <Input
                                label={__('Minimálna šírka (mm)')}
                                value={data.blinds.min_width}
                                onChange={value => this.onChange('blinds', 'min_width', value)}
                            />
                            <Input
                                label={__('Maximálna šírka (mm)')}
                                value={data.blinds.max_width}
                                onChange={value => this.onChange('blinds', 'max_width', value)}
                            />
                            <Input
                                label={__('Minimálna výška (mm)')}
                                value={data.blinds.min_length}
                                onChange={value => this.onChange('blinds', 'min_length', value)}
                            />
                            <Input
                                label={__('Maximálna výška (mm)')}
                                value={data.blinds.max_length}
                                onChange={value => this.onChange('blinds', 'max_length', value)}
                            />
                            <Input
                                label={__('Zameranie žalúzií (€)')}
                                value={data.blinds.fee1}
                                onChange={value => this.onChange('blinds', 'fee1', value)}
                            />
                            <Input
                                label={__('Montáž žalúzií (€)')}
                                value={data.blinds.fee2}
                                onChange={value => this.onChange('blinds', 'fee2', value)}
                            />
                            <Input
                                label={__('Výjazd k zákazníkovi (€)')}
                                value={data.blinds.fee3}
                                onChange={value => this.onChange('blinds', 'fee3', value)}
                            />
                            <Input
                                label={__('Zľava pre registrovaných zákazníkov (%)')}
                                value={data.blinds.discount}
                                onChange={value => this.onChange('blinds', 'discount', value)}
                            />
                        </div>
                        <div className="mtokna_window_settings__content__panels__panel">
                            <div className="mtokna_window_settings__content__panels__panel__title">{__('Sieťky')}</div>
                            <Input
                                label={__('Základ (€)')}
                                value={data.nets.base}
                                onChange={value => this.onChange('nets', 'base', value)}
                            />
                            <Input
                                label={__('Príplatok za farbu imit. Dreva (€)')}
                                value={data.nets.charge1}
                                onChange={value => this.onChange('nets', 'charge1', value)}
                            />
                            <Input
                                label={__('Príplatok za farbu nástrek RAL (€)')}
                                value={data.nets.charge2}
                                onChange={value => this.onChange('nets', 'charge2', value)}
                            />
                            <Input
                                label={__('Príplatok za sieťovinu PET (€)')}
                                value={data.nets.charge3}
                                onChange={value => this.onChange('nets', 'charge3', value)}
                            />
                            <Input
                                label={__('Príplatok za sieťovinu ALU (€)')}
                                value={data.nets.charge4}
                                onChange={value => this.onChange('nets', 'charge4', value)}
                            />
                            <Input
                                label={__('Príplatok za sieťovinu BLACK (€)')}
                                value={data.nets.charge5}
                                onChange={value => this.onChange('nets', 'charge5', value)}
                            />
                            <Input
                                label={__('Zameranie sietí proti hmyzu (€)')}
                                value={data.nets.fee1}
                                onChange={value => this.onChange('nets', 'fee1', value)}
                            />
                            <Input
                                label={__('Zľava pre registrovaných zákazníkov (%)')}
                                value={data.nets.discount}
                                onChange={value => this.onChange('nets', 'discount', value)}
                            />
                        </div>
                    </div>
                    <Button
                        onClick={() => this.save()}
                        loading={loading}
                        className="mtokna_window_settings__content__button"
                        color="green"
                    >{__('Uložiť')}</Button>
                </div>
                {this.renderSnackbar()}
            </div>
        );
    }
}

const stateToProps = ({ mtokna_window_settings, user }) => ({ settings: mtokna_window_settings, user });

export default withCookies(connect(stateToProps, {
    fetchMtoknaWindowSettings,
    setUser,
    cleanMtoknaWindowSettings,
})(MtoknaWindowSettingsScreen));
